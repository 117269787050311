import HeaderCarousel from '../../../../../components/HeaderCarousel/HeaderCarousel';
import image from '../../../../../assets/css/images/nahlasene.jpg';
import './StateCompareInfo.css';
import { Firearm, PoliceForState, PoliceType, State, StateCompareCountForPolice, StateCompareIndexForPolice, StateCompareRequest, StateInfoDto, drogsLimit, isFirearm } from '../../../../../interfaces/europe/interfaces';
import React, { useEffect, useRef } from 'react';
import { COLORS, GET_ALL_STATES, GET_ALL_TYPE_OF_FIREARMS, HEADER_STATE_COMPARE_LOCATION, ITEM_HEIGHT, ITEM_PADDING_TOP, LINK_STATE_COMAPRE_CO, LINK_STATE_COMAPRE_CONVICTED, LINK_STATE_COMAPRE_INFO, LINK_STATE_COMAPRE_POLICE, LINK_STATE_COMAPRE_PRISON, MAP_COLORS, POST_FILTER_CO_LINK, POST_POLICE_CIVILIANS_LINK, POST_POLICE_OFFICERS_LINK, POST_STATE_COMPARE_INFO, POST_STATE_COMPARE_POLICE_COUNTS, POST_STATE_COMPARE_POLICE_INDEXES, STATE_INFO_SITE, TABLE_PAGE_SIZE_TEN } from '../../../../../interfaces/europe/Constants';
import axios from 'axios';
import { Box, Tabs, Tab, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { LineChart, CartesianGrid, XAxis, YAxis, Legend, Line, Tooltip as TooltipChart } from 'recharts';
import { CustomToolbar, getImage } from '../../../../../interfaces/europe/Functions';
import Chart from 'react-google-charts';
import { FaChartLine, FaCheckSquare, FaMinusSquare, FaTable, FaWindowClose } from 'react-icons/fa';
import { useTranslation } from "react-i18next";
import generatePDF, { Margin, Options } from 'react-to-pdf';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

export default function StateComparePolice() {
    const targetRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation("europe");

    const { codes } = useParams<string>();

    //RESTS FOR GET
    const [states, setStates] = React.useState<State[]>([]);
    const [statesInfo, setStatesInfo] = React.useState<StateInfoDto[]>([]);
    const [firearms, setFirearms] = React.useState<Firearm[]>([]);
    const [policeForState, setPoliceForState] = React.useState<PoliceForState[]>([]);
    const [civilianForState, setCivilianForState] = React.useState<PoliceForState[]>([]);


    //RESTS POST 
    let stateCompareRequest: StateCompareRequest = { "states": [] };
    const [stateIds, setStateIds] = React.useState<number[]>([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL_EU + GET_ALL_STATES).then((response) => {
            setStates(response.data);
        });
    }, []);

    useEffect(() => {
        stateCompareRequest.states = stateIds;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_INFO, stateCompareRequest).then((response) => {
            setStatesInfo(response.data);
        });
    }, [stateIds]);

    useEffect(() => {
        stateCompareRequest.states = stateIds;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_POLICE_OFFICERS_LINK, stateCompareRequest).then((response) => {
            setPoliceForState(response.data);
        });
    }, [stateIds]);

    useEffect(() => {
        stateCompareRequest.states = stateIds;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_POLICE_CIVILIANS_LINK, stateCompareRequest).then((response) => {
            setCivilianForState(response.data);
        });
    }, [stateIds]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL_EU + GET_ALL_TYPE_OF_FIREARMS).then((response) => {
            setFirearms(response.data);
        });

    }, []);

    useEffect(() => {
        if (codes !== undefined && codes !== null && codes !== ":codes") {
            setStateIds([...(codes.split(',').map(Number)).filter(x => x !== null)]);
        }
    }, []);




    const [valueIndexOrCounts, setValueIndexOrCounts] = React.useState('1');
    const [valueIndexOrCounts1, setValueIndexOrCounts1] = React.useState('1');

    const [stateIndexesByCategory, setStateIndexesByCategory] = React.useState<StateCompareIndexForPolice[]>([]);
    const [stateCountsByCategory, setStateCountsByCategory] = React.useState<StateCompareCountForPolice[]>([]);


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL_EU + GET_ALL_STATES).then((response) => {
            setStates(response.data);
        });
    }, []);

    useEffect(() => {
        stateCompareRequest.states = stateIds;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_INFO, stateCompareRequest).then((response) => {
            setStatesInfo(response.data);
        });
    }, [stateIds]);

    useEffect(() => {
        stateCompareRequest.states = stateIds;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_POLICE_OFFICERS_LINK, stateCompareRequest).then((response) => {
            setPoliceForState(response.data);
        });
    }, [stateIds]);

    useEffect(() => {
        stateCompareRequest.states = stateIds;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_POLICE_CIVILIANS_LINK, stateCompareRequest).then((response) => {
            setCivilianForState(response.data);
        });
    }, [stateIds]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL_EU + GET_ALL_TYPE_OF_FIREARMS).then((response) => {
            setFirearms(response.data);
        });

    }, []);

    useEffect(() => {
        if (codes !== undefined && codes !== null && codes !== ":codes") {
            setStateIds([...(codes.split(',').map(Number)).filter(x => x !== null)]);
        }
    }, []);

    useEffect(() => {
        stateCompareRequest.states = stateIds;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_POLICE_INDEXES, stateCompareRequest).then((response) => {
            setStateIndexesByCategory(response.data);
        });
    }, [stateIds]);

    useEffect(() => {
        stateCompareRequest.states = stateIds;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_POLICE_COUNTS, stateCompareRequest).then((response) => {
            setStateCountsByCategory(response.data);
        });
    }, [stateIds]);

    const [value, setValue] = React.useState(2);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleStateChange = (event: SelectChangeEvent<typeof stateIds>) => {
        const {
            target: { value },
        } = event;
        setStateIds(value as number[]);
    };

   
    const findIncludeInState = (code: string, types: PoliceType[]) => {
        let find = types.find(el => el.code == code);

        if (find !== undefined) {
            return find.isIncluded;
        } else {
            return undefined;
        }

    };

    const handleChangeCountsOrIndex = (_event: React.SyntheticEvent, newValue: string) => {
        setValueIndexOrCounts(newValue);
    };

    const handleChangeCountsOrIndex1 = (_event: React.SyntheticEvent, newValue: string) => {
        setValueIndexOrCounts1(newValue);
    };

    const printResultOfWeapon = (type: Boolean | undefined) => {

        if (type === undefined) {
            return "";
        } else if (type === null) {
            return <FaMinusSquare className=" text-neutral" />
        } else {
            if (type) {
                return <FaCheckSquare className=" text-success" />
            } else {
                return <FaWindowClose className=" text-danger" />
            }
        }
    }

    function getState(x: number[], l: number = 3) {
        const filteredCourses = states.filter(state => x.slice(0, 3).some(number => number === state.id));
        const translated = filteredCourses.map(u => t(`states.${u.code}`));
        if (x.length > 3) {
            return translated.join(', ') + ",...";
        }
        return translated.join(', ');
    }




    const findMin = (arr: any[]) => {
        return arr.reduce((min, current) => (current < min ? current : min), Infinity);
    };
    const findMax = (arr: any[]) => {
        return arr.reduce((max, current) => (current > max ? current : max), -Infinity);
    };

    const mapped = statesInfo.map((state, index) => [state.code.toUpperCase(), state.name, (index % COLORS.length), '']);
    const data = [HEADER_STATE_COMPARE_LOCATION, ...mapped];


    const updatedData = stateIndexesByCategory.map((entry) => {
        const maxIndex = findMax(entry.data.map((s) => s.indexPoliceOfficers));
        const minIndex = findMin(entry.data.map((s) => s.indexPoliceOfficers));

        const indexes = entry.data.map((state, index) => ({
            ...state,
            min: state.indexPoliceOfficers === minIndex,
            max: state.indexPoliceOfficers === maxIndex,
        }));
        return { ...entry, statesIndexes: indexes };
    });

    const uniqueStateNames = Array.from(new Set(updatedData.flatMap(item => item.stateName)));



    //TABLE COLUMNS
    interface RowData {
        id: string;
        criminalOffence: string;
        [stateName: string]: string | number | DoubleRange;
    }


    const columns2: GridColDef[] = [
        { field: 'criminalOffence', headerName: 'Criminal Offence', align: 'left', headerAlign: 'left', width: 280 },
        ...uniqueStateNames.map(stateName => ({
            field: stateName,
            headerName: stateName,
            flex: 1,
            renderCell: (params: any) => {
                const propertyName = params.colDef.headerName;
                const value = params.row[propertyName];

                if (params.row.max === value) {
                    const val = Number(value.toFixed(2));

                    return <span className="box-danger"> {val} </span>;
                } else if (params.row.min === value) {
                    const val = Number(value.toFixed(2));

                    return <span className="box-success"> {val} </span>;
                } else {
                    const val = Number(value.toFixed(2));

                    return <span> {val} </span>;
                }
            }

        })),
    ];


    const rows2: RowData[] = updatedData.map(item => {

        const maxTrueObject = item.statesIndexes.find(state => state.max === true);
        const minTrueObject = item.statesIndexes.find(state => state.min === true);

        const row: RowData = { id: item.stateId.toString(), criminalOffence: "", className: '', min: minTrueObject?.indexPoliceOfficers ?? 0, max: maxTrueObject?.indexPoliceOfficers ?? 0 };
        /* item.statesIndexes.forEach(state => {
             row[state.] = state.indexPoliceOfficers;
         });*/

        return row;
    });

    return (
        <>
            <HeaderCarousel name={t("stateCompare.title")} image={image} />

            <div className='underLabel pb-2 '>
                {stateIds.length != 0 ? <button className='btn btn-secondary download-btn' onClick={() => generatePDF(targetRef, { filename: 'state-compare.pdf' })}>{t("stateCompare.downloadPDF")}</button> : <div></div>}
                <p>{t("stateCompare.subTitle")}</p>
            </div>
            <div className='d-inline w-100'>
                <FormControl sx={{ m: 1, width: 500 }}>
                    <InputLabel id="multiple-checkbox-label-states"><p>{t("stateCompare.states")}</p></InputLabel>
                    <Select
                        labelId="multiple-checkbox-label-states"
                        id="multiple-checkbox-states"
                        multiple
                        value={stateIds}
                        onChange={handleStateChange}
                        input={<OutlinedInput label="States" />}
                        renderValue={selected => getState(selected)}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                    width: 250,
                                },
                            }
                        }
                        }
                    >
                        {states.map((state) => (
                            <MenuItem key={state.name.toString()} value={state.id} >
                                <Checkbox checked={stateIds.indexOf(state.id) > -1} />
                                <ListItemText primary={state.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            {stateIds.length !== 0 ?

                <div>
                    <div>
                        <Box sx={{ width: '100%' }}>
                            <Tabs
                                onChange={handleChange}
                                value={value}
                                aria-label="Tabs where selection follows focus"
                                selectionFollowsFocus>
                                <Link to={LINK_STATE_COMAPRE_INFO + stateIds} ><Tab label={t(`stateInfo.information`)} ></Tab></Link>
                                <Link to={LINK_STATE_COMAPRE_CO + stateIds}><Tab label={t(`stateInfo.criminalOffences`)} ></Tab></Link>
                                <Link to={LINK_STATE_COMAPRE_POLICE + stateIds} className="text-primary "><Tab label={t(`stateInfo.police`)} className="font-weight-bold" ></Tab></Link>
                                <Link to={LINK_STATE_COMAPRE_CONVICTED + stateIds}><Tab label={t(`stateInfo.convictedCriminalOffences`)} ></Tab></Link>
                                <Link to={LINK_STATE_COMAPRE_PRISON + stateIds}><Tab label={t(`stateInfo.prison`)}></Tab></Link>

                            </Tabs>
                        </Box>
                    </div>

                    <div ref={targetRef}>

                        <div className="my-3 col-10">
                            <h5>{getState(stateIds, 10)}</h5>
                            <h3 className="mb-4">{policeForState !== undefined && policeForState.length > 0 ? policeForState[0].policeStaffName : ""}</h3>
                            <p className="mb-3">{policeForState !== undefined && policeForState.length > 0 ? policeForState[0].policeStaffDescription : ""}</p>
                            <table className="table table-striped mt-2">
                                <thead>
                                    <tr key={"weaponHeader"}>
                                        <th className=""> {policeForState !== undefined && policeForState.length > 0 ? policeForState[0].policeStaffName : ""} </th>
                                        {
                                            statesInfo.map((state) => {
                                                return (
                                                    <Tooltip title={state.name} placement="top">
                                                        <th key={state.id.toString()} className="text-center">{state.code}</th>
                                                    </Tooltip>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>



                                    {
                                        policeForState !== undefined && policeForState.length > 0 ?
                                            policeForState[0].policeTypes.map((policeType) => {

                                                return (
                                                    <tr>
                                                        <th className="" key={policeType.code.toString()}>{policeType.name}</th>

                                                        {
                                                            policeForState.map((state) => {

                                                                return (
                                                                    <th className="text-center" key={policeType.name.toString()}>
                                                                        {printResultOfWeapon(findIncludeInState(policeType.code, state.policeTypes))}
                                                                    </th>
                                                                )
                                                            })}
                                                    </tr>
                                                )
                                            })
                                            : <></>
                                    }

                                </tbody>
                            </table>
                        </div>


                        <div className="my-5 col-8 w-100 h-100">

                            <TabContext value={valueIndexOrCounts1}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChangeCountsOrIndex1} aria-label="lab API tabs example" className="revertTabs">
                                        <Tab icon={<FaChartLine />} value="1" />
                                        <Tab icon={<FaChartLine />} value="2" />

                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <h3 className="mb-5 trendLabel">{t("stateCompare.police.title2")}</h3>
                                    <div className="w-100 h-100">
                                        <LineChart
                                            width={800}
                                            height={300}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}>
                                            <CartesianGrid strokeDasharray="2 2" />
                                            <XAxis allowDuplicatedCategory={false} dataKey="year" />
                                            <YAxis />
                                            <TooltipChart />
                                            <Legend />
                                            {
                                                stateIndexesByCategory.map((state, index) => {
                                                    return (<Line key={state.stateId.toString()} name={state.stateName} data={state.data} dataKey="indexPoliceOfficers" stroke={COLORS[index % COLORS.length]} strokeWidth={2} activeDot={{ r: 5 }}></Line>)
                                                })
                                            }
                                        </LineChart>
                                    </div>

                                </TabPanel>
                                <TabPanel value="2">
                                    <h3 className="mb-5 trendLabel">{t("stateCompare.police.title3")}</h3>
                                    <div className="w-100 h-100">
                                        <LineChart
                                            width={800}
                                            height={300}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}>
                                            <CartesianGrid strokeDasharray="2 2" />
                                            <XAxis allowDuplicatedCategory={false} dataKey="year" />
                                            <YAxis />
                                            <TooltipChart />
                                            <Legend />
                                            {
                                                stateCountsByCategory.map((state, index) => {
                                                    return (<Line key={state.stateId.toString()} name={state.stateName} data={state.data} dataKey="countPoliceOfficers" stroke={COLORS[index % COLORS.length]} strokeWidth={2} activeDot={{ r: 5 }}></Line>)
                                                })
                                            }
                                        </LineChart>
                                    </div>
                                </TabPanel>
                            </TabContext>
                        </div>





                        <div className="my-5 col-10">
                            <h3 className="mb-4">{civilianForState !== undefined && civilianForState.length > 0 ? civilianForState[0].policeStaffName : ""}</h3>
                            <p className="mb-3">{civilianForState !== undefined && civilianForState.length > 0 ? civilianForState[0].policeStaffDescription : ""}</p>
                            <table className="table table-striped mt-2">
                                <thead>
                                    <tr key={"weaponHeader"}>
                                        <th className=""> {civilianForState !== undefined && civilianForState.length > 0 ? civilianForState[0].policeStaffName : ""} </th>
                                        {
                                            statesInfo.map((state) => {
                                                return (
                                                    <Tooltip title={state.name} placement="top">
                                                        <th key={state.id.toString()} className="text-center">{state.code}</th>
                                                    </Tooltip>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>



                                    {
                                        civilianForState !== undefined && civilianForState.length > 0 ?
                                            civilianForState[0].policeTypes.map((policeType) => {

                                                return (
                                                    <tr>
                                                        <th className="" key={policeType.code.toString()}>{policeType.name}</th>

                                                        {
                                                            civilianForState.map((state) => {

                                                                return (
                                                                    <th className="text-center" key={policeType.name.toString()}>
                                                                        {printResultOfWeapon(findIncludeInState(policeType.code, state.policeTypes))}
                                                                    </th>
                                                                )
                                                            })}
                                                    </tr>
                                                )
                                            })
                                            : <></>
                                    }

                                    {/* {
                                        civilianForState !== undefined && civilianForState.length > 0 ?
                                            civilianForState[0].policeTypes.map((policeType) => {

                                                return (
                                                    <tr>
                                                        <th className="" key={policeType.code.toString()}>{t("stateCompare.police.civilians")}</th>

                                                        {
                                                            civilianForState.map((state) => {

                                                                return (
                                                                    <th className="text-center" key={policeType.name.toString()}>
                                                                        {printResultOfWeapon(findIncludeInState(policeType.code, state.policeTypes))}
                                                                    </th>
                                                                )
                                                            })}
                                                    </tr>
                                                )
                                            })
                                            : <></>
                                    } */}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="my-5 col-8 w-100 h-100">
                        <TabContext value={valueIndexOrCounts}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChangeCountsOrIndex} aria-label="lab API tabs example" className="revertTabs">
                                    <Tab icon={<FaTable />} value="1" />
                                    <Tab icon={<FaTable />} value="2" />

                                </TabList>
                            </Box>
                            <TabPanel value="1">

                                <h3 className="mb-5 trendLabel">{t("stateCompare.police.title1")}</h3>
                                <div className="w-100 h-100">
                                    <LineChart
                                        width={800}
                                        height={300}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}>
                                        <CartesianGrid strokeDasharray="2 2" />
                                        <XAxis allowDuplicatedCategory={false} dataKey="year" />
                                        <YAxis />
                                        <TooltipChart />
                                        <Legend />
                                        {
                                            stateIndexesByCategory.map((state, index) => {
                                                return (<Line key={state.stateId.toString()} name={state.stateName} data={state.data} dataKey="indexOfCivilians" stroke={COLORS[index % COLORS.length]} strokeWidth={2} activeDot={{ r: 5 }}></Line>)
                                            })
                                        }
                                    </LineChart>
                                </div>

                            </TabPanel>
                            <TabPanel value="2">
                                <h3 className="mb-5 trendLabel">{t("stateCompare.police.title4")}</h3>
                                <div className="w-100 h-100">
                                    <LineChart
                                        width={800}
                                        height={300}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}>
                                        <CartesianGrid strokeDasharray="2 2" />
                                        <XAxis allowDuplicatedCategory={false} dataKey="year" />
                                        <YAxis />
                                        <TooltipChart />
                                        <Legend />
                                        {
                                            stateCountsByCategory.map((state, index) => {
                                                return (<Line key={state.stateId.toString()} name={state.stateName} data={state.data} dataKey="countOfCivilians" stroke={COLORS[index % COLORS.length]} strokeWidth={2} activeDot={{ r: 5 }}></Line>)
                                            })
                                        }
                                    </LineChart>
                                </div>
                            </TabPanel>
                        </TabContext>
                    </div>
                </div>




                :
                <div></div>
            }




        </>

    );
}
