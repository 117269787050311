import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { MdLocalPolice } from "react-icons/md";
import TDataset from "../../../components/DataAnalyzis/TrafficAccidents/TDataset";
import CustomModal from "../../../components/Modal/CustomModal";
import { Button } from "../../../components/StyleComponents/StyleComponents";
import { IDataset } from "../../../interfaces/traffic_accidents/InterfacesTraffic";
import NotificationService from "../../../services/NotificationService";
import image from '../../../assets/css/images/police.jpg';
import { useTranslation } from 'react-i18next';
import PageForm from "../../../components/PageHeader/PageForm";
import filterItemsTrafficPeds from '../../../interfaces/ConstFilterTrafficPeds';
import filterItemsTrafficAnimals from '../../../interfaces/ConstFilterTrafficAnimals';
import { ToggleButtonGroup } from '@mui/material'; 

export default function TAnalysis() {
  const { t, i18n } = useTranslation("crimes");
  const currentLanguage = i18n.language; 
  const [datasets, setDatasets]: [IDataset[], Function] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [newDatasetName, setNewDatasetName] = useState("");

  //toggleDN == true, ak sa zobrazuju zaznamy o Dopravnych Nehodach
  const [toggleDN, setToggleDN] = useState(false)
  const [toggleZV, setToggleZV] = useState(true)

  function handleClick() {
    if (toggleDN) {
      setToggleDN(!toggleDN)
    } 
    
    if (!toggleZV) {
        setToggleZV(!toggleZV)
    }
}

function handleZVClick() {
  if (toggleZV) {
    setToggleZV(!toggleZV)
  }

  if (!toggleDN) {
    setToggleDN(!toggleDN)
  }
}

useEffect(() => {
  setDatasets([
    {
      title: toggleDN ? "Dataset 2" : "Dataset 1",
      basicStatistic: {},
      filter: toggleDN ? filterItemsTrafficAnimals : filterItemsTrafficPeds,
      filterValues: [],
      expressionValues: [],
      graphValues: [],
    }
  ]);
}, [toggleDN]);

  useEffect(() => {
    if (showModal === true)
      setNewDatasetName("");
  }, [showModal]);

  const addDataset = () => {
    let tempDatasets = [...datasets];
    tempDatasets.push({title: newDatasetName, filter: filterItemsTrafficPeds, filterValues: [], expressionValues: [], graphValues: [] });
    setDatasets(tempDatasets);
    setShowModal(false);
    NotificationService.success(t("analyzis.succAdded"));
  };

  const addDatasetTZ = () => {
    let tempDatasets = [...datasets];
    tempDatasets.push({title: newDatasetName, filter: filterItemsTrafficAnimals, filterValues: [], expressionValues: [], graphValues: [] });
    setDatasets(tempDatasets);
    setShowModal(false);
    NotificationService.success(t("analyzis.succAdded"));
  };

  const setDataset = (idx: any, newDataset: IDataset) => {
    let tempDatasets = [...datasets];
    tempDatasets[idx] = newDataset;
    setDatasets(tempDatasets);
  };

  const deleteDataset = (idx: any) => {
    let tempDatasets = [...datasets];
    tempDatasets.splice(idx, 1);
    setDatasets(tempDatasets);
    NotificationService.success(t("analyzis.succDeleted"));
  };

  const duplicateDataset = (idx: any) => {
    let tempDatasets = [...datasets];
    tempDatasets.push(JSON.parse(JSON.stringify(datasets[idx])));
    setDatasets(tempDatasets);
    NotificationService.success(t("analyzis.succDuplicated"));
  };

  const renameDataset = (idx: any, newName: string) => {
    let tempDatasets = [...datasets];
    tempDatasets[idx].title = newName;
    setDatasets(tempDatasets);
    NotificationService.success(t("analyzis.succChangedName"));
  };

  const datasetsShow = datasets.map((dataset, idx) => (
    <TDataset key={idx} idx={idx} dataset={dataset} deleteDataset={() => deleteDataset(idx)} 
      duplicateDataset={() => duplicateDataset(idx)} renameDataset={renameDataset} setDataset={setDataset}/>
  ));
  

  return (
  <PageForm label={t("analyzis.pageTitle")} image={image}>
    <div className='box-12 text-center mb-6'>
        <ToggleButtonGroup>
            <Button style={{margin: '0px'}}>{t("Search.nadpisNehody")}</Button>
            <Button className={toggleDN ? 'main-button-peds' : ``} onClick={handleClick} style={{margin: '0px'}}>{t("Search.nadpisChodciOnly")}</Button>
            <Button className={toggleZV ? 'main-button-zver' : ``} onClick={handleZVClick} style={{margin: '0px'}}>{t("Search.nadpisZverOnly")}</Button>
        </ToggleButtonGroup>
    </div>

    <div className='box-12 text-center'>
      { datasets.length === 0 && <h3>{t("analyzis.infoCreate")}</h3> }
      {datasetsShow}
      <Grid sx={{ textAlign: "right" }}>
         <Button onClick={() => setShowModal(true)}>{t("analyzis.addButton")}</Button>
      </Grid>

      <CustomModal
        show={showModal}
        title={t("analyzis.insertNameNew")}
        width="500px"
        onClose={() => setShowModal(false)}
      >
        <Grid marginBottom={2} marginTop={2}>
          <input type="text" placeholder={t("analyzis.placeholderName") + ""} value={newDatasetName} onChange={(e) => setNewDatasetName(e.target.value)}/>
        </Grid>
        <Grid sx={{ textAlign: "right" }}>
          <Button onClick={() => setShowModal(false)}>{t("analyzis.closeButton")}</Button>
          {!toggleDN ? (
            <Button onClick={() => addDataset()}>{t("analyzis.addButtonShort")}</Button>
          ) : (
            <Button onClick={() => addDatasetTZ()}>{t("analyzis.addButtonShort")}</Button>
          )}
        </Grid>
      </CustomModal>
    </div>
  </PageForm>
  );
}