import { useTranslation } from 'react-i18next';
import PageForm from '../../../components/PageHeader/PageForm';
import image from '../../../assets/css/images/police.jpg';
import { MdLocalPolice } from "react-icons/md";
import { useEffect, useState } from "react";
import analyzisService from "../../../services/StatisticService";

import { Link } from "react-router-dom";
import { SearchFilter, SearchFilterValue } from "../../../interfaces/SearchFilter";
import { Grid, MenuItem, Select } from "@mui/material";
import { Sort } from "../../../interfaces/Sort";
import { GraphValue } from "../../../interfaces/Graphs";
import filterItems from './../../../interfaces/ConstFilter';
import DistrictStatistics from './DistrictStatistics';
import ShowBox from '../../../components/ShowBox';
import { Slider, Typography } from "@mui/material";
import { useRef } from "react";

/* // bratislavsky : Bratislava 1, Bratislava 2, Bratislava 3, Bratislava 4, Bratislava 5, Malacky, Pezinok, Senec
    // trnavsky : Dunajska Streda, Galanta, Hlohovec, Piešťany, Senica, Skalica, Trnava
    // trenciansky : Bánovce nad Bebravou, Ilava, Myjava, Nové Mesto nad Váhom, Partizánske, Považská Bystrica, Prievidza, Púchov, Trenčín
    // nitriansky : Komarno, Levice, Nitra, Nové Zámky, Šaľa, Topoľčany, Zlaté Moravce
    // zilinsky : Bytča, Čadca, Dolný Kubín, Kysucké Nové Mesto nn, Liptovský Mikuláš, Martin, Námestovo, Ružomberok, Turčianske Teplice, Tvrdošín, Žilina
    // banskobystricky : Banská Bystrica, Banská Štiavnica, Brezno, Detva, Krupina, Lučenec, Poltár, Revúca, Rimavská Sobota, Veľký Krtíš, Zvolen, Žarnovica, Žiar nad Hronom
    // presovsky : Bardejov 0, Humenné 1, Kežmarok 2, Levoča nn, Medzilaborce nn, Poprad, Prešov 4, Sabinov nn, Snina nn, Stará Ľubovňa 5, Stropkov nn, Svidník 6, Vranov nad Topľou 7
    // kosicky : Gelnica nn , Košice I, Košice II, Košice III, Košice IV, Košice-okolie 0, Michalovce 2, Rožňava 4, Sobrance 9, Spišská Nová Ves 4, Trebišov  5
    
    // Svg source: https://commons.wikimedia.org/wiki/File:Slovensko_okresy.svg
    */


export default function DistrictMap() {

  const currentYear = new Date().getFullYear();

  const { t } = useTranslation("crimes");
  const [from, setFrom] = useState(`${2007}-01-01`);
  const [to, setTo] = useState(`${currentYear}-12-31`);
  const [loading, setLoading] = useState(true);
  const [typeDate, setTypeDate] = useState('datum_podania');
  const [sumOfAllCrimes, setSumOfAllCrimes] = useState(0);
  const [countOfDistricts, setCountOfDistricts] = useState(0);
  const [meanOfCrimes, setMeanOfCrimes] = useState(0);
  const [districtWithLeastCrimes, setDistrictWithLeastCrimes] = useState("");
  const [districtWithMostCrimes, setDistrictWithMostCrimes] = useState("");
  const [medianOfCrimes, setMedianOfCrimes] = useState(0);
  const [stdOfCrimes, setStdOfCrimes] = useState(0);

  const [minYear, setMinYear] = useState<number>(2007);
  const [maxYear, setMaxYear] = useState<number>(currentYear);
  const [yearRange, setYearRange] = useState<number[]>([2007, currentYear]);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);


  const [crimesInRegionBratislavsky, setCrimesInRegionBratislavsky]: [any, Function] = useState([]);
  const [crimesInRegionTrnavsky, setCrimesInRegionTrnavsky]: [any, Function] = useState([]);
  const [crimesInRegionTrenciansky, setCrimesInRegionTrenciansky]: [any, Function] = useState([]);
  const [crimesInRegionNitriansky, setCrimesInRegionNitriansky]: [any, Function] = useState([]);
  const [crimesInRegionZilinsky, setCrimesInRegionZilinsky]: [any, Function] = useState([]);
  const [crimesInRegionBanskobystricky, setCrimesInRegionBanskobystricky]: [any, Function] = useState([]);
  const [crimesInRegionPresovsky, setCrimesInRegionPresovsky]: [any, Function] = useState([]);
  const [crimesInRegionKosicky, setCrimesInRegionKosicky]: [any, Function] = useState([]);

  const [checkedCategories, setCheckedCategories] = useState({
    legend_dis_1: true,
    legend_dis_2: true,
    legend_dis_3: true,
    legend_dis_4: true,
    legend_dis_5: true,
    legend_dis_6: true,
    legend_dis_7: true,
    legend_dis_8: true,
    legend10: true
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    setCheckedCategories(prev => ({
      ...prev,
      [id]: checked
    }));
  };

  const getLegendIdFromCrimePercentage = (crimePercentage: number): keyof typeof checkedCategories => {
    if (crimePercentage <= 0.5) return 'legend_dis_1';
    if (crimePercentage > 0.5 && crimePercentage <= 1) return 'legend_dis_2';
    if (crimePercentage > 1 && crimePercentage <= 1.5) return 'legend_dis_3';
    if (crimePercentage > 1.5 && crimePercentage <= 2) return 'legend_dis_4';
    if (crimePercentage > 2 && crimePercentage <= 2.5) return 'legend_dis_5';
    if (crimePercentage > 2.5 && crimePercentage <= 3) return 'legend_dis_6';
    if (crimePercentage > 3 && crimePercentage <= 4) return 'legend_dis_7';
    if (crimePercentage > 4) return 'legend_dis_8';
    
    return 'legend10';
  };

  useEffect(() => {
    refresh();
  }, [from, to, typeDate]);

  useEffect(() => {
    fetchDateRange();
  }, []);

  const fetchDateRange = async () => {
    try {
      const response = await analyzisService.getDateRange();
      const min = Number(response.data.minYear) || 2007;
      const max = Number(response.data.maxYear) || currentYear;

      setMinYear(min);
      setMaxYear(max);
      setYearRange([min, max]);
      setFrom(`${min}-01-01`);
      setTo(`${max}-12-31`);
    } catch (error) {
      console.error("Chyba pri načítaní rozsahu dátumov:", error);
    }
  };

  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setYearRange(newValue);
    }
  };

  // Keď používateľ pustí slider, aktualizujeme `from` a `to`
  const handleSliderCommit = () => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      setFrom(`${yearRange[0]}-01-01`);
      setTo(`${yearRange[1]}-12-31`);
    }, 300);
  };



  const refresh = async () => {
    setLoading(true);

    const regions: { region: number; items: any[] }[] = [];
    let sumOfAllCrimes = 0;
    let countOfDistricts = 0;
    let meanOfCrimesInRegions = 0;
    let districtWithLeastCrimes = "";
    let districtWithMostCrimes = "";
    let minCrimePercent = 100;
    let maxCrimePercent = -100;

    await Promise.all(
      Array.from({ length: 8 }, (_, i) => i + 1).map(async (i) => {
        try {
          const response = await analyzisService.getDistrictByCrimes(from, to, typeDate, i);
          let count = 0;
          response.data.data.forEach((item: any) => {
            count += Number(item.count);
            sumOfAllCrimes += Number(item.count);
          });
          regions.push({ region: i, items: response.data.data });
          countOfDistricts += response.data.data.length;
        } catch (error) {
          console.error(`Error fetching data for region ${i}:`, error);
        }
      })
    );

    setSumOfAllCrimes(sumOfAllCrimes);
    setCountOfDistricts(countOfDistricts);

    regions.forEach((region) => {
      region.items.forEach((item: any) => {
        item.crimePercent = (Number(item.count) / sumOfAllCrimes) * 100;
        // Set color based on crimePercent
        if (item.crimePercent <= 0.5) { item.color = "#006100"; }
        else if (item.crimePercent <= 1) { item.color = "#498a00"; }
        else if (item.crimePercent <= 1.5) { item.color = "#8bb500"; }
        else if (item.crimePercent <= 2) { item.color = "#d6e600"; }
        else if (item.crimePercent <= 2.5) { item.color = "#ffe500"; }
        else if (item.crimePercent <= 3) { item.color = "#ffa600"; }
        else if (item.crimePercent <= 4) { item.color = "#ff6f00"; }
        else { item.color = "#ff2200"; }
        // Log percentage to the console
        console.log(`Region ${region.region}, District ${item.district}: ${item.crimePercent.toFixed(2)}%`);

        // Update district with least crimes
        if (item.crimePercent < minCrimePercent) {
          minCrimePercent = item.crimePercent;
          districtWithLeastCrimes = item.district;
        }

        // Update district with most crimes
        if (item.crimePercent > maxCrimePercent) {
          maxCrimePercent = item.crimePercent;
          districtWithMostCrimes = item.district;
        }

      });

      setDistrictWithLeastCrimes(districtWithLeastCrimes);
      setDistrictWithMostCrimes(districtWithMostCrimes);

      // Mean of crimes
      meanOfCrimesInRegions = sumOfAllCrimes / countOfDistricts;
      setMeanOfCrimes(Number(meanOfCrimesInRegions.toFixed(0)));

      const crimeCounts = regions.flatMap(region => region.items.map(item => Number(item.count)));

      // Median
      crimeCounts.sort((a, b) => a - b);
      const middle = Math.floor(crimeCounts.length / 2);
      const median = crimeCounts.length % 2 !== 0 ? crimeCounts[middle] : (crimeCounts[middle - 1] + crimeCounts[middle]) / 2;
      setMedianOfCrimes(median);

      // Standard Deviation
      const mean = crimeCounts.reduce((acc, val) => acc + val, 0) / crimeCounts.length;
      const variance = crimeCounts.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / crimeCounts.length;
      const stdDeviation = Math.sqrt(variance);
      setStdOfCrimes(Number(stdDeviation.toFixed(0)));

      // Set data based on region
      switch (region.region) {
        case 1: setCrimesInRegionBratislavsky(region.items); break;
        case 3: setCrimesInRegionNitriansky(region.items); break;
        case 4: setCrimesInRegionTrenciansky(region.items); break;
        case 2: setCrimesInRegionTrnavsky(region.items); break;
        case 5: setCrimesInRegionZilinsky(region.items); break;
        case 7: setCrimesInRegionBanskobystricky(region.items); break;
        case 6: setCrimesInRegionPresovsky(region.items); break;
        case 8: setCrimesInRegionKosicky(region.items); break;
        default: break;
      }
    });

    setLoading(false);
  };

  const setTimeFrom = (e: any) => {
    setFrom(e.target.value);
  }

  const setTimeTo = (e: any) => {
    setTo(e.target.value);
  }

  const setDate = (e: any) => {
    setTypeDate(e.target.value);
  }

  const [filter] = useState<SearchFilter[]>(filterItems);
  const [filterValues] = useState<SearchFilterValue[]>(
    [
      {
        type: {
          value: "AND",
          label: "A súčastne"
        },
        value: [
          {
            type: {
              "value": "AND",
              "label": "A súčastne"
            },
            atribute: {
              value: "district_title",
              label: "Okres"
            },
            option: {
              value: "EXACT_TEXT",
              label: "obsahuje text"
            },
            value: [
              {
                label: "Bratislava",
                value: "Bratislava"
              }
            ]
          }
        ]
      }
    ]
  );
  const [sort] = useState<Sort>({ page: 1, perPage: 5, label: "", order: true });

  const [graphValues] = useState<GraphValue[]>([]);


  const dataToFile = {
    filter: filter,
    filterValues: filterValues,
    sort: sort,
    graphValues: graphValues
  };

  const saveDistrictToLocal = (okres: string) => {
    let tempData = dataToFile;
    tempData.filterValues[0].value[0].value[0].value = okres;
    tempData.filterValues[0].value[0].value[0].label = okres;
    const name = "Mapa okresov"
    window.sessionStorage.setItem('TCSearch' + name, JSON.stringify(dataToFile));
    const arr = JSON.parse(window.sessionStorage.getItem('TCSearch') || "[]");
    const exist = arr.find((item: any) => item.value === name);
    if (exist) {
      arr.splice(arr.indexOf(exist), 1);
    }
    arr.push({ label: name + " (" + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString() + ")", value: name });
    window.sessionStorage.setItem('TCSearch', JSON.stringify(arr));
  };


  return (

    <PageForm label={t("districtMap.pageTitle")} image={image}>
      {loading && <div className="loading">
        <div><MdLocalPolice /> {t("dashboard.loading")}</div>
      </div>}

      <Grid container spacing={2} paddingBottom={2}>
        <Grid item xs={12} md={4} mb={2}>
          <Select
            labelId="selectTypeDate"
            id="selectTypeDate"
            value={typeDate}
            onChange={(e) => setDate(e)}
            fullWidth
          >
            <MenuItem value={'datum_podania'}>{t("dashboard.datum_podania")}</MenuItem>
            <MenuItem value={'datum_zacatia'}>{t("dashboard.datum_zacatia")}</MenuItem>
            <MenuItem value={'datum_pokracovania_tc'}>{t("dashboard.datum_pokracovania_tc")}</MenuItem>
            <MenuItem value={'datum_ukoncenia'}>{t("dashboard.datum_ukoncenia")}</MenuItem>
            <MenuItem value={'datum_obvinenia'}>{t("dashboard.datum_obvinenia")}</MenuItem>
            <MenuItem value={'datum_rozsirenia'}>{t("dashboard.datum_rozsirenia")}</MenuItem>
            <MenuItem value={'datum_pokracovania_p'}>{t("dashboard.datum_pokracovania_p")}</MenuItem>
            <MenuItem value={'datum_prevzatia'}>{t("dashboard.datum_prevzatia")}</MenuItem>
            <MenuItem value={'datum_vratenia'}>{t("dashboard.datum_vratenia")}</MenuItem>
            <MenuItem value={'datum_ukoncenia_po_dos'}>{t("dashboard.datum_ukoncenia_po_dos")}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} md={4}>
          <input type="date" style={{ height: "56px", width: "100%" }} value={from} onChange={e => setTimeFrom(e)}></input>
        </Grid>
        <Grid item xs={12} md={4}>
          <input type="date" style={{ height: "56px", width: "100%" }} value={to} onChange={e => setTimeTo(e)}></input>
        </Grid>
        <Grid item xs={12} md={9}>
          <svg className="district-map" viewBox="0 0 1550 1200">
            <g>
              {/* Presovsky Medzilaborce */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Medzilaborce")}>
                <path
                  d="M1489.49,210.39l-0.07,0.1l-1.68,0.59c-0.42,0.15-0.73,0.51-0.81,0.95l-0.81,4.35
                      c-0.01,0.06-0.02,0.11-0.02,0.17l-0.1,2.15l-0.06,12.41l-0.22,1.08l-0.4,0.75l-0.65,0.57l-1.39,0.62c-0.54,0.24-0.84,0.82-0.71,1.4
                      l0.27,1.28c0.01,0.07,0.03,0.13,0.06,0.2l1.19,3.07l0.14,0.41l-2.35,0.88c-0.06,0.03-0.12,0.06-0.18,0.09l-1.41,0.79
                      c-0.12,0.07-0.24,0.16-0.33,0.26l-0.83,0.95l-1.41,0.3l-1.77-1.06c-0.24-0.15-0.51-0.21-0.79-0.17l-1.3,0.16
                      c-0.07,0.01-0.14,0.02-0.22,0.05l-1.43,0.45c-0.27,0.08-0.51,0.27-0.67,0.51l-0.78,1.2l-0.68,0.3l-1.57,0.13
                      c-0.45,0.04-0.85,0.32-1.04,0.73l-0.59,1.28c-0.06,0.14-0.1,0.3-0.11,0.45l-0.08,1.63l-0.3,2.62l-0.67,0.81l-1.11,0.47
                      c-0.24,0.1-0.44,0.28-0.58,0.5l-9.81,16.23l-0.8,0.86c-0.23,0.25-0.35,0.59-0.33,0.94l0.09,1.25c0.02,0.2,0.08,0.39,0.18,0.56
                      l0.64,1.05c0.04,0.06,0.08,0.12,0.13,0.17l0.49,0.56l-0.17,0.58l-0.79,0.9c-0.15,0.18-0.26,0.41-0.29,0.65l-0.19,1.35l-0.75,2.44
                      c-0.03,0.12-0.05,0.24-0.05,0.37l-0.01,2.83l-0.22,3.86l-6.24,2.85c-0.43,0.2-0.71,0.62-0.73,1.1l-0.04,1.05l-0.51,0.54l-0.94,0.53
                      c-0.23,0.13-0.42,0.33-0.53,0.58l-0.04,0.09c-0.06-0.06-0.13-0.12-0.2-0.16l-1.2-0.77c-0.29-0.19-0.64-0.25-0.97-0.16l-1.15,0.29
                      l-1.12,0.18l-0.61-0.04l-0.46-0.54c-0.36-0.41-0.94-0.54-1.44-0.33l-0.96,0.4l-1.03,0.05c-0.05,0-0.1,0.01-0.15,0.02l-0.9,0.15
                      l-0.83-0.42c-0.05-0.02-0.1-0.04-0.15-0.06l-0.94-0.33l-0.83-0.88c-0.21-0.23-0.5-0.37-0.8-0.4l-1.37-0.12
                      c-0.45-0.04-0.88,0.17-1.13,0.53l-0.42,0.59l-0.64,0.04c-0.22,0.02-0.42,0.08-0.6,0.2l-0.97,0.62l-2.23,0.26l-1.11-0.08l-1.2-0.28
                      c-0.21-0.05-0.43-0.04-0.64,0.02l-1.17,0.35l-1.12,0.28l-3.68,0.45c-0.08,0.01-0.17,0.02-0.25,0.05l-1.07,0.35l-0.15,0.02l-0.16-0.4
                      c-0.04-0.08-0.08-0.16-0.13-0.23l-0.47-0.7l0.22-0.59c0.01-0.05,0.03-0.09,0.04-0.14l0.33-1.34c0.07-0.28,0.04-0.58-0.08-0.84
                      l-0.54-1.13l-0.43-1.15l-0.31-1.22c-0.04-0.16-0.11-0.3-0.21-0.43l-0.79-1.07c-0.05-0.08-0.12-0.15-0.19-0.21l-0.7-0.61l-0.33-0.88
                      l-0.2-1.15c-0.02-0.11-0.05-0.22-0.1-0.32l-0.71-1.53c-0.02-0.04-0.04-0.08-0.07-0.12l-0.43-0.72l0.12-0.76
                      c0.02-0.1,0.02-0.2,0.01-0.3l-0.24-2.62c-0.01-0.1-0.03-0.19-0.06-0.28l-0.51-1.47l-0.34-1.13l-0.18-1.13
                      c-0.06-0.43-0.34-0.79-0.74-0.96l-1.42-0.61c-0.17-0.07-0.36-0.11-0.56-0.1l-0.88,0.05l-0.74-0.55c-0.18-0.13-0.4-0.21-0.63-0.23
                      l-2.63-0.22l-1.63-0.23c-0.11-0.01-0.22-0.01-0.33,0l-1.94,0.26h-0.01l-0.55-0.21l-0.84-0.73l-0.75-1.09
                      c-0.13-0.19-0.31-0.34-0.53-0.43l-1.16-0.51c-0.09-0.04-0.18-0.07-0.28-0.09l-1.59-0.28c-0.05-0.01-0.1-0.02-0.15-0.02l-1.36-0.07
                      l-1.24,0.03c-0.03,0-0.06,0-0.09,0.01l0.05-0.08c0.1-0.11,0.17-0.25,0.22-0.39l0.38-1.16c0.14-0.42,0.04-0.88-0.25-1.21l-0.82-0.94
                      c-0.03-0.03-0.05-0.06-0.08-0.09l-0.9-0.85c-0.06-0.06-0.13-0.11-0.2-0.15l-1.07-0.67c-0.11-0.07-0.23-0.12-0.36-0.15l-1.35-0.34
                      c-0.42-0.11-0.86,0.01-1.17,0.32c-0.31,0.3-0.44,0.74-0.35,1.17l0.12,0.52l-1.35,1.4c-0.26,0.27-0.38,0.64-0.33,1.02l0.13,0.94
                      l-0.05,0.06l-1.13-0.23l-1.26-0.39c-0.33-0.1-0.68-0.06-0.98,0.12c-0.3,0.17-0.52,0.46-0.6,0.79l-0.54,2.31l-0.41,0.36l-0.48,0.06
                      l-0.82-0.51l-1.82-1.74l-0.52-1.06c-0.3-0.59-1.01-0.85-1.62-0.59l-1.64,0.71l-0.34-0.57c-0.29-0.5-0.89-0.73-1.44-0.57l-1.34,0.4
                      c-0.33,0.1-0.6,0.33-0.76,0.64l-0.15,0.31l-0.44-0.46l-0.53-0.96c-0.03-0.06-0.08-0.12-0.12-0.18l-0.78-0.95
                      c-0.18-0.21-0.41-0.36-0.67-0.42l-1.15-0.29l-2.26-0.87c-0.16-0.06-0.33-0.1-0.51-0.09l-1.29,0.04c-0.14,0-0.28,0.03-0.42,0.09
                      l-1.02,0.4l-1.01,0.12l-0.7,0.02l-0.2-0.17l-0.1-0.85c-0.04-0.37-0.25-0.7-0.56-0.9l-0.68-0.44l-0.12-1.02l1.93-0.81
                      c0.4-0.18,0.68-0.55,0.74-0.99l0.1-0.75l0.58-0.41c0.05-0.04,0.1-0.09,0.15-0.13l1.69-1.69l0.9-0.29c0.14-0.05,0.27-0.13,0.39-0.22
                      l1.05-0.86c0.1-0.08,0.18-0.17,0.25-0.28l0.69-1.04c0.2-0.3,0.26-0.68,0.16-1.03l-0.62-2.24l-0.04-0.95l0.38-0.93
                      c0.02-0.05,0.03-0.1,0.05-0.15l0.32-1.19c0.03-0.13,0.05-0.28,0.04-0.42l-0.08-0.98l0.83-1.83l0.53-0.77l0.51-0.38l0.71-0.01
                      l0.94,0.21l2.06,1.1c0.3,0.16,0.65,0.2,0.98,0.09c0.32-0.1,0.59-0.33,0.74-0.64l0.47-0.96l1.86-0.88c0.13-0.06,0.24-0.14,0.34-0.24
                      l3.27-3.17l0.76,0.07c0.24,0.02,0.49-0.04,0.71-0.16l1.13-0.63c0.32-0.18,0.55-0.49,0.62-0.86c0.07-0.36-0.03-0.74-0.26-1.02
                      l-0.86-1.05c-0.07-0.08-0.14-0.15-0.22-0.21l-0.9-0.67l-0.69-0.78l-0.6-1.2l-0.62-1.09c-0.11-0.19-0.27-0.35-0.46-0.46l0.51-0.45
                      c0.4-0.37,0.52-0.95,0.3-1.45l-0.48-1.05l-0.22-2.2c-0.01-0.05-0.02-0.1-0.03-0.15l-0.21-1.04l0.16-0.65l0.62-0.6l1.15-0.94
                      l1.28-0.97c0.07-0.05,0.13-0.11,0.18-0.17l0.28-0.31l0.38,0.18c0.4,0.19,0.86,0.15,1.22-0.09l1.14-0.76l1.02-0.38
                      c0.16-0.06,0.31-0.15,0.43-0.28l1.36-1.33c0.17-0.16,0.29-0.37,0.34-0.59l0.4-1.63c0.05-0.2,0.05-0.41,0-0.61l-0.29-1.1l0.04-1.13
                      c0-0.15-0.02-0.3-0.07-0.44l-0.38-1.13l-0.33-1.31l-0.43-1.34c-0.02-0.07-0.05-0.14-0.08-0.2l-0.57-1.09
                      c-0.07-0.13-0.16-0.25-0.28-0.35l-0.98-0.88c-0.1-0.09-0.22-0.17-0.35-0.22l-1.33-0.56c-0.24-0.11-0.51-0.13-0.77-0.07l-1.04,0.25
                      l-0.57-0.18l-0.3-0.52l0.3-1.96l0.74-3.8c0.02-0.09,0.03-0.19,0.03-0.29l-0.03-1.29c0-0.07-0.01-0.14-0.02-0.21l-0.26-1.33
                      c-0.01-0.05-0.02-0.09-0.04-0.14l-0.4-1.27l-0.17-0.61l0.44-0.3c0.13-0.09,0.24-0.2,0.33-0.33l0.8-1.21l0.4-0.52l0.79,0.01
                      c0.19-0.02,0.46-0.06,0.66-0.18l2.41-1.5c0.37-0.24,0.59-0.65,0.58-1.1l-0.04-1.38c0-0.08-0.01-0.17-0.03-0.25l-0.3-1.23l0.18-2.23
                      l0.56-0.39c0.21-0.14,0.37-0.33,0.46-0.56l0.46-1.14c0.01-0.04,0.03-0.07,0.04-0.11l0.37-1.24c0.03-0.1,0.04-0.21,0.05-0.31
                      l0.03-0.9l0.49-0.74c0.14-0.21,0.21-0.46,0.2-0.72l-0.02-0.74l0.27-0.31l0.95-0.27c0.27-0.07,0.5-0.23,0.66-0.45l0.75-1.01
                      c0.11-0.15,0.18-0.31,0.22-0.49l0.29-1.34c0.01-0.05,0.02-0.1,0.02-0.15l0.07-0.72l0.71,0.03l0.64,0.58
                      c0.07,0.06,0.15,0.11,0.23,0.16l1.21,0.68c0.05,0.03,0.1,0.05,0.15,0.07l1.36,0.53l1.09,0.35l1.51,1.14l0.25,0.85
                      c0.13,0.43,0.46,0.75,0.89,0.86l1.22,0.31c0.36,0.09,0.75,0.02,1.05-0.2l1.09-0.8c0.04-0.03,0.08-0.06,0.11-0.09l0.76-0.69l1-0.4
                      c0.23-0.1,0.43-0.26,0.57-0.47l0.69-1.04l0.37-0.44l1.76,0.14l0.93,0.38l0.77,0.56l0.38,0.89l0.5,0.98l0.35,1.87l-0.39,0.44
                      l-0.94,0.78c-0.1,0.08-0.18,0.17-0.25,0.28l-0.67,1.03c-0.16,0.25-0.23,0.54-0.19,0.84l0.12,0.98l-0.28,0.93
                      c-0.16,0.52,0.05,1.09,0.5,1.4l1.11,0.75c0.3,0.2,0.67,0.26,1.02,0.17l0.76-0.2l0.66,0.35c0.16,0.09,0.34,0.15,0.53,0.16l1.37,0.08
                      c0.09,0,0.18,0,0.27-0.02l0.66-0.11l0.54,0.45c0.13,0.11,0.29,0.19,0.46,0.24l1.16,0.31l0.99,0.66c0.09,0.05,0.18,0.1,0.28,0.13
                      l1.21,0.42l1.18,0.56l1.27,0.52l1.06,0.58c0.14,0.07,0.29,0.12,0.45,0.14l3.89,0.45l0.84,0.37l0.72,0.76
                      c0.16,0.17,0.37,0.29,0.6,0.35l1.21,0.31c0.1,0.03,0.2,0.04,0.3,0.04l1.09,0.01l0.94,0.42c0.49,0.22,1.08,0.1,1.45-0.31l1.76-1.97
                      l0.76-0.92l0.62-0.58l0.87-0.16l1.34,0.02l1.17-0.03l2.7,0.32c0.06,0.01,0.12,0.01,0.19,0.01l1.32-0.06
                      c0.12-0.01,0.25-0.03,0.37-0.08l0.32-0.12l0.57,1.13c0.02,0.04,0.05,0.09,0.07,0.13l1.13,1.91l0.05,0.95
                      c0.02,0.38,0.21,0.73,0.52,0.95l1.05,0.76c0.06,0.04,0.12,0.08,0.18,0.11l0.94,0.46l0.38,0.38l-0.06,0.72c0,0.1,0,0.2,0.01,0.29
                      l0.27,1.72c0.07,0.41,0.33,0.76,0.7,0.94l0.81,0.39l0.5,0.86c0.09,0.17,0.22,0.31,0.38,0.41l0.79,0.54l1.15,1.94
                      c0.02,0.03,0.04,0.07,0.07,0.1l0.92,1.19c0.03,0.04,0.06,0.08,0.09,0.11l0.88,0.91c0.15,0.15,0.34,0.27,0.54,0.33l1.29,0.39
                      l1.39,0.69c0.23,0.13,0.51,0.17,0.76,0.12l1.38-0.23c0.2-0.03,0.39-0.11,0.54-0.23l0.75-0.55l0.85,0.02
                      c0.17,0.01,0.35-0.03,0.51-0.09l1.15-0.48l1.08,0.1c0.13,0,0.25,0,0.38-0.03l0.94-0.2l1.04,0.52c0.04,0.02,0.09,0.04,0.13,0.05
                      l1.1,0.4l0.67,0.6l0.76,0.91c0.03,0.03,0.05,0.06,0.08,0.09l1.02,1c0.12,0.12,0.26,0.21,0.41,0.27l1.08,0.43l0.89,0.55l1.03,0.9
                      c0.06,0.06,0.13,0.11,0.21,0.15l1.18,0.66c0.08,0.04,0.16,0.08,0.25,0.11l1.26,0.38c0.49,0.14,1.02-0.02,1.34-0.43l0.83-1.05
                      l0.69-0.64l0.87-0.29l1.1-0.19l2.69-0.17l1.16-0.17l1.32-0.05c0.15-0.01,0.3-0.04,0.44-0.09l0.53-0.22l0.35,0.23l0.9,0.94
                      c0.14,0.14,0.31,0.25,0.49,0.32l1.26,0.45l1.13,0.6c0.22,0.11,0.46,0.15,0.7,0.14l0.39-0.04L1489.49,210.39z"/>
                <title>Medzilaborce</title>
              </Link>

              {/* Zilinsky Kysucké Nové Mesto */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Kysucké Nové Mesto")}>
                <path
                  d="M543.15,130.14l-0.22,1.06c-0.01,0.05-0.01,0.11-0.02,0.16l-0.09,1.23v0.1l0.01,1.2l-0.18,1.11
                  c-0.02,0.13-0.02,0.27,0.01,0.41l0.18,1.04l-0.15,1.01l-0.21,0.68l-2.28,0.8c-0.14,0.05-0.27,0.13-0.39,0.22l-1.95,1.6
                  c-0.04,0.03-0.08,0.07-0.11,0.1l-0.9,0.96l-0.91,0.76l-0.86,0.59l-0.94,0.06c-0.04,0.01-0.09,0.01-0.13,0.02l-0.79,0.13l-0.5-0.46
                  c-0.37-0.34-0.91-0.43-1.36-0.22l-1.31,0.59c-0.07,0.03-0.13,0.06-0.18,0.1l-0.75,0.5l-0.3-0.05l-0.63-0.72
                  c-0.23-0.27-0.57-0.43-0.93-0.43c-0.16-0.01-0.29,0.02-0.43,0.07c-0.02-0.06-0.04-0.13-0.07-0.19l-0.53-1.11l-0.52-1.23l-0.58-1.14
                  c-0.17-0.34-0.49-0.58-0.86-0.66l-1.24-0.25c-0.2-0.03-0.4-0.03-0.59,0.03l-1.13,0.31l-0.77,0.11l-0.66-0.42
                  c-0.11-0.08-0.23-0.13-0.36-0.16l-0.33-0.08l0.01-0.35c0.02-0.55-0.33-1.05-0.85-1.22l-1.17-0.4c-0.09-0.04-0.19-0.06-0.29-0.07
                  l-1.24-0.1c-0.03-0.01-0.07-0.01-0.1-0.01l-1.46-0.01h-0.01c-0.42,0-0.82,0.21-1.05,0.57l-0.68,1.05c-0.1,0.15-0.16,0.32-0.19,0.5
                  l-0.18,1.22c-0.04,0.31,0.03,0.63,0.21,0.89l0.23,0.33l-1,1.39l-1.01,0.78c-0.07,0.06-0.14,0.13-0.19,0.2
                  c-0.13-0.15-0.28-0.27-0.46-0.35l-1.24-0.55l-0.82-0.5l-0.56-0.81c-0.05-0.08-0.11-0.15-0.17-0.21l-1.79-1.65l-0.09-0.16l0.22-0.24
                  c0.35-0.35,0.45-0.87,0.27-1.33l-0.37-0.95l-0.04-1.02c-0.03-0.63-0.52-1.14-1.14-1.2l-1.12-0.09l-0.78-0.44l-0.77-0.83
                  c-0.46-0.48-1.22-0.53-1.73-0.09l-1.08,0.93c-0.16,0.14-0.28,0.33-0.36,0.53l-0.37,1.05l-0.78,1.38l-0.34,0.37l-1.16-0.11
                  c-0.07-0.01-0.14-0.01-0.21-0.01l-1.59,0.12l-0.12-0.35c-0.06-0.19-0.17-0.35-0.31-0.49l-0.75-0.72l-0.36-0.85l-0.26-1.24
                  c-0.01-0.08-0.04-0.16-0.06-0.23l-0.48-1.16c-0.04-0.09-0.09-0.18-0.16-0.26l-0.44-0.6l0.13-0.68c0.07-0.36-0.03-0.73-0.26-1.02
                  l-0.54-0.67l-0.1-0.92c-0.02-0.25-0.12-0.49-0.28-0.68l-1.38-1.68l-0.03-0.81c-0.01-0.35-0.17-0.69-0.44-0.92l-0.84-0.7l-0.44-0.82
                  l-0.28-1.06c-0.02-0.06-0.04-0.12-0.06-0.17l-0.47-1.09l-0.23-1.16l-0.28-1.18l-0.23-1.11l0.01-1.11c0-0.41-0.19-0.79-0.52-1.02
                  l-0.72-0.52l-0.28-0.81l-0.21-1.12c-0.08-0.41-0.35-0.75-0.73-0.91l-1.31-0.58l-0.54-0.72l-0.47-1.14l-0.01-0.85l0.27-1.07
                  c0.06-0.24,0.05-0.48-0.03-0.71l-0.48-1.46c-0.04-0.11-0.09-0.21-0.16-0.3l-0.66-0.97c0.38-0.11,0.69-0.38,0.83-0.76l0.28-0.75
                  l0.62-0.21l0.96,0.06l1.32,0.21c0.31,0.05,0.62-0.01,0.88-0.18l1.14-0.74c0.16-0.11,0.3-0.25,0.4-0.42l0.79-1.36l0.65-0.9l0.67-0.16
                  l0.73,0.05l0.67,0.43l0.86,0.89c0.26,0.27,0.64,0.41,1.01,0.38l1.36-0.12c0.42-0.04,0.8-0.29,0.99-0.67l0.63-1.19
                  c0.09-0.15,0.13-0.32,0.15-0.49l0.1-1.42v-0.13l-0.03-0.91l0.85-0.6l1.09-0.51c0.35-0.17,0.6-0.49,0.68-0.87l0.28-1.31l0.37-0.71
                  l0.32-0.11l0.88,0.33l0.65,0.69c0.05,0.05,0.1,0.09,0.15,0.14l1.33,1c0.09,0.07,0.19,0.13,0.3,0.17l1.2,0.46
                  c0.12,0.04,0.24,0.07,0.36,0.08l0.29,0.02l0.1,0.51c0.06,0.29,0.23,0.56,0.47,0.74l0.64,0.49l0.2,0.95
                  c0.05,0.25,0.17,0.47,0.34,0.64l1.13,1.11c0.16,0.16,0.36,0.27,0.57,0.32l2.19,0.57l0.17,0.5l0.38,1.83
                  c0.03,0.16,0.09,0.31,0.18,0.45l0.76,1.13c0.07,0.1,0.16,0.19,0.25,0.27l1.1,0.88c0.39,0.32,0.94,0.37,1.39,0.12l1.04-0.57l1.8-0.69
                  l1.4-0.18l0.81,0.36c0.09,0.04,0.18,0.06,0.28,0.08l1.23,0.22c0.3,0.05,0.6,0,0.86-0.16l1-0.59l0.4-0.11l0.28,0.34
                  c0.17,0.2,0.39,0.35,0.65,0.42l2.25,0.64l2.33,1.2l0.23,0.16l-0.09,0.35c-0.15,0.57,0.12,1.16,0.65,1.43l1.3,0.65l0.95,0.84
                  c0.05,0.04,0.1,0.08,0.15,0.11l1.14,0.74c0.05,0.03,0.11,0.06,0.16,0.09l1.06,0.48l0.87,0.51l0.5,0.8l0.26,1.04l-0.23,1.01
                  l-0.49,1.24c-0.05,0.13-0.08,0.26-0.09,0.4l-0.06,1.29c-0.01,0.35,0.11,0.69,0.36,0.94l1.01,1.02c0.11,0.12,0.25,0.22,0.41,0.28
                  l1,0.43l-0.18,0.39c-0.08,0.17-0.12,0.35-0.12,0.54l0.01,1.03l-0.45,0.84c-0.04,0.08-0.07,0.16-0.09,0.24l-0.38,1.36
                  c-0.12,0.43-0.01,0.88,0.29,1.2l1,1.05c0.15,0.16,0.34,0.27,0.54,0.34l1.22,0.37l1.25,0.62c0.22,0.11,0.46,0.15,0.7,0.12l0.37-0.04
                  l0.12,0.26l0.34,1.24c0.13,0.46,0.51,0.81,0.97,0.9l0.9,0.17l0.3,0.27L543.15,130.14z"/>
                <title>Kysucké Nové Mesto</title>

              </Link>

              {/*Kosicky Sobrance */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Sobrance")}>
                <path
                  d="M1443.13,322.24l-0.23,0.59l-0.71,0.44c-0.21,0.13-0.38,0.33-0.48,0.56l-0.48,1.12l-0.68,1.28
                  c-0.1,0.18-0.15,0.37-0.15,0.57l-0.02,1.19l-0.41,0.95l-0.87,0.4l-1.1,0.58c-0.19,0.1-0.35,0.25-0.47,0.43l-1.37,2.11l-1.01,0.17
                  c-0.35,0.05-0.65,0.26-0.84,0.55l-1.21,1.88c-0.09,0.14-0.15,0.29-0.18,0.44l-0.29,1.5c-0.01,0.06-0.02,0.12-0.02,0.17l-0.11,2.15
                  l-0.41,1.32c-0.01,0.04-0.02,0.07-0.02,0.11l-0.26,1.21c-0.13,0.58,0.18,1.18,0.73,1.41l1.45,0.62l1.32,2.18
                  c0.09,0.14,0.21,0.26,0.35,0.36l0.95,0.65l-0.25,1.01c-0.05,0.16-0.05,0.34-0.02,0.51l0.29,1.75l-0.24,0.88l-0.96,0.65
                  c-0.1,0.07-0.2,0.16-0.27,0.26l-1.11,1.38c-0.37,0.45-0.37,1.1-0.01,1.56l0.3,0.38l-1.8,4.02c-0.08,0.16-0.11,0.34-0.11,0.52
                  l0.03,2.71l-1.34,4.88l-2.01,0.37l-1.76,0.08c-0.17,0.01-0.35,0.05-0.51,0.14l-3.37,1.79l-1.4,0.46c-0.41,0.14-0.72,0.48-0.82,0.9
                  l-0.18,0.78l-0.98,0.65l-1.16,0.49c-0.34,0.13-0.59,0.41-0.71,0.76l-0.3,0.91l-1.56,1.6l-5.32,3.91l-1.4,1.1l-1.1,0.3
                  c-0.52,0.15-0.89,0.61-0.92,1.15l-0.15,2.85l-2.14,4.17l-2.01,1.74l-3.57,0.35c-0.24,0.02-0.48,0.11-0.67,0.27l-1.54,1.25
                  c-0.11,0.09-0.2,0.19-0.27,0.31l-1.49,2.37l-0.56,0.35l-1.81-0.07h-0.04c-0.52,0-0.99,0.32-1.17,0.81l-0.47,1.24
                  c-0.01,0.03-0.02,0.06-0.03,0.09l-0.28,0.96l-2.47,3.28c-0.03,0.04-0.07,0.09-0.1,0.15l-1.6,2.91c-0.04,0.06-0.07,0.13-0.09,0.2
                  l-1.22,3.58v0.01l-1.4,0.54l-0.27-1.27l-0.16-1.24l0.11-1.08c0.03-0.29-0.04-0.58-0.2-0.83l-0.65-0.96l-0.25-1.02
                  c-0.09-0.36-0.34-0.67-0.68-0.83l-2.41-1.15c-0.41-0.21-0.91-0.16-1.28,0.11l-0.02,0.01l-0.21-2.25c-0.03-0.33-0.2-0.64-0.46-0.85
                  l-0.51-0.4l0.05-0.72l-0.01-1.81l0.07-2.87l0.05-0.98l0.51-0.76c0.2-0.28,0.27-0.64,0.19-0.98l-0.39-1.69
                  c-0.15-0.66-0.79-1.08-1.46-0.95l-1.14,0.23l-0.36,0.05l-0.35-1.35l0.25-0.91c0.1-0.38,0.02-0.78-0.22-1.09l-1.63-2.08
                  c-0.15-0.18-0.34-0.32-0.55-0.39l-0.82-0.3l-0.56-0.7l-0.37-0.89l-0.17-1.21c-0.07-0.48-0.4-0.87-0.86-1.02
                  c-0.45-0.15-0.95-0.02-1.28,0.33l-0.5,0.52l-0.15-0.01l-0.4-0.51c-0.16-0.21-0.38-0.36-0.64-0.44l-1.22-0.36
                  c-0.15-0.04-0.31-0.06-0.47-0.04l-0.53,0.05l0.08-0.42l0.81-1.2c0.13-0.21,0.21-0.45,0.21-0.69l0.01-0.85l0.55-0.36
                  c0.58-0.36,0.75-1.12,0.4-1.7l-0.6-0.98l-0.41-1.26c-0.12-0.35-0.39-0.64-0.74-0.77l-0.3-0.12l0.75-0.63l1.78-1.34
                  c0.41-0.31,0.59-0.83,0.46-1.32l-1.24-4.71c-0.15-0.55-0.64-0.93-1.21-0.93h-0.28l0.35-0.67c0.08-0.15,0.12-0.31,0.14-0.48l0.1-1.26
                  v-0.18l-0.1-1.57c-0.01-0.15-0.05-0.3-0.11-0.44l-0.57-1.24c-0.04-0.07-0.07-0.14-0.12-0.2l-0.59-0.84l-0.27-0.96l-0.21-1.16
                  c-0.01-0.04-0.02-0.08-0.03-0.12l-0.77-2.6c-0.02-0.09-0.06-0.17-0.11-0.25l-1.51-2.62l-0.28-1.05c-0.01-0.03-0.02-0.07-0.04-0.1
                  l-0.39-1.07l0.04-0.45l0.7-0.36l1.3-0.25c0.07-0.02,0.14-0.04,0.21-0.06l1.19-0.46l1.39,0.07c0.16,0.01,0.31-0.02,0.46-0.07
                  l1.69-0.57c0.1-0.03,0.19-0.07,0.28-0.13l1.18-0.77l1.24-0.47c0.63-0.24,0.95-0.95,0.73-1.58l-0.55-1.57
                  c-0.2-0.57-0.77-0.91-1.36-0.83l-2.89,0.42l-0.56,0.11l-0.02-0.03l0.02-0.87c0-0.05,0-0.1-0.01-0.16l-0.26-2.57l0.39-0.63l0.8-0.79
                  c0.05-0.06,0.11-0.12,0.15-0.18l0.7-1.02c0.05-0.07,0.09-0.15,0.12-0.22l0.45-1.07l0.86-1.07c0.4-0.51,0.35-1.24-0.12-1.69
                  l-0.54-0.51l0.25-1.69l0.7-0.48l1.21-0.48c0.19-0.07,0.37-0.2,0.5-0.36l0.9-1.08l0.95-0.86c0.42,0.46,1.13,0.54,1.65,0.16l1-0.72
                  c0.06-0.04,0.11-0.08,0.16-0.13l0.86-0.87l0.72-0.32l1.79-0.03l0.56,0.52c0.19,0.17,0.43,0.29,0.69,0.32l1.45,0.19
                  c0.53,0.07,1.03-0.2,1.27-0.67l0.5-0.98l0.72-0.81c0.21-0.25,0.33-0.57,0.31-0.9l-0.04-0.64l0.51-0.4c0.34-0.27,0.52-0.69,0.47-1.13
                  l-0.18-1.51c-0.01-0.12-0.04-0.23-0.09-0.34l-0.51-1.19c-0.03-0.07-0.07-0.14-0.11-0.2l-0.32-0.48l0.76-1.91l0.55-1.09
                  c0.02-0.05,0.04-0.1,0.06-0.16l0.5-1.46c0.07-0.2,0.09-0.41,0.05-0.62l-0.17-0.96l0.23-0.94l0.59-0.87
                  c0.03-0.06,0.07-0.11,0.09-0.17l0.54-1.16c0.04-0.08,0.07-0.16,0.08-0.24l0.6-2.27l0.44-1.24c0.06-0.17,0.08-0.35,0.07-0.52
                  l-0.1-1.14l0.09-1.24c0-0.1,0-0.21-0.02-0.31l-0.19-0.99l0.2-0.97c0.02-0.08,0.03-0.17,0.03-0.26l-0.01-1.32
                  c0-0.14-0.03-0.28-0.08-0.42l-0.44-1.21c-0.07-0.2-0.19-0.37-0.35-0.51l-0.71-0.62l-0.29-1.1l-0.4-1.17
                  c-0.01-0.06-0.04-0.11-0.06-0.16l-0.05-0.1c0.07-0.11,0.14-0.23,0.18-0.36l0.5-1.68c0.01-0.07,0.03-0.13,0.04-0.19l0.18-1.34
                  l0.31-0.64l0.94-0.5c0.07-0.03,0.13-0.07,0.19-0.12l1.18-0.93c0.19-0.15,0.33-0.35,0.41-0.58l0.29-0.87l0.93-0.8l1.22-0.76
                  c0.36-0.22,0.58-0.61,0.59-1.03l0.02-1l0.88-0.95c0.2-0.21,0.32-0.48,0.33-0.77l0.03-0.36l1.67-0.22l1.55,0.22l2.32,0.18l0.82,0.61
                  c0.23,0.17,0.5,0.26,0.8,0.24l1.04-0.06l0.52,0.22l0.08,0.54c0.01,0.06,0.02,0.11,0.04,0.17l0.38,1.26l0.48,1.39l0.21,2.26
                  l-0.18,1.07c-0.04,0.29,0.02,0.6,0.17,0.85l0.64,1.05l0.61,1.32l0.2,1.1c0.01,0.08,0.03,0.15,0.06,0.23l0.48,1.29
                  c0.07,0.19,0.19,0.37,0.35,0.51l0.84,0.72l0.57,0.77l0.27,1.04c0.01,0.04,0.02,0.08,0.04,0.12l0.47,1.27
                  c0.01,0.04,0.02,0.07,0.04,0.11l0.37,0.77l-0.21,0.84l-0.42,1.12c-0.14,0.38-0.09,0.8,0.13,1.14l0.77,1.13l0.48,1.19
                  c0.22,0.53,0.77,0.85,1.34,0.77l1.23-0.18c0.07-0.01,0.14-0.03,0.2-0.05l1.05-0.34l3.69-0.45c0.05-0.01,0.09-0.02,0.13-0.03
                  l1.24-0.31l0.91-0.27l0.96,0.23c0.07,0.01,0.13,0.02,0.19,0.03l1.32,0.1c0.08,0,0.16,0,0.24-0.01l2.66-0.31
                  c0.19-0.02,0.37-0.09,0.53-0.19l0.93-0.6l0.92-0.06c0.38-0.03,0.72-0.22,0.94-0.53l0.35-0.49l0.17,0.01l0.72,0.76
                  c0.13,0.15,0.31,0.26,0.5,0.33l1.15,0.4l1.11,0.57c0.24,0.12,0.51,0.15,0.77,0.11l1.24-0.21l1.18-0.05c0.14-0.01,0.29-0.04,0.42-0.1
                  l0.33-0.14l0.22,0.26c0.22,0.25,0.53,0.41,0.87,0.43l1.28,0.08c0.1,0,0.19,0,0.28-0.02l1.36-0.23l0.72-0.18l0.53,0.34l0.48,0.72
                  l0.19,0.46l-0.11,0.43c-0.01,0.04-0.02,0.09-0.03,0.14l-0.18,1.33c-0.01,0.05-0.01,0.1-0.01,0.15l-0.04,2.78l-0.21,1.82l-0.24,1.55
                  l-0.73,1.7c-0.08,0.19-0.12,0.4-0.1,0.61l0.13,1.26c0.01,0.12,0.04,0.25,0.09,0.36l0.68,1.64l0.54,1.1l0.77,2.74l0.26,2.7
                  l-0.12,1.15c-0.02,0.12-0.01,0.23,0,0.34L1443.13,322.24z"/>
                <title>Sobrance</title>
              </Link>

              {/* Presovsky Snina */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Snina")}>
                <path
                  d="M1369.87,163.23l-0.69,0.7c-0.14,0.15-0.25,0.32-0.31,0.52l-0.4,1.32c-0.06,0.21-0.07,0.44-0.01,0.66l0.3,1.22
                  l-0.27,1.17l-0.17,0.56l-0.61,0.2c-0.22,0.08-0.42,0.21-0.57,0.39l-1.04,1.24c-0.07,0.08-0.13,0.17-0.17,0.27l-0.59,1.27
                  c-0.03,0.07-0.06,0.14-0.08,0.21l-0.81,2.83l-0.68,1.23l-0.92,0.93c-0.21,0.22-0.34,0.5-0.36,0.81l-0.07,1.24
                  c-0.02,0.35,0.11,0.69,0.35,0.94l0.29,0.3l-0.68,0.92c-0.02,0.03-0.04,0.07-0.06,0.1l-0.45,0.75l-0.8,0.25
                  c-0.29,0.09-0.53,0.27-0.69,0.53l-0.65,1.05l-0.8,0.84l-0.69,0.52l-1.26,0.19c-0.15,0.03-0.3,0.08-0.44,0.16l-1.01,0.59l-1.03-0.06
                  c-0.28-0.01-0.57,0.07-0.8,0.23l-1.03,0.74l-2.6,1.01c-0.18,0.07-0.34,0.18-0.47,0.32l-0.78,0.83l-0.77,0.61l-0.73,0.17l-2.57-0.71
                  c-0.06-0.02-0.13-0.03-0.19-0.03l-1.47-0.15c-0.36-0.04-0.69,0.07-0.95,0.3l-0.49,0.42l-0.75-0.16c-0.3-0.06-0.61-0.02-0.87,0.13
                  l-0.7,0.38l-1.1-0.18l-1.21-0.52c-0.53-0.23-1.15-0.06-1.49,0.4l-0.74,0.99l-0.83,0.82c-0.26,0.25-0.4,0.6-0.38,0.96l0.07,1.26
                  c0.01,0.14,0.04,0.28,0.1,0.41l0.45,1.09l0.39,1.12l-0.51,2.86l-0.61,0.73c-0.13,0.16-0.22,0.35-0.27,0.55l-0.14,0.71l-0.39,0.14
                  l-1.12,0.13c-0.11,0.01-0.22,0.03-0.32,0.07l-0.55,0.21l-0.53-0.38c-0.06-0.04-0.12-0.07-0.19-0.11l-0.35-0.17l0.07-0.6
                  c0.07-0.57-0.26-1.11-0.8-1.32l-0.66-0.25l-0.24-0.83c-0.08-0.3-0.27-0.56-0.54-0.73l-1.26-0.79c-0.27-0.17-0.59-0.23-0.9-0.17
                  l-1.25,0.25l-1.6-0.23c-0.28-0.04-0.58,0.02-0.82,0.17l-1.15,0.69c-0.03,0.02-0.06,0.04-0.09,0.06l-1.26,0.91
                  c-0.16,0.11-0.29,0.26-0.38,0.43l-0.68,1.32l-0.45,0.95l-0.46,0.42l-0.32,0.02l-0.88-0.69l-0.91-1.34c-0.09-0.13-0.2-0.24-0.34-0.33
                  l-2.61-1.73l-0.58-0.9c-0.04-0.06-0.09-0.13-0.15-0.19l-1.02-1.05l-0.95-0.85c-0.1-0.1-0.22-0.17-0.36-0.23l-1.39-0.57
                  c-0.21-0.09-0.46-0.11-0.69-0.07l-1,0.18l-1.01-0.2l-0.75-0.44l-0.49-0.77l-0.47-1.04c-0.06-0.12-0.13-0.23-0.22-0.33l-0.89-0.96
                  c-0.09-0.09-0.18-0.17-0.29-0.23l-0.72-0.42l-0.37-0.82c-0.19-0.43-0.62-0.72-1.09-0.74l-0.87-0.03l-1.72-1.41l-0.82-0.58
                  l-0.44-0.72l-0.38-1.1c-0.01-0.04-0.02-0.07-0.04-0.11l-0.45-1.01l-0.22-1.16c-0.05-0.23-0.16-0.45-0.33-0.62l-0.14-0.15l0.13-0.17
                  c0.31-0.4,0.35-0.95,0.09-1.38l-0.46-0.82l0.05-1.08c0-0.08,0-0.16-0.01-0.24l-0.17-1.12l0.02-1.04l0.29-1.41
                  c0.03-0.18,0.03-0.36-0.01-0.54l-0.38-1.6c-0.03-0.1-0.07-0.2-0.12-0.3l-0.65-1.23l-0.99-2.32c-0.04-0.1-0.1-0.19-0.16-0.28
                  l-0.74-0.95l-0.28-1.03l-0.43-1.27l-0.3-0.78l-0.32,0.12l0.03-0.63c0-0.11-0.01-0.22-0.03-0.33l-0.35-1.49l1.4-0.26l1.19-0.06
                  c0.13-0.01,0.25-0.04,0.37-0.08l1.04-0.39l0.99,0.25c0.1,0.03,0.19,0.04,0.29,0.04l1.31,0.02c0.01,0,0.01,0,0.02,0
                  c0.61,0,1.13-0.44,1.23-1.04l0.28-1.66c0.01-0.04,0.01-0.08,0.02-0.11l0.1-1.35l0.48-0.1c0.54-0.11,0.95-0.58,0.99-1.14l0.09-1.33
                  v-0.1l-0.02-1.28l0.2-1.38l0.14-2.55l0.17-0.66l0.7-0.42c0.3-0.19,0.52-0.5,0.58-0.85l0.24-1.33c0.01-0.06,0.02-0.11,0.02-0.17
                  l0.05-1.25c0.01-0.35-0.12-0.69-0.37-0.93l-0.8-0.8l-0.26-0.43l0.22-0.64c0.06-0.19,0.09-0.39,0.06-0.58l-0.11-0.81l0.75-0.86
                  l0.32,0.14c0.18,0.08,0.37,0.11,0.57,0.1l1.24-0.08c0.34-0.02,0.66-0.19,0.88-0.45l0.85-1.02c0.1-0.12,0.18-0.26,0.23-0.41l0.4-1.21
                  c0.01-0.03,0.02-0.07,0.03-0.11l0.2-0.88l0.72-0.73l0.81-0.93c0.04-0.04,0.08-0.09,0.11-0.14l0.73-1.13l1.07-1.3l0.96-1.06
                  c0.07-0.08,0.13-0.17,0.18-0.27l0.65-1.26c0.07-0.14,0.12-0.29,0.13-0.45l0.13-1.3c0.03-0.24-0.02-0.49-0.14-0.71l-0.59-1.1
                  c-0.04-0.06-0.08-0.13-0.13-0.19l-0.87-1.06c-0.03-0.03-0.05-0.05-0.07-0.08l-0.9-0.93c-0.05-0.05-0.11-0.1-0.17-0.14l-1.23-0.9
                  c-0.04-0.03-0.08-0.05-0.12-0.08l-2.04-1.16l-0.89-2.23c-0.07-0.17-0.17-0.32-0.31-0.45l-0.92-0.85l-0.98-1.08
                  c-0.08-0.08-0.16-0.15-0.25-0.21l-0.99-0.64l-0.66-0.71l-0.54-0.86l-0.35-0.94l-0.24-1.32c-0.01-0.06-0.02-0.11-0.04-0.16
                  l-0.41-1.28c-0.06-0.2-0.18-0.39-0.34-0.53l-1.02-0.96l-1.05-0.86c-0.35-0.28-0.83-0.36-1.25-0.19l-1.77,0.7l-0.45,0.13l-0.2-0.23
                  l-0.49-0.84l-0.27-1.31c-0.02-0.11-0.05-0.22-0.1-0.31l-0.71-1.4l0.18-0.9c0.05-0.26,0.01-0.54-0.1-0.78l-0.56-1.17l-0.04,0.02
                  l0.12-0.22c0.1-0.17,0.15-0.37,0.16-0.56l0.05-1.35c0-0.18-0.02-0.35-0.09-0.5l-0.35-0.91l0.04-0.84l0.39-0.71l0.92-0.85
                  c0.2-0.18,0.34-0.42,0.39-0.68l0.2-1.05l0.72-0.7l0.36-0.4l0.75,0.14c0.25,0.05,0.52,0.01,0.75-0.1l1.32-0.61
                  c0.07-0.03,0.13-0.06,0.19-0.1l1.21-0.84c0.12-0.09,0.23-0.19,0.31-0.31l0.7-1l0.57-0.59l0.77-0.2l1.86-0.22l0.53,0.46l0.81,0.86
                  l0.44,0.62l-0.22,0.8c-0.06,0.21-0.07,0.43-0.02,0.64l0.31,1.23c0.03,0.16,0.1,0.31,0.2,0.44l0.85,1.15l0.68,1.16
                  c0.04,0.06,0.08,0.11,0.12,0.16l0.86,1.03c0.28,0.34,0.72,0.5,1.15,0.44l1.31-0.2c0.2-0.04,0.39-0.11,0.55-0.23l1.04-0.77l0.55-0.45
                  l0.88,0.11l2.04,1.19l0.42,0.87l-0.05,1.06c-0.01,0.18,0.02,0.37,0.1,0.54l0.53,1.27c0.05,0.13,0.12,0.25,0.22,0.35l0.88,0.98
                  c0.09,0.11,0.2,0.19,0.32,0.26l0.81,0.45l0.68,1.17c0.18,0.3,0.47,0.52,0.82,0.59c0.34,0.08,0.7,0,0.99-0.2l0.84-0.61l0.9-0.08
                  c0.06,0,0.12-0.01,0.18-0.02l0.96-0.23l0.69,0.28l0.7,0.71l0.77,0.92c0.08,0.1,0.18,0.18,0.28,0.25l1.04,0.67
                  c0.23,0.15,0.5,0.22,0.76,0.2l1.35-0.09c0.16-0.01,0.31-0.05,0.45-0.12l0.91-0.43l1.02,0.01h0.01c0.21,0,0.43-0.06,0.61-0.16
                  l0.87-0.49l0.84,0.02l0.73,0.21l0.3,0.44l0.16,1.06c0.03,0.16,0.08,0.3,0.16,0.44l1.49,2.6l0.46,1.15l0.54,1.12l0.37,1.06l0.26,1.03
                  l-0.06,1.19c0,0.07,0,0.14,0.01,0.21l0.17,1.42c0.02,0.22,0.11,0.43,0.24,0.6l0.75,0.99l0.16,0.93c0.05,0.32,0.23,0.61,0.49,0.8
                  l0.87,0.63l1.49,2.05c0.06,0.09,0.14,0.17,0.23,0.24l1.18,0.95l0.73,0.64l0.4,0.88c0.03,0.07,0.06,0.13,0.1,0.19l0.93,1.38
                  c0.03,0.03,0.05,0.07,0.08,0.1l1,1.21c0.13,0.16,0.3,0.28,0.49,0.36l1.14,0.46l2.19,1.08l0.93,0.83l0.77,0.82l0.4,0.74l0.02,0.96
                  l-0.26,2.61l-0.26,1.19c-0.04,0.19-0.04,0.38,0.01,0.57l0.31,1.26c0.07,0.31,0.26,0.57,0.53,0.75l1.03,0.66l0.43,0.33l-0.05,0.35
                  l-0.47,0.93c-0.06,0.1-0.1,0.22-0.12,0.33l-0.26,1.35c-0.02,0.12-0.03,0.24-0.02,0.36l0.09,0.82l-0.54,0.53
                  c-0.22,0.21-0.35,0.49-0.38,0.79l-0.1,1.3v0.16l0.07,1.42l-0.08,1.09l-0.52,0.93c-0.05,0.09-0.09,0.18-0.12,0.28l-0.35,1.29
                  c-0.12,0.47,0.03,0.96,0.39,1.28l1.09,0.94c0.04,0.03,0.08,0.06,0.12,0.08l1.06,0.72c0.07,0.05,0.15,0.1,0.23,0.13l1.09,0.44
                  l0.97,0.59l1.12,0.81c0.14,0.1,0.29,0.17,0.46,0.21l1.01,0.22l0.74,0.72c0.17,0.16,0.38,0.27,0.61,0.32l1.34,0.29l3.56,1.39
                  c0.07,0.03,0.14,0.05,0.21,0.07l1.18,0.23l1.31,0.39l1.28,0.32c0.06,0.02,0.12,0.03,0.18,0.03l1.38,0.13L1369.87,163.23z"/>
                <title>Snina</title>
              </Link>

              {/* Presovsky Stropkov */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Stropkov")}>
                <path
                  d="M1308.5,127.46l-0.07,0.68l-0.47,0.9l-0.88,0.99l-1.15,1.39c-0.03,0.04-0.06,0.08-0.09,0.12l-0.72,1.12
                  l-0.7,0.8l-0.92,0.93c-0.16,0.17-0.28,0.37-0.33,0.6l-0.26,1.16l-0.31,0.93l-0.36,0.43l-0.4,0.02l-0.88-0.38
                  c-0.51-0.22-1.1-0.08-1.45,0.34l-1.7,1.94c-0.25,0.28-0.36,0.65-0.31,1.01l0.15,1.08l-0.32,0.93c-0.13,0.36-0.08,0.75,0.12,1.08
                  l0.66,1.05c0.05,0.07,0.11,0.15,0.18,0.21l0.51,0.52l-0.03,0.61l-0.12,0.69l-0.66,0.4c-0.28,0.17-0.48,0.45-0.56,0.77l-0.32,1.28
                  c-0.02,0.08-0.03,0.16-0.04,0.25l-0.14,2.51l-0.2,1.39c-0.01,0.07-0.01,0.13-0.01,0.2l0.02,1.33l-0.02,0.33l-0.46,0.1
                  c-0.55,0.11-0.95,0.57-1,1.12l-0.17,2.23l-0.09,0.54l-1.32-0.31c-0.25-0.07-0.51-0.05-0.75,0.04l-1.23,0.46l-1.06,0.06
                  c-0.04,0-0.07,0.01-0.11,0.01l-1.29,0.19c-0.07,0.01-0.14,0.03-0.21,0.05l-1.45,0.47c-0.61,0.2-0.97,0.84-0.83,1.47l0.56,2.45
                  l-0.05,1.26c0,0.06,0,0.12,0.01,0.18v0.01c-0.3,0.02-0.58,0.13-0.79,0.34l-0.35,0.32l-0.29-0.18c-0.51-0.3-1.17-0.2-1.56,0.24
                  l-0.9,0.99c-0.04,0.04-0.07,0.08-0.1,0.12l-0.84,1.2c-0.05,0.06-0.08,0.13-0.11,0.2l-0.53,1.15c-0.02,0.03-0.04,0.07-0.05,0.1
                  l-0.48,1.36c-0.01,0.04-0.02,0.08-0.03,0.12l-0.43,1.74c-0.07,0.25-0.05,0.51,0.04,0.75l0.4,1.01l0.12,1.13
                  c0.01,0.07,0.02,0.14,0.04,0.21l0.27,0.96l-0.11,1.01l-0.33,1.19c-0.1,0.37-0.02,0.76,0.2,1.07l0.62,0.84l0.32,0.94l-0.16,0.85
                  l-0.08,0.3l-0.43,0.01c-0.04,0-0.08,0-0.12,0.01l-1.29,0.16c-0.46,0.06-0.85,0.36-1.01,0.79l-0.46,1.18
                  c-0.04,0.08-0.06,0.16-0.07,0.25l-0.14,0.82l-0.43,0.29l-0.98,0.14c-0.44,0.07-0.82,0.37-0.98,0.79l-0.46,1.2
                  c-0.05,0.15-0.08,0.3-0.08,0.45v1.06l-0.05,0.18l-0.66,0.32c-0.26,0.12-0.47,0.34-0.59,0.6l-0.08,0.18l-0.2-0.05
                  c-0.23-0.05-0.48-0.04-0.71,0.04l-1.2,0.43c-0.04,0.02-0.07,0.03-0.11,0.05l-1.22,0.57l-1.26,0.72c-0.36,0.21-0.6,0.58-0.63,1
                  l-0.1,1.33c0,0.1,0,0.2,0.02,0.3l0.24,1.38l0.31,1.21l0.23,1.28c0.03,0.14,0.08,0.28,0.15,0.4l1.07,1.81l-0.14,0.84
                  c-0.01,0.08-0.02,0.15-0.02,0.23l0.02,1.48c0,0.09,0.01,0.19,0.04,0.29l0.23,0.92l-0.26,0.6l-0.85,0.81l-3.16,3.37l-0.74,0.7
                  l-1.34,0.08c-0.29,0.02-0.56,0.14-0.78,0.34l-0.98,0.92l-1.03,0.82c-0.04,0.03-0.07,0.05-0.1,0.09l-0.84,0.81l-0.65,0.58l-1.17,0.08
                  c-0.11,0.01-0.21,0.03-0.32,0.07l-1.45,0.5c-0.33,0.11-0.59,0.36-0.73,0.68c-0.14,0.32-0.14,0.68,0,1l1.61,3.7l0.41,0.84l-0.51,0.62
                  l-0.85,0.75l-0.64,0.37l-0.72-0.32c-0.42-0.19-0.91-0.13-1.27,0.15l-1.1,0.85c-0.08,0.06-0.16,0.13-0.22,0.21l-0.01,0.02
                  c-0.03-0.03-0.06-0.05-0.09-0.07l-2.08-1.39l-0.49-0.82c-0.07-0.13-0.17-0.24-0.29-0.33l-0.78-0.63l-0.39-0.92
                  c-0.07-0.16-0.17-0.3-0.3-0.41l-2.12-2.05l-0.49-0.74l-0.27-1.07c-0.14-0.58-0.65-0.96-1.26-0.95l-0.79,0.03
                  c0.02-0.12,0.03-0.25,0.01-0.37l-0.17-1.4l-0.23-1.22c-0.02-0.1-0.05-0.2-0.09-0.29l-0.51-1.11c-0.06-0.12-0.14-0.24-0.23-0.33
                  l-0.5-0.54l0.02-0.72c0-0.06,0-0.11-0.01-0.17l-0.07-0.63l0.48-0.45c0.4-0.38,0.5-0.97,0.26-1.46l-0.57-1.16
                  c-0.05-0.11-0.12-0.21-0.2-0.29l-1.62-1.79l-0.68-1.16c-0.11-0.19-0.27-0.34-0.45-0.45l-1.07-0.62c-0.09-0.05-0.18-0.09-0.28-0.12
                  l-1.04-0.3l-0.54-0.74c-0.14-0.2-0.34-0.35-0.56-0.44l-0.64-0.24l-0.1-0.29l0.16-0.17l1.81-1.71l0.96-0.78
                  c0.2-0.17,0.35-0.39,0.42-0.65l0.67-2.64c0.02-0.09,0.03-0.19,0.03-0.28l0.01-1.41v-0.11l-0.1-1.13l0.08-1.18
                  c0.02-0.33-0.09-0.65-0.3-0.89l-0.89-1.04c-0.09-0.11-0.2-0.2-0.33-0.28l-0.92-0.52l-0.56-0.68l-0.5-1.01l-0.52-1.2
                  c-0.03-0.06-0.06-0.11-0.09-0.16l-0.69-1.1c-0.03-0.05-0.06-0.09-0.09-0.13l-0.84-1.03l-0.35-0.56l0.64-1.71
                  c0.02-0.05,0.03-0.09,0.04-0.13l0.29-1.03l0.5-0.78l0.77-0.83l0.95-0.57c0.36-0.21,0.59-0.59,0.61-1l0.06-1.15l0.2-0.96l0.52-0.97
                  c0.17-0.31,0.2-0.68,0.08-1.02l-0.23-0.61l0.08-0.14l0.73-0.23c0.19-0.05,0.36-0.15,0.5-0.29l0.92-0.89
                  c0.2-0.19,0.32-0.44,0.36-0.71l0.2-1.25c0.02-0.13,0.01-0.27-0.01-0.4l-0.18-1.05l0.15-1.1l0.12-1.7l0.15-1.31
                  c0.04-0.32-0.05-0.64-0.24-0.89l-0.7-0.93l-0.05-0.79l0.17-1.37l0.08-1.34c0.01-0.26-0.05-0.52-0.19-0.74l-0.45-0.71l0.12-0.74
                  c0.01-0.05,0.02-0.11,0.02-0.17l0.02-1.09l0.32-0.96l0.53-0.86l0.67-0.96l0.45-0.56l0.61,0.1c0.49,0.07,0.98-0.15,1.24-0.56
                  l0.76-1.18l0.82-0.69c0.03-0.03,0.06-0.05,0.09-0.08l0.88-0.89c0.26-0.26,0.39-0.62,0.36-0.99l-0.29-3.02
                  c0-0.04-0.01-0.08-0.01-0.12l-0.22-1.12l-0.06-0.91l0.3-0.81l0.65-0.93c0.2-0.28,0.27-0.62,0.21-0.95l-0.17-0.87l0.1-0.19l0.72-0.08
                  c0.29-0.02,0.56-0.15,0.76-0.36l1.12-1.12c0.07-0.06,0.12-0.12,0.17-0.2l0.79-1.21c0.16-0.24,0.23-0.54,0.19-0.83l-0.24-1.94
                  c-0.01-0.11-0.04-0.22-0.08-0.32l-0.99-2.61l-0.1-0.62l0.22-0.33l0.38-0.16l1.73,1.21c0.29,0.2,0.65,0.27,0.99,0.19l1.26-0.3
                  c0.04-0.01,0.07-0.02,0.1-0.03l1.5-0.5l1.16,0.01h0.01c0.45,0,0.87-0.24,1.09-0.64l0.65-1.16c0.04-0.07,0.07-0.14,0.1-0.22l0.5-1.51
                  l0.32-0.64l0.93-0.38c0.03-0.02,0.07-0.04,0.11-0.06l1.51-0.8l1.47-0.89c0.11-0.07,0.22-0.16,0.31-0.26l0.92-1.09
                  c0.29-0.35,0.37-0.82,0.21-1.24l-0.43-1.16c-0.03-0.09-0.07-0.17-0.12-0.24l-0.76-1.2l-0.31-0.93l0.13-0.84l0.75-0.89l0.85-0.9
                  l0.78-0.61l1.01-0.44l0.97,1.01c0.04,0.05,0.09,0.09,0.14,0.13l1.02,0.78l0.57,0.77l0.47,0.91l0.06,1.18
                  c0.02,0.24,0.1,0.47,0.24,0.67l0.83,1.13c0.03,0.03,0.05,0.07,0.08,0.1l0.75,0.83l0.46,1.14c0.03,0.07,0.06,0.14,0.1,0.2l0.68,1.1
                  l0.5,1.59c0.02,0.08,0.05,0.15,0.09,0.22l0.83,1.55l0.96,1.52l0.14,0.96l-0.36,0.9c-0.16,0.41-0.1,0.87,0.17,1.22
                  c0.27,0.35,0.7,0.53,1.14,0.48l1.24-0.15l1.13,0.05c0.08,0,0.16,0,0.24-0.01l1.16-0.18l1.46-0.05l1.39,0.03h0.03
                  c0.62,0,1.14-0.45,1.24-1.06l0.19-1.27c0.02-0.14,0.01-0.28-0.01-0.41l-0.72-3.94c-0.01-0.03-0.02-0.07-0.03-0.1l-0.34-1.19
                  c-0.06-0.21-0.17-0.4-0.33-0.55l-0.78-0.77l-1.09-2.48l-0.57-1.11l-0.36-0.94l-0.11-1.31c-0.01-0.09-0.03-0.17-0.06-0.26l-0.3-0.99
                  l0.06-1.13l-0.02-1.15l0.06-1.31l-0.02-0.82l0.43-0.62l1.67-1.69l0.93-0.78c0.46-0.39,0.57-1.05,0.27-1.58l-0.21-0.35l0.17-0.22
                  l0.8-0.7c0.05,0.11,0.12,0.2,0.2,0.29l0.84,0.92c0.15,0.18,0.36,0.3,0.58,0.36l1.25,0.35c0.06,0.02,0.12,0.03,0.19,0.04l1.36,0.17
                  c0.01,0,0.01,0,0.02,0l0.04,0.08l-0.18,0.91c-0.05,0.27-0.01,0.56,0.11,0.8l0.83,1.64l0.28,1.34c0.02,0.13,0.07,0.26,0.14,0.37
                  l0.64,1.11c0.04,0.07,0.08,0.13,0.13,0.19l0.8,0.94c0.32,0.38,0.83,0.53,1.3,0.39l1.29-0.38c0.04-0.01,0.08-0.02,0.11-0.04
                  l1.14-0.45l1.14,1.02l0.29,0.89l0.24,1.35c0.01,0.07,0.03,0.14,0.06,0.21l0.43,1.17c0.03,0.08,0.07,0.16,0.11,0.24l0.67,1.06
                  c0.04,0.06,0.09,0.12,0.14,0.18l0.85,0.92c0.07,0.08,0.16,0.14,0.24,0.2l0.99,0.63l0.91,1c0.02,0.03,0.04,0.05,0.07,0.08l0.75,0.7
                  l0.95,2.35c0.1,0.26,0.29,0.48,0.53,0.62l2.37,1.36l1.1,0.8l0.79,0.81l0.76,0.93L1308.5,127.46z"/>
                <title>Stropkov</title>
              </Link>

              {/* Presovsky Svidník */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Svidník")}>
                <path style={{
                  fill: crimesInRegionPresovsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionPresovsky[6].crimePercent)] ? crimesInRegionPresovsky[6].color : "#D3D3D3"
                }}
                  d="M1281.07,121.84l0.67,3.63l-0.35-0.01l-1.61,0.05c-0.05,0-0.1,0.01-0.15,0.01l-1.11,0.18l-0.78-0.04l-0.22-1.54
                  c-0.03-0.17-0.09-0.34-0.18-0.49l-1.06-1.67l-0.73-1.37l-0.51-1.62c-0.03-0.1-0.07-0.2-0.13-0.29l-0.71-1.15l-0.5-1.24
                  c-0.05-0.14-0.13-0.27-0.23-0.38l-0.86-0.95l-0.57-0.78l-0.06-1.08c-0.01-0.18-0.06-0.35-0.14-0.51l-0.64-1.23
                  c-0.03-0.06-0.07-0.11-0.1-0.16l-0.73-0.99c-0.07-0.1-0.16-0.18-0.25-0.25l-1.08-0.83l-1.51-1.57c-0.36-0.38-0.92-0.49-1.4-0.29
                  l-1.96,0.85c-0.1,0.05-0.19,0.1-0.27,0.17l-0.98,0.77c-0.05,0.04-0.1,0.08-0.14,0.13l-0.95,1.01l-1.02,1.2
                  c-0.15,0.18-0.25,0.39-0.29,0.62l-0.22,1.48c-0.04,0.19-0.02,0.39,0.04,0.58l0.45,1.37c0.03,0.1,0.08,0.19,0.14,0.28l0.77,1.22
                  l0.13,0.35l-0.31,0.38l-1.24,0.75l-1.39,0.74l-1.31,0.53c-0.28,0.12-0.5,0.33-0.64,0.6l-0.57,1.14c-0.02,0.05-0.05,0.11-0.07,0.17
                  l-0.49,1.48l-0.23,0.41h-0.63c-0.16,0-0.27,0.02-0.4,0.06l-1.64,0.55l-0.67,0.16l-1.74-1.26c-0.35-0.26-0.81-0.32-1.21-0.16
                  l-1.4,0.56c-0.24,0.09-0.44,0.26-0.58,0.47l-0.7,1.06c-0.18,0.26-0.24,0.58-0.2,0.88l0.19,1.22c0.02,0.08,0.04,0.16,0.06,0.23
                  l0.57,1.58l0.44,1.09l0.16,1.32l-0.47,0.72l-0.71,0.71l-0.97,0.1c-0.43,0.04-0.81,0.3-1,0.69l-0.58,1.19
                  c-0.12,0.24-0.16,0.52-0.11,0.78l0.14,0.76l-0.43,0.63c-0.06,0.09-0.11,0.18-0.15,0.28l-0.46,1.21c-0.06,0.17-0.09,0.35-0.08,0.53
                  l0.09,1.26c0.01,0.05,0.01,0.1,0.02,0.15l0.22,1.14l0.22,2.36l-0.43,0.44l-0.92,0.77c-0.1,0.08-0.18,0.18-0.25,0.28l-0.42,0.66
                  l-0.52-0.08c-0.44-0.07-0.89,0.11-1.16,0.46l-0.95,1.17l-0.77,1.09l-0.65,1.06c-0.05,0.08-0.09,0.17-0.12,0.26l-0.42,1.28
                  c-0.04,0.12-0.06,0.24-0.06,0.36l-0.03,1.18l-0.18,1.13c-0.05,0.3,0.01,0.61,0.17,0.87l0.49,0.77l-0.05,0.86l-0.17,1.4
                  c-0.01,0.07-0.01,0.14-0.01,0.22l0.07,1.28c0.02,0.25,0.1,0.49,0.25,0.68l0.63,0.84l-0.22,2.54l-0.16,1.22
                  c-0.02,0.12-0.01,0.25,0.01,0.37l0.17,1.03l-0.09,0.63l-0.4,0.39l-0.92,0.29c-0.3,0.09-0.56,0.29-0.72,0.57l-0.61,1.07
                  c-0.18,0.32-0.21,0.7-0.09,1.05l0.23,0.63l-0.34,0.64c-0.06,0.1-0.1,0.22-0.12,0.34l-0.25,1.23c-0.02,0.06-0.03,0.12-0.03,0.18
                  l-0.03,0.58l-0.53,0.32c-0.1,0.06-0.19,0.13-0.27,0.22l-0.97,1.04c-0.06,0.05-0.1,0.11-0.14,0.18l-0.66,1.03
                  c-0.07,0.1-0.12,0.21-0.15,0.33l-0.33,1.15l-0.47,1.19l-0.38,1.19c-0.11,0.35-0.06,0.73,0.13,1.05l0.7,1.11
                  c0.03,0.04,0.06,0.08,0.09,0.12l0.84,1.03l0.6,0.95l0.51,1.18l0.6,1.2c0.04,0.08,0.1,0.16,0.16,0.23l0.79,0.96
                  c0.09,0.12,0.21,0.22,0.34,0.3l0.93,0.53l0.42,0.49l-0.05,0.76v0.18l0.1,1.17l-0.01,1.21l-0.51,2.05l-0.7,0.57l-0.94,0.85l-0.91,0.9
                  l-1.25-1.76l-0.17-1c-0.07-0.45-0.38-0.83-0.81-0.98l-1.23-0.43c-0.38-0.13-0.81-0.07-1.14,0.17l-1.04,0.75
                  c-0.25,0.18-0.42,0.44-0.49,0.74l-0.39,1.77c-0.05,0.23-0.04,0.48,0.05,0.7l0.47,1.28c0.1,0.27,0.29,0.5,0.54,0.65l1.8,1.06
                  c-0.39,0.34-0.52,0.89-0.34,1.38l0.43,1.11l0.27,0.97v0.77l-0.43,0.86c-0.02,0.05-0.05,0.11-0.07,0.17l-0.4,1.2
                  c-0.05,0.16-0.07,0.33-0.05,0.51l0.12,1.33l0.17,1.21l-0.03,0.44l-0.67,0.39c-0.19,0.11-0.35,0.26-0.46,0.45l-0.16,0.28
                  c-0.39-0.24-0.89-0.25-1.29-0.02c-0.42,0.24-0.67,0.72-0.61,1.21l0.06,0.6l-0.14,0.13l-0.75-0.02c-0.33,0-0.67,0.12-0.91,0.37
                  l-0.9,0.9c-0.03,0.02-0.05,0.05-0.08,0.07l-0.64,0.77l-0.62,0.33l-0.99-0.11c-0.23-0.03-0.45,0.01-0.65,0.1l-0.94,0.42l-0.01-0.22
                  c-0.02-0.25-0.12-0.49-0.28-0.69l-0.79-0.97c-0.3-0.37-0.79-0.54-1.25-0.43l-0.85,0.2l-0.84-0.5l-1.01-0.7
                  c-0.07-0.04-0.14-0.08-0.21-0.12l-1.25-0.54c-0.04-0.01-0.07-0.03-0.11-0.04l-1.18-0.39c-0.04-0.01-0.09-0.02-0.13-0.03l-2.55-0.55
                  c-0.15-0.03-0.3-0.04-0.44-0.02l-1.51,0.21c-0.06,0.01-0.11,0.02-0.16,0.04l-2.32,0.62l-0.83-0.15l-0.92-0.49
                  c-0.07-0.04-0.15-0.07-0.23-0.09l-1.19-0.35c-0.06-0.02-0.13-0.03-0.2-0.04l-0.91-0.11l-0.24-0.21l0.03-0.62
                  c0-0.11-0.01-0.21-0.03-0.32l-0.21-0.99l0.3-0.9c0.19-0.54-0.02-1.14-0.5-1.45l-1.08-0.7c-0.17-0.11-0.37-0.18-0.58-0.2l-1.28-0.1
                  l-0.76-0.16l-0.57-0.58l-0.71-1.37c-0.1-0.19-0.25-0.35-0.43-0.47l-1.1-0.7c-0.13-0.09-0.28-0.15-0.44-0.18l-0.72-0.13l-0.51-0.73
                  c-0.15-0.2-0.36-0.36-0.59-0.44l-1.33-0.49l-1.25-0.54l-5.69-2.08l-0.01-0.06l1.48-1.23c0.26-0.2,0.42-0.5,0.45-0.83l0.11-0.95
                  l0.32-0.25l1.18-0.18l1.3-0.29l1.31,0.15l1.29,0.22c0.12,0.02,0.24,0.03,0.37,0.01l1.41-0.18c0.13-0.02,0.26-0.06,0.39-0.12
                  l1.07-0.52l1.42-0.6c0.07-0.03,0.15-0.07,0.22-0.12l1.01-0.69l1.15-0.6c0.29-0.15,0.5-0.39,0.61-0.69l0.41-1.16
                  c0.09-0.27,0.09-0.56,0-0.83l-0.07-0.21l0.18-0.1c0.07-0.04,0.13-0.09,0.2-0.14l1.07-0.95l0.91-0.94c0.14-0.15,0.25-0.34,0.31-0.54
                  l0.3-1.12l0.23-0.53l0.61-0.19c0.09-0.03,0.19-0.07,0.28-0.12l0.78-0.47l0.97,0.92c0.02,0.03,0.05,0.06,0.09,0.08l1.97,1.55
                  l0.95,0.91c0.24,0.23,0.55,0.35,0.87,0.35c0.03,0.14,0.07,0.28,0.14,0.41l0.62,1.13c0.07,0.12,0.16,0.24,0.26,0.33l0.73,0.66
                  l0.22,0.73l0.12,1.54l-0.02,1.19c-0.01,0.46,0.23,0.88,0.63,1.1c0.39,0.23,0.88,0.23,1.27-0.01l1.52-0.92l2.44-1.06l0.76-0.18
                  l0.51,0.18l1.07,1.82c0.17,0.3,0.46,0.51,0.8,0.58c0.29,0.06,0.58,0.03,0.84-0.11c0.03,0,0.06-0.01,0.09-0.01
                  c0.37-0.07,0.69-0.3,0.86-0.62l0.21-0.15c0.12-0.07,0.22-0.17,0.3-0.28l0.55-0.74l0.94-0.44c0.23-0.11,0.43-0.29,0.56-0.52
                  l0.66-1.17c0.26-0.46,0.2-1.04-0.16-1.44c-0.35-0.4-0.93-0.53-1.42-0.32l-0.36,0.15l-0.25-0.29c-0.2-0.24-0.48-0.39-0.77-0.43
                  c0.23-0.14,0.41-0.36,0.51-0.62l0.44-1.16c0.18-0.46,0.06-0.99-0.3-1.33l-0.61-0.61l-0.18-0.83c-0.08-0.43-0.39-0.78-0.79-0.92
                  l-1.18-0.43c-0.04-0.02-0.08-0.03-0.13-0.04l-1.05-0.26l-0.8-0.66l-0.8-0.83c-0.12-0.13-0.26-0.23-0.43-0.3l-1.71-0.69l-0.02-0.13
                  l0.39-0.68c0.08-0.13,0.13-0.28,0.15-0.42l0.25-1.45l0.46-1.01c0.16-0.35,0.15-0.76-0.03-1.1c-0.18-0.34-0.51-0.58-0.89-0.65
                  l-1.23-0.22c-0.48-0.08-0.97,0.12-1.25,0.52l-0.31,0.45l-0.27-0.02l-0.86-0.54l-1.01-0.54l-0.72-0.52l-0.24-0.63l-0.01-0.68
                  l0.49-0.83c0.18-0.31,0.22-0.68,0.11-1.03l-0.36-1.06l-0.1-1.04l-0.02-1.02l0.36-0.9l0.41-0.67l0.86-0.46l0.77-0.17l0.94,0.23
                  c0.06,0.02,0.11,0.03,0.17,0.03l1.45,0.15c0.38,0.05,0.77-0.1,1.03-0.38l0.72-0.75l0.93-0.46c0.07-0.04,0.13-0.07,0.19-0.12
                  l1.1-0.82c0.1-0.08,0.2-0.17,0.27-0.28l0.81-1.15c0.1-0.14,0.17-0.29,0.2-0.45l0.26-1.18l0.36-1.13c0.11-0.34,0.07-0.71-0.11-1.01
                  l-0.62-1.05c-0.14-0.24-0.36-0.43-0.62-0.53l-0.45-0.18l-0.1-2.32l-0.01-1.58c0-0.11-0.02-0.23-0.05-0.34l-0.35-1.19
                  c-0.05-0.16-0.12-0.3-0.22-0.42l-0.84-1.07l-0.86-0.93l-0.4-1.03c-0.1-0.25-0.28-0.47-0.5-0.61l-0.99-0.62l-0.5-0.62l0.01-0.38
                  l0.59-0.78c0.12-0.16,0.2-0.34,0.23-0.54l0.19-1.09l0.46-1.09c0.05-0.11,0.08-0.22,0.09-0.34l0.17-1.41c0.02-0.19,0-0.39-0.06-0.57
                  l-0.41-1.16c-0.01-0.03-0.03-0.06-0.04-0.1l-0.55-1.21c-0.12-0.25-0.31-0.45-0.55-0.58l-1.09-0.58c-0.18-0.1-0.37-0.15-0.57-0.15
                  l-0.62-0.01l-0.29-0.4l-0.43-1.08c-0.05-0.12-0.11-0.24-0.2-0.34l-0.86-1.04c-0.05-0.06-0.1-0.11-0.16-0.16l-0.97-0.81
                  c-0.35-0.29-0.82-0.37-1.24-0.21l-1.07,0.41l-0.91,0.18l-0.47-0.13l-0.4-0.61l-0.62-1.36c-0.07-0.16-0.17-0.29-0.29-0.4l-0.52-0.49
                  v-0.71c0-0.2-0.05-0.4-0.14-0.58l-0.4-0.76l0.1-0.99l0.04-1.55c0-0.14-0.02-0.29-0.06-0.43l-0.39-1.13l-0.26-1.14
                  c-0.11-0.45-0.45-0.81-0.89-0.93l-1.19-0.32c-0.37-0.1-0.76-0.02-1.07,0.2l-0.93,0.69l-0.12,0.04l-1.23-1.58
                  c-0.04-0.05-0.08-0.1-0.12-0.14l-0.97-0.92c-0.06-0.06-0.13-0.11-0.2-0.15l-1.31-0.81c-0.09-0.06-0.18-0.1-0.28-0.13l-1.51-0.49
                  l-1.02-0.72c-0.08-0.06-0.16-0.1-0.25-0.14l-2.37-0.96l-0.03-0.02l0.72-0.42l0.89-0.41l0.98-0.04c0.36-0.02,0.69-0.18,0.92-0.46
                  l0.78-0.96c0.08-0.1,0.14-0.2,0.18-0.31l0.49-1.18l0.76-0.53c0.12-0.08,0.23-0.19,0.31-0.31l0.84-1.22c0.12-0.18,0.2-0.39,0.22-0.61
                  l0.1-1.3c0.04-0.58-0.31-1.1-0.85-1.29l-1.23-0.41l-1.2-0.61c-0.04-0.03-0.09-0.05-0.14-0.06l-0.98-0.36l-0.95-0.92l-1.14-0.95
                  c-0.17-0.14-0.38-0.24-0.6-0.28l-1.12-0.17l-0.92-0.61c-0.1-0.07-0.22-0.12-0.34-0.16l-1.14-0.33l-1.17-0.48l-0.92-0.51l-0.72-0.78
                  c-0.08-0.08-0.16-0.15-0.25-0.2l0.17-0.28l0.49,0.04c0.25,0.03,0.49-0.02,0.71-0.14l0.7-0.37l1.96,0.53
                  c0.3,0.09,0.63,0.06,0.91-0.09l1.2-0.62c0.07-0.04,0.14-0.08,0.21-0.13l0.91-0.73l2.18-0.07c0.45-0.02,0.85-0.27,1.06-0.66
                  l0.72-1.35c0.04-0.07,0.07-0.14,0.09-0.21l0.34-1.05l0.69-0.76c0.44-0.47,0.44-1.2,0-1.68l-0.41-0.46l0.1-0.19l1.17-0.66l1.11-0.71
                  c0.08-0.04,0.14-0.1,0.21-0.16l1.08-1.06c0.04-0.04,0.08-0.09,0.12-0.14l0.86-1.15l0.75-1.1c0.08-0.12,0.14-0.25,0.18-0.39
                  l0.37-1.45l0.41-1.24c0.01-0.04,0.02-0.08,0.03-0.12l0.27-1.24l0.25-0.5l0.67-0.25c0.11-0.04,0.21-0.1,0.3-0.16l0.84-0.62l0.65,0.09
                  l1.06,0.48c0.13,0.06,0.27,0.09,0.41,0.11l1.28,0.1c0.11,0.01,0.23,0,0.35-0.03l1.57-0.32c0.14-0.02,0.28-0.08,0.4-0.15l1.06-0.65
                  c0.25-0.15,0.43-0.38,0.53-0.65l0.42-1.2l0.65-1.14c0.06-0.09,0.1-0.19,0.12-0.29l0.36-1.29l0.42-0.51l0.52-0.06l0.62,0.45
                  l0.82,0.85c0.04,0.05,0.09,0.1,0.15,0.14l0.93,0.7l0.84,0.83c0.02,0.03,0.05,0.06,0.08,0.08l1.16,0.97
                  c0.24,0.21,0.56,0.31,0.89,0.29l1.45-0.1c0.63-0.05,1.13-0.56,1.16-1.19l0.07-1.42c0-0.12-0.01-0.25-0.04-0.37l-0.14-0.54l0.53-0.57
                  c0.14-0.14,0.24-0.32,0.29-0.51l0.26-0.9l0.57-0.61l0.64-0.42l0.74,0.05l3.75,0.73c0.41,0.08,0.84-0.05,1.13-0.36l0.88-0.9
                  c0.09-0.08,0.16-0.18,0.21-0.28l0.47-0.88l0.65-0.55l0.44-0.1l0.45,0.27l0.62,0.88c0.15,0.21,0.36,0.37,0.6,0.46l1.12,0.4l1.48,0.61
                  c0.1,0.04,0.2,0.06,0.31,0.08l1.35,0.19c0.21,0.03,0.44,0,0.64-0.08l1.27-0.52c0.1-0.04,0.19-0.1,0.28-0.16l0.43-0.33l0.46,0.27
                  c0.46,0.27,1.05,0.21,1.45-0.14l0.97-0.85c0.13-0.12,0.24-0.26,0.31-0.42l0.43-0.92l1.69-1.47l0.97-0.07
                  c0.11-0.01,0.22-0.04,0.33-0.08l1.14-0.42l0.29-0.06l0.83,1.46l0.58,1.28c0.05,0.1,0.1,0.19,0.17,0.27l0.67,0.83l0.47,1.04
                  c0.07,0.17,0.18,0.32,0.32,0.44l1,0.87c0.06,0.05,0.12,0.1,0.19,0.14l0.88,0.51l0.55,0.73l0.36,0.91l0.19,1.31
                  c0.02,0.12,0.06,0.25,0.11,0.36l0.55,1.15c0.12,0.25,0.33,0.46,0.58,0.58l1.15,0.56c0.15,0.08,0.32,0.12,0.49,0.13l1.13,0.05
                  l0.98,0.37l2.12,1.23c0.4,0.23,0.9,0.23,1.29-0.01l1.29-0.79c0.06-0.04,0.11-0.08,0.16-0.12l0.91-0.79l0.93-0.68l0.61-0.32
                  l1.62,0.99c0.06,0.04,0.13,0.07,0.2,0.1l1.32,0.55c0.07,0.03,0.14,0.06,0.21,0.07l1.19,0.26l0.41,0.19l0.16,0.83
                  c0.01,0.03,0.02,0.06,0.03,0.1l0.31,1.13l-0.03,1.16c-0.01,0.17,0.02,0.33,0.08,0.49l0.51,1.32c0.24,0.6,0.89,0.93,1.51,0.75
                  l0.73-0.21l0.44,0.36l0.57,0.94c0.06,0.1,0.14,0.2,0.24,0.28l1.13,1.01c0.08,0.08,0.18,0.14,0.28,0.19l1.02,0.5l0.94,0.92
                  c0.14,0.13,0.31,0.23,0.49,0.29l1.15,0.37l0.76,0.51l0.54,0.72l0.05,0.69l-0.42,0.97c-0.14,0.31-0.15,0.66-0.01,0.98l0.54,1.32
                  l0.16,1.18c0.02,0.12,0.05,0.24,0.1,0.35l0.56,1.22c0.03,0.07,0.07,0.13,0.12,0.19l-1.24,1.08c-0.06,0.05-0.12,0.11-0.16,0.17
                  l-0.76,0.97c-0.32,0.4-0.36,0.95-0.1,1.4l0.1,0.18l-0.17,0.15c-0.03,0.02-0.06,0.05-0.09,0.08l-1.75,1.77
                  c-0.06,0.05-0.11,0.11-0.16,0.18l-0.73,1.05c-0.15,0.21-0.23,0.47-0.22,0.74l0.03,1.14l-0.06,1.3l0.02,1.14l-0.07,1.27
                  c-0.01,0.15,0.01,0.3,0.06,0.44l0.33,1.07l0.12,1.35c0,0.12,0.03,0.23,0.07,0.34l0.45,1.17c0.02,0.04,0.04,0.08,0.06,0.12l0.56,1.09
                  l1.17,2.66c0.06,0.14,0.15,0.27,0.27,0.38l0.71,0.7L1281.07,121.84z"/>
                <title>Svidník</title>
              </Link>

              {/* Bardejov */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Bardejov")}>
                <path style={{ fill: crimesInRegionPresovsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionPresovsky[0].crimePercent)] ? crimesInRegionPresovsky[0].color : "#D3D3D3" }}
                  d="M1217.76,184.41c-0.35,0.32-0.49,0.81-0.37,1.26l0.21,0.74l-0.38,0.67c-0.2,0.35-0.21,0.77-0.05,1.13l0.43,0.93
                  l-0.2-0.07c-0.23-0.08-0.48-0.09-0.71-0.04l-1.22,0.29c-0.06,0.02-0.13,0.04-0.19,0.06l-2.53,1.1l-0.07-0.91
                  c-0.01-0.09-0.03-0.18-0.05-0.27l-0.36-1.2c-0.07-0.22-0.19-0.42-0.36-0.57l-0.84-0.75l-0.35-0.65l0.01-0.94
                  c0.01-0.37-0.15-0.73-0.43-0.97c-0.29-0.24-0.66-0.35-1.03-0.28l-0.64,0.1l-0.54-0.52c-0.04-0.03-0.07-0.06-0.1-0.09l-1.97-1.53
                  l-0.72-0.7l-0.4-1.17c-0.15-0.46-0.55-0.78-1.03-0.84s-0.95,0.17-1.21,0.58l-0.56,0.89l-0.67,0.41l-1.03,0.32
                  c-0.35,0.1-0.64,0.36-0.78,0.71l-0.48,1.14c-0.02,0.05-0.04,0.1-0.05,0.15l-0.25,0.89l-0.62,0.64l-0.9,0.8l-0.97,0.56
                  c-0.51,0.3-0.75,0.92-0.55,1.49l0.27,0.77l-0.1,0.28l-0.78,0.4c-0.05,0.02-0.09,0.05-0.14,0.08l-0.97,0.67l-1.37,0.58l-0.94,0.46
                  l-1.02,0.13l-1.18-0.2l-1.59-0.18c-0.14-0.02-0.28-0.01-0.41,0.02l-1.41,0.31l-1.42,0.21c-0.22,0.03-0.42,0.12-0.59,0.25l-1,0.78
                  c-0.27,0.21-0.44,0.52-0.47,0.86l-0.11,0.97l-1.64,1.37c-0.3,0.26-0.46,0.62-0.45,1l-0.08-0.19l-1.55,0.55l-1.14,0.28l-1.17,0.23
                  l-0.5,0.05l-0.32-0.4l-1.13-2.23c-0.04-0.08-0.1-0.16-0.16-0.23l-0.84-0.99c-0.12-0.15-0.28-0.26-0.45-0.34l-1.27-0.55
                  c-0.35-0.15-0.75-0.13-1.09,0.05l-0.1,0.05l-0.06-0.1c-0.05-0.08-0.11-0.15-0.18-0.21l-1.01-0.99c-0.13-0.14-0.3-0.24-0.49-0.3
                  l-1.18-0.38c-0.06-0.02-0.12-0.03-0.19-0.04l-1.26-0.2c-0.3-0.05-0.61,0.01-0.87,0.18l-1.04,0.68c-0.11,0.07-0.21,0.16-0.29,0.26
                  l-0.78,0.96c-0.18,0.22-0.28,0.5-0.28,0.78l-0.55-0.25l-0.65-0.43l-0.27-0.68l-0.21-1.05l-0.14-1.11l0.24-2.4
                  c0.02-0.12,0.01-0.24-0.01-0.35l-0.23-1.32c-0.04-0.24-0.14-0.45-0.3-0.62l-0.88-0.98c-0.22-0.25-0.52-0.39-0.84-0.42l-0.84-0.06
                  l-1.58-1.35c-0.08-0.07-0.16-0.12-0.25-0.17l-1.12-0.56c-0.05-0.02-0.11-0.05-0.16-0.06l-1.4-0.48c-0.12-0.04-0.25-0.06-0.37-0.06
                  l-1.25-0.03h-0.03c-0.61,0-1.12,0.43-1.23,1.03l-0.02,0.1l-1.35-0.2l-1.11-0.28l-1.01-0.56c-0.13-0.07-0.26-0.11-0.41-0.13
                  l-1.09-0.18l-2.3-0.82l-1.04-0.56l-0.98-0.73c-0.03-0.02-0.06-0.04-0.09-0.06l-1.13-0.7c-0.12-0.07-0.24-0.12-0.37-0.16l-1-0.23
                  l-0.68-0.46l-0.54-0.8l-0.72-2.23c-0.07-0.21-0.18-0.39-0.34-0.53l-0.96-0.9c-0.13-0.13-0.3-0.23-0.49-0.29l-1.21-0.37
                  c-0.17-0.05-0.35-0.06-0.54-0.04l-0.97,0.14l-0.48-0.21l-0.41-0.63l-0.4-1.16c-0.18-0.52-0.67-0.87-1.22-0.84l-0.27,0.01l-0.63-0.71
                  l-0.45-0.82l-0.04-0.96c-0.01-0.17-0.05-0.33-0.12-0.48l-0.57-1.2c-0.22-0.46-0.7-0.75-1.23-0.71l-0.91,0.08l-0.83-0.44
                  c-0.07-0.03-0.13-0.06-0.2-0.08l-1.22-0.39c-0.14-0.04-0.28-0.06-0.42-0.06l-0.84,0.03l-0.63-0.44l-0.46-0.77l-0.3-1.17
                  c-0.07-0.26-0.23-0.5-0.45-0.67l-0.98-0.76l-0.89-0.86c-0.09-0.1-0.2-0.17-0.32-0.23l-0.74-0.37l-0.4-0.79
                  c-0.02-0.04-0.05-0.08-0.07-0.12l-1.41-2.16c-0.22-0.34-0.6-0.55-1.01-0.56l-1.31-0.03c-0.26-0.01-0.52,0.07-0.73,0.22l-0.79,0.53
                  l-0.92,0.13l-1.2,0.01c-0.15,0-0.29,0.03-0.43,0.08l-1.18,0.44c-0.11,0.04-0.22,0.1-0.32,0.18l-0.68,0.51l-1.02,0.03
                  c-0.24,0.01-0.47,0.08-0.67,0.22l-0.9,0.62l-0.77,0.31l-0.56-0.12l-0.77-0.63c-0.04-0.03-0.09-0.07-0.14-0.1l-0.95-0.58l-0.8-0.77
                  c-0.03-0.03-0.06-0.05-0.09-0.08l-1.04-0.83c-0.26-0.2-0.59-0.3-0.92-0.26l-1.28,0.14c-0.35,0.04-0.67,0.23-0.88,0.52l-0.58,0.81
                  l-0.35,0.2l-1.83-0.72c-0.35-0.14-0.74-0.12-1.07,0.07l-1.07,0.59c-0.13,0.07-0.25,0.16-0.35,0.27l-0.53,0.62l-0.56,0.1l-0.83-0.15
                  l-0.49-0.63c-0.12-0.15-0.27-0.27-0.44-0.35l-0.9-0.44l-0.65-0.76c-0.08-0.09-0.17-0.17-0.27-0.23l-0.74-0.47l-0.24-0.83
                  c-0.03-0.09-0.07-0.18-0.12-0.27l-0.63-1.09c-0.16-0.27-0.42-0.48-0.73-0.57l-0.51-0.15c0.07-0.06,0.13-0.14,0.18-0.22l0.63-0.99
                  l0.77-0.86c0.16-0.19,0.27-0.42,0.3-0.66l0.19-1.39c0.01-0.09,0.01-0.19,0-0.28l-0.09-1.16l0.09-1.09l0.21-1.16
                  c0.03-0.14,0.03-0.29,0-0.43l-0.22-1.28c-0.06-0.36-0.28-0.68-0.6-0.87l-0.71-0.42l-0.31-0.75l-0.25-2.59
                  c0-0.06-0.01-0.12-0.03-0.18l-0.32-1.26c-0.06-0.23-0.18-0.44-0.36-0.61l-0.41-0.37l1.19-0.16l1.11,0.11c0.18,0.02,0.37,0,0.54-0.06
                  l1.26-0.44c0.26-0.09,0.48-0.26,0.63-0.49l0.68-1.02c0.09-0.13,0.15-0.28,0.18-0.43l0.25-1.21c0.08-0.37-0.02-0.77-0.27-1.06
                  c-0.25-0.3-0.63-0.46-1.01-0.44l-0.74,0.04l-0.41-0.5l-0.84-1.21l-0.5-1.21c-0.06-0.15-0.15-0.29-0.27-0.41l-1-0.99
                  c-0.03-0.03-0.06-0.06-0.1-0.08l-0.78-0.64l-0.44-0.79l-0.34-1.16c-0.02-0.06-0.04-0.12-0.07-0.18l-0.27-0.56
                  c0.07-0.05,0.14-0.12,0.2-0.19l0.82-1.03c0.09-0.12,0.16-0.25,0.21-0.39l0.4-1.23c0.09-0.3,0.08-0.61-0.05-0.9l-1.04-2.3l-0.1-1.08
                  c0-0.05-0.01-0.1-0.02-0.16l-0.15-0.66l0.35-0.46l0.74-0.7l0.88-0.36l1.16-0.12c0.25-0.02,0.48-0.12,0.67-0.28l1.06-0.89
                  c0.4-0.33,0.55-0.87,0.38-1.36l-0.39-1.18l0.2-0.82l0.32-0.75l0.69-0.15c0.29-0.06,0.55-0.23,0.74-0.47l0.69-0.92l0.82-0.94
                  l0.9-0.94c0.07-0.07,0.13-0.15,0.18-0.23l0.39-0.68l0.89-0.28c0.29-0.09,0.54-0.29,0.69-0.55l0.55-0.92l0.9-0.6
                  c0.06-0.05,0.12-0.1,0.18-0.15l0.66-0.66l0.2-0.04l0.61,0.6c0.18,0.17,0.42,0.29,0.67,0.33l1.23,0.2c0.24,0.04,0.48,0.01,0.7-0.09
                  l1.65-0.73l1.14,1.13c0.2,0.21,0.47,0.35,0.77,0.38l1.23,0.14c0.65,0.08,1.26-0.37,1.37-1.03l0.05-0.28l0.45-0.05
                  c0.14-0.01,0.27-0.05,0.4-0.11l0.96-0.45l1.01,0.14c0.51,0.07,1.01-0.18,1.26-0.63l0.61-1.1l0.78-1.19c0.07-0.1,0.11-0.2,0.15-0.31
                  l0.86-2.5c0.15-0.43,0.05-0.92-0.26-1.26l-0.98-1.07c-0.02-0.03-0.05-0.06-0.08-0.08L1109,90.4c-0.1-0.1-0.22-0.18-0.35-0.23
                  l-0.95-0.4l-0.73-0.71l-0.77-1c-0.11-0.13-0.24-0.24-0.39-0.33l-0.96-0.52l-0.8-0.76c-0.04-0.04-0.09-0.07-0.13-0.11l-1.11-0.78
                  l-0.44-0.28l0.1-0.3c0.09-0.27,0.08-0.57-0.03-0.84l-0.48-1.21c-0.2-0.5-0.68-0.83-1.23-0.79l-0.98,0.06l-1.05-0.41l-0.45-0.68
                  l-0.3-1l-0.21-1.33c-0.02-0.19-0.1-0.37-0.21-0.53l-0.46-0.66l0.04-0.16l0.72-0.32l0.8-0.21l0.88,0.35
                  c0.45,0.18,0.96,0.08,1.32-0.25l0.8-0.76l0.93-0.61l0.84-0.24l1.11,0.13c0.29,0.04,0.58-0.03,0.83-0.19l1.19-0.76l0.98-0.47
                  l0.66-0.21l1.74,1.08c0.06,0.04,0.13,0.08,0.2,0.11l1.28,0.52c0.24,0.1,0.5,0.12,0.75,0.06l1.33-0.31c0.43-0.1,0.78-0.43,0.91-0.85
                  l0.39-1.25l0.89-2.04l0.14-0.08l1.93,0.84l1.13,0.78c0.05,0.03,0.1,0.06,0.15,0.09l2.31,1.17l0.92,0.54l0.33,0.44l-0.03,0.86
                  c-0.02,0.52,0.28,1,0.76,1.2l2.17,0.93l1.56,2.02c0.08,0.11,0.18,0.2,0.29,0.28l1.75,1.22l0.19,0.48l-0.17,0.98
                  c-0.06,0.33,0.02,0.68,0.22,0.96c0.2,0.27,0.5,0.45,0.84,0.5l1.15,0.15l1.17,0.38c0.29,0.09,0.6,0.08,0.88-0.05l2.06-0.88l0.76,0.01
                  l0.88,0.41c0.31,0.15,0.66,0.16,0.98,0.03c0.32-0.12,0.57-0.37,0.7-0.68l0.48-1.15l0.59-0.91l0.79-0.78l1-0.79
                  c0.06-0.04,0.11-0.09,0.16-0.14l0.87-0.97l0.81-0.98c0.2-0.25,0.31-0.56,0.29-0.87l-0.09-1.54c-0.01-0.21-0.08-0.41-0.18-0.58
                  l-0.45-0.73l0.16-0.32l0.74-0.72c0.08-0.08,0.16-0.18,0.22-0.28l0.62-1.09c0.08-0.14,0.13-0.3,0.15-0.46l0.01-0.1l1.28,0.15
                  c0.37,0.05,0.73-0.08,1-0.33l0.97-0.91c0.05-0.04,0.09-0.08,0.12-0.12l0.76-0.95l1.01-0.79c0.06-0.05,0.11-0.1,0.17-0.16l0.83-0.97
                  l1.12-0.73l1.1-0.59c0.43-0.23,0.69-0.68,0.66-1.17l-0.07-1.19l0.26-1.06l0.37-0.77l0.53-0.34l0.58-0.09l0.75,0.51l0.96,0.56
                  l0.91,0.82c0.05,0.05,0.11,0.1,0.17,0.14l1.19,0.74l1.1,0.57l1.03,0.84c0.16,0.13,0.34,0.21,0.54,0.26l1.29,0.26
                  c0.11,0.02,0.22,0.02,0.33,0.02l2.53-0.18c0.07-0.01,0.15-0.02,0.22-0.04l1.02-0.27l1.04,0.05c0.17,0,0.34-0.02,0.5-0.08l1.05-0.39
                  l0.97-0.18l0.86,0.11l0.67,0.35l0.57,0.83c0.05,0.09,0.13,0.17,0.2,0.24l0.99,0.87c0.05,0.04,0.1,0.08,0.16,0.11l1.05,0.67
                  c0.14,0.09,0.29,0.15,0.45,0.18l1.07,0.19l0.67,0.51l0.57,0.68l0.47,1.34c0.12,0.35,0.39,0.63,0.74,0.76l1.21,0.46
                  c0.32,0.12,0.68,0.11,0.99-0.05l0.92-0.45l0.83-0.04l0.77,0.42c0.34,0.18,0.74,0.2,1.09,0.05l0.88-0.37l0.72,0.07l0.53,0.33
                  l0.66,1.94c0.08,0.23,0.22,0.44,0.42,0.6l1.03,0.8c0.06,0.04,0.11,0.08,0.17,0.11l0.74,0.4l0.35,0.74c0.1,0.2,0.24,0.37,0.42,0.49
                  l2.07,1.46c0.04,0.02,0.07,0.05,0.11,0.07l0.96,0.56l1.06,0.92c0.28,0.25,0.66,0.35,1.03,0.29l0.88-0.15l-0.22,1.02l-0.41,1.26
                  l-0.34,1.32l-0.59,0.87l-0.77,1.02l-0.92,0.91l-0.94,0.6l-1.43,0.8c-0.21,0.12-0.38,0.29-0.49,0.5l-0.69,1.29
                  c-0.25,0.47-0.18,1.04,0.18,1.43l0.24,0.27l-0.11,0.12c-0.12,0.13-0.21,0.29-0.27,0.46l-0.39,1.2l-0.32,0.61l-1.88,0.07
                  c-0.26,0.01-0.52,0.1-0.72,0.27l-1.15,0.9l-0.65,0.34l-2.03-0.53c-0.3-0.08-0.61-0.04-0.89,0.11l-0.8,0.42l-0.91-0.08
                  c-0.48-0.05-0.95,0.19-1.2,0.61l-0.6,1.03l-0.72,1.04c-0.21,0.31-0.28,0.7-0.17,1.06c0.11,0.37,0.37,0.66,0.72,0.81l0.96,0.39
                  l0.67,0.73c0.09,0.1,0.2,0.18,0.32,0.24l1.16,0.64c0.04,0.03,0.08,0.05,0.13,0.07l1.3,0.53c0.04,0.01,0.08,0.03,0.12,0.04l1.02,0.3
                  l0.98,0.65c0.15,0.1,0.32,0.17,0.49,0.19l1.06,0.17l0.8,0.67l1.07,1.04c0.12,0.12,0.27,0.21,0.44,0.27l1.16,0.43l1.21,0.62
                  c0.05,0.03,0.11,0.06,0.17,0.08l0.39,0.13l-0.51,0.75l-0.87,0.61c-0.19,0.13-0.34,0.32-0.43,0.54l-0.56,1.34l-0.31,0.38l-0.67,0.03
                  c-0.16,0.01-0.33,0.05-0.47,0.12l-1.17,0.54c-0.04,0.01-0.08,0.03-0.11,0.05l-1.12,0.66c-0.22,0.13-0.39,0.31-0.5,0.53l-0.63,1.31
                  c-0.27,0.56-0.08,1.23,0.44,1.58l1.05,0.7c0.07,0.05,0.14,0.09,0.22,0.12l2.36,0.96l1.05,0.74c0.1,0.07,0.21,0.13,0.34,0.17
                  l1.55,0.5l1.07,0.67l0.82,0.77l1.67,2.19c0.33,0.45,0.92,0.62,1.44,0.44l1.16-0.42c0.11-0.05,0.21-0.1,0.31-0.17l0.51-0.37
                  l0.08,0.37c0.01,0.04,0.02,0.08,0.03,0.12l0.33,0.98l-0.02,1.23l-0.13,1.27c-0.03,0.25,0.02,0.49,0.13,0.71l0.43,0.82v0.94
                  c0,0.35,0.14,0.68,0.4,0.91l0.73,0.69l0.56,1.23c0.03,0.07,0.06,0.13,0.1,0.18l0.71,1.07c0.16,0.25,0.41,0.43,0.69,0.51l1.21,0.35
                  c0.2,0.06,0.4,0.06,0.6,0.02l1.31-0.27c0.07-0.01,0.13-0.03,0.19-0.05l0.48-0.18l0.32,0.27l0.66,0.79l0.42,1.05
                  c0.04,0.1,0.09,0.19,0.14,0.27l0.74,1.03c0.24,0.32,0.61,0.51,1,0.52l0.95,0.02l0.45,0.23l0.35,0.78l0.29,0.83l-0.11,0.95
                  l-0.45,1.06c-0.04,0.09-0.07,0.18-0.08,0.28l-0.16,0.93l-0.64,0.85c-0.15,0.2-0.24,0.45-0.25,0.7l-0.05,1.24
                  c-0.01,0.3,0.09,0.6,0.28,0.84l0.93,1.15c0.08,0.1,0.19,0.19,0.3,0.27l0.81,0.51l0.35,0.87c0.05,0.15,0.14,0.28,0.24,0.4l0.96,1.02
                  l0.63,0.8l0.23,0.79l0.08,3.38l0.09,1.29c0.04,0.48,0.34,0.89,0.79,1.07l0.78,0.31l0.12,0.2l-0.21,0.65
                  c-0.01,0.04-0.02,0.07-0.03,0.11l-0.22,1l-0.55,0.78l-0.85,0.63l-1.02,0.51c-0.13,0.07-0.24,0.15-0.34,0.26l-0.45,0.46l-0.76-0.07
                  l-1.13-0.28c-0.18-0.05-0.37-0.05-0.56-0.01l-1.23,0.26c-0.12,0.02-0.23,0.06-0.34,0.12l-1.31,0.71c-0.19,0.11-0.35,0.26-0.47,0.45
                  l-0.64,1.05c-0.03,0.06-0.06,0.12-0.09,0.19l-0.49,1.23c-0.06,0.15-0.09,0.31-0.09,0.48l0.02,1.32c0,0.03,0,0.07,0.01,0.1l0.12,1.23
                  c0,0.09,0.02,0.19,0.05,0.28l0.22,0.66l-0.37,0.63c-0.11,0.19-0.17,0.41-0.17,0.64l0.01,1.26c0,0.15,0.03,0.3,0.08,0.44l0.46,1.19
                  c0.09,0.23,0.24,0.43,0.44,0.57l1.09,0.78c0.05,0.03,0.09,0.06,0.14,0.08l1,0.54l1.04,0.66c0.17,0.1,0.36,0.17,0.56,0.19l1.3,0.11
                  h0.11l-0.05,0.26l-0.51,0.89c-0.15,0.26-0.2,0.57-0.15,0.87l0.24,1.25c0.08,0.41,0.37,0.76,0.76,0.92l2.09,0.85l0.67,0.69
                  c0.03,0.04,0.07,0.08,0.11,0.11l1.08,0.88c0.14,0.11,0.31,0.2,0.49,0.24l1,0.25C1218.03,184.22,1217.89,184.3,1217.76,184.41z"/>
                <title>Bardejov</title>
              </Link>

              {/* Presovsky levoca */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Levoča")}>
                <path
                  d="M1056.76,214.59l-0.06,0.55l-0.25,1.53l-0.25,1.16l-0.66,1.44c-0.04,0.09-0.07,0.19-0.09,0.29l-0.54,2.86
                  c-0.02,0.09-0.02,0.19-0.02,0.29l0.04,0.83l-0.41,0.6l-0.76,0.83l-1.32,1.19c-0.2,0.18-0.33,0.42-0.39,0.69l-0.24,1.22
                  c-0.02,0.11-0.02,0.23-0.02,0.34l0.09,1.03l-0.25,1c-0.05,0.19-0.05,0.39-0.01,0.58l0.2,0.88l-0.29,0.84
                  c-0.09,0.27-0.09,0.56,0,0.82l0.23,0.67l-0.35,0.57l-0.75,0.86l-0.93,0.98c-0.21,0.22-0.33,0.51-0.34,0.81l-0.04,1.12l-0.37,1.31
                  c-0.03,0.11-0.05,0.22-0.05,0.33l-0.01,1.23c0,0.33,0.12,0.64,0.35,0.88l1.65,1.71l0.67,0.92c0.03,0.05,0.06,0.09,0.1,0.13
                  l-0.55,0.66c-0.05,0.06-0.1,0.13-0.14,0.21l-0.58,1.11c-0.19,0.37-0.19,0.8,0.01,1.17l0.65,1.22c0.09,0.18,0.23,0.33,0.4,0.44
                  l0.46,0.32l-0.19,0.83c-0.06,0.27-0.03,0.55,0.09,0.8l-0.66,0.37l-1.04-0.27c-0.28-0.07-0.57-0.05-0.84,0.08l-1.09,0.5l-1.12,0.22
                  c-0.04,0.01-0.09,0.02-0.13,0.04l-1.23,0.38c-0.05,0.01-0.1,0.03-0.15,0.05c-0.2-0.56-0.78-0.91-1.37-0.81l-1.3,0.2l-0.9-0.4
                  c-0.42-0.18-0.89-0.12-1.25,0.15l-0.86,0.64l-0.7-0.02l-0.91-0.45l-0.69-0.58l-0.07-0.15l0.27-0.2l2.58-1.19
                  c0.29-0.14,0.52-0.38,0.64-0.67l0.37-0.91l0.7-0.63c0.38-0.34,0.52-0.88,0.34-1.37c-0.18-0.48-0.64-0.8-1.15-0.81l-1.27-0.02
                  c-0.37-0.01-0.68,0.13-0.92,0.39l-0.72,0.74l-0.82,0.4l-0.82,0.11l-3.44-0.85l-0.58-0.25l-0.2-0.63c-0.09-0.3-0.3-0.56-0.58-0.71
                  l-1.06-0.59l-0.93-0.63l-0.84-0.83c-0.26-0.26-0.6-0.39-0.98-0.37l-2.18,0.17c-0.22,0.02-0.43,0.1-0.61,0.22l-0.19-0.33
                  c-0.07-0.13-0.16-0.25-0.27-0.34l-0.96-0.84c-0.26-0.23-0.61-0.34-0.97-0.3c-0.35,0.04-0.66,0.23-0.87,0.51l-0.72,1.01
                  c-0.17,0.25-0.25,0.54-0.23,0.84l0.11,1.28c0.03,0.33,0.19,0.64,0.45,0.85c0.19,0.16,0.42,0.26,0.66,0.28
                  c0.02,0.17,0.07,0.34,0.15,0.49l0.72,1.31l0.25,0.85l-0.62,1.58c-0.04,0.12-0.07,0.25-0.08,0.38l-0.14,2.6
                  c-0.03,0.54,0.29,1.03,0.79,1.22l0.62,0.25l-0.89,0.58l-0.13,0.07l-0.03-0.04c-0.21-0.36-0.59-0.59-1-0.62l-0.72-0.04l-0.27-0.34
                  l-0.27-1.02c-0.09-0.31-0.3-0.58-0.58-0.75l-1.1-0.64c-0.3-0.18-0.67-0.22-1-0.11l-1.2,0.37c-0.12,0.03-0.24,0.09-0.35,0.17
                  l-1.49,1.05c-0.16,0.11-0.28,0.25-0.38,0.42l-0.46,0.85l-0.29-0.34l-0.64-1.03c-0.02-0.04-0.05-0.07-0.07-0.11l-0.64-0.82
                  l-0.37-1.03c-0.12-0.34-0.38-0.61-0.71-0.74l-2.39-0.93c-0.28-0.11-0.6-0.11-0.88,0l-1.22,0.46l-1.44,0.41
                  c-0.09,0.02-0.18,0.06-0.27,0.11l-2.11,1.13l-1.14,0.11c-0.19,0.01-0.38,0.08-0.54,0.18l-1.19,0.74c-0.05,0.03-0.1,0.07-0.15,0.11
                  l-1.19,1.01c-0.37,0.31-0.52,0.81-0.4,1.28l0.34,1.21l0.1,1.02l-0.34,0.78l-0.56,0.92l-0.73,0.58l-2.22,0.77l-1.2,0.09l-1.34-0.58
                  c-0.14-0.06-0.28-0.1-0.43-0.11l-1.77-0.1c-0.32-0.02-0.64,0.09-0.88,0.3l-1.1,0.93l-0.23,0.05l-0.25-0.62l-0.86-3.05
                  c-0.12-0.45-0.5-0.8-0.96-0.89l-0.42-0.08l-0.01-0.33c-0.01-0.15-0.04-0.3-0.1-0.44l-0.42-0.99l-0.1-1.11l0.1-1.37
                  c0.03-0.42-0.16-0.83-0.49-1.08c-0.33-0.26-0.77-0.33-1.17-0.19l-1.11,0.38l-1.32,0.33l-1.31,0.42c-0.09,0.03-0.17,0.07-0.26,0.12
                  l-0.76,0.46l-0.67-0.22c-0.37-0.13-0.77-0.07-1.09,0.14l-0.69,0.46l-0.82-0.06c-0.24-0.01-0.49,0.04-0.7,0.16l-0.95,0.55l-0.04,0.01
                  l-0.09-0.17c-0.04-0.08-0.08-0.15-0.14-0.22l-0.66-0.81l-0.32-0.99c-0.02-0.06-0.04-0.11-0.07-0.16l-0.55-1.12
                  c-0.06-0.13-0.15-0.26-0.26-0.36l-0.93-0.88c-0.11-0.1-0.23-0.18-0.36-0.23l-1.1-0.49l-0.71-0.77c-0.02-0.03-0.05-0.06-0.08-0.08
                  l-0.94-0.86c-0.21-0.19-0.47-0.31-0.75-0.33l-1.37-0.09l-0.86-0.19l-1.45-1.6l-0.9-0.95c-0.15-0.16-0.34-0.27-0.55-0.34l-0.15-0.04
                  l0.06-0.34c0.02-0.14,0.03-0.27,0.01-0.41l-0.21-1.36l0.37-0.54l0.92-0.89c0.36-0.33,0.48-0.85,0.32-1.31l-0.42-1.21l-0.2-1.14
                  c-0.07-0.39-0.32-0.72-0.68-0.9c-0.35-0.18-0.77-0.17-1.12,0.01l-0.42,0.21l-0.3-0.28c-0.38-0.37-0.97-0.46-1.44-0.21l-1.09,0.57
                  c-0.23,0.12-0.42,0.31-0.54,0.55l-0.53,1.06l-0.65,0.95c-0.04,0.07-0.08,0.14-0.11,0.22l-0.42,1l-0.74,0.63
                  c-0.12,0.11-0.23,0.25-0.3,0.4l-0.74,1.46l-1.74-0.33c-0.04-0.01-0.09-0.01-0.13-0.02l-1.25-0.09c-0.18-0.01-0.36,0.02-0.52,0.08
                  l-1.19,0.44c-0.45,0.16-0.77,0.57-0.81,1.05c-0.05,0.48,0.18,0.94,0.59,1.19l1.16,0.7l1.16,0.63c0.04,0.02,0.09,0.04,0.13,0.06
                  l2.27,0.89l1.5,0.71l-0.35,0.29l-2.19,0.15c-0.26,0.02-0.5,0.11-0.7,0.27l-0.5,0.39l-0.26-0.06l-0.59-0.66
                  c-0.34-0.38-0.86-0.52-1.34-0.35c-0.43,0.15-0.74,0.52-0.82,0.96l-0.57-0.73l-0.82-0.91c-0.09-0.09-0.19-0.17-0.3-0.24l-1.17-0.69
                  c-0.42-0.25-0.96-0.22-1.36,0.07l-0.98,0.71l-0.19,0.06l-0.17-0.24c-0.05-0.07-0.11-0.14-0.17-0.2l-1.75-1.71
                  c-0.03-0.03-0.07-0.06-0.11-0.09l-0.36-0.29l0.12-0.47c0.15-0.6-0.16-1.22-0.74-1.46l-0.57-0.23l-0.08-0.38l0.11-0.87l0.53-0.81
                  c0.13-0.2,0.21-0.43,0.21-0.67v-0.05c0.23,0.18,0.52,0.28,0.82,0.27c0.45-0.02,0.85-0.28,1.06-0.67l0.64-1.23
                  c0.07-0.13,0.11-0.27,0.13-0.41l0.17-1.26c0.01-0.06,0.01-0.12,0.01-0.18l-0.01-1.26l0.32-1.07l0.4-0.62l2.14-1.04l1.09-0.65
                  l1.11-0.5c0.5-0.22,0.79-0.73,0.73-1.27l-0.13-1.22c0-0.05-0.01-0.1-0.02-0.14l-0.19-0.84l0.31-0.77l0.49-0.73l0.9-0.64l0.39-0.31
                  l0.15,0.07l0.53,0.77l0.63,1.08c0.14,0.25,0.36,0.44,0.63,0.55l1.02,0.39l0.72,0.5l0.49,0.85c0.31,0.54,0.98,0.76,1.55,0.53
                  l1.15-0.47c0.53-0.22,0.85-0.76,0.77-1.33l-0.05-0.38l0.37-0.19c0.04-0.02,0.07-0.04,0.1-0.06l0.7-0.43l0.77,0.12
                  c0.28,0.04,0.57-0.02,0.82-0.16l0.97-0.56l1.06-0.28c0.11-0.03,0.22-0.08,0.32-0.14l1.07-0.64c0.07-0.04,0.13-0.08,0.19-0.13
                  l0.79-0.7l1.03-0.34c0.32-0.11,0.57-0.33,0.72-0.62l0.61-1.2c0.17-0.32,0.18-0.7,0.05-1.03c-0.13-0.34-0.4-0.6-0.74-0.72l-1.21-0.42
                  l-0.18-0.07l0.05-0.27h0.04c0.51,0.11,1.03-0.11,1.31-0.56c0.28-0.44,0.25-1.01-0.07-1.42l-0.05-0.06l0.04-0.03
                  c0.03-0.03,0.06-0.06,0.09-0.1l0.85-0.91c0.14-0.14,0.24-0.32,0.29-0.51l0.34-1.19c0.01-0.03,0.02-0.06,0.03-0.1l0.3-1.54l0.17-1.22
                  c0.08-0.57-0.25-1.13-0.79-1.34l-0.75-0.29l-0.09-0.16l0.09-0.21l0.73-0.47c0.08-0.05,0.16-0.12,0.22-0.19l0.69-0.71l1.15-0.47
                  c0.1-0.04,0.2-0.1,0.28-0.16l2.42-1.87c0.05-0.05,0.11-0.1,0.16-0.15l0.85-0.97l0.83-0.24c0.36-0.1,0.65-0.37,0.8-0.71l0.39-0.93
                  l0.68-0.7l2.18-1.2c0.03-0.02,0.07-0.04,0.1-0.06l1.17-0.81c0.16-0.11,0.29-0.26,0.38-0.42l1.19-2.1l0.86-0.75l0.88-0.83l1.04-0.78
                  c0.05-0.04,0.11-0.09,0.16-0.14l1.61-1.7l1.11-0.95c0.05-0.04,0.09-0.08,0.13-0.13l1.4-1.59l1.21-1.53c0.05-0.07,0.09-0.13,0.13-0.2
                  l1.41-2.71c0.02-0.04,0.04-0.08,0.05-0.13l0.36-0.9l0.68-0.15c0.21-0.04,0.4-0.14,0.56-0.28l0.42-0.36l2.79,3.26l1.92,2.03
                  c0.03,0.03,0.07,0.06,0.1,0.09l2.21,1.8l5.32,3.85l0.77,0.68l0.65,1c0.15,0.25,0.39,0.43,0.67,0.52l1.18,0.38
                  c0.22,0.07,0.47,0.08,0.7,0.02l0.69-0.18l0.6,0.41c0.36,0.25,0.82,0.29,1.22,0.11l1.14-0.51c0.21-0.09,0.39-0.24,0.52-0.43
                  l0.29-0.41l0.61,0.05h0.11l1.39-0.01l1.31,0.05c0.27,0.01,0.51-0.06,0.73-0.2l0.55-0.36l0.23,0.09l0.66,0.84l0.64,0.72l0.34,1.06
                  c0.04,0.13,0.11,0.26,0.19,0.37l0.89,1.18l0.94,1.12c0.23,0.27,0.55,0.43,0.9,0.45c0.34,0.01,0.69-0.11,0.94-0.36l0.92-0.9
                  l0.81-0.41l0.92-0.24l0.61,0.03l0.73,0.68c0.03,0.03,0.07,0.06,0.11,0.09l1.94,1.48l0.67,0.79l0.38,0.99
                  c0.03,0.07,0.06,0.14,0.1,0.2l0.99,1.62l0.59,1.14c0.04,0.08,0.08,0.15,0.14,0.21l0.94,1.17c0.03,0.05,0.08,0.1,0.12,0.14l1.15,1.05
                  l1.03,1.1l0.4,0.94c0.03,0.08,0.07,0.15,0.12,0.22l1.06,1.55c0.29,0.43,0.83,0.63,1.33,0.5l1.3-0.33l1.21-0.37l1.27-0.28
                  c0.25-0.05,0.48-0.19,0.65-0.38l0.57-0.62l1.42,0.06c0.18,0.01,0.34-0.02,0.51-0.09l1.33-0.52c0.1-0.04,0.19-0.09,0.28-0.15
                  l0.85-0.63l0.76-0.02l1.3,0.22c0.07,0.01,0.15,0.02,0.22,0.02l1.09-0.01l0.98,0.53c0.03,0.02,0.06,0.03,0.1,0.05l0.92,0.39
                  l1.09,1.12l1.21,1.35c0.15,0.17,0.35,0.3,0.56,0.37L1056.76,214.59z"/>
                <title>Levoča</title>
              </Link>

              {/* Presovsky stara Lubovna */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Ľubovňa")}>
                <path style={{ fill: crimesInRegionPresovsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionPresovsky[5].crimePercent)] ? crimesInRegionPresovsky[5].color : "#D3D3D3" }}
                  d="M1085.32,131.88l-0.48,0.17l-0.98-0.1c-0.1-0.01-0.2-0.01-0.3,0l-1.19,0.16l-1.07-0.12
                  c-0.33-0.04-0.66,0.06-0.92,0.27c-0.27,0.21-0.43,0.51-0.46,0.85l-0.16,1.63c-0.04,0.39,0.1,0.77,0.39,1.04l0.85,0.79l0.21,0.81
                  l0.25,2.65c0.01,0.13,0.04,0.25,0.09,0.37l0.55,1.32c0.1,0.25,0.28,0.46,0.52,0.6l0.57,0.33l0.08,0.49l-0.18,1
                  c-0.01,0.03-0.01,0.07-0.02,0.11l-0.1,1.26c-0.01,0.07-0.01,0.15,0,0.22l0.09,1.13l-0.12,0.88l-0.58,0.65
                  c-0.04,0.05-0.08,0.1-0.11,0.16l-0.57,0.89l-0.85,0.68c-0.44,0.36-0.59,0.96-0.37,1.47l0.1,0.23l-1.11,0.18
                  c-0.11,0.01-0.22,0.05-0.32,0.09l-1.18,0.54c-0.26,0.12-0.47,0.32-0.6,0.57l-1.03,2.06l-0.43,0.27l-0.85-0.11
                  c-0.18-0.02-0.36-0.01-0.54,0.05l-1.42,0.45c-0.23,0.07-0.43,0.21-0.58,0.39l-0.63,0.75l-0.99,0.49c-0.08,0.04-0.16,0.08-0.23,0.14
                  l-0.93,0.75l-0.6,0.4l-0.84-0.31c-0.21-0.07-0.44-0.09-0.67-0.04l-1.05,0.21l-1.28-0.42c-0.06-0.02-0.11-0.03-0.16-0.04l-1.34-0.25
                  c-0.48-0.09-0.97,0.11-1.25,0.5l-0.72,1.02c-0.02,0.03-0.04,0.06-0.06,0.09l-0.46,0.78l-0.3,0.1l-0.58-0.62
                  c-0.32-0.34-0.79-0.48-1.24-0.36l-1.02,0.28l-1.2-0.04c-0.16,0-0.32,0.02-0.47,0.08l-0.38,0.14l-0.31-0.4
                  c-0.13-0.16-0.29-0.28-0.47-0.36l-0.38-0.17l0.06-1.48l0.36-1c0.05-0.15,0.07-0.31,0.07-0.47l-0.05-1.27
                  c-0.01-0.09-0.02-0.18-0.04-0.26l-0.33-1.27c-0.09-0.35-0.32-0.64-0.64-0.8c-0.32-0.16-0.69-0.18-1.03-0.05l-0.95,0.37l-1.13-0.28
                  c-0.47-0.12-0.97,0.05-1.28,0.43c-0.3,0.38-0.36,0.9-0.14,1.34l0.34,0.67l-0.21,0.78c-0.02,0.09-0.04,0.19-0.04,0.3l-0.01,0.57
                  c-0.05-0.07-0.12-0.14-0.19-0.2l-1.35-1.16c-0.28-0.24-0.66-0.35-1.03-0.28l-1.21,0.22c-0.02,0-0.03,0.01-0.05,0.01
                  c0-0.03-0.01-0.06-0.01-0.08l-0.31-1.33c-0.08-0.33-0.29-0.62-0.58-0.79l-1.17-0.7c-0.16-0.1-0.35-0.16-0.54-0.18l-1.32-0.11
                  c-0.33-0.03-0.67,0.09-0.93,0.31l-0.42,0.38l-0.23-0.07l-1.67-1.92c-0.35-0.41-0.93-0.55-1.43-0.35l-1.29,0.53
                  c-0.08,0.04-0.16,0.08-0.23,0.13l-0.45,0.31l-0.77-0.33c-0.35-0.15-0.74-0.13-1.08,0.05l-2.3,1.17l-2.52-0.21l-1.18-0.28l-1.17-0.48
                  c-0.09-0.03-0.17-0.06-0.26-0.07l-1.37-0.23c-0.48-0.08-0.95,0.12-1.23,0.51l-0.77,1.09c-0.19,0.27-0.27,0.61-0.21,0.94l0.2,1.12
                  l-0.22,0.97c-0.04,0.2-0.04,0.4,0.01,0.6l0.23,0.87l-0.42,1.07l-0.65,0.82c-0.06,0.06-0.1,0.13-0.14,0.2l-0.78,1.49l-0.74,0.34
                  c-0.13,0.07-0.26,0.15-0.36,0.25l-0.88,0.87c-0.11,0.11-0.2,0.23-0.26,0.37l-0.54,1.19c-0.08,0.19-0.12,0.39-0.11,0.59l0.06,1.06
                  l-0.31,0.75l-0.18,0.26l-0.37-0.09c-0.36-0.09-0.75-0.02-1.05,0.21l-0.99,0.74c-0.07,0.05-0.13,0.11-0.19,0.18l-0.75,0.86
                  l-0.85,0.68l-1.03,0.44c-0.28,0.13-0.51,0.35-0.64,0.63l-0.47,1.04l-0.49,0.51l-0.88,0.26c-0.14,0.04-0.28,0.11-0.41,0.2l-1.1,0.84
                  c-0.08,0.06-0.16,0.13-0.22,0.21l-0.88,1.09l-0.77,0.82l-0.9,0.66c-0.1,0.08-0.19,0.17-0.27,0.27l-0.52,0.71l-0.25,0.09l-0.31-0.24
                  l-0.51-0.91c-0.17-0.29-0.44-0.5-0.77-0.59l-1.34-0.36c-0.03-0.01-0.06-0.01-0.1-0.02l-1.23-0.22c-0.28-0.05-0.56,0-0.8,0.13
                  l-1.03,0.54l-1.04,0.24c-0.12,0.03-0.23,0.07-0.34,0.13l-1.2,0.68c-0.2,0.12-0.36,0.28-0.47,0.48l-0.58,1.05l-1.16,1.87l-0.8,0.47
                  c-0.2,0.12-0.36,0.29-0.47,0.49l-0.54,1.02h-0.01l-0.84,0.73h-0.02l-0.6-0.66c-0.05-0.06-0.1-0.11-0.16-0.15l-0.91-0.7l-0.76-0.85
                  c-0.17-0.2-0.41-0.34-0.66-0.39l-2.07-0.42l-0.18-0.22l0.15-0.69c0.03-0.14,0.04-0.28,0.02-0.42l-0.16-1.29
                  c-0.01-0.06-0.02-0.11-0.03-0.16l-0.38-1.51v-1.05l0.33-2.43c0.03-0.23-0.01-0.46-0.1-0.67l-0.51-1.12l0.29-0.92l0.31-1.3
                  c0.15-0.63-0.21-1.27-0.82-1.47l-1.05-0.36l-1.18-1.08l0.06-0.65c0.04-0.35-0.07-0.7-0.3-0.96l-2.69-3.03
                  c-0.19-0.22-0.47-0.37-0.76-0.41l-1.47-0.2c-0.19-0.03-0.38-0.01-0.57,0.05l-0.61,0.21l-1.68-1.19c-0.13-0.09-0.29-0.16-0.45-0.19
                  l-1.07-0.24l-0.53-0.23l-0.12-0.3l0.23-0.79c0.18-0.62-0.14-1.27-0.74-1.51l-0.82-0.33l-0.55-0.74c-0.16-0.21-0.38-0.37-0.63-0.45
                  l-0.69-0.2l-0.4-0.75c-0.1-0.19-0.25-0.35-0.43-0.47l-1.07-0.68c-0.1-0.06-0.2-0.1-0.31-0.14l-0.82-0.25l-0.57-0.77l-0.85-0.99
                  l-0.87-0.9c-0.06-0.06-0.12-0.11-0.18-0.15l-1.04-0.73c-0.18-0.13-0.39-0.21-0.61-0.22l-0.94-0.09l-0.55-0.32l-1.02-1.92
                  c-0.09-0.16-0.22-0.31-0.37-0.42l-1.01-0.72c-0.03-0.02-0.05-0.03-0.08-0.05l0.04-0.8c0.02-0.37-0.13-0.72-0.4-0.97l-0.83-0.78
                  l-0.77-0.95c-0.02-0.04-0.05-0.07-0.08-0.1l-1.16-1.17l0.83-1.37c0.16-0.27,0.22-0.58,0.16-0.89l-0.18-0.91l0.26-0.77l0.66-1.33
                  l0.74-0.71c0.4-0.39,0.49-0.99,0.24-1.48l-0.59-1.13c-0.06-0.1-0.12-0.2-0.2-0.28l-0.78-0.82l-0.45-0.81l-0.26-1.09
                  c-0.04-0.15-0.11-0.3-0.2-0.43l-0.64-0.92l-0.08-0.92v-2.05c0-0.15-0.03-0.29-0.08-0.43l-0.45-1.24l-0.2-1.49
                  c-0.01-0.08-0.03-0.16-0.06-0.24l-0.64-1.83l-0.62-2.45c-0.14-0.5-0.57-0.86-1.08-0.91c-0.52-0.05-1,0.21-1.24,0.67l-0.63,1.21
                  l-0.41,0.61l-0.33-0.03l-0.76-0.53c-0.44-0.3-1.01-0.29-1.44,0.02l-1.02,0.74c-0.13,0.09-0.23,0.2-0.31,0.32l-0.81,1.23l-0.65,0.89
                  l-0.71,0.6l-0.95,0.38c-0.08,0.02-0.15,0.06-0.21,0.1l-5.9,3.77c-0.05,0.03-0.09,0.07-0.14,0.11l-0.78,0.67l-0.75,0.22l-0.67-0.32
                  c-0.06-0.03-0.13-0.05-0.19-0.07l-0.86-0.25l-1.35-1.64c-0.13-0.16-0.29-0.29-0.48-0.37l-1.1-0.47l-0.23-0.12l0.48-1.41
                  c0.08-0.24,0.09-0.51,0.02-0.76l-0.45-1.6l-0.32-0.88l0.16-0.45l0.7-0.63c0.11-0.1,0.2-0.22,0.26-0.34l0.6-1.13
                  c0.04-0.07,0.07-0.14,0.09-0.22l0.3-0.95l0.67-0.75c0.07-0.08,0.13-0.17,0.18-0.27l0.56-1.11c0.04-0.07,0.07-0.16,0.09-0.24
                  l0.27-1.04l0.63-1.05c0.09-0.15,0.15-0.32,0.17-0.5l0.15-1.29c0.02-0.11,0.01-0.22,0-0.33l-0.2-1.28l-0.04-0.82l1.1-1.79l0.7-1.02
                  c0.11-0.17,0.18-0.36,0.2-0.56l0.14-1.23c0.02-0.17,0.01-0.35-0.05-0.52l-0.27-0.87l0.16-0.91c0.01-0.03,0.01-0.06,0.01-0.1
                  l0.12-1.18l0.15-0.81l0.59-0.43c0.04-0.03,0.08-0.07,0.12-0.1l0.9-0.85c0.26-0.24,0.4-0.59,0.39-0.94l-0.07-1.99l0.56-0.56
                  l1.04-0.13c0.09-0.01,0.18-0.03,0.26-0.06l1.17-0.41c0.43-0.15,0.74-0.51,0.82-0.96l0.22-1.21c0.09-0.48-0.12-0.97-0.53-1.25
                  l-0.97-0.67l-0.66-0.83c-0.12-0.15-0.28-0.27-0.45-0.35l-1.31-0.61l-0.48-0.18v-0.01l-0.08-0.74l0.4-0.83
                  c0.13-0.27,0.15-0.58,0.07-0.87l-0.33-1.19c-0.15-0.56-0.63-0.94-1.24-0.92l-0.16,0.01L936.05,79c0.13,0.15,0.28,0.27,0.46,0.35
                  l1.07,0.46l0.85,0.7c0.32,0.27,0.75,0.36,1.14,0.24l1.19-0.34c0.45-0.13,0.78-0.49,0.88-0.94l0.53,0.01h0.12l2.42-0.24l0.91,0.19
                  l0.66,0.43l0.63,0.83c0.13,0.17,0.29,0.3,0.48,0.39l1.12,0.51l1.06,0.74c0.03,0.03,0.07,0.05,0.11,0.07l1.1,0.61
                  c0.09,0.05,0.18,0.09,0.27,0.11l1.35,0.38l0.98,0.32l0.38,0.32l0.1,0.81c0,0.04,0.01,0.08,0.02,0.11l0.27,1.25
                  c0.05,0.26,0.18,0.48,0.37,0.66l1.02,0.94c0.16,0.14,0.35,0.25,0.56,0.3l2.33,0.57l1.98,1.36c0.09,0.06,0.19,0.11,0.29,0.15
                  l1.47,0.49c0.06,0.01,0.11,0.03,0.16,0.04l2.44,0.48l2.01,1.82l0.93,0.92c0.4,0.39,1.01,0.47,1.5,0.2l0.63-0.37l0.73,0.19l0.94,0.58
                  c0.45,0.28,1.02,0.25,1.43-0.08l1.09-0.86c0.1-0.07,0.18-0.16,0.24-0.25l0.77-1.07l0.46-0.74l0.29-0.12l0.69,0.35
                  c0.36,0.19,0.79,0.18,1.15,0l1.19-0.62c0.12-0.07,0.24-0.15,0.33-0.26l1.05-1.13c0.13-0.13,0.22-0.28,0.27-0.45l0.43-1.27
                  c0.05-0.13,0.07-0.28,0.07-0.42l-0.02-1.15l0.63-0.95c0.08-0.11,0.13-0.24,0.17-0.37l0.11-0.44l0.58-0.08
                  c0.52-0.08,0.94-0.49,1.04-1.01l0.27-1.45c0.02-0.14,0.03-0.27,0.01-0.41l-0.32-2.42l0.19-0.68l0.29-0.16l0.2,1.14
                  c0.05,0.31,0.22,0.59,0.47,0.79l1.4,1.06c0.17,0.12,0.36,0.2,0.57,0.24l1.18,0.18l0.67,0.31l0.45,0.63l0.2,1.08
                  c0.1,0.55,0.54,0.96,1.09,1.02l1.43,0.16c0.12,0.02,0.24,0.01,0.36-0.01l1.37-0.25c0.09-0.02,0.18-0.04,0.26-0.08l1.21-0.5l1.11-0.3
                  c0.27-0.07,0.51-0.24,0.68-0.46l0.65-0.86l0.98-0.62c0.12-0.08,0.23-0.17,0.32-0.29l0.75-0.95l2.05-1.15
                  c0.08-0.04,0.15-0.1,0.22-0.16l1.03-0.93l0.68-0.7l1.03-0.39l0.84-0.08l1.06,0.35c0.1,0.03,0.21,0.05,0.32,0.06l3.72,0.13l0.64,0.27
                  l0.59,0.74c0.22,0.27,0.55,0.44,0.9,0.47l1.25,0.08c0.25,0.01,0.52-0.05,0.75-0.19l0.64-0.41l0.42,0.08l0.4,0.32l0.08,0.29
                  l-0.36,0.62c-0.06,0.11-0.11,0.22-0.14,0.34l-0.3,1.25c-0.1,0.41,0.02,0.85,0.32,1.15l0.89,0.93c0.12,0.13,0.27,0.23,0.43,0.3
                  l1.15,0.46c0.13,0.05,0.26,0.08,0.39,0.09l-0.22,0.11c-0.15,0.07-0.29,0.17-0.4,0.3l-0.85,0.99c-0.2,0.23-0.31,0.52-0.31,0.83
                  l0.01,1.25c0,0.33,0.14,0.65,0.38,0.88l0.88,0.87c0.04,0.04,0.08,0.07,0.12,0.1l1.05,0.8c0.13,0.1,0.27,0.17,0.42,0.21l0.52,0.15
                  l0.03,0.09l-0.34,0.82l-0.41,1.18c-0.09,0.25-0.09,0.51-0.02,0.77l0.36,1.21c0.06,0.2,0.18,0.39,0.34,0.54l0.9,0.87
                  c0.31,0.3,0.75,0.41,1.16,0.32l1.37-0.34c0.1-0.02,0.2-0.06,0.3-0.11l1.1-0.58l1.03-0.38l0.76-0.16l0.23,0.09l-1.12,1.4
                  c-0.13,0.16-0.21,0.35-0.24,0.56l-0.2,1.22c-0.05,0.29,0.01,0.6,0.16,0.85l0.67,1.09c0.25,0.4,0.69,0.63,1.17,0.6l1.35-0.11
                  c0.22-0.02,0.43-0.1,0.61-0.22l0.83-0.57l0.21-0.04l-0.04,1.12c-0.01,0.16,0.02,0.32,0.07,0.47l0.34,0.96l-0.05,0.75l-0.41,0.92
                  l-0.66,0.85c-0.36,0.47-0.35,1.11,0.02,1.56l0.79,0.96c0.41,0.5,1.14,0.61,1.67,0.24l1.07-0.73c0.04-0.03,0.08-0.06,0.12-0.09
                  l0.78-0.67l0.16-0.07l0.88,0.96l0.17,0.9c0.04,0.23,0.15,0.44,0.3,0.61l0.81,0.89l0.71,1.04c0.24,0.35,0.65,0.56,1.09,0.54
                  l2.44-0.07l1.23,0.03c0.47,0.01,0.95-0.27,1.16-0.72l0.47-1l0.43-0.59l0.87-0.32c0.22-0.08,0.41-0.22,0.56-0.4l0.76-0.98
                  c0.08-0.11,0.14-0.23,0.19-0.36l0.24-0.69l0.44-0.22l0.64,0.05l0.55,0.32l0.98,1.92c0.05,0.1,0.11,0.19,0.17,0.27l0.79,0.9
                  l0.63,0.98c0.08,0.13,0.18,0.24,0.3,0.33l0.54,0.41l-0.09,0.42c-0.01,0.05-0.02,0.11-0.03,0.17l-0.09,1.17l-0.12,0.85l-0.55,0.33
                  c-0.1,0.05-0.19,0.12-0.27,0.21l-0.85,0.9c-0.36,0.37-0.45,0.93-0.23,1.4s0.72,0.74,1.23,0.71l1.05-0.09l1.08,0.21
                  c0.19,0.03,0.38,0.03,0.56-0.02l1.28-0.34c0.39-0.1,0.7-0.38,0.84-0.76l0.25-0.62l0.53-0.16l0.92-0.17l0.63,0.21l-0.11,0.18
                  c-0.11,0.16-0.18,0.34-0.2,0.53l-0.16,1.28c-0.05,0.39,0.09,0.78,0.37,1.05l1.39,1.4l-0.01,0.8c-0.01,0.31,0.11,0.61,0.31,0.85
                  l0.88,1c0.09,0.09,0.19,0.18,0.3,0.25l0.99,0.58l1.39,1.77c0.03,0.03,0.06,0.07,0.09,0.1l0.87,0.92c0.35,0.36,0.88,0.49,1.35,0.31
                  l1.1-0.42l1.15-0.3c0.1-0.03,0.21-0.07,0.3-0.13l1.1-0.63c0.04-0.02,0.08-0.05,0.12-0.08l0.75-0.55l0.96-0.14
                  c0.12-0.02,0.23-0.05,0.33-0.1l1.2-0.54c0.13-0.06,0.24-0.13,0.34-0.23l0.74-0.69l0.95-0.35c0.13-0.05,0.25-0.12,0.36-0.21
                  l0.72-0.61l0.78-0.19l0.91,0.02l1.13,0.35l1.02,0.24l0.44,0.92l0.35,1.19c0.03,0.08,0.06,0.16,0.1,0.24l0.62,1.13
                  c0.08,0.14,0.19,0.27,0.32,0.37l0.93,0.75l0.77,0.77l0.46,1.1c0.03,0.08,0.07,0.16,0.12,0.23l0.97,1.4l0.87,1.07
                  C1084.97,131.68,1085.14,131.8,1085.32,131.88z"/>
                <title>Ľubovňa</title>
              </Link>

              {/* Presovsky kezmarok */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Kežmarok")}>
                <path style={{ fill: crimesInRegionPresovsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionPresovsky[2].crimePercent)] ? crimesInRegionPresovsky[2].color : "#D3D3D3" }}
                  d="M988.9,183.57l-0.41,1.04l-1.32,2.55l-1.1,1.39l-1.3,1.48l-1.09,0.93c-0.03,0.03-0.07,0.06-0.1,0.09l-1.58,1.67
                  l-1.02,0.76c-0.04,0.03-0.08,0.06-0.11,0.1l-0.88,0.84l-0.98,0.84c-0.11,0.09-0.2,0.2-0.27,0.33l-1.17,2.06l-0.88,0.61l-2.27,1.24
                  c-0.12,0.06-0.22,0.14-0.31,0.23l-0.97,1.01c-0.11,0.11-0.19,0.24-0.25,0.38l-0.25,0.59l-0.61,0.18c-0.23,0.07-0.43,0.2-0.59,0.37
                  l-1.01,1.16l-2.15,1.66l-1.25,0.51c-0.16,0.07-0.31,0.17-0.43,0.3l-0.77,0.8l-0.91,0.59c-0.2,0.13-0.36,0.31-0.45,0.52l-0.52,1.12
                  c-0.17,0.37-0.16,0.8,0.05,1.15l0.64,1.11c0.14,0.25,0.36,0.44,0.63,0.55l0.24,0.09l-0.31,1.59l-0.24,0.85l-0.6,0.64l-0.88,0.75
                  c-0.21,0.19-0.35,0.43-0.4,0.68c-0.24,0.18-0.42,0.44-0.48,0.75l-0.52,2.56c-0.11,0.59,0.21,1.17,0.77,1.39l0.49,0.2
                  c-0.12,0.05-0.24,0.12-0.34,0.21l-0.91,0.8l-0.82,0.49l-1.05,0.27c-0.11,0.03-0.21,0.07-0.31,0.13l-0.74,0.43l-0.79-0.13
                  c-0.29-0.04-0.6,0.02-0.85,0.18l-1.04,0.65l-1.12,0.56c-0.26,0.13-0.45,0.34-0.57,0.58l-0.84-0.58c-0.08-0.05-0.17-0.1-0.26-0.14
                  l-0.75-0.29l-0.43-0.74c-0.01-0.03-0.03-0.06-0.05-0.09l-0.74-1.07c-0.12-0.17-0.28-0.31-0.47-0.41l-1.15-0.58
                  c-0.43-0.22-0.95-0.16-1.34,0.14l-0.97,0.77l-1.03,0.74c-0.12,0.09-0.22,0.2-0.3,0.32l-0.7,1.02c-0.05,0.07-0.09,0.15-0.12,0.24
                  l-0.5,1.25c-0.09,0.23-0.11,0.49-0.06,0.73l0.25,1.15l0.03,0.25l-0.35,0.16c-0.04,0.02-0.09,0.04-0.13,0.06l-1.06,0.64l-1.7,0.82
                  l-0.86-0.59c-0.27-0.2-0.6-0.29-0.94-0.23l-0.36,0.05l-0.1-0.3c-0.19-0.53-0.73-0.86-1.27-0.83l-1.32,0.1l-1.34,0.15
                  c-0.1,0.01-0.2,0.03-0.3,0.07l-0.81,0.31l-1.95-0.49l-1.59-0.52c-0.15-0.05-0.31-0.07-0.47-0.06l-0.84,0.05l-0.08-0.17l0.01-0.94
                  c0-0.05,0-0.09-0.01-0.14l-0.13-1.24c-0.03-0.33-0.2-0.64-0.46-0.85s-0.6-0.3-0.93-0.26l-1.43,0.17c-0.16,0.02-0.31,0.07-0.45,0.14
                  l-0.85,0.47h-0.33l-0.26-0.47c-0.02-0.04-0.04-0.07-0.07-0.11l-0.72-1.02c-0.09-0.13-0.21-0.24-0.34-0.33l-1.82-1.17l-0.39-0.87
                  c-0.05-0.11-0.12-0.21-0.2-0.3l-0.81-0.93c-0.03-0.04-0.07-0.07-0.1-0.11l-0.81-0.73l-1.2-1.91l-0.52-1.17l-0.52-1.06l-0.06-0.21
                  l0.56-0.38c0.13-0.08,0.23-0.19,0.32-0.31l1.16-1.59l1.11-1.4c0.08-0.1,0.14-0.21,0.18-0.33l0.47-1.21
                  c0.13-0.33,0.12-0.69-0.04-1.01c-0.16-0.31-0.44-0.55-0.78-0.64l-1.24-0.35c-0.06-0.02-0.13-0.03-0.19-0.04l-0.95-0.12l-0.78-0.65
                  l-0.85-0.84c-0.4-0.4-1.01-0.48-1.5-0.21l-0.54,0.31l-1.71-0.85c-0.24-0.12-0.5-0.16-0.76-0.13l0.03-0.17
                  c0.07-0.53-0.19-1.05-0.67-1.29l-2.36-1.24l-0.77-0.63l-2.21-2.97l-0.84-1c-0.03-0.04-0.06-0.07-0.1-0.1l-1.74-1.65l-0.86-0.92
                  c-0.05-0.06-0.11-0.11-0.17-0.15l-1.02-0.76l-0.59-0.65l-0.38-0.96c-0.04-0.12-0.1-0.22-0.18-0.32l-0.76-0.97
                  c-0.1-0.13-0.22-0.24-0.36-0.32l-1.07-0.61c-0.03-0.01-0.06-0.03-0.09-0.04l-0.5-0.23v-0.22l0.26-0.41h0.66
                  c0.48-0.03,0.87-0.24,1.1-0.63c0.22-0.39,0.21-0.88-0.02-1.26l-1.23-2.02l0.08-0.83l0.39-0.84l0.22-0.23
                  c0.07,0.08,0.16,0.15,0.26,0.2l1,0.55l1.04,0.69c0.09,0.06,0.2,0.11,0.31,0.15l1.24,0.4l1.02,0.26l0.76,0.46l0.5,0.55l0.26,1.01
                  c0.1,0.41,0.4,0.75,0.81,0.88c0.4,0.14,0.84,0.06,1.18-0.2l0.98-0.78l0.98-0.42c0.28-0.12,0.5-0.33,0.63-0.6l0.54-1.1
                  c0.15-0.31,0.17-0.67,0.05-1c-0.13-0.32-0.38-0.58-0.7-0.71l-0.76-0.3l-0.12-0.21l0.19-0.17c0.32-0.27,0.48-0.68,0.43-1.09
                  l-0.16-1.4c-0.01-0.1-0.03-0.19-0.06-0.28l-0.19-0.52l0.36-0.26l0.58-0.12l0.47,0.39c0.13,0.12,0.29,0.2,0.46,0.25l1.18,0.34
                  l1.27,0.59c0.45,0.2,0.98,0.12,1.35-0.21l1.05-0.94c0.36-0.32,0.51-0.82,0.37-1.29c0.21-0.07,0.41-0.2,0.56-0.37l0.91-1.08
                  c0.11-0.14,0.19-0.3,0.24-0.47l1.08,0.47l0.88,0.66c0.08,0.06,0.16,0.11,0.24,0.14l1.13,0.5c0.37,0.17,0.79,0.14,1.14-0.06
                  c0.34-0.2,0.57-0.55,0.61-0.95l0.04-0.36l0.42-0.09c0.45-0.1,0.81-0.44,0.93-0.88c0.13-0.45,0-0.92-0.34-1.24l-0.93-0.89
                  c-0.33-0.32-0.8-0.43-1.23-0.29l-0.55,0.17l-1.68-1.67l-1.07-1l-0.73-0.58l-0.26-0.6l0.04-0.94c0-0.05,0-0.11-0.01-0.16l-0.1-1.18
                  l0.36-1.08c0.12-0.36,0.08-0.75-0.12-1.07c-0.02-0.03-0.05-0.06-0.07-0.1c0.25-0.02,0.5-0.13,0.69-0.3l1.05-0.92
                  c0.34-0.29,0.49-0.74,0.41-1.18l-0.24-1.24l-0.01-0.77l0.46-0.29c0.39-0.25,0.62-0.68,0.59-1.14l-0.18-2.55
                  c-0.03-0.32-0.17-0.61-0.4-0.83l-0.89-0.82l-0.71-0.9c-0.15-0.2-0.36-0.34-0.59-0.42l-2.67-0.82c-0.16-0.05-0.33-0.06-0.5-0.04
                  l-0.77,0.09l-0.49-0.44c-0.31-0.29-0.75-0.4-1.15-0.29l-0.67,0.17l-0.56-0.37c-0.15-0.1-0.31-0.17-0.49-0.19l-0.35-0.06l-0.05-0.31
                  l0.04-1.28l-0.04-1.28c-0.01-0.18-0.05-0.36-0.14-0.53l-0.54-1.06l-0.19-1.18c-0.02-0.14-0.07-0.29-0.14-0.41l-0.6-1.07l-0.18-0.92
                  l0.04-1.35l0.15-1.26c0.04-0.37-0.07-0.73-0.33-1l-0.78-0.84l-0.53-0.64l0.07-0.56l0.43-1.23c0.03-0.1,0.06-0.2,0.07-0.31l0.09-1.17
                  l0.44-1.28c0.09-0.25,0.09-0.52,0.02-0.77l-0.34-1.11l-0.14-1.63c-0.04-0.33-0.2-0.64-0.45-0.85l-1.06-0.88
                  c-0.37-0.31-0.89-0.38-1.33-0.17l-0.89,0.41l-0.79,0.03l-1.03-0.33l-1.25-0.5c-0.17-0.07-0.36-0.1-0.55-0.09l-1.29,0.09
                  c-0.05,0.01-0.1,0.01-0.15,0.02l-1.1,0.22l-0.8-0.28l-1.21-0.52l-1.07-0.4l-0.95-0.61c-0.22-0.13-0.47-0.2-0.74-0.19l-1.27,0.07
                  l-1.21-0.06c-0.07,0-0.14,0-0.21,0.01l-1.36,0.16l-1.12-0.09l-1.19-0.2c-0.38-0.07-0.78,0.05-1.06,0.32l-0.9,0.84
                  c-0.04,0.03-0.08,0.07-0.11,0.11l-0.74,0.89l-0.97,0.58c-0.14,0.08-0.26,0.19-0.35,0.31l-0.51,0.67l-0.56,0.17l-0.67-0.18
                  l-0.79-0.62c-0.05-0.03-0.1-0.07-0.16-0.1l-2.44-1.36l-0.53-0.57l-0.09-0.61l0.36-0.95c0.15-0.36,0.11-0.78-0.1-1.11l-0.73-1.16
                  l-0.71-1c-0.1-0.15-0.23-0.26-0.38-0.35l-1.19-0.71c-0.05-0.04-0.11-0.06-0.17-0.09l-0.68-0.27l-0.27-0.72
                  c-0.04-0.08-0.08-0.17-0.13-0.24l-0.5-0.76l-0.05-0.81l0.42-0.88c0.1-0.22,0.14-0.46,0.11-0.7l-0.19-1.5l-0.03-2.47h-0.02l0.73-0.82
                  c0.13-0.14,0.22-0.31,0.27-0.5l0.42-1.51c0.03-0.09,0.04-0.18,0.05-0.28l0.03-0.8l0.38-0.42l1.15-0.19l1.25-0.29
                  c0.06-0.02,0.12-0.03,0.17-0.06l1.21-0.47l1.16-0.52c0.4-0.18,0.68-0.56,0.73-1l0.07-0.61l0.5-0.25c0.09-0.05,0.17-0.1,0.24-0.16
                  l0.2-0.16l0.21,0.16l0.79,0.87c0.23,0.25,0.55,0.4,0.88,0.41l1.31,0.05c0.18,0.01,0.35-0.02,0.51-0.09l1.23-0.49
                  c0.19-0.07,0.35-0.19,0.48-0.34l0.3-0.34l0.58,0.2c0.32,0.1,0.68,0.08,0.98-0.08l0.39-0.21l0.35,0.33c0.12,0.11,0.26,0.2,0.42,0.25
                  l1.45,0.53c0.07,0.03,0.14,0.05,0.21,0.06l1.23,0.22c0.34,0.06,0.69-0.02,0.97-0.23l1.06-0.8c0.05-0.04,0.1-0.08,0.14-0.12
                  l0.52-0.53l0.65-0.02l1.22,0.16c0.08,0.01,0.17,0.01,0.25,0.01l1.41-0.1l0.84,0.1l0.78,0.54c0.04,0.02,0.07,0.05,0.11,0.07
                  l0.96,0.52l0.83,0.76c0.06,0.06,0.13,0.1,0.19,0.14l1.07,0.66c0.24,0.15,0.53,0.22,0.8,0.18l1.33-0.15c0.3-0.03,0.59-0.18,0.79-0.41
                  l0.85-0.95c0.05-0.05,0.1-0.12,0.14-0.18l0.73-1.2c0.02-0.04,0.05-0.09,0.07-0.14l0.53-1.16c0.07-0.17,0.11-0.35,0.11-0.54
                  l-0.02-1.2l0.71-3.9c0.02-0.15,0.03-0.3,0-0.44l-0.24-1.46c-0.01-0.06-0.02-0.12-0.04-0.17l-0.44-1.41
                  c-0.02-0.07-0.05-0.14-0.08-0.2l-0.38-0.74l0.11-0.67l0.42-0.9l0.58-1.09c0.02-0.05,0.05-0.09,0.06-0.13l0.43-1.1l0.52-1.04
                  c0.18-0.35,0.18-0.76,0.01-1.11s-0.5-0.6-0.88-0.68l-1.24-0.24c-0.15-0.03-0.31-0.03-0.45,0l-0.35,0.06l0.21-0.61
                  c0.01-0.05,0.03-0.1,0.04-0.15l0.2-1.08l0.4-0.47l0.49-0.09l1.02,0.35c0.09,0.02,0.18,0.04,0.26,0.05l1.24,0.14l1.22,0.25
                  c0.17,0.04,0.35,0.04,0.51,0l0.94-0.19l0.8,0.3l0.91,0.6c0.21,0.14,0.45,0.19,0.7,0.21l1.43-0.01c0.21,0,0.41-0.05,0.59-0.15
                  l1.15-0.63c0.23-0.13,0.41-0.32,0.52-0.56l0.55-1.14c0.07-0.14,0.12-0.3,0.13-0.46l0.03-0.55l0.39,0.65l0.13,0.92l-0.09,2.51
                  c-0.01,0.29,0.08,0.57,0.26,0.79l0.91,1.18c0.21,0.27,0.51,0.44,0.85,0.48l1.52,0.17c0.14,0.02,0.28,0.01,0.42-0.02l2.59-0.62
                  l1.32-0.22c0.15-0.02,0.29-0.07,0.42-0.15l1.29-0.74c0.12-0.06,0.22-0.15,0.31-0.25l0.93-1.04c0.19-0.21,0.3-0.48,0.32-0.77
                  l0.06-1.25v-0.13l-0.04-0.64l0.88,0.51l0.46,0.67l0.34,1.07c0.1,0.35,0.36,0.64,0.7,0.78s0.72,0.13,1.05-0.03l0.17-0.09l-0.01,0.03
                  l1.2,0.45l0.96,0.45l0.59,0.75c0.08,0.1,0.17,0.18,0.28,0.25l0.18,0.13l-1.36,0.17c-0.28,0.03-0.54,0.16-0.73,0.36l-1.26,1.26
                  c-0.25,0.25-0.39,0.59-0.37,0.95l0.09,1.91l-0.44,0.42l-0.93,0.68c-0.26,0.19-0.43,0.47-0.49,0.78l-0.25,1.35
                  c0,0.04-0.01,0.07-0.01,0.11l-0.12,1.18l-0.21,1.16c-0.04,0.2-0.02,0.4,0.04,0.6l0.29,0.91l-0.08,0.66l-1.87,2.97
                  c-0.13,0.22-0.2,0.46-0.19,0.71l0.05,1.27c0,0.05,0.01,0.1,0.02,0.14l0.18,1.18l-0.1,0.86l-0.58,0.97c-0.06,0.1-0.11,0.21-0.14,0.32
                  l-0.28,1.08l-0.43,0.85l-0.74,0.83c-0.11,0.13-0.2,0.29-0.25,0.46l-0.35,1.1l-0.44,0.83l-0.78,0.7c-0.16,0.15-0.27,0.33-0.34,0.53
                  l-0.4,1.17c-0.09,0.26-0.09,0.55,0,0.82l0.44,1.21l0.32,1.13l-0.33,0.91c-0.01,0.05-0.03,0.1-0.04,0.15l-0.28,1.24
                  c-0.13,0.56,0.15,1.14,0.67,1.39l1.17,0.58l0.87,0.37l1.46,1.76c0.16,0.18,0.37,0.31,0.6,0.38l1.11,0.32l1.02,0.49
                  c0.28,0.13,0.6,0.16,0.9,0.07l1.47-0.44c0.17-0.05,0.32-0.14,0.46-0.26l0.91-0.79l5.7-3.64l1.04-0.41c0.12-0.05,0.24-0.12,0.35-0.21
                  l0.98-0.83c0.07-0.06,0.14-0.13,0.2-0.21l0.78-1.06l0.71-1.09l0.12-0.09l0.3,0.21c0.18,0.12,0.38,0.19,0.59,0.21l1.39,0.13
                  c0.46,0.05,0.9-0.16,1.15-0.54l0.06-0.07l0.51,1.46l0.2,1.5c0.01,0.09,0.03,0.17,0.07,0.26l0.42,1.16v1.98l0.12,1.31
                  c0.01,0.22,0.09,0.44,0.22,0.62l0.7,1l0.24,1.03c0.03,0.11,0.07,0.22,0.13,0.32l0.62,1.1c0.05,0.09,0.11,0.18,0.18,0.25l0.76,0.8
                  l0.08,0.15l-0.22,0.21c-0.1,0.1-0.18,0.21-0.25,0.34l-0.8,1.6c-0.02,0.05-0.05,0.11-0.07,0.16l-0.39,1.17
                  c-0.06,0.21-0.08,0.43-0.04,0.64l0.15,0.75l-1.08,1.82c-0.28,0.49-0.21,1.1,0.19,1.5l1.79,1.82l0.78,0.97
                  c0.03,0.05,0.07,0.09,0.12,0.13l0.47,0.45l-0.02,0.55l-0.34,1.27c-0.13,0.51,0.07,1.06,0.51,1.35c0.44,0.3,1.01,0.29,1.44-0.02
                  l0.26-0.2l1.08,1.97c0.1,0.21,0.26,0.38,0.46,0.49l1.09,0.65c0.16,0.1,0.34,0.16,0.53,0.17l0.9,0.09l0.67,0.47l0.73,0.75l0.74,0.86
                  l0.76,1.04c0.16,0.22,0.39,0.38,0.65,0.46l1.05,0.32l0.65,0.41l0.49,0.91c0.15,0.29,0.42,0.51,0.73,0.61l0.79,0.24l0.52,0.69
                  c0.14,0.18,0.32,0.32,0.54,0.41l0.09,0.04l-0.03,0.11c-0.08,0.26-0.06,0.55,0.04,0.8l0.46,1.18c0.12,0.31,0.35,0.56,0.66,0.69
                  l1.12,0.5c0.08,0.03,0.15,0.05,0.24,0.07l0.95,0.21l1.94,1.39c0.33,0.24,0.75,0.3,1.14,0.18l0.95-0.32l0.72,0.1l2.01,2.27
                  l-0.07,0.72c-0.04,0.39,0.1,0.77,0.38,1.04l1.88,1.72c0.13,0.11,0.27,0.19,0.43,0.24l0.21,0.08l-0.43,1.39
                  c-0.09,0.3-0.08,0.62,0.05,0.9l0.57,1.24l-0.3,2.1c-0.01,0.06-0.01,0.13-0.01,0.19v1.3c0,0.1,0.01,0.21,0.04,0.31l0.4,1.58l0.12,1
                  l-0.23,1.07c-0.08,0.38,0.01,0.77,0.25,1.06l0.85,1.04c0.19,0.23,0.45,0.38,0.74,0.44l2.05,0.41l0.57,0.63
                  c0.05,0.06,0.11,0.11,0.17,0.16l0.91,0.7L988.9,183.57z"/>
                <title>Kežmarok</title>
              </Link>

              {/* Presovsky sabinov */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Sabinov")}>
                <path
                  d="M1140.98,207.79l-0.14,0.33l-0.25-0.07c-0.04-0.01-0.07-0.02-0.11-0.02l-1.27-0.22
                  c-0.23-0.04-0.48-0.01-0.7,0.08l-2.38,1c-0.24,0.09-0.44,0.26-0.58,0.48l-0.67,1.06c-0.32,0.52-0.23,1.2,0.23,1.6l0.21,0.19
                  l-0.76-0.13c-0.06-0.01-0.12-0.02-0.18-0.02l-1.25-0.03c-0.05,0-0.11,0-0.16,0.01l-1.29,0.13l-0.63,0.09l-0.53-0.5
                  c-0.02-0.03-0.05-0.05-0.07-0.07l-0.9-0.73l-0.7-0.88c-0.23-0.29-0.57-0.46-0.94-0.47l-1.23-0.04c-0.4,0-0.8,0.17-1.04,0.5
                  c-0.25,0.33-0.32,0.76-0.19,1.14l0.39,1.17c0.07,0.2,0.18,0.37,0.33,0.52l0.92,0.87l0.16,0.21l-0.48,0.75
                  c-0.16,0.25-0.23,0.55-0.19,0.85l0.17,1.27c0.01,0.06,0.02,0.11,0.04,0.17l-2.55-0.16h-0.16l-1.45,0.09
                  c-0.49,0.03-0.91,0.34-1.09,0.8l-0.4,1.03l-0.78,0.53c-0.04,0.03-0.08,0.06-0.12,0.1l-0.93,0.81c-0.09,0.08-0.17,0.17-0.24,0.28
                  l-0.72,1.15l-0.69,0.49l-1.02,0.41c-0.18,0.07-0.34,0.18-0.46,0.32l-0.78,0.84l-0.39,0.31l-1.43-0.7l-0.88-0.72
                  c-0.27-0.22-0.63-0.32-0.97-0.27c-0.35,0.05-0.66,0.25-0.86,0.54l-0.73,1.08c-0.07,0.11-0.12,0.23-0.16,0.35l-0.32,1.1l-0.35,0.6
                  l-0.51-0.9c-0.25-0.44-0.72-0.68-1.22-0.63s-0.91,0.39-1.06,0.87l-0.18,0.56l-0.41,0.13l-0.72-0.01l-1.1-0.83
                  c-0.27-0.2-0.6-0.29-0.94-0.24c-0.33,0.05-0.63,0.24-0.82,0.51l-0.84,1.16c-0.23,0.31-0.3,0.7-0.19,1.07l0.46,1.63l0.18,1.43
                  c0,0.03,0.01,0.05,0.01,0.07c-0.04-0.03-0.09-0.05-0.14-0.08l-1.88-0.95l-0.88-2.05c-0.1-0.24-0.28-0.45-0.51-0.58l-1.28-0.76
                  c-0.36-0.21-0.79-0.24-1.17-0.06l-0.87,0.4h-0.01l0.28-0.58c0.21-0.43,0.16-0.93-0.13-1.31l-0.76-0.99
                  c-0.04-0.05-0.08-0.1-0.12-0.14l-0.98-0.93l-0.8-0.84l-0.8-1.05c-0.05-0.07-0.11-0.13-0.17-0.19l-1.04-0.9
                  c-0.12-0.1-0.25-0.17-0.4-0.23l-0.4-0.14l0.01-0.44c0.01-0.39-0.17-0.76-0.47-1.01l-0.64-0.51l-0.17-0.8
                  c-0.01-0.05-0.02-0.1-0.04-0.15l-0.46-1.31c-0.04-0.11-0.09-0.22-0.16-0.32l-0.64-0.89l-0.44-1.04c-0.05-0.11-0.12-0.22-0.2-0.32
                  l-0.84-0.99c-0.11-0.13-0.25-0.24-0.41-0.32l-1.22-0.58c-0.2-0.09-0.42-0.13-0.64-0.12l-1.34,0.11l-0.51,0.07l0.72-0.81
                  c0.35-0.37,0.45-0.91,0.24-1.38c-0.2-0.46-0.65-0.75-1.18-0.74l-1.43,0.05l-1.56-0.28l-1.43-0.15c-0.57-0.06-1.12,0.28-1.31,0.83
                  l-0.38,1.1l-0.6,1.15c-0.03,0.05-0.05,0.11-0.07,0.16l-0.31,0.89l-0.53,0.32l-1.16-0.07l-0.8,0.01l-0.83-0.71
                  c-0.24-0.21-0.55-0.32-0.87-0.3l-1.29,0.06c-0.2,0.01-0.39,0.07-0.57,0.17l-0.84,0.49l-0.07-0.02l-0.1-0.6
                  c-0.05-0.34-0.25-0.65-0.53-0.84l-1.01-0.67l-0.67-0.57l-0.15-0.81c-0.03-0.17-0.1-0.34-0.2-0.48l-1.07-1.54
                  c-0.32-0.46-0.89-0.65-1.42-0.48c-0.52,0.17-0.87,0.67-0.86,1.22l0.03,1.15l-0.54,0.47c-0.16,0.15-0.28,0.33-0.35,0.53l-0.82,2.44
                  l-1.65,0.97l-0.37-0.11l-1.01-1.13l-1.3-1.34c-0.12-0.12-0.26-0.21-0.41-0.28l-1.1-0.47l-1.21-0.65c-0.18-0.1-0.35-0.15-0.6-0.15
                  l-1.3,0.01l-1.31-0.22c-0.08-0.02-0.16-0.02-0.24-0.02l-1.27,0.03c-0.25,0.01-0.5,0.09-0.71,0.24l-1.04,0.77l-0.93,0.36l-1.74-0.07
                  c-0.37-0.01-0.72,0.13-0.97,0.41l-0.69,0.75l-0.94,0.21c-0.03,0.01-0.07,0.02-0.1,0.03l-1.19,0.37l-0.39,0.09l-0.5-0.73l-0.44-1.02
                  c-0.06-0.14-0.14-0.26-0.24-0.36l-1.24-1.33l-1.14-1.06l-0.81-1l-0.57-1.1l-0.97-1.6l-0.42-1.09c-0.05-0.13-0.13-0.26-0.22-0.36
                  l-0.89-1.04c-0.05-0.06-0.11-0.12-0.17-0.17l-1.12-0.89l-0.93-0.69l-1.02-0.94c-0.22-0.2-0.5-0.31-0.79-0.33l-1.26-0.06
                  c-0.13-0.01-0.26,0.01-0.38,0.04l-1.23,0.33c-0.09,0.02-0.16,0.05-0.24,0.09l-1.1,0.55c-0.12,0.06-0.22,0.14-0.31,0.23l-0.1,0.09
                  l-0.75-0.99l-0.36-1.11c-0.05-0.17-0.14-0.32-0.26-0.45l-0.76-0.85l-0.81-1.05c-0.14-0.18-0.32-0.32-0.53-0.4l-1.16-0.45
                  c-0.37-0.15-0.79-0.11-1.13,0.11l-0.76,0.5l-0.97-0.04l-1.39,0.01l-1.28-0.11c-0.44-0.04-0.87,0.17-1.13,0.54l-0.5,0.72l-0.18,0.08
                  l-0.49-0.34c-0.3-0.21-0.67-0.27-1.03-0.18l-0.89,0.24l-0.41-0.13l-0.5-0.78c-0.06-0.1-0.14-0.2-0.23-0.28l-0.96-0.83
                  c-0.03-0.02-0.06-0.05-0.09-0.07l-5.32-3.84l-2.04-1.67l-1.84-1.94l-3.04-3.56l0.01-0.02l0.75-0.44c0.17-0.1,0.32-0.24,0.42-0.41
                  l0.68-1.06l1.13-1.94l0.74-0.41l1.02-0.24c0.11-0.03,0.21-0.06,0.31-0.12l0.78-0.41l0.76,0.13l0.78,0.21l0.38,0.68
                  c0.09,0.14,0.2,0.27,0.33,0.37l1.07,0.82c0.35,0.27,0.81,0.33,1.21,0.18l1.24-0.48c0.22-0.09,0.41-0.24,0.55-0.43l0.63-0.85
                  l0.84-0.61c0.06-0.05,0.12-0.1,0.17-0.16l0.91-0.97l0.84-1.03l0.81-0.61l0.99-0.29c0.21-0.06,0.4-0.18,0.56-0.34l0.86-0.91
                  c0.09-0.1,0.17-0.22,0.23-0.34l0.36-0.79l0.74-0.32c0.1-0.05,0.2-0.11,0.28-0.18l1.07-0.86c0.06-0.04,0.11-0.09,0.16-0.15l0.73-0.84
                  l0.41-0.3l0.64,0.15c0.51,0.13,1.04-0.07,1.33-0.5l0.74-1.08c0.05-0.07,0.09-0.15,0.12-0.23l0.47-1.13c0.08-0.17,0.11-0.36,0.1-0.55
                  l-0.06-1.04l0.32-0.7l0.56-0.56l0.93-0.42c0.25-0.12,0.46-0.32,0.59-0.56l0.92-1.76l0.7-0.88c0.08-0.1,0.15-0.21,0.2-0.33l0.63-1.63
                  c0.09-0.24,0.11-0.51,0.04-0.77l-0.25-0.96l0.21-0.92c0.04-0.17,0.04-0.34,0.01-0.5l-0.16-0.86l0.02-0.02l0.46,0.08l1.13,0.46
                  c0.06,0.02,0.12,0.04,0.18,0.06l1.37,0.33c0.06,0.01,0.13,0.02,0.2,0.03l2.9,0.24c0.24,0.03,0.47-0.02,0.68-0.12l1.61-0.79l0.56-0.3
                  l0.86,0.37c0.39,0.17,0.85,0.12,1.2-0.12l0.9-0.63l0.33-0.13l1.34,1.53c0.16,0.17,0.35,0.3,0.57,0.36l1.23,0.38
                  c0.42,0.13,0.87,0.03,1.2-0.25l0.55-0.49l0.5,0.04l0.46,0.28l0.01,0.01l-0.52,0.38c-0.06,0.05-0.12,0.1-0.17,0.15l-0.92,0.97
                  c-0.29,0.3-0.4,0.73-0.31,1.14c0.1,0.41,0.39,0.75,0.79,0.89l1.26,0.47c0.4,0.15,0.85,0.09,1.19-0.17l0.94-0.71l0.98-0.37l0.51-0.09
                  l0.59,0.5l0.23,1.02c0.04,0.17,0.13,0.34,0.24,0.49l0.84,1.06c0.29,0.36,0.76,0.54,1.22,0.45s0.83-0.43,0.96-0.88l0.29-0.99
                  l0.59-0.84c0.15-0.2,0.22-0.45,0.23-0.7l0.03-1.41l0.24-0.89c0.07-0.02,0.15-0.04,0.22-0.06l0.03,0.65l-0.34,0.96
                  c-0.04,0.13-0.07,0.25-0.07,0.38l-0.11,2.47c-0.03,0.52,0.27,1,0.74,1.21l0.88,0.4l0.69,0.86c0.34,0.42,0.91,0.58,1.41,0.39
                  l0.99-0.36l1.14,0.04c0.13,0,0.26-0.01,0.38-0.04l0.47-0.13l0.61,0.64c0.32,0.36,0.83,0.49,1.29,0.34l1.51-0.48
                  c0.29-0.09,0.54-0.29,0.7-0.56l0.67-1.15l0.22-0.31l0.48,0.08l1.52,0.5c0.21,0.07,0.43,0.08,0.64,0.03l1.03-0.2l1.1,0.4
                  c0.38,0.13,0.8,0.08,1.13-0.15l1.2-0.81l0.9-0.72l1.11-0.54c0.16-0.07,0.3-0.18,0.41-0.32l0.57-0.68l0.8-0.25l1.03,0.13
                  c0.29,0.04,0.58-0.03,0.82-0.18l1.12-0.7c0.19-0.13,0.35-0.3,0.45-0.51l0.98-1.96l0.63-0.28l1.57-0.26c0.19-0.03,0.37-0.1,0.53-0.22
                  l0.57-0.41l0.13,0.04l0.3,0.53l0.34,1.13c0.09,0.28,0.27,0.53,0.52,0.69l0.97,0.62l0.7,0.82c0.11,0.13,0.25,0.23,0.4,0.3l0.87,0.43
                  l0.61,0.78c0.19,0.24,0.47,0.41,0.77,0.46l1.52,0.27c0.15,0.03,0.29,0.03,0.44,0l1.22-0.22c0.28-0.05,0.54-0.2,0.73-0.41l0.67-0.78
                  l0.35-0.2l1.89,0.73c0.35,0.13,0.74,0.1,1.06-0.08l1.1-0.63c0.16-0.09,0.29-0.22,0.4-0.37l0.41-0.58l0.21-0.02l0.59,0.47l0.86,0.83
                  c0.07,0.06,0.14,0.12,0.22,0.17l0.99,0.6l0.94,0.77c0.15,0.12,0.33,0.21,0.53,0.25l1.23,0.26c0.24,0.05,0.49,0.03,0.72-0.06
                  l1.26-0.5c0.09-0.03,0.17-0.08,0.25-0.13l0.71-0.49l1.04-0.03c0.27-0.01,0.52-0.1,0.73-0.25l0.85-0.65l0.8-0.3l1.06-0.01
                  c0.05,0,0.11,0,0.16-0.01l1.29-0.18c0.19-0.03,0.37-0.1,0.53-0.21l0.7-0.47h0.26l0.99,1.54l0.56,1.11c0.13,0.25,0.32,0.44,0.57,0.56
                  l0.94,0.46l0.79,0.78c0.04,0.04,0.08,0.07,0.12,0.1l0.7,0.54l0.24,0.93c0.03,0.11,0.08,0.22,0.14,0.32l0.69,1.15
                  c0.09,0.15,0.21,0.28,0.35,0.38l1.19,0.84c0.22,0.15,0.49,0.23,0.76,0.22l1.03-0.03l0.92,0.29l1.05,0.55
                  c0.21,0.11,0.44,0.15,0.68,0.14l0.41-0.04l0.09,0.2l0.05,1c0.01,0.19,0.06,0.37,0.15,0.54l0.65,1.19c0.05,0.08,0.1,0.16,0.17,0.23
                  l0.1,0.12l-0.51,0.58c-0.09,0.11-0.17,0.24-0.22,0.37l-0.18,0.45h-0.4c-0.44-0.01-0.84,0.22-1.07,0.58
                  c-0.23,0.37-0.25,0.83-0.06,1.22l0.69,1.43l0.05,0.77l-0.49,1.02c-0.18,0.38-0.16,0.83,0.07,1.2l0.71,1.15
                  c0.04,0.07,0.09,0.13,0.15,0.19l0.84,0.9l0.72,0.94l0.57,0.91l0.22,0.97l0.15,1.29c0.02,0.19,0.09,0.38,0.2,0.54l0.84,1.27
                  c0.16,0.25,0.41,0.43,0.7,0.51l1.23,0.35l0.27,0.4l0.15,1.08c0.02,0.14,0.06,0.27,0.13,0.4l0.32,0.63l-0.24,0.66
                  c-0.24,0.64,0.08,1.35,0.72,1.6l1.1,0.41l0.75,0.43l0.46,0.78l0.46,1.22c0.04,0.09,0.09,0.18,0.14,0.26l0.58,0.84l0.06,0.92
                  c0.01,0.18,0.06,0.35,0.15,0.51l0.71,1.33l0.44,1.1c0.02,0.04,0.04,0.09,0.06,0.13l0.72,1.33c0.05,0.09,0.1,0.17,0.17,0.25
                  l0.82,0.91c0.12,0.13,0.26,0.23,0.43,0.3l0.58,0.26l-0.89,0.78c-0.28,0.23-0.43,0.58-0.43,0.95l0.01,1.41
                  c0,0.35,0.15,0.68,0.41,0.91l0.94,0.86c0.09,0.09,0.19,0.15,0.3,0.21L1140.98,207.79z"/>
                <title>Sabinov</title>
              </Link>


              {/* Presovsky presov */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Prešov")}>
                <path style={{ fill: crimesInRegionPresovsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionPresovsky[4].crimePercent)] ? crimesInRegionPresovsky[4].color : "#D3D3D3" }}
                  d="M1208.61,287.74l-0.19,1.15l-1.01-0.11c-0.33-0.04-0.65,0.05-0.91,0.25l-0.79,0.62l-1.02,0.03
                  c-0.27,0.01-0.54,0.11-0.75,0.28l-0.98,0.8c-0.45,0.37-0.59,1.01-0.33,1.53l-2.08,1.07l-0.65,0.01l-0.52-0.29l-0.53-0.76
                  c-0.22-0.32-0.58-0.52-0.96-0.54c-0.4-0.02-0.76,0.14-1.02,0.43l-0.44,0.51l-0.14,0.01l-0.34-0.42l-0.43-1.01l-0.31-1.35l-0.15-1.21
                  l0.06-1.3c0.02-0.4-0.16-0.79-0.48-1.04l-1.14-0.89c-0.37-0.29-0.88-0.34-1.31-0.14c-0.42,0.2-0.7,0.63-0.71,1.1l-0.03,1.14
                  l-0.27,1.11l-0.46,1.05c-0.11,0.26-0.13,0.56-0.06,0.83l0.18,0.66l-0.59-0.66c-0.13-0.15-0.3-0.27-0.48-0.34l-1.22-0.46l-1.09-0.5
                  l-0.65-0.81c-0.31-0.38-0.81-0.55-1.28-0.43l-1.44,0.36l-2.79,0.85c-0.29,0.09-0.54,0.27-0.7,0.52l-0.69,1.07
                  c-0.23,0.36-0.26,0.8-0.09,1.19l0.5,1.13c0.02,0.05,0.05,0.1,0.08,0.15l0.61,0.98l0.46,0.98l0.29,0.93l0.09,2.62l0.11,1.31
                  l-0.24,0.84l-0.58,1.06l-0.7,0.87l-0.88,1c-0.06,0.07-0.11,0.14-0.15,0.22l-0.6,1.07c-0.02,0.04-0.05,0.09-0.06,0.13l-0.37,0.87
                  l-0.51,0.39l-1.86,0.33l-1.17-0.16l-1.86-0.39l-1.24-0.35c-0.38-0.11-0.78-0.03-1.09,0.2c-0.32,0.24-0.5,0.61-0.5,1v0.64l-0.38,0.26
                  l-0.71,0.12l-0.98-0.33l-1.02-0.65c-0.36-0.24-0.83-0.26-1.22-0.07l-1.24,0.61c-0.07,0.04-0.14,0.08-0.2,0.13l-1.15,0.88l-1,0.88
                  c-0.1,0.1-0.19,0.21-0.26,0.33l-0.66,1.18c-0.17,0.3-0.21,0.65-0.11,0.98c0.11,0.32,0.33,0.6,0.64,0.75l0.08,0.04l-0.04,0.08
                  c-0.18,0.46-0.09,0.98,0.25,1.34l0.28,0.3c-0.16,0.15-0.28,0.34-0.34,0.55l-2.52,0.65c-0.06,0.01-0.11,0.03-0.16,0.05l-1.17,0.5
                  c-0.09,0.04-0.18,0.09-0.26,0.15l-0.6,0.45l-1.09-0.16l-0.09-0.08l0.14-0.34c0.18-0.41,0.11-0.89-0.16-1.24
                  c-0.27-0.36-0.71-0.54-1.16-0.48l-1.19,0.17l-1.03-0.04l-0.72-0.41l-0.69-0.92c-0.07-0.09-0.15-0.18-0.25-0.25l-1.13-0.85
                  l-0.75-0.89l-0.24-0.27l0.2-0.35c0.12-0.23,0.17-0.48,0.14-0.73l-0.14-1.33c-0.02-0.21-0.09-0.41-0.21-0.58l-0.52-0.74l-0.08-1.04
                  l0.07-1.56v-0.1l-0.06-1.34l0.01-0.11l0.21-0.02c0.19-0.01,0.38-0.08,0.54-0.18l1.13-0.71c0.1-0.06,0.19-0.14,0.27-0.22l0.7-0.78
                  l1.05-0.61c0.3-0.18,0.51-0.47,0.59-0.81l0.26-1.19l0.49-1.06c0.13-0.29,0.15-0.61,0.05-0.91l-0.4-1.22
                  c-0.02-0.06-0.04-0.11-0.07-0.16l-0.55-1.11c-0.07-0.14-0.16-0.27-0.28-0.37l-1.01-0.92c-0.43-0.4-1.08-0.44-1.56-0.1l-1.02,0.72
                  c-0.17,0.12-0.31,0.27-0.4,0.46l-0.13,0.27l-1.42-0.51c-0.11-0.04-0.23-0.06-0.35-0.07l-0.66-0.03l-0.06-0.33
                  c-0.01-0.06-0.02-0.11-0.03-0.17l-0.36-1.21c-0.09-0.28-0.27-0.52-0.51-0.68l-1.03-0.68c-0.29-0.19-0.64-0.25-0.97-0.18l-1.21,0.28
                  c-0.57,0.13-0.97,0.63-0.97,1.21v0.41l-4.79-3.7c-0.39-0.3-0.91-0.34-1.35-0.12l-1.16,0.6c-0.17,0.09-0.33,0.23-0.45,0.39
                  l-1.63,2.36l-0.73,0.92l-0.06,0.07l-0.06-0.05c-0.05-0.05-0.1-0.08-0.15-0.12l-0.89-0.58l-0.69-0.94c-0.04-0.06-0.09-0.11-0.14-0.16
                  l-0.77-0.76l-0.46-0.86l-0.27-1.03l-0.05-1.14c-0.02-0.36-0.19-0.69-0.47-0.92l-0.8-0.63l-0.51-0.87c-0.12-0.2-0.28-0.35-0.47-0.46
                  l-1.09-0.61c-0.12-0.07-0.24-0.11-0.37-0.14l-1.1-0.21l-2.14-1.08l-1.03-0.68c-0.29-0.19-0.64-0.26-0.98-0.18l-1.25,0.31l-1.26,0.21
                  c-0.14,0.02-0.28,0.07-0.41,0.14l-1.29,0.73c-0.27,0.16-0.47,0.41-0.57,0.71l-0.01,0.02l-0.13-0.04c-0.04-0.01-0.08-0.03-0.12-0.03
                  l-1.24-0.3c-0.2-0.05-0.4-0.05-0.6,0l-1.3,0.33c-0.1,0.02-0.2,0.06-0.3,0.12l-0.64,0.36l-0.34-0.2l-0.82-0.76
                  c-0.19-0.17-0.44-0.29-0.69-0.32l-1.27-0.16c-0.21-0.03-0.42,0-0.61,0.07l-0.96,0.37l-0.61,0.03l-0.58-0.43l-0.82-0.73l-0.89-0.89
                  c-0.14-0.14-0.31-0.25-0.5-0.31l-1.13-0.37l-1.11-0.51c-0.31-0.15-0.66-0.16-0.98-0.04l-1.03,0.4l-0.93,0.1l-1.06-0.24
                  c-0.26-0.06-0.52-0.03-0.76,0.07l-0.92,0.39l-1.07-0.06l-1.11-0.01l-1.47-0.79l-0.04-0.81c-0.01-0.17-0.05-0.33-0.13-0.49
                  l-0.55-1.13l-0.43-2.48c-0.01-0.07-0.02-0.13-0.05-0.19l-0.42-1.27c-0.09-0.27-0.26-0.5-0.5-0.65l-1.03-0.66l-1.09-0.85l-0.99-1
                  c-0.03-0.02-0.05-0.05-0.08-0.07l-1.32-1.12l-0.53-0.51l0.05-0.71c0-0.14-0.01-0.27-0.04-0.4l-0.32-1.18l0.08-1.23l0.27-1.14
                  c0.08-0.33,0.03-0.68-0.16-0.97l-0.76-1.18l-0.1-0.97c-0.01-0.13-0.04-0.26-0.1-0.38l-0.51-1.15c-0.21-0.47-0.7-0.77-1.22-0.74
                  l-1.09,0.07l-1.04-0.28c-0.27-0.07-0.55-0.05-0.8,0.06l-1.18,0.49c-0.08,0.03-0.15,0.07-0.22,0.11l-1.05,0.71
                  c-0.05,0.03-0.09,0.07-0.13,0.1l-0.48,0.43l-0.69-0.1c-0.36-0.05-0.71,0.05-0.98,0.28l-0.95,0.79c-0.09,0.08-0.16,0.17-0.23,0.26
                  l-0.59,0.88l-0.12,0.09l-0.52-0.33c-0.08-0.06-0.17-0.1-0.26-0.13l-0.93-0.32l-0.73-0.79l-0.83-1.09c-0.06-0.08-0.12-0.14-0.19-0.2
                  l-2.12-1.81c-0.05-0.05-0.11-0.09-0.18-0.13l-1.07-0.63c-0.12-0.07-0.25-0.12-0.38-0.15l-1.24-0.25c-0.07-0.01-0.13-0.02-0.19-0.02
                  l-1.3-0.06c-0.13,0-0.26,0.01-0.38,0.04l-1.27,0.33l-0.28,0.05l-0.12-0.3c-0.08-0.18-0.21-0.34-0.36-0.47l-1.05-0.85
                  c-0.31-0.25-0.71-0.34-1.09-0.24l-0.54,0.13l-1.29-1.38l0.99-0.43c0.39-0.17,0.67-0.53,0.73-0.95c0.07-0.42-0.08-0.84-0.39-1.13
                  l-0.48-0.43l0.21-0.95c0.11-0.5-0.09-1.01-0.52-1.3l-0.9-0.62l-0.2-0.37l0.22-0.41l0.65-0.8l0.86-0.92c0.29-0.3,0.4-0.73,0.31-1.14
                  c-0.1-0.4-0.39-0.74-0.79-0.88l-0.8-0.3l-0.5-0.69c-0.03-0.04-0.07-0.08-0.11-0.12l-1.36-1.42l0.01-0.55l0.36-1.29
                  c0.03-0.1,0.05-0.19,0.05-0.29l0.03-0.8l1.49-1.66c0.05-0.05,0.09-0.1,0.12-0.16l0.72-1.16c0.2-0.32,0.24-0.72,0.12-1.07l-0.29-0.82
                  l0.27-0.77c0.08-0.22,0.09-0.46,0.04-0.69l-0.21-0.94l0.22-0.91c0.04-0.13,0.05-0.27,0.04-0.4l-0.09-1.06l0.13-0.64l1.05-0.95
                  c0.03-0.03,0.06-0.06,0.08-0.09l0.86-0.94c0.04-0.04,0.08-0.08,0.11-0.13l0.7-1.02c0.16-0.23,0.23-0.5,0.22-0.77l-0.05-1.1
                  l0.48-2.57l0.66-1.43c0.04-0.08,0.06-0.17,0.08-0.27l0.28-1.35l0.27-1.64l0.1-0.87l2.21-1.29c0.26-0.16,0.45-0.4,0.54-0.69
                  l0.83-2.52l0.02-0.02c0.08,0.17,0.19,0.32,0.33,0.44l1.07,0.91c0.04,0.03,0.07,0.06,0.11,0.09l0.61,0.41l0.14,0.81
                  c0.07,0.46,0.4,0.84,0.84,0.99l1.32,0.43c0.34,0.11,0.71,0.07,1.02-0.11l1.06-0.62l0.5-0.02l0.81,0.69c0.23,0.2,0.53,0.3,0.82,0.3
                  l1.17-0.01l1.47,0.09c0.26,0.02,0.51-0.05,0.73-0.18l1.24-0.76c0.25-0.15,0.43-0.38,0.53-0.65l0.43-1.22l0.6-1.15
                  c0.03-0.06,0.05-0.11,0.07-0.17l0.09-0.26l1.45,0.24l-0.19,0.61c-0.15,0.46-0.01,0.96,0.34,1.28l0.9,0.84
                  c0.27,0.26,0.64,0.38,1.01,0.33l1.17-0.15l0.94-0.08l0.68,0.32l0.54,0.64l0.42,0.99c0.03,0.08,0.08,0.16,0.13,0.24l0.62,0.86
                  l0.37,1.07l0.25,1.16c0.06,0.28,0.21,0.54,0.44,0.72l0.51,0.4l-0.02,0.72c-0.01,0.54,0.32,1.02,0.83,1.21l1.03,0.37l0.76,0.66
                  l0.77,1c0.02,0.03,0.05,0.07,0.09,0.1l0.88,0.93l0.95,0.91l0.23,0.3l-0.25,0.51c-0.15,0.29-0.17,0.63-0.07,0.94l0.38,1.17
                  c0.17,0.51,0.65,0.86,1.19,0.86h1.34c0.18,0,0.36-0.04,0.53-0.12l0.52-0.24l0.36,0.22l0.92,2.1c0.11,0.26,0.31,0.47,0.56,0.6
                  l2.07,1.06l0.73,0.85c0.09,0.11,0.19,0.2,0.31,0.27l2.03,1.23l0.74,0.79c0.38,0.4,0.97,0.5,1.46,0.26s0.77-0.78,0.68-1.32l-0.2-1.25
                  c-0.02-0.13-0.06-0.27-0.13-0.39l-0.48-0.91l-0.02-1.13c-0.01-0.43-0.24-0.83-0.61-1.06l-0.63-0.36l-0.11-0.92
                  c-0.01-0.07-0.02-0.13-0.04-0.19l-0.12-0.43c0.2,0.13,0.43,0.21,0.67,0.21l1.34,0.02c0.14,0,0.27-0.02,0.4-0.06l1.22-0.39
                  c0.02-0.01,0.04-0.01,0.06-0.02l0.66,0.69c0.24,0.24,0.58,0.41,0.91,0.38c0.34,0,0.66-0.14,0.9-0.39l0.86-0.91
                  c0.07-0.07,0.12-0.15,0.17-0.24l0.62-1.08c0.05-0.08,0.09-0.18,0.12-0.27l0.23-0.8c0.02,0.02,0.04,0.03,0.06,0.04l1.11,0.58
                  c0.03,0.01,0.06,0.03,0.09,0.04l1.2,0.5c0.41,0.18,0.88,0.12,1.24-0.16l1.06-0.81c0.06-0.04,0.11-0.09,0.16-0.15l0.66-0.71
                  l0.89-0.36c0.1-0.04,0.18-0.08,0.27-0.14l1.02-0.73c0.13-0.1,0.24-0.22,0.33-0.36l0.75-1.2l0.73-0.64l1.05-0.7
                  c0.21-0.15,0.37-0.35,0.47-0.59l0.25-0.65l0.57-0.04l4.38,0.28c0.56,0.03,1.03-0.28,1.23-0.77l0.5-1.19
                  c0.26-0.62-0.02-1.33-0.63-1.62l-0.5-0.23l-0.02-0.13l0.71-1.1c0.01-0.01,0.01-0.02,0.02-0.03c0.27,0.21,0.62,0.31,0.96,0.26
                  l1.18-0.18l1.16-0.12l1.08,0.03l1.13,0.19c0.19,0.04,0.38,0.02,0.57-0.03l1.21-0.36c0.5-0.15,0.85-0.6,0.89-1.12l0.08-1.25
                  c0.02-0.38-0.13-0.76-0.42-1.01l-0.03-0.03l1.49-0.63l0.85,0.15l1.24,0.33c0.6,0.15,1.22-0.14,1.46-0.71l1.04-2.46
                  c0.24-0.62-0.03-1.31-0.62-1.6l-0.98-0.47l-0.4-0.36l-0.01-0.29l0.99-0.86c0.29-0.25,0.45-0.63,0.43-1.03l-0.09-1.3
                  c-0.04-0.46-0.32-0.87-0.75-1.05l-1.09-0.49l-0.54-0.6l-0.61-1.13l-0.44-1.1c-0.02-0.04-0.04-0.09-0.06-0.13l-0.62-1.15l-0.06-1
                  c-0.02-0.23-0.09-0.45-0.22-0.63l-0.7-1l-0.44-1.17c-0.03-0.06-0.06-0.12-0.09-0.18l-0.68-1.16c-0.11-0.19-0.27-0.35-0.46-0.46
                  l-1.13-0.64c-0.06-0.03-0.12-0.06-0.17-0.08l-0.03-0.01v-0.01c0.12-0.33,0.1-0.69-0.06-1l-0.47-0.93l-0.16-1.17
                  c-0.03-0.19-0.1-0.38-0.21-0.54l-0.7-1.01c-0.17-0.24-0.41-0.41-0.69-0.49l-1.21-0.35l-0.42-0.63l-0.13-1.06
                  c0-0.04-0.01-0.08-0.02-0.13l-0.28-1.24c-0.03-0.13-0.09-0.26-0.16-0.38l-0.71-1.14c-0.02-0.04-0.04-0.07-0.07-0.1l-0.8-1.04
                  c-0.02-0.03-0.05-0.06-0.08-0.09l-0.79-0.85l-0.29-0.46l0.34-0.7c0.09-0.19,0.13-0.41,0.12-0.62l-0.09-1.35
                  c-0.01-0.16-0.05-0.32-0.12-0.47l-0.02-0.02c0.23-0.14,0.41-0.35,0.51-0.61l0.41-1.05l0.42-0.48l0.14,0.4
                  c0.03,0.09,0.08,0.18,0.13,0.27l0.69,1.07c0.13,0.2,0.32,0.36,0.54,0.46l1.15,0.51c0.22,0.1,0.45,0.13,0.69,0.1l1.05-0.15l0.67,0.2
                  l0.51,0.48l0.67,2.09c0.04,0.11,0.09,0.22,0.15,0.32l0.78,1.16c0.09,0.13,0.21,0.25,0.35,0.34l1.08,0.71
                  c0.12,0.08,0.26,0.14,0.4,0.18l1.01,0.24l0.92,0.56l1.01,0.76c0.05,0.04,0.1,0.07,0.16,0.1l1.21,0.65c0.06,0.03,0.12,0.06,0.18,0.08
                  l2.46,0.88c0.07,0.03,0.15,0.05,0.24,0.06l1,0.16l0.96,0.53c0.09,0.05,0.19,0.09,0.29,0.11l1.37,0.34l1.21,0.21
                  c0.03,0.01,0.06,0.01,0.1,0.01l1.47,0.14c0.63,0.05,1.23-0.39,1.34-1.03l0.03-0.17l1.13,0.38l0.91,0.45l1.75,1.51
                  c0.2,0.18,0.46,0.29,0.73,0.31l0.76,0.05l0.3,0.34l0.14,0.79l-0.25,2.42c0,0.09,0,0.18,0.01,0.27l0.17,1.33l0.25,1.24
                  c0.02,0.07,0.04,0.14,0.06,0.2l0.45,1.16c0.1,0.25,0.27,0.45,0.48,0.6l1.08,0.7c0.05,0.03,0.11,0.06,0.17,0.09l0.97,0.44l0.66,0.73
                  c0.26,0.3,0.64,0.45,1.03,0.42c0.39-0.04,0.75-0.25,0.96-0.58l0.69-1.09c0.18-0.3,0.24-0.66,0.14-1l-0.16-0.61l0.25-0.31l0.47-0.31
                  l0.69,0.11l0.81,0.26l0.7,0.69l0.61,0.94c0.35,0.54,1.06,0.73,1.63,0.42l0.59-0.3l0.45,0.19l0.56,0.66l1.1,2.18
                  c0.04,0.07,0.08,0.14,0.13,0.21l0.82,1.05c0.27,0.34,0.7,0.52,1.12,0.47l1.25-0.13c0.04,0,0.07-0.01,0.11-0.01l1.28-0.26l1.28-0.32
                  l1.24-0.4c0.04-0.01,0.07-0.02,0.1-0.04l0.15-0.06c0.14,0.15,0.31,0.27,0.51,0.35l6.32,2.32l1.24,0.53l1.04,0.38l0.58,0.82
                  c0.19,0.26,0.47,0.44,0.79,0.5l0.97,0.18l0.6,0.39l0.65,1.25c0.06,0.11,0.14,0.21,0.23,0.3l0.97,0.97c0.17,0.17,0.38,0.29,0.62,0.34
                  l1.2,0.26c0.05,0.01,0.1,0.02,0.16,0.03l1.04,0.08h0.01l-0.11,0.3c-0.07,0.21-0.08,0.44-0.03,0.66l0.24,1.17l-0.05,1.07
                  c-0.02,0.38,0.14,0.76,0.44,1.01l0.99,0.85c0.18,0.16,0.41,0.26,0.66,0.29l1.18,0.15l0.98,0.28l0.27,0.14l-0.32,0.22
                  c-0.39,0.26-0.59,0.71-0.54,1.16l0.14,1.29c0.02,0.23,0.11,0.44,0.24,0.63l0.43,0.55l-1.77,3.74l-0.69,0.56l-1.39,0.71
                  c-0.21,0.11-0.39,0.28-0.51,0.49l-0.58,0.99l-0.47,0.31l-0.88-0.06c-0.48-0.02-0.91,0.2-1.15,0.59l-0.56,0.9l-0.82,0.77
                  c-0.16,0.15-0.28,0.34-0.34,0.56l-0.27,0.91l-0.61,0.57l-1,0.66c-0.21,0.15-0.38,0.36-0.47,0.61l-0.47,1.27l-0.39,1.25
                  c-0.01,0.03-0.02,0.07-0.03,0.1l-0.49,2.18l-0.24,0.32l-0.65,0.03c-0.53,0.03-0.98,0.38-1.14,0.89c-0.15,0.5,0.03,1.05,0.45,1.36
                  l2.01,1.44l2.46,2.94l0.2,0.49l-0.21,0.33l-0.77,0.53l-1.16,0.52c-0.07,0.03-0.13,0.06-0.19,0.1l-1.05,0.7
                  c-0.27,0.18-0.46,0.45-0.53,0.77l-0.3,1.36c-0.05,0.24-0.03,0.48,0.05,0.71l0.48,1.28c0.16,0.43,0.54,0.73,0.99,0.8l1.06,0.15
                  l0.79,0.36l0.51,0.62l0.44,1c0.04,0.09,0.09,0.18,0.16,0.26l0.78,1l0.51,0.94l0.4,1.04l0.34,1.24c0.04,0.11,0.08,0.21,0.14,0.31
                  l0.65,1.09c0.08,0.14,0.18,0.26,0.31,0.35l0.89,0.68l0.73,1c0.12,0.16,0.27,0.29,0.45,0.38l0.91,0.46l0.27,0.51l0.06,1.23
                  c0.01,0.2,0.08,0.4,0.18,0.58l0.67,1.09l0.43,1.09c0.04,0.12,0.11,0.22,0.18,0.32l0.84,1.05c0.14,0.17,0.32,0.3,0.52,0.38l1.14,0.46
                  c0.07,0.03,0.15,0.05,0.22,0.06l0.56,0.12l-0.39,0.52c-0.08,0.1-0.14,0.22-0.19,0.34l-0.42,1.19c-0.07,0.21-0.09,0.43-0.05,0.65
                  l0.23,1.25c0.02,0.1,0.05,0.2,0.1,0.3l0.56,1.2c0.03,0.06,0.06,0.12,0.1,0.17l0.73,1.07c0.07,0.12,0.17,0.22,0.28,0.3l1.1,0.82
                  c0.13,0.1,0.29,0.17,0.44,0.21l0.8,0.2l0.26,0.35l-0.08,0.83l-0.25,1.03l-0.52,1.18c-0.04,0.09-0.07,0.19-0.08,0.3l-0.2,1.21
                  c-0.02,0.11-0.03,0.22-0.01,0.33l0.14,1.36c0.01,0.15,0.06,0.3,0.13,0.44l0.51,1.01l0.28,0.96l-0.08,1.01
                  c-0.01,0.14,0.01,0.29,0.05,0.43l0.38,1.34c0.04,0.15,0.11,0.29,0.2,0.41L1208.61,287.74z"/>
                <title>Prešov</title>
              </Link>

              {/* Presovsky Humenne */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Humenné")}>
                <path style={{ fill: crimesInRegionPresovsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionPresovsky[1].crimePercent)] ? crimesInRegionPresovsky[1].color : "#D3D3D3" }}
                  d="M1391.63,272.19l-0.03,0.4l-0.9,0.97c-0.22,0.22-0.34,0.52-0.34,0.82l-0.02,0.81l-0.72,0.45
                  c-0.06,0.03-0.11,0.07-0.16,0.11l-1.26,1.09c-0.17,0.15-0.3,0.34-0.37,0.55l-0.28,0.84l-0.8,0.63l-1.2,0.64
                  c-0.23,0.12-0.42,0.31-0.53,0.55l-0.58,1.18c-0.06,0.12-0.1,0.25-0.12,0.38l-0.2,1.45l-0.41,1.38l-0.44,0.61l-0.94,0.4l-0.88,0.45
                  l-1-0.02c-0.1,0-0.24,0.01-0.36,0.05l-1.31,0.36l-1.38,0.02c-0.33,0-0.65,0.14-0.89,0.39l-0.87,0.91c-0.2,0.21-0.32,0.48-0.34,0.77
                  l-0.35,3.77c-0.02,0.3,0.06,0.61,0.23,0.85l0.76,1.04l0.5,0.94l0.24,2.44l-0.17,1.42l-0.06,1.33c-0.01,0.16,0.01,0.32,0.07,0.47
                  l0.35,1.02l0.05,0.9l-0.24,1.05c-0.03,0.11-0.04,0.22-0.03,0.34v0.07l-1.78,0.02l-2.1-0.03l-8.2-0.31l-1.18-0.28l-1.25-0.22
                  c-0.19-0.04-0.4-0.02-0.59,0.04l-4.09,1.25l-1.17,0.4l-2.43,0.74l-2.74,0.68l-2.39,0.01l-6.01-0.34l0.01-0.79
                  c0-0.33-0.13-0.64-0.35-0.88l-1.19-1.23c-0.24-0.24-0.56-0.38-0.9-0.38h-0.01l-1.17,0.01l-1.15-0.13l-1.11-0.3
                  c-0.35-0.09-0.73-0.03-1.03,0.18l-0.65,0.45l-1.68-0.23l-0.91-0.56l-0.92-0.48l-0.37-0.38l-0.01-0.73c0-0.18-0.05-0.36-0.13-0.53
                  l-0.45-0.93l-0.14-0.99l0.13-1.31c0.01-0.06,0.01-0.11,0.01-0.17l-0.04-1.33c-0.02-0.49-0.32-0.93-0.78-1.12l-1.36-0.56
                  c-0.1-0.04-0.21-0.07-0.33-0.08l-1.1-0.13l-2.14-1.01l-0.87-0.58l-0.87-0.98l-1-0.97c-0.17-0.16-0.37-0.27-0.6-0.32l-1.35-0.3
                  c-0.04-0.01-0.08-0.02-0.12-0.02l-1-0.12l-0.61-0.68c-0.26-0.29-0.64-0.45-1.03-0.42l-0.93,0.08l-0.78-0.38l-0.8-0.73
                  c-0.11-0.09-0.23-0.17-0.36-0.23l-1.16-0.48c-0.07-0.02-0.14-0.05-0.22-0.06l-1.14-0.25l-1.31-0.42l-1.15-0.45
                  c-0.1-0.05-0.22-0.07-0.33-0.09l-1.3-0.11l-1.38-0.22c-0.08-0.02-0.16-0.02-0.24-0.02l-1.36,0.05c-0.05,0-0.11,0.01-0.16,0.02
                  l-2.61,0.4c-0.35,0.05-0.67,0.25-0.87,0.55l-0.59,0.9l-0.17,0.08l-0.06-0.12c-0.25-0.44-0.74-0.68-1.24-0.62l-0.37,0.04l-0.52-1.52
                  l-0.45-1.14c-0.13-0.33-0.4-0.59-0.73-0.71l-1.16-0.43l-1.15-0.66c-0.25-0.14-0.55-0.19-0.84-0.14l-1.28,0.22l-1.42,0.07
                  c-0.49,0.02-0.93,0.34-1.11,0.8l-0.19,0.48l-0.3,0.05l-0.32-1.27c-0.14-0.57-0.63-0.94-1.23-0.95l-1.06,0.02l-0.42-0.14l-0.13-0.33
                  l0.96-1.98c0.08-0.16,0.12-0.33,0.13-0.51l0.06-2.73c0-0.08-0.01-0.15-0.02-0.23l-0.26-1.46c-0.04-0.21-0.13-0.41-0.27-0.58
                  l-0.48-0.58l1.03-2.74l0.37-1.07l0.7-0.63l1-0.62l1.24-0.34c0.36-0.1,0.66-0.36,0.81-0.7c0.15-0.34,0.15-0.73-0.02-1.07l-0.44-0.89
                  l-0.08-1.5l0.23-0.91l0.53-1.1c0.05-0.11,0.09-0.24,0.11-0.36l0.24-1.66l0.36-1.3c0.03-0.11,0.05-0.23,0.05-0.34v-1.4
                  c0-0.22-0.06-0.44-0.17-0.63l-0.64-1.09c-0.13-0.22-0.31-0.39-0.54-0.5l-0.94-0.45l-0.39-0.93c-0.05-0.12-0.11-0.23-0.2-0.33
                  l-0.84-0.99c-0.03-0.04-0.06-0.07-0.1-0.1l-1.23-1.16l-1.07-0.92l-0.4-0.93l0.16-1.02l0.33-1.54c0.03-0.11,0.04-0.23,0.03-0.35
                  l-0.09-1.31c-0.01-0.16-0.06-0.31-0.13-0.46l-0.56-1.15c-0.12-0.24-0.31-0.44-0.55-0.56l-1.2-0.62l-0.54-0.24l-0.04-0.23l0.42-0.69
                  c0.08-0.14,0.14-0.3,0.17-0.47l0.14-0.97l1.41-1.78c0.43-0.53,0.36-1.31-0.17-1.75l-1.08-0.9c-0.16-0.13-0.35-0.22-0.55-0.26
                  l-0.72-0.15l-0.42-0.63c-0.16-0.22-0.37-0.39-0.63-0.48l-1.23-0.43c-0.25-0.09-0.52-0.09-0.77-0.02l-0.16,0.05l-0.18-0.75l0.37-0.64
                  c0.15-0.25,0.2-0.54,0.16-0.82l-0.11-0.66l0.49-0.47c0.25-0.25,0.39-0.58,0.38-0.93l-0.02-0.91l0.57-0.49
                  c0.22-0.18,0.36-0.43,0.42-0.71l0.27-1.41l0.26-1.14l0.74-0.91l0.61-0.86l0.9-0.46l1.1-0.66c0.34-0.2,0.56-0.55,0.6-0.94
                  s-0.1-0.78-0.39-1.05l-1.08-1l-0.62-0.63l-0.04-0.85c-0.01-0.2-0.08-0.41-0.18-0.58l-0.18-0.3l0.24-0.29
                  c0.25-0.3,0.34-0.69,0.26-1.07l-0.29-1.29c-0.03-0.13-0.08-0.26-0.15-0.37l-1.42-2.38c-0.04-0.07-0.09-0.14-0.15-0.2l-1.53-1.64
                  l-0.33-0.93c-0.03-0.09-0.07-0.17-0.11-0.24l-0.66-1.08c-0.09-0.15-0.21-0.28-0.35-0.37l-0.21-0.15l0.24-0.66
                  c0.04-0.11,0.07-0.22,0.08-0.34l0.1-1.4c0.01-0.19-0.02-0.39-0.09-0.57l-0.42-1l-0.07-1l0.35-2.9c0.03-0.2,0-0.4-0.06-0.59
                  l-0.4-1.12l-0.4-2.16c-0.03-0.14-0.08-0.27-0.15-0.4l-0.65-1.11l0.04-0.14c0.03-0.11,0.04-0.22,0.04-0.33v-1l0.12-0.3l0.54-0.08
                  c0.18-0.03,0.36-0.1,0.51-0.2l1.11-0.75c0.29-0.2,0.48-0.5,0.53-0.84l0.2-1.22l0.14-0.36l1.78-0.09c0.55-0.01,1.02-0.38,1.17-0.91
                  l0.35-1.24c0.01-0.04,0.02-0.07,0.03-0.11l0.23-1.23c0.04-0.21,0.02-0.43-0.05-0.64l-0.49-1.43c-0.04-0.11-0.1-0.23-0.17-0.33
                  l-0.38-0.51l0.2-0.7c0.01-0.06,0.03-0.13,0.03-0.19l0.15-1.35c0.02-0.16,0.01-0.33-0.04-0.48l-0.31-1.1l-0.13-1.19
                  c-0.01-0.11-0.04-0.22-0.08-0.32l-0.3-0.79l0.32-1.3l0.44-1.25l0.46-1l0.73-1.04l0.16-0.18l0.22,0.14c0.48,0.28,1.09,0.21,1.49-0.17
                  l0.66-0.61l0.83,0.01h0.01l0.12,0.45c0.05,0.16,0.12,0.31,0.22,0.44l0.8,1.02l0.94,2.22c0.01,0.03,0.03,0.06,0.05,0.1l0.6,1.13
                  l0.28,1.18l-0.25,1.25c-0.02,0.08-0.03,0.15-0.03,0.23l-0.02,1.26c0,0.07,0,0.14,0.01,0.2l0.17,1.1l-0.07,1.33
                  c-0.01,0.24,0.05,0.48,0.17,0.68l0.23,0.41l-0.27,0.36c-0.38,0.49-0.34,1.18,0.09,1.62l0.62,0.66l0.18,0.94
                  c0.02,0.09,0.05,0.18,0.09,0.27l0.48,1.09l0.41,1.18c0.03,0.09,0.07,0.18,0.12,0.26l0.65,1.05c0.08,0.14,0.2,0.26,0.34,0.36
                  l0.97,0.69l1.91,1.59c0.22,0.18,0.49,0.29,0.77,0.3l0.53,0.02l0.22,0.48c0.11,0.24,0.29,0.44,0.51,0.57l0.91,0.53l0.63,0.67
                  l0.42,0.94c0.03,0.05,0.05,0.1,0.08,0.15l0.69,1.09c0.11,0.17,0.25,0.31,0.42,0.41l1.2,0.71c0.13,0.07,0.26,0.12,0.4,0.15l1.45,0.28
                  c0.15,0.03,0.31,0.03,0.46,0l0.87-0.16l0.86,0.36l0.72,0.64l0.86,0.9l0.66,1.02c0.09,0.14,0.22,0.27,0.37,0.37l2.61,1.73l0.89,1.29
                  c0.07,0.1,0.16,0.19,0.26,0.27l1.4,1.1c0.24,0.19,0.54,0.28,0.84,0.27l1.24-0.07c0.29-0.02,0.56-0.13,0.78-0.33l0.97-0.89
                  c0.11-0.11,0.21-0.24,0.28-0.39l1.05-2.13l0.98-0.71l0.72-0.43l1.37,0.2c0.14,0.02,0.29,0.01,0.42-0.01l0.98-0.2l0.43,0.27
                  l0.27,0.97c0.1,0.39,0.38,0.7,0.76,0.84l0.34,0.13l-0.06,0.52c-0.07,0.53,0.21,1.04,0.7,1.28l1.06,0.51l0.99,0.71
                  c0.22,0.15,0.47,0.23,0.73,0.23c0.15,0,0.3-0.03,0.45-0.08l1.02-0.39l1.13-0.13c0.11-0.01,0.22-0.03,0.32-0.07l1.18-0.45
                  c0.4-0.16,0.69-0.5,0.78-0.92l0.21-1.07l0.63-0.75c0.14-0.17,0.24-0.38,0.27-0.6l0.62-3.47c0.04-0.21,0.02-0.44-0.05-0.64l-0.52-1.5
                  l-0.4-0.96l-0.03-0.48l0.49-0.49c0.05-0.04,0.09-0.09,0.13-0.14l0.21-0.28l0.45,0.19c0.09,0.04,0.19,0.07,0.29,0.08l1.68,0.27
                  c0.27,0.05,0.56,0,0.8-0.14l0.66-0.36l0.91,0.2c0.39,0.09,0.79-0.02,1.09-0.28l0.55-0.48l0.84,0.08l2.72,0.76
                  c0.2,0.06,0.42,0.07,0.63,0.02l1.33-0.3c0.18-0.04,0.35-0.13,0.5-0.25l1.06-0.84c0.05-0.04,0.09-0.08,0.13-0.13l0.65-0.68l2.5-0.98
                  c0.1-0.04,0.19-0.08,0.27-0.14l0.8-0.58l0.96,0.06c0.25,0.02,0.49-0.05,0.7-0.17l1.13-0.66l1.34-0.2c0.2-0.04,0.4-0.12,0.56-0.24
                  l1.02-0.77c0.06-0.04,0.11-0.09,0.16-0.14l0.96-1.01c0.05-0.06,0.11-0.13,0.15-0.2l0.48-0.76l0.81-0.26c0.3-0.09,0.55-0.29,0.7-0.55
                  l0.66-1.11l1.27-1.72c0.36-0.49,0.31-1.17-0.11-1.61l-0.64-0.66l0.01-0.23l0.71-0.71c0.08-0.08,0.15-0.17,0.2-0.27l0.83-1.5
                  c0.05-0.08,0.08-0.17,0.11-0.25l0.53-1.77l0.31-1.16l0.47-1.02l0.71-0.85l0.89-0.29c0.39-0.13,0.69-0.44,0.81-0.82l0.38-1.27
                  l0.35-1.56c0.04-0.18,0.04-0.38-0.01-0.57l-0.28-1.17l0.21-0.69l0.77-0.78c0.11-0.11,0.2-0.25,0.26-0.4l0.15-0.35l0.94,0.49
                  l1.11,0.52c0.3,0.14,0.64,0.16,0.96,0.04l0.67-0.24l0.71,0.41c0.21,0.11,0.44,0.17,0.68,0.16l1.31-0.07l0.81,0.09l0.28,0.32
                  l-0.05,0.7c-0.02,0.24,0.04,0.47,0.15,0.68l0.61,1.13c0.14,0.26,0.37,0.47,0.64,0.57l0.68,0.27c-0.05,0.13-0.09,0.27-0.1,0.41
                  l-0.11,1.15l-0.22,1l-0.33,0.45l-0.9,0.26c-0.23,0.06-0.44,0.19-0.6,0.38l-0.84,0.96c-0.21,0.24-0.32,0.54-0.31,0.86l0.03,0.84
                  l-0.46,0.7c-0.12,0.19-0.19,0.41-0.2,0.63l-0.04,1.08l-0.31,1.04l-0.3,0.73l-0.74,0.51c-0.32,0.21-0.51,0.55-0.55,0.93l-0.24,3.03
                  c-0.01,0.13,0,0.26,0.03,0.39l0.31,1.3l0.02,0.53l-1.47,0.92l-1.05-0.01h-0.01c-0.39,0-0.75,0.18-0.99,0.49l-0.83,1.08l-0.71,1.06
                  l-0.97,0.67c-0.44,0.3-0.65,0.84-0.51,1.36l0.42,1.52l0.4,1.25l0.22,1.16l0.03,1.03l-0.25,1.16l-0.86,5.03
                  c-0.05,0.29,0,0.58,0.15,0.84l0.78,1.32c0.16,0.26,0.41,0.46,0.7,0.55l1.35,0.43c0.22,0.07,0.45,0.08,0.67,0.03l0.98-0.24l0.76,0.32
                  l0.66,0.59l0.41,0.8l0.38,1.15l0.32,1.28c0.01,0.04,0.01,0.07,0.03,0.1l0.32,0.97l-0.03,1.08c0,0.12,0.01,0.24,0.04,0.35l0.25,0.97
                  l-0.24,0.98l-0.92,0.89l-0.9,0.34c-0.09,0.03-0.17,0.08-0.25,0.13l-0.68,0.45l-0.6-0.27c-0.49-0.24-1.09-0.11-1.45,0.3l-0.81,0.91
                  l-1.23,0.93l-1.25,1.03l-0.95,0.9c-0.17,0.17-0.3,0.38-0.35,0.61l-0.32,1.28c-0.05,0.18-0.05,0.37-0.02,0.55l0.26,1.25l0.25,2.42
                  c0.02,0.13,0.05,0.25,0.1,0.37l0.18,0.39l-0.4,0.35c-0.14,0.13-0.25,0.29-0.32,0.48l-0.46,1.17c-0.14,0.34-0.12,0.71,0.05,1.03
                  c0.16,0.32,0.46,0.56,0.81,0.64l0.72,0.18l0.97,1.86c0.05,0.1,0.11,0.19,0.19,0.27l0.88,0.99c0.05,0.06,0.11,0.12,0.18,0.17
                  l0.09,0.07c-0.3,0.01-0.59,0.14-0.81,0.36l-3.5,3.4l-2.14,1.04c-0.24,0.12-0.43,0.32-0.55,0.56l-0.08,0.15l-1.11-0.57
                  c-0.09-0.05-0.2-0.09-0.3-0.11l-1.24-0.27c-0.1-0.02-0.21-0.03-0.29-0.03l-1.26,0.02c-0.26,0-0.51,0.09-0.72,0.25l-1,0.74
                  c-0.11,0.08-0.21,0.18-0.29,0.29l-0.71,1.03c-0.04,0.06-0.07,0.12-0.1,0.18l-1.03,2.27c-0.08,0.19-0.11,0.39-0.1,0.6l0.09,1.07
                  l-0.25,0.91l-0.45,1.11c-0.07,0.17-0.1,0.35-0.09,0.52l0.06,1.36c0,0.1,0.02,0.19,0.04,0.28l0.51,1.85l-0.27,0.4l-0.72,0.6
                  l-0.96,0.31c-0.18,0.07-0.35,0.17-0.48,0.31l-1.83,1.81l-0.94,0.67c-0.28,0.2-0.46,0.51-0.51,0.86l-0.08,0.59l-2.08,0.84
                  c-0.52,0.2-0.84,0.73-0.78,1.28l0.3,2.63c0.05,0.36,0.25,0.68,0.56,0.88l0.67,0.43l0.09,0.75c0.04,0.31,0.19,0.59,0.43,0.8l0.93,0.8
                  c0.23,0.2,0.51,0.32,0.84,0.3l1.24-0.03c0.04,0,0.08,0,0.12-0.01l1.23-0.15c0.11-0.01,0.21-0.04,0.31-0.08l0.97-0.38l0.81-0.02
                  l2.16,0.83c0.05,0.01,0.09,0.02,0.13,0.03l0.81,0.2l0.44,0.55l0.56,1c0.05,0.09,0.11,0.18,0.19,0.25l0.88,0.92
                  c0.19,0.21,0.45,0.34,0.72,0.38l1.27,0.18c0.55,0.07,1.06-0.2,1.3-0.68l0.26-0.53l0.35,0.59c0.32,0.55,1,0.77,1.58,0.52l1.57-0.68
                  l0.09,0.19c0.06,0.12,0.15,0.24,0.25,0.34l2.07,1.99c0.07,0.06,0.14,0.12,0.22,0.17l1.31,0.8c0.24,0.14,0.52,0.21,0.8,0.17l1.3-0.16
                  c0.25-0.03,0.49-0.14,0.68-0.31l0.99-0.88c0.19-0.17,0.33-0.39,0.38-0.64l0.34-1.39l0.03,0.01c0.04,0.01,0.08,0.02,0.12,0.02
                  l1.92,0.4c0.45,0.1,0.92-0.07,1.21-0.42l0.88-1.06c0.22-0.27,0.32-0.62,0.28-0.97l-0.12-0.9l1.37-1.38
                  c0.08-0.08,0.15-0.17,0.21-0.27l0.29,0.18l0.77,0.73l0.31,0.36l-0.09,0.26l-0.72,0.91c-0.1,0.12-0.18,0.26-0.22,0.41l-0.44,1.41
                  c-0.09,0.29-0.08,0.6,0.05,0.88l0.53,1.18c0.19,0.42,0.58,0.7,1.04,0.74s0.89-0.18,1.14-0.55l0.5-0.75l0.58-0.28l0.87-0.02
                  l1.19,0.06l1.37,0.25l0.69,0.3l0.64,0.93c0.06,0.09,0.13,0.17,0.21,0.23L1391.63,272.19z"/>
                <title>Humenné</title>
              </Link>

              {/* Presovsky Vranov nad toplou */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Vranov nad Topľou")}>
                <path style={{ fill: crimesInRegionPresovsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionPresovsky[7].crimePercent)] ? crimesInRegionPresovsky[7].color : "#D3D3D3" }}
                  d="M1290.86,321.45c-0.26,0.26-0.39,0.61-0.37,0.97l0.09,1.41c0,0.04,0.01,0.09,0.02,0.13l0.16,0.95l-0.01,0.01
                  l-0.23-0.03c-0.09-0.01-0.17-0.01-0.26-0.01l-1.44,0.1c-0.2,0.02-0.4,0.08-0.57,0.19l-1.04,0.64l-1.01,0.46l-1.03,0.28l-1.29,0.09
                  c-0.36,0.03-0.69,0.21-0.91,0.5c-0.22,0.28-0.3,0.65-0.23,1l0.07,0.32l-2.87,0.83c-0.56,0.16-0.94,0.7-0.9,1.28l0.07,1.1l-0.02,0.08
                  l-0.78,0.04l-0.69-0.04l-0.1-0.16l0.08-0.86c0.04-0.34-0.07-0.68-0.3-0.94c-0.23-0.27-0.56-0.42-0.9-0.43l-1.3-0.04
                  c-0.24-0.02-0.47,0.05-0.67,0.17l-1.55,0.9c-0.15,0.09-0.29,0.21-0.39,0.35l-0.74,1.05c-0.14,0.19-0.22,0.41-0.23,0.65l-0.06,1.14
                  l-0.04,0.07l-0.36-0.05c-0.35-0.06-0.7,0.04-0.98,0.26c-0.27,0.22-0.44,0.55-0.46,0.9l-0.01,0.21l-0.19,0.02
                  c-0.26,0.03-0.5,0.13-0.69,0.31l-0.55,0.48l-0.84-0.03c-0.09,0-0.18,0-0.27,0.02l-0.86,0.15l-0.78-0.36
                  c-0.31-0.14-0.65-0.16-0.97-0.04l-1.12,0.42l-0.99,0.3l-0.7-0.03l-0.82-0.54c-0.1-0.07-0.2-0.12-0.31-0.15l-0.94-0.3l-1.84-1.87
                  l-0.57-0.7l-0.27-1.01c-0.05-0.15-0.12-0.3-0.22-0.43l-0.86-1.13c-0.2-0.26-0.49-0.43-0.82-0.48l-1.25-0.17
                  c-0.08-0.01-0.16-0.01-0.23-0.01l-1.15,0.06l-1.33-0.02c-0.12,0-0.24,0.01-0.36,0.05l-1.09,0.3l-1.15,0.14
                  c-0.12,0.01-0.24,0.05-0.35,0.09l-2.59,1.14l-1.85,0.56l-1.55-1.09l-0.89-0.81c-0.13-0.12-0.28-0.21-0.44-0.26l-1.27-0.43
                  l-1.25-0.35c-0.27-0.08-0.55-0.06-0.81,0.04l-0.62,0.25l-0.1-0.05l0.01-0.53c0-0.38-0.16-0.75-0.46-0.99l-2.29-1.92
                  c-0.12-0.1-0.26-0.18-0.42-0.24l-1.29-0.43l-0.91-0.59c-0.23-0.14-0.48-0.22-0.76-0.2l-1.3,0.08c-0.09,0.01-0.18,0.02-0.27,0.05
                  l-1.3,0.37l-1.01,0.19l-0.7-0.29l-0.61-0.56l-0.35-1.27c-0.01-0.06-0.04-0.13-0.06-0.19l-0.58-1.27c-0.08-0.17-0.2-0.32-0.34-0.44
                  l-0.89-0.75l-0.7-1.25l-0.68-1.01l-0.1-0.59l0.39-0.71c0.04-0.08,0.08-0.17,0.11-0.25l0.36-1.21c0.05-0.18,0.06-0.37,0.03-0.56
                  l-0.2-1.26c-0.01-0.09-0.04-0.19-0.08-0.28l-0.51-1.23c-0.01-0.04-0.04-0.09-0.06-0.13l-0.6-1.08c-0.06-0.12-0.15-0.23-0.25-0.32
                  l-0.75-0.67l-0.38-0.9c-0.12-0.27-0.33-0.5-0.6-0.63l-1.19-0.59c-0.25-0.12-0.53-0.16-0.81-0.1l-0.76,0.16l-0.62-0.41l-0.69-0.61
                  l-0.52-0.93c-0.03-0.07-0.08-0.14-0.13-0.2l-0.72-0.86l-0.43-0.91l-0.17-0.85l0.31-1.15l0.22-1.04l0.49-0.9l0.76-1.11
                  c0.27-0.4,0.29-0.9,0.06-1.32l-0.68-1.22c-0.11-0.2-0.28-0.37-0.49-0.49l-1.22-0.67c-0.2-0.1-0.42-0.16-0.64-0.15l-0.98,0.03
                  l0.07-0.41c0.02-0.13,0.02-0.26,0.01-0.39l-0.38-2.82c-0.03-0.18-0.09-0.35-0.18-0.5l0.19,0.03l0.49-2.94
                  c0.06-0.34-0.03-0.69-0.23-0.96l-0.65-0.87l-0.26-0.9l0.08-1.01c0.01-0.15-0.01-0.29-0.05-0.43l-0.37-1.29
                  c-0.02-0.08-0.05-0.16-0.09-0.23l-0.46-0.91l-0.1-0.96l0.15-0.89l0.5-1.14c0.02-0.06,0.05-0.13,0.06-0.2l0.3-1.22
                  c0.02-0.06,0.03-0.12,0.04-0.18l0.13-1.4c0.02-0.31-0.06-0.62-0.25-0.87l-0.81-1.09c-0.17-0.23-0.42-0.39-0.69-0.46l-0.99-0.25
                  l-0.74-0.55l-0.55-0.81l-0.45-0.96l-0.14-0.77l0.24-0.69l0.69-0.9c0.23-0.3,0.31-0.69,0.22-1.06l-0.31-1.27
                  c-0.11-0.47-0.49-0.83-0.96-0.92l-1.3-0.27l-0.74-0.3l-0.52-0.64l-0.4-1.02c-0.02-0.07-0.06-0.13-0.09-0.19l-0.56-0.91l-0.06-1.19
                  c-0.01-0.18-0.06-0.35-0.14-0.51l-0.58-1.11c-0.12-0.23-0.31-0.42-0.55-0.54l-1-0.5l-0.66-0.9c-0.07-0.1-0.16-0.18-0.25-0.25
                  l-0.84-0.65l-0.44-0.74l-0.31-1.13c-0.02-0.04-0.03-0.08-0.04-0.12l-0.45-1.17c-0.02-0.05-0.04-0.1-0.07-0.14l-0.6-1.11
                  c-0.03-0.07-0.07-0.13-0.12-0.18l-0.75-0.96l-0.45-1.01c-0.04-0.1-0.1-0.19-0.17-0.28l-0.8-0.98c-0.12-0.15-0.28-0.27-0.46-0.35
                  l-1.22-0.55c-0.1-0.05-0.21-0.08-0.33-0.1l-0.5-0.07l-0.09-0.24l0.11-0.51l0.54-0.36l1.17-0.52c0.08-0.03,0.14-0.07,0.21-0.11
                  l1.09-0.75c0.14-0.1,0.26-0.23,0.35-0.37l0.68-1.09c0.2-0.34,0.24-0.76,0.09-1.12l-0.5-1.25c-0.05-0.12-0.11-0.23-0.19-0.33
                  l-2.71-3.24c-0.07-0.09-0.16-0.16-0.25-0.23l-0.28-0.19l0.45-0.59c0.1-0.14,0.18-0.31,0.22-0.48l0.54-2.41l0.35-1.13l0.31-0.84
                  l0.74-0.49c0.05-0.04,0.1-0.08,0.14-0.12l0.94-0.86c0.17-0.15,0.29-0.35,0.36-0.56l0.27-0.93l0.69-0.65
                  c0.08-0.08,0.14-0.16,0.2-0.25l0.25-0.4l0.56,0.04c0.28,0.01,0.55-0.06,0.78-0.21l1.07-0.71c0.16-0.11,0.3-0.25,0.39-0.42l0.54-0.93
                  l1.17-0.6c0.08-0.04,0.15-0.08,0.21-0.14l1.02-0.82c0.15-0.12,0.27-0.27,0.35-0.44l2.21-4.67c0.2-0.43,0.15-0.92-0.13-1.3
                  l-0.67-0.88l-0.03-0.2l0.43-0.29c0.14-0.1,0.26-0.23,0.36-0.38l0.26-0.44h0.01c0.04-0.01,0.09-0.02,0.14-0.03l2.53-0.68l1.21-0.17
                  l2.28,0.49l1.07,0.35l1.08,0.47l0.98,0.67l1.35,0.81c0.28,0.16,0.61,0.21,0.92,0.14l0.57-0.14l0.12,0.98
                  c0.02,0.2,0.08,0.39,0.19,0.56l0.73,1.17c0.22,0.36,0.62,0.58,1.04,0.59c0.38,0,0.82-0.2,1.06-0.55l0.55-0.83l0.49-0.22l1.03,0.11
                  c0.25,0.03,0.5-0.02,0.72-0.13l1.18-0.62c0.14-0.08,0.27-0.18,0.38-0.3l0.76-0.91l0.48-0.49l0.7,0.02c0.32,0.01,0.62-0.1,0.85-0.31
                  l1-0.87c0.07-0.05,0.12-0.12,0.17-0.18c0.07-0.01,0.13-0.01,0.19-0.03c0.35-0.07,0.65-0.29,0.82-0.59l0.68-1.18l0.96-0.56
                  c0.36-0.2,0.6-0.57,0.63-0.99l0.09-1.23c0-0.09,0-0.18-0.01-0.26l-0.19-1.29l-0.09-1.01l0.29-0.87l0.52-1.04
                  c0.09-0.17,0.13-0.37,0.13-0.56v-1.24c0-0.11-0.02-0.23-0.05-0.33l-0.33-1.19c-0.01-0.04-0.02-0.08-0.03-0.12l-0.13-0.33l0.29-0.25
                  c0.19-0.16,0.33-0.37,0.4-0.6l0.09,0.26c0.12,0.35,0.39,0.63,0.73,0.76l0.84,0.32l0.57,0.78c0.16,0.23,0.4,0.39,0.66,0.47l1.31,0.38
                  l0.65,0.38l0.57,0.98c0.04,0.07,0.1,0.14,0.15,0.21l1.61,1.76l0.08,0.17l-0.26,0.25c-0.28,0.27-0.43,0.66-0.38,1.05l0.13,1.16
                  l-0.03,1.15c-0.01,0.33,0.11,0.64,0.34,0.88l0.71,0.77l0.36,0.78l0.19,0.99l0.12,1.02l-0.34,0.92c-0.13,0.34-0.1,0.72,0.07,1.04
                  c0.18,0.32,0.48,0.54,0.83,0.62l1.28,0.28c0.11,0.02,0.21,0.03,0.32,0.03l0.22-0.01l0.08,0.29c0.03,0.14,0.09,0.27,0.17,0.39
                  l0.68,1.03c0.04,0.07,0.1,0.14,0.16,0.2l1.24,1.22l0.84,0.79l0.41,0.96c0.08,0.18,0.21,0.35,0.37,0.47l0.84,0.68l0.52,0.86
                  c0.09,0.16,0.21,0.29,0.36,0.39l2.28,1.53l0.9,0.77c0.26,0.21,0.59,0.32,0.93,0.28c0.34-0.03,0.65-0.2,0.86-0.46l0.72-0.9l0.38-0.31
                  l0.63,0.28c0.37,0.16,0.79,0.14,1.14-0.06l1.29-0.75c0.07-0.04,0.14-0.09,0.2-0.14l1.02-0.9c0.05-0.05,0.1-0.1,0.14-0.15l1.08-1.32
                  c0.31-0.38,0.37-0.9,0.15-1.34l-0.73-1.51l-1.05-2.41l1.44-0.1c0.27-0.02,0.53-0.13,0.74-0.31l1.01-0.91l0.84-0.81l1.02-0.81
                  c0.03-0.02,0.06-0.04,0.09-0.07l0.69-0.65l1.34-0.08c0.29-0.02,0.57-0.14,0.78-0.35l1.12-1.06l3.16-3.36l0.99-0.94
                  c0.12-0.12,0.22-0.26,0.29-0.41l0.53-1.22c0.11-0.26,0.13-0.54,0.06-0.81l-0.3-1.19l-0.02-1.22l0.2-1.2
                  c0.05-0.3-0.01-0.61-0.17-0.87l-1.17-1.95l-0.21-1.15l-0.31-1.2l-0.19-1.13l0.04-0.52l0.59-0.34l1.08-0.5l0.79-0.29l0.84,0.21
                  c0.58,0.14,1.18-0.16,1.43-0.7l0.26-0.57l0.07,0.13l0.38,2.04c0.01,0.06,0.03,0.12,0.05,0.19l0.33,0.93l-0.33,2.78
                  c-0.01,0.08-0.01,0.16-0.01,0.23l0.1,1.31c0.01,0.14,0.04,0.26,0.09,0.39l0.38,0.91l-0.06,0.93l-0.52,1.39
                  c-0.2,0.54-0.01,1.14,0.45,1.46l0.79,0.55l0.45,0.75l0.38,1.07c0.06,0.18,0.16,0.33,0.29,0.46l1.58,1.68l1.25,2.1l0.12,0.49
                  l-0.43,0.51c-0.34,0.41-0.39,1-0.11,1.45l0.49,0.79l0.05,1c0.02,0.3,0.14,0.58,0.35,0.8l0.89,0.91l-0.69,0.35
                  c-0.17,0.09-0.33,0.22-0.45,0.38l-0.74,1.05l-0.87,1.08c-0.12,0.14-0.2,0.31-0.25,0.5l-0.34,1.46l-0.19,1.02l-0.7,0.6
                  c-0.29,0.25-0.45,0.61-0.44,0.98l0.03,0.95l-0.56,0.54c-0.29,0.28-0.43,0.69-0.37,1.09l0.14,0.86l-0.4,0.69
                  c-0.16,0.28-0.21,0.61-0.14,0.92l0.31,1.29l0.39,1.29c0.1,0.32,0.32,0.59,0.61,0.74c0.29,0.16,0.64,0.19,0.95,0.1l0.96-0.3
                  l0.46,0.16l0.47,0.71c0.19,0.27,0.47,0.46,0.79,0.52l0.75,0.16l-0.82,1.07c-0.13,0.17-0.22,0.37-0.25,0.58l-0.15,1.05l-0.53,0.86
                  c-0.15,0.25-0.21,0.56-0.16,0.85l0.22,1.35c0.06,0.41,0.33,0.77,0.72,0.94l1.09,0.49l0.78,0.4l0.27,0.56l0.06,0.89l-0.3,1.43
                  l-0.23,1.44c-0.04,0.24-0.01,0.47,0.08,0.69l0.65,1.53c0.07,0.17,0.19,0.33,0.33,0.46l1.26,1.07l1.14,1.07l0.67,0.79l0.5,1.19
                  c0.11,0.28,0.33,0.51,0.61,0.65l1.02,0.49l0.28,0.47v0.89l-0.34,1.21c-0.02,0.05-0.03,0.11-0.04,0.16l-0.22,1.55l-0.5,1.04
                  c-0.03,0.07-0.06,0.15-0.08,0.23l-0.31,1.22c-0.03,0.12-0.04,0.25-0.04,0.37l0.1,1.95c0.01,0.09,0.02,0.17,0.04,0.25
                  c-0.11,0.03-0.22,0.08-0.31,0.14l-1.25,0.78c-0.06,0.04-0.12,0.08-0.18,0.13l-1.02,0.92c-0.16,0.14-0.27,0.32-0.34,0.52l-1.73,4.74
                  c-0.16,0.42-0.08,0.88,0.21,1.22l0.73,0.89l0.18,1.03l-0.05,2.26l-0.55,1.1l-0.54,1.21c-0.14,0.3-0.15,0.64-0.03,0.95l0.52,1.38
                  c0.14,0.36,0.43,0.63,0.79,0.75l1.19,0.38c0.13,0.04,0.26,0.06,0.4,0.06h0.28l0.13,0.53l-0.24,0.8l-0.01,0.01
                  c-0.12,0.15-0.22,0.31-0.27,0.51l-0.31,1.22c-0.06,0.21-0.05,0.43,0,0.64l0.6,2.31l0.04,0.56l-0.53,0.46
                  c-0.38,0.33-0.52,0.86-0.35,1.34l0.42,1.23c0.03,0.11,0.08,0.21,0.14,0.3l0.52,0.77l0.07,0.94c0.02,0.29,0.15,0.56,0.35,0.76
                  l0.68,0.72l0.05,0.22l-1.26,1.34c-0.22,0.24-0.33,0.56-0.32,0.88l0.04,1.28c0,0.07,0.01,0.15,0.03,0.22l0.26,1.2
                  c0.01,0.07,0.03,0.13,0.05,0.19l0.43,1.1l0.41,1.33l0.43,1.14l0.7,2.62c0.02,0.09,0.05,0.17,0.09,0.25l0.37,0.77l-0.18,1.03
                  l-0.17,1.42c-0.01,0.04-0.01,0.07-0.01,0.11l-0.04,1.01l-0.56,0.76c-0.25,0.33-0.32,0.76-0.19,1.15l0.41,1.23
                  c0.06,0.18,0.17,0.35,0.3,0.48l0.78,0.78l0.57,0.93c0.03,0.04,0.05,0.08,0.08,0.11l0.66,0.87l0.6,1.46L1290.86,321.45z"/>
                <title>Vranov nad Topľou</title>
              </Link>

              {/* Michalovce - kosicky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Michalovce")}>
                <path style={{ fill: crimesInRegionKosicky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionKosicky[2].crimePercent)] ? crimesInRegionKosicky[2].color : "#D3D3D3" }}
                  d="M1385.65,296.03v0.18l0.08,0.97l-0.37,1.06l-0.62,2.33l-0.44,0.95l-0.66,0.98c-0.08,0.12-0.14,0.26-0.17,0.4
                  l-0.35,1.41c-0.05,0.17-0.05,0.35-0.02,0.52l0.16,0.9l-0.36,1.07l-0.55,1.08c-0.02,0.04-0.04,0.08-0.05,0.13l-0.47,1.27l-0.53,1.17
                  c-0.18,0.4-0.14,0.85,0.1,1.21l0.64,0.96l0.39,0.92l0.08,0.63l-0.47,0.37c-0.32,0.25-0.5,0.64-0.48,1.05l0.04,0.78l-0.48,0.54
                  c-0.07,0.08-0.13,0.17-0.17,0.26l-0.17,0.34l-0.18-0.03l-0.64-0.59c-0.24-0.22-0.57-0.33-0.87-0.33l-2.55,0.04
                  c-0.17,0-0.34,0.04-0.5,0.11l-1.17,0.53c-0.13,0.06-0.26,0.15-0.37,0.26l-0.87,0.88l-0.1-0.14c-0.23-0.31-0.59-0.5-0.98-0.51
                  c-0.37-0.01-0.75,0.16-0.99,0.45l-0.77,0.92l-1.03,0.95c-0.04,0.03-0.08,0.07-0.12,0.12l-0.76,0.9l-1.04,0.42
                  c-0.09,0.03-0.17,0.08-0.25,0.13l-1.25,0.86c-0.29,0.19-0.48,0.49-0.53,0.83l-0.26,1.58l-0.16,1.38c-0.05,0.39,0.09,0.78,0.38,1.05
                  l0.16,0.15l-0.25,0.3c-0.07,0.09-0.13,0.19-0.17,0.29l-0.47,1.12l-0.56,0.81l-0.83,0.82c-0.07,0.07-0.14,0.15-0.19,0.24l-0.68,1.12
                  c-0.14,0.23-0.2,0.5-0.17,0.77l0.29,2.92l-0.03,1.24c-0.01,0.29,0.09,0.57,0.26,0.8l0.76,0.98c0.29,0.37,0.76,0.54,1.22,0.46
                  l1.26-0.24l0.31-0.05l-0.3,0.2l-1.32,0.44l-1.41-0.07c-0.17-0.01-0.35,0.02-0.51,0.08l-1.34,0.51l-1.36,0.27
                  c-0.12,0.03-0.22,0.06-0.33,0.12l-1.47,0.75c-0.38,0.19-0.64,0.57-0.67,1l-0.13,1.42c-0.02,0.18,0,0.37,0.07,0.54l0.47,1.28
                  l0.31,1.16c0.03,0.1,0.07,0.2,0.13,0.3l1.52,2.62l0.69,2.35l0.21,1.16c0.01,0.04,0.02,0.08,0.03,0.12l0.35,1.23
                  c0.04,0.14,0.1,0.26,0.18,0.38l0.64,0.92l0.43,0.92l0.08,1.25l-0.08,0.92l-0.54,1.03c-0.16,0.31-0.19,0.68-0.06,1.01l0.46,1.24
                  c0.18,0.49,0.65,0.82,1.17,0.82h0.28l0.78,2.97l-1.16,0.87l-1.35,1.13c-0.25,0.21-0.41,0.51-0.44,0.83l-0.13,1.26
                  c-0.06,0.56,0.26,1.09,0.78,1.29l0.79,0.31l0.27,0.84c0.03,0.09,0.07,0.18,0.12,0.26l0.03,0.05l-0.09,0.07
                  c-0.36,0.22-0.58,0.62-0.58,1.04l-0.01,1.15l-0.74,1.1c-0.09,0.14-0.16,0.3-0.19,0.46l-0.24,1.24c-0.07,0.36,0.02,0.72,0.24,1.01
                  l0.76,0.98c0.27,0.34,0.69,0.52,1.11,0.47l0.99-0.09l0.6,0.17l0.5,0.66c0.22,0.3,0.57,0.47,0.94,0.49l1.3,0.05h0.11l0.37,0.87
                  c0.04,0.11,0.1,0.21,0.17,0.3l0.89,1.11c0.15,0.18,0.34,0.32,0.55,0.39l0.82,0.3l1.01,1.28l-0.17,0.62
                  c-0.05,0.2-0.05,0.41-0.01,0.61l0.36,1.56c0.02,0.08,0.05,0.16,0.08,0.24l0.53,1.16c0.23,0.5,0.76,0.79,1.3,0.72l1.35-0.17
                  l-0.37,0.56c-0.13,0.18-0.21,0.41-0.22,0.63l-0.14,4.31l0.01,1.76l-0.09,1.28c-0.03,0.41,0.15,0.81,0.47,1.07l0.61,0.48l0.18,2.1
                  c0.02,0.24,0.11,0.47,0.26,0.66l0.94,1.23c0.41,0.54,1.18,0.65,1.73,0.25l0.42-0.32l1.22,0.59l0.16,0.65
                  c0.03,0.14,0.09,0.27,0.17,0.39l0.52,0.78l-0.08,0.78c-0.01,0.1-0.01,0.19,0,0.29l0.19,1.46l0.25,1.28
                  c0.01,0.05,0.03,0.09,0.04,0.14l0.47,1.47c0.1,0.33,0.34,0.6,0.65,0.75c0.17,0.08,0.36,0.12,0.54,0.12c0.15,0,0.3-0.03,0.45-0.08
                  l0.69-0.27l-0.45,1.31c-0.03,0.11-0.06,0.23-0.06,0.35l-0.7,15.75l-0.66,4.48l-0.46,0.92c-0.07,0.14-0.11,0.29-0.13,0.44l-0.8,8.55
                  l-2.23,14.43l-0.54-2.22c-0.02-0.11-0.06-0.21-0.12-0.31l-0.58-1.05l-0.37-1.26l-0.32-1.34c-0.02-0.08-0.05-0.15-0.08-0.22
                  l-0.58-1.29c-0.06-0.14-0.14-0.26-0.25-0.37l-1.15-1.17c-0.09-0.09-0.19-0.16-0.3-0.22l-1.13-0.6c-0.16-0.08-0.32-0.13-0.49-0.15
                  l-1.29-0.1c-0.14-0.01-0.28,0-0.41,0.04l-1.24,0.31l-2.08,0.41l-0.46-0.36l-0.75-0.89c-0.02-0.03-0.05-0.06-0.09-0.09l-0.9-0.86
                  l-0.83-0.91l-0.99-1.37l-0.78-1.33c-0.08-0.13-0.17-0.24-0.29-0.34l-0.95-0.78c-0.19-0.15-0.42-0.25-0.66-0.27l-1.21-0.13
                  l-0.99-0.57l-0.6-0.66l-0.22-0.96c-0.06-0.25-0.19-0.47-0.38-0.65l-0.77-0.7l-0.44-0.89c-0.16-0.34-0.47-0.58-0.84-0.67l-0.99-0.23
                  l-0.48-0.38l-0.3-0.86c-0.02-0.06-0.04-0.11-0.07-0.17l-0.57-1.09c-0.23-0.44-0.72-0.7-1.18-0.68l-1.24,0.07
                  c-0.21,0.01-0.42,0.08-0.59,0.19l-0.42,0.26l-0.38-0.28c-0.29-0.21-0.65-0.29-1-0.21c-0.31,0.06-0.57,0.24-0.75,0.49
                  c-0.51-0.32-1.17-0.23-1.58,0.21l-0.61,0.64l-0.86,0.23l-1.1-0.01c-0.39-0.01-0.77,0.18-1.01,0.5s-0.31,0.73-0.19,1.11l0.07,0.25
                  c-0.05-0.02-0.11-0.03-0.16-0.04l-1.24-0.22c-0.1-0.02-0.2-0.02-0.3-0.02l-1.15,0.08h-1.07l-1.23-0.34
                  c-0.29-0.08-0.59-0.06-0.86,0.06l-1.23,0.56l-3.76,0.67l-3.74,0.61l-4,0.1c-0.35,0.01-0.68,0.16-0.91,0.42l-0.05,0.06
                  c-0.11-0.11-0.25-0.19-0.4-0.25l-1.2-0.45c-0.12-0.05-0.25-0.07-0.38-0.08l-1.32-0.06c-0.14-0.26-0.37-0.46-0.66-0.57l-1.19-0.45
                  c-0.5-0.19-1.07-0.04-1.41,0.39l-0.63,0.78l-0.73,0.39l-1.07,0.24c-0.33,0.08-0.61,0.28-0.79,0.57l-0.15-0.08
                  c-0.43-0.22-0.96-0.18-1.35,0.12l-0.04,0.03l-0.03-0.04c-0.24-0.27-0.59-0.43-0.95-0.43h-0.01c-0.37,0-0.72,0.17-0.95,0.45
                  l-0.79,0.95c-0.35,0.42-0.39,1.02-0.09,1.48l0.2,0.3l-0.73,0.53l-1.08,0.14c-0.4,0.05-0.75,0.29-0.94,0.65
                  c-0.17,0.31-0.19,0.67-0.08,1l-0.65,0.19l-0.77-0.28c-0.34-0.12-0.72-0.09-1.03,0.09c-0.32,0.17-0.55,0.47-0.62,0.83l-0.07,0.31
                  h-0.26c-0.44,0-0.84,0.24-1.06,0.61c-0.23,0.38-0.24,0.84-0.03,1.23l0.2,0.37l-0.32,0.42c-0.06,0.08-0.12,0.17-0.16,0.26l-0.43,1
                  l-0.69,0.78c-0.3,0.35-0.39,0.83-0.23,1.26l0.39,1.07l-0.21,0.05c-0.37,0.1-0.67,0.36-0.82,0.71l-0.52,1.18l-0.08-0.05l0.05-0.09
                  c0.23-0.43,0.19-0.96-0.11-1.35c-0.11-0.14-0.24-0.26-0.39-0.34l0.22-0.1c0.49-0.21,0.78-0.72,0.74-1.25
                  c-0.05-0.53-0.43-0.97-0.95-1.1l-0.82-0.21l0.21-0.15c0.33-0.24,0.53-0.62,0.53-1.02v-1.26c0-0.13-0.02-0.26-0.06-0.38l-0.19-0.59
                  l0.29-0.4l0.81-0.8c0.36-0.35,0.47-0.88,0.28-1.35l-0.88-2.32c-0.07-0.19-0.18-0.37-0.34-0.51l-0.87-0.76l-0.65-0.83l-1.35-2.2
                  l-0.43-0.93l-0.14-0.66l0.57-1.18l0.57-0.03c0.05,0,0.11-0.01,0.16-0.02l1.21-0.22c0.23-0.04,0.44-0.14,0.61-0.29l0.51-0.45
                  l0.67,0.08c0.36,0.04,0.73-0.08,1-0.33s0.42-0.61,0.4-0.97l-0.07-1.37c-0.01-0.13-0.03-0.25-0.08-0.37l0.34-0.6
                  c0.09-0.16,0.14-0.33,0.16-0.51c0.33-0.07,0.64-0.27,0.82-0.57c0.27-0.43,0.25-0.96-0.03-1.37l-0.73-1.05
                  c-0.34-0.49-0.96-0.66-1.48-0.45c-0.11-0.18-0.26-0.33-0.44-0.43l-1.17-0.68c-0.52-0.3-1.18-0.19-1.57,0.26l-0.42,0.48l-0.17-0.02
                  l-0.59-0.63l-0.84-8.08l0.3-0.69l0.69-0.89c0.27-0.33,0.34-0.78,0.2-1.18s-0.47-0.71-0.89-0.81l-1.21-0.29
                  c-0.09-0.02-0.18-0.03-0.27-0.03h-0.07l-0.4-6.74l-0.05-1.63l0.15,0.01c0.05,0,0.11,0,0.16-0.01l1.31-0.13
                  c0.38-0.03,0.72-0.24,0.93-0.56c0.2-0.31,0.26-0.71,0.14-1.07l-0.76-2.36c-0.06-0.2-0.17-0.38-0.32-0.52l-0.77-0.76l-0.45-0.74
                  l-1.18-7.4l-0.14-8.69c0-0.43-0.23-0.83-0.6-1.06l-1.15-0.7c-0.37-0.22-0.83-0.24-1.21-0.05s-0.64,0.57-0.68,1l-0.12,1.22
                  l-0.01,0.01l-1.03-4.69c-0.03-0.17-0.11-0.34-0.21-0.48l-0.75-1.02l-0.4-1.07c-0.05-0.13-0.11-0.26-0.2-0.36l-0.57-0.7l0.1-0.97
                  c0.03-0.28-0.03-0.56-0.19-0.8l-0.63-1l-0.25-1.1c-0.07-0.29-0.24-0.55-0.48-0.73l-0.72-0.53l-0.29-0.68l-0.01-1.06l0.07-1.23
                  c0-0.07,0-0.14-0.01-0.21l-0.14-1.23c-0.02-0.21-0.1-0.41-0.22-0.58l-0.72-1.03c-0.07-0.1-0.15-0.19-0.25-0.26l-0.99-0.78
                  c-0.1-0.08-0.22-0.15-0.34-0.19l-1.2-0.44c-0.1-0.04-0.2-0.06-0.31-0.07l-0.7-0.07l-0.47-0.6c-0.21-0.26-0.52-0.43-0.85-0.46
                  l-0.88-0.09l-0.27-0.24l-0.06-0.6l0.13-1.02l0.26-1.24c0.08-0.37-0.01-0.75-0.24-1.04c-0.21-0.25-0.5-0.42-0.82-0.46
                  c0-0.11-0.01-0.22-0.04-0.32l-0.31-1.2c-0.01-0.05-0.03-0.1-0.05-0.15l-0.47-1.19l-0.56-1.61c-0.04-0.13-0.11-0.24-0.19-0.35
                  l-0.55-0.71l-0.12-1.11c0,0,0,0,0-0.01l2.54-0.26c0.04,0,0.08-0.01,0.12-0.01l2.75-0.51c0.12-0.02,0.24-0.06,0.35-0.11l2.4-1.16
                  l1.28-0.53c0.53-0.21,0.84-0.77,0.76-1.33l-0.23-1.5l1.32-2.66l0.5-1.15c0.07-0.17,0.11-0.36,0.1-0.54l-0.14-3.35
                  c-0.02-0.29-0.14-0.57-0.34-0.79l-0.88-0.93c-0.11-0.11-0.24-0.21-0.38-0.27l-0.74-0.34l-0.19-0.37l0.12-0.83l-0.26-0.04l0.22-1.01
                  c0.02-0.11,0.03-0.22,0.03-0.34l-0.02-0.27l1.64-0.46l1.41-0.46c0.6-0.2,0.96-0.82,0.83-1.44v-0.01c0.08-0.01,0.15-0.02,0.23-0.04
                  l1.25-0.34c0.06-0.02,0.12-0.04,0.18-0.07l1.17-0.53c0.05-0.02,0.1-0.05,0.15-0.08l0.84-0.52l1.01-0.07l1.11,0.15
                  c0.59,0.08,1.14-0.26,1.34-0.82l0.46-1.28c0.08-0.2,0.1-0.42,0.06-0.63l-0.21-1.21l-0.05-0.77l1.44-1.46
                  c0.34-0.36,0.44-0.89,0.25-1.35l-0.96-2.36c-0.04-0.1-0.1-0.2-0.17-0.29l-0.73-0.95l-0.62-1c-0.05-0.09-0.11-0.16-0.18-0.23
                  l-0.67-0.68l-0.11-0.33l0.41-0.54c0.15-0.21,0.24-0.45,0.25-0.71l0.04-1.35l0.16-1.29l0.25-1.36c0.05-0.27,0.01-0.54-0.11-0.78
                  l-0.5-1.02l-0.34-1.35l-0.38-1.31l-0.43-1.18l-0.42-1.34l-0.42-1.09l-0.22-0.99l-0.02-0.67l1.32-1.33c0.31-0.31,0.45-0.75,0.35-1.18
                  l-0.29-1.25c-0.05-0.22-0.16-0.42-0.32-0.59l-0.61-0.63l-0.07-0.83c-0.01-0.21-0.08-0.42-0.2-0.6l-0.61-0.9l-0.1-0.28l0.36-0.32
                  c0.3-0.26,0.45-0.64,0.43-1.03l-0.09-1.28c-0.01-0.07-0.02-0.13-0.04-0.2l-0.53-2.1l0.22,0.01c0.61,0.02,1.06-0.34,1.23-0.86
                  l0.16-0.5l0.47-0.07c0.44-0.07,0.81-0.37,0.97-0.79l0.16-0.4l0.69-0.03c0.05,0,0.1-0.01,0.16-0.02l0.91-0.16l0.85,0.48
                  c0.06,0.04,0.12,0.07,0.19,0.09l0.73,0.27l0.59,1.71c0.01,0.03,0.02,0.06,0.03,0.09l0.51,1.25c0.21,0.52,0.75,0.84,1.31,0.77
                  l0.49-0.06l0.22,0.39c0.32,0.56,1.01,0.79,1.61,0.52l1.54-0.7c0.21-0.1,0.39-0.26,0.52-0.45l0.48-0.73l2.04-0.32l1.15-0.05
                  l1.34,0.21l1.2,0.12l1.05,0.41l1.44,0.47c0.04,0.01,0.08,0.02,0.12,0.03l1.09,0.24l0.86,0.35l0.78,0.71
                  c0.09,0.08,0.19,0.14,0.29,0.19l1.24,0.6c0.2,0.1,0.42,0.14,0.65,0.13l0.65-0.06l0.51,0.57c0.2,0.23,0.47,0.37,0.78,0.41l1.41,0.17
                  l0.94,0.21l0.68,0.66l0.91,1.03c0.07,0.08,0.16,0.15,0.24,0.21l1.08,0.72c0.05,0.03,0.1,0.06,0.15,0.08l2.46,1.16
                  c0.12,0.05,0.25,0.09,0.37,0.1l1.12,0.13l0.45,0.19l0.02,0.43l-0.15,1.38c-0.01,0.1,0,0.21,0.01,0.31l0.19,1.34
                  c0.02,0.12,0.06,0.25,0.11,0.37l0.42,0.86l0.02,0.95c0,0.32,0.13,0.63,0.35,0.85l0.86,0.88c0.1,0.1,0.2,0.18,0.32,0.24l1.01,0.53
                  l1.06,0.65c0.15,0.08,0.31,0.14,0.47,0.17l2.48,0.32c0.29,0.04,0.59-0.04,0.84-0.21l0.57-0.38l0.65,0.17
                  c0.06,0.01,0.12,0.02,0.18,0.03l1.32,0.15c0.05,0.01,0.08,0.01,0.15,0.01l0.7-0.01l0.46,0.48l-0.01,1.46
                  c-0.01,0.66,0.5,1.21,1.16,1.26l7.29,0.42l2.6-0.01c0.09,0,0.19-0.01,0.28-0.03l3-0.74l7.47-2.33l0.88,0.16l1.22,0.29
                  c0.08,0.01,0.16,0.03,0.23,0.03l4.94,0.25l5.58,0.1l3.14-0.07c0.34-0.01,0.65-0.16,0.88-0.4c0.22-0.25,0.34-0.57,0.33-0.91
                  l-0.06-1.16l0.25-1.05c0.02-0.12,0.03-0.23,0.03-0.35l-0.07-1.25c-0.01-0.12-0.03-0.23-0.07-0.34l-0.33-0.96l0.04-1l0.18-1.46
                  c0.01-0.08,0.01-0.17,0-0.25l-0.12-1.53l-0.17-1.37c-0.02-0.16-0.07-0.31-0.14-0.44l-0.65-1.21c-0.03-0.05-0.06-0.1-0.09-0.14
                  l-0.52-0.73l0.22-2.1l0.05-0.81l0.2-0.21l1.01-0.01c0.11,0,0.22-0.02,0.32-0.05l1.3-0.36l1.14,0.02c0.17,0,0.4-0.04,0.59-0.14
                  l1.06-0.55l0.49-0.2l0.12,0.35l0.36,1.39c0.06,0.25,0.2,0.47,0.39,0.63l0.76,0.66l0.25,0.7l0.01,0.98l-0.22,1.08
                  c-0.04,0.16-0.04,0.32-0.01,0.48l0.2,1.07L1385.65,296.03z"/>
                <title>Michalovce</title>
              </Link>

              {/* Trebisov - kosicky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Trebišov")}>
                <path style={{ fill: crimesInRegionKosicky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionKosicky[5].crimePercent)] ? crimesInRegionKosicky[5].color : "#D3D3D3" }}
                  d="M1382.9,480.22l-0.16,0.53l-0.49,0.46l-1.79,0.55l-1.18,0.12c-0.1,0.01-0.2,0.03-0.3,0.06l-1.35,0.49
                  c-0.5,0.19-0.83,0.66-0.82,1.19l0.01,1.32c0,0.25,0.08,0.49,0.22,0.69l0.68,1.01l0.02,0.04l-0.68-0.08c-0.22-0.03-0.43,0-0.63,0.08
                  l-0.99,0.41l-0.72,0.01l-0.93-0.43c-0.2-0.09-0.43-0.13-0.65-0.1l-1.25,0.13c-0.33,0.03-0.63,0.19-0.84,0.45l-0.74,0.9l-0.64,0.32
                  l-0.8-0.25c-0.39-0.13-0.82-0.05-1.15,0.2l-0.97,0.76l-0.25-0.58l0.03-1.02c0.01-0.33-0.12-0.66-0.35-0.9l-0.99-1.03
                  c-0.06-0.06-0.14-0.12-0.21-0.17l-1.03-0.68c-0.53-0.35-1.25-0.25-1.66,0.24l-0.68,0.83l-0.52,0.37l-0.73-0.18
                  c-0.26-0.06-0.53-0.03-0.77,0.06l-1.28,0.54c-0.27,0.11-0.49,0.31-0.63,0.56l-1.06-1.1c-0.08-0.09-0.18-0.17-0.29-0.23l-1.16-0.65
                  c-0.04-0.02-0.08-0.04-0.12-0.06l-1.06-0.46l-0.85-0.54l-0.61-0.84c-0.1-0.14-0.22-0.25-0.37-0.34l-1.08-0.65
                  c-0.29-0.18-0.65-0.23-0.99-0.13l-1.18,0.34c-0.34,0.1-0.62,0.33-0.77,0.65c-0.15,0.29-0.17,0.64-0.07,0.95
                  c-0.13,0.06-0.25,0.13-0.36,0.23l-0.78,0.7l-0.94,0.36c-0.22,0.09-0.41,0.24-0.56,0.42l-0.58,0.79l-0.71,0.1
                  c-0.2,0.03-0.39,0.11-0.56,0.23l-0.92,0.67l-0.33,0.21l-0.38-0.27c-0.49-0.36-1.15-0.31-1.59,0.1c-0.31-0.37-0.82-0.53-1.29-0.39
                  l-1.16,0.32l-0.98,0.11l-1.08-0.28c-0.03-0.01-0.07-0.02-0.1-0.02l-1.35-0.23c-0.39-0.07-0.78,0.05-1.06,0.32
                  c-0.29-0.24-0.67-0.35-1.03-0.28l-1.82,0.33c-0.32,0.06-0.61,0.24-0.8,0.5l-0.59,0.82l-0.94,0.4c-0.13,0.05-0.24,0.12-0.34,0.2
                  l-1.55,1.36l-0.69-0.14c-0.59-0.13-1.19,0.19-1.42,0.75l-0.48,1.17c-0.11,0.28-0.12,0.58-0.03,0.86c-0.02-0.01-0.03-0.01-0.05-0.01
                  l-0.96-0.21l-0.9-0.64c-0.08-0.06-0.18-0.11-0.27-0.15l-1.21-0.48c-0.15-0.06-0.31-0.09-0.46-0.09c-0.27,0-0.54,0.09-0.76,0.26
                  l-0.05-0.07c-0.24-0.37-0.66-0.58-1.11-0.56c-0.45,0.03-0.85,0.29-1.05,0.69l-0.55,1.1c-0.01,0.01-0.01,0.03-0.02,0.04
                  c-0.06-0.29-0.22-0.56-0.47-0.75l-0.98-0.74c-0.28-0.21-0.63-0.3-0.98-0.23c-0.35,0.07-0.65,0.27-0.84,0.57l-0.65,1.05
                  c-0.13,0.22-0.19,0.47-0.18,0.72l0.01,0.12c-0.23-0.02-0.5,0.06-0.72,0.2l-1.11,0.72c-0.12,0.08-0.23,0.18-0.32,0.29
                  c-0.25-0.2-0.57-0.29-0.9-0.25c-0.29,0.03-0.55,0.16-0.75,0.36l-0.17-0.84c-0.04-0.22-0.14-0.42-0.28-0.59l-0.83-0.94
                  c-0.31-0.34-0.78-0.49-1.23-0.39l-3.06,0.72l-1.2,0.14c-0.28,0.03-0.53,0.16-0.73,0.35l-0.91,0.9c-0.29,0.29-0.42,0.7-0.35,1.1
                  l0.2,1.2l0.05,1.21c0,0.11,0.02,0.22,0.06,0.32l0.3,0.96l-0.06,0.83l-0.41,0.95c-0.14,0.33-0.13,0.71,0.03,1.04l0.13,0.27
                  l-0.25,0.22c-0.33,0.3-0.48,0.76-0.38,1.19l0.21,1l-1.41,0.1l-0.23,0.01l-0.05-0.2c-0.11-0.49-0.5-0.86-0.98-0.95l-1.02-0.19
                  l-1.01-0.58c-0.23-0.14-0.5-0.19-0.77-0.16l-0.65,0.08l-0.48-0.47c-0.17-0.16-0.38-0.27-0.61-0.32l-1.28-0.27
                  c-0.2-0.04-0.4-0.04-0.6,0.02l-2.4,0.61c-0.04,0.01-0.08,0.02-0.12,0.04l-0.74,0.25l-0.71-0.29c-0.24-0.1-0.51-0.12-0.77-0.05
                  l-1.15,0.28l-1.21,0.09l-0.47-0.1l-0.32-0.6c-0.08-0.16-0.2-0.29-0.34-0.4l-1.07-0.82c-0.08-0.06-0.16-0.11-0.25-0.15l-1.25-0.56
                  l-1.35-0.73c-0.13-0.07-0.28-0.12-0.43-0.14l-1.13-0.14l-1.06-0.36c-0.16-0.05-0.33-0.07-0.5-0.05l-1.16,0.1l-1.13-0.03l-0.54-0.42
                  l-0.46-0.97c-0.11-0.22-0.28-0.41-0.5-0.54l-0.82-0.48l-0.15-0.51l0.26-0.91c0.04-0.12,0.05-0.25,0.05-0.38l-0.04-1.23
                  c-0.02-0.6-0.47-1.11-1.07-1.2l-1.21-0.18l-0.51-0.12l-0.09-0.24l0.3-0.65c0.05-0.12,0.09-0.25,0.1-0.38l0.14-1.25
                  c0.03-0.28-0.03-0.57-0.18-0.81l-0.67-1.06c-0.04-0.05-0.08-0.1-0.12-0.15l-1.11-1.27c-0.14-0.17-0.33-0.29-0.53-0.36l-1.11-0.38
                  l-0.95-0.55l-0.72-0.51l-0.44-0.98c-0.22-0.49-0.74-0.78-1.25-0.74l-1.23,0.12c-0.04,0-0.07,0-0.11,0.01l-1.26,0.22l-0.55,0.12
                  l0.04-0.64l0.3-1.17c0.06-0.25,0.05-0.51-0.04-0.75l-0.39-1.02l-0.13-1.06l0.17-2.55c0-0.11,0-0.22-0.02-0.33l-0.28-1.46
                  c-0.07-0.32-0.25-0.6-0.52-0.79l-0.75-0.52l-0.74-2.02l-0.72-2.44l-0.39-1.06l-0.12-1.08c-0.03-0.27-0.15-0.53-0.34-0.73l-1.62-1.72
                  l-1.08-2.05c-0.04-0.08-0.09-0.15-0.14-0.22l-0.78-0.96c-0.29-0.35-0.74-0.52-1.19-0.44l-0.32,0.06l-0.35-1.21
                  c-0.01-0.04-0.02-0.08-0.03-0.11l-0.19-0.52l0.35-0.45c0.41-0.51,0.34-1.26-0.14-1.7l-0.85-0.76l-0.66-0.84l-0.59-0.89l-0.22-0.61
                  l0.29-0.74c0.02-0.06,0.04-0.13,0.05-0.2l0.25-1.21c0.03-0.11,0.03-0.24,0.02-0.35l-0.5-6.35c-0.01-0.15-0.05-0.31-0.11-0.45
                  l-0.45-0.98l-0.21-1.09c-0.07-0.32-0.25-0.6-0.51-0.78l-0.55-0.39l-0.11-2.31c-0.01-0.19-0.06-0.38-0.15-0.55l-0.63-1.18
                  c-0.16-0.29-0.42-0.51-0.74-0.61c-0.32-0.09-0.67-0.06-0.96,0.1l-0.69,0.38l-0.51-0.09l-1.77-1.36c-0.28-0.21-0.65-0.3-1-0.22
                  l-1.22,0.25c-0.12,0.02-0.24,0.06-0.34,0.12l-1.14,0.61c-0.17,0.09-0.32,0.21-0.43,0.37l-0.83,1.16l-0.37,0.58l-1.72,0.06
                  l-0.52-0.16l-0.32-0.76c-0.15-0.34-0.43-0.59-0.78-0.7l-1.3-0.41c-0.32-0.1-0.67-0.07-0.97,0.09l-0.76,0.41l-0.76-0.18
                  c-0.2-0.04-0.4-0.04-0.59,0.01l-0.44,0.11l-0.46-1.25c-0.09-0.28-0.29-0.52-0.54-0.67l-0.99-0.59l-0.8-0.56l-0.52-0.83
                  c-0.06-0.11-0.15-0.21-0.25-0.3l-0.61-0.52l-0.12-1.05c-0.01-0.12-0.05-0.23-0.09-0.35l-0.48-1.1l-0.39-1.08l-0.19-1.11
                  c-0.04-0.24-0.15-0.46-0.32-0.64l-0.63-0.69l-0.07-0.72l0.12-0.35v-0.01l0.59-0.43c0.15-0.12,0.28-0.26,0.37-0.43l0.6-1.15
                  c0.19-0.37,0.19-0.8,0-1.16l-0.62-1.18c-0.05-0.08-0.1-0.16-0.15-0.23l-0.77-0.9l-0.55-0.87l-0.33-1.04l-0.16-0.76l0.69-0.87
                  l1.81-1.83c0.05-0.05,0.09-0.11,0.13-0.17l0.75-1.14l1.28-2.22l0.85-1.11c0.07-0.09,0.12-0.18,0.16-0.27l0.41-0.96l0.68-0.77
                  c0.36-0.4,0.42-0.98,0.16-1.45c-0.27-0.47-0.8-0.71-1.33-0.61l-0.75,0.15l-0.7-0.44c-0.12-0.08-0.25-0.13-0.39-0.16l-1.28-0.28
                  c-0.08-0.02-0.15-0.03-0.23-0.03l-0.98-0.03l-0.5-0.25l-0.19-0.45l0.23-0.44l0.52-0.48l1.25-0.21c0.33-0.05,0.63-0.24,0.82-0.52
                  l0.68-0.98l0.38-0.44l0.73,0.01c0.31,0.01,0.58-0.09,0.8-0.28l2.02-1.65l0.66-0.46l0.44-0.01l2.84,1.87
                  c0.52,0.33,1.2,0.24,1.61-0.21l1.65-1.9l0.9-0.74c0.25-0.2,0.41-0.5,0.44-0.83l0.13-1.24c0.04-0.32-0.05-0.64-0.25-0.89l-0.78-1.01
                  c-0.05-0.07-0.11-0.14-0.18-0.19l-1.8-1.53l-0.65-0.9c-0.05-0.07-0.1-0.13-0.16-0.18l-0.89-0.84l-0.67-0.84l-0.68-0.96l-0.54-1.04
                  c-0.09-0.18-0.22-0.33-0.37-0.44l-1-0.73c-0.1-0.07-0.21-0.13-0.32-0.17l-0.79-0.28l-1.81-2.85l-1.05-1.92l0.05-0.74l0.84-3.5
                  l0.53-0.92c0.07-0.13,0.12-0.27,0.14-0.41l0.41-2.4l0.4-1.1c0.02-0.04,0.03-0.09,0.04-0.13l0.3-1.21c0.03-0.09,0.04-0.19,0.04-0.28
                  l0.02-1.3c0-0.1-0.01-0.19-0.03-0.28l-0.26-1.21c-0.02-0.08-0.04-0.16-0.07-0.23l-0.5-1.14l-0.32-0.91l0.24-0.78
                  c0.06-0.18,0.07-0.38,0.04-0.57l-0.14-0.9l0.14-0.34l0.65-0.23c0.27-0.1,0.5-0.29,0.64-0.54l0.64-1.08
                  c0.15-0.25,0.21-0.54,0.16-0.83l-0.21-1.37c-0.02-0.16-0.07-0.31-0.16-0.45l-0.41-0.69l0.54-2.09c0.04-0.12,0.05-0.25,0.05-0.38
                  l-0.03-1.08l0.38-0.89c0.03-0.07,0.05-0.14,0.07-0.22l0.26-1.15l0.83-2.52c0.07-0.21,0.09-0.42,0.05-0.63l-0.23-1.21
                  c-0.03-0.17-0.1-0.34-0.2-0.48l-0.79-1.14l-0.5-1.02l-0.41-1.22l-0.47-2.24l0.26-0.88c0.08-0.29,0.06-0.6-0.06-0.87l-0.52-1.15
                  l-0.33-1.07l-0.09-0.45l0.38-0.32c0.13-0.11,0.23-0.24,0.31-0.38l0.57-1.1c0.03-0.07,0.06-0.13,0.08-0.2l0.42-1.31
                  c0.14-0.44,0.03-0.91-0.29-1.25l-0.71-0.73l-0.18-0.76l0.14-1.16c0.02-0.15,0.01-0.31-0.03-0.46l-0.28-1.1l-0.02-0.25l0.78,0.33
                  c0.23,0.1,0.48,0.12,0.72,0.08l1.44-0.27c0.04-0.01,0.08-0.02,0.11-0.03l1.23-0.35l0.75-0.05l0.71,0.46
                  c0.09,0.06,0.18,0.1,0.28,0.14l1.21,0.4l1.68,1.42l-0.01,0.68c-0.01,0.47,0.24,0.9,0.66,1.12l1.28,0.69
                  c0.32,0.18,0.71,0.2,1.05,0.06l0.78-0.31l1.74,0.54l0.75,0.68c0.03,0.03,0.07,0.06,0.1,0.09l1.13,0.82l1.08,0.7
                  c0.31,0.2,0.69,0.25,1.04,0.15l2.47-0.75c0.05-0.02,0.09-0.04,0.14-0.05l2.49-1.09l1.06-0.13c0.06-0.01,0.12-0.02,0.18-0.04
                  l1.02-0.28l1.23,0.02l1.11-0.06l0.63,0.09l0.4,0.52l0.27,1.03c0.05,0.16,0.13,0.32,0.24,0.46l0.78,0.96
                  c0.02,0.03,0.05,0.06,0.08,0.09l2.1,2.13c0.14,0.14,0.32,0.25,0.51,0.31l1.06,0.34l0.96,0.64c0.2,0.13,0.42,0.2,0.65,0.21l1.27,0.05
                  h0.05c0.12,0,0.25-0.02,0.37-0.06l1.25-0.39l0.7-0.26l0.67,0.31c0.23,0.11,0.49,0.14,0.75,0.1l1.11-0.2l1.22,0.05
                  c0.32,0.01,0.64-0.1,0.88-0.32l0.62-0.55l0.85-0.1c0.57-0.06,1.02-0.5,1.1-1.06c0.45,0.01,0.91-0.23,1.14-0.65l0.6-1.09
                  c0.09-0.16,0.15-0.34,0.16-0.53l0.06-1.06l0.37-0.53l0.62-0.35c0.02,0.19,0.09,0.38,0.2,0.54l0.7,1.05
                  c0.22,0.33,0.57,0.53,0.96,0.56l1.37,0.08h0.13l0.64-0.03c0.01,0.15,0.05,0.3,0.12,0.44l0.57,1.1c0.13,0.24,0.33,0.44,0.58,0.55
                  l0.91,0.42l0.4,0.43l0.1,2.55l-1.83,3.81c-0.11,0.22-0.14,0.48-0.11,0.72l0.14,0.92l-1.79,0.8l-0.99,0.5l-2.56,0.47l-3.12,0.33
                  c-0.09,0.01-0.18,0.03-0.26,0.06l-1.41,0.49c-0.42,0.14-0.73,0.5-0.81,0.93c-0.09,0.43,0.05,0.87,0.37,1.17l0.56,0.51l0.11,0.99
                  c0.02,0.22,0.11,0.44,0.25,0.62l0.64,0.83l0.51,1.47l0.46,1.17l0.23,0.89l-0.12,1c-0.04,0.35,0.06,0.7,0.3,0.97
                  c0.17,0.2,0.41,0.34,0.66,0.4l-0.15,1.13c-0.01,0.1-0.01,0.2,0,0.3l0.13,1.23c0.03,0.31,0.18,0.6,0.41,0.8l0.93,0.83
                  c0.19,0.18,0.44,0.29,0.7,0.31l0.76,0.08l0.47,0.6c0.21,0.26,0.52,0.43,0.86,0.46l1.07,0.11l0.86,0.31l0.69,0.55l0.43,0.62l0.1,0.81
                  l-0.07,1.2l0.01,1.39c0,0.16,0.03,0.33,0.1,0.48l0.52,1.22c0.09,0.2,0.23,0.38,0.41,0.51l0.63,0.47l0.19,0.86
                  c0.03,0.14,0.09,0.27,0.17,0.39l0.51,0.81l-0.11,1.06c-0.04,0.33,0.06,0.66,0.27,0.92l0.77,0.94l0.39,1.04
                  c0.04,0.11,0.09,0.22,0.16,0.31l0.69,0.94l1.23,5.55c0.11,0.46,0.47,0.83,0.93,0.94l1.78,0.42c0.27,0.06,0.53,0.03,0.77-0.06
                  l0.12,5.5c0,0.04,0.01,0.09,0.01,0.13l1.24,7.73c0.02,0.17,0.08,0.32,0.16,0.46l0.66,1.08c0.06,0.09,0.12,0.17,0.2,0.24l0.66,0.65
                  l0.18,0.58l-1.04-0.04c-0.33-0.02-0.67,0.12-0.91,0.35c-0.25,0.24-0.38,0.57-0.38,0.91l0.5,10.92c0.02,0.67,0.57,1.2,1.23,1.21
                  l0.36,0.01l-0.3,0.71c-0.08,0.19-0.11,0.4-0.09,0.61l0.91,8.83c0.02,0.28,0.14,0.54,0.33,0.74l1.17,1.24
                  c0.19,0.2,0.44,0.33,0.71,0.37l1.27,0.21c0.43,0.07,0.86-0.08,1.14-0.41l0.07-0.08l0.1,0.44c0.09,0.39,0.36,0.72,0.72,0.88
                  c0.05,0.02,0.1,0.03,0.14,0.05l0.01,0.03l-0.25,0.45l-0.8,0.57c-0.47,0.33-0.65,0.94-0.44,1.47l-0.28,0.24l-0.78,0.15l-1.2,0.05
                  c-0.44,0.02-0.84,0.28-1.05,0.67l-0.61,1.16l-0.53,1.16c-0.11,0.25-0.14,0.52-0.08,0.79l0.26,1.19c0.02,0.1,0.05,0.18,0.09,0.27
                  l0.52,1.12c0.02,0.04,0.04,0.08,0.06,0.12l1.42,2.32c0.03,0.04,0.06,0.08,0.09,0.12l0.77,0.98c0.04,0.06,0.1,0.12,0.15,0.17
                  l0.71,0.62l0.49,1.32l-0.29,0.29c-0.05,0.05-0.09,0.1-0.13,0.16l-0.72,1c-0.24,0.32-0.3,0.73-0.18,1.11l0.32,1v0.42l-0.51,0.37
                  c-0.23,0.16-0.39,0.39-0.47,0.65l-0.05,0.15c-0.3,0.06-0.56,0.23-0.75,0.48l-0.73,0.99c-0.12,0.17-0.21,0.38-0.23,0.6l-0.15,1.28
                  c-0.01,0.1-0.01,0.21,0,0.32l0.18,1.22c0.06,0.41,0.32,0.76,0.69,0.94s0.81,0.17,1.17-0.03l0.48-0.28l0.44,0.26
                  c0.03,0.02,0.06,0.04,0.09,0.05c0.08,0.3,0.26,0.57,0.54,0.75l0.93,0.59l0.55,0.8c0.23,0.34,0.62,0.55,1.03,0.55h0.01l1.31-0.01
                  c0.42,0,0.81-0.22,1.04-0.57s0.26-0.79,0.1-1.18l-0.28-0.63l0.27-0.62l0.71-0.18c0.61-0.16,1.01-0.76,0.92-1.39l-0.18-1.23
                  c-0.02-0.09-0.04-0.17-0.07-0.25l-0.18-0.49l0.33-0.37c0.08-0.1,0.15-0.21,0.21-0.33l0.45-1.04l0.71-0.93
                  c0.17-0.22,0.26-0.48,0.26-0.75c0.3-0.15,0.53-0.42,0.63-0.75c0.2,0.03,0.4,0.03,0.6-0.03l2.43-0.7c0.36-0.1,0.66-0.36,0.81-0.7
                  c0.1-0.23,0.13-0.48,0.09-0.73c0.09-0.03,0.18-0.08,0.26-0.14l1.03-0.7c0.1-0.06,0.18-0.14,0.26-0.23l0.79-0.94
                  c0.16-0.19,0.26-0.43,0.28-0.68c0.15-0.04,0.3-0.11,0.43-0.21l0.34-0.27l0.4,0.21c0.11,0.06,0.23,0.1,0.35,0.12l1.22,0.23
                  c0.45,0.09,0.92-0.09,1.21-0.45c0.25-0.32,0.33-0.73,0.23-1.11c0.03-0.01,0.05-0.02,0.08-0.04l1.1-0.59l-0.13,0.38
                  c-0.18,0.48-0.05,1.01,0.32,1.35c0.37,0.35,0.9,0.44,1.37,0.23l1.19-0.54c0.15-0.07,0.29-0.17,0.4-0.3l0.1,0.16
                  c0.08,0.15,0.19,0.27,0.32,0.37l0.98,0.75c0.29,0.22,0.65,0.3,1,0.24c0.35-0.07,0.66-0.29,0.84-0.6l0.57-0.97l0.4-0.45l3.54-0.09
                  c0.05,0,0.09-0.01,0.14-0.01l1.39-0.21l6.43-1.13c0.1-0.02,0.19-0.05,0.29-0.09l0.95-0.43l0.97,0.26c0.11,0.03,0.22,0.05,0.33,0.05
                  h1.32l1.08-0.07l0.96,0.17l1.1,0.45c0.57,0.23,1.24,0.01,1.56-0.54l0.64-1.12c0.1-0.18,0.15-0.38,0.16-0.59l1.35-0.35
                  c0.23-0.06,0.43-0.18,0.59-0.36l0.11-0.11l0.17,0.12c0.29,0.2,0.65,0.28,0.99,0.2c0.29-0.07,0.56-0.24,0.73-0.48
                  c0.41,0.27,0.94,0.28,1.36,0.02l0.85-0.54l0.11-0.01l0.55,1.43c0.08,0.23,0.22,0.42,0.41,0.57l0.99,0.78
                  c0.14,0.12,0.31,0.2,0.49,0.24l0.68,0.15l0.28,0.58c0.07,0.14,0.16,0.27,0.28,0.38l0.66,0.59l0.2,0.9c0.05,0.21,0.15,0.41,0.3,0.57
                  l0.96,1.04c0.09,0.09,0.18,0.17,0.29,0.23l1.38,0.81c0.15,0.09,0.33,0.15,0.5,0.16l1.11,0.12l0.49,0.39l0.69,1.19
                  c0.02,0.04,0.04,0.07,0.07,0.1l1.07,1.48c0.03,0.04,0.06,0.08,0.09,0.11l0.94,1.02l0.91,0.88l0.79,0.93
                  c0.05,0.06,0.11,0.12,0.18,0.17l1,0.79c0.28,0.22,0.64,0.31,0.99,0.25l2.75-0.53l1.1-0.28l0.82,0.07l0.73,0.38l0.86,0.88l0.44,0.98
                  l0.31,1.28l0.43,1.46c0.03,0.09,0.06,0.18,0.11,0.26l0.57,1.02l0.89,3.69c0.06,0.24,0.18,0.46,0.37,0.62l1.04,0.95
                  c0.04,0.03,0.07,0.06,0.11,0.09l-0.42,3.3c-0.01,0.03-0.01,0.07-0.01,0.1l-0.06,1.3c-0.01,0.19,0.03,0.38,0.1,0.56l0.37,0.84
                  l-0.38,0.86c-0.07,0.15-0.11,0.31-0.11,0.47l-0.04,1.34v0.11l0.15,2.76c0.01,0.23,0.09,0.45,0.22,0.64l0.61,0.89l0.24,1.22
                  l0.45,1.72c0.14,0.55,0.64,0.93,1.21,0.93c0,0,0.01,0,0.02,0l1.37-0.02l0.28,0.03L1382.9,480.22z"/>
                <title>Trebišov</title>
              </Link>

              {/* Kosice okolie - kosicky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Košice - okolie")}>
                <path style={{ fill: crimesInRegionKosicky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionKosicky[0].crimePercent)] ? crimesInRegionKosicky[0].color : "#D3D3D3" }}
                  d="M1231.94,394.7l-1.81-1.55c-0.08-0.06-0.14-0.14-0.2-0.22l-0.67-0.92l-0.88-0.82
                  c-0.04-0.04-0.08-0.09-0.12-0.13l-0.77-0.97l-0.77-1.09c-0.03-0.04-0.06-0.09-0.09-0.15l-0.45-0.85l-0.61-0.45l-1.04-0.37
                  c-0.27-0.1-0.51-0.29-0.66-0.54l-1.3-2.13l-0.71-1.06c-0.01-0.03-0.03-0.06-0.05-0.09l-1.24-2.26c-0.12-0.21-0.17-0.45-0.16-0.69
                  l0.08-1.23c0.01-0.08,0.02-0.16,0.04-0.23l0.9-3.75c0.03-0.12,0.08-0.23,0.13-0.33l0.5-0.88l0.4-2.29c0.01-0.08,0.03-0.15,0.05-0.22
                  l0.42-1.15l0.25-1l0.02-1.02l-0.21-0.95l-0.47-1.1l-0.5-1.37c-0.09-0.25-0.09-0.53-0.01-0.79l0.28-0.9l-0.16-0.97
                  c-0.03-0.23,0-0.47,0.09-0.68l0.49-1.17c0.13-0.32,0.4-0.58,0.72-0.7l0.74-0.26l0.19-0.31l-0.11-0.69l-0.54-0.91
                  c-0.17-0.27-0.22-0.6-0.15-0.92l0.31-1.36l0.32-1.15l-0.03-1.17c-0.01-0.19,0.03-0.37,0.1-0.53l0.45-1.04l0.24-1.09
                  c0.01-0.04,0.02-0.08,0.03-0.12l0.76-2.29l-0.12-0.63l-0.69-1c-0.03-0.05-0.06-0.1-0.09-0.15l-0.58-1.17
                  c-0.03-0.06-0.05-0.11-0.06-0.16l-0.46-1.37c-0.02-0.04-0.03-0.09-0.04-0.13l-0.56-2.66c-0.04-0.2-0.03-0.4,0.03-0.59l0.22-0.74
                  l-0.36-0.8c-0.02-0.04-0.04-0.09-0.05-0.14l-0.37-1.2c-0.02-0.04-0.03-0.08-0.04-0.12l-0.25-1.24c-0.09-0.45,0.08-0.91,0.42-1.21
                  l0.76-0.64l0.41-0.78l0.16-0.5l-0.42-0.44c-0.15-0.16-0.26-0.36-0.31-0.57l-0.32-1.31c-0.04-0.15-0.05-0.3-0.03-0.45l0.14-1.15
                  l-0.24-0.96c-0.02-0.06-0.03-0.12-0.03-0.18l-0.14-1.37c-0.03-0.27,0.03-0.54,0.17-0.77l0.22-0.37c-0.01-0.02-0.01-0.03-0.02-0.05
                  l-0.41-1.52l-0.42-0.92l-0.86-0.72c-0.11-0.1-0.21-0.22-0.28-0.35l-0.76-1.36l-0.78-1.15c-0.1-0.15-0.16-0.32-0.19-0.49l-0.22-1.29
                  c-0.05-0.28,0-0.57,0.14-0.82l0.54-0.97l0.24-0.8l-0.13-0.84l-0.43-1.03l-0.46-0.83l-0.8-0.72c-0.13-0.12-0.24-0.27-0.31-0.44
                  l-0.31-0.73l-0.39-0.19l-0.85,0.18c-0.33,0.07-0.68,0.01-0.96-0.18l-1.12-0.75c-0.04-0.03-0.09-0.07-0.13-0.1l-0.92-0.81
                  c-0.11-0.1-0.2-0.21-0.27-0.34l-0.56-1.01l-0.74-0.89c-0.07-0.08-0.12-0.17-0.17-0.26l-0.57-1.2c-0.04-0.1-0.08-0.2-0.1-0.3
                  l-0.25-1.28c-0.03-0.19-0.03-0.38,0.02-0.57l0.37-1.37l0.25-1.14c0.02-0.12,0.06-0.23,0.12-0.33l0.6-1.12
                  c0.02-0.04,0.04-0.08,0.07-0.12l0.36-0.53l-0.13-0.23l-0.6-0.33l-0.91,0.03c-0.15,0.01-0.3-0.02-0.45-0.07l-1.19-0.41
                  c-0.58-0.2-0.93-0.79-0.82-1.4l0.22-1.28l-0.31-2.35l-0.21-0.3l-0.38-0.04l-0.71,0.56c-0.21,0.16-0.47,0.25-0.74,0.26l-0.77,0.02
                  l0.04,0.07c0.16,0.3,0.19,0.65,0.09,0.98c-0.1,0.32-0.33,0.59-0.64,0.74l-3.49,1.77c-0.16,0.08-0.34,0.13-0.52,0.13l-1.26,0.03
                  c-0.21,0.01-0.44-0.05-0.63-0.15l-1.08-0.59c-0.07-0.04-0.14-0.09-0.2-0.14c-0.21,0.14-0.45,0.22-0.7,0.22l-1.31,0.01h-0.01
                  c-0.38,0-0.73-0.17-0.97-0.46l-0.83-1.02c-0.07-0.09-0.14-0.19-0.18-0.3l-0.45-1.05l-0.12,0.56c-0.06,0.29-0.23,0.54-0.46,0.72
                  l-1.03,0.79c-0.28,0.21-0.62,0.3-0.97,0.24c-0.34-0.06-0.64-0.25-0.84-0.54l-0.65-1.01l-0.61-0.67l-1-0.38l-1.44-0.65
                  c-0.18-0.08-0.33-0.2-0.46-0.36l-0.34-0.42l-0.58,0.14l-2.32,0.72l-0.07,0.11l0.19,0.43l0.6,0.97c0.03,0.04,0.05,0.08,0.07,0.13
                  l0.53,1.13c0.03,0.05,0.05,0.11,0.06,0.16l0.36,1.17c0.03,0.1,0.05,0.21,0.06,0.32l0.1,2.74l0.12,1.47
                  c0.01,0.15-0.01,0.29-0.05,0.43l-0.33,1.2c-0.02,0.1-0.06,0.19-0.11,0.28l-0.71,1.28c-0.03,0.06-0.07,0.12-0.11,0.17l-0.8,1
                  l-0.83,0.95l-0.5,0.89l-0.47,1.11c-0.08,0.2-0.22,0.38-0.39,0.51l-1.02,0.78c-0.16,0.13-0.35,0.21-0.55,0.24l-2.36,0.41
                  c-0.12,0.02-0.25,0.03-0.38,0.01l-1.45-0.2l-1.8-0.38c-0.08,0.3-0.26,0.56-0.52,0.73l-1.15,0.77c-0.14,0.09-0.3,0.16-0.47,0.19
                  l-1.29,0.23c-0.21,0.04-0.42,0.02-0.63-0.05l-1.43-0.49c-0.09-0.03-0.18-0.07-0.26-0.13l-0.55-0.35l-0.49,0.25l-0.98,0.75
                  l-0.64,0.57c0.51,0.32,0.73,0.97,0.49,1.54l-0.17,0.41l0.6,0.63c0.2,0.22,0.32,0.49,0.34,0.78c0.07,0.07,0.13,0.14,0.18,0.22
                  c0.24,0.36,0.28,0.81,0.1,1.2l-0.51,1.14c-0.26,0.58-0.93,0.88-1.53,0.68l-0.86-0.28l-1.29,0.3l-1.24,0.34l-0.95,0.41l-0.89,0.67
                  c-0.27,0.2-0.6,0.28-0.93,0.24l-1.97-0.28c-0.24-0.04-0.46-0.14-0.64-0.3l-1.05-0.92c-0.22-0.19-0.35-0.43-0.41-0.69l-1.06-0.04
                  c-0.2-0.01-0.4-0.06-0.58-0.16l-1.22-0.69c-0.15-0.09-0.28-0.2-0.38-0.34l-0.74-0.99l-1.1-0.83c-0.08-0.06-0.15-0.13-0.21-0.2
                  l-0.8-0.96l-0.8-0.88c-0.36-0.39-0.43-0.97-0.18-1.44l0.44-0.8l-0.07-0.62l-0.53-0.76c-0.12-0.18-0.2-0.39-0.22-0.62l-0.11-1.45
                  v-0.14l0.07-1.58l-0.06-1.35v-0.13l0.08-1.24c0.04-0.61,0.52-1.11,1.13-1.16l0.98-0.1l0.73-0.46l0.71-0.79
                  c0.09-0.09,0.19-0.18,0.3-0.24l0.75-0.43l0.17-0.79c0.02-0.08,0.05-0.17,0.08-0.25l0.34-0.74l-0.22-0.67l-0.48-0.88l-0.46,0.93
                  c-0.29,0.57-0.96,0.83-1.55,0.61l-2.27-0.82l-1.49-0.08c-0.59-0.03-1.07-0.47-1.17-1.06l-0.19-1.24l-0.14-0.48
                  c-0.01,0.07-0.02,0.15-0.04,0.22l-0.34,1.34c-0.1,0.4-0.4,0.73-0.8,0.87c-0.4,0.14-0.84,0.07-1.17-0.19l-5.84-4.48l-0.17,0.09
                  l-1.51,2.15l-0.82,1.05l-0.94,1.06c-0.46,0.51-1.25,0.55-1.76,0.09l-0.92-0.81l-1.01-0.66c-0.13-0.09-0.24-0.19-0.33-0.32
                  l-0.76-1.04l-0.84-0.82c-0.09-0.09-0.16-0.19-0.22-0.3l-0.62-1.16c-0.05-0.09-0.09-0.18-0.11-0.27l-0.34-1.3
                  c-0.02-0.09-0.04-0.17-0.04-0.26l-0.03-0.7l-0.54-0.43c-0.12-0.1-0.22-0.21-0.3-0.35l-0.46-0.77l-0.62-0.35l-1.05-0.2
                  c-0.1-0.02-0.2-0.06-0.3-0.1l-1.15-0.55l-1.28-0.67c-0.04-0.02-0.08-0.05-0.11-0.07l-0.63-0.42l-0.8,0.2l-1.13,0.19l-0.67,0.38
                  l-0.25,0.77c-0.1,0.32-0.33,0.59-0.63,0.74s-0.65,0.17-0.97,0.06l-1.28-0.44l-0.88-0.21l-0.85,0.21l-1.11,0.62
                  c-0.39,0.22-0.85,0.21-1.23,0l-1.07-0.61c-0.08-0.05-0.16-0.11-0.23-0.17l-0.64-0.59l-0.56-0.07l-0.85,0.33
                  c-0.13,0.04-0.26,0.07-0.39,0.08l-1.25,0.06c-0.29,0.01-0.57-0.07-0.8-0.24l-0.99-0.73c-0.04-0.03-0.07-0.05-0.1-0.08l-0.91-0.82
                  l-0.72-0.72l-0.22-0.07l0.33,0.96l1.07,2.32c0.03,0.06,0.06,0.13,0.08,0.21l0.34,1.27c0.01,0.03,0.02,0.07,0.02,0.11l0.23,1.33
                  c0.02,0.11,0.02,0.22,0.01,0.33l-0.1,1.1l0.18,1.05c0.04,0.24,0.01,0.5-0.09,0.73l-0.53,1.16c-0.16,0.35-0.48,0.61-0.86,0.7
                  c-0.37,0.09-0.77-0.01-1.07-0.25l-0.97-0.79c-0.09-0.08-0.17-0.16-0.24-0.26l-0.26-0.39c-0.12,0.11-0.26,0.2-0.4,0.25l-1.18,0.46
                  l-0.4,0.26l0.11,1.78c0.02,0.3-0.07,0.59-0.25,0.83l-0.41,0.56l0.04,0.2l0.49,0.33l1.15,0.27c0.06,0.02,0.11,0.04,0.16,0.06
                  l1.21,0.47l1.46,0.51c0.49,0.17,0.83,0.63,0.84,1.15l0.03,1.28l-0.04,1l0.23-0.14c0.31-0.17,0.69-0.21,1.02-0.09
                  c0.34,0.11,0.61,0.36,0.75,0.69l0.52,1.23c0.08,0.19,0.11,0.4,0.1,0.6l-0.13,1.33l0.38,0.93c0.03,0.08,0.06,0.17,0.07,0.26
                  l0.21,1.21c0.09,0.53-0.16,1.06-0.64,1.31l-1.14,0.61c-0.05,0.03-0.11,0.05-0.16,0.08l-1.16,0.42c-0.11,0.03-0.22,0.06-0.34,0.07
                  l-0.55,0.04l0.46,0.75c0.06,0.1,0.1,0.2,0.13,0.31l0.41,1.43c0.02,0.06,0.03,0.13,0.04,0.2l0.14,1.22c0.04,0.32-0.05,0.64-0.25,0.9
                  l-0.76,0.99c-0.15,0.19-0.35,0.34-0.58,0.42l-1.18,0.41c-0.11,0.04-0.22,0.06-0.33,0.07l-0.73,0.05l0.1,1.09
                  c0.02,0.28-0.05,0.56-0.2,0.79l-0.68,1.04c-0.18,0.28-0.46,0.47-0.77,0.54l-1,0.23l-0.7,0.63c-0.2,0.19-0.46,0.3-0.74,0.33
                  l-0.97,0.07c0,0.01,0,0.01,0,0.01l0.06,1.29l-0.04,1.3l0.11,1.14c0.03,0.26-0.03,0.52-0.16,0.74l-0.91,1.65l0.93,1.81
                  c0.05,0.11,0.09,0.23,0.11,0.35l0.21,1.22c0.09,0.51-0.15,1.02-0.59,1.29l-1.15,0.68l-1.07,0.72c-0.09,0.06-0.18,0.11-0.28,0.15
                  l-1.24,0.44l-2.59,0.77c-0.14,0.04-0.29,0.06-0.44,0.05l-1.4-0.11c-0.19-0.02-0.38-0.08-0.54-0.18l-0.98-0.58l-1.06-0.27
                  c-0.07-0.01-0.13-0.04-0.2-0.06l-2.34-1l-2.23-0.83l-1.03-0.11l-2.55,0.62l-1.1,0.34c-0.04,0.02-0.08,0.03-0.12,0.03l-0.86,0.18
                  l-0.71,0.95l-0.86,1.01c-0.03,0.03-0.06,0.07-0.09,0.1l-1.09,1.03c-0.16,0.15-0.35,0.25-0.57,0.3l-1.43,0.35
                  c-0.04,0.01-0.07,0.02-0.11,0.03l-1.17,0.18l-1.35,0.67c-0.33,0.16-0.71,0.17-1.04,0.03l-1.27-0.52l-1.28-0.41l-1.57-0.42
                  c-0.18-0.05-0.35-0.15-0.5-0.27l-0.79-0.71l-0.82,0.23c-0.09,0.02-0.18,0.03-0.27,0.04l-1.31,0.06h-0.15l-1.39-0.1
                  c-0.05-0.01-0.11-0.01-0.16-0.02l-1.23-0.25l-1.53-0.44c-0.13-0.03-0.24-0.09-0.35-0.16l-1.13-0.75c-0.06-0.04-0.12-0.09-0.18-0.14
                  l-1.06-1.02c-0.02-0.03-0.05-0.05-0.07-0.08l-0.47-0.53l-0.14,0.09l-0.89,0.95l-0.74,0.93c-0.06,0.09-0.14,0.16-0.23,0.23
                  l-0.74,0.56l-0.44,1.09l-0.05,1.1l0.41,1.47c0.06,0.19,0.06,0.4,0.02,0.59l-0.26,1.27c-0.01,0.08-0.04,0.16-0.07,0.24l-0.49,1.16
                  c-0.01,0.03-0.03,0.07-0.05,0.11l-0.59,1.09c-0.09,0.15-0.2,0.29-0.34,0.39l-0.76,0.58l-0.43,1.01c-0.19,0.44-0.61,0.73-1.08,0.76
                  l-0.61,0.03l-0.3,0.51c-0.12,0.22-0.3,0.39-0.53,0.5l-1.13,0.56c-0.11,0.06-0.23,0.09-0.36,0.11l-1.18,0.19l-1.16,0.34
                  c-0.04,0.01-0.07,0.02-0.11,0.03l-1.17,0.23l-0.74,0.5l-0.91,0.82c-0.04,0.03-0.07,0.05-0.11,0.08l-1.13,0.8l-1.2,0.72
                  c-0.12,0.08-0.26,0.13-0.4,0.16l-5.08,1.02l-0.78,0.31l-0.41,0.74l-0.7,1.12c-0.16,0.25-0.4,0.43-0.69,0.52l-1.21,0.38l-0.68,0.27
                  l-0.23,0.47l0.12,0.88c0.01,0.07,0.01,0.13,0.01,0.2l-0.03,1.28l0.02,1.09l0.41,1.18c0.07,0.2,0.09,0.42,0.05,0.63l-0.22,1.23
                  c-0.03,0.14-0.08,0.28-0.15,0.41l-0.56,0.96l-0.24,1.04c-0.05,0.22-0.16,0.43-0.32,0.59l-0.83,0.85l-0.38,0.54l0.07,0.53l0.5,0.97
                  c0.07,0.13,0.12,0.29,0.13,0.44l0.13,1.25c0.02,0.12,0.01,0.25-0.01,0.37l-0.24,1.24c-0.05,0.23-0.16,0.45-0.33,0.62l-0.08,0.09
                  l1.02,0.27c0.05,0.02,0.11,0.04,0.16,0.06l1.18,0.5c0.03,0.01,0.06,0.03,0.09,0.04l1.72,0.92l0.65-0.25
                  c0.19-0.08,0.41-0.11,0.61-0.08l1.51,0.2c0.06,0.01,0.12,0.02,0.18,0.04l1.08,0.3l0.99,0.05l1.06-0.21
                  c0.38-0.07,0.77,0.03,1.07,0.29l0.69,0.61l0.72-0.06c0.21-0.03,0.42,0.01,0.61,0.09l1.22,0.52c0.28,0.12,0.5,0.33,0.64,0.61
                  l0.54,1.12c0.05,0.11,0.09,0.23,0.11,0.36l0.15,1.07l0.38,0.93l0.6,0.87l0.85,0.9c0.18,0.19,0.29,0.44,0.33,0.69l0.16,1.18
                  l0.21,0.76l0.58,0.52l1.02,0.68l1.01,0.61l1.13,0.8c0.17,0.12,0.3,0.29,0.39,0.47l0.56,1.15c0.05,0.1,0.09,0.21,0.11,0.31l0.24,1.25
                  c0.01,0.06,0.02,0.12,0.02,0.18l0.16,2.46c0.03,0.46-0.19,0.9-0.58,1.15c-0.38,0.25-0.88,0.27-1.28,0.04l-1.27-0.71l-1.02-0.37
                  l-2.55-0.12c-0.11-0.01-0.22-0.03-0.33-0.06l-0.97-0.33l-0.67,0.1l0.34,0.9l0.75,0.69l0.09,0.09l0.88,1
                  c0.07,0.08,0.13,0.17,0.18,0.27l0.64,1.28c0.04,0.08,0.07,0.17,0.09,0.26l0.32,1.3c0.04,0.16,0.05,0.32,0.03,0.47l-0.25,1.78
                  c-0.09,0.65-0.68,1.12-1.33,1.08l-1.3-0.09c-0.1-0.01-0.2-0.03-0.3-0.06l-1.14-0.37l-0.35-0.01l-0.05,0.11l0.03,0.94l-0.06,2.55
                  l0.39,4.99l0.81-0.17l2.3-0.67l1.19-0.55c0.06-0.03,0.13-0.05,0.2-0.07l1.1-0.29l1.25-0.51l2.47-0.82c0.5-0.17,1.06,0.01,1.38,0.43
                  l0.81,1.05c0.04,0.05,0.08,0.11,0.11,0.17l0.9-0.84c0.39-0.36,0.97-0.43,1.43-0.18c0.47,0.24,0.73,0.76,0.65,1.29l-0.24,1.61
                  l-0.04,2.65c0.46,0,0.88,0.26,1.09,0.66l0.62,1.18c0.02,0.03,0.04,0.06,0.05,0.1l0.55,1.3l0.79,0.87c0.07,0.09,0.13,0.18,0.18,0.28
                  l0.49,0.97l0.21,0.22l0.28-0.15c0.42-0.22,0.93-0.18,1.31,0.1l0.65,0.46l0.66-0.03l8.88-3.2c0.22-0.08,0.45-0.09,0.68-0.04l1.2,0.26
                  c0.29,0.06,0.54,0.22,0.72,0.45l0.68,0.87l1.09,0.92c0.22,0.18,0.37,0.44,0.42,0.73l0.48,2.46l0.36,1.03l0.34,0.63l0.32-0.24
                  c0.39-0.29,0.92-0.33,1.35-0.1c0.43,0.24,0.68,0.7,0.65,1.19l-0.08,1.03l0.1,0.31l0.23-0.02c0.18-0.02,0.36,0,0.53,0.06l1.53,0.54
                  c0.34,0.12,0.61,0.38,0.75,0.71l0.55,1.35c0.06,0.15,0.09,0.31,0.09,0.47l0.05,2.39l0.42,0.42l0.51,0.07l1.34-0.76
                  c0.12-0.06,0.25-0.11,0.38-0.14l1.29-0.25c0.53-0.1,1.07,0.15,1.33,0.63l0.62,1.06l0.07-0.05l0.52-0.89l0.49-1.32
                  c0.16-0.43,0.55-0.74,1.01-0.8l1.23-0.16c0.06-0.01,0.12-0.01,0.18-0.01l1.1,0.02l0.9-0.3l1.06-0.7l1.17-0.69
                  c0.36-0.21,0.82-0.23,1.2-0.03l0.91,0.47l0.92-0.03l1.39,0.02c0.39,0.01,0.76,0.2,1,0.52l0.44,0.63l0.9,0.13
                  c0.14,0.03,0.28,0.07,0.41,0.14l0.82,0.45l1.04,0.01c0.08,0,0.17,0.01,0.25,0.03l1.27,0.27c0.17,0.03,0.33,0.11,0.47,0.21l0.49,0.36
                  l1.83-0.79c0.46-0.2,0.99-0.1,1.35,0.25l1.36,1.3l0.75,0.64l0.88,0.35l1.34,0.25c0.08,0.02,0.16,0.04,0.23,0.07l1.3,0.51
                  c0.19,0.07,0.36,0.19,0.5,0.35l0.9,1.05l0.84,1.09c0.12,0.15,0.2,0.33,0.23,0.52l0.25,1.26c0.02,0.06,0.03,0.13,0.03,0.2l0.04,1.27
                  c0,0.21-0.04,0.41-0.13,0.6l-0.47,0.93l-0.08,0.51l1.02,1.29l1.11,0.47l1.43,0.4l1.14,0.11l2.11-0.3l0.62-0.48l1-2.37
                  c0.02-0.05,0.05-0.1,0.07-0.14l0.78-1.35l0.47-1.09c0.08-0.18,0.2-0.34,0.36-0.47l0.8-0.65l0.5-0.93c0.02-0.03,0.04-0.07,0.07-0.11
                  l0.74-1.08c0.17-0.25,0.43-0.43,0.73-0.5l0.93-0.23l0.5-0.43l0.29-0.84c0.18-0.56,0.74-0.9,1.32-0.84l1.03,0.11l1.03-0.25
                  c0.28-0.07,0.58-0.04,0.84,0.08l1.15,0.55c0.12,0.06,0.23,0.14,0.32,0.23l0.26,0.24l0.13-0.08l0.74-0.79
                  c0.17-0.18,0.39-0.3,0.63-0.36l0.54-0.12l0.13-0.58c0.08-0.4,0.35-0.73,0.72-0.89c0.38-0.16,0.8-0.13,1.15,0.08l0.64,0.4l0.69-0.13
                  l0.8-0.4l0.23-0.69c0.05-0.14,0.13-0.28,0.22-0.39l0.8-0.98c0.25-0.3,0.6-0.47,1.02-0.46l0.53,0.02l0.27-0.5
                  c0.15-0.28,0.4-0.5,0.71-0.6l1.22-0.4c0.28-0.09,0.59-0.08,0.87,0.04l0.65,0.27l0.4-0.2l0.64-0.77c0.3-0.36,0.78-0.52,1.25-0.42
                  l1.23,0.29c0.18,0.05,0.35,0.13,0.5,0.25l1.06,0.87l0.12,0.12l0.92,0.98l1.07,0.74l2.22,1.23c0.06,0.04,0.12,0.08,0.18,0.13
                  l0.49,0.39l0.54-0.21c0.27-0.11,0.57-0.12,0.86-0.02l2.64,0.89l1.38,0.24c0.09,0.02,0.18,0.04,0.26,0.08l0.96,0.4l1.35,0.07
                  c0-0.01,0.01-0.02,0.01-0.03l0.49-1.12c0.09-0.2,0.22-0.37,0.39-0.5l0.99-0.75c0.09-0.06,0.17-0.11,0.27-0.15l1.01-0.44l-0.66-0.41
                  c-0.3-0.2-0.51-0.51-0.57-0.86c-0.05-0.36,0.05-0.72,0.28-1l0.79-0.95c0.08-0.1,0.19-0.19,0.31-0.27l0.73-0.44l0.49-1.05l-1.13-0.88
                  c-0.31-0.23-0.49-0.6-0.49-0.99c0-0.38,0.17-0.75,0.48-0.99l0.29-0.23c-0.16-0.22-0.24-0.47-0.24-0.73c0-0.15,0.03-0.3,0.08-0.44
                  l0.43-1.16c0.05-0.16,0.14-0.29,0.25-0.41l0.87-0.95c0.28-0.31,0.69-0.46,1.11-0.4l1.24,0.19c0.37,0.06,0.69,0.28,0.88,0.6
                  l2.13,0.16l0.92-0.34c0.09-0.03,0.18-0.06,0.28-0.07l1.42-0.18c0.06-0.01,0.12-0.01,0.18-0.01l1.37,0.02l1.49-0.02l1.55,0.05
                  c0.14,0.01,0.28,0.03,0.42,0.09l1.02,0.4l1.03-0.04c0.16-0.01,0.32,0.02,0.47,0.07l0.53,0.19l0.44-0.34
                  c0.09-0.06,0.18-0.12,0.28-0.16l2.3-1.02c0.08-0.04,0.16-0.07,0.25-0.09l1.22-0.28c0.1-0.02,0.2-0.04,0.32-0.03l1.36,0.04
                  c0.32,0.01,0.62,0.14,0.85,0.37l0.82,0.83l0.71,0.5l0.37,0.03l0.52-0.45l0.47-0.51l-0.05-0.72l-0.03-2.94
                  c0-0.08,0.01-0.17,0.02-0.25l0.25-1.27c0.04-0.21,0.13-0.39,0.26-0.56l0.7-0.83l0.43-0.98c0.14-0.32,0.41-0.56,0.75-0.68l1.29-0.43
                  l1.3-0.32c0.07-0.02,0.15-0.03,0.23-0.04l1.25-0.07c0.49-0.01,0.94,0.23,1.17,0.66l0.54,1.02l0.73,0.8
                  c0.06,0.07,0.11,0.14,0.15,0.22l0.53,0.92l0.41,0.22l0.83-0.12c0.3-0.04,0.62,0.03,0.87,0.2l1.09,0.73c0.07,0.05,0.14,0.1,0.2,0.17
                  l0.93,0.95l0.92,1.08c0.03,0.04,0.06,0.07,0.08,0.1l0.52,0.76l0.7,0.34l1.15,0.21c0.29,0.05,0.56,0.21,0.74,0.44l1.18,1.43
                  l0.35-0.09l1.1-0.5c0.32-0.14,0.68-0.15,1-0.01c0.32,0.13,0.57,0.39,0.69,0.72l0.37,0.99l0.25,0.31l0.54-0.07
                  c0.25-0.03,0.49,0.01,0.71,0.13l-0.12-0.39c-0.01-0.04-0.03-0.08-0.03-0.12l-0.29-1.39c-0.08-0.37,0.01-0.74,0.24-1.03l1.1-1.4
                  c0.03-0.05,0.07-0.09,0.11-0.13l0.88-0.85l0.84-0.88l0.64-0.97l0.56-1.02l0.75-1.25c0.02-0.03,0.05-0.07,0.08-0.11l0.79-1.04
                  l0.15-0.36l-0.58-0.37l-0.95-0.2l-1.15-0.04c-0.18-0.01-0.37-0.05-0.53-0.14l-1.15-0.59c-0.26-0.13-0.47-0.35-0.58-0.62l-0.6-1.41
                  c-0.15-0.35-0.13-0.74,0.05-1.07l0.6-1.16c0.07-0.12,0.16-0.23,0.26-0.33l0.95-0.88c0.18-0.16,0.4-0.27,0.64-0.31l1.09-0.19
                  l0.42-0.6c0.03-0.04,0.06-0.08,0.09-0.11l0.81-0.93c0.24-0.28,0.59-0.43,0.96-0.43l0.86,0.01l1.67-1.37
                  c0.02-0.02,0.05-0.04,0.08-0.06l1.02-0.72c0.21-0.15,0.46-0.23,0.71-0.23l1.23-0.01c0.25-0.01,0.52,0.08,0.74,0.23l1.01,0.72
                  l1.17,0.74l0.97-1.13c0.04-0.05,0.1-0.11,0.15-0.15l0.59-0.49l0.02-0.23L1231.94,394.7z M1177.21,386.54
                  c-0.47,0.2-1.02,0.1-1.38-0.26l-0.61-0.6l-0.78,0.11c-0.08,0.01-0.16,0.01-0.24,0.01l-1.23-0.06c-0.24-0.01-0.47-0.09-0.66-0.23
                  l-0.95-0.67l-0.95-0.08c-0.25-0.03-0.49-0.12-0.69-0.29l-0.94-0.77l-2.03-0.9c-0.07-0.03-0.14-0.06-0.2-0.1l-0.25-0.17l-0.23,0.21
                  c-0.21,0.18-0.48,0.28-0.75,0.3l-3.36,0.18l-1.04,0.29l-1.8,0.89c-0.57,0.29-1.28,0.08-1.62-0.47l-0.23-0.37l-0.52,0.07l-2.62,1.68
                  c-0.3,0.19-0.67,0.25-1.01,0.15l-0.99-0.27l-2.9,0.2h-0.15l-1.23-0.07c-0.09-0.01-0.18-0.02-0.27-0.05l-1.3-0.37
                  c-0.15-0.04-0.3-0.11-0.42-0.21l-0.89-0.68l-0.61-0.24l-0.59,0.16l-1.28,0.96l-1.03,0.7l0.34,1.17c0.1,0.33,0.05,0.69-0.13,0.99
                  c-0.17,0.3-0.47,0.51-0.81,0.58l-0.35,0.08l3.43,4.39c0.23,0.31,0.31,0.71,0.2,1.08l-0.31,1.16l0.16,1.05
                  c0.02,0.19,0.01,0.38-0.05,0.56l-0.35,1.14l-0.06,0.7l0.48,0.72c0.1,0.15,0.17,0.33,0.2,0.52l0.15,1.11l0.45,1.33
                  c0.02,0.05,0.04,0.11,0.05,0.17l0.49,2.58l0.7,1.07c0.1,0.16,0.17,0.34,0.19,0.53l0.14,1.19l0.35,1.48l0.46,1.06
                  c0.25,0.59,0.02,1.27-0.54,1.58l-5.15,2.87c-0.19,0.11-0.4,0.16-0.61,0.16c-0.17,0-0.34-0.04-0.5-0.11l-9.73-4.22
                  c-0.2-0.09-0.38-0.23-0.51-0.41l-1.2-1.65l-1.11-1.64c-0.34-0.51-0.26-1.19,0.19-1.61l0.29-0.27l-0.16-0.4l-0.95-1.12
                  c-0.2-0.23-0.3-0.52-0.3-0.82l0.01-1.42c0-0.09,0.01-0.17,0.03-0.26l0.17-0.78l-0.43-0.72c-0.13-0.22-0.18-0.48-0.17-0.73l0.11-1.48
                  c0.01-0.05,0.01-0.1,0.02-0.15l0.15-0.73l-0.43-0.34c-0.07-0.06-0.14-0.12-0.19-0.2l-0.45-0.56l-3.97-0.42l-1.49,0.03l-5.08,0.38
                  c-0.65,0.04-1.24-0.43-1.33-1.08l-0.17-1.24c-0.02-0.12-0.01-0.25,0.01-0.37l0.24-1.46c0.02-0.15,0.07-0.3,0.15-0.43l0.64-1.09
                  l0.71-1.04l0.37-1.13l-0.03-1.16l-0.17-1.05l-0.8-2.25c-0.05-0.13-0.08-0.27-0.08-0.41l-0.03-1.31l-0.3-1.05
                  c-0.15-0.52,0.05-1.07,0.5-1.37l2.46-1.69l4.02-2.88c0.38-0.28,0.89-0.32,1.31-0.1s0.68,0.66,0.67,1.14l-0.03,1.38
                  c0,0.04,0,0.09-0.01,0.14l-0.12,0.88l0.17,0.33l1.87,0.58l1.45,0.21l1.11-0.09h0.11l0.64,0.01l0.79-1.18l-0.1-0.85
                  c-0.02-0.13-0.01-0.26,0.02-0.39l0.47-2.25l-0.45-2.52c-0.02-0.13-0.03-0.25-0.01-0.38l0.13-1.08l-0.14-1.08
                  c-0.01-0.11-0.01-0.21,0-0.32l0.28-2.36l-0.2-1.01l-0.36-1.08c-0.21-0.61,0.08-1.28,0.67-1.54l1.21-0.54
                  c0.17-0.08,0.37-0.12,0.54-0.11l1.3,0.03c0.12,0,0.24,0.02,0.36,0.06l0.12,0.04l-0.4-1l-0.69-0.71c-0.09-0.1-0.16-0.2-0.22-0.32
                  l-0.54-1.09l-0.64-0.97c-0.17-0.26-0.24-0.57-0.2-0.87l0.1-0.68l-0.42-0.46c-0.08-0.08-0.14-0.16-0.19-0.26l-0.49-0.9l-0.47-0.46
                  l-0.89-0.16c-0.31-0.06-0.59-0.24-0.78-0.5l-0.73-1.01c-0.23-0.31-0.3-0.72-0.19-1.09l0.29-0.96c-0.27-0.07-0.51-0.23-0.68-0.45
                  l-0.79-1.04c-0.07-0.09-0.12-0.18-0.16-0.27l-0.45-1.06l-1.67-2l-0.69-0.94l-3.11-2.71c-0.15-0.14-0.26-0.31-0.33-0.51l-0.06-0.14
                  l-0.08,0.02c-0.29,0.07-0.58,0.05-0.85-0.08l-1.14-0.53c-0.11-0.05-0.21-0.12-0.31-0.2l-0.9-0.82l-0.98-0.61
                  c-0.36-0.23-0.58-0.62-0.59-1.04l-0.02-1.31c-0.01-0.43,0.21-0.84,0.58-1.08l0.33-0.2l-0.08-0.43l-0.38-1.39
                  c-0.03-0.1-0.05-0.2-0.05-0.31l-0.02-1.17l-0.19-1.15c-0.05-0.27-0.01-0.54,0.12-0.78l0.56-1.1c0.12-0.24,0.33-0.44,0.58-0.56
                  l1.16-0.55c0.21-0.1,0.44-0.14,0.68-0.11l1.65,0.21l0.23-0.72c0.04-0.12,0.1-0.24,0.18-0.34l0.25-0.35l-0.22-0.38l-1.08-2.24
                  l-0.74-0.85c-0.12-0.13-0.2-0.28-0.25-0.45l-0.42-1.34l-0.41-1.1c-0.06-0.14-0.08-0.3-0.08-0.46l0.03-1.27
                  c0.01-0.26,0.1-0.52,0.26-0.73l0.66-0.87l0.4-1.01c0.06-0.17,0.16-0.33,0.3-0.45l0.93-0.89c0.06-0.06,0.13-0.12,0.21-0.16l1.2-0.75
                  l0.69-0.89c0.1-0.12,0.22-0.23,0.36-0.31l1.11-0.65c0.32-0.19,0.71-0.22,1.06-0.09l1.31,0.48c0.08,0.03,0.16,0.06,0.23,0.11
                  l1.06,0.66c0.19,0.12,0.35,0.29,0.45,0.49l0.59,1.15c0.04,0.08,0.08,0.16,0.1,0.25l0.22,0.83l0.69,0.61l0.96,0.95
                  c0.26,0.25,0.4,0.59,0.38,0.94l-0.04,0.85l0.46,0.45l1.11,0.81c0.04,0.03,0.08,0.06,0.11,0.09l0.97,0.9l0.85,0.49l0.8,0.25
                  l0.94-0.16c0.06-0.01,0.12-0.02,0.18-0.02l1.23-0.03c0.38-0.03,0.68,0.13,0.92,0.37l0.94,0.95c0.17,0.17,0.28,0.38,0.33,0.62
                  l0.14,0.65l0.6,0.28c0.12,0.06,0.23,0.14,0.33,0.23l0.96,0.92c0.1,0.1,0.19,0.22,0.26,0.36l0.35,0.73l0.73,0.4l1.08,0.4
                  c0.3,0.11,0.54,0.32,0.68,0.6l0.6,1.16c0.2,0.41,0.17,0.89-0.08,1.27c-0.06,0.08-0.13,0.16-0.21,0.23h0.15
                  c0.11,0,0.21,0.01,0.31,0.03l0.57,0.14c0.03-0.31,0.17-0.61,0.41-0.82l0.91-0.83c0.23-0.21,0.53-0.33,0.84-0.33h0.98l1.1-1.07
                  c0.11-0.1,0.24-0.19,0.38-0.25l1.14-0.49c0.07-0.03,0.14-0.05,0.21-0.07l0.55-0.12l0.17-0.52l-0.12-1.04
                  c-0.05-0.43,0.13-0.87,0.48-1.13l0.57-0.44l0.04-0.74c0.02-0.22,0.09-0.44,0.22-0.63l0.75-1.08c0.31-0.45,0.87-0.65,1.4-0.49
                  c0.52,0.16,0.87,0.64,0.88,1.18l0.04,1.45l0.45-0.31l1.1-0.68c0.37-0.23,0.82-0.25,1.2-0.07l1.19,0.57c0.04,0.02,0.07,0.04,0.1,0.06
                  l1.34,0.8c0.23,0.13,0.41,0.34,0.51,0.58l0.71,1.66c0.04,0.09,0.07,0.19,0.09,0.29l0.16,1.04l0.59,1.02
                  c0.02,0.03,0.03,0.07,0.05,0.11l0.59,1.32c0.06,0.12,0.09,0.25,0.1,0.38l0.13,1.25c0.04,0.39-0.1,0.78-0.39,1.04l-0.64,0.6
                  l-0.17,0.75l0.01,1.05l0.64,2.21l1.62,3.46l0.72,1.06c0.05,0.07,0.09,0.15,0.12,0.24l0.43,1.08l0.58,1.04
                  c0.14,0.27,0.19,0.59,0.11,0.89l-0.15,0.64l0.19,0.14l1.13,0.37c0.42,0.15,0.74,0.5,0.83,0.94l0.26,1.29
                  c0.02,0.11,0.02,0.23,0.02,0.34l-0.09,1.25c-0.01,0.13-0.04,0.25-0.09,0.37l-0.93,2.28l-0.07,0.41l0.35,0.23l1.07,0.65
                  c0.5,0.3,0.73,0.89,0.55,1.45l-0.39,1.24c-0.11,0.34-0.36,0.61-0.68,0.76l0.28,0.29l0.92,1.08c0.27,0.33,0.36,0.78,0.23,1.19
                  l-0.35,1.08l0.33,0.67c0.08,0.16,0.12,0.32,0.13,0.49l0.08,1.41c0,0.07,0,0.14-0.01,0.21l-0.14,1.22l0.2,0.96l0.52,1.13l0.82,0.58
                  c0.11,0.07,0.2,0.17,0.28,0.27l0.78,1.04c0.08,0.11,0.15,0.24,0.19,0.38l0.21,0.67l0.61,0.18c0.43,0.13,0.75,0.46,0.86,0.89
                  l0.52,2.01c0.02,0.08,0.04,0.17,0.04,0.26l0.09,3.24l0.47,1.02l0.75,0.95c0.16,0.21,0.25,0.46,0.27,0.72l0.07,1.35l0.19,1.19
                  l0.43,1.09c0.05,0.13,0.08,0.27,0.08,0.42l0.04,1.46C1177.97,385.87,1177.68,386.33,1177.21,386.54z"/>
                <title>Košice - okolie</title>
              </Link>

              {/* Gelnica kosicky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Gelnica")}>
                <path
                  d="M1101.12,307.62l-1.17,0.48l-1.07,0.07c-0.11,0.01-0.21,0.03-0.32,0.07l-1.22,0.42
                  c-0.36,0.12-0.65,0.42-0.78,0.78c-0.12,0.37-0.06,0.78,0.15,1.1l0.67,0.99l0.6,0.99l0.34,1.18l0.07,0.61l-0.22,0.29l-0.67,0.23
                  l-0.73,0.05l-0.88-0.41c-0.57-0.27-1.24-0.07-1.58,0.45l-0.67,1.04c-0.2,0.31-0.25,0.68-0.15,1.02c0.1,0.35,0.34,0.64,0.66,0.79
                  l0.5,0.23l0.02,0.25l-0.17,0.25l-0.83,0.19c-0.21,0.05-0.4,0.15-0.56,0.3l-0.63,0.57l-1.03,0.08l-1.3,0.17
                  c-0.41,0.05-0.76,0.3-0.95,0.66c-0.19,0.37-0.19,0.8,0,1.16l0.46,0.87l0.04,0.91l-0.04,1.28c0,0.05,0,0.11,0.01,0.16l0.08,0.83
                  l-1.07,1.99c-0.19,0.37-0.18,0.8,0.01,1.16l1.12,2.15l0.03,0.19l-1.47,0.97l-1.04,0.37l-2.27,0.68l-0.88-0.07l-0.89-0.53
                  c-0.11-0.07-0.22-0.11-0.34-0.14l-1.14-0.29l-1.08-0.46l-3.66-1.43c-0.1-0.04-0.21-0.06-0.32-0.07l-1.4-0.14
                  c-0.13-0.02-0.27-0.01-0.41,0.02l-2.85,0.7l-1.11,0.34l-1.26,0.26c-0.3,0.06-0.57,0.22-0.75,0.47l-0.95,1.27l-0.77,0.9l-0.8,0.75
                  l-1.06,0.26l-1.31,0.2c-0.13,0.02-0.25,0.06-0.37,0.12l-1.02,0.51l-0.79-0.33c-0.03-0.02-0.07-0.03-0.1-0.04l-1.38-0.44l-1.34-0.36
                  l-1.08-0.96c-0.32-0.28-0.75-0.38-1.16-0.27l-1.34,0.37l-1.09,0.05l-1.24-0.09l-1.06-0.21l-1.25-0.36l-0.88-0.58l-0.93-0.9
                  l-1.17-1.34c-0.41-0.48-1.12-0.57-1.65-0.21l-1.17,0.81c-0.07,0.05-0.14,0.11-0.2,0.17l-1.02,1.08c-0.03,0.03-0.05,0.06-0.07,0.09
                  l-0.67,0.85l-0.89,0.67c-0.18,0.14-0.32,0.32-0.41,0.53l-0.65,1.61c-0.05,0.13-0.08,0.27-0.09,0.41l-0.07,1.51
                  c-0.01,0.14,0.01,0.27,0.05,0.4l0.39,1.38l-0.18,0.85l-0.42,0.99l-0.43,0.8l-0.81,0.62c-0.17,0.13-0.3,0.3-0.39,0.5l-0.25,0.59
                  l-0.52,0.03c-0.42,0.02-0.8,0.26-1.02,0.63l-0.44,0.77l-0.61,0.31l-1.08,0.17c-0.05,0-0.1,0.01-0.15,0.03l-1.19,0.34l-1.37,0.27
                  c-0.17,0.04-0.33,0.1-0.47,0.2l-1.01,0.7c-0.04,0.03-0.08,0.06-0.12,0.09l-0.92,0.82l-0.99,0.71l-0.93,0.56l-2.5,0.44l-2.52,0.57
                  c-0.07,0.01-0.14,0.03-0.21,0.06l-1.28,0.52c-0.26,0.11-0.48,0.3-0.62,0.55l-0.6,1.06l-0.42,0.68l-0.86,0.27l-1.21,0.48
                  c-0.29,0.11-0.52,0.32-0.66,0.6l-0.62,1.23c-0.11,0.22-0.15,0.48-0.12,0.73l0.16,1.17l-0.03,1.23l0.02,1.34
                  c0,0.13,0.02,0.27,0.07,0.39l0.37,1.06l-0.12,0.69l-0.55,0.94c-0.06,0.11-0.11,0.23-0.14,0.35l-0.2,0.88l-0.65,0.67
                  c-0.04,0.04-0.08,0.09-0.11,0.14l-0.73,1.01c-0.19,0.26-0.27,0.58-0.23,0.9l0.17,1.24c0.02,0.14,0.06,0.28,0.13,0.4l0.49,0.95
                  l0.09,0.83l-0.14,0.7l-0.87,0.92c-0.11,0.12-0.21,0.26-0.26,0.42l-0.12-0.08l-0.23,0.35l-0.36,0.13l-0.77-0.48
                  c-0.13-0.08-0.28-0.14-0.44-0.17l-1.38-0.25l-0.99-0.27l-2.22-1.48l-0.96-0.98c-0.04-0.04-0.08-0.07-0.12-0.11l-0.98-0.76
                  c-0.25-0.19-0.56-0.28-0.87-0.26l-1.12,0.09l-0.88-0.1l-1.69-0.93l-0.06-0.74c-0.04-0.45-0.33-0.85-0.75-1.04
                  c-0.42-0.18-0.91-0.12-1.27,0.16l-0.91,0.71l-1.13,0.26l-1.67-0.68l-0.67-0.86l-0.51-1.11l-0.31-1.08l-0.19-1.08l-0.02-1.13
                  c0-0.03,0-0.07-0.01-0.1l-0.23-2.35l0.07-1.06l0.28-1.2c0.04-0.17,0.04-0.35,0-0.53l-0.53-2.71c-0.02-0.1-0.05-0.19-0.1-0.29
                  l-0.3-0.64l0.28-0.75l0.37-1.25c0.06-0.21,0.07-0.43,0.02-0.64l-0.15-0.62l0.42-0.54c0.27-0.34,0.34-0.8,0.19-1.21l-0.52-1.39
                  c-0.13-0.35-0.42-0.63-0.79-0.75l-0.87-0.28l-0.57-0.67c-0.18-0.2-0.42-0.35-0.69-0.4l-0.64-0.14l-0.29-0.6
                  c-0.04-0.07-0.08-0.14-0.12-0.2l-0.76-1.03c-0.15-0.2-0.34-0.34-0.57-0.43l-1.17-0.44l-1.23-0.79c-0.11-0.07-0.24-0.12-0.37-0.15
                  l-1.3-0.32c-0.29-0.07-0.59-0.04-0.85,0.09l-1.34,0.66c-0.12,0.06-0.23,0.14-0.33,0.24l-1.15,1.16l-0.66,0.43l-2.38-0.3
                  c-0.15-0.02-0.3-0.01-0.44,0.03l-1.79,0.45c-0.04,0.01-0.08,0.02-0.11,0.03l-1.58,0.55l-0.92,0.08l-0.86-0.24l-0.63-0.74l-0.47-0.99
                  c-0.06-0.13-0.15-0.24-0.25-0.34l-0.71-0.71l-0.73-1.98l0.22-0.88c0.04-0.13,0.05-0.27,0.04-0.4l-0.2-2.47l-0.04-1.26
                  c0-0.12-0.02-0.23-0.06-0.35l-0.39-1.2c-0.03-0.09-0.07-0.18-0.12-0.26l-0.92-1.5c-0.14-0.22-0.33-0.39-0.56-0.49l-2.51-1.12
                  l-0.69-0.36l-0.13-1.01l0.05-1.59c0.01-0.3-0.09-0.58-0.27-0.81l-0.01-0.01l-0.63-1.21l-0.17-0.89l0.16-2.32l0.21-1.23
                  c0.01-0.07,0.02-0.14,0.02-0.21v-0.37l0.33-0.11c0.06-0.02,0.12-0.05,0.18-0.08l1.08-0.55l1.07-0.38l0.96-0.29l0.96,0.15
                  c0.37,0.05,0.75-0.06,1.02-0.31c0.28-0.25,0.43-0.61,0.42-0.98l-0.04-1.02l0.31-0.95c0.06-0.18,0.07-0.36,0.05-0.55l-0.16-1.28
                  c-0.04-0.29-0.17-0.56-0.39-0.76l-0.02-0.01c0.03-0.03,0.05-0.06,0.08-0.1l0.67-0.88l1.02-0.54c0.11-0.06,0.2-0.13,0.29-0.21
                  l0.95-0.92c0.1-0.1,0.18-0.21,0.25-0.34l0.7-1.39c0.03-0.07,0.06-0.15,0.08-0.23l0.63-2.19l0.69-0.54l2.22-1.52
                  c0.33-0.23,0.53-0.6,0.54-1c0.01-0.39-0.17-0.77-0.48-1.02l-0.64-0.49l-0.16-0.87l-0.25-0.99l0.29-1.71l0.63-0.4
                  c0.13-0.09,0.25-0.2,0.35-0.34l0.7-1.01c0.22-0.32,0.28-0.73,0.16-1.11c-0.1-0.3-0.32-0.55-0.59-0.7l0.24-0.56l0.69-0.28
                  c0.03-0.01,0.07-0.03,0.1-0.05l1.11-0.57c0.13-0.06,0.24-0.15,0.34-0.25l0.8-0.86l0.83-0.78l1.03-0.72l0.87-0.69l2.99-1.28
                  l0.89,0.17c0.29,0.05,0.59,0,0.84-0.14l1.01-0.56l1.08-0.33c0.14-0.05,0.27-0.12,0.39-0.21l0.05-0.04l0.03,0.04
                  c0.04,0.05,0.09,0.1,0.14,0.14l0.99,0.88c0.27,0.25,0.64,0.36,1,0.31l1.18-0.16h0.04l-0.16,1.23c-0.02,0.15-0.01,0.3,0.02,0.45
                  l0.27,1.16l0.14,1.09l-0.3,0.94c-0.14,0.42-0.04,0.88,0.26,1.21l0.48,0.54l-0.07,0.57l-0.33,1.08c-0.03,0.1-0.05,0.21-0.05,0.32
                  l-0.04,1.2l-0.2,1.13c-0.01,0.08-0.02,0.16-0.02,0.25l0.03,1.36c0,0.1,0.02,0.2,0.04,0.29l0.64,2.31l0.15,1.18
                  c0.04,0.33,0.21,0.63,0.48,0.83l0.94,0.73l0.84,0.95c0.4,0.45,1.06,0.55,1.57,0.25c0.52-0.31,0.75-0.93,0.55-1.5l-0.85-2.4
                  l-0.01-0.73l0.47-0.74c0.06-0.11,0.12-0.22,0.15-0.35l0.16-0.61l0.25-0.08l0.88,0.28c0.16,0.04,0.32,0.06,0.48,0.05l0.99-0.09
                  l0.66,0.2l1.5,1.63c0.18,0.19,0.41,0.32,0.66,0.37l1.25,0.27c0.11,0.03,0.22,0.04,0.33,0.03l2.14-0.11l0.57,0.38l1.75,1.78
                  l0.61,0.94c0.11,0.16,0.25,0.29,0.42,0.39l1.09,0.64c0.13,0.08,0.28,0.13,0.44,0.16l1.12,0.17l0.7,0.23l0.5,0.66
                  c0.04,0.05,0.08,0.09,0.12,0.13l0.94,0.94l0.74,0.63l0.37,0.88c0.16,0.37,0.49,0.65,0.88,0.73l1.24,0.27
                  c0.22,0.05,0.45,0.04,0.66-0.03l0.95-0.32l1.02,0.16c0.14,0.03,0.28,0.02,0.41,0l1.27-0.22c0.18-0.03,0.34-0.1,0.49-0.19l2.13-1.39
                  c0.05-0.03,0.09-0.06,0.13-0.09l0.58-0.48l0.72,0.07c0.2,0.02,0.41-0.01,0.61-0.09l1.2-0.51l1.06-0.52l0.99-0.41l2.26-0.11
                  c0.07,0,0.15-0.01,0.22-0.03l1.32-0.29c0.04-0.01,0.09-0.02,0.14-0.04l1.31-0.45c0.05-0.02,0.1-0.04,0.15-0.06l1.09-0.54l1.01-0.3
                  l2.37-0.04c0.04,0,0.08,0,0.12-0.01l1.32-0.15c0.27-0.03,0.52-0.15,0.72-0.33l0.73-0.7l1.05-0.41c0.07-0.02,0.13-0.06,0.2-0.1
                  l0.92-0.57l2.94-0.91c0.06-0.02,0.12-0.05,0.18-0.08l2.29-1.18c0.33-0.17,0.57-0.48,0.65-0.85l0.24-1.12l0.41-1.07l0.57-1.07
                  c0.02-0.04,0.04-0.08,0.06-0.13l0.44-1.12l1.1-2.37c0.01-0.03,0.03-0.06,0.04-0.09l0.42-1.1l0.62-0.91l0.96-1.16l0.57-0.76l0.8-0.26
                  c0.16-0.06,0.32-0.15,0.45-0.27l1.05-0.97c0.1-0.1,0.19-0.21,0.25-0.33l0.64-1.2c0.03-0.05,0.05-0.1,0.07-0.15l0.27-0.73l0.69-0.37
                  c0.17-0.09,0.31-0.22,0.42-0.38l0.71-1l0.8-0.79c0.36-0.35,0.48-0.88,0.29-1.35l-0.48-1.21c-0.11-0.28-0.32-0.51-0.58-0.65
                  l-0.78-0.4l-0.6-1.13c-0.08-0.15-0.2-0.29-0.34-0.4l-0.58-0.45l0.07-0.71c0.04-0.33-0.06-0.65-0.27-0.91l-0.71-0.87l-0.52-1.04
                  c-0.12-0.23-0.3-0.42-0.53-0.54l-1.09-0.58c-0.16-0.09-0.34-0.14-0.53-0.15l-1.4-0.07c-0.08,0-0.17,0-0.26,0.02l-0.08,0.01
                  l0.34-0.84l0.67-0.9c0.1-0.13,0.17-0.28,0.21-0.43l0.38-1.46c0.16-0.62-0.17-1.26-0.76-1.49l0.03-0.08c0.12-0.28,0.13-0.6,0.03-0.89
                  l-0.59-1.68c-0.01-0.04-0.03-0.08-0.05-0.12l-0.54-1.16l-0.22-0.6l0.88-1.61l0.99-0.52c0.04-0.02,0.07-0.04,0.1-0.06l0.91-0.59
                  l0.99-0.15c0.04-0.01,0.09-0.02,0.13-0.03l1.14-0.3l1.02,0.04l0.94,0.19l0.79,0.47l1.93,1.65l0.79,1.04
                  c0.03,0.04,0.06,0.07,0.09,0.1l0.99,1.06c0.13,0.15,0.31,0.27,0.5,0.33l1.08,0.37l1.15,0.73c0.44,0.29,1.02,0.26,1.44-0.07
                  l0.97-0.76c0.1-0.08,0.19-0.17,0.27-0.28l0.6-0.9l0.37-0.31l0.71,0.1c0.36,0.05,0.73-0.06,1-0.3l0.87-0.76l0.88-0.59l0.67-0.28
                  l0.83,0.22c0.13,0.03,0.27,0.05,0.4,0.04l0.42-0.03l0.08,0.17l0.11,1.08c0.02,0.19,0.08,0.38,0.19,0.55l0.63,0.98l-0.18,0.72
                  c-0.01,0.07-0.02,0.14-0.03,0.21l-0.1,1.54c-0.01,0.13,0.01,0.27,0.04,0.4l0.32,1.19l-0.07,1.08c-0.02,0.37,0.12,0.73,0.38,0.98
                  l1.01,0.96l1.33,1.14l1.01,1.02c0.04,0.04,0.08,0.07,0.12,0.11l1.2,0.93c0.03,0.02,0.06,0.04,0.09,0.06l0.7,0.45l0.25,0.75
                  l0.45,2.59c0.02,0.11,0.06,0.22,0.11,0.33l0.51,1.06l0.06,1.27c0.02,0.45,0.28,0.85,0.68,1.05l2.34,1.25
                  c0.18,0.1,0.38,0.15,0.59,0.15l1.35,0.01l1.3,0.08c0.21,0.01,0.39-0.02,0.57-0.1l0.82-0.35l0.87,0.19c0.13,0.03,0.26,0.04,0.39,0.02
                  l1.04-0.11l0.42,0.93l0.39,1.14c0.01,0.04,0.02,0.08,0.04,0.11l1.06,2.31l0.3,1.11l0.19,1.11l-0.07,0.76
                  c-0.01,0-0.01-0.01-0.02-0.01l-0.95-0.83c-0.31-0.28-0.75-0.38-1.15-0.26c-0.41,0.11-0.73,0.41-0.86,0.81l-0.25,0.79l-0.74,0.29
                  c-0.07,0.02-0.14,0.06-0.21,0.1l-1.13,0.71c-0.38,0.24-0.6,0.68-0.58,1.13l0.12,2.08l-0.48,0.65c-0.22,0.3-0.3,0.67-0.22,1.03
                  l0.28,1.24c0.07,0.3,0.25,0.57,0.51,0.75l1.08,0.74c0.13,0.09,0.27,0.15,0.42,0.19l1.29,0.31l1.86,0.69l0.01,0.33l-0.08,1.74
                  c-0.01,0.32,0.09,0.63,0.3,0.87l0.8,0.94c0.37,0.44,0.98,0.56,1.49,0.32l-0.08,0.86c-0.01,0.19,0.02,0.39,0.09,0.58l0.44,1.09
                  L1101.12,307.62z"/>
                <title>Gelnica</title>
              </Link>

              {/* Spiska nova ves kosicky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Spišská Nová Ves")}>
                <path style={{ fill: crimesInRegionKosicky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionKosicky[4].crimePercent)] ? crimesInRegionKosicky[4].color : "#D3D3D3" }}
                  d="M1061.36,281.15l-0.28,0.28c-0.06,0.05-0.11,0.11-0.15,0.17l-0.61,0.86l-0.85,0.46
                  c-0.26,0.15-0.47,0.38-0.57,0.66l-0.4,1.09l-0.5,0.94l-0.7,0.64l-0.92,0.3c-0.24,0.08-0.45,0.24-0.6,0.44l-0.76,1.02l-0.96,1.16
                  c-0.03,0.03-0.05,0.06-0.07,0.09l-0.74,1.08c-0.06,0.09-0.1,0.17-0.14,0.26l-0.45,1.18l-1.12,2.45l-0.44,1.09l-0.57,1.08
                  c-0.03,0.05-0.05,0.09-0.07,0.14l-0.47,1.23c-0.02,0.06-0.04,0.13-0.05,0.19l-0.14,0.64l-1.68,0.86l-1.54,0.51l-1.38,0.4
                  c-0.11,0.03-0.21,0.08-0.31,0.14l-0.97,0.6l-1.17,0.46c-0.15,0.06-0.28,0.14-0.4,0.25l-0.61,0.58l-0.84,0.09l-2.48,0.04
                  c-0.11,0-0.23,0.02-0.34,0.05l-1.28,0.38c-0.06,0.02-0.13,0.05-0.2,0.08l-1.11,0.55l-1.17,0.4l-1.14,0.26l-2.42,0.12
                  c-0.14,0.01-0.27,0.04-0.4,0.1l-1.25,0.52l-1.07,0.52l-0.84,0.36l-0.92-0.09c-0.33-0.04-0.66,0.06-0.91,0.27l-0.92,0.75l-1.92,1.24
                  l-0.8,0.14l-1.11-0.17c-0.2-0.04-0.4-0.02-0.59,0.04l-0.92,0.31l-0.27-0.06l-0.24-0.56c-0.08-0.18-0.19-0.34-0.34-0.46l-0.89-0.75
                  l-0.8-0.81l-0.69-0.91c-0.16-0.21-0.37-0.36-0.61-0.44l-1.17-0.38c-0.07-0.02-0.13-0.04-0.2-0.05l-0.98-0.15l-0.64-0.37l-0.52-0.81
                  c-0.05-0.07-0.11-0.14-0.17-0.2l-1.89-1.91c-0.06-0.07-0.13-0.13-0.2-0.18l-1.02-0.69c-0.22-0.15-0.49-0.22-0.76-0.21l-2.41,0.12
                  l-0.7-0.15l-1.46-1.58c-0.15-0.16-0.34-0.28-0.55-0.34l-1.21-0.38c-0.15-0.05-0.32-0.07-0.48-0.06l-1,0.09l-1-0.31
                  c-0.24-0.07-0.48-0.07-0.72,0l-1.28,0.37c-0.42,0.12-0.74,0.45-0.86,0.88l-0.3,1.11l-0.03,0.06l-0.07-0.26l-0.03-1.08l0.2-1.1
                  c0.01-0.06,0.02-0.12,0.02-0.18l0.03-1.13l0.32-1.03c0.02-0.07,0.03-0.14,0.04-0.22l0.14-1.24c0.04-0.35-0.07-0.71-0.31-0.97
                  l-0.37-0.42l0.17-0.53c0.06-0.17,0.07-0.36,0.05-0.55l-0.19-1.42c-0.01-0.03-0.01-0.07-0.02-0.11l-0.23-1l0.14-1.04
                  c0-0.05,0.01-0.09,0.01-0.13l0.04-1.25c0.02-0.66-0.47-1.22-1.12-1.28l-1.35-0.14c-0.1-0.01-0.2-0.01-0.3,0l-0.76,0.1l-0.48-0.43
                  l-0.74-0.9c-0.43-0.52-1.2-0.62-1.73-0.2l-0.83,0.64l-1,0.3c-0.08,0.03-0.17,0.07-0.24,0.11l-0.72,0.41l-0.82-0.16
                  c-0.24-0.04-0.49-0.02-0.72,0.08l-2.36,0.99l-1.17,0.54c-0.09,0.03-0.17,0.08-0.25,0.14l-0.94,0.74l-1.06,0.73
                  c-0.05,0.04-0.1,0.08-0.14,0.13l-0.94,0.89l-0.7,0.75l-0.87,0.44l-1.12,0.45c-0.3,0.12-0.55,0.36-0.68,0.67l-0.46,1.08l-0.59,1
                  c-0.19,0.32-0.22,0.72-0.09,1.07c0.06,0.16,0.15,0.3,0.26,0.42l-0.33,0.21c-0.29,0.19-0.5,0.5-0.55,0.85l-0.43,2.48
                  c-0.03,0.17-0.03,0.35,0.02,0.53l0.3,1.17l0.22,1.25c0.03,0.17,0.09,0.33,0.19,0.47l-0.92,0.63l-1.07,0.84
                  c-0.2,0.16-0.35,0.37-0.43,0.62l-0.72,2.51l-0.55,1.09l-0.67,0.65l-1.11,0.58c-0.16,0.09-0.31,0.21-0.42,0.36l-0.8,1.05l-0.8,0.87
                  c-0.23,0.24-0.35,0.56-0.34,0.9c0.01,0.33,0.16,0.64,0.4,0.87l0.61,0.56l0.07,0.55l-0.3,0.89c-0.02,0.07-0.04,0.13-0.05,0.2
                  c-0.1,0-0.21,0.02-0.31,0.05l-1.29,0.39l-1.21,0.43c-0.05,0.02-0.1,0.04-0.15,0.07l-1.06,0.54l-1.1,0.36
                  c-0.51,0.17-0.86,0.65-0.86,1.19v1.17l-0.2,1.19c-0.01,0.04-0.01,0.09-0.02,0.13l-0.18,2.52c0,0.12,0,0.23,0.02,0.34l0.18,0.93
                  l-0.68-0.48c-0.17-0.12-0.36-0.19-0.56-0.22l-0.99-0.13l-0.83-0.59l-0.54-0.75l-0.47-1.1c-0.11-0.26-0.3-0.47-0.54-0.6l-1.1-0.61
                  c-0.16-0.09-0.32-0.14-0.5-0.15l-1.32-0.12c-0.04-0.01-0.07,0-0.12-0.01l-1.23,0.01c-0.33,0-0.65,0.14-0.88,0.37l-0.32-0.89
                  c-0.05-0.14-0.13-0.27-0.22-0.38l-0.79-0.95c-0.02-0.03-0.04-0.05-0.07-0.07l-0.93-0.95c-0.07-0.08-0.16-0.15-0.25-0.2l-1.1-0.66
                  c-0.06-0.04-0.13-0.08-0.21-0.1l-0.97-0.36l-0.42-0.47l0.03-0.86v-0.11l-0.07-1.31c-0.01-0.19-0.07-0.38-0.17-0.55l-0.68-1.19
                  l-0.52-1.09c-0.19-0.41-0.58-0.68-1.02-0.71l-1.13-0.1l-0.76-0.57l-1.03-0.86c-0.23-0.2-0.54-0.31-0.84-0.29l-1.32,0.04
                  c-0.13,0-0.25,0.03-0.38,0.07l-0.99,0.35l-2.1-0.33l-0.75-0.46l-0.87-0.89c-0.15-0.15-0.34-0.26-0.55-0.32l-1.3-0.37
                  c-0.07-0.02-0.14-0.04-0.21-0.04l-1.24-0.13l-1.21-0.31c-0.08-0.02-0.17-0.03-0.25-0.04l-2.2-0.07l-0.77-0.48
                  c-0.08-0.05-0.17-0.09-0.26-0.12l-0.78-0.27l0.23-0.13c0.26-0.16,0.46-0.41,0.55-0.7l0.33-1.09l1.72-3.57l0.87-0.65
                  c0.09-0.07,0.17-0.15,0.24-0.24l0.67-0.86l0.82-0.61l0.96-0.58l1.07-0.5c0.54-0.26,0.82-0.87,0.67-1.45
                  c-0.16-0.59-0.69-0.97-1.31-0.93l-1.38,0.11c-0.26,0.02-0.51,0.13-0.71,0.3l-0.84,0.72l-0.18,0.08l-0.75-0.78l0.13-0.57
                  c0.06-0.23,0.05-0.48-0.03-0.72l-0.42-1.14l-0.1-0.74l0.46-1.07c0.23-0.51,0.09-1.11-0.35-1.47l-0.83-0.68l-0.55-0.94
                  c-0.07-0.12-0.16-0.23-0.26-0.32l-0.47-0.4l0.05-0.37l0.28-0.54l0.21-0.09l0.78,0.38c0.16,0.07,0.33,0.11,0.51,0.12l1.24,0.03
                  c0.13,0.01,0.26-0.01,0.38-0.05l2.47-0.77c0.04-0.01,0.07-0.03,0.1-0.04l1.17-0.5c0.37-0.16,0.64-0.48,0.73-0.87l0.29-1.24
                  c0.1-0.42-0.03-0.86-0.34-1.17c-0.31-0.3-0.75-0.43-1.18-0.32l-1.45,0.36l-0.97-0.22c-0.06-0.01-0.12-0.02-0.19-0.03l-1.75-0.12
                  l-0.67-0.23l-0.72-0.92c-0.26-0.34-0.67-0.51-1.1-0.47l-1.27,0.12c-0.08,0-0.16,0.02-0.24,0.04l-0.71,0.22l-0.52-0.44
                  c-0.07-0.06-0.16-0.12-0.25-0.16l-1.02-0.5l-0.89-0.77l-1.01-0.78c-0.18-0.14-0.4-0.23-0.63-0.25l-1.23-0.13l-0.96-0.39l-1.05-0.71
                  c-0.07-0.05-0.15-0.1-0.23-0.13l-1.06-0.43l-0.02-0.05c-0.03-0.08-0.06-0.15-0.1-0.23l-1.23-2.17c-0.08-0.14-0.19-0.27-0.32-0.37
                  l-1.21-0.95c-0.06-0.05-0.12-0.09-0.18-0.12l-1.12-0.6c-0.2-0.11-0.43-0.16-0.66-0.15l-1.3,0.07c-0.15,0.01-0.31,0.05-0.45,0.11
                  l-1.19,0.55l-0.72,0.38l-0.4-0.1l-0.5-0.82c-0.17-0.29-0.46-0.5-0.79-0.58c-0.26-0.06-0.53-0.03-0.78,0.07l0.37-0.95l0.42-0.65
                  l0.88-0.47c0.03-0.02,0.07-0.04,0.1-0.06l1.06-0.7c0.2-0.13,0.36-0.32,0.46-0.55l0.92-2.04l0.73-0.77c0.09-0.09,0.16-0.19,0.22-0.3
                  l0.4-0.8l0.75-0.5c0.1-0.06,0.19-0.14,0.27-0.24l0.74-0.88l0.61-0.57l0.73-0.09l1.07,0.15c0.43,0.06,0.87-0.11,1.14-0.46l0.68-0.83
                  l0.87-0.6c0.24-0.15,0.41-0.38,0.49-0.65l0.33-1.03l0.63-0.47l1.02-0.64l1.14-0.51c0.09-0.04,0.17-0.08,0.24-0.14l0.66-0.49
                  l0.77,0.05c0.13,0,0.26-0.01,0.39-0.04l1.2-0.31c0.07-0.02,0.14-0.04,0.2-0.07l1.38-0.62c0.14-0.06,0.27-0.15,0.37-0.26l0.88-0.88
                  c0.08-0.08,0.15-0.17,0.21-0.26l0.38-0.67l1.59-0.47c0.06-0.02,0.11-0.04,0.16-0.06l0.77-0.34l-0.04,0.69
                  c-0.02,0.53,0.29,1.01,0.77,1.22c0.49,0.2,1.06,0.07,1.41-0.33l0.73-0.81l0.73-0.4l1.01-0.01c0.45,0,0.87-0.25,1.08-0.65l0.6-1.07
                  l0.7-1.11c0.06-0.1,0.1-0.2,0.14-0.31l0.3-1.01l0.18-0.27c0.1,0.51,0.5,0.91,1.03,0.99l1.27,0.21c0.25,0.05,0.51,0.01,0.74-0.1
                  l0.51-0.24l1.97,1.87l0.63,0.91c0.32,0.46,0.91,0.65,1.43,0.47l1.26-0.43c0.12-0.04,0.23-0.09,0.33-0.17l0.47-0.34l0.3,0.18
                  l0.63,0.7l0.76,0.97l0.28,0.46l-0.3,0.68c-0.1,0.22-0.13,0.45-0.1,0.69l0.17,1.16l-0.01,1.21c0,0.33,0.12,0.64,0.35,0.88l0.88,0.91
                  c0.04,0.04,0.09,0.08,0.13,0.12l0.87,0.67l0.69,0.84c0.34,0.42,0.91,0.57,1.41,0.38l1.17-0.44c0.61-0.23,0.94-0.88,0.76-1.5
                  l-0.33-1.19c-0.02-0.1-0.06-0.19-0.11-0.28l-0.61-1.1c-0.12-0.21-0.3-0.39-0.52-0.5l-0.59-0.3l-0.31-1.63l0.11-0.42l0.79,0.19
                  c0.37,0.1,0.77,0.01,1.07-0.23l0.67-0.52l2.25-0.17c0.24-0.03,0.47-0.12,0.66-0.26l1.04-0.81c0.26-0.21,0.43-0.5,0.47-0.83
                  l0.18-1.41c0.07-0.53-0.2-1.03-0.67-1.27l-1.25-0.64l-0.64-0.29c0.23-0.11,0.43-0.3,0.56-0.54l1.11-2.14l0.77-0.66
                  c0.14-0.13,0.26-0.29,0.33-0.47l0.49-1.15l0.61-0.92c0.03-0.04,0.06-0.09,0.09-0.14l0.35-0.71l0.27,0.25
                  c0.31,0.31,0.76,0.42,1.17,0.31l0.13,0.36l-0.44,0.42c-0.06,0.06-0.11,0.12-0.16,0.19l-0.73,1.05c-0.18,0.26-0.25,0.58-0.21,0.9
                  l0.25,1.65l-0.21,1.23c-0.11,0.63,0.26,1.23,0.86,1.42l0.9,0.27l1.54,1.7l0.88,0.9c0.17,0.18,0.38,0.3,0.62,0.35l1.31,0.29
                  l0.18,0.03l1.03,0.07l0.57,0.53l0.86,0.92c0.11,0.13,0.26,0.23,0.42,0.3l1.14,0.51l0.61,0.57l0.41,0.84l0.37,1.13
                  c0.05,0.15,0.12,0.28,0.22,0.4l0.72,0.89l0.51,0.99c0.28,0.54,0.9,0.81,1.49,0.62l1.17-0.37c0.08-0.02,0.16-0.06,0.24-0.1l0.74-0.43
                  l0.87,0.06c0.26,0.01,0.54-0.06,0.77-0.21l0.53-0.35l0.64,0.21c0.35,0.12,0.73,0.08,1.04-0.11l1.14-0.69l1.08-0.35l0.83-0.2
                  l0.1,1.26c0.01,0.13,0.05,0.26,0.1,0.38l0.41,0.96l0.04,1.09c0.02,0.58,0.44,1.07,1.01,1.18l0.63,0.12l0.68,2.37
                  c0.01,0.04,0.02,0.08,0.04,0.12l0.65,1.64c0.22,0.56,0.81,0.88,1.4,0.77l1.56-0.3c0.22-0.04,0.41-0.14,0.58-0.28l0.97-0.82
                  l1.04,0.06l1.42,0.62c0.19,0.08,0.39,0.11,0.59,0.1l1.66-0.12c0.1-0.01,0.2-0.03,0.3-0.06l2.64-0.93c0.13-0.04,0.24-0.11,0.35-0.19
                  l1.06-0.84c0.11-0.09,0.21-0.2,0.29-0.33l0.72-1.18c0.03-0.05,0.05-0.1,0.07-0.15l0.51-1.16c0.09-0.2,0.13-0.41,0.1-0.63l-0.15-1.45
                  c0-0.07-0.02-0.14-0.03-0.2l-0.15-0.54l0.51-0.44l0.86-0.53l1.09-0.11c0.15-0.01,0.31-0.06,0.45-0.13l2.27-1.21l1.35-0.38
                  c0.03-0.01,0.07-0.02,0.1-0.03l0.83-0.31l1.37,0.53l0.25,0.69c0.05,0.13,0.11,0.24,0.19,0.35l0.72,0.92l0.66,1.06
                  c0.03,0.05,0.06,0.1,0.1,0.14l1.03,1.23c0.39,0.47,1.06,0.59,1.59,0.28l1.18-0.69c0.2-0.12,0.36-0.28,0.47-0.48l0.51-0.94l1.09-0.76
                  l0.48-0.15l0.19,0.1l0.2,0.77c0.05,0.16,0.12,0.31,0.23,0.44l0.77,0.98c0.22,0.28,0.55,0.46,0.9,0.48l0.61,0.04l0.31,0.52
                  c0.34,0.59,1.09,0.79,1.68,0.46l1.29-0.71l1.09-0.72l1.08-0.65c0.29-0.17,0.5-0.45,0.57-0.78c0.08-0.33,0.03-0.67-0.15-0.95
                  l-0.72-1.15c-0.14-0.23-0.35-0.4-0.6-0.5l-0.54-0.21l0.09-1.49l0.69-1.79c0.11-0.25,0.12-0.54,0.04-0.8l-0.41-1.39
                  c-0.03-0.09-0.06-0.17-0.1-0.25l-0.02-0.03l0.68-0.85l0.5-0.56l1.11-0.09l0.52,0.51c0.05,0.06,0.11,0.11,0.18,0.16l1.08,0.73
                  c0.03,0.02,0.06,0.04,0.09,0.05l0.67,0.37l0.22,0.7c0.11,0.34,0.36,0.62,0.69,0.77l1.2,0.52c0.07,0.02,0.13,0.05,0.2,0.06l3.19,0.8
                  l-0.53,0.38c-0.47,0.35-0.64,0.97-0.41,1.51l0.58,1.34c0.08,0.17,0.2,0.33,0.34,0.45l1.03,0.87c0.08,0.07,0.17,0.13,0.26,0.17
                  l1.29,0.63c0.16,0.08,0.33,0.12,0.5,0.13l1.41,0.05c0.24,0,0.44-0.04,0.63-0.14c0,0.21,0.04,0.42,0.15,0.61
                  c0.22,0.41,0.69,0.69,1.13,0.66l1.36-0.03c0.04,0,0.09,0,0.13-0.01l1.51-0.2c0.21-0.03,0.41-0.11,0.58-0.23l0.93-0.69l0.96-0.3
                  l1.21-0.23c0.1-0.02,0.19-0.05,0.28-0.1l0.83-0.38l0.79,0.21l0.68,0.56l1.08,1.21l1.05,1.02c0.31,0.3,0.75,0.42,1.17,0.31l0.61-0.15
                  l0.28,0.23l-0.14,0.09l-1.28,0.67c-0.22,0.12-0.4,0.3-0.52,0.52l-1.32,2.44c-0.17,0.31-0.2,0.68-0.08,1.01l0.42,1.18
                  c0.02,0.04,0.03,0.08,0.05,0.11l0.54,1.15l0.41,1.17l-0.36,0.88c-0.14,0.32-0.13,0.68,0.01,1c0.14,0.31,0.41,0.55,0.74,0.66
                  l0.12,0.04l-0.65,0.93c-0.07,0.09-0.12,0.19-0.16,0.29l-0.22,0.56l-0.36,0.03l-1.02-0.26c-0.45-0.11-0.93,0.03-1.24,0.37
                  c-0.31,0.34-0.4,0.83-0.25,1.27l0.42,1.16c0.09,0.23,0.25,0.44,0.46,0.59l1.04,0.74c0.23,0.16,0.5,0.25,0.8,0.23l1.34-0.09
                  c0.04-0.01,0.08-0.01,0.12-0.02l1.18-0.19l0.99,0.05l0.49,0.27l0.4,0.8c0.04,0.08,0.09,0.16,0.15,0.23l0.48,0.58l-0.09,0.89
                  c-0.05,0.43,0.14,0.85,0.48,1.11l0.91,0.71l0.66,1.23c0.11,0.22,0.3,0.4,0.52,0.52L1061.36,281.15z"/>
                <title>Spišská Nová Ves</title>
              </Link>

              {/* Roznava - kosicky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Rožňava")}>
                <path style={{ fill: crimesInRegionKosicky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionKosicky[3].crimePercent)] ? crimesInRegionKosicky[3].color : "#D3D3D3" }}
                  d="M1038.2,391.52l-0.85-0.31c-0.12-0.04-0.24-0.06-0.37-0.07l-2.53-0.12l-1.1-0.37
                  c-0.19-0.06-0.39-0.07-0.59-0.05l-2.26,0.34c-0.33,0.05-0.63,0.24-0.82,0.51c-0.2,0.27-0.28,0.61-0.22,0.94l0.22,1.26
                  c0.01,0.09,0.03,0.16,0.06,0.24l0.54,1.4c0.07,0.18,0.18,0.34,0.32,0.47l0.91,0.84l0.73,0.83l0.51,1.01l0.23,0.94l-0.05,0.38
                  l-1.16-0.38c-0.12-0.04-0.24-0.06-0.36-0.06l-1.35-0.03h-0.03c-0.49,0-0.93,0.28-1.13,0.72l-0.52,1.12
                  c-0.09,0.18-0.13,0.37-0.12,0.57l0.04,1.15l-0.06,2.59v0.1l0.38,4.92l-8.23,1.18l-5.12,0.52c-0.18,0.01-0.35,0.07-0.5,0.16
                  l-1.15,0.67c-0.04,0.02-0.07,0.04-0.1,0.07l-0.31,0.22l-0.09-0.1c-0.2-0.23-0.48-0.38-0.78-0.42l-1.26-0.16l-1.08-0.34
                  c-0.23-0.07-0.47-0.08-0.7-0.01l-1.18,0.32l-1.12,0.06c-0.17,0.01-0.33,0.05-0.47,0.12l-3.49,1.7l-1.37,0.6l-1.09,0.56
                  c-0.08,0.04-0.15,0.09-0.22,0.14l-0.39,0.31l-0.32-0.2c-0.13-0.08-0.27-0.14-0.42-0.17l-1.31-0.26c-0.3-0.06-0.62,0-0.89,0.16
                  l-0.69,0.42l-2.47-0.32c-0.38-0.05-0.76,0.07-1.03,0.32l-1.16,1.09c-0.09,0.08-0.17,0.17-0.23,0.27l-0.57,0.97
                  c-0.19-0.13-0.4-0.2-0.63-0.22c-0.33-0.02-0.67,0.1-0.92,0.32l-1.03,0.93l-0.64,0.44l-0.82,0.03l-1.26-0.01c-0.01,0-0.01,0-0.01,0
                  c-0.36,0-0.71,0.16-0.94,0.43l-0.83,0.95c-0.06,0.07-0.12,0.14-0.16,0.23l-0.49,0.9l-0.47,0.41l-0.85,0.01
                  c-0.06,0-0.12,0.01-0.17,0.01l-1.2,0.19l-1.27,0.1c-0.19,0.02-0.37,0.08-0.54,0.17l-2.09,1.3l-1.81,0.74l-0.78-0.25l-1.19-0.57
                  l-1.42-0.6c-0.04-0.02-0.09-0.04-0.13-0.05l-1.13-0.33l-1.21-0.52c-0.23-0.1-0.49-0.12-0.74-0.07l-1.01,0.2l-0.85-0.33
                  c-0.43-0.16-0.91-0.08-1.26,0.22l-0.94,0.8c-0.08,0.06-0.15,0.14-0.21,0.22l-0.78,1.08c-0.09,0.12-0.15,0.27-0.19,0.41l-0.75,2.97
                  l-0.59-0.42c-0.11-0.09-0.25-0.15-0.39-0.19l-0.82-0.24l-0.32-0.7c-0.02-0.03-0.03-0.06-0.05-0.09l-0.64-1.14
                  c-0.17-0.3-0.45-0.52-0.79-0.6c-0.33-0.08-0.68-0.03-0.97,0.15l-0.81,0.52l-0.97,0.13c-0.35,0.05-0.67,0.25-0.87,0.54l-0.6,0.89
                  l-0.87,0.67c-0.2,0.16-0.35,0.37-0.42,0.61l-0.43,1.36c-0.08,0.24-0.08,0.49-0.01,0.72l0.29,1.03l-0.08,1.05
                  c-0.02,0.25,0.04,0.49,0.16,0.7l0.61,1.09c0.06,0.1,0.13,0.2,0.21,0.28l0.66,0.65l0.11,0.38l-0.47,0.84
                  c-0.09,0.16-0.14,0.34-0.16,0.52l-0.6,6.71c-0.01,0.15,0,0.3,0.04,0.44l-0.63,0.09c-0.15,0.02-0.29,0.07-0.42,0.14l-1.24,0.67
                  c-0.24,0.13-0.42,0.32-0.54,0.55l-0.61,1.26c-0.03,0.07-0.05,0.14-0.07,0.21l-0.43,1.52l-0.23,1.07l-0.87,0.83
                  c-0.02,0.02-0.04,0.04-0.07,0.07l-0.76,0.86l-1.83,1.57l-0.54,0.2c-0.02-0.06-0.05-0.11-0.08-0.16l-0.82-1.31
                  c-0.09-0.14-0.2-0.26-0.33-0.35l-1.15-0.83c-0.31-0.23-0.72-0.3-1.09-0.19l-2.67,0.79c-0.05,0.02-0.11,0.04-0.16,0.06l-1.37,0.61
                  c-0.08,0.03-0.15,0.07-0.22,0.12l-0.35,0.26l-0.41-0.24c-0.39-0.23-0.87-0.24-1.26-0.02l-0.71,0.4l-1.14-0.68
                  c-0.48-0.28-1.09-0.21-1.5,0.17l-0.91,0.86c-0.14,0.13-0.24,0.29-0.31,0.47l-0.47,1.28l-0.15,0.52l-0.5,0.13
                  c-0.22,0.05-0.42,0.17-0.58,0.34l-0.7,0.71l-0.83,0.36l-0.56,0.12l-0.31-0.24l-0.18-0.8c-0.05-0.23-0.15-0.43-0.31-0.6l-0.96-1.02
                  c-0.07-0.07-0.16-0.14-0.25-0.2l-2.1-1.33c-0.39-0.24-0.88-0.26-1.28-0.03l-1.08,0.6c-0.07,0.04-0.14,0.08-0.2,0.14l-0.94,0.8
                  c-0.1,0.09-0.19,0.19-0.26,0.3l-0.23,0.38l-1.56-0.29c-0.21-0.05-0.43-0.04-0.63,0.02l-1.12,0.34l-2.73,0.22l-0.9-0.05l-0.02-0.01
                  l1.31-2.61c0.29-0.58,0.08-1.29-0.47-1.63l-1.13-0.68c-0.31-0.18-0.68-0.23-1.02-0.12l-0.94,0.28l-0.95-0.07l-2.26-0.31l-0.82-0.36
                  l-0.78-0.66l-0.25-0.46l0.27-0.69c0.1-0.26,0.11-0.55,0.03-0.81l-0.3-0.99l0.14-0.9l0.28-1.15c0.07-0.3,0.02-0.63-0.13-0.91
                  l-0.65-1.15c-0.05-0.09-0.12-0.17-0.19-0.25l-0.86-0.89c-0.04-0.04-0.08-0.08-0.12-0.11l-0.71-0.56l-0.14-0.39l0.35-0.69
                  c0.05-0.1,0.08-0.2,0.1-0.31l0.27-1.27l0.17-1.2l0.25-1.31c0.01-0.03,0.01-0.07,0.01-0.11l0.04-0.37l0.4-0.1
                  c0.08-0.02,0.16-0.05,0.23-0.09l1.07-0.53l1.27-0.54c0.26-0.11,0.48-0.31,0.61-0.56l0.6-1.12c0.05-0.09,0.08-0.18,0.11-0.27
                  l0.6-2.35l0.13-0.42l0.53-0.06c0.32-0.03,0.61-0.19,0.82-0.44l0.81-0.97c0.22-0.26,0.32-0.6,0.28-0.94l-0.14-1.32l-0.13-2.28
                  l0.46-0.93c0.02-0.03,0.04-0.07,0.05-0.11l0.44-1.18c0.02-0.05,0.04-0.1,0.05-0.15l0.29-1.23c0.02-0.09,0.03-0.17,0.03-0.25
                  l0.03-1.11l0.4-1.11l0.46-1.13l0.43-0.85l0.78-0.52c0.05-0.03,0.09-0.06,0.13-0.1l0.94-0.82c0.36-0.31,0.51-0.8,0.39-1.25
                  l-0.26-1.01l0.16-0.97c0.04-0.22,0.02-0.44-0.06-0.65l-0.25-0.67l0.95-2.04l0.48-1.21c0.02-0.03,0.03-0.06,0.04-0.1l0.37-1.22
                  c0.1-0.35,0.05-0.72-0.14-1.03l-0.69-1.07c-0.17-0.27-0.43-0.46-0.73-0.54l-1.3-0.33l-0.95-0.2l-0.48-0.36l-0.23-0.87
                  c-0.04-0.13-0.09-0.26-0.17-0.37l-0.68-1.03c-0.23-0.35-0.61-0.56-1.03-0.56l-1.3-0.01h-0.01c-0.26,0-0.52,0.08-0.73,0.24l-0.85,0.6
                  l-0.73,0.25l-0.69-0.12l-1.2-0.67c-0.03-0.02-0.06-0.04-0.1-0.05l-1.38-0.62c-0.06-0.03-0.12-0.05-0.19-0.07l-1.38-0.37l-0.32-0.1
                  l0.42-0.67l0.77-0.86c0.12-0.14,0.21-0.3,0.26-0.47l0.37-1.24c0.07-0.23,0.07-0.48,0-0.71l-0.35-1.21
                  c-0.02-0.08-0.05-0.15-0.09-0.22l-1.09-2.07l-0.04-0.96c0-0.09-0.02-0.17-0.04-0.25l-0.54-2.35c-0.01-0.08-0.04-0.16-0.07-0.23
                  l-0.31-0.76l0.19-0.67l0.52-1.01c0.05-0.1,0.09-0.21,0.12-0.32l0.23-1.16l0.39-1.13c0.14-0.39,0.07-0.82-0.18-1.15l-0.82-1.11
                  l-0.81-0.95c-0.05-0.06-0.11-0.12-0.17-0.17l-0.72-0.57l-0.38-1.02c-0.03-0.09-0.08-0.17-0.13-0.25l-0.71-1.07l-0.51-1.03
                  c-0.07-0.14-0.16-0.26-0.27-0.37l-0.83-0.76l-0.48-0.92l-0.34-0.95l-0.17-1.16c-0.02-0.12-0.06-0.24-0.1-0.34l-0.58-1.26
                  c-0.08-0.16-0.19-0.31-0.32-0.43l-1.02-0.88l-1-0.77l-1.01-0.88c-0.07-0.07-0.16-0.12-0.25-0.17l-1.03-0.54l-0.39-0.74
                  c-0.1-0.2-0.26-0.37-0.45-0.49l-1.28-0.8c-0.06-0.04-0.12-0.07-0.19-0.1l-0.98-0.39l-0.88-0.69l-2.26-2.28
                  c-0.05-0.05-0.11-0.1-0.16-0.14l-1.03-0.73l-0.75-0.87c-0.02-0.03-0.05-0.06-0.07-0.08l-0.9-0.88l-0.68-0.9
                  c-0.07-0.09-0.14-0.17-0.23-0.23l-1-0.79c-0.06-0.05-0.13-0.1-0.2-0.13l-1.19-0.61c-0.21-0.11-0.45-0.15-0.69-0.13l-1.38,0.13
                  c-0.16,0.01-0.31,0.06-0.45,0.13l-0.94,0.48l-0.58,0.06l-0.46-0.48l-1.31-1.64l-0.02-0.84l0.21-1.23c0.03-0.16,0.03-0.32-0.01-0.47
                  l-0.3-1.38l-0.29-1.05l0.19-0.92c0.01-0.05,0.02-0.09,0.02-0.14l0.12-1.22c0.01-0.04,0.01-0.09,0.01-0.13l-0.01-1.35
                  c0-0.05,0-0.09-0.01-0.14l-0.14-1.19l-0.02-0.48l1.81-0.95c0.26-0.14,0.47-0.37,0.58-0.65l0.5-1.27l2.68-2.8
                  c0.26-0.28,0.39-0.65,0.34-1.03l-0.25-1.92c-0.02-0.18-0.09-0.35-0.18-0.5l-0.61-0.97l-0.3-1.4c-0.01-0.07-0.04-0.14-0.07-0.21
                  l-0.5-1.2c-0.02-0.05-0.04-0.1-0.07-0.15l-0.38-0.66l0.09-0.48l0.69-0.83c0.3-0.36,0.37-0.86,0.19-1.3l-0.54-1.25
                  c-0.02-0.03-0.03-0.06-0.05-0.09l-0.3-0.56l0.53-0.85c0.09-0.14,0.15-0.3,0.18-0.47l0.2-1.29c0.03-0.23,0-0.48-0.1-0.7l-0.46-1.04
                  l-0.17-1.19l0.05-0.62l3.73-2.97c0.05-0.04,0.1-0.09,0.15-0.14l0.87-1.01c0.1-0.12,0.18-0.26,0.23-0.41l0.41-1.18l0.93-0.34
                  c0.08-0.02,0.15-0.06,0.22-0.1l1.24-0.76c0.18-0.11,0.33-0.27,0.43-0.45l0.79-1.37l0.49-0.6l0.8-0.23c0.45-0.12,0.79-0.48,0.89-0.93
                  l0.27-1.21c0.01-0.05,0.02-0.1,0.02-0.15l0.12-1.19l0.18-1.23c0-0.04,0.01-0.09,0.01-0.14l0.05-1.3l0.16-0.95l0.49-1.01
                  c0.07-0.15,0.11-0.31,0.12-0.48l0.05-1.09l0.26-0.48l0.46-0.17l1.01,0.08c0.16,0.01,0.32-0.01,0.47-0.06l1.21-0.38
                  c0.14-0.04,0.27-0.11,0.38-0.19l0.71-0.53l0.16,0.13c0.15,0.12,0.33,0.21,0.52,0.25l1.26,0.28c0.21,0.05,0.43,0.04,0.64-0.03
                  l1.19-0.37c0.11-0.03,0.22-0.08,0.32-0.15l2.14-1.41c0.35-0.23,0.56-0.61,0.56-1.03l0.05-2.83l0.34-0.67l0.86-0.67
                  c0.15-0.11,0.26-0.25,0.35-0.41l0.58-1.1c0.3-0.57,0.11-1.29-0.44-1.64l-0.4-0.25l0.03-0.37l0.39-0.86l1.71-1.53
                  c0.1-0.09,0.18-0.2,0.25-0.31l0.04-0.07c0.15,0.08,0.31,0.14,0.48,0.16l1.12,0.15l2.3,0.67c0.34,0.1,0.7,0.05,1-0.13
                  c0.3-0.19,0.51-0.49,0.58-0.84l0.18-0.93l0.59-0.69l0.9-0.66c0.13-0.1,0.24-0.22,0.33-0.36l0.54-0.89l0.81-0.57
                  c0.29-0.21,0.48-0.54,0.51-0.91l0.1-1.1l0.15-0.48l0.94,0.64c0.07,0.05,0.15,0.1,0.23,0.13l1.24,0.5c0.11,0.04,0.22,0.07,0.34,0.08
                  l1.06,0.12l1.63,1.35c0.08,0.06,0.17,0.12,0.27,0.17l1.02,0.51l0.93,0.77c0.32,0.27,0.76,0.36,1.16,0.24l1.23-0.38l0.47-0.04
                  l0.52,0.66c0.08,0.11,0.19,0.2,0.3,0.28c-0.05,0.01-0.1,0.03-0.14,0.05l-1.14,0.49c-0.27,0.11-0.48,0.31-0.61,0.57l-0.61,1.14
                  c-0.07,0.14-0.12,0.29-0.13,0.44l-0.14,1.26c-0.05,0.41,0.11,0.82,0.42,1.09l0.81,0.69l0.55,0.95c0.08,0.13,0.17,0.24,0.29,0.34
                  l0.27,0.22l-0.22,0.53c-0.1,0.21-0.13,0.44-0.1,0.67l0.17,1.22c0.01,0.09,0.03,0.17,0.06,0.26l0.34,0.91l-0.21,0.88
                  c-0.11,0.42,0.02,0.86,0.32,1.17l1.45,1.49l-1.05,0.82c-0.16,0.12-0.29,0.28-0.38,0.46l-1.89,3.93c-0.03,0.05-0.06,0.11-0.07,0.17
                  l-0.23,0.73l-0.65,0.39c-0.19,0.12-0.35,0.29-0.46,0.49l-0.57,1.1c-0.16,0.32-0.18,0.68-0.06,1.01c0.12,0.33,0.37,0.59,0.7,0.73
                  l1.29,0.52l1.07,0.37l0.95,0.58c0.2,0.12,0.41,0.19,0.64,0.19l2.33,0.07l1.18,0.3c0.06,0.02,0.12,0.03,0.18,0.03l1.22,0.13
                  l0.88,0.25l0.75,0.76c0.07,0.08,0.15,0.14,0.24,0.2l1.09,0.66c0.14,0.08,0.29,0.14,0.45,0.16l2.65,0.43
                  c0.21,0.04,0.42,0.02,0.62-0.05l1.11-0.4l0.66-0.02l0.71,0.6l1.1,0.82c0.19,0.14,0.41,0.23,0.64,0.25l0.77,0.06l0.23,0.49
                  c0.01,0.04,0.03,0.07,0.05,0.09l0.56,0.98l0.05,0.95l-0.04,1.31c-0.01,0.32,0.1,0.63,0.32,0.87l0.95,1.07
                  c0.13,0.15,0.3,0.27,0.5,0.34l1.16,0.43l0.86,0.52l0.78,0.8l0.62,0.74l0.29,0.8l-0.01,0.64l-1.16,1.78c-0.1,0.15-0.16,0.32-0.19,0.5
                  l-0.18,1.23c-0.01,0.13-0.01,0.25,0.01,0.38l0.14,0.89l-0.39,0.77c-0.29,0.56-0.12,1.24,0.39,1.59c0.51,0.36,1.21,0.29,1.64-0.17
                  l1.03-1.11c0.09-0.1,0.16-0.21,0.22-0.34l0.51-1.13c0.05-0.11,0.08-0.23,0.1-0.36l0.13-1l0.52-0.81c0.02-0.03,0.04-0.06,0.05-0.09
                  l0.62-1.14l0.37-0.46l0.58-0.01l0.99,0.09l0.5,0.28l0.35,0.82c0.04,0.08,0.08,0.16,0.13,0.23l0.74,1.04c0.08,0.12,0.18,0.22,0.3,0.3
                  l1.26,0.89c0.16,0.12,0.36,0.19,0.56,0.22l0.99,0.13l0.79,0.55c0.04,0.03,0.08,0.06,0.12,0.08l1.25,0.68
                  c0.17,0.09,0.36,0.14,0.55,0.15v0.09c0,0.07,0,0.13,0.01,0.19l0.22,1.77c0.05,0.4,0.3,0.76,0.66,0.95l1.34,0.69l2.23,1l0.64,1.04
                  l0.29,0.89l0.22,3.45l-0.27,1.07c-0.07,0.26-0.05,0.52,0.04,0.76l0.95,2.55c0.06,0.17,0.17,0.33,0.3,0.46l0.75,0.76l0.45,0.94
                  c0.05,0.1,0.11,0.19,0.17,0.27l0.99,1.16c0.16,0.19,0.38,0.33,0.62,0.4l1.45,0.4c0.14,0.03,0.29,0.05,0.44,0.04l1.3-0.11
                  c0.1-0.01,0.2-0.04,0.3-0.07l1.68-0.58l1.51-0.38l2.54,0.34c0.31,0.04,0.62-0.03,0.88-0.2l1.17-0.77c0.07-0.04,0.14-0.1,0.2-0.16
                  l1.09-1.1l0.74-0.37l0.65,0.17l1.17,0.74c0.07,0.04,0.15,0.08,0.23,0.11l0.94,0.36l0.47,0.64l0.51,1.05
                  c0.17,0.35,0.49,0.6,0.87,0.68l0.83,0.18l0.52,0.61c0.15,0.17,0.35,0.3,0.57,0.37l0.63,0.2l0.06,0.17l-0.35,0.46
                  c-0.24,0.3-0.32,0.69-0.24,1.05l0.21,0.88l-0.25,0.86l-0.43,1.16c-0.12,0.31-0.1,0.65,0.04,0.96l0.45,0.98l0.46,2.31l-0.24,1.03
                  c-0.01,0.06-0.02,0.13-0.03,0.19l-0.09,1.27c0,0.07,0,0.15,0.01,0.22l0.24,2.39l0.02,1.18c0,0.07,0.01,0.13,0.02,0.2l0.22,1.24
                  c0.01,0.04,0.02,0.08,0.03,0.12l0.35,1.24c0.02,0.06,0.04,0.13,0.07,0.19l0.62,1.33c0.04,0.08,0.09,0.17,0.15,0.24l0.96,1.23
                  c0.13,0.17,0.31,0.31,0.51,0.39l2.34,0.95c0.24,0.09,0.5,0.12,0.74,0.06l1.75-0.39c0.1,0.15,0.24,0.28,0.4,0.37l2.51,1.39
                  c0.14,0.07,0.29,0.12,0.45,0.14l1.24,0.15c0.08,0.01,0.17,0.01,0.25,0.01l0.77-0.07l0.53,0.42l0.98,1c0.05,0.05,0.12,0.11,0.18,0.15
                  l1.44,0.99l1.12,0.71c0.11,0.06,0.22,0.11,0.33,0.14l1.22,0.34c0.04,0.01,0.08,0.02,0.12,0.03l1.2,0.22l1.09,0.67
                  c0.2,0.13,0.43,0.19,0.66,0.19c0.14,0,0.29-0.03,0.43-0.08l1.31-0.48c0.17-0.06,0.32-0.16,0.44-0.28l0.03,0.02
                  c0.14,0.11,0.29,0.19,0.46,0.24l1.57,0.42l1.06,0.45l2.16,1.15c0.32,0.17,0.7,0.2,1.04,0.06l0.88-0.34l1.11,0.15l1.13,0.31
                  c0.09,0.03,0.18,0.04,0.27,0.05l1.28,0.07c0.11,0,0.21,0,0.31-0.02l0.61-0.12l0.63,0.56c0.26,0.23,0.6,0.34,0.95,0.3l0.94-0.09
                  l0.5,0.22l0.25,0.52l0.15,1.03c0.02,0.1,0.04,0.19,0.08,0.28l0.48,1.2c0.03,0.09,0.08,0.18,0.14,0.26l0.74,1.06
                  c0.03,0.04,0.07,0.09,0.11,0.13l0.63,0.68l0.12,0.87c0.01,0.05,0.02,0.11,0.04,0.16l0.33,1.19c0.06,0.23,0.19,0.44,0.36,0.6
                  l0.92,0.83c0.05,0.04,0.09,0.08,0.14,0.11l1.14,0.76l0.98,0.59l0.79,0.56l0.35,0.72l0.2,1L1038.2,391.52z"/>
                <title>Rožňava</title>
              </Link>

              {/* Revuca - banskobb*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Revúca")}>
                <path style={{ fill: crimesInRegionBanskobystricky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionBanskobystricky[3].crimePercent)] ? crimesInRegionBanskobystricky[3].color : "#D3D3D3" }}
                  d="M944.17,458.2l-0.42,0.48l-0.68,0.56l-2.25,0.62c-0.22,0.07-0.42,0.19-0.57,0.36l-0.69,0.77l-0.9,0.54
                  c-0.24,0.14-0.43,0.36-0.53,0.63l-0.97,2.53l-0.64,0.85l-0.87,0.96c-0.1,0.11-0.17,0.23-0.23,0.36l-0.29,0.71l-0.72,0.31
                  c-0.31,0.13-0.56,0.39-0.68,0.7l-0.4,1.05l-0.65,0.6l-1.04,0.68c-0.11,0.08-0.21,0.17-0.3,0.28l-0.25,0.32l-0.3,0.05l-0.85,0.05
                  l-0.47-0.4l-0.71-1.01c-0.19-0.26-0.47-0.44-0.79-0.5l-1.24-0.23c-0.18-0.03-0.36-0.03-0.54,0.02l-2.72,0.7
                  c-0.18,0.05-0.35,0.13-0.49,0.25l-0.95,0.79c-0.06,0.05-0.11,0.1-0.16,0.16l-0.5,0.6l-0.89,0.12c-0.26,0.03-0.51,0.15-0.7,0.33
                  l-0.91,0.86c-0.29,0.28-0.43,0.69-0.38,1.09l0.19,1.29l-0.18,0.83l-0.4,0.75l-0.68,0.54l-1.12,0.46c-0.1,0.05-0.2,0.11-0.29,0.18
                  l-0.76,0.59l-4.44,0.64l-1.55-0.04c-0.08,0-0.15,0-0.23,0.02l-1.33,0.21l-0.88,0.19l-0.74-0.5c-0.22-0.15-0.47-0.23-0.75-0.21
                  l-2.91,0.09l-1.03-0.21l-1.36-0.42l-1.08-0.27l-0.27-0.19l0.08-0.44c0.01-0.05,0.01-0.1,0.02-0.15l0.08-1.31l0.02-1.24
                  c0-0.07,0-0.13-0.01-0.2l-0.18-1.18l-0.12-1.22c-0.05-0.58-0.5-1.05-1.08-1.12l-1.27-0.16c-0.25-0.03-0.51,0.01-0.73,0.13
                  l-0.73,0.37l-0.72-0.19c-0.03-0.01-0.06-0.02-0.09-0.03l-2.16-0.44l-0.59-0.61c-0.09-0.1-0.19-0.18-0.31-0.24l-0.94-0.5l-0.75-0.75
                  c-0.05-0.05-0.1-0.1-0.16-0.14l-0.54-0.38l-0.01-0.52l0.08-1.63l-0.03-1.3c0-0.08-0.01-0.17-0.03-0.25l-0.28-1.21
                  c-0.12-0.51-0.55-0.9-1.07-0.96l-1.15-0.14l-0.98-0.38l-1.1-0.59c-0.07-0.04-0.14-0.07-0.22-0.09l-0.77-0.24l-0.27-0.47l-0.04-0.93
                  c-0.01-0.21-0.07-0.42-0.18-0.6l-0.66-1.08c-0.12-0.2-0.3-0.36-0.5-0.46l-1.04-0.53l-0.55-0.46l-0.15-0.63l0.15-1.04
                  c0.02-0.15,0.01-0.32-0.03-0.47l-0.31-1.24l0.02-1.07c0-0.08-0.01-0.16-0.02-0.24l-0.22-1.21c-0.01-0.07-0.03-0.13-0.05-0.18
                  l-0.4-1.17c-0.09-0.28-0.28-0.52-0.53-0.67l-1.09-0.66c-0.12-0.07-0.25-0.12-0.39-0.15l-0.94-0.2l-0.68-0.61
                  c-0.1-0.08-0.21-0.15-0.32-0.2l-1.2-0.53l-1.02-0.38l-2.02-1.33l-1.08-0.65c-0.08-0.04-0.17-0.08-0.26-0.11l-1.05-0.33l-1.09-0.61
                  c-0.1-0.06-0.2-0.1-0.31-0.13l-1.06-0.25l-0.79-0.61c-0.13-0.1-0.28-0.17-0.44-0.21l-1.22-0.32c-0.1-0.02-0.19-0.04-0.29-0.04
                  l-0.69-0.02l-0.06-0.08l0.13-0.71l0.48-1.05c0.08-0.17,0.11-0.36,0.11-0.55l-0.03-1.33c-0.01-0.27-0.1-0.54-0.28-0.75l-0.47-0.59
                  l0.15-0.8c0.02-0.11,0.03-0.23,0.01-0.36l-0.11-1.15l0.34-0.77l1.96-3.4l0.77-1.21c0.19-0.29,0.25-0.65,0.16-0.98
                  c-0.09-0.34-0.31-0.63-0.62-0.79l-1.08-0.59l-0.76-0.49l-0.47-0.84c-0.15-0.29-0.41-0.5-0.72-0.59l-1.38-0.43l-0.99-0.25l-0.94-0.69
                  c-0.36-0.26-0.82-0.31-1.22-0.14l-1.15,0.48c-0.08,0.03-0.15,0.07-0.22,0.12l-0.96,0.65l-1.13,0.53c-0.21,0.1-0.4,0.26-0.52,0.46
                  l-0.49,0.76l-0.8,0.3c-0.45,0.17-0.76,0.58-0.8,1.06l-0.27,2.58l-0.26,1.04l-0.32,0.91c-0.26-0.21-0.6-0.32-0.94-0.27l-0.99,0.12
                  l-0.84-0.31l-0.88-0.39l-0.61-0.74l-0.61-0.95l0.08-0.04c0.51-0.28,0.76-0.87,0.6-1.43l-0.26-0.97l0.28-2.38
                  c0.02-0.15,0.01-0.29-0.02-0.44l-0.23-1.02l0.12-1.04c0.01-0.04,0.01-0.09,0.01-0.13l0.59,0.56c0.16,0.16,0.36,0.27,0.58,0.32
                  l1.25,0.29c0.13,0.03,0.27,0.04,0.4,0.02l1.35-0.13c0.28-0.02,0.54-0.14,0.74-0.33l1.01-0.96l0.17-0.12l0.25,0.2
                  c0.44,0.36,1.06,0.37,1.52,0.04c0.46-0.34,0.63-0.94,0.42-1.47l-0.46-1.17c-0.06-0.18-0.17-0.33-0.3-0.46l-0.55-0.51l-0.02-0.73
                  c-0.01-0.26-0.1-0.52-0.26-0.73l-0.69-0.88l-0.47-1.04c-0.02-0.04-0.05-0.08-0.07-0.13l-0.53-0.87l-0.2-1.06
                  c-0.05-0.21-0.14-0.41-0.28-0.57l-0.8-0.94c-0.19-0.22-0.44-0.37-0.73-0.42l-1.12-0.2l-1.01-0.35l-0.49-0.29l-0.14-0.63l-0.12-1.2
                  c-0.04-0.5-0.38-0.92-0.86-1.07l-1.22-0.39c-0.15-0.05-0.29-0.06-0.45-0.06l-1.32,0.08l-0.74,0.09l-0.3-0.23l-0.24-0.63l0.02-1.03
                  c0.01-0.21-0.04-0.42-0.14-0.61l-0.5-0.95l-0.28-1.21c-0.01-0.04-0.02-0.08-0.03-0.12l-0.47-1.41c-0.03-0.07-0.06-0.13-0.09-0.2
                  l-0.62-1.14c-0.21-0.38-0.6-0.63-1.04-0.65l-2.24-0.08l-0.56-0.31l-0.35-0.66l-0.13-1.1c-0.04-0.36-0.24-0.69-0.54-0.89
                  c-0.31-0.2-0.68-0.26-1.03-0.16l-1.16,0.32l-0.64,0.02l1.08-0.29c0.47-0.13,0.83-0.51,0.91-0.99l0.23-1.29l0.16-1.22
                  c0.03-0.23-0.01-0.47-0.11-0.69l-0.53-1.14c-0.04-0.1-0.1-0.19-0.17-0.27l-0.67-0.81l-0.4-1c-0.1-0.26-0.28-0.47-0.52-0.61
                  l-0.98-0.59l-0.77-0.78c-0.13-0.14-0.28-0.24-0.46-0.3l-0.97-0.36l-0.52-0.43l-0.5-1.8l0.75-1.93c0.05-0.12,0.08-0.25,0.09-0.38
                  l0.1-1.38c0.03-0.43-0.17-0.85-0.52-1.1l-2.14-1.58c-0.07-0.05-0.15-0.1-0.23-0.14l-0.9-0.41l-0.28-0.36l0.06-0.36l0.57-0.67
                  c0.07-0.09,0.13-0.18,0.17-0.28l0.53-1.13c0.12-0.25,0.15-0.54,0.09-0.81l-0.32-1.4c-0.02-0.08-0.04-0.15-0.08-0.22l-0.2-0.47
                  l0.33-0.44c0.04-0.05,0.07-0.11,0.11-0.17l0.64-1.21c0.1-0.19,0.15-0.41,0.14-0.63l-0.06-1.51c-0.02-0.4-0.22-0.76-0.55-0.99
                  l-1.14-0.76l-0.76-1.05c-0.17-0.24-0.41-0.4-0.69-0.48l-1.07-0.28l-1.74-1.77c-0.05-0.05-0.11-0.1-0.16-0.14l-0.99-0.7l-0.28-0.89
                  c-0.19-0.6-0.79-0.96-1.41-0.85l-1.25,0.22c-0.19,0.03-0.37,0.11-0.53,0.23l-0.91,0.68l-0.12,0.06l0.01-0.16l0.62-0.49
                  c0.15-0.12,0.26-0.27,0.35-0.44l0.54-1.13l0.98-0.83c0.29-0.24,0.46-0.62,0.44-1l-0.07-1.6l-0.15-1.27
                  c-0.02-0.22-0.11-0.43-0.25-0.61l-0.47-0.62l0.03-0.64l0.33-0.99c0.07-0.2,0.09-0.42,0.05-0.63l-0.51-2.75
                  c-0.01-0.04-0.01-0.07-0.02-0.11l-0.75-2.6c-0.03-0.08-0.06-0.15-0.1-0.23l-0.83-1.53c-0.19-0.35-0.54-0.58-0.93-0.64l-1.16-0.16
                  l-1.02-0.42l-1.01-0.76c-0.1-0.08-0.22-0.14-0.34-0.18l-1.51-0.53c-0.12-0.04-0.26-0.07-0.39-0.07l-1.45-0.02
                  c-0.12,0-0.24,0.01-0.35,0.04l-1.2,0.33l-1.09-0.24l-1.12-0.38c-0.12-0.04-0.25-0.07-0.37-0.07l-1.05-0.03l-1.2-1.59
                  c-0.12-0.16-0.28-0.29-0.46-0.38l-0.99-0.47l-0.79-0.95c-0.12-0.15-0.28-0.27-0.46-0.35l-1.55-0.67l-0.96-0.56l-0.05-1.55l0.05-1.08
                  l0.48-0.64l0.32-0.03l2.09,0.94l1.11,0.53c0.18,0.08,0.37,0.14,0.57,0.12l0.73-0.02l0.35,0.5c0.24,0.34,0.64,0.55,1.05,0.53
                  c0.42-0.01,0.81-0.23,1.03-0.58l0.72-1.15l0.8-0.99l0.97-0.73l0.76-0.29l0.4,0.05l0.66,0.86c0.17,0.23,0.42,0.39,0.69,0.45
                  l1.75,0.43c0.14,0.04,0.29,0.05,0.43,0.03l2.38-0.25l3.22,0.48c0.62,0.09,1.22-0.3,1.39-0.92l0.22-0.83l2.49-0.06
                  c0.2-0.01,0.39-0.06,0.56-0.15l1.11-0.61c0.13-0.07,0.24-0.17,0.34-0.28l0.59-0.67l0.9-0.21c0.11-0.03,0.21-0.07,0.31-0.12
                  l3.64-1.97c0.31-0.18,0.54-0.47,0.62-0.81l0.31-1.33l0.11-0.24l0.35,0.04c0.24,0.02,0.5-0.03,0.71-0.15l1.35-0.75l1.04-0.65
                  l0.69-0.34l0.71,0.17l0.93,0.53c0.17,0.1,0.37,0.15,0.58,0.16l0.82,0.02l-0.2,0.71c-0.09,0.36-0.03,0.75,0.19,1.05
                  c0.22,0.31,0.56,0.5,0.93,0.53l1.45,0.1c0.13,0.01,0.26-0.01,0.38-0.03l1.29-0.31c0.15-0.04,0.28-0.1,0.4-0.18l1.11-0.74
                  c0.05-0.03,0.09-0.06,0.13-0.09l0.79-0.69l0.75-0.16l0.97,0.29c0.26,0.07,0.52,0.07,0.77-0.02l0.83-0.29l0.14,0.04l0.06,0.38
                  l0.01,1.15c0,0.1,0.01,0.21,0.04,0.31l0.31,1.15l0.25,1.22c0.07,0.35,0.29,0.65,0.6,0.83l1.1,0.64c0.07,0.04,0.13,0.07,0.2,0.09
                  l1.21,0.44c0.04,0.02,0.07,0.03,0.11,0.04l0.97,0.26l0.74,0.61c0.17,0.13,0.37,0.23,0.58,0.26l1.31,0.22
                  c0.2,0.04,0.4,0.02,0.58-0.04l1.31-0.41c0.12-0.03,0.23-0.09,0.33-0.16l1.01-0.69l1.02-0.6l0.65-0.25l0.49,0.17l0.55,0.71l0.75,1.13
                  c0.11,0.17,0.27,0.31,0.45,0.41l1.12,0.6c0.38,0.21,0.84,0.2,1.22-0.02l1.06-0.62c0.17-0.1,0.32-0.24,0.42-0.41l0.72-1.12l0.68-0.87
                  l2.01-1.94l0.11,0.06l0.52,0.81c0.03,0.04,0.06,0.09,0.1,0.13l0.8,0.94c0.26,0.3,0.64,0.46,1.04,0.44l2.64-0.25
                  c0.04,0,0.09-0.01,0.14-0.02l0.76-0.17h0.01l0.35,0.61l0.42,1.02l0.33,1.5c0.03,0.14,0.08,0.27,0.16,0.39l0.58,0.92l0.13,1.06
                  l-2.44,2.56c-0.11,0.12-0.19,0.25-0.25,0.4l-0.42,1.06l-2.11,1.12c-0.41,0.23-0.66,0.67-0.65,1.14l0.04,1.3c0,0.04,0,0.07,0.01,0.11
                  l0.14,1.18l0.01,1.21l-0.11,1.09l-0.24,1.15c-0.04,0.2-0.04,0.4,0.02,0.6l0.36,1.27l0.23,1.07l-0.19,1.11
                  c-0.02,0.08-0.02,0.16-0.02,0.24l0.03,1.36c0.01,0.26,0.1,0.52,0.26,0.73l0.82,1.07l0.85,1.04l0.94,0.99
                  c0.27,0.28,0.65,0.42,1.04,0.38l1.43-0.15c0.16-0.01,0.31-0.06,0.44-0.13l0.93-0.48l0.77-0.07l0.76,0.39l0.78,0.61l0.64,0.85
                  c0.04,0.05,0.08,0.1,0.13,0.14l0.92,0.9l0.81,0.95c0.07,0.08,0.14,0.15,0.22,0.21l1.07,0.76l2.24,2.26
                  c0.04,0.04,0.08,0.07,0.12,0.11l1.08,0.84c0.09,0.07,0.19,0.13,0.3,0.17l1.04,0.42l0.9,0.56l0.41,0.79
                  c0.12,0.23,0.31,0.42,0.53,0.54l1.26,0.65l0.95,0.83l1,0.77l0.77,0.67l0.39,0.85l0.16,1.1c0.02,0.08,0.04,0.16,0.06,0.24l0.41,1.16
                  c0.02,0.06,0.05,0.11,0.08,0.17l0.63,1.18c0.06,0.12,0.15,0.23,0.25,0.33l0.81,0.75l0.45,0.9c0.02,0.05,0.05,0.1,0.08,0.14
                  l0.67,1.01l0.45,1.21c0.08,0.21,0.21,0.4,0.39,0.54l0.9,0.71l0.68,0.79l0.37,0.51l-0.2,0.59c-0.02,0.05-0.03,0.11-0.05,0.16
                  l-0.21,1.07l-0.49,0.96c-0.04,0.07-0.07,0.14-0.09,0.21l-0.36,1.21c-0.08,0.28-0.06,0.58,0.05,0.84l0.44,1.06l0.51,2.21l0.04,1.12
                  c0.01,0.19,0.06,0.38,0.15,0.55l1.15,2.17l0.22,0.73l-0.19,0.63l-0.65,0.73c-0.04,0.05-0.08,0.1-0.12,0.16l-0.66,1.04
                  c-0.11,0.18-0.18,0.39-0.19,0.61l-0.06,1.27c-0.03,0.56,0.33,1.07,0.86,1.25l1.3,0.42l1.35,0.36l1.24,0.56l1.33,0.74
                  c0.12,0.07,0.25,0.12,0.39,0.14l1.21,0.22c0.21,0.04,0.42,0.02,0.62-0.05l1.22-0.41c0.12-0.04,0.23-0.09,0.33-0.17l0.67-0.48h0.22
                  l0.21,0.31l0.28,1.08c0.07,0.27,0.23,0.51,0.45,0.67l1.05,0.8c0.15,0.12,0.33,0.2,0.51,0.23l1.16,0.24l0.76,0.2l0.11,0.17
                  l-0.19,0.62l-0.43,1.09l-1.15,2.45c-0.14,0.3-0.16,0.66-0.04,0.97l0.32,0.84l-0.15,0.9c-0.03,0.17-0.02,0.35,0.02,0.52l0.14,0.53
                  l-0.3,0.25l-0.99,0.66c-0.18,0.12-0.33,0.28-0.43,0.48l-0.62,1.23l-0.52,1.27l-0.48,1.36c-0.04,0.13-0.06,0.25-0.07,0.38l-0.03,1.18
                  l-0.25,1.03l-0.39,1.04l-0.57,1.16c-0.09,0.18-0.14,0.39-0.13,0.6l0.05,1.41l0.19,2.16l-0.15,0.17l-0.83,0.1
                  c-0.49,0.05-0.9,0.39-1.05,0.86l-0.4,1.27l-0.58,2.25l-0.32,0.6l-0.93,0.4l-1.02,0.5l-1.12,0.3c-0.51,0.14-0.87,0.57-0.92,1.09
                  l-0.12,1.17l-0.25,1.31l-0.16,1.18l-0.22,1.04l-0.51,1.01c-0.15,0.3-0.17,0.65-0.06,0.96l0.42,1.23c0.08,0.23,0.22,0.43,0.41,0.58
                  l0.93,0.74l0.69,0.71l0.33,0.59l-0.17,0.72c-0.01,0.03-0.02,0.06-0.03,0.1l-0.19,1.23c-0.02,0.18-0.01,0.37,0.04,0.55l0.26,0.85
                  l-0.32,0.83c-0.13,0.34-0.1,0.72,0.07,1.04l0.63,1.17c0.07,0.14,0.17,0.26,0.29,0.37l1.1,0.92c0.09,0.08,0.2,0.14,0.3,0.19l1.13,0.5
                  c0.1,0.05,0.21,0.08,0.32,0.1l1.26,0.19l2.54,0.26c0.15,0.01,0.32-0.01,0.47-0.06l0.11-0.03l-1.14,2.31
                  c-0.3,0.61-0.06,1.33,0.53,1.65l1.32,0.71c0.16,0.09,0.34,0.14,0.52,0.15l1.28,0.07c0.06,0,0.13,0,0.19-0.01l2.88-0.22
                  c0.09-0.01,0.19-0.02,0.28-0.05l0.95-0.29l0.88,0.19c0.05,0.01,0.09,0.02,0.14,0.02l1.29,0.14c0.49,0.06,0.95-0.18,1.2-0.59
                  l0.54-0.89l0.69-0.59l0.32-0.18l1.35,0.85l0.61,0.65l0.2,0.91c0.06,0.29,0.22,0.55,0.46,0.73l1.14,0.88
                  c0.22,0.17,0.49,0.26,0.76,0.26c0.09,0,0.18-0.01,0.27-0.03l1.25-0.28c0.08-0.02,0.15-0.04,0.23-0.07l1.15-0.5
                  c0.15-0.07,0.29-0.16,0.4-0.28l0.63-0.63l0.85-0.22c0.43-0.11,0.77-0.44,0.89-0.87l0.32-1.12l0.34-0.91l0.01-0.01l0.96,0.57
                  c0.39,0.22,0.87,0.23,1.26,0.01l0.71-0.4l0.48,0.28c0.43,0.26,0.96,0.24,1.37-0.05l0.91-0.66l1.18-0.52l2.02-0.6l0.45,0.33
                  l0.47,0.75l-0.09,1.01h0.04c0.01,0.29,0.12,0.57,0.32,0.79l0.78,0.88l0.37,0.82L944.17,458.2z"/>
                <title>Revúca</title>
              </Link>

              {/* Poprad */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Poprad")}>
                <path style={{ fill: crimesInRegionPresovsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionPresovsky[3].crimePercent)] ? crimesInRegionPresovsky[3].color : "#D3D3D3" }}
                  d="M940.3,231.59l-0.02,0.03c-0.06,0.1-0.11,0.21-0.15,0.33l-0.42,1.42c-0.03,0.11-0.05,0.24-0.05,0.36l0.01,1.02
                  c-0.05-0.07-0.11-0.13-0.17-0.19l-0.91-0.84c-0.39-0.36-0.96-0.43-1.43-0.19c-0.46,0.24-0.73,0.75-0.66,1.27l0.36,2.66l-0.01,0.83
                  l-0.49,0.74c-0.11,0.16-0.18,0.34-0.2,0.53l-0.18,1.37c-0.02,0.14-0.01,0.29,0.02,0.43l0.08,0.35l-0.61-0.42
                  c-0.27-0.19-0.61-0.26-0.93-0.2c-0.33,0.06-0.62,0.25-0.81,0.52l-1.43,2.07c-0.07,0.11-0.13,0.23-0.17,0.35l-0.31,1.04l-0.64,1.03
                  l-0.28,0.5h-0.6c-0.21,0-0.41,0.06-0.6,0.16l-0.6,0.33l-0.91-0.81c-0.36-0.33-0.89-0.41-1.34-0.21l-1.52,0.68l-2,0.59
                  c-0.31,0.09-0.58,0.3-0.74,0.58l-0.54,0.97l-0.6,0.59l-1.07,0.49l-0.91,0.23l-1.03-0.07c-0.31-0.01-0.59,0.07-0.83,0.25l-0.92,0.69
                  l-1.09,0.49c-0.05,0.02-0.1,0.05-0.15,0.08l-1.17,0.74l-1.04,0.76c-0.22,0.16-0.37,0.38-0.45,0.63l-0.32,1l-0.67,0.46
                  c-0.1,0.07-0.2,0.15-0.27,0.25l-0.35,0.43l-0.53-0.08c-0.11-0.01-0.22-0.01-0.32,0l-1.3,0.16c-0.27,0.03-0.51,0.15-0.71,0.33
                  l-0.96,0.9c-0.03,0.03-0.06,0.07-0.1,0.11l-0.67,0.8l-0.88,0.58c-0.18,0.12-0.33,0.28-0.43,0.48l-0.46,0.93l-0.74,0.78
                  c-0.1,0.09-0.17,0.2-0.23,0.32l-0.56,1.18l-0.34,0.79l-0.7,0.46l-1.11,0.6c-0.19,0.1-0.34,0.24-0.46,0.42l-0.67,1.03
                  c-0.05,0.07-0.08,0.15-0.12,0.23l-0.47,1.22c-0.03,0.08-0.05,0.15-0.06,0.23l-0.22,1.24c-0.07,0.41,0.06,0.82,0.35,1.11l0.89,0.88
                  c0.41,0.4,1.03,0.47,1.52,0.18l0.11,0.18c0.17,0.29,0.46,0.5,0.79,0.58l1.37,0.32c0.29,0.07,0.59,0.03,0.86-0.11l1.07-0.56
                  l0.92-0.42l0.71-0.04l0.71,0.38l0.93,0.73l1.05,1.86l0.23,0.7l-0.34,1.09c-0.02,0.09-0.04,0.17-0.05,0.26l-0.05,0.66l-0.55,0.38
                  c-0.13,0.1-0.25,0.22-0.34,0.37l-0.55,0.9l-0.82,0.6c-0.08,0.06-0.15,0.12-0.21,0.2l-0.9,1.05c-0.12,0.14-0.21,0.3-0.26,0.48
                  l-1.07-0.31c-0.06-0.02-0.12-0.03-0.19-0.04l-0.93-0.12l-0.8-0.55c-0.29-0.19-0.64-0.25-0.98-0.18c-0.34,0.08-0.63,0.29-0.8,0.6
                  l-0.61,1.05l-1.78,1.6c-0.12,0.11-0.22,0.25-0.29,0.4l-0.59,1.29c-0.06,0.13-0.09,0.27-0.11,0.42l-0.11,1.34
                  c-0.03,0.46,0.19,0.9,0.58,1.15l-0.72,0.56c-0.15,0.11-0.27,0.26-0.36,0.43l-0.59,1.18c-0.08,0.16-0.12,0.34-0.13,0.52l-0.05,2.49
                  l-1.44,0.95l-0.7,0.22l-0.65-0.15l-0.74-0.6l-0.35,0.43l-0.53-0.8c-0.03-0.05-0.06-0.09-0.09-0.12l-0.85-0.99
                  c-0.06-0.07-0.12-0.13-0.19-0.18l-1.03-0.79c-0.06-0.05-0.12-0.09-0.18-0.12l-0.93-0.48l-0.73-0.74c-0.09-0.09-0.2-0.17-0.32-0.23
                  l-1.13-0.58c-0.19-0.1-0.41-0.15-0.63-0.14l-1.12,0.06l-0.87-0.15l-0.76-0.46l-1.81-1.64c-0.13-0.12-0.28-0.21-0.45-0.27l-1.12-0.36
                  l-0.96-0.54c-0.16-0.09-0.35-0.15-0.54-0.16l-1.36-0.07l-1.04-0.16l-2.48-1.36c-0.08-0.04-0.16-0.07-0.24-0.1l-1.02-0.31l-2.41-2.38
                  c-0.29-0.28-0.7-0.41-1.09-0.34l-1.27,0.22c-0.38,0.06-0.7,0.29-0.89,0.63l-0.85,1.56l-0.71,1.45l-0.74,0.93
                  c-0.03,0.02-0.05,0.05-0.06,0.08l-1.13,1.69l-9.74-1.61l-1.42-0.31l-1.3-0.22l-1.49-0.17l-1.26-0.08l-1.16-0.3
                  c-0.06-0.02-0.14-0.03-0.21-0.04l-2.55-0.21l-1.54-0.43c-0.14-0.04-0.3-0.06-0.44-0.05l-1.85,0.15c-0.05,0.01-0.1,0.01-0.14,0.02
                  l-1.25,0.25l-1.13,0.03l-0.88-0.23l-0.76-0.66c-0.18-0.16-0.4-0.26-0.63-0.3l-3.71-0.56c-0.16-0.02-0.32-0.01-0.48,0.02l-1.55,0.37
                  l-1.38-0.1l-1.4-0.16l-2.19-0.86l1.97-0.09c0.07-0.01,0.14-0.01,0.21-0.03l1.88-0.42c0.09-0.02,0.17-0.05,0.25-0.08l3.07-1.43
                  l1.21-0.65c0.07-0.03,0.13-0.07,0.18-0.12l1.43-1.13c0.09-0.07,0.17-0.15,0.24-0.25l0.73-1l0.64-0.71l1.05-0.54
                  c0.34-0.18,0.58-0.5,0.65-0.88l0.18-0.99l0.64-0.8c0.42-0.53,0.34-1.29-0.17-1.73l-1.02-0.87c-0.15-0.13-0.33-0.22-0.52-0.27
                  l-1.21-0.29c-0.19-0.04-0.38-0.04-0.57,0l-1.11,0.26l-1.12,0.18l-0.58-0.01l-0.23-0.46c-0.03-0.06-0.06-0.12-0.1-0.17l-0.71-1.01
                  l-1.51-2.68c-0.04-0.08-0.1-0.15-0.15-0.22l-0.6-0.69l0.24-0.8c0.03-0.08,0.05-0.17,0.05-0.25l0.13-1.39
                  c0.05-0.53-0.23-1.03-0.7-1.25l0.78-1.02c0.25-0.33,0.33-0.76,0.2-1.15l-0.4-1.21c-0.05-0.15-0.12-0.28-0.22-0.39l-1.12-1.39
                  c-0.07-0.09-0.15-0.17-0.24-0.24l-1.57-1.12L826,252.5l-0.34-1.27c-0.03-0.12-0.08-0.23-0.15-0.33l-0.88-1.44l-0.89-1.22
                  c-0.07-0.09-0.15-0.18-0.24-0.25l-0.65-0.5l-0.28-1.2c-0.05-0.18-0.13-0.34-0.24-0.49l-1.2-1.51c-0.1-0.12-0.21-0.22-0.35-0.3
                  l-1.48-0.87c-0.1-0.06-0.21-0.1-0.32-0.13l-1.2-0.31l-0.94-1.04c-0.05-0.05-0.11-0.1-0.16-0.15l-1.05-0.81l-0.27-0.23l0.48-0.9
                  c0.03-0.06,0.06-0.13,0.08-0.2l0.19-0.59l0.35-0.09l0.33,0.03l0.3,0.6c0.3,0.59,1.01,0.85,1.62,0.59l1.19-0.52l0.94-0.31h1.05
                  c0.15,0,0.29-0.03,0.43-0.07l0.96-0.35l0.8-0.02l1.01,0.38c0.27,0.1,0.57,0.11,0.84,0.01l1.19-0.39c0.33-0.12,0.6-0.37,0.75-0.69
                  l0.49-1.12c0.06-0.16,0.1-0.33,0.1-0.5v-1.27c0-0.07,0-0.13-0.02-0.2l-0.2-1.25l-0.22-2.1l-0.24-1.54l0.19-0.75l0.71-1.04l0.89-1.16
                  c0.11-0.14,0.19-0.31,0.23-0.49l0.3-1.33c0.01-0.05,0.02-0.11,0.03-0.17l0.08-1.07l0.54-1.2l0.56-1.46
                  c0.06-0.16,0.08-0.32,0.08-0.49l-0.04-1.41c-0.01-0.17-0.05-0.34-0.12-0.5l-0.54-1.13c-0.07-0.13-0.15-0.25-0.26-0.35l-0.75-0.74
                  l-0.04-0.17l0.17-0.06c0.23-0.08,0.43-0.23,0.58-0.43l0.76-1.01c0.04-0.05,0.07-0.11,0.11-0.17l0.62-1.18
                  c0.04-0.08,0.07-0.16,0.1-0.25l0.41-1.5c0.03-0.13,0.05-0.27,0.04-0.4l-0.08-1.36c-0.01-0.14-0.04-0.28-0.09-0.41l-0.29-0.69
                  l0.31-0.7c0.03-0.06,0.05-0.12,0.07-0.18l0.39-1.45l0.23-1.15l0.46-1c0.02-0.05,0.04-0.11,0.06-0.17l0.35-1.21
                  c0.01-0.04,0.02-0.08,0.03-0.12l0.23-1.22c0.04-0.21,0.02-0.42-0.04-0.62l-0.46-1.39c-0.15-0.46-0.55-0.78-1.02-0.85l-2.58-0.34
                  c-0.16-0.02-0.32-0.01-0.47,0.03l-1.21,0.31c-0.13,0.03-0.26,0.09-0.37,0.16l-0.98,0.64l-0.81,0.42l-1.05,0.08
                  c-0.32,0.03-0.62,0.18-0.83,0.42l-0.4,0.45l-0.75-0.21c-0.07-0.02-0.14-0.04-0.21-0.04l-0.44-0.05c0.03-0.25-0.02-0.51-0.14-0.73
                  c-0.16-0.31-0.45-0.53-0.79-0.62l-1.18-0.31c-0.24-0.06-0.48-0.05-0.71,0.02l-1.12,0.37l-0.7,0.13l-0.5-0.29l-0.21-0.59l-0.06-1.16
                  c-0.02-0.4-0.22-0.75-0.55-0.97l-1.06-0.72c-0.5-0.35-1.19-0.28-1.61,0.17l-0.85,0.9c-0.11,0.12-0.2,0.26-0.26,0.41l-0.23,0.62
                  l-0.75,0.17c-0.01,0.01-0.01,0.01-0.02,0.01l-0.17-0.74c-0.04-0.16-0.1-0.31-0.2-0.44l-0.52-0.75l0.03-0.82c0-0.07,0-0.14-0.01-0.21
                  l-0.13-1.02l0.21-0.62l1.59-1.48c0.06-0.06,0.12-0.12,0.17-0.2l0.77-1.05l0.56-0.89l0.54-0.47l2.15-0.33l1.21,0.03
                  c0.58,0.02,1.11-0.39,1.25-0.97l0.27-1.15l0.33-1.02l0.62-0.8c0.19-0.24,0.28-0.54,0.27-0.84l-0.05-0.83l0.3-0.43l1.83-0.93
                  c0.26-0.13,0.46-0.35,0.58-0.61l0.94-2.19c0.23-0.53,0.06-1.16-0.41-1.5s-1.12-0.32-1.56,0.07l-0.87,0.76l-0.81,0.49l-1.07,0.27
                  c-0.08,0.02-0.15,0.04-0.22,0.07l-1.06,0.49l-1.26,0.4c-0.21,0.06-0.4,0.18-0.54,0.34l-1.75,1.88l-1.67,1.61l-0.87,0.65l-0.89,0.31
                  l-1.03-0.08c-0.24-0.01-0.48,0.04-0.69,0.16l-1.31,0.72l-0.97,0.11l-1.21-0.23c-0.08-0.01-0.17-0.02-0.24-0.02l-1.29,0.01
                  c-0.25,0-0.5,0.08-0.71,0.23l-1.01,0.71c-0.21,0.15-0.37,0.36-0.46,0.6l-0.52,1.46l-0.62,0.88c-0.02,0.03-0.05,0.07-0.07,0.1
                  l-1.47,2.7c-0.02,0.05-0.05,0.1-0.07,0.16l-0.15-0.2c-0.07-0.1-0.16-0.18-0.26-0.26l-0.84-0.6l-0.79-1.16l0.3-0.46
                  c0.05-0.07,0.09-0.16,0.12-0.25l0.4-1.08l0.59-0.8l2.7-2.75l1.19-0.64c0.27-0.14,0.48-0.39,0.59-0.68l0.43-1.22l0.36-1.21
                  c0.05-0.15,0.07-0.32,0.05-0.49l-0.14-1.37l-0.22-1.21l-0.09-1.37c-0.01-0.04-0.01-0.08-0.02-0.13l-0.22-1.26
                  c-0.03-0.18-0.1-0.35-0.21-0.5l-0.75-1.07l-0.43-0.78l-0.04-1.07c-0.01-0.18-0.06-0.36-0.14-0.53l-0.59-1.14
                  c-0.19-0.37-0.56-0.62-0.98-0.66l-1.23-0.13c-0.21-0.03-0.44,0.01-0.64,0.1l-0.47,0.21l-0.23-0.18l-0.25-0.44l0.17-0.77l0.34-1.1
                  l0.48-1.24c0.08-0.22,0.1-0.46,0.06-0.69l-0.26-1.31c-0.08-0.37-0.31-0.69-0.64-0.86l-1.09-0.58c-0.16-0.09-0.34-0.14-0.53-0.15
                  l-1-0.05l-0.76-0.59l0.38-0.71l0.92-0.4c0.04-0.02,0.08-0.04,0.12-0.06l1-0.56l1.09-0.4c0.53-0.19,0.86-0.72,0.81-1.28l-0.07-0.79
                  l0.63-0.87c0.16-0.22,0.24-0.49,0.24-0.76l-0.04-1.46c0-0.11-0.02-0.21-0.05-0.32l-0.3-1.05v-0.47l0.39-0.33l1.18-0.44
                  c0.08-0.03,0.16-0.07,0.24-0.11l1.11-0.71l0.85-0.26l1.14,0.1c0.21,0.02,0.41-0.01,0.59-0.08l0.51-0.21l0.47,0.33
                  c0.12,0.09,0.27,0.16,0.42,0.19l1.36,0.33c0.04,0.01,0.07,0.02,0.11,0.02l1.18,0.19l1.17,0.36c0.11,0.04,0.24,0.06,0.36,0.06
                  l1.32,0.01l1.42,0.36l1.22,0.21l1.28,0.28c0.17,0.04,0.34,0.04,0.51,0.01l1.27-0.25c0.07-0.02,0.13-0.04,0.19-0.06l0.51-0.19
                  l0.49,0.38l0.87,1.17c0.1,0.13,0.22,0.24,0.36,0.32l1.14,0.68l1.26,0.86c0.07,0.05,0.13,0.08,0.2,0.11l1.18,0.52
                  c0.05,0.03,0.11,0.04,0.16,0.06l0.41,0.12l0.01,0.14l-0.35,0.99c-0.12,0.35-0.09,0.72,0.09,1.04l0.68,1.18l0.68,1.04
                  c0.02,0.03,0.04,0.07,0.07,0.09l1.56,1.98c0.05,0.07,0.11,0.13,0.17,0.19l0.95,0.8c0.13,0.1,0.27,0.18,0.43,0.23l1.18,0.38
                  c0.04,0.01,0.08,0.02,0.12,0.03l1.1,0.24l1.07,0.54c0.03,0.01,0.06,0.03,0.09,0.04l0.62,0.24l0.2,0.62
                  c0.05,0.15,0.13,0.29,0.23,0.41l0.8,0.95c0.04,0.06,0.09,0.1,0.14,0.15l1.03,0.89c0.07,0.05,0.14,0.1,0.21,0.14l1.11,0.62
                  c0.13,0.07,0.27,0.12,0.42,0.15l0.86,0.13l0.61,0.6c0.07,0.07,0.15,0.13,0.24,0.18l0.9,0.54l0.61,0.79
                  c0.12,0.15,0.28,0.28,0.45,0.36l2.26,1.05c0.24,0.11,0.51,0.14,0.78,0.08l1.16-0.25l1.21-0.19c0.47-0.07,0.86-0.4,1-0.86l0.38-1.22
                  c0.06-0.19,0.07-0.39,0.04-0.59l-0.21-1.16l-0.1-1.17c0-0.09-0.02-0.18-0.05-0.27l-0.36-1.16l-0.16-0.81l0.35-0.82
                  c0.05-0.12,0.08-0.24,0.09-0.37l0.13-1.35l0.04-1.67l0.32-1.08l0.63-1.15c0.02-0.04,0.03-0.08,0.05-0.12l0.88-2.04l0.71-0.65
                  c0.03-0.03,0.07-0.06,0.1-0.09l0.81-0.92c0.16-0.19,0.27-0.43,0.3-0.68l0.15-1.26c0.03-0.26-0.02-0.53-0.15-0.76l-0.46-0.83
                  l-0.01-0.91c0-0.17-0.04-0.33-0.1-0.48l-0.36-0.85l0.08-0.72l0.41-0.99c0.04-0.1,0.06-0.21,0.08-0.32l0.15-1.24l0.25-1.21
                  c0.04-0.17,0.04-0.34,0-0.51l-0.19-0.91l0.15-0.51l0.72-0.59c0.03-0.02,0.05-0.05,0.08-0.07l0.9-0.89c0.06-0.07,0.12-0.14,0.17-0.22
                  l0.67-1.05c0.04-0.06,0.08-0.13,0.11-0.2l0.39-0.97l0.63-0.68l0.9-0.84c0.09-0.08,0.17-0.18,0.24-0.29l1.29-2.31
                  c0.04-0.08,0.08-0.16,0.1-0.25l0.36-1.18c0.02-0.05,0.04-0.11,0.05-0.18l0.17-1.19l0.31-1.13c0.05-0.17,0.06-0.36,0.03-0.54
                  l-0.18-1.04l0.13-0.89l0.46-1.12l0.38-1.18c0.08-0.25,0.08-0.51,0-0.76l-0.19-0.6l0.35-0.47c0.07-0.09,0.12-0.18,0.16-0.28
                  l0.47-1.18c0.09-0.23,0.11-0.5,0.06-0.75l-0.05-0.19l0.15-0.06c0.34-0.13,0.6-0.41,0.72-0.76l0.3-0.88l0.7-0.61
                  c0.21-0.19,0.36-0.44,0.41-0.72l0.1-0.57l0.12,0.33c0.04,0.11,0.1,0.22,0.17,0.32l0.65,0.89l0.24,0.69l-0.21,0.81
                  c-0.12,0.45,0.02,0.91,0.35,1.23c0.33,0.31,0.8,0.42,1.24,0.28l1.25-0.4c0.18-0.06,0.35-0.16,0.49-0.3l3.08-2.99
                  c0.19-0.19,0.31-0.44,0.35-0.7l0.05,0.76c0.02,0.22,0.09,0.43,0.21,0.61l0.61,0.93l0.42,1.09c0.13,0.32,0.38,0.58,0.7,0.71
                  l1.09,0.44l0.88,0.53l0.52,0.72l0.36,0.57l-0.27,0.68c-0.07,0.2-0.1,0.42-0.07,0.64l0.2,1.32c0.04,0.25,0.15,0.48,0.32,0.66l0.93,1
                  c0.1,0.1,0.2,0.18,0.32,0.25l2.52,1.39l0.91,0.72c0.13,0.1,0.29,0.17,0.45,0.22l1.27,0.34c0.22,0.06,0.47,0.05,0.69-0.02l1.28-0.4
                  c0.25-0.07,0.47-0.23,0.62-0.43l0.61-0.79l0.94-0.57c0.12-0.07,0.23-0.16,0.32-0.27l0.83-0.99l0.39-0.36l0.63,0.1
                  c0.03,0.01,0.07,0.01,0.1,0.02l1.3,0.11c0.09,0,0.17,0,0.26-0.01l1.39-0.17l1.16,0.06h0.13l0.94-0.05l0.72,0.46
                  c0.07,0.04,0.15,0.08,0.23,0.11l1.13,0.43l1.24,0.53l1.21,0.42c0.21,0.07,0.43,0.09,0.65,0.05l1.35-0.27l0.93-0.06l1.06,0.42
                  l1.32,0.43c0.14,0.05,0.29,0.06,0.44,0.06l1.26-0.05c0.17-0.01,0.33-0.05,0.48-0.12l0.4-0.18l0.16,1.28
                  c0.01,0.09,0.03,0.17,0.05,0.25l0.26,0.85l-0.36,1.04c-0.04,0.1-0.06,0.2-0.07,0.31l-0.09,1.17l-0.42,1.2
                  c-0.03,0.08-0.05,0.16-0.06,0.25l-0.16,1.22c-0.04,0.34,0.05,0.69,0.27,0.95l0.93,1.12l0.44,0.48l-0.09,0.74
                  c-0.01,0.04-0.01,0.08-0.01,0.12l-0.04,1.54c0,0.09,0,0.18,0.02,0.26l0.24,1.26c0.03,0.14,0.07,0.27,0.14,0.38l0.59,1.05l0.19,1.15
                  c0.02,0.13,0.06,0.25,0.12,0.37l0.5,0.99l0.03,0.92l-0.04,1.33c0,0.08,0,0.17,0.02,0.25l0.22,1.31c0.08,0.53,0.5,0.94,1.03,1.02
                  l0.96,0.16l0.8,0.53c0.29,0.2,0.65,0.26,1,0.17l0.56-0.14l0.41,0.37c0.26,0.25,0.62,0.36,0.99,0.32l1.09-0.13l1.98,0.61l0.54,0.68
                  c0.04,0.05,0.08,0.1,0.13,0.15l0.6,0.55l0.09,1.3l-0.42,0.26c-0.36,0.23-0.58,0.64-0.58,1.07l0.01,1.58c0,0.07,0.01,0.15,0.02,0.23
                  l0.13,0.65l-0.14,0.13l-0.99-0.04l-1.14-0.09l-1.55-0.47c-0.14-0.04-0.27-0.06-0.41-0.05l-1.24,0.05c-0.68,0.03-1.22,0.6-1.2,1.28
                  l0.03,1.25c0.01,0.6,0.46,1.11,1.05,1.2l1.04,0.17l0.91,0.49c0.05,0.03,0.11,0.06,0.17,0.08l1.18,0.42
                  c0.02,0.01,0.05,0.01,0.07,0.02c-0.03,0.13-0.04,0.27-0.03,0.4l0.13,1.36l-0.05,1.14c-0.01,0.18,0.03,0.37,0.1,0.54l0.49,1.15
                  c0.08,0.19,0.21,0.36,0.37,0.49l0.91,0.71l0.95,0.89l0.12,0.12l-0.6-0.18c-0.32-0.09-0.67-0.06-0.96,0.1
                  c-0.29,0.16-0.51,0.44-0.6,0.76l-0.28,0.97l-0.49,0.58l-0.81,0.23c-0.32,0.09-0.6,0.31-0.76,0.6c-0.16,0.3-0.19,0.65-0.09,0.97
                  l0.06,0.19l-0.37-0.17c-0.05-0.02-0.11-0.04-0.17-0.06l-1.01-0.3l-0.74-0.62c-0.3-0.26-0.7-0.36-1.08-0.27l-1.44,0.32
                  c-0.17,0.04-0.32,0.11-0.46,0.21l-1.33,0.96c-0.45,0.32-0.63,0.91-0.45,1.43l0.45,1.27l0.07,0.59l-0.49,0.42
                  c-0.47,0.39-0.58,1.07-0.26,1.59l0.89,1.48c0.04,0.08,0.09,0.15,0.15,0.21l-0.05,0.02c-0.03-0.03-0.06-0.07-0.09-0.1l-0.84-0.92
                  c-0.08-0.09-0.17-0.17-0.28-0.23l-1.07-0.64c-0.1-0.06-0.21-0.11-0.33-0.14l-1.12-0.29l-1.01-0.33l-0.98-0.64l-0.92-0.51l-0.74-0.77
                  c-0.24-0.24-0.56-0.38-0.9-0.38s-0.66,0.14-0.9,0.38l-1.26,1.3c-0.11,0.1-0.19,0.22-0.25,0.35l-0.56,1.23
                  c-0.05,0.13-0.09,0.26-0.1,0.39l-0.15,1.46c-0.03,0.28,0.03,0.56,0.18,0.8l0.43,0.67c-0.13,0.09-0.24,0.2-0.33,0.34l-0.83,1.32
                  c-0.14,0.21-0.21,0.45-0.2,0.7l0.03,1.37c0.01,0.47,0.29,0.9,0.72,1.1l1.16,0.54l0.81,0.46l0.49,0.64l0.39,1
                  c0.06,0.15,0.14,0.28,0.25,0.39l0.83,0.91c0.05,0.06,0.11,0.12,0.18,0.16l1.02,0.77l0.84,0.89l1.74,1.65l0.74,0.87l2.26,3.05
                  c0.06,0.08,0.13,0.16,0.21,0.22l0.98,0.81c0.07,0.06,0.14,0.11,0.22,0.15l1.7,0.89l-0.05,0.37c-0.03,0.26,0.01,0.51,0.12,0.74
                  l0.55,1.09c0.17,0.33,0.47,0.57,0.83,0.66c0.36,0.08,0.73,0,1.03-0.21l0.39-0.29l1.76,0.81c0.36,0.15,0.77,0.13,1.11-0.06l0.26-0.14
                  l0.22,0.21c0.02,0.03,0.05,0.06,0.08,0.08l1.11,0.92c0.19,0.16,0.41,0.25,0.65,0.28l0.92,0.11l-0.81,1.04l-1.06,1.45l-1.16,0.78
                  c-0.47,0.31-0.67,0.89-0.5,1.42l0.38,1.2c0.02,0.06,0.04,0.11,0.07,0.17l1.07,2.3c0.02,0.04,0.04,0.09,0.07,0.14l1.37,2.18
                  c0.06,0.09,0.13,0.18,0.21,0.25l0.88,0.79l0.64,0.73l0.47,1.05c0.1,0.22,0.27,0.4,0.47,0.53l1.91,1.23l0.54,0.77l0.58,1.04
                  c0.21,0.39,0.63,0.64,1.08,0.64l1.39,0.01c0.18-0.01,0.42-0.05,0.61-0.15l0.84-0.46l-0.01,1.04c0,0.19,0.04,0.39,0.13,0.56
                  l0.58,1.18c0.22,0.45,0.67,0.72,1.19,0.7l1.44-0.09l1.43,0.47l2.45,0.61c0.24,0.05,0.5,0.04,0.73-0.05l1.02-0.39l1.46-0.15
                  l0.13,0.38c0.2,0.57,0.79,0.92,1.38,0.82l0.87-0.13L940.3,231.59z"/>
                <title>Poprad</title>
              </Link>

              {/* Tvrdosin - Zilinsky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Tvrdošín")}>
                <path
                  d="M775.38,132.45c-0.07,0.02-0.15,0.03-0.22,0.06l-1.22,0.42l-1.31,0.23c-0.26,0.04-0.5,0.17-0.68,0.36
                  l-0.86,0.89l-0.95,0.67l-1.15,0.44c-0.31,0.12-0.55,0.35-0.69,0.65l-1.19,2.73c-0.04,0.1-0.07,0.22-0.08,0.33l-0.15,1.23
                  c-0.02,0.15-0.01,0.3,0.02,0.45l0.24,0.97l-1.2,2.73l-0.91,0.22c-0.38,0.09-0.69,0.35-0.85,0.7l-0.52,1.15l-0.55,0.94l-1.05,1.14
                  c-0.14,0.16-0.24,0.34-0.29,0.55l-0.43,1.73c-0.04,0.13-0.05,0.26-0.04,0.39l0.11,1.42l-0.23,0.93l-0.38,1.23l-1,1.17
                  c-0.13,0.17-0.23,0.36-0.27,0.57l-0.39,2.01c-0.04,0.2-0.03,0.41,0.03,0.61l0.37,1.21c0.06,0.19,0.16,0.36,0.3,0.5l0.06,0.06
                  l-0.22,0.32c-0.05,0.07-0.09,0.14-0.12,0.21l-0.43,0.97l-0.6,0.72l-0.72,0.55l-1.03,0.11l-1.25,0.22l-1.37-0.43l-0.84-0.63
                  c-0.2-0.15-0.45-0.24-0.7-0.25l-1.54-0.06c-0.15,0-0.29,0.01-0.42,0.06l-1.67,0.52l-1.08,0.25l-0.2-0.16l-0.23-0.78l0.48-1.12
                  c0.15-0.34,0.13-0.72-0.03-1.05c-0.17-0.33-0.48-0.57-0.84-0.66l-1.14-0.26l-2-0.59l-1.16-0.57l-0.46-0.82
                  c-0.07-0.12-0.15-0.23-0.26-0.32l-0.36-0.33l0.16-0.54c0.09-0.3,0.06-0.62-0.08-0.9l-0.73-1.48c-0.08-0.16-0.19-0.3-0.32-0.41
                  l-1.11-0.92c-0.2-0.16-0.44-0.26-0.69-0.29l-1.25-0.11h-0.15l-1.3,0.04c-0.28,0.01-0.54,0.11-0.76,0.29l-1.99,1.64l-1.45,0.9
                  c-0.12,0.07-0.22,0.15-0.31,0.26l-0.89,1.06c-0.12,0.14-0.2,0.3-0.25,0.48l-0.25,0.97l-0.49,0.34l-1.34-0.25l-1.42-0.33
                  c-0.13-0.03-0.26-0.04-0.39-0.02l-1.14,0.1l-1.17-0.43l-0.99-0.77c-0.23-0.18-0.5-0.27-0.79-0.27l-1.29,0.02l-0.95-0.94
                  c-0.03-0.03-0.06-0.05-0.09-0.08l-1.11-0.91c-0.05-0.04-0.12-0.08-0.18-0.12l-1.2-0.67c-0.15-0.08-0.31-0.14-0.49-0.15l-0.81-0.09
                  l-0.15-0.16l0.15-0.67c0.03-0.13,0.04-0.26,0.02-0.4l-0.13-1.28c-0.01-0.12-0.04-0.23-0.08-0.34l-0.5-1.22
                  c-0.11-0.27-0.31-0.5-0.57-0.63l-0.89-0.48l-0.84-0.95c-0.14-0.15-0.32-0.27-0.52-0.35l-1.25-0.45l-1.61-0.48
                  c-0.1-0.03-0.21-0.04-0.31-0.05l-1.27-0.05l-2.36-0.28l-0.39-0.22l-0.15-1.11l-0.03-0.15l-0.5-1.95l-0.25-1.3l-0.16-1.38l-0.11-2.74
                  c-0.01-0.16-0.05-0.33-0.12-0.47l-0.57-1.18c-0.18-0.38-0.54-0.64-0.94-0.7l-1.96-0.29l-2.42-1.06l-0.68-0.72
                  c-0.17-0.18-0.39-0.3-0.62-0.36l-1.23-0.29c-0.37-0.08-0.75,0-1.05,0.23l-0.46,0.35l-0.16-0.05l-0.48-0.78l-0.53-0.74l-0.05-1.23
                  c-0.02-0.42-0.24-0.8-0.6-1.02l-0.55-0.33l-0.07-0.61c-0.03-0.27-0.14-0.51-0.32-0.71l-1.02-1.11c-0.21-0.22-0.49-0.36-0.8-0.39
                  l-1.24-0.12c-0.15-0.02-0.31,0-0.46,0.04l-1.3,0.37l-1.08,0.38l-0.97,0.11l-1.03-0.07l-1.71-0.61l-0.32-0.68
                  c-0.04-0.08-0.09-0.16-0.14-0.24l-1.01-1.29l-0.84-1c-0.15-0.18-0.33-0.31-0.55-0.38l-0.93-0.33l-0.73-0.65l-2.48-3.43
                  c-0.11-0.16-0.25-0.29-0.42-0.38l-1.01-0.54l-0.24-0.26l0.08-0.59l0.46-0.69l0.8-0.72c0.07-0.05,0.12-0.12,0.18-0.19l0.77-1.04
                  l0.77-0.68l1.2-0.96c0.28-0.23,0.45-0.56,0.47-0.91c0.02-0.36-0.11-0.7-0.37-0.95l-0.29-0.3l0.18-0.44l0.67-0.84l0.91-0.9l0.58-0.64
                  l0.79-0.14c0.25-0.04,0.47-0.16,0.66-0.33l0.81-0.78l0.88-0.34l1.33-0.28c0.32-0.07,0.61-0.26,0.79-0.54l0.79-1.22l0.53-0.68
                  l0.59-0.2l1.11,0.09c0.45,0.05,0.87-0.15,1.13-0.52l0.79-1.1c0.11-0.17,0.19-0.36,0.22-0.56l0.11-0.81l0.43-0.46l0.82-0.44l1.3-0.42
                  c0.51-0.17,0.86-0.65,0.86-1.19l0.02-2.74l0.38-0.75l0.82-0.44c0.09-0.05,0.17-0.1,0.24-0.17l1.2-1.08l0.84-0.84l0.91-0.62
                  l1.32-0.59c0.22-0.09,0.4-0.24,0.53-0.44l0.84-1.24c0.19-0.28,0.26-0.62,0.2-0.95l-0.11-0.54l0.36-0.28l1-0.41
                  c0.09-0.04,0.17-0.09,0.25-0.14l1.11-0.81l0.87-0.74l1.08-0.56c0.11-0.06,0.2-0.13,0.29-0.21l1.06-1.03
                  c0.03-0.03,0.05-0.06,0.08-0.09l0.97-1.13c0.03-0.03,0.05-0.06,0.08-0.1l0.67-0.96l0.71-0.68l0.9-0.58l1.04-0.4
                  c0.29-0.11,0.52-0.33,0.66-0.6l0.54-1.05l2.08-2.29l2.84-2.59l0.98-0.65c0.36-0.24,0.58-0.65,0.56-1.09l-0.05-1.24
                  c-0.02-0.42-0.24-0.8-0.6-1.02l-0.72-0.43l-0.32-0.79c-0.03-0.07-0.07-0.14-0.11-0.2l-1.31-2.03l-0.41-1.26
                  c0.09-0.02,0.18-0.06,0.27-0.1l1.02-0.54l0.54,0.03l0.65,0.45l0.71,0.64l0.52,0.89c0.06,0.11,0.14,0.2,0.23,0.28l0.71,0.67
                  l0.42,0.93c0.26,0.58,0.91,0.87,1.51,0.68l2.86-0.86c0.07-0.02,0.15-0.05,0.21-0.09l1.21-0.61c0.07-0.03,0.14-0.08,0.2-0.13
                  l1.29-1.01l-0.09,0.08c-0.26,0.21-0.41,0.52-0.44,0.85l-0.1,1.31v0.19l0.09,1.23c0.02,0.29,0.14,0.55,0.34,0.76l0.81,0.87l0.69,0.93
                  c0.1,0.14,0.23,0.25,0.39,0.34l0.16,0.09l-0.76,1.09c-0.04,0.07-0.08,0.14-0.11,0.21l-0.66,1.47l-0.5,1.34
                  c-0.03,0.08-0.05,0.16-0.06,0.24l-0.24,1.41l-0.45,1.04c-0.04,0.07-0.06,0.15-0.08,0.23l-0.25,1.09l-0.69,0.99
                  c-0.31,0.43-0.31,1-0.01,1.43s0.84,0.63,1.35,0.5l1.06-0.28h0.58l0.54,0.51c0.34,0.32,0.82,0.42,1.25,0.28l1-0.34l0.89,0.29
                  l1.2,0.28l0.21,0.03l0.86,0.05l0.66,0.53c0.26,0.2,0.58,0.29,0.91,0.26l0.79-0.08l1.56,1.14c0.33,0.26,0.77,0.33,1.17,0.19
                  l0.62-0.22l0.55,0.36c0.1,0.07,0.21,0.11,0.33,0.15l1.12,0.33l1.03,0.57l0.76,0.36l0.37,0.74c0.15,0.29,0.41,0.51,0.72,0.61
                  l0.46,0.16l0.07,0.46c0.06,0.37,0.27,0.69,0.59,0.88c0.31,0.2,0.69,0.24,1.05,0.12l0.35-0.11l-0.07,0.41
                  c-0.07,0.44,0.11,0.89,0.46,1.17c0.36,0.28,0.83,0.35,1.25,0.18l1.25-0.51c0.07-0.03,0.13-0.06,0.19-0.1l1.16-0.72l1.05-0.77
                  c0.1-0.08,0.19-0.16,0.26-0.26l0.95-1.28l1.54-2.33c0.05-0.09,0.1-0.18,0.13-0.28l0.35-1.02l0.52-0.7l0.86-0.6l3.93-1.46l1.04-0.26
                  l1.65,0.21l2.3,0.38l0.18,0.31l-0.26,2.4c-0.02,0.19,0,0.37,0.06,0.55l0.41,1.19l-0.01,1.4l0.05,3.33c0,0.08,0.01,0.15,0.03,0.23
                  l0.28,1.34l0.11,1.22c0.02,0.22,0.11,0.43,0.24,0.61l0.72,0.99l0.66,1.05c0.04,0.06,0.08,0.12,0.13,0.17l0.78,0.88l0.24,0.89
                  l0.07,1.04l-0.4,1.1c-0.06,0.16-0.09,0.33-0.08,0.5l0.08,1.32l0.07,0.62l-0.5,0.47c-0.09,0.08-0.16,0.18-0.22,0.28l-0.77,1.32
                  c-0.07,0.12-0.12,0.24-0.15,0.37l-0.27,1.29c-0.06,0.29-0.01,0.59,0.13,0.85l0.56,1.05l0.17,1.01l0.13,1.31
                  c0.01,0.08,0.03,0.17,0.06,0.25l0.48,1.53l0.48,1.25l0.34,1.14l0.23,0.94l-0.2,0.94l-0.66,1.31l-0.99,2.38l-0.65,1.4l-0.48,1.2
                  c-0.05,0.11-0.08,0.22-0.09,0.34l-0.14,1.47c-0.01,0.18,0.01,0.36,0.07,0.53l0.48,1.38c0.15,0.45,0.55,0.77,1.02,0.83l1.36,0.18
                  l1.98,0.14l0.2,0.53c0.03,0.05,0.05,0.1,0.08,0.15l0.61,1.09l0.59,1.25c0.04,0.09,0.09,0.17,0.15,0.24l0.7,0.9L775.38,132.45z"/>
                <title>Tvrdošín</title>
              </Link>

              {/* Namestovo - Zilinsky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Námestovo")}>
                <path
                  d="M716.54,69.18l-0.37,0.25c-0.05,0.03-0.1,0.07-0.14,0.11l-1.9,1.68L713,72.3l-2.24,2.48
                  c-0.08,0.08-0.14,0.17-0.19,0.27l-0.4,0.78l-0.72,0.27c-0.08,0.03-0.15,0.07-0.22,0.12l-1.11,0.71c-0.07,0.04-0.13,0.09-0.19,0.15
                  l-0.89,0.85c-0.06,0.05-0.12,0.12-0.17,0.19l-0.7,1.02l-0.89,1.03l-0.89,0.87l-1.04,0.54c-0.08,0.05-0.16,0.1-0.23,0.16l-0.9,0.76
                  l-0.91,0.66l-1.03,0.42c-0.1,0.05-0.2,0.11-0.3,0.18l-1.1,0.87c-0.36,0.29-0.54,0.77-0.45,1.23l0.16,0.77l-0.34,0.51l-1.1,0.49
                  c-0.08,0.03-0.15,0.07-0.21,0.11l-1.1,0.76c-0.06,0.04-0.12,0.09-0.17,0.15l-0.87,0.87l-1.04,0.93l-1.04,0.56
                  c-0.23,0.12-0.41,0.31-0.53,0.54l-0.68,1.35c-0.08,0.16-0.13,0.35-0.13,0.54l-0.03,2.16l-0.55,0.18c-0.07,0.02-0.14,0.05-0.2,0.09
                  l-1.09,0.59c-0.12,0.06-0.23,0.15-0.32,0.24l-0.84,0.89c-0.18,0.19-0.29,0.43-0.33,0.69l-0.13,0.9l-0.19,0.28l-0.67-0.06
                  c-0.18-0.01-0.35,0.01-0.52,0.07l-1.18,0.41c-0.22,0.08-0.42,0.22-0.57,0.4l-0.8,1.01c-0.02,0.04-0.05,0.07-0.07,0.1l-0.54,0.83
                  l-0.92,0.2c-0.06,0.01-0.13,0.03-0.19,0.05l-1.21,0.48c-0.16,0.05-0.3,0.14-0.42,0.26l-0.71,0.68l-0.84,0.15
                  c-0.28,0.05-0.53,0.18-0.72,0.39l-0.82,0.91l-0.91,0.9c-0.03,0.04-0.07,0.07-0.1,0.11l-0.83,1.04c-0.07,0.09-0.13,0.2-0.18,0.31
                  l-0.56,1.38c-0.17,0.42-0.1,0.89,0.18,1.24l-1.09,0.94c-0.06,0.06-0.12,0.13-0.18,0.2l-0.77,1.04l-0.82,0.74
                  c-0.08,0.07-0.15,0.15-0.21,0.23l-0.71,1.06c-0.1,0.16-0.17,0.34-0.2,0.53l-0.14,1l-0.63,0.61c-0.05,0.04-0.09,0.08-0.12,0.13
                  l-1.58,2.01l-1.14,1.07l-0.91,0.98c-0.08,0.09-0.14,0.18-0.2,0.29l-0.5,1l-0.99,1.12l-0.65,0.84l-1.04,0.7l-0.27,0.13l-0.16-0.24
                  c-0.1-0.15-0.24-0.27-0.39-0.37l-1.05-0.61l-2.24-1.62c-0.28-0.21-0.62-0.29-0.96-0.23l-2.26,0.4l-0.42-0.42
                  c-0.09-0.09-0.19-0.16-0.3-0.22l-2.35-1.2c-0.06-0.03-0.13-0.05-0.19-0.07l-1.2-0.37c-0.2-0.07-0.41-0.08-0.62-0.04l-1.34,0.27
                  c-0.04,0.01-0.07,0.02-0.11,0.03l-1.33,0.38l-2.39,0.84l-2.44-0.05l-0.16-0.04l-0.02-0.36c-0.02-0.39-0.22-0.74-0.55-0.96
                  c-0.32-0.22-0.72-0.28-1.09-0.16l-1.19,0.39c-0.03,0.01-0.06,0.02-0.1,0.04l-1.01,0.42l-1.29,0.24l-1.3,0.3
                  c-0.17,0.04-0.33,0.12-0.47,0.22l-0.97,0.73l-0.85,0.55l-1.02,0.05c-0.42,0.02-0.81,0.27-1.02,0.64l-0.58,1.05l-0.64,0.99
                  l-0.73,0.88c-0.04,0.05-0.07,0.09-0.1,0.14l-0.59,0.95l-0.78,0.75c-0.22,0.2-0.35,0.48-0.38,0.78l-0.1,0.94l-0.23,0.28l0.2-0.91
                  c0.02-0.08,0.03-0.16,0.03-0.24l0.03-1.36c0-0.22-0.05-0.43-0.15-0.62l-0.58-1.09l-0.48-1.26c-0.05-0.11-0.11-0.22-0.19-0.32
                  l-0.45-0.58l0.4-3.4c0.01-0.1,0.01-0.19,0-0.29l-0.14-1.28l0.18-1.13c0.04-0.27-0.01-0.54-0.14-0.78l-0.63-1.17
                  c-0.19-0.36-0.54-0.6-0.94-0.65l-1.55-0.2l-1.35-0.08l-1.08-0.12l-2.29-1.18c-0.4-0.2-0.87-0.17-1.24,0.07l-0.96,0.63l-0.95,0.05
                  c-0.35,0.02-0.68,0.19-0.9,0.46l-0.82,1.02l-1.19,0.24l-1.42,0.16l-1.58,0.03l-1.29-0.11l-0.57-0.58c-0.19-0.2-0.45-0.33-0.72-0.37
                  l-0.22-0.03l-0.01-0.22c-0.01-0.08-0.02-0.16-0.04-0.24l-0.28-1.12l-0.1-1.16c-0.02-0.14-0.05-0.27-0.11-0.4l-0.55-1.24
                  c-0.15-0.35-0.46-0.61-0.83-0.7s-0.77-0.01-1.07,0.22l-0.89,0.69l-0.7-0.12l-0.37-0.29l-0.13-0.71l0.27-0.91
                  c0.03-0.1,0.04-0.2,0.05-0.3l0.04-1.02l0.35-0.84l0.74-1.03l0.68-1.1c0.17-0.28,0.23-0.61,0.16-0.92l-0.55-2.47
                  c-0.05-0.22-0.17-0.42-0.32-0.58l-0.97-1c-0.1-0.1-0.21-0.18-0.34-0.25l-0.8-0.4l-0.5-0.78c-0.16-0.26-0.41-0.45-0.7-0.53l-1.4-0.4
                  l-0.49-0.11l-0.15-0.52c-0.17-0.54-0.63-0.87-1.22-0.89l-1.37,0.02c-0.17,0-0.33,0.04-0.49,0.11l-0.93,0.41l-0.39,0.02l-0.22-0.26
                  l0.02-0.36l0.54-0.74l0.86-0.83c0.05-0.04,0.1-0.09,0.14-0.15l0.78-1.03c0.16-0.21,0.25-0.47,0.25-0.73l0.02-1.36l0.17-0.93
                  l0.49-0.97c0.09-0.18,0.14-0.37,0.14-0.56l0.01-1.27c0-0.18-0.03-0.35-0.11-0.51l-0.47-1.1l-0.06-1c-0.01-0.15-0.04-0.29-0.1-0.42
                  l-0.04-0.1l0.12-0.04c0.1-0.03,0.21-0.08,0.3-0.14l1.35-0.86c0.19-0.12,0.35-0.3,0.45-0.5l0.3-0.62l0.45-0.13l0.61,0.3l0.82,1.1
                  c0.19,0.26,0.48,0.43,0.81,0.48l1.35,0.21c0.31,0.05,0.63-0.02,0.89-0.2l1.03-0.7l1.1-0.61c0.06-0.03,0.11-0.07,0.17-0.11l1.17-0.93
                  c0.02-0.02,0.05-0.05,0.08-0.07l0.92-0.87l0.46-0.22l0.92,0.39l1.16,0.71c0.27,0.16,0.59,0.22,0.9,0.15l1.25-0.26
                  c0.2-0.04,0.38-0.12,0.54-0.25l1.17-0.95c0.26-0.21,0.42-0.52,0.45-0.85l0.12-1.28c0.03-0.32-0.06-0.64-0.25-0.89l-0.77-0.98
                  l-0.17-0.56l0.31-0.79c0.11-0.27,0.12-0.57,0.03-0.85l-0.44-1.3c-0.05-0.16-0.13-0.31-0.24-0.43l-0.76-0.87l-0.2-0.55l0.43-0.57
                  c0.04-0.06,0.08-0.12,0.11-0.18l0.54-1.04l0.75-0.96c0.08-0.09,0.14-0.21,0.19-0.32l0.37-0.97l0.69-0.72l0.95-0.42
                  c0.06-0.03,0.12-0.06,0.17-0.09l0.48-0.31l0.41,0.25c0.05,0.03,0.09,0.05,0.14,0.07l1.26,0.56c0.59,0.26,1.27,0.04,1.59-0.52
                  l0.49-0.85l0.82-0.65c0.17-0.14,0.3-0.31,0.38-0.51l0.56-1.37c0.05-0.12,0.08-0.25,0.09-0.38l0.09-1.3l0.35-1.21
                  c0.06-0.21,0.06-0.42,0.02-0.63l-0.3-1.29c-0.1-0.41-0.39-0.75-0.79-0.89l-0.72-0.27l-0.2-0.75l0.11-0.66l0.62-0.86
                  c0.08-0.11,0.15-0.24,0.19-0.37l0.35-1.18l0.32-0.91l0.75-0.69c0.34-0.32,0.48-0.8,0.36-1.25l-0.33-1.19l-0.05-0.37l0.59-0.39
                  c0.17-0.12,0.32-0.29,0.41-0.48l0.6-1.19c0.06-0.11,0.1-0.24,0.12-0.36l0.26-1.63c0.05-0.3-0.01-0.61-0.17-0.86l-0.61-0.97
                  l0.12-0.79c0.01-0.04,0.01-0.08,0.02-0.12l0.05-0.83l0.55-0.51c0.03-0.02,0.06-0.05,0.09-0.08l0.94-1.06l0.66-0.79l0.94-0.42
                  c0.18-0.08,0.34-0.2,0.47-0.35l1.63-2l0.98-0.61c0.38-0.24,0.61-0.67,0.59-1.12l-0.03-0.46l0.41-0.23l0.94,0.19
                  c0.19,0.03,0.38,0.02,0.57-0.03l1.2-0.33c0.17-0.04,0.33-0.13,0.47-0.24l0.88-0.73l1.07,0.18l0.96,0.47c0.2,0.1,0.42,0.14,0.64,0.13
                  l1.34-0.1c0.17-0.02,0.33-0.06,0.48-0.14l2.03-1.05l1.07-0.06l0.49,0.35l0.02,0.62c0.01,0.35,0.16,0.67,0.42,0.9
                  c0.26,0.24,0.6,0.35,0.95,0.31l2.8-0.27c0.16-0.01,0.32-0.06,0.46-0.13l1.47-0.78l1.85-0.36c0.06-0.02,0.12-0.03,0.17-0.05
                  l1.01-0.35l1.62,0.14c0.08,0.01,0.17,0.01,0.25,0l1.71-0.2c0.31-0.03,0.6-0.18,0.8-0.42l0.99-1.13c0.13-0.15,0.23-0.33,0.27-0.53
                  l0.71-2.96c0.04-0.19,0.04-0.38-0.01-0.57l-0.32-1.38l-0.11-0.7l0.52-0.34c0.21-0.13,0.37-0.32,0.47-0.54l0.61-1.37
                  c0.11-0.26,0.14-0.54,0.07-0.81l-0.34-1.39l-0.18-0.88l0.29-0.42l0.63-0.5l0.89-0.28l1.07-0.14l1.25-0.08
                  c0.25-0.02,0.49-0.11,0.69-0.27l0.88-0.69l0.95-0.18c0.32-0.06,0.6-0.24,0.78-0.5l0.68-0.95l0.89-1.11
                  c0.14-0.17,0.23-0.37,0.26-0.58l0.24-1.49c0.03-0.15,0.02-0.3-0.01-0.44l-0.17-0.87l0.28-0.74l0.42-0.68l0.91-0.44l1-0.36l1.1-0.12
                  l1.5-0.07c0.1-0.01,0.2-0.02,0.3-0.05l1.56-0.47c0.1-0.03,0.2-0.08,0.29-0.13l1.1-0.67c0.16-0.1,0.3-0.23,0.4-0.39l0.81-1.25
                  c0.27-0.43,0.26-0.98-0.03-1.4l-0.48-0.68l0.17-0.86l0.25-0.9l0.2,0.12c0.13,0.08,0.26,0.13,0.4,0.16l1.2,0.25
                  c0.31,0.07,0.63,0.01,0.9-0.15l0.98-0.58L683.63,0l0.33,0.29l0.25,0.93c0.04,0.12,0.09,0.24,0.16,0.35l0.52,0.8l-0.08,0.6
                  l-0.62,0.88c-0.08,0.11-0.14,0.23-0.18,0.35l-0.39,1.27c-0.14,0.48,0.01,1,0.39,1.32l0.96,0.81l0.99,1.53
                  c0.06,0.09,0.13,0.17,0.2,0.24l1.31,1.19c0.14,0.14,0.32,0.23,0.51,0.29l1.51,0.41l1.61,1.01c0.1,0.06,0.21,0.11,0.32,0.14
                  l1.19,0.35c0.18,0.05,0.38,0.07,0.57,0.03l1.11-0.2l0.52,0.07l0.72,0.94l0.41,1.02c0.07,0.2,0.2,0.37,0.36,0.5l0.95,0.79
                  c0.16,0.14,0.36,0.23,0.56,0.27l1.06,0.2l0.32,0.42l-0.29,2.64c-0.01,0.05-0.01,0.1-0.01,0.16l0.02,1.49c0,0.05,0,0.1,0.01,0.16
                  l0.46,3.22l-0.18,2.6l-0.01,1.41c0,0.1,0.01,0.2,0.03,0.3l0.33,1.36c0.03,0.12,0.07,0.23,0.13,0.33l1.18,2.04l-0.12,0.98
                  c-0.07,0.54,0.21,1.06,0.7,1.29l0.81,0.38l0.31,0.62l0.22,1.31c0.02,0.12,0.06,0.25,0.12,0.36l0.62,1.2
                  c0.04,0.08,0.08,0.15,0.13,0.21l0.69,0.86l1.17,3.66l0.15,1.08l0.09,1.46l-0.08,1.37c0,0.07,0,0.15,0.01,0.22l0.15,1.22
                  c0.04,0.36,0.24,0.68,0.53,0.88l0.81,0.55l0.49,0.98l1.21,2.04l0.3,0.93l-0.1,1.04c-0.03,0.33,0.07,0.65,0.27,0.9l0.74,0.92
                  l0.62,0.94l0.44,1.03c0.08,0.18,0.2,0.34,0.36,0.47l0.8,0.66l0.37,0.63l0.11,0.96c0.03,0.32,0.18,0.6,0.41,0.8l-0.25,0.08l0.81,2.42
                  c0.03,0.1,0.08,0.2,0.13,0.28l1.33,2.05l0.43,1.06c0.1,0.25,0.28,0.46,0.51,0.6L716.54,69.18z"/>
                <title>Námestovo</title>
              </Link>

              {/* Cadca - Zilinsky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Čadca")}>
                <path style={{ fill: crimesInRegionZilinsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionZilinsky[0].crimePercent)] ? crimesInRegionZilinsky[0].color : "#D3D3D3" }}
                  d="M602.31,113.37l-6.39,6.66l-0.29,0.06l-0.3-0.7c-0.17-0.41-0.55-0.69-0.98-0.75c-0.45-0.06-0.88,0.12-1.15,0.46
                  l-0.26,0.34l-0.26-0.07l-1.16-0.64c-0.2-0.11-0.42-0.16-0.66-0.16l-1.1,0.05l-1.36-0.27c-0.56-0.1-1.11,0.17-1.36,0.67l-0.56,1.14
                  l-0.37,0.66l-0.79,0.21c-0.07,0.02-0.13,0.04-0.19,0.07l-2.32,1.02l-1.09,0.27c-0.17,0.04-0.33,0.11-0.47,0.21l-0.33,0.26l-0.2-0.11
                  l-0.71-0.79c-0.22-0.24-0.52-0.38-0.84-0.41l-1.19-0.08l-1.23-0.15c-0.37-0.05-0.74,0.08-1.01,0.33l-0.27,0.25l-0.2-0.58
                  c-0.06-0.18-0.16-0.34-0.3-0.48l-1.36-1.35c-0.29-0.28-0.7-0.41-1.1-0.34c-0.4,0.07-0.74,0.33-0.91,0.7l-0.56,1.18l-0.2,0.11
                  l-0.6-0.26c-0.09-0.03-0.18-0.06-0.27-0.08l-1-0.18l-0.87-0.79c-0.03-0.02-0.06-0.05-0.09-0.07c0-0.1-0.02-0.2-0.05-0.3
                  c-0.1-0.37-0.37-0.66-0.71-0.81l-1.23-0.52c-0.58-0.25-1.25-0.02-1.57,0.52l-0.56,0.96l-0.33,0.28l-0.73-0.2
                  c-0.14-0.05-0.3-0.06-0.45-0.04l-1.24,0.12c-0.05,0-0.1,0.01-0.15,0.02l-0.95,0.21l-0.42-0.1l-0.29-0.6l-0.52-1.36
                  c-0.16-0.42-0.54-0.72-0.98-0.79l-1.17-0.18l-1.19-0.3c-0.11-0.03-0.22-0.05-0.34-0.04l-1.5,0.03c-0.11,0-0.23,0.02-0.35,0.06
                  l-1.32,0.42l-1.19,0.47c-0.13,0.05-0.25,0.12-0.35,0.21l-1.06,0.91l-0.83,0.36l-1.03,0.09c-0.34,0.04-0.65,0.2-0.86,0.47l-0.78,0.97
                  c-0.1,0.11-0.17,0.24-0.22,0.38l-0.44,1.33l-0.48,1.1l-0.19,0.16l-1.17-0.4l-1.36-0.88c-0.15-0.09-0.31-0.16-0.49-0.18l-0.48-0.08
                  l-0.09-0.66v-1.14c0-0.17-0.04-0.34-0.11-0.5l-0.54-1.23c-0.08-0.2-0.22-0.37-0.39-0.5l-0.95-0.72l-1.01-0.87
                  c-0.1-0.08-0.22-0.15-0.34-0.2l-1.12-0.48l-0.46-0.46l0.03-0.53l0.45-1.14c0.03-0.06,0.05-0.12,0.06-0.19l0.31-1.4
                  c0.04-0.19,0.04-0.39-0.01-0.57l-0.38-1.51c-0.03-0.13-0.08-0.25-0.15-0.36l-0.76-1.23c-0.1-0.17-0.25-0.31-0.43-0.42l-1.19-0.7
                  c-0.03-0.02-0.07-0.04-0.11-0.06l-1.04-0.47l-0.98-0.64l-1.01-0.89c-0.08-0.07-0.17-0.13-0.27-0.18l-0.54-0.27l0.06-0.22
                  c0.13-0.51-0.07-1.05-0.51-1.34l-1.03-0.7c-0.04-0.04-0.09-0.06-0.14-0.09l-2.45-1.26c-0.07-0.04-0.15-0.07-0.22-0.1l-2.07-0.59
                  l-0.54-0.67c-0.32-0.39-0.83-0.55-1.31-0.41l-1.35,0.38c-0.1,0.03-0.21,0.07-0.3,0.13l-0.74,0.44l-0.63-0.11l-0.99-0.44
                  c-0.21-0.09-0.43-0.12-0.66-0.09l-1.89,0.24c-0.1,0.01-0.19,0.04-0.29,0.07l-2.02,0.77c-0.05,0.02-0.1,0.05-0.16,0.08l-0.38,0.21
                  l-0.29-0.23l-0.52-0.77l-0.34-1.66c-0.01-0.05-0.02-0.1-0.03-0.14l-0.4-1.21c-0.14-0.4-0.46-0.71-0.87-0.82l-2.54-0.67l-0.62-0.6
                  l-0.22-1.04c-0.06-0.3-0.22-0.56-0.46-0.74l-0.64-0.49l-0.19-0.98c-0.12-0.55-0.58-0.96-1.14-1l-1.06-0.07l-0.86-0.33l-1.09-0.83
                  l-0.78-0.83c-0.13-0.14-0.29-0.24-0.47-0.31l-1.58-0.59c-0.28-0.1-0.58-0.11-0.85-0.01l-1.2,0.42c-0.3,0.1-0.55,0.32-0.69,0.59
                  l-0.69,1.3c-0.06,0.11-0.1,0.22-0.12,0.33l-0.19,0.88l-0.62,0.29c-0.06,0.03-0.12,0.07-0.18,0.11l-1.49,1.05
                  c-0.34,0.24-0.54,0.64-0.53,1.06l0.05,1.52l-0.08,1.09l-0.19,0.35l-0.09,0.01l-0.55-0.57c-0.07-0.07-0.15-0.13-0.23-0.19l-1.06-0.67
                  c-0.18-0.11-0.37-0.17-0.58-0.19L487.75,95c-0.13-0.01-0.26,0-0.39,0.04l-1.3,0.32c-0.29,0.07-0.54,0.24-0.71,0.47l-0.96,1.32
                  c-0.03,0.04-0.05,0.07-0.07,0.11l-0.67,1.15l-0.49,0.32l-0.9-0.14c-0.04-0.01-0.08-0.01-0.12-0.02l-1.27-0.08
                  c-0.17-0.01-0.33,0.01-0.48,0.07l-1.41,0.48c-0.36,0.12-0.64,0.39-0.77,0.74l-0.21,0.55l-0.5,0.03c-0.36,0.03-0.69,0.21-0.9,0.49
                  c-0.22,0.29-0.31,0.65-0.24,1.01l0.03,0.16l-0.29-0.1l-0.9-0.57l-0.78-0.89c-0.19-0.22-0.45-0.36-0.73-0.41l-1.36-0.23
                  c-0.05-0.01-0.1-0.02-0.15-0.02l-0.68-0.03l-0.52-0.64l-0.49-0.98c-0.19-0.38-0.56-0.64-0.98-0.68l-1.36-0.15
                  c-0.22-0.03-0.45,0.01-0.65,0.1l-1.32,0.59c-0.32,0.14-0.56,0.4-0.67,0.73l-0.42,1.21c-0.04,0.1-0.06,0.2-0.07,0.3l-0.09,1.14
                  l-0.38,0.75l-0.84,0.65l-0.37,0.32l-0.4-0.2c-0.1-0.05-0.21-0.09-0.33-0.11l-2.11-0.39l-0.07-0.2l0.08-1.09
                  c0.01-0.25-0.05-0.51-0.18-0.73l-0.74-1.21c-0.19-0.3-0.48-0.51-0.83-0.58c-0.34-0.06-0.69,0.01-0.97,0.22l-0.61,0.44l-0.45-0.1
                  l-0.5-0.48l-0.56-0.96c-0.25-0.45-0.76-0.69-1.27-0.62c-0.51,0.08-0.92,0.47-1.03,0.98l-0.25,1.14l-0.02,0.09
                  c-0.31-0.08-0.64-0.04-0.92,0.12c-0.34,0.19-0.58,0.53-0.63,0.93l-0.12,0.93l-0.26-0.11c-0.09-0.04-0.17-0.06-0.27-0.08l-1.02-0.19
                  l-0.67-0.59c-0.1-0.09-0.21-0.16-0.33-0.21l-1.14-0.5c-0.14-0.07-0.3-0.1-0.46-0.11l-1.12-0.04l-1.1-0.22
                  c-0.13-0.03-0.28-0.04-0.42-0.02l-1.23,0.17c-0.32,0.05-0.61,0.21-0.81,0.46l-0.88,1.11c-0.07,0.09-0.13,0.19-0.17,0.3l-0.36,0.84
                  l-0.69,0.24c-0.11,0.03-0.22,0.09-0.31,0.15l-1.03,0.71c-0.08,0.06-0.15,0.12-0.22,0.19l-0.39,0.44l-0.35-0.08l-0.52-0.39
                  l-0.31-0.87c-0.14-0.39-0.46-0.68-0.86-0.79c-0.4-0.1-0.82-0.01-1.13,0.26l-0.91,0.78l-1.02,0.5c-0.1,0.05-0.19,0.11-0.28,0.18
                  l-0.97,0.86c-0.18,0.17-0.31,0.38-0.38,0.61l-0.12,0.47l-0.5,0.11c-0.13,0.02-0.26,0.07-0.38,0.14l-1.15,0.68
                  c-0.08,0.06-0.17,0.12-0.24,0.19l-0.91,0.91l-0.45,0.38l-0.35-0.19c-0.26-0.13-0.56-0.18-0.85-0.11l-1.07,0.23l-1.25-0.07
                  c-0.57-0.03-1.09,0.33-1.26,0.88l-0.1,0.3l-0.04-0.01c-0.31-0.09-0.65-0.06-0.94,0.1l-1.12,0.6c-0.07,0.03-0.13,0.07-0.19,0.12
                  l-0.5,0.4l-0.72-0.23l-1.19-0.54c-0.32-0.14-0.67-0.15-0.99-0.02l-1.58,0.64l-1.35,0.6l-1.83,0.52l-0.58,0.06l-0.39-0.34l-0.48-0.88
                  c-0.05-0.08-0.1-0.15-0.16-0.22l-0.81-0.93c-0.36-0.42-0.96-0.55-1.46-0.32l-2.57,1.18c-0.34,0.16-0.59,0.45-0.69,0.81l-0.24,0.88
                  l-0.83,0.41c-0.17,0.08-0.33,0.21-0.45,0.37l-0.72,0.97l-0.67,0.65l-0.95,0.03c-0.18,0.01-0.35,0.05-0.5,0.12l-1.32,0.62
                  c-0.19,0.09-0.35,0.22-0.48,0.39l-0.53,0.72l-0.59-0.01l3.08-2.19c0.11-0.09,0.21-0.19,0.29-0.31l0.69-1.01
                  c0.04-0.05,0.07-0.1,0.09-0.16l1.71-3.55l0.6-0.94l0.72-0.89l0.7-0.55l1.11-0.39c0.59-0.21,0.93-0.82,0.8-1.44l-0.26-1.24
                  c-0.01-0.08-0.04-0.16-0.07-0.23l-0.22-0.51l0.13-0.12l1.04-0.49c0.15-0.06,0.27-0.15,0.38-0.27l0.92-0.96
                  c0.13-0.13,0.23-0.29,0.29-0.47l0.31-0.93l0.7-0.6c0.33-0.3,0.49-0.75,0.41-1.19l-0.25-1.3c-0.01-0.05-0.03-0.1-0.04-0.16l-0.44-1.3
                  l-0.45-1.1l-0.17-1.06l-0.01-1.33c0-0.15-0.03-0.29-0.08-0.43l-0.43-1.14l0.06-1.03c0.01-0.31-0.08-0.61-0.27-0.84l-0.64-0.82
                  l-0.14-0.44l0.08-0.06l0.65-0.02l1.07,0.46l0.97,0.6c0.08,0.05,0.16,0.09,0.25,0.12l1.1,0.39l1.24,0.51
                  c0.05,0.02,0.11,0.03,0.16,0.05l1.28,0.34c0.2,0.05,0.41,0.05,0.61,0.01l1.05-0.26l1.15,0.03c0.28,0.02,0.56-0.08,0.79-0.26
                  l0.98-0.75c0.28-0.21,0.45-0.53,0.48-0.88l0.09-0.94l0.42-0.68l0.69-0.67l0.96-0.43c0.04-0.02,0.08-0.04,0.11-0.06l0.96-0.55
                  l1.1-0.28c0.09-0.02,0.17-0.05,0.24-0.08l1.14-0.55c0.23-0.11,0.42-0.29,0.54-0.5l0.7-1.21c0.11-0.2,0.17-0.42,0.17-0.64l-0.01-1.36
                  l0.03-0.84l0.62-0.82c0.1-0.14,0.17-0.29,0.21-0.46l0.22-0.91l0.46-0.41l0.92-0.25c0.18-0.05,0.34-0.14,0.48-0.26l1.08-0.92
                  c0.26-0.22,0.42-0.54,0.44-0.88s-0.1-0.67-0.33-0.92l-0.39-0.42l1.46-0.32c0.37-0.07,0.69-0.31,0.87-0.64l0.59-1.11
                  c0.05-0.1,0.09-0.2,0.12-0.31l0.16-0.71l0.62-0.35c0.06-0.04,0.12-0.08,0.18-0.13l1.73-1.39l0.99-0.23
                  c0.37-0.09,0.68-0.34,0.84-0.68l0.56-1.18c0.06-0.13,0.09-0.26,0.11-0.39l0.1-0.8l0.51-0.49l0.93-0.38c0.32-0.14,0.57-0.4,0.69-0.73
                  l0.41-1.12l0.3-0.66l1.91-0.55c0.3-0.09,0.55-0.29,0.71-0.56l0.63-1.05c0.17-0.28,0.22-0.61,0.14-0.93l-0.29-1.26
                  c-0.06-0.24-0.19-0.45-0.36-0.62l-0.55-0.52l-0.06-0.75c-0.02-0.18-0.07-0.36-0.16-0.51l-0.63-1.12c-0.07-0.12-0.15-0.23-0.26-0.32
                  l-0.77-0.69l-0.33-0.64l-0.04-0.84l0.09-0.47l0.64-0.29c0.24-0.11,0.43-0.29,0.56-0.52l0.63-1.1c0.05-0.08,0.09-0.16,0.11-0.25
                  l0.3-0.96l0.6-0.54l0.94-0.39c0.1-0.04,0.19-0.09,0.27-0.15l0.93-0.71l1.17-0.67l0.95-0.61l0.89-0.3l0.71-0.07l0.84,0.46
                  c0.27,0.15,0.59,0.2,0.89,0.13l1.25-0.31c0.03,0,0.07-0.01,0.1-0.02l1.26-0.42l1.27-0.47l0.48,0.01l0.24,0.24l0.12,1.03l-0.04,1.15
                  c0,0.06,0,0.12,0.01,0.18l0.14,1.23c0.06,0.54,0.46,0.98,1,1.09l1.33,0.26c0.05,0.01,0.09,0.01,0.14,0.02l1.1,0.08l1.14,0.42
                  c0.29,0.11,0.61,0.11,0.9-0.01l1.29-0.53l1.11-0.51l0.74-0.27l0.77,0.26c0.18,0.06,0.37,0.08,0.56,0.05l1.28-0.18
                  c0.32-0.05,0.61-0.21,0.81-0.46l0.52-0.65l1.06-0.28c0.06-0.01,0.11-0.03,0.17-0.06l1.03-0.42l1.2-0.07l1.52,0.1
                  c0.32,0.01,0.65-0.09,0.9-0.31l1.06-0.93l0.77-0.54l0.56,0.08l0.58,0.41l0.34,0.98c0.12,0.34,0.37,0.61,0.71,0.75l1.27,0.52
                  c0.11,0.04,0.23,0.07,0.35,0.08l1.23,0.12c0.22,0.02,0.45-0.02,0.66-0.11l1.14-0.55l1.8-0.24l0.44,0.42
                  c0.21,0.2,0.49,0.32,0.78,0.34l0.82,0.05l0.45,0.61c0.06,0.09,0.14,0.18,0.23,0.25l1.03,0.82c0.04,0.03,0.09,0.06,0.13,0.09
                  l0.85,0.51l0.59,0.91c0.16,0.24,0.39,0.42,0.66,0.51l2.55,0.84c0.26,0.08,0.54,0.08,0.8-0.01l0.84-0.3l0.85,0.26
                  c0.45,0.13,0.93,0,1.26-0.33l0.92-0.95l0.88-0.64l1.19-0.74c0.16-0.1,0.29-0.23,0.39-0.38l0.62-0.97l0.71-0.83l0.96-0.86
                  c0.03-0.02,0.06-0.05,0.09-0.08l0.86-0.93l1.77-1.01l2.38-0.39c0.16-0.02,0.32-0.08,0.45-0.17l1.25-0.77
                  c0.11-0.06,0.2-0.14,0.29-0.23l0.79-0.9l0.72-0.62l0.75-0.2l1,0.01l1.35,0.12l1.29,0.03l2.49,0.36c0.25,0.04,0.51,0,0.74-0.11
                  l1.07-0.53l0.97-0.15l1.2,0.03c0.13,0,0.28-0.02,0.41-0.06l1.24-0.4l1.2-0.22l0.53-0.13l0.42,0.43c0.13,0.13,0.28,0.23,0.45,0.29
                  l1.16,0.43c0.17,0.06,0.35,0.09,0.53,0.08l1.34-0.1l1.07-0.15l0.92,0.24l1.28,0.44c0.17,0.06,0.36,0.08,0.54,0.06l1.4-0.15
                  c0.07,0,0.13-0.02,0.2-0.03l1.26-0.35l1.4-0.44l1.39-0.34l1.28-0.19l1.58-0.14l1.18-0.17l1.36,0.13l0.7,0.19l0.54,0.79
                  c0.02,0.04,0.05,0.07,0.08,0.11l0.87,1.01c0.2,0.22,0.46,0.37,0.76,0.41l0.96,0.15l1.81,1.49c0.09,0.08,0.19,0.14,0.3,0.19
                  l1.03,0.46l0.41,0.41l-0.02,0.73c0,0.13,0.01,0.26,0.05,0.39l0.18,0.58l-0.3,0.39l-0.94,0.83c-0.21,0.18-0.35,0.43-0.41,0.7
                  l-0.27,1.37l-0.38,1.03l-0.73,1.41c-0.26,0.51-0.15,1.13,0.28,1.51l1.45,1.3l-0.13,0.38l-0.58,1.22c-0.13,0.28-0.16,0.61-0.07,0.91
                  l0.29,0.93l-0.15,1.03l-0.33,1.11c-0.02,0.07-0.04,0.14-0.04,0.22l-0.12,1.11l-0.39,1.22c-0.13,0.41-0.04,0.87,0.24,1.2l1.02,1.18
                  c0.23,0.26,0.54,0.41,0.88,0.43l0.94,0.05l0.44,0.36l0.59,2.12l0.05,1.26c0.01,0.22,0.07,0.42,0.18,0.6l1.45,2.33
                  c0.04,0.08,0.1,0.15,0.16,0.21l0.7,0.7L558.05,74l-0.2,0.85l-0.75,0.58c-0.06,0.04-0.11,0.09-0.16,0.15l-0.81,0.88l-2.09,1.36
                  c-0.33,0.21-0.54,0.58-0.56,0.97l-0.08,1.44c-0.02,0.32,0.09,0.63,0.3,0.88l0.6,0.71l0.03,2.24c0,0.33,0.14,0.65,0.37,0.88l0.9,0.88
                  l0.82,1.13c0.33,0.45,0.94,0.63,1.47,0.42l1.3-0.51c0.04-0.02,0.09-0.04,0.14-0.06l0.82-0.45l1.2-0.06l0.92,0.07l0.85,0.63
                  c0.14,0.1,0.31,0.18,0.49,0.22l1.24,0.25c0.21,0.04,0.43,0.03,0.63-0.04l0.92-0.29l2.52,0.71c0.22,0.05,0.44,0.05,0.65-0.01
                  l1.13-0.31l1.37-0.3c0.16-0.04,0.32-0.11,0.46-0.21l1.18-0.85l1.37-1.06c0.32-0.26,0.5-0.65,0.48-1.06l-0.07-1.14l0.57-0.54
                  c0.06-0.05,0.11-0.11,0.16-0.18l0.97-1.34c0.09-0.12,0.16-0.26,0.2-0.41l0.18-0.7l0.37-0.01l0.99,0.06l1.03,0.4
                  c0.53,0.21,1.13,0.04,1.46-0.42l0.54-0.73l0.79-0.29l2.43,0.31l0.49,0.25l0.2,0.77c0.13,0.45,0.5,0.8,0.96,0.89l1.15,0.24l0.69,0.3
                  l0.38,0.63l0.41,1.1c0.2,0.54,0.76,0.88,1.33,0.8l1.2-0.16l1.43,0.35l0.3,0.71l0.06,1.01c0.01,0.15,0.05,0.3,0.1,0.43l0.47,1.06
                  l-0.01,0.71l-0.44,0.87c-0.06,0.11-0.1,0.23-0.12,0.35l-0.22,1.21c-0.01,0.06-0.02,0.13-0.02,0.2l-0.01,1.05l-0.48,0.63l-0.86,0.83
                  c-0.06,0.05-0.1,0.1-0.15,0.16l-0.83,1.13c-0.14,0.2-0.23,0.43-0.24,0.68l-0.06,1.23c-0.01,0.31,0.09,0.62,0.29,0.86l0.93,1.11
                  c0.25,0.3,0.62,0.47,1.02,0.45l1.24-0.06c0.15-0.01,0.31-0.04,0.45-0.11l0.91-0.4l0.18-0.01l0.09,0.32
                  c0.13,0.43,0.49,0.76,0.93,0.86l1.12,0.25l0.87,0.25l0.43,0.67c0.12,0.19,0.29,0.34,0.49,0.45l0.92,0.46l0.58,0.6l0.36,1.59
                  l-0.36,0.6l-0.78,1.08c-0.06,0.08-0.11,0.16-0.14,0.25l-0.49,1.18c-0.06,0.14-0.09,0.28-0.1,0.43l-0.04,1.09l-0.31,1.04
                  c-0.05,0.18-0.06,0.38-0.03,0.57l0.26,1.46c0.05,0.3,0.21,0.57,0.45,0.76l0.99,0.79c0.17,0.13,0.36,0.22,0.57,0.25L602.31,113.37z"/>
                <title>Čadca</title>
              </Link>

              {/* Ruzomberok - Zilinsky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Ružomberok")}>
                <path style={{ fill: crimesInRegionZilinsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionZilinsky[4].crimePercent)] ? crimesInRegionZilinsky[4].color : "#D3D3D3" }}
                  d="M680.74,194.93l-0.1,1.46c0,0.08,0,0.17,0.01,0.25l0.17,1.31l0.19,1.15l0.04,0.39l-0.51,0.25
                  c-0.16,0.08-0.3,0.2-0.41,0.34l-0.78,0.97c-0.06,0.07-0.11,0.15-0.16,0.24l-0.75,1.56c-0.08,0.16-0.12,0.35-0.12,0.53l-0.01,0.91
                  l-0.51,0.68c-0.02,0.03-0.05,0.07-0.07,0.11l-0.59,0.98l-0.88,0.64c-0.36,0.27-0.55,0.71-0.5,1.16l-0.51,0.06
                  c-0.43,0.05-0.8,0.32-0.99,0.72l-0.61,1.33c-0.06,0.13-0.09,0.28-0.11,0.42l-0.03,0.48l-0.3,0.05c-0.06,0.01-0.12,0.03-0.17,0.05
                  l-2.83,0.94c-0.24,0.08-0.46,0.23-0.61,0.43l-0.92,1.2c-0.15,0.19-0.23,0.41-0.25,0.65l-0.13,1.43c-0.05,0.48,0.19,0.95,0.61,1.19
                  l1.15,0.67l0.41,0.49l0.07,0.92c0.01,0.15,0.05,0.3,0.11,0.43l0.46,1l0.12,0.97l0.03,1.09l-0.12,0.81l-0.7,0.55l-1.07,0.6
                  c-0.16,0.09-0.29,0.22-0.4,0.36l-0.72,1.01c-0.02,0.03-0.04,0.06-0.06,0.09l-0.66,1.13c-0.03,0.06-0.07,0.13-0.09,0.2l-0.46,1.26
                  l-0.55,1.22l-0.59,1.14l-0.93,1.25c-0.05,0.07-0.09,0.14-0.12,0.22l-0.38,0.81l-0.79,0.51c-0.25,0.15-0.44,0.4-0.52,0.68l-0.79,2.54
                  l-2.05,1.88l-0.8,0.78l-0.97,0.52c-0.4,0.21-0.65,0.62-0.66,1.07l-0.03,1.26l-0.5,0.94c-0.02,0.05-0.05,0.11-0.07,0.16l-0.37,1.05
                  l-0.77,1.11c-0.18,0.25-0.25,0.57-0.21,0.88l0.26,1.9l0.06,1.2l-0.18,1.51l-0.18,2.77c0,0.1,0,0.21,0.02,0.31l0.21,1.11l-0.48,1.22
                  l-0.77,1.05c-0.21,0.29-0.28,0.64-0.22,0.98l0.26,1.28l0.17,1.32c0.04,0.31,0.2,0.6,0.44,0.8l1.25,1.04
                  c0.35,0.29,0.83,0.37,1.25,0.21l0.87-0.34l0.7,1.82c0.16,0.43,0.54,0.74,0.99,0.81l0.59,0.08l0.18,0.37l-0.22,2.16l-0.47,0.85
                  c-0.15,0.27-0.2,0.59-0.13,0.89l0.33,1.44c0.02,0.07,0.04,0.14,0.07,0.21l0.48,1.12l-0.79,0.64c-0.1,0.08-0.19,0.18-0.26,0.29
                  l-1.02,1.56l-2.23,2.74c-0.07,0.1-0.13,0.2-0.18,0.31l-0.43,1.07l-0.8,0.73l-1.1,1.17c-0.1,0.11-0.18,0.23-0.24,0.37l-0.46,1.07
                  l-0.37,0.44l-0.94,0.07l-1.78,0.24c-0.21,0.03-0.41,0.11-0.58,0.24l-1.22,0.92c-0.07,0.05-0.13,0.11-0.19,0.17l-2.03,2.34
                  c-0.07,0.08-0.13,0.17-0.17,0.26l-0.59,1.19l-1.58,2.65c-0.12,0.21-0.19,0.45-0.18,0.69l0.04,1.28l-0.15,1.16l-0.42,0.95l-0.7,0.92
                  l-2.43,1.76l-0.96,0.27l-1.22-0.3c-0.05-0.01-0.09-0.02-0.14-0.03l-1.22-0.16c-0.17-0.02-0.34-0.01-0.51,0.04l-3.83,1.01l-0.2-0.03
                  c0.04-0.05,0.07-0.1,0.1-0.15l0.66-1.18c0.12-0.21,0.17-0.45,0.16-0.69l-0.08-1.28c-0.01-0.05-0.01-0.11-0.02-0.16l-0.24-1.2
                  l-0.21-1.48l0.2-1.14c0.05-0.26,0.01-0.53-0.11-0.77l-0.57-1.16c-0.13-0.27-0.36-0.48-0.63-0.6l-1.19-0.5l-0.39-0.15l-0.03-0.41
                  c-0.01-0.15-0.05-0.3-0.12-0.44l-0.58-1.25c-0.08-0.18-0.2-0.33-0.36-0.45l-0.97-0.76c-0.09-0.07-0.18-0.13-0.28-0.17l-1.17-0.5
                  c-0.25-0.11-0.52-0.13-0.78-0.06l-1.13,0.27l-1.32,0.07c-0.55,0.03-1.02,0.42-1.15,0.96l-0.17,0.72l-0.12,0.08l-0.87-0.33
                  c-0.13-0.05-0.27-0.08-0.4-0.08l-2.07-0.06c-0.16,0-0.33,0.02-0.48,0.08l-1.34,0.5c-0.12,0.04-0.23,0.11-0.33,0.18l-0.76,0.59
                  l-0.91,0.17c-0.07,0.01-0.14,0.03-0.21,0.06l-1.07,0.39l-2.52,0.52c-0.3,0.07-0.56,0.24-0.74,0.48l-0.46,0.64l-0.41,0.09l-2.27-0.83
                  c-0.08-0.03-0.17-0.05-0.25-0.06l-1.25-0.17c-0.38-0.05-0.76,0.07-1.03,0.34l-0.93,0.89c-0.09,0.08-0.16,0.17-0.22,0.26l-0.47,0.8
                  l-0.02,0.01l-0.29-0.29c-0.05-0.05-0.11-0.1-0.17-0.14l-1.15-0.79c-0.24-0.16-0.53-0.24-0.82-0.22l-1.6,0.15
                  c-0.09,0-0.19,0.02-0.28,0.05l-1.18,0.39l-1.06,0.04c-0.05,0-0.09,0.01-0.14,0.01l-1.22,0.19l-2.41-0.05c-0.06,0-0.12,0-0.18,0.01
                  l-1.34,0.15c-0.04,0-0.08,0.01-0.12,0.02l-1.55,0.33c-0.15,0.03-0.3,0.09-0.43,0.18l-0.84,0.55l-0.89,0.01l-1.45-0.02
                  c-0.26,0-0.58,0.09-0.8,0.28l-0.31,0.25l0.06-0.26c0.01-0.04,0.01-0.08,0.02-0.12l0.26-1.83l0.32-1.17
                  c0.08-0.29,0.06-0.59-0.07-0.86l-0.68-1.49l0.38-0.97c0.1-0.24,0.12-0.49,0.06-0.74l-0.41-1.76l0.18-1.09l0.24-0.48l0.73-0.14
                  c0.54-0.11,0.95-0.58,1-1.13l0.1-1.32l0.19-0.88l0.69-0.79c0.12-0.13,0.2-0.29,0.26-0.46l0.25-0.82l0.63-0.42l1.18-0.6l1.13-0.48
                  c0.08-0.03,0.15-0.07,0.22-0.12l1.15-0.8c0.11-0.08,0.2-0.16,0.27-0.26l0.39-0.49l0.69,0.01c0.06,0,0.13,0,0.19-0.01l1.25-0.18
                  c0.23-0.04,0.46-0.14,0.64-0.29l0.93-0.8c0.14-0.13,0.25-0.28,0.33-0.46l0.42-0.98l0.62-0.73l0.88-0.74
                  c0.16-0.13,0.28-0.3,0.36-0.48l0.48-1.16c0.06-0.16,0.09-0.33,0.09-0.5l-0.02-1.33c0-0.07-0.01-0.13-0.02-0.2l-0.22-1.22
                  c-0.06-0.33-0.25-0.62-0.52-0.81l-0.83-0.57l-0.38-0.8c-0.15-0.34-0.45-0.58-0.81-0.68l-1.28-0.34c-0.06-0.01-0.12-0.02-0.17-0.03
                  l-0.85-0.1l-0.43-0.58l-0.59-1.27l-0.47-1.14c-0.08-0.18-0.2-0.34-0.34-0.47l-2.45-2.07l-0.32-0.63l0.01-0.97l0.1-1.39l0.01-0.71
                  l0.5-0.44c0.32-0.29,0.47-0.72,0.4-1.14l-0.2-1.23c-0.02-0.18-0.09-0.35-0.19-0.5l-0.59-0.87l-0.22-0.73l0.18-0.76l0.49-0.61
                  l1.02-0.7c0.22-0.15,0.39-0.37,0.47-0.63l0.45-1.32c0.04-0.11,0.06-0.23,0.07-0.35l0.05-1.3c0-0.07,0-0.13-0.01-0.2l-0.12-0.97
                  l0.33-1.06c0.04-0.11,0.06-0.22,0.06-0.33l0.04-1.38l-0.02-1.29c0-0.11-0.02-0.23-0.05-0.34l-0.28-0.92l0.21-0.86
                  c0.09-0.41-0.02-0.83-0.3-1.13l-0.37-0.41l0.71-1.77c0.1-0.26,0.12-0.55,0.04-0.82l-0.32-1.16l0.04-1.18l0.2-1.11l0.28-1.13
                  l0.34-0.64l0.83-0.42c0.18-0.09,0.33-0.22,0.45-0.38l0.76-1.03c0.24-0.33,0.31-0.76,0.17-1.15l-0.4-1.16l-0.16-1.23l-0.03-1.18
                  c0-0.12-0.02-0.25-0.06-0.36l-0.27-0.81l0.29-0.81c0.05-0.14,0.07-0.29,0.07-0.44l-0.02-1.11l0.24-1.19
                  c0.05-0.22,0.03-0.45-0.04-0.66l-1.23-3.58l-0.11-0.81l0.95-2.09c0.02-0.04,0.04-0.08,0.05-0.13l0.41-1.31
                  c0.08-0.25,0.08-0.52-0.01-0.77l-0.38-1.13l-0.09-1.11l0.19-0.51l0.52-0.36l1-0.21c0.31-0.06,0.58-0.23,0.76-0.49l0.68-0.93
                  l1.72-1.74c0.12-0.11,0.21-0.25,0.28-0.41l0.47-1.15c0.05-0.13,0.08-0.28,0.09-0.43l0.05-1.48c0.01-0.22-0.04-0.43-0.15-0.63
                  l-0.38-0.72l0.33-1.37c0.07-0.11,0.12-0.24,0.14-0.37l0.21-1.15l0.43-1.2c0.01-0.04,0.02-0.08,0.03-0.12l0.33-1.34l0.21-1.22
                  c0.04-0.22,0.02-0.45-0.06-0.66l-0.54-1.39c-0.15-0.39-0.49-0.68-0.89-0.77l-0.47-0.11l-0.07-0.49c-0.02-0.11-0.05-0.22-0.09-0.32
                  l-0.5-1.17c-0.05-0.11-0.1-0.2-0.18-0.29l-0.39-0.49l0.14-0.56l0.08-0.23l0.33,0.03c0.13,0.02,0.25,0.01,0.36-0.01l1.21-0.24
                  c0.33-0.07,0.61-0.26,0.8-0.53l0.3-0.46l0.35,0.05l0.75,0.38l0.61,0.7l0.76,1.04c0.2,0.29,0.52,0.47,0.87,0.51l1.25,0.14
                  c0.19,0.02,0.39,0,0.57-0.07l1.14-0.42l1-0.16l1.13,0.12l0.9,0.35l0.68,0.66l0.53,0.94c0.02,0.05,0.05,0.09,0.08,0.13l0.68,0.95
                  l0.28,0.79l-0.09,1.01c-0.03,0.25,0.02,0.51,0.15,0.73l0.39,0.71l-0.12,0.73l-0.41,1.13c-0.2,0.56,0.03,1.19,0.54,1.5l1.13,0.66
                  c0.11,0.07,0.22,0.11,0.33,0.14l1.19,0.3c0.61,0.16,1.24-0.16,1.47-0.75l0.44-1.09l1.14-1.46l0.9-0.21
                  c0.22-0.05,0.42-0.16,0.58-0.31l0.97-0.92l0.93-0.74c0.08-0.06,0.15-0.13,0.21-0.2l0.81-1.02l0.8-0.73l1.04-0.67
                  c0.05-0.04,0.11-0.08,0.16-0.12l1-0.91l0.78-0.81l0.85-0.54l0.48-0.03l0.68,0.48c0.12,0.09,0.27,0.15,0.42,0.19l0.97,0.25l0.56,0.51
                  l0.3,1.04c0.02,0.09,0.06,0.18,0.1,0.26l0.66,1.21c0.06,0.11,0.13,0.2,0.22,0.29l1.09,1.08c0.42,0.41,1.07,0.48,1.57,0.15l0.79-0.52
                  l1.8-0.16l0.75,0.65c0.36,0.3,0.86,0.38,1.29,0.21l0.79-0.32l1.14,0.36c0.34,0.11,0.7,0.07,1-0.11l0.94-0.53l0.88,0.39
                  c0.06,0.02,0.12,0.04,0.18,0.06l1.31,0.34l1.1,0.57c0.17,0.09,0.37,0.14,0.57,0.14h2.31l1.39,0.15c0.34,0.04,0.67-0.06,0.93-0.27
                  c0.26-0.22,0.43-0.53,0.46-0.86l0.1-1.28l0.61-0.75c0.11-0.14,0.19-0.3,0.23-0.48l0.31-1.25c0.02-0.05,0.03-0.11,0.03-0.16
                  l0.12-1.04l0.71-0.97l0.86-0.71c0.27-0.23,0.44-0.55,0.46-0.91l0.05-0.95l0.75-0.56l1.07-0.58c0.04-0.02,0.08-0.04,0.11-0.07
                  l0.61-0.41l0.54,0.16l1.07,0.46c0.27,0.11,0.57,0.13,0.85,0.05l0.53-0.16l0.44,0.37c0.17,0.14,0.39,0.24,0.61,0.28l1.26,0.19
                  c0.22,0.03,0.45,0,0.65-0.08l1.02-0.41l1.16-0.07c0.08-0.01,0.15-0.02,0.22-0.04l0.72-0.18l0.2,0.3c0.26,0.37,0.69,0.58,1.14,0.55
                  l1-0.09l0.9,0.27l0.3,0.13l-0.04,3.19c0,0.06,0,0.12,0.01,0.18l0.17,1.31c0.01,0.07,0.02,0.13,0.04,0.2l0.33,1.1l0.06,1l-0.15,1.14
                  c-0.03,0.27,0.02,0.53,0.15,0.76l0.63,1.14c0.06,0.13,0.15,0.24,0.26,0.33l0.49,0.45l-0.08,0.57c-0.06,0.41,0.1,0.82,0.41,1.1
                  l0.94,0.84c0.06,0.05,0.12,0.1,0.19,0.14l1.06,0.64l0.84,0.94c0.12,0.13,0.28,0.24,0.45,0.31l1.14,0.48
                  c0.34,0.15,0.72,0.13,1.05-0.04l0.52-0.26l0.29,0.17l0.39,0.44l-0.03,0.69l-0.49,1.03C680.8,194.62,680.75,194.77,680.74,194.93z"/>
                <title>Ružomberok</title>
              </Link>


              {/* Dolny Kubin - Zilinsky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Dolný Kubín")}>
                <path style={{ fill: crimesInRegionZilinsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionZilinsky[1].crimePercent)] ? crimesInRegionZilinsky[1].color : "#D3D3D3" }}
                  d="M704.34,147.51l-1.01,0.51l-2.7,0.48c-0.34,0.06-0.63,0.26-0.82,0.55l-0.74,1.13
                  c-0.05,0.08-0.09,0.17-0.13,0.26l-0.44,1.23c-0.02,0.08-0.04,0.16-0.06,0.24l-0.18,1.27l-0.34,1.1c-0.02,0.07-0.03,0.14-0.04,0.21
                  l-0.13,1.08l-0.59,1.06l-0.86,0.91l-1.15,1.03c-0.2,0.17-0.34,0.41-0.39,0.68l-0.2,0.95l-0.5,0.61l-0.93,0.54
                  c-0.04,0.03-0.08,0.06-0.12,0.09l-1.04,0.8c-0.11,0.08-0.2,0.18-0.28,0.29l-0.66,0.99l-0.43,0.28l-0.7-0.32
                  c-0.55-0.25-1.21-0.07-1.55,0.44l-0.75,1.1c-0.02,0.02-0.04,0.05-0.06,0.08l-0.68,1.2c-0.02,0.05-0.04,0.09-0.06,0.14l-0.5,1.2
                  c-0.08,0.19-0.11,0.39-0.1,0.59l0.22,2.58l-0.12,1.27l-0.17,0.67l-0.62,0.47l-0.53,0.02l-0.7-0.45c-0.25-0.16-0.55-0.23-0.84-0.19
                  l-1.56,0.22c-0.32,0.05-0.61,0.21-0.81,0.46l-0.96,1.21l-0.17,0.16l-0.17-0.14c-0.29-0.26-0.68-0.36-1.06-0.29l-2.14,0.42h-0.1
                  l-0.01-0.09l0.04-1.2c0.01-0.39-0.16-0.77-0.47-1.02c-0.31-0.24-0.71-0.33-1.09-0.23l-1.24,0.32c-0.23,0.06-0.44,0.19-0.61,0.36
                  l-0.87,0.94c-0.06,0.07-0.11,0.14-0.15,0.21l-0.55-0.16c-0.14-0.04-0.29-0.06-0.45-0.05l-0.5,0.04l-0.28-0.41
                  c-0.3-0.43-0.83-0.64-1.34-0.51l-1.46,0.36l-1.25,0.08c-0.13,0.01-0.26,0.04-0.38,0.09l-0.89,0.35l-0.57-0.08l-0.68-0.58
                  c-0.33-0.27-0.76-0.36-1.17-0.24l-0.74,0.22l-0.73-0.31c-0.04-0.02-0.09-0.04-0.14-0.05l-1.18-0.34c-0.36-0.1-0.74-0.04-1.05,0.17
                  l-1.04,0.7l-1.09,0.59c-0.06,0.03-0.11,0.06-0.16,0.1l-1.3,0.97c-0.3,0.22-0.48,0.56-0.5,0.93l-0.05,0.99l-0.56,0.46
                  c-0.08,0.07-0.16,0.15-0.22,0.23l-1,1.37c-0.13,0.18-0.21,0.39-0.23,0.6l-0.14,1.29l-0.23,0.9l-0.67,0.84
                  c-0.15,0.19-0.24,0.43-0.27,0.67l-0.03,0.38l-0.18-0.02c-0.05-0.01-0.09-0.01-0.14-0.01h-2.08l-0.95-0.49
                  c-0.08-0.04-0.16-0.08-0.25-0.1l-1.35-0.35l-1.37-0.61c-0.36-0.15-0.78-0.13-1.12,0.07l-1,0.57l-1.05-0.33
                  c-0.28-0.09-0.58-0.08-0.85,0.03l-0.5,0.2l-0.57-0.49c-0.26-0.21-0.58-0.32-0.91-0.3l-2.69,0.25c-0.2,0.02-0.39,0.09-0.56,0.2
                  l-0.2,0.13l-0.24-0.24l-0.51-0.92l-0.35-1.25c-0.06-0.22-0.18-0.43-0.36-0.58l-1.06-0.97c-0.15-0.14-0.34-0.24-0.54-0.29l-1.05-0.27
                  l-0.84-0.59c-0.23-0.16-0.51-0.24-0.79-0.23l-1.24,0.07c-0.21,0.01-0.42,0.08-0.6,0.19l-1.25,0.79c-0.08,0.06-0.16,0.12-0.23,0.19
                  l-0.83,0.86l-0.86,0.78l-1.04,0.67c-0.05,0.04-0.11,0.08-0.16,0.12l-0.96,0.87c-0.05,0.05-0.1,0.1-0.14,0.15l-0.78,0.99l-0.86,0.68
                  c-0.03,0.02-0.06,0.05-0.09,0.07l-0.76,0.73l-1,0.23c-0.28,0.07-0.52,0.22-0.7,0.44l-1.54,1.98c-0.07,0.09-0.13,0.19-0.17,0.3
                  l-0.09,0.22l-0.05-0.03l0.1-0.26c0.02-0.07,0.04-0.14,0.05-0.21l0.22-1.27c0.05-0.28,0-0.57-0.14-0.82l-0.42-0.76l0.08-0.9
                  c0.02-0.18,0-0.37-0.06-0.54l-0.43-1.22c-0.04-0.11-0.1-0.22-0.17-0.31l-0.74-1.03l-0.57-1.04c-0.06-0.11-0.14-0.21-0.23-0.3
                  l-1-0.97c-0.12-0.12-0.27-0.21-0.43-0.27l-1.29-0.49c-0.1-0.04-0.2-0.06-0.3-0.07l-1.46-0.16c-0.12-0.02-0.23-0.01-0.34,0.01
                  l-1.29,0.21c-0.08,0.01-0.16,0.03-0.23,0.06l-0.97,0.36l-0.41-0.05l-0.47-0.64c-0.02-0.03-0.04-0.06-0.07-0.09l-0.81-0.93
                  c-0.1-0.12-0.23-0.22-0.38-0.3l-1.16-0.58c-0.12-0.06-0.26-0.1-0.39-0.12l-1.32-0.18c-0.47-0.06-0.94,0.15-1.21,0.54l-0.45,0.67
                  l-0.53,0.11l-1.11-0.1c-0.58-0.05-1.1,0.28-1.3,0.82l-0.43,1.2c-0.01,0.04-0.02,0.08-0.03,0.12l-0.3,1.22
                  c-0.1,0.38-0.01,0.78,0.24,1.08l0.67,0.84l0.37,0.86l0.16,1.18c0.07,0.52,0.46,0.93,0.96,1.05l0.66,0.15l0.18,0.45l-0.13,0.79
                  l-0.3,1.19l-0.44,1.24c-0.02,0.06-0.04,0.13-0.05,0.19l-0.01,0.08l-1.03-0.21l-0.82-0.56c-0.03-0.01-0.06-0.03-0.09-0.05l-1.08-0.61
                  c-0.22-0.13-0.48-0.18-0.74-0.15l-1.27,0.13c-0.11,0.01-0.22,0.03-0.32,0.07l-1.32,0.51l-1.2-0.03l-0.73,0.02l-0.29-0.38l-0.25-0.9
                  l-0.24-1.39c-0.01-0.06-0.02-0.12-0.04-0.18l-0.42-1.27c-0.07-0.21-0.19-0.39-0.35-0.53l-0.8-0.74l-1.22-1.96l-0.39-1.03
                  c-0.06-0.16-0.15-0.31-0.27-0.43l-0.7-0.72l-0.39-0.9l-0.3-0.84l0.18-0.73l0.46-1l0.57-1.09c0.02-0.05,0.04-0.09,0.06-0.14
                  l0.44-1.17c0.08-0.21,0.1-0.44,0.06-0.67l-0.21-1.12v-1.12c0-0.12-0.02-0.24-0.05-0.36l-0.62-2.1l0.14-1.01
                  c0.04-0.35-0.05-0.69-0.27-0.96l-0.57-0.7l-0.16-0.96l-0.26-1.12l0.01-0.92l0.55-1.87l0.04,0.03c0.07,0.05,0.14,0.1,0.22,0.14
                  l1.22,0.6l1.18,0.5c0.52,0.22,1.11,0.07,1.46-0.36l0.84-1.04c0.07-0.09,0.13-0.18,0.17-0.28l0.58-1.32l0.46-1.26
                  c0.05-0.12,0.07-0.24,0.08-0.36l0.07-1.32c0.01-0.16-0.02-0.32-0.07-0.47l-0.39-1.17l-0.03-1.32l0.42-0.91
                  c0.01-0.04,0.03-0.07,0.04-0.1l0.83-2.45c0.06-0.19,0.08-0.4,0.04-0.59l-0.17-1.07l0.19-1.04c0.01-0.05,0.01-0.09,0.02-0.13
                  l0.13-1.63l0.16-1.32c0.02-0.2,0-0.4-0.07-0.58l-0.44-1.19c-0.03-0.09-0.07-0.18-0.12-0.25l-0.53-0.81l0.02-0.81l0.13-1.27
                  c0.01-0.08,0.01-0.17,0-0.25l-0.12-1.23c0-0.09-0.02-0.17-0.05-0.25l-0.37-1.18c-0.02-0.08-0.05-0.16-0.09-0.23l-0.38-0.69l0.71-2.3
                  c0.07-0.25,0.07-0.5-0.01-0.75l-0.25-0.8l0.37-0.43c0.09-0.11,0.16-0.23,0.21-0.36l0.44-1.15l0.55-1c0.03-0.05,0.05-0.1,0.07-0.15
                  l0.43-1.08l0.71-0.81c0.16-0.18,0.26-0.4,0.3-0.64l0.08-0.59l0.49-0.19l1.13-0.32l1.36-0.29c0.24-0.05,0.47-0.17,0.64-0.35l7-7.3
                  l0.11,0.45l0.06,1.13c0.03,0.6,0.49,1.09,1.08,1.17l0.82,0.11l0.59,0.6c0.21,0.22,0.48,0.35,0.78,0.38l1.82,0.16h0.13l1.7-0.03
                  c0.04,0,0.08,0,0.12-0.01l1.54-0.17c0.04,0,0.08-0.01,0.11-0.02l1.68-0.35c0.29-0.06,0.54-0.21,0.72-0.43l0.75-0.93l0.73-0.04
                  c0.22-0.01,0.43-0.08,0.61-0.2l0.65-0.42l1.8,0.94c0.14,0.08,0.3,0.13,0.46,0.14l1.37,0.15l1.32,0.08l0.83,0.11l0.13,0.23
                  l-0.14,0.88c-0.01,0.11-0.01,0.22,0,0.33l0.14,1.3l-0.44,3.8c-0.04,0.32,0.06,0.65,0.26,0.9l0.65,0.83l0.44,1.16
                  c0.02,0.05,0.04,0.09,0.07,0.14l0.46,0.87l-0.02,0.91l-0.25,1.12c-0.05,0.22-0.04,0.44,0.03,0.66l0.6,1.83
                  c0.11,0.34,0.36,0.61,0.69,0.75c0.16,0.08,0.33,0.11,0.5,0.11c0.18,0,0.35-0.04,0.51-0.11l1.27-0.57c0.18-0.08,0.34-0.21,0.46-0.36
                  l0.88-1.11c0.15-0.19,0.24-0.42,0.26-0.66l0.09-0.85l0.56-0.54c0.08-0.07,0.15-0.15,0.2-0.24l0.63-1.01l0.72-0.87
                  c0.03-0.04,0.06-0.08,0.09-0.13l0.72-1.12l0.28-0.51l0.66-0.03c0.22-0.01,0.43-0.08,0.62-0.2l1.2-0.78l0.83-0.62l2.35-0.48
                  c0.09-0.02,0.18-0.04,0.27-0.08l0.74-0.31c0.14,0.36,0.45,0.65,0.85,0.76l1.19,0.32c0.1,0.02,0.2,0.04,0.31,0.04l2.77,0.06
                  c0.17,0,0.33-0.02,0.48-0.08l1.38-0.5l2.36-0.73l0.98-0.2l0.8,0.24l2.06,1.05l0.75,0.75c0.29,0.29,0.71,0.42,1.11,0.34l2.33-0.43
                  l1.95,1.4l0.89,0.52l0.61,0.89c0.35,0.5,1.01,0.68,1.56,0.42l1.32-0.62c0.06-0.03,0.11-0.06,0.17-0.09l1.28-0.86
                  c0.11-0.08,0.2-0.17,0.29-0.27l0.73-0.94l1.04-1.18c0.07-0.08,0.13-0.17,0.18-0.27l0.5-0.99l0.73-0.79l1.14-1.07
                  c0.04-0.04,0.08-0.09,0.12-0.14l1.55-1.97c0.07,0.06,0.16,0.13,0.25,0.18l0.93,0.5l2.39,3.31c0.05,0.08,0.12,0.15,0.19,0.21
                  l1.02,0.91c0.12,0.11,0.26,0.2,0.42,0.25l0.84,0.3l1.49,1.85l0.47,1c0.13,0.29,0.38,0.52,0.68,0.63l1.2,0.47l1.23,0.42
                  c0.1,0.04,0.21,0.06,0.31,0.07l1.31,0.09c0.08,0,0.16,0,0.24-0.01l1.23-0.15c0.09-0.01,0.18-0.03,0.26-0.06l1.13-0.4l1.01-0.28
                  l0.52,0.05l0.42,0.46l0.1,0.81c0.04,0.39,0.26,0.73,0.59,0.93l0.5,0.3l0.04,0.93c0.01,0.25,0.09,0.48,0.23,0.67l0.69,0.97l0.67,1.11
                  c0.16,0.26,0.4,0.45,0.69,0.54l1.23,0.39c0.39,0.13,0.82,0.05,1.14-0.2l0.51-0.39l0.29,0.07l0.6,0.64c0.12,0.13,0.27,0.23,0.43,0.29
                  l1.58,0.66l1.15,0.54c0.11,0.05,0.22,0.09,0.35,0.11l1.48,0.22l0.18,0.36l0.09,2.47c0,0.04,0,0.08,0.01,0.12l0.18,1.52l0.28,1.46
                  l0.5,1.96l0.22,1.67c0.05,0.4,0.29,0.75,0.65,0.94L704.34,147.51z"/>
                <title>Dolný Kubín</title>
              </Link>


              {/* Liptovsky mikulas - Zilinsky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Liptovský Mikuláš")}>
                <path style={{ fill: crimesInRegionZilinsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionZilinsky[2].crimePercent)] ? crimesInRegionZilinsky[2].color : "#D3D3D3" }}
                  d="M836.75,271.11l-0.03,0.04c-0.13,0.16-0.21,0.35-0.25,0.55l-0.13,0.69l-0.71,0.37
                  c-0.13,0.07-0.25,0.16-0.35,0.27l-0.83,0.92c-0.03,0.04-0.06,0.07-0.08,0.11l-0.67,0.92l-1.21,0.95l-1.06,0.57l-2.86,1.33
                  l-1.65,0.37l-2.18,0.1l-1.32-0.15c-0.22-0.03-0.44,0.01-0.65,0.1l-1.41,0.63l-0.54-0.6c-0.05-0.05-0.09-0.09-0.15-0.13l-1.61-1.3
                  c-0.03-0.03-0.07-0.06-0.11-0.09l-1.31-0.83c-0.37-0.23-0.84-0.25-1.23-0.06l-1.1,0.55c-0.21,0.11-0.38,0.26-0.5,0.46l-0.52,0.83
                  l-0.82,0.35c-0.05,0.03-0.11,0.05-0.16,0.09l-1.19,0.74l-0.91,0.23l-1.08,0.06l-1.14-0.09c-0.08,0-0.16,0-0.24,0.01l-1.74,0.21
                  l-1.44,0.06c-0.17,0.01-0.33,0.05-0.49,0.12l-0.62,0.3l-0.6-0.4c-0.09-0.06-0.19-0.11-0.3-0.15l-1.41-0.46
                  c-0.2-0.06-0.41-0.08-0.62-0.04l-1.15,0.21l-1.66-0.51c-0.06-0.02-0.12-0.04-0.19-0.05l-1.74-0.26c-0.18-0.02-0.35-0.01-0.53,0.04
                  l-2.02,0.58l-2.72-0.44c-0.42-0.08-0.86,0.08-1.14,0.41l-0.57,0.65l-0.82,0.08l-1.19-0.55c-0.18-0.09-0.38-0.12-0.58-0.12
                  l-1.68,0.07c-0.04,0-0.08,0.01-0.13,0.01l-1.34,0.19c-0.15,0.03-0.31,0.08-0.45,0.16l-1.04,0.6l-0.72,0.32l-0.94-0.16l-1.11-0.27
                  l-1.22-0.69c-0.09-0.05-0.18-0.09-0.28-0.12l-0.69-0.19l-0.74-1.85c-0.2-0.5-0.69-0.81-1.22-0.8l-1.24,0.05
                  c-0.06,0-0.13,0.01-0.19,0.02l-0.97,0.19l-0.91-0.31c-0.38-0.12-0.79-0.06-1.12,0.17l-0.87,0.61l-1.26,0.4l-1.17,0.47
                  c-0.05,0.02-0.09,0.04-0.14,0.07l-2.28,1.25l-0.99,0.62l-0.94,0.42l-1.15,0.28l-1.21,0.14c-0.13,0.01-0.26,0.05-0.38,0.1l-1.12,0.51
                  c-0.16,0.07-0.3,0.18-0.41,0.31l-0.74,0.83l-1.73,0.85l-0.76-0.14l-0.57-0.62l-0.05-0.9c-0.01-0.19-0.06-0.38-0.16-0.54l-0.64-1.16
                  c-0.22-0.41-0.63-0.67-1.1-0.65l-1.33,0.01l-1.14-0.24l-1.45-1l-0.79-0.86l-0.63-0.95c-0.07-0.11-0.17-0.22-0.28-0.3l-2.67-2.13
                  c-0.09-0.08-0.2-0.15-0.32-0.2l-1.37-0.56c-0.19-0.08-0.41-0.11-0.62-0.08l-1.49,0.18c-0.11,0.01-0.21,0.04-0.31,0.08l-2.39,0.94
                  c-0.21,0.08-0.39,0.22-0.53,0.39l-0.3,0.39l-0.56-0.13l-1.69-1.08c-0.07-0.05-0.14-0.09-0.22-0.12l-0.98-0.37l-1.06-0.83l-0.76-0.51
                  l-0.98-2.41c-0.11-0.27-0.31-0.49-0.56-0.63l-2.12-1.16c-0.07-0.03-0.14-0.06-0.21-0.09l-1.42-0.47c-0.13-0.04-0.26-0.06-0.41-0.06
                  l-1.19,0.02l-1.05-0.35l-1.21-0.58c-0.07-0.03-0.15-0.06-0.22-0.08l-1.26-0.33c-0.2-0.05-0.41-0.05-0.61-0.01l-1.35,0.33l-0.88,0.12
                  l-1.01-0.36l-2.03-0.58c-0.29-0.08-0.6-0.06-0.87,0.07l-1.71,0.8l-0.67,0.37l-1.05-0.25c-0.08-0.01-0.16-0.03-0.24-0.03l-1.23-0.05
                  l-1.24-0.41c-0.15-0.05-0.31-0.07-0.47-0.06l-1.27,0.09h-1.21l-0.86-0.11l-0.48-0.6c-0.06-0.08-0.13-0.15-0.2-0.21l-1.1-0.87
                  c-0.27-0.21-0.62-0.3-0.95-0.26l-1.37,0.19c-0.21,0.03-0.4,0.11-0.56,0.23l-1.78,1.29l-0.94,0.78l-0.91,0.62l-0.76-0.18l-1.13-0.44
                  c-0.18-0.07-0.36-0.1-0.55-0.09l-1.57,0.12l-1.05-0.22l-0.79-0.59c-0.49-0.37-1.17-0.33-1.61,0.09l-0.92,0.88l-0.9-0.01
                  c-0.38-0.01-0.66,0.13-0.9,0.37l-0.56,0.57l-1.02,0.1c-0.2,0.01-0.4,0.08-0.57,0.2l-2.09,1.38c-0.04,0.03-0.09,0.06-0.13,0.1
                  l-0.99,0.86l-0.83-0.05c-0.32-0.02-0.65,0.09-0.89,0.31l-0.99,0.86l-2.61,0.35l-4.85,0.16l-1.12-0.24c-0.18-0.04-0.36-0.04-0.53,0
                  l-2.47,0.55l-1.22-0.47c-0.23-0.08-0.48-0.1-0.72-0.05l-1.63,0.36l-0.43-1l-0.19-0.86l0.36-0.66c0.08-0.15,0.14-0.31,0.15-0.48
                  l0.28-2.78c0.03-0.23-0.02-0.46-0.12-0.67l-0.63-1.28c-0.18-0.37-0.53-0.63-0.94-0.69l-0.5-0.07l-0.99-2.35
                  c-0.26-0.61-0.96-0.91-1.59-0.67l-1.29,0.5l-0.31-0.26l-0.12-0.91l-0.17-0.83l0.54-0.73c0.06-0.09,0.12-0.18,0.15-0.28l0.67-1.71
                  c0.09-0.22,0.11-0.46,0.07-0.69l-0.24-1.31l0.16-2.55l0.18-1.54c0.01-0.06,0.01-0.13,0.01-0.19l-0.06-1.36
                  c0-0.04-0.01-0.08-0.01-0.12l-0.2-1.48l0.58-0.84c0.06-0.09,0.11-0.19,0.15-0.29l0.4-1.13l0.59-1.12c0.09-0.17,0.14-0.36,0.14-0.55
                  l0.02-0.82l0.48-0.26c0.1-0.05,0.2-0.12,0.28-0.2l2.16-2l1.04-0.99c0.16-0.15,0.27-0.34,0.33-0.54l0.75-2.41l0.71-0.46
                  c0.2-0.12,0.37-0.31,0.47-0.53l0.47-1.03l0.91-1.23c0.03-0.05,0.07-0.1,0.1-0.16l0.67-1.29l0.61-1.37l0.46-1.23l0.57-0.98l0.53-0.74
                  l0.9-0.51c0.05-0.03,0.1-0.07,0.14-0.1l1.18-0.91c0.26-0.2,0.43-0.49,0.48-0.82l0.2-1.42c0.01-0.06,0.01-0.13,0.01-0.2l-0.03-1.25
                  c0-0.04,0-0.08-0.01-0.12l-0.15-1.22c-0.01-0.13-0.05-0.25-0.1-0.37l-0.45-0.98l-0.08-1.09c-0.02-0.26-0.12-0.51-0.29-0.71
                  l-0.81-0.97c-0.09-0.11-0.21-0.21-0.33-0.28l-0.65-0.38l0.02-0.28l0.46-0.6l2.44-0.81l1.14-0.22c0.55-0.11,0.96-0.57,1.01-1.13
                  l0.09-1.2l0.23-0.49l1.09-0.14c0.34-0.04,0.64-0.22,0.85-0.49c0.2-0.27,0.29-0.61,0.24-0.94l-0.08-0.48l0.47-0.34
                  c0.13-0.1,0.25-0.22,0.33-0.37l0.69-1.14l0.72-0.96c0.16-0.21,0.25-0.47,0.25-0.74l0.01-1.04l0.56-1.18l0.52-0.65l1.05-0.52
                  c0.47-0.24,0.74-0.74,0.68-1.26l-0.48-3.63l0.07-1.09l0.51-1.05c0.07-0.15,0.11-0.32,0.12-0.49l0.06-1.45
                  c0.01-0.32-0.1-0.63-0.31-0.87l-0.85-0.97c-0.09-0.1-0.19-0.19-0.31-0.26l-1.06-0.62c-0.37-0.21-0.82-0.23-1.2-0.03l-0.61,0.31
                  l-0.35-0.14l-0.78-0.87c-0.08-0.1-0.18-0.18-0.29-0.24l-1.12-0.67l-0.35-0.32l0.08-0.57c0.06-0.41-0.1-0.82-0.4-1.1l-0.84-0.75
                  l-0.33-0.6l0.11-0.85c0.01-0.08,0.01-0.15,0.01-0.23l-0.07-1.26c-0.01-0.1-0.02-0.19-0.05-0.29l-0.34-1.14l-0.15-1.12l0.03-2.81
                  c0.17,0.1,0.35,0.15,0.55,0.15l1.37,0.03c0.09,0,0.18-0.01,0.27-0.02l1.67-0.33l0.57,0.5c0.23,0.21,0.53,0.31,0.82,0.31
                  c0.31,0,0.63-0.12,0.87-0.35l1.06-1.02c0.04-0.04,0.07-0.08,0.11-0.12l0.7-0.88l0.61-0.09l0.64,0.41c0.21,0.14,0.46,0.21,0.72,0.2
                  l1.3-0.05c0.25-0.01,0.49-0.1,0.7-0.25l1.29-0.96c0.23-0.17,0.39-0.41,0.46-0.69l0.31-1.2c0.02-0.06,0.03-0.13,0.04-0.2l0.13-1.47
                  v-0.2l-0.2-2.41l0.35-0.85l0.62-1.09l0.13-0.18l0.37,0.17c0.39,0.18,0.85,0.14,1.21-0.1l1.22-0.81c0.14-0.09,0.26-0.21,0.35-0.34
                  l0.68-1.02l0.82-0.63l1.05-0.62c0.13-0.08,0.24-0.17,0.34-0.29l0.83-1.01c0.12-0.15,0.21-0.34,0.25-0.54l0.18-0.85l0.88-0.79
                  c0.03-0.02,0.05-0.05,0.08-0.07l1.01-1.07c0.07-0.08,0.13-0.16,0.18-0.26l0.78-1.41c0.08-0.14,0.13-0.29,0.15-0.45l0.15-1.21
                  l0.33-1.09c0.01-0.06,0.03-0.12,0.04-0.18l0.18-1.24l0.35-0.97l0.36-0.56l2.33-0.41c0.12-0.02,0.24-0.06,0.36-0.12l1.38-0.71
                  l1.33-0.56c0.04-0.01,0.08-0.03,0.11-0.05l1.08-0.58l0.09,0.01c0.03,0.01,0.07,0.01,0.1,0.01l1.17,0.04l1.39,0.42l0.89,0.32
                  l0.77,0.88c0.1,0.11,0.22,0.2,0.35,0.27l0.69,0.37l0.27,0.64l0.09,0.89l-0.25,1.1c-0.09,0.39,0.02,0.8,0.29,1.1l0.9,1.01
                  c0.21,0.24,0.49,0.38,0.81,0.41l1.04,0.11l0.87,0.49l0.98,0.8l1.28,1.27c0.24,0.23,0.55,0.36,0.9,0.36l1.38-0.02l0.8,0.62
                  c0.1,0.09,0.22,0.15,0.34,0.19l1.62,0.59c0.18,0.07,0.36,0.09,0.54,0.07l1.21-0.11l1.28,0.3l1.91,0.36
                  c0.33,0.06,0.68-0.01,0.95-0.21l1.29-0.91c0.24-0.17,0.41-0.42,0.49-0.7l0.3-1.14l0.58-0.69l1.35-0.83c0.05-0.04,0.1-0.07,0.15-0.11
                  l1.73-1.42l0.79-0.03l0.79,0.07l0.61,0.5l0.39,0.81l-0.24,0.83c-0.13,0.46,0.01,0.96,0.37,1.28l0.79,0.71l0.55,0.98
                  c0.12,0.22,0.31,0.4,0.54,0.51l1.6,0.79c0.06,0.03,0.13,0.06,0.2,0.08l1.94,0.57L745,162.2c-0.11,0.27-0.13,0.57-0.05,0.85
                  l0.48,1.59c0.08,0.25,0.22,0.46,0.42,0.62l0.97,0.77c0.3,0.23,0.69,0.32,1.06,0.24l1.76-0.41l1.56-0.48l0.93,0.03l0.7,0.53
                  c0.11,0.08,0.24,0.15,0.37,0.19l1.87,0.59c0.13,0.04,0.25,0.06,0.38,0.06c0.07,0,0.15-0.01,0.22-0.02l1.45-0.27l1.3-0.13
                  c0.24-0.02,0.46-0.11,0.65-0.26l1.1-0.86c0.07-0.05,0.13-0.11,0.19-0.17l0.8-0.95c0.07-0.1,0.14-0.2,0.18-0.3l0.45-1.02l0.09-0.12
                  l0.92,0.31c0.04,0.02,0.08,0.03,0.11,0.04l1.06,0.24l0.66,0.33l0.29,0.76c0.13,0.31,0.37,0.56,0.69,0.69l1.15,0.48
                  c0.1,0.05,0.21,0.07,0.31,0.09l1.88,0.25c0.28,0.04,0.55-0.02,0.79-0.16l1.08-0.62c0.06-0.03,0.13-0.08,0.18-0.12l1-0.84
                  c0.04-0.03,0.07-0.06,0.1-0.09l0.54-0.56l0.42-0.04l0.69,0.34l0.88,0.85c0.27,0.26,0.63,0.38,0.99,0.34l2.06-0.2l0.41,0.35
                  l0.58,0.89c0.17,0.27,0.44,0.47,0.75,0.54l0.7,0.18l0.29,0.67c0.16,0.35,0.47,0.62,0.84,0.71c0.38,0.1,0.78,0.01,1.08-0.23
                  l0.79-0.62l0.92-0.22l1.18-0.17l1.37-0.05l1-0.09l1.1,0.29c0.64,0.17,1.3-0.19,1.51-0.82l0.28-0.88l1.5-1.29l0.53-0.19l1.48,1.14
                  c0.2,0.15,0.44,0.24,0.7,0.26l1.11,0.05l0.32,0.17l0.07,0.38l-0.38,0.98l-0.38,1.27l-0.31,1.34c-0.07,0.3-0.02,0.63,0.13,0.9
                  l0.61,1.07c0.08,0.14,0.19,0.26,0.32,0.36l1.02,0.8c0.36,0.29,0.86,0.35,1.28,0.16l0.85-0.38l0.23,0.02l0.15,0.3l0.04,1.08
                  c0.01,0.19,0.06,0.38,0.16,0.56l0.61,1.1c0.02,0.03,0.04,0.07,0.07,0.11l0.63,0.9l0.16,0.92l0.09,1.37
                  c0.01,0.04,0.01,0.09,0.02,0.13l0.22,1.19l0.1,1.03l-0.53,1.62l-0.92,0.49c-0.1,0.05-0.2,0.12-0.28,0.21l-2.93,2.97
                  c-0.04,0.05-0.08,0.09-0.12,0.14l-0.74,1.01c-0.07,0.09-0.12,0.2-0.16,0.31l-0.41,1.11l-0.67,1.01c-0.27,0.42-0.27,0.95,0,1.37
                  l1.39,2.09c0.08,0.12,0.18,0.22,0.3,0.31l0.87,0.62l0.84,1.09c0.19,0.25,0.46,0.41,0.76,0.47l1.36,0.25
                  c0.37,0.07,0.74-0.03,1.03-0.27c0.28-0.23,0.45-0.58,0.45-0.95l0.01-1.33l1.26-2.3l0.68-0.97c0.07-0.09,0.12-0.19,0.16-0.3
                  l0.44-1.25l0.37-0.25l0.78-0.01l1.28,0.24c0.12,0.02,0.25,0.03,0.37,0.01l1.4-0.16c0.16-0.02,0.32-0.07,0.46-0.15l1.2-0.66
                  l0.92,0.07c0.17,0.01,0.34-0.01,0.5-0.07l1.31-0.45c0.12-0.04,0.23-0.1,0.33-0.18l1.1-0.81c0.04-0.03,0.08-0.06,0.12-0.1l1.78-1.72
                  l1.56-1.69l0.14-0.04c-0.08,0.18-0.12,0.38-0.11,0.58l0.05,0.79l-0.47,0.6c-0.09,0.11-0.16,0.24-0.21,0.38l-0.42,1.28
                  c-0.01,0.04-0.02,0.07-0.03,0.11l-0.05,0.22l-0.3-0.01c-0.07-0.01-0.15,0-0.22,0.01l-2.62,0.4c-0.23,0.04-0.45,0.14-0.63,0.3
                  l-0.95,0.83c-0.09,0.08-0.17,0.17-0.24,0.27l-0.61,0.97l-0.64,0.89l-1.78,1.66c-0.14,0.14-0.25,0.31-0.31,0.5l-0.4,1.18
                  c-0.06,0.18-0.08,0.37-0.06,0.56l0.16,1.21l-0.05,1.13c-0.01,0.27,0.07,0.54,0.23,0.77l0.63,0.9l0.51,2.28
                  c0.09,0.42,0.39,0.77,0.79,0.91c0.4,0.15,0.85,0.08,1.19-0.18l0.92-0.71l1.13-0.26c0.4-0.1,0.73-0.39,0.88-0.77l0.07-0.19
                  c0.01,0.03,0.02,0.06,0.03,0.1l0.42,1.19c0.1,0.28,0.3,0.51,0.55,0.66l1.3,0.75c0.26,0.15,0.57,0.2,0.86,0.15l1.24-0.23
                  c0.05-0.01,0.1-0.02,0.16-0.04l0.33-0.11c-0.01,0.26,0.06,0.51,0.19,0.73c0.21,0.32,0.55,0.53,0.93,0.57l1.86,0.2l1.4,0.39
                  c0.46,0.13,0.95-0.01,1.27-0.37l0.59-0.65l0.8-0.06c0.16-0.02,0.33-0.06,0.48-0.14l1.09-0.57c0.03-0.02,0.07-0.04,0.1-0.06
                  l0.86-0.56l0.78-0.2l1.55,0.2l0.11,0.35l-0.16,0.84l-0.3,1.06l-0.49,1.05c-0.04,0.09-0.07,0.18-0.09,0.27l-0.24,1.21l-0.34,1.28
                  l-0.49,1.1c-0.14,0.31-0.15,0.67-0.02,0.99l0.41,0.99l0.06,0.95l-0.32,1.17l-0.51,0.97l-0.47,0.63l-0.86,0.3
                  c-0.59,0.21-0.94,0.84-0.8,1.45l0.36,1.61c0.05,0.24,0.17,0.45,0.35,0.62l0.86,0.85l0.33,0.69l0.02,0.9l-0.44,1.16l-0.6,1.34
                  c-0.06,0.13-0.1,0.27-0.11,0.41l-0.09,1.19l-0.22,0.98l-0.76,0.99l-0.87,1.27c-0.08,0.12-0.14,0.25-0.18,0.39l-0.32,1.22
                  c-0.04,0.17-0.05,0.34-0.03,0.51l0.28,1.73l0.23,2.11l0.19,1.22v0.91l-0.16,0.37l-0.25,0.08l-0.81-0.3
                  c-0.15-0.06-0.33-0.08-0.46-0.08l-1.25,0.02c-0.14,0-0.27,0.03-0.41,0.07l-0.95,0.35h-1.03c-0.13,0-0.26,0.02-0.39,0.06l-1.19,0.39
                  c-0.04,0.01-0.07,0.03-0.11,0.04l-0.16,0.07l-0.07-0.15c-0.2-0.38-0.57-0.64-0.99-0.68l-1.25-0.13c-0.16-0.02-0.31-0.01-0.46,0.04
                  l-1.23,0.34c-0.41,0.11-0.73,0.42-0.86,0.82l-0.36,1.13l-0.88,1.67c-0.26,0.51-0.15,1.14,0.28,1.52l1.06,0.93l1.03,0.79l1.11,1.24
                  c0.17,0.18,0.38,0.31,0.62,0.37l1.4,0.36l1.13,0.66l0.88,1.11l0.32,1.36c0.07,0.28,0.23,0.52,0.45,0.7l0.86,0.66l0.73,0.99l0.73,1.2
                  l0.38,1.44c0.06,0.23,0.19,0.44,0.36,0.6l1.39,1.29c0.04,0.03,0.08,0.06,0.12,0.1l1.49,1.07l0.87,1.08l0.13,0.36l-0.62,0.81
                  c-0.04,0.04-0.07,0.09-0.09,0.14l-0.65,1.13c-0.19,0.32-0.22,0.71-0.09,1.06c0.13,0.35,0.42,0.63,0.77,0.74l0.4,0.14l-0.02,0.27
                  l-0.41,1.34c-0.13,0.41-0.04,0.86,0.24,1.19l0.97,1.11l1.51,2.68c0.02,0.03,0.04,0.06,0.06,0.09l0.68,0.97l0.52,1.05
                  c0.2,0.42,0.63,0.69,1.09,0.7l1.45,0.03c0.08,0,0.15,0,0.22-0.01l1.32-0.21l0.92-0.22L836.75,271.11z"/>
                <title>Liptovský Mikuláš</title>
              </Link>

              {/* Brezno - Banskobystricky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Brezno")}>
                <path style={{ fill: crimesInRegionBanskobystricky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionBanskobystricky[1].crimePercent)] ? crimesInRegionBanskobystricky[1].color : "#D3D3D3" }}
                  d="M890.57,293.69l-0.63,0.2l-1.04-0.08c-0.19-0.01-0.36,0.01-0.53,0.08l-1.17,0.43c-0.29,0.1-0.53,0.31-0.67,0.58
                  l-0.61,1.15c-0.09,0.17-0.14,0.34-0.15,0.53l-0.05,1.11l-0.46,0.94c-0.05,0.1-0.08,0.21-0.1,0.33l-0.21,1.21
                  c-0.01,0.05-0.02,0.11-0.02,0.16l-0.05,1.33l-0.17,1.22l-0.12,1.17l-0.09,0.41l-0.46,0.13c-0.25,0.07-0.48,0.21-0.64,0.41l-0.8,0.99
                  c-0.04,0.05-0.08,0.11-0.11,0.17l-0.69,1.18l-0.86,0.53l-1.36,0.5c-0.35,0.12-0.63,0.4-0.75,0.76l-0.53,1.51l-0.65,0.76l-0.96,0.8
                  l-3.04,2.37c-0.28,0.21-0.45,0.53-0.48,0.88l-0.12,1.31c-0.01,0.09-0.01,0.19,0,0.29l0.22,1.51c0.02,0.11,0.05,0.22,0.1,0.33
                  l0.38,0.86l-0.11,0.68l-0.77,1.24c-0.24,0.38-0.25,0.85-0.04,1.25l0.61,1.15l0.22,0.5l-0.4,0.48c-0.12,0.15-0.21,0.32-0.26,0.51
                  l-1.14,0.29l-1.84,0.18l-0.34-0.4l-0.63-0.98c-0.1-0.16-0.24-0.29-0.4-0.39l-1.15-0.7c-0.48-0.29-1.08-0.23-1.5,0.15l-2.83,2.7
                  c-0.04,0.04-0.07,0.08-0.11,0.12l-0.76,0.98c-0.02,0.03-0.04,0.06-0.06,0.1l-0.59,0.91l-0.2,0.12l-0.22-0.12l-0.63-0.94l-0.83-1.08
                  c-0.15-0.2-0.35-0.34-0.58-0.42l-1.27-0.44c-0.28-0.1-0.58-0.09-0.86,0.01l-1.18,0.45c-0.06,0.03-0.13,0.06-0.19,0.1l-1.17,0.7
                  l-0.92,0.62l-0.85,0.27l-0.68-0.12l-0.7-0.57c-0.13-0.11-0.29-0.19-0.47-0.24l-1.17-0.31l-1.06-0.39l-0.53-0.3l-0.43-1.81
                  l-0.01-1.08c0-0.06-0.01-0.12-0.01-0.18l-0.2-1.3c-0.08-0.5-0.45-0.9-0.94-1.02l-1.31-0.32c-0.24-0.06-0.48-0.05-0.71,0.03
                  l-0.8,0.28l-0.89-0.27c-0.21-0.06-0.42-0.07-0.63-0.02l-1.38,0.3c-0.2,0.04-0.39,0.14-0.55,0.27l-0.97,0.84l-0.86,0.58l-0.59,0.14
                  l0.25-0.87c0.12-0.41,0.02-0.85-0.25-1.17c-0.28-0.32-0.7-0.48-1.12-0.42L835.6,320l-0.84-0.02l-0.81-0.46
                  c-0.1-0.05-0.2-0.1-0.31-0.12l-1.31-0.32c-0.29-0.07-0.59-0.04-0.85,0.09l-1.16,0.57c-0.04,0.02-0.08,0.04-0.11,0.06l-1.03,0.64
                  l-0.96,0.53l-0.84-0.08c-0.52-0.04-1.02,0.24-1.24,0.72l-0.54,1.15c-0.04,0.08-0.07,0.16-0.09,0.24l-0.21,0.91l-3,1.62l-1.13,0.27
                  c-0.25,0.06-0.48,0.2-0.65,0.4l-0.71,0.8l-0.65,0.36l-3.09,0.06c-0.56,0.01-1.04,0.39-1.19,0.93l-0.18,0.67l-2.27-0.34
                  c-0.1-0.01-0.21-0.01-0.31,0l-2.32,0.24l-1.12-0.27l-0.71-0.92c-0.2-0.27-0.5-0.44-0.83-0.48l-1.22-0.16c-0.2-0.03-0.41,0-0.6,0.07
                  l-1.22,0.46c-0.11,0.04-0.22,0.1-0.31,0.17l-1.24,0.93c-0.08,0.06-0.16,0.14-0.22,0.22l-0.84,1.04c-0.2-0.11-0.42-0.18-0.66-0.17
                  l-1.09,0.02l-3.25-1.49c-0.21-0.1-0.44-0.14-0.67-0.11l-1.22,0.13c-0.35,0.03-0.66,0.21-0.87,0.49l-1.03,1.38
                  c-0.15,0.2-0.24,0.44-0.25,0.69l-0.07,1.51v0.1l0.09,2.95l-0.69,1.09l-0.65,1.21c-0.02,0.04-0.04,0.08-0.06,0.12l-0.5,1.23
                  c-0.1,0.24-0.12,0.51-0.05,0.77l0.27,1.13l-0.12,0.74l-0.4,0.47l-0.46,0.21l-0.74-0.23l-1.02-0.61c-0.34-0.19-0.75-0.23-1.11-0.08
                  l-1.14,0.46c-0.11,0.05-0.22,0.11-0.31,0.18l-1.24,0.99c-0.09,0.08-0.18,0.17-0.25,0.27l-0.77,1.11c-0.04,0.06-0.07,0.12-0.1,0.18
                  l-0.46,0.98l-0.76,0.87l-0.72,0.68l-0.93,0.47c-0.25,0.12-0.44,0.31-0.56,0.55l-0.55,1.07l-0.62,0.98l-0.73,0.96
                  c-0.07,0.09-0.13,0.2-0.18,0.31l-0.48,1.25c-0.07,0.18-0.09,0.38-0.07,0.57l0.22,2.55l-0.1,0.64l-0.34,0.2l-0.67-0.01l-0.98-0.47
                  c-0.32-0.16-0.69-0.17-1.02-0.04s-0.59,0.4-0.71,0.73l-0.41,1.16l-0.46,1.04l-0.85,0.99c-0.21,0.24-0.32,0.56-0.3,0.89l0.08,1.2
                  l-0.07,0.77l-0.39,0.37l-2.38,0.68c-0.13,0.04-0.26,0.1-0.38,0.18l-0.59,0.41l-1.96-0.45c-0.17-0.04-0.35-0.04-0.52,0l-1.44,0.3
                  c-0.24,0.05-0.46,0.16-0.63,0.33l-0.88,0.88l-1.03,0.83c-0.27,0.21-0.44,0.53-0.47,0.87l-0.1,1.29c-0.01,0.13,0,0.25,0.03,0.38
                  l0.23,1.02l-0.14,0.14l-0.95,0.14c-0.11,0.02-0.21,0.05-0.31,0.1l-1.59,0.7c-0.04,0.01-0.07,0.03-0.11,0.05l-0.99,0.55l-0.95,0.08
                  c-0.1,0.01-0.19,0.03-0.28,0.06l-1.38,0.45c-0.07,0.03-0.14,0.05-0.2,0.09l-1.13,0.61c-0.36,0.19-0.6,0.54-0.65,0.94l-0.15,1.19
                  l-0.49,0.97c-0.02,0.05-0.04,0.1-0.06,0.16l-0.42,1.23c-0.19,0.56,0.04,1.18,0.55,1.48l1.2,0.71l0.74,0.84
                  c0.07,0.07,0.15,0.14,0.23,0.2l0.94,0.64l0.33,0.63l0.01,0.13l-5.39,2.35c-0.07,0.03-0.13,0.06-0.19,0.1l-1.09,0.71l-0.88,0.4
                  l-1.18,0.16c-0.11,0.01-0.22,0.04-0.32,0.08l-1.14,0.47c-0.12,0.05-0.23,0.12-0.33,0.21l-0.96,0.82c-0.08,0.07-0.15,0.14-0.21,0.23
                  l-0.62,0.87l-0.77,0.6l-1.02,0.55c-0.08,0.04-0.15,0.09-0.22,0.15l-0.87,0.74l-0.99,0.58c-0.1,0.07-0.2,0.15-0.28,0.24l-0.7,0.77
                  l-0.58,0.2l-0.67-0.13l-0.93-0.55c-0.07-0.05-0.14-0.08-0.22-0.11l-1.32-0.47l-1.25-0.36c-0.15-0.04-0.29-0.06-0.44-0.05l-1.17,0.09
                  l-1.05-0.08l-0.39-0.19l-0.04-0.17l0.2-0.27l0.89-0.38c0.28-0.12,0.5-0.33,0.63-0.6l0.6-1.23c0.09-0.17,0.13-0.35,0.13-0.54
                  l0.01-1.53c0-0.35-0.14-0.68-0.39-0.92l-0.93-0.88c-0.23-0.22-0.53-0.34-0.85-0.34l-1.34-0.01h-0.11l-1.47,0.12l-1.08-0.08
                  l-0.86-0.3l-0.79-0.73c-0.29-0.27-0.69-0.38-1.07-0.31l-1.15,0.21l-1-0.11l-0.47-0.31l-0.35-0.86c-0.13-0.31-0.37-0.55-0.68-0.68
                  l-1.31-0.55c-0.05-0.03-0.11-0.04-0.16-0.06l-1.23-0.32l-0.15-0.03l-1.43-0.2c-0.03,0-0.07-0.01-0.11-0.01l-1.29-0.06l-1.79-0.39
                  l-0.75-0.24l-0.62-0.85c-0.28-0.39-0.77-0.59-1.24-0.5l-1.42,0.27c-0.22,0.04-0.42,0.14-0.59,0.29l-0.29,0.25l-0.28-0.22
                  c-0.16-0.13-0.36-0.22-0.57-0.25l-1.34-0.22l-0.76-0.2l-0.57-0.65c-0.24-0.28-0.61-0.46-0.97-0.43l-1.48,0.04
                  c-0.09,0-0.17,0.01-0.26,0.03l-1,0.24l-1.25-0.09c-0.12,0-0.24,0-0.35,0.03l-1.43,0.31c-0.09,0.02-0.18,0.05-0.26,0.09l-1.05,0.48
                  l-1.08-0.04c-0.24-0.01-0.48,0.05-0.69,0.18l-1.02,0.6l-0.56,0.24l-0.11-1.18c-0.04-0.43-0.3-0.81-0.68-1l-0.9-0.44l-0.45-0.84
                  c-0.1-0.18-0.24-0.34-0.42-0.45l-0.74-0.48l-0.59-1.93c-0.12-0.4-0.44-0.72-0.85-0.84l-0.84-0.25l-0.57-0.79
                  c-0.02-0.04-0.05-0.08-0.09-0.11l-0.73-0.8l-0.49-0.9l-0.28-0.59l0.26-0.49l0.63-0.21l1.55-0.2c0.16-0.02,0.31-0.08,0.46-0.16
                  l1.07-0.62c0.05-0.03,0.11-0.07,0.16-0.11l1.06-0.87l0.95-0.89c0.15-0.13,0.25-0.29,0.32-0.47l0.48-1.29l0.49-1.03l0.81-1.15
                  l0.66-1.11c0.19-0.31,0.23-0.68,0.12-1.02l-0.36-1.13l0.36-0.68c0.05-0.1,0.09-0.21,0.11-0.32l0.25-1.18l0.42-1.01l0.6-1.22
                  c0.26-0.53,0.12-1.17-0.35-1.54l-1.09-0.84l-1.26-0.88l-0.89-0.71l-0.59-0.67l-0.2-1.11c-0.03-0.16-0.09-0.31-0.18-0.45l-0.8-1.26
                  l-0.76-1.09l-0.69-1.23l-1.72-2.86c-0.23-0.38-0.64-0.59-1.11-0.59l-1.37,0.05c-0.12,0-0.23,0.02-0.33,0.06l-0.97,0.3l-0.39-0.05
                  l-0.02-0.16l0.17-1.17l0.27-1.37l0.68-1.25c0.24-0.45,0.19-0.99-0.12-1.38l-0.78-0.98c-0.35-0.43-0.94-0.59-1.46-0.38l-1.11,0.46
                  l-0.94,0.32l-0.93-0.06l-1.36-0.53l-0.56-0.84l-0.63-0.86l-0.23-1.12c-0.06-0.3-0.22-0.56-0.47-0.75l-1.17-0.89l-0.1-0.81
                  c-0.06-0.48-0.39-0.89-0.86-1.04l-1.28-0.41c-0.03-0.01-0.07-0.02-0.1-0.03l-0.91-0.21l-0.27-0.31l0.01-0.91
                  c0-0.27-0.08-0.52-0.23-0.74l-0.81-1.13c-0.17-0.23-0.41-0.4-0.68-0.47l-1.24-0.35c-0.27-0.08-0.56-0.06-0.82,0.04l-0.65,0.27
                  l0.41-0.54c0.15-0.2,0.24-0.44,0.25-0.69l0.06-1.26c0-0.12-0.01-0.23-0.03-0.35l-0.37-1.56c-0.06-0.22-0.17-0.43-0.33-0.59
                  l-1.42-1.43l-0.51-0.71l0.01-0.95c0-0.05,0-0.1-0.01-0.15l-0.34-2.86l0.11-1.36l4.01-5.27c0.05-0.06,0.09-0.14,0.13-0.21l0.75-1.58
                  c0.09-0.19,0.13-0.4,0.12-0.61l-0.07-1.31c-0.01-0.15-0.05-0.29-0.11-0.43l-0.45-1.04l-0.01-1.27c0-0.14-0.03-0.28-0.07-0.41
                  l-0.51-1.42c-0.07-0.19-0.18-0.36-0.33-0.5l-0.77-0.71l-0.47-1.04c-0.05-0.12-0.12-0.23-0.21-0.32l-1.45-1.64l-0.04-0.9
                  c-0.01-0.25-0.1-0.5-0.26-0.71l-0.99-1.27c-0.11-0.15-0.25-0.26-0.41-0.35l-1.45-0.76c-0.03-0.01-0.06-0.03-0.09-0.04l-1.3-0.55
                  c-0.17-0.07-0.33-0.11-0.52-0.1l-1.44,0.03l-0.8-0.62l-1.34-2.27l-0.24-0.52l0.46-0.54c0.08-0.1,0.15-0.2,0.2-0.32l0.44-1.04
                  l0.88-0.94l0.94-0.86c0.14-0.12,0.25-0.28,0.32-0.45l0.47-1.16l2.13-2.62c0.03-0.04,0.06-0.08,0.09-0.12l0.95-1.46l0.79-0.64
                  l1.02-0.36l1.34-0.29l1.22,0.47c0.23,0.08,0.48,0.1,0.72,0.05l2.58-0.57l1,0.21c0.1,0.02,0.2,0.03,0.3,0.03l5.06-0.16
                  c0.04,0,0.08-0.01,0.13-0.01l3.05-0.41c0.24-0.03,0.47-0.14,0.65-0.3l0.89-0.77l0.81,0.05c0.33,0.02,0.65-0.09,0.9-0.31l1.32-1.14
                  l1.76-1.17l1.16-0.11c0.29-0.02,0.56-0.15,0.77-0.36l0.51-0.52l0.87,0.01c0.34,0.01,0.63-0.12,0.87-0.34l0.52-0.5l0.17,0.13
                  c0.15,0.11,0.32,0.19,0.5,0.22l1.5,0.31c0.11,0.03,0.22,0.04,0.34,0.03l1.46-0.11l0.95,0.37c0.06,0.03,0.12,0.04,0.18,0.06
                  l1.38,0.32c0.34,0.07,0.7,0.01,0.98-0.19l1.41-0.96c0.04-0.02,0.07-0.04,0.1-0.07l0.92-0.77l1.46-1.05l0.53-0.08l0.57,0.45
                  l0.69,0.89c0.21,0.26,0.51,0.43,0.84,0.47l1.45,0.18c0.05,0.01,0.1,0.01,0.15,0.01h1.37l1.11-0.08l1.18,0.39
                  c0.11,0.03,0.22,0.05,0.34,0.06l1.28,0.05l1.4,0.34c0.3,0.07,0.62,0.02,0.89-0.12l1.02-0.57l1.22-0.57l1.49,0.42l1.21,0.44
                  c0.19,0.07,0.4,0.09,0.6,0.07l1.25-0.17c0.04-0.01,0.08-0.02,0.12-0.02l1.11-0.27l0.84,0.22l1.17,0.56c0.05,0.02,0.1,0.04,0.15,0.06
                  l1.33,0.44c0.13,0.04,0.26,0.05,0.41,0.06l1.19-0.02l1.11,0.37l1.63,0.89l0.96,2.36c0.1,0.23,0.26,0.43,0.47,0.57l1,0.66l1.13,0.89
                  c0.1,0.08,0.21,0.14,0.32,0.19l1.03,0.39l1.76,1.12c0.11,0.08,0.24,0.13,0.37,0.16l1.55,0.38c0.48,0.12,0.98-0.05,1.29-0.44
                  l0.59-0.76l1.92-0.76l1.01-0.12l0.89,0.37l1.42,1.16l1,0.77l0.57,0.86c0.03,0.06,0.08,0.11,0.12,0.16l0.96,1.04
                  c0.06,0.07,0.13,0.13,0.21,0.18l1.77,1.22c0.14,0.09,0.29,0.16,0.45,0.19l1.51,0.32c0.09,0.02,0.18,0.03,0.27,0.03h0.73l0.14,0.25
                  l0.05,1.06c0.02,0.29,0.13,0.57,0.33,0.78l1.16,1.27c0.18,0.21,0.43,0.34,0.7,0.39l1.59,0.28c0.27,0.05,0.54,0.01,0.79-0.12
                  l2.27-1.11c0.15-0.07,0.28-0.17,0.39-0.3l0.74-0.82l0.7-0.32l1.09-0.13c0.05,0,0.1-0.01,0.15-0.02l1.34-0.32
                  c0.08-0.02,0.15-0.05,0.23-0.08l1.12-0.51c0.05-0.03,0.09-0.05,0.14-0.08l0.99-0.62l2.18-1.19l1.01-0.41l1.35-0.43
                  c0.12-0.04,0.23-0.09,0.34-0.17l0.51-0.36l0.63,0.22c0.21,0.06,0.43,0.08,0.64,0.04l1.19-0.23l0.26-0.02l0.69,1.67
                  c0.15,0.34,0.45,0.6,0.82,0.7l1.12,0.31l1.24,0.7c0.1,0.06,0.2,0.1,0.31,0.12l1.35,0.33l1.39,0.25c0.24,0.04,0.49,0.01,0.72-0.09
                  l1.14-0.5c0.05-0.01,0.09-0.04,0.13-0.06l0.89-0.52l1.03-0.14l1.32-0.06l1.22,0.57c0.21,0.1,0.43,0.14,0.66,0.11l1.66-0.17
                  c0.31-0.03,0.6-0.18,0.81-0.42l0.43-0.49l2.32,0.37c0.18,0.03,0.37,0.02,0.55-0.03l2.02-0.58l1.38,0.2l1.86,0.58
                  c0.19,0.06,0.4,0.08,0.6,0.04l1.14-0.21l0.95,0.31l1.05,0.69c0.36,0.24,0.83,0.28,1.23,0.09l1.03-0.49l1.23-0.05
                  c0.03,0,0.07-0.01,0.1-0.01l1.67-0.2l1.1,0.08h0.16l1.28-0.07c0.08-0.01,0.16-0.02,0.24-0.04l1.21-0.31
                  c0.12-0.03,0.24-0.08,0.35-0.15l1.28-0.79l1.1-0.47c0.23-0.1,0.43-0.27,0.56-0.49l0.54-0.86l0.15-0.08l0.65,0.42l1.48,1.19
                  l1.01,1.13l0.05-0.05c0.01,0.01,0.01,0.02,0.02,0.03l1.07,1.35c0.22,0.27,0.54,0.44,0.89,0.47l2.24,0.16l1.16,0.48l1.18,0.43
                  c0.09,0.04,0.19,0.06,0.28,0.07l1.6,0.19l1.63,0.12c0.12,0.01,0.25,0,0.38-0.03l1.5-0.36l3.11,0.47l0.7,0.61
                  c0.14,0.13,0.31,0.22,0.5,0.27l1.34,0.36c0.11,0.03,0.24,0.05,0.36,0.04l1.42-0.04c0.07,0,0.14-0.01,0.2-0.02l1.28-0.26l1.56-0.13
                  l1.44,0.41c0.08,0.02,0.16,0.04,0.24,0.05l2.56,0.21l1.17,0.3c0.07,0.02,0.15,0.03,0.23,0.04l1.32,0.08l1.36,0.15l1.17,0.2
                  l1.42,0.31l10.62,1.76c0.5,0.09,0.99-0.13,1.26-0.56l1.51-2.29l0.79-0.99c0.06-0.07,0.11-0.15,0.15-0.23l0.75-1.53l0.54-0.98
                  l0.04-0.01l2.17,2.15c0.15,0.14,0.32,0.25,0.52,0.31l1.19,0.36l2.56,1.4c0.13,0.07,0.27,0.11,0.41,0.14l1.32,0.2
                  c0.04,0,0.08,0.01,0.12,0.01l1.13,0.06l0.82,0.46c0.07,0.04,0.15,0.07,0.22,0.1l0.98,0.31l1.7,1.56c0.06,0.05,0.12,0.1,0.19,0.14
                  l1.07,0.65c0.14,0.08,0.29,0.14,0.45,0.16l1.24,0.2c0.09,0.02,0.18,0.02,0.26,0.02l0.92-0.05l0.65,0.34l0.73,0.74
                  c0.09,0.09,0.2,0.17,0.32,0.23l1,0.52l0.84,0.64L890.57,293.69z"/>
                <title>Brezno</title>
              </Link>


              {/* Rimavska sobota - Banskobystricky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Rimavská Sobota")}>
                <path style={{ fill: crimesInRegionBanskobystricky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionBanskobystricky[4].crimePercent)] ? crimesInRegionBanskobystricky[4].color : "#D3D3D3" }}
                  d="M931.08,478.84l-0.58,1.39c-0.05,0.12-0.08,0.24-0.1,0.37l-0.1,1.11l-0.37,1.07c-0.01,0.03-0.02,0.06-0.02,0.1
                  l-0.3,1.18l-0.77,0.79l-0.99,0.87c-0.21,0.19-0.36,0.45-0.4,0.74l-0.2,1.22c-0.05,0.28,0,0.58,0.15,0.83l0.66,1.14l0.35,1.17
                  c0.03,0.09,0.07,0.18,0.11,0.26l0.32,0.55l-0.27,0.51c-0.06,0.13-0.11,0.26-0.13,0.4l-0.15,1.02l-0.38,0.74l-0.84,0.61
                  c-0.1,0.07-0.18,0.16-0.26,0.25l-0.57,0.76l-0.87,0.39c-0.09,0.04-0.18,0.1-0.26,0.16l-1.11,0.88l-1.08,0.66
                  c-0.43,0.26-0.66,0.75-0.59,1.24l0.13,0.96l-0.3,0.73l-1.35,2.11l-0.81,0.97l-0.76,0.85l-0.89,0.85c-0.24,0.23-0.38,0.55-0.38,0.88
                  l-0.02,1.23c-0.01,0.38,0.16,0.75,0.46,0.99l0.31,0.26l-0.45,1.58l-0.51,0.77l-0.68,0.73l-1.11,0.72c-0.28,0.18-0.47,0.46-0.54,0.78
                  l-0.25,1.14l-0.59,0.34l-1.08,0.51c-0.08,0.03-0.15,0.08-0.22,0.13l-1.03,0.77c-0.03,0.02-0.06,0.04-0.08,0.06l-1.99,1.72
                  c-0.18,0.15-0.31,0.34-0.38,0.56l-0.34,1.03l-0.62,0.84c-0.18,0.25-0.27,0.56-0.23,0.87l0.12,1.14c-0.37,0.15-0.66,0.46-0.77,0.87
                  l-1.12,4.54c-0.01,0.06-0.02,0.11-0.02,0.17l-0.06,0.52l-1.29,0.05l-1.36-0.29c-0.46-0.1-0.92,0.06-1.22,0.41l-1.15,1.36
                  c-0.41,0.49-0.39,1.21,0.05,1.67l0.12,0.12l-0.39,0.07c-0.09,0.01-0.17,0.03-0.25,0.06l-1.07,0.42l-1.09,0.18
                  c-0.04,0-0.07,0.01-0.1,0.02l-0.85,0.21l-0.4-0.14l-0.35-0.74c-0.14-0.28-0.37-0.5-0.66-0.62l-1.24-0.5
                  c-0.09-0.04-0.18-0.06-0.28-0.08l-0.66-0.1l-0.39-0.6c-0.16-0.25-0.4-0.44-0.68-0.52l-0.79-0.25l-0.44-0.65
                  c-0.16-0.23-0.38-0.4-0.65-0.49l-0.99-0.33l-0.87-0.62l-0.96-0.81c-0.22-0.19-0.53-0.28-0.82-0.29l-1.06,0.02l-0.92-0.36
                  c-0.17-0.07-0.35-0.1-0.52-0.09l-1.27,0.06c-0.31,0.02-0.61,0.15-0.83,0.37l-0.88,0.89c-0.2,0.2-0.32,0.46-0.35,0.74l-0.14,1.26
                  c-0.05,0.42,0.12,0.84,0.45,1.11l0.33,0.27l-0.07,0.22l-0.66,0.73c-0.14,0.15-0.24,0.33-0.29,0.52l-0.1,0.38h-0.25
                  c-0.22,0-0.44,0.05-0.63,0.16l-1.2,0.67c-0.1,0.05-0.19,0.12-0.28,0.21l-0.53,0.53l-0.4-0.04l-0.78-0.53
                  c-0.48-0.34-1.14-0.28-1.57,0.13l-0.9,0.87c-0.39,0.38-0.49,0.97-0.25,1.46l0.72,1.42l-1.01,0.33c-0.09,0.03-0.16,0.06-0.24,0.1
                  l-0.89,0.51l-1.01,0.13l-0.88,0.02l-0.91-0.4c-0.2-0.09-0.42-0.13-0.64-0.1l-0.77,0.08l-0.71-0.48c-0.15-0.09-0.3-0.16-0.47-0.19
                  l-1.28-0.24c-0.17-0.03-0.34-0.03-0.51,0.01l-1.12,0.25l-1.89-0.24l-0.78-0.62c-0.04-0.03-0.08-0.06-0.12-0.08l-1.09-0.68
                  c-0.29-0.18-0.64-0.23-0.96-0.15l-1.21,0.3l-1.21,0.15c-0.51,0.06-0.93,0.44-1.05,0.94l-0.6,2.42c-0.11,0.42,0.02,0.87,0.32,1.18
                  l0.89,0.89c0.05,0.05,0.1,0.1,0.16,0.14l0.47,0.33l-0.01,0.29l-0.4,0.77l-0.67,1.1c-0.11,0.18-0.17,0.38-0.18,0.58l-0.07,1.23
                  l-0.12,1.23c0,0.08,0,0.17,0.01,0.25l0.08,0.78l-1.16,1.14l-1.06,0.37c-0.17,0.05-0.33,0.15-0.45,0.27l-0.71,0.66l-0.93,0.26
                  c-0.26,0.07-0.5,0.23-0.66,0.45l-0.73,0.98l-0.74,0.74l-1,0.58c-0.12,0.07-0.23,0.16-0.32,0.27l-0.84,0.98
                  c-0.16,0.19-0.27,0.43-0.29,0.68l-0.06,0.52l-0.59,0.28c-0.33,0.16-0.57,0.45-0.67,0.8l-0.27,1.03l-0.54,0.49l-0.51,0.17l-0.76-0.29
                  c-0.44-0.17-0.94-0.07-1.29,0.26l-0.92,0.85l-1.01,0.59c-0.11,0.07-0.21,0.15-0.3,0.25l-0.88,1c-0.29,0.31-0.39,0.75-0.28,1.16
                  l0.36,1.33c0.14,0.5,0.57,0.87,1.09,0.91l0.28,0.03l-0.33,0.45l-0.74,0.18l-1.28,0.06c-0.41,0.02-0.78,0.24-1,0.59l-0.59,0.97
                  l-0.79,0.71c-0.18,0.16-0.3,0.37-0.37,0.6l-0.28,1.04l-0.32,0.38h-0.05l-0.08-0.09l-0.03-0.91c-0.02-0.44-0.27-0.84-0.66-1.05
                  l-1.1-0.59c-0.14-0.08-0.29-0.12-0.44-0.14l-0.91-0.11l-0.78-0.61c-0.25-0.2-0.55-0.29-0.86-0.27l-1.28,0.09
                  c-0.04,0.01-0.08,0.01-0.12,0.02l-1.23,0.21c-0.22,0.03-0.42,0.13-0.59,0.27l-0.98,0.81l-0.74,0.46l-0.9,0.04
                  c-0.06,0-0.11,0.01-0.17,0.02l-1.26,0.23c-0.17,0.03-0.33,0.1-0.47,0.2l-1.98,1.35l-1.15,0.69c-0.27,0.16-0.47,0.42-0.56,0.72
                  l-0.05,0.17l-0.39-0.43c-0.22-0.23-0.5-0.37-0.81-0.39l-1.38-0.12c-0.43-0.04-0.86,0.15-1.12,0.5l-0.81,1.1
                  c-0.04,0.05-0.07,0.11-0.1,0.16l-1.73,3.33l-0.7,0.22c-0.03,0.01-0.06,0.02-0.1,0.03l-1.3,0.52c-0.05,0.02-0.09,0.04-0.14,0.07
                  l-1.13,0.63c-0.32,0.18-0.55,0.49-0.62,0.85l-0.19,0.97l-1.49,1.73l-0.88,0.85c-0.13,0.13-0.23,0.28-0.3,0.44l-0.49,1.25
                  c-0.05,0.15-0.08,0.3-0.08,0.46v0.25l-0.29,0.05c-0.14,0.02-0.27,0.06-0.38,0.12L804,584.6l-1.14,0.27l-1.05,0.06h-1.17l-1.11-0.17
                  c-0.32-0.05-0.65,0.02-0.9,0.2l0.26-0.96l0.6-0.28c0.18-0.09,0.34-0.22,0.46-0.38l0.74-0.99c0.09-0.13,0.16-0.27,0.2-0.42l0.27-0.96
                  l0.72-0.95c0.4-0.53,0.33-1.27-0.18-1.71l-0.94-0.82c-0.07-0.06-0.15-0.11-0.23-0.15l-0.68-0.37l-0.35-0.87l-0.42-1.32l-0.42-1.13
                  l-0.66-2.32c-0.09-0.33-0.31-0.61-0.61-0.78c-0.31-0.16-0.66-0.2-0.99-0.09l-1.26,0.41l-0.83,0.31l0.01-0.15l0.38-1.08l0.71-1.19
                  c0.02-0.05,0.05-0.1,0.07-0.14l1.32-3.05c0.07-0.17,0.1-0.36,0.1-0.54l-0.04-1.23c-0.01-0.15-0.04-0.29-0.09-0.43l-0.34-0.85
                  l0.12-1.13c0.01-0.04,0.01-0.08,0.01-0.12l0.12,0.05c0.03,0.02,0.07,0.03,0.1,0.04l1.16,0.39c0.34,0.12,0.72,0.08,1.03-0.1l1.2-0.7
                  c0.25-0.14,0.43-0.35,0.54-0.61l0.4-1l0.32-0.37l1.06,0.06c0.36,0.01,0.68-0.1,0.93-0.34s0.39-0.57,0.39-0.92l-0.01-0.83l0.81-1
                  c0.07-0.09,0.14-0.2,0.18-0.32l0.46-1.15c0.03-0.07,0.05-0.15,0.07-0.23l0.23-1.23c0.04-0.2,0.02-0.4-0.04-0.59l-0.3-1.01l0.11-0.78
                  l0.47-1.12l0.45-1.29c0.03-0.09,0.06-0.19,0.06-0.3l0.13-1.36l0.02-0.54l0.06-0.02l0.92,0.21c0.34,0.07,0.7,0.01,0.99-0.19
                  s0.48-0.51,0.53-0.85l0.18-1.25c0.04-0.32-0.03-0.64-0.22-0.9l-0.49-0.7l0.13-0.87c0.04-0.32-0.04-0.66-0.24-0.92l-1.01-1.38
                  c-0.11-0.15-0.26-0.27-0.42-0.36l-1.1-0.59c-0.09-0.05-0.19-0.09-0.3-0.11l-1.36-0.33l-1.35-0.45c-0.09-0.03-0.19-0.05-0.28-0.05
                  l-0.75-0.07l-1.05-1.36l-0.02-2.34l0.05-1.02l0.52-1c0.15-0.29,0.18-0.64,0.08-0.95l-0.4-1.24c-0.05-0.16-0.13-0.3-0.24-0.43
                  l-0.78-0.92l-0.52-0.76l-0.17-1.22c-0.01-0.08-0.03-0.16-0.06-0.24l-0.41-1.18c-0.12-0.34-0.38-0.62-0.72-0.75l-0.97-0.39l-0.85-0.7
                  c-0.11-0.09-0.22-0.16-0.35-0.21l-0.57-0.22l-0.14-0.78c-0.07-0.42-0.36-0.78-0.76-0.94l-0.73-0.29l-0.52-1.2l0.02-1.17l-0.02-1.22
                  l0.11-1.29c0.02-0.33-0.09-0.66-0.3-0.92c-0.22-0.25-0.53-0.4-0.86-0.43l-0.35-0.02l-0.08-0.4c-0.02-0.05-0.03-0.1-0.04-0.14
                  l-0.83-2.38l-0.22-1.27c-0.02-0.15-0.08-0.29-0.16-0.43l-0.76-1.26c-0.05-0.07-0.1-0.14-0.16-0.21l-1-1.06l-0.15-0.95
                  c-0.03-0.24-0.14-0.46-0.31-0.64l-1.48-1.59l-0.17-0.81l-0.05-0.69l1.51-2.07l1.01-1.59c0.3-0.47,0.25-1.08-0.12-1.5l-0.67-0.75
                  l-0.04-0.16l0.36-0.3c0.44-0.37,0.57-0.99,0.33-1.5l-0.46-0.94l-0.05-0.99c-0.02-0.24-0.1-0.48-0.25-0.67l-0.77-1.04
                  c-0.14-0.2-0.34-0.35-0.57-0.43l-2.46-0.94c-0.07-0.03-0.14-0.05-0.22-0.07l-0.65-0.12l-1-1.83c-0.06-0.11-0.13-0.21-0.22-0.3
                  l-0.72-0.71l-0.02-0.93c0-0.13-0.03-0.26-0.07-0.39l-0.3-0.83c0.01-0.01,0.02-0.02,0.03-0.03l0.88-1.19
                  c0.08-0.1,0.14-0.21,0.18-0.32l0.38-1.07l0.31-0.36l0.83-0.05l1.15,0.05l1.04,0.2l1.3,0.77c0.09,0.06,0.2,0.11,0.31,0.14l1.19,0.32
                  l1.47,0.71l1.74,0.73c0.14,0.06,0.29,0.1,0.45,0.1l1.26,0.03c0.15,0,0.28-0.02,0.42-0.06l2.52-0.81l1.38-0.37
                  c0.24-0.07,0.46-0.2,0.62-0.39l0.39-0.44l0.69,0.11l1.36,0.13l1.38,0.07c0.36,0.01,0.7-0.12,0.95-0.38c0.26-0.25,0.38-0.6,0.36-0.96
                  l-0.09-1.23c-0.03-0.41-0.26-0.78-0.61-0.98l-0.33-0.2l0.07-0.41c0.02-0.08,0.02-0.16,0.02-0.24l-0.03-1.48
                  c0-0.04-0.01-0.09-0.01-0.14l-0.3-2.38l0.06-1.12l0.17-1.17c0.01-0.12,0.02-0.24,0-0.35l-0.17-1.18l0.02-0.43l1.88-1.02
                  c0.47-0.26,0.72-0.79,0.63-1.31l-0.23-1.31l-0.3-1.23c-0.08-0.33-0.29-0.61-0.59-0.79c-0.3-0.17-0.65-0.21-0.98-0.11l-1.14,0.34
                  l-0.83,0.02l-0.88-0.52l-0.83-0.61l-0.62-0.72l-0.46-0.95l-0.47-1.16c-0.03-0.06-0.06-0.12-0.09-0.18l-0.67-1.1
                  c-0.05-0.08-0.11-0.15-0.17-0.22l-0.49-0.5l0.03-0.42l1.07-1.87l0.73-0.89c0.03-0.03,0.05-0.06,0.08-0.09l0.69-1.02
                  c0.38-0.57,0.23-1.35-0.34-1.73l-0.51-0.36c0.21-0.3,0.28-0.69,0.17-1.05l-0.34-1.19c-0.01-0.05-0.03-0.09-0.04-0.14l-0.67-1.62
                  c-0.1-0.22-0.25-0.42-0.45-0.55l-1.04-0.71c-0.1-0.07-0.21-0.12-0.32-0.16l-0.73-0.24l-0.3-0.5l-0.22-1.01
                  c-0.01-0.06-0.03-0.12-0.05-0.17l-0.46-1.25c-0.05-0.15-0.13-0.28-0.24-0.4l-0.87-0.98c-0.05-0.06-0.1-0.11-0.16-0.15l-1.01-0.79
                  c-0.1-0.08-0.21-0.15-0.34-0.19l-1.02-0.38l-0.69-0.61l-1.6-1.69l-0.42-0.98c-0.04-0.09-0.09-0.17-0.15-0.25l-0.81-1.08
                  c-0.02-0.03-0.05-0.06-0.08-0.09l-1.59-1.81l-0.74-0.94l-0.54-0.87l-0.31-1.12c-0.17-0.6-0.76-0.99-1.37-0.91l-1.46,0.19
                  c-0.21,0.03-0.41,0.11-0.58,0.24l-0.37,0.27l-0.38-0.62l0.02-2.24l0.22-1.08l0.41-0.89l0.85-1.3l0.51-0.85l0.55-0.39l0.8-0.09
                  l1.04,0.14l0.96,0.25l0.86,0.58c0.29,0.19,0.64,0.25,0.98,0.18c0.34-0.09,0.63-0.31,0.8-0.61l0.56-1.01l0.89-1.33l1.64-2.04
                  l0.47-0.68l0.72-0.13c0.14-0.02,0.27-0.07,0.39-0.14l1.07-0.6c0.29-0.16,0.5-0.43,0.59-0.75l0.35-1.23
                  c0.04-0.14,0.06-0.28,0.05-0.42l-0.07-1.09l0.38-0.98c0.08-0.19,0.11-0.4,0.08-0.6l-0.14-1.24l-0.1-1.49
                  c-0.02-0.22-0.08-0.42-0.2-0.6l-0.7-1.08c-0.09-0.14-0.21-0.26-0.34-0.35l-0.82-0.56l-1.09-1.98c-0.08-0.15-0.2-0.28-0.34-0.38
                  l-1.02-0.76l-0.96-1c-0.11-0.13-0.26-0.23-0.42-0.29l-1.18-0.49c-0.09-0.04-0.18-0.07-0.28-0.08l-1.53-0.25
                  c-0.13-0.02-0.26-0.03-0.39-0.01l-1.01,0.16l-0.4-0.1l-0.41-0.79c-0.04-0.06-0.08-0.13-0.12-0.19l-0.91-1.17
                  c-0.08-0.1-0.18-0.19-0.29-0.27l-0.8-0.54l-0.47-0.74l-0.43-0.99l-0.22-1.27c-0.04-0.2-0.12-0.38-0.24-0.54l-0.78-1.01
                  c-0.17-0.22-0.39-0.37-0.66-0.44l-1.14-0.32l-0.97-0.49l-0.63-0.44l-0.09-0.69c-0.07-0.49-0.41-0.89-0.88-1.03l-1.08-0.32
                  l-0.94-0.58c-0.18-0.11-0.38-0.17-0.59-0.18l-1.12-0.05l-0.56-0.14l-0.18-0.56l-0.13-1.12c-0.02-0.22-0.11-0.43-0.24-0.61
                  l-0.76-1.02c-0.12-0.16-0.29-0.3-0.47-0.38l-1.32-0.61l-1.21-0.67c-0.14-0.08-0.3-0.13-0.46-0.15l-1.27-0.16
                  c-0.08-0.01-0.16-0.01-0.24-0.01l-0.95,0.06l-0.82-0.4l-0.94-0.92l-1.06-1.12c-0.03-0.03-0.07-0.07-0.11-0.1l-0.99-0.83l-0.7,0.84
                  l-0.07-0.83c-0.02-0.16-0.06-0.32-0.14-0.46l-0.58-1.13c-0.09-0.19-0.23-0.34-0.4-0.46l-1.07-0.73l-0.77-0.88
                  c-0.09-0.1-0.19-0.18-0.31-0.25l-0.5-0.29l0.61-1.27c0.06-0.13,0.1-0.26,0.12-0.4l0.1-0.76l0.46-0.26l1.14-0.37l1.08-0.09
                  c0.18-0.02,0.35-0.07,0.5-0.16l1.17-0.65l1.38-0.61l1.17-0.17c0.25-0.04,0.48-0.16,0.67-0.33l0.95-0.9
                  c0.32-0.31,0.46-0.76,0.36-1.19l-0.35-1.53l0.04-0.56l0.66-0.54c0.04-0.02,0.07-0.05,0.1-0.08l0.67-0.66l0.81-0.17l2.2,0.51
                  c0.35,0.08,0.71,0.01,1-0.19l0.87-0.6l2.53-0.74c0.18-0.05,0.34-0.15,0.48-0.27l0.96-0.89c0.23-0.22,0.37-0.51,0.4-0.82l0.11-1.35
                  v-0.18l-0.05-0.78l0.64-0.75c0.08-0.09,0.14-0.19,0.19-0.3l0.54-1.22c0.16,0.08,0.33,0.12,0.51,0.12l1.32,0.02
                  c0.22,0,0.48-0.06,0.68-0.19l1.13-0.7c0.31-0.19,0.52-0.5,0.57-0.86l0.22-1.35c0.02-0.1,0.02-0.2,0.02-0.3l-0.22-2.45l0.31-0.8
                  l0.66-0.86c0.02-0.03,0.05-0.06,0.07-0.09l0.68-1.08c0.02-0.04,0.04-0.07,0.05-0.1l0.39-0.75l0.73-0.37
                  c0.12-0.06,0.22-0.13,0.31-0.22l0.89-0.85c0.03-0.02,0.06-0.05,0.08-0.08l0.92-1.05c0.08-0.09,0.14-0.19,0.19-0.29l0.49-1.05
                  l0.6-0.87l0.96-0.76l0.4-0.16l0.61,0.37c0.09,0.05,0.18,0.09,0.27,0.11l1.33,0.41c0.29,0.09,0.61,0.08,0.88-0.05l1.15-0.52
                  c0.17-0.08,0.32-0.19,0.44-0.33l0.8-0.94c0.15-0.17,0.24-0.38,0.28-0.6l0.23-1.33c0.03-0.17,0.03-0.34-0.02-0.51l-0.24-0.99
                  l0.32-0.8l0.57-1.07l0.68-1.06l0.07,0.04c0.05,0.02,0.1,0.05,0.14,0.07l1.35,0.58l0.77,0.93c0.11,0.14,0.26,0.25,0.42,0.33
                  l0.97,0.46l1.37,1.82c0.23,0.3,0.58,0.49,0.96,0.5l1.46,0.04l1.01,0.35c0.05,0.02,0.1,0.03,0.14,0.04l1.46,0.32
                  c0.2,0.04,0.4,0.04,0.6-0.01l1.33-0.37l1.07,0.02l1.14,0.4l0.98,0.73c0.08,0.06,0.17,0.11,0.27,0.15l1.31,0.55
                  c0.1,0.04,0.2,0.07,0.31,0.09l0.69,0.09l0.47,0.87l0.68,2.35l0.44,2.39l-0.28,0.85c-0.04,0.11-0.06,0.23-0.07,0.34l-0.06,1.27
                  c-0.01,0.3,0.08,0.59,0.26,0.82l0.54,0.71l0.1,0.83l0.04,0.89l-0.72,0.61c-0.13,0.11-0.24,0.25-0.32,0.41l-0.53,1.11l-0.84,0.67
                  c-0.28,0.23-0.45,0.56-0.47,0.91l-0.07,1.32c-0.01,0.32,0.09,0.63,0.29,0.88l0.85,1.01c0.36,0.42,0.95,0.56,1.46,0.34l1.19-0.52
                  c0.09-0.04,0.17-0.09,0.25-0.15l0.68-0.51l0.06,0.19c0.08,0.26,0.25,0.48,0.46,0.64l1.24,0.88l1.9,1.93
                  c0.16,0.16,0.35,0.27,0.57,0.33l0.97,0.25l0.63,0.87c0.08,0.12,0.19,0.22,0.31,0.31l0.8,0.53l0.02,0.54l-0.44,0.83l-0.71,0.94
                  c-0.27,0.36-0.33,0.84-0.14,1.25l0.45,1.03l0.2,0.87l-0.28,0.59l-0.7,0.82c-0.15,0.18-0.24,0.4-0.28,0.63l-0.18,1.23
                  c-0.04,0.33,0.04,0.67,0.25,0.94l0.79,1.03c0.12,0.16,0.28,0.29,0.47,0.38l1.06,0.48l1.5,1.12l-0.03,0.49l-0.84,2.2
                  c-0.09,0.25-0.1,0.51-0.04,0.76l0.73,2.58c0.07,0.25,0.21,0.46,0.4,0.62l0.98,0.81c0.11,0.09,0.24,0.16,0.37,0.21l0.9,0.34
                  l0.69,0.69c0.07,0.08,0.16,0.15,0.25,0.2l0.75,0.45l0.32,0.8c0.05,0.12,0.12,0.24,0.2,0.34l0.7,0.83l0.3,0.67l-0.18,1.25l-1.68,0.49
                  c-0.62,0.19-1,0.82-0.86,1.45l0.26,1.25c0.05,0.27,0.19,0.51,0.4,0.69l0.94,0.82c0.24,0.21,0.55,0.33,0.86,0.31l1.28-0.04
                  c0.07,0,0.13-0.01,0.2-0.02c0.02,0.11,0.06,0.22,0.11,0.32l0.62,1.19c0.11,0.21,0.29,0.39,0.5,0.51l1.16,0.65
                  c0.18,0.1,0.38,0.16,0.59,0.16l1.78,0.05l0.23,0.42l0.42,1.25l0.3,1.31c0.03,0.11,0.06,0.21,0.11,0.3l0.42,0.8l-0.02,0.95
                  c0,0.16,0.02,0.32,0.08,0.47l0.46,1.21c0.09,0.23,0.23,0.42,0.43,0.57l1,0.74c0.25,0.19,0.58,0.27,0.89,0.23l1.15-0.14l1.01-0.06
                  l0.21,0.07l0.05,0.45c0,0.05,0.01,0.1,0.02,0.15l0.27,1.24c0.07,0.34,0.29,0.63,0.59,0.81l1.07,0.63c0.07,0.04,0.14,0.07,0.22,0.1
                  l1.22,0.42c0.06,0.02,0.13,0.04,0.19,0.05l0.78,0.14l0.3,0.35l0.18,0.96c0.03,0.14,0.08,0.28,0.16,0.41l0.6,0.99l0.5,1.11
                  c0.04,0.09,0.09,0.17,0.15,0.25l0.53,0.68l0.03,0.83c0.01,0.25,0.09,0.49,0.23,0.69l-0.31,0.22c-0.05,0.03-0.09,0.07-0.14,0.11
                  l-0.77,0.73l-0.72,0.07l-0.71-0.17l-1.59-1.51c-0.45-0.44-1.15-0.47-1.64-0.08l-1.01,0.81c-0.34,0.27-0.51,0.69-0.46,1.12l0.12,1.11
                  l-0.13,1.12c-0.02,0.14-0.01,0.29,0.02,0.43l0.23,1.01l-0.28,2.45c-0.02,0.15-0.01,0.31,0.03,0.46l0.07,0.25l-0.27,0.14
                  c-0.59,0.32-0.82,1.05-0.52,1.65l0.56,1.13c0.02,0.05,0.04,0.09,0.07,0.13l0.7,1.08c0.03,0.04,0.06,0.08,0.09,0.12l0.85,1.02
                  c0.12,0.15,0.28,0.26,0.45,0.34l1.21,0.54l1.2,0.45c0.19,0.07,0.39,0.1,0.59,0.07l0.75-0.09l0.57,0.47c0.52,0.44,1.3,0.37,1.75-0.14
                  l0.87-1.01c0.1-0.13,0.18-0.27,0.23-0.42l0.47-1.38l0.31-1.21c0.01-0.05,0.02-0.11,0.03-0.16l0.22-1.96l0.45-0.17
                  c0.25-0.1,0.47-0.27,0.61-0.5l0.52-0.8l0.89-0.42c0.06-0.02,0.12-0.06,0.17-0.1l0.94-0.64l0.38-0.16l0.57,0.42
                  c0.13,0.09,0.28,0.16,0.43,0.2l1.17,0.29l0.83,0.26l0.38,0.68c0.09,0.18,0.24,0.34,0.41,0.45l1.06,0.69
                  c0.01,0.01,0.02,0.01,0.04,0.02l-2.1,3.62c-0.02,0.04-0.04,0.08-0.06,0.12l-0.51,1.13c-0.09,0.2-0.13,0.42-0.1,0.63l0.13,1.31
                  l-0.23,1.19c-0.07,0.36,0.03,0.73,0.26,1.02l0.57,0.7l0.01,0.62l-0.42,0.93c-0.04,0.1-0.07,0.19-0.09,0.29l-0.26,1.4
                  c-0.06,0.35,0.02,0.7,0.23,0.98l0.76,1.01c0.23,0.31,0.59,0.49,0.97,0.5l1.15,0.03l0.83,0.22l0.8,0.61c0.14,0.1,0.3,0.17,0.46,0.22
                  l1.15,0.27l1.05,0.59c0.08,0.04,0.15,0.08,0.23,0.1l1.04,0.33l2.99,1.92c0.08,0.05,0.17,0.09,0.25,0.12l1.08,0.4l0.96,0.43
                  l0.78,0.69c0.16,0.14,0.36,0.24,0.57,0.28l1.05,0.23l0.52,0.31l0.23,0.66l0.18,0.99l-0.02,1.11c0,0.11,0.01,0.22,0.04,0.32
                  l0.29,1.17l-0.15,1.03c-0.02,0.15-0.01,0.31,0.02,0.46l0.3,1.27c0.07,0.26,0.21,0.5,0.42,0.67l0.98,0.81
                  c0.07,0.06,0.15,0.11,0.23,0.15l0.85,0.43l0.3,0.51l0.04,0.92c0.01,0.21,0.07,0.41,0.17,0.59l0.69,1.15c0.15,0.26,0.4,0.46,0.7,0.55
                  l1.11,0.35l1.07,0.57c0.04,0.03,0.09,0.05,0.14,0.07l1.2,0.46c0.1,0.03,0.2,0.06,0.3,0.07l0.43,0.05l0.08,1.32l-0.08,1.63l0.03,1.24
                  c0.01,0.4,0.21,0.76,0.53,0.99l0.96,0.68l0.81,0.8c0.08,0.09,0.18,0.16,0.29,0.22l0.92,0.5l0.72,0.74c0.17,0.18,0.39,0.31,0.63,0.36
                  l2.55,0.53l1.14,0.31c0.3,0.09,0.62,0.06,0.9-0.09l0.72-0.37l0.19,1.36l-0.02,1.08l-0.07,1.17l-0.22,1.14
                  c-0.09,0.47,0.1,0.96,0.49,1.24l1.11,0.81c0.14,0.1,0.29,0.17,0.45,0.2l1.24,0.3l1.35,0.42c0.04,0.02,0.08,0.03,0.12,0.03l1.22,0.25
                  c0.09,0.02,0.18,0.03,0.27,0.03l2.7-0.07l0.85,0.57c0.21,0.14,0.45,0.21,0.7,0.21c0.09,0,0.18-0.01,0.27-0.03l1.33-0.3l1.14-0.18
                  l1.54,0.04c0.07,0,0.13,0,0.2-0.01l4.9-0.7c0.21-0.04,0.41-0.12,0.58-0.26l0.89-0.69l1.12-0.46c0.11-0.05,0.21-0.11,0.3-0.18
                  l1.02-0.81c0.13-0.11,0.24-0.24,0.32-0.39l0.6-1.13c0.06-0.1,0.1-0.21,0.12-0.32l0.27-1.22c0.03-0.15,0.04-0.3,0.02-0.45l-0.13-0.88
                  l0.14-0.13l0.98-0.13c0.32-0.04,0.6-0.2,0.8-0.44l0.74-0.89l0.64-0.54l2.18-0.56l0.48,0.09l0.5,0.71c0.06,0.08,0.12,0.15,0.2,0.21
                  l0.96,0.84c0.25,0.22,0.57,0.33,0.9,0.31l0.55-0.03l-0.33,1.06c-0.12,0.36-0.06,0.76,0.15,1.07l0.66,0.98l0.46,0.93L931.08,478.84z"/>
                <title>Rimavská Sobota</title>
              </Link>


              {/* Poltar Banskobystricky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Poltár")}>
                <path
                  d="M802.23,466.94l-1.73,0.94c-0.39,0.21-0.64,0.62-0.65,1.06l-0.04,1.25c0,0.07,0,0.14,0.01,0.21l0.16,1.11
                  l-0.16,1.05c0,0.04-0.01,0.08-0.01,0.12l-0.06,1.28c0,0.07,0,0.13,0.01,0.2l0.3,2.47l0.03,1.29l-0.19,1.14
                  c-0.04,0.23-0.02,0.46,0.07,0.66l-1.45-0.23c-0.43-0.07-0.86,0.08-1.15,0.41l-0.6,0.68l-1.07,0.29l-2.39,0.77l-0.81-0.02l-1.47-0.61
                  l-1.51-0.74c-0.06-0.03-0.14-0.05-0.21-0.08l-1.13-0.31l-1.35-0.8c-0.12-0.08-0.26-0.13-0.4-0.16l-1.35-0.26
                  c-0.07-0.01-0.13-0.02-0.19-0.02l-1.31-0.05h-0.13l-1.4,0.09c-0.33,0.02-0.63,0.17-0.85,0.42l-0.82,0.92
                  c-0.11,0.12-0.19,0.26-0.25,0.41l-0.4,1.13l-0.56,0.76l-0.66,0.27l-1.39-0.05l-1.22-0.13c-0.39-0.05-0.77,0.1-1.04,0.38
                  c-0.27,0.29-0.39,0.68-0.32,1.06l0.03,0.19l-0.58,0.18l-0.43,0.06l-0.27-0.4c-0.02-0.03-0.05-0.06-0.07-0.09l-0.85-1.02
                  c-0.36-0.42-0.95-0.55-1.45-0.34l-1.19,0.51c-0.05,0.02-0.1,0.05-0.14,0.07l-1.01,0.6l-1.13,0.55c-0.27,0.13-0.49,0.36-0.61,0.65
                  l-0.34,0.84l-0.71,0.49c-0.15,0.1-0.27,0.23-0.36,0.38l-0.18,0.29l-1.73-0.49c-0.09-0.03-0.18-0.04-0.27-0.05l-1.19-0.07l-1.15-0.2
                  c-0.27-0.05-0.55,0-0.79,0.12l-0.92,0.49l-1.23,0.07c-0.52,0.03-0.98,0.4-1.12,0.91l-0.4,1.4c-0.07,0.22-0.07,0.46,0,0.69l0.37,1.25
                  l0.07,0.21l-0.17,0.1c-0.1,0.06-0.2,0.13-0.29,0.22l-0.56,0.58c-0.18-0.17-0.42-0.29-0.68-0.33c-0.4-0.07-0.81,0.07-1.09,0.36
                  l-0.44,0.46l-0.29-0.04l-0.79-0.64c-0.28-0.23-0.64-0.32-0.99-0.26l-1.58,0.26c-0.26,0.04-0.49,0.16-0.68,0.34l-0.73,0.73
                  l-0.72,0.23l-1.08-0.19l-1.32-0.33c-0.06-0.02-0.13-0.03-0.2-0.04l-1.35-0.11c-0.29-0.02-0.57,0.06-0.81,0.22l-1.27,0.87
                  c-0.06,0.04-0.12,0.09-0.17,0.14l-0.64,0.63l-0.89,0.19l-0.84-0.36l-0.61-0.42l-0.37-1.18c-0.08-0.25-0.23-0.47-0.44-0.63
                  l-0.81-0.61l-0.63-1.22c-0.04-0.07-0.08-0.14-0.13-0.21l-0.83-1.04c-0.03-0.04-0.07-0.08-0.1-0.11l-1.05-1.03
                  c-0.14-0.14-0.31-0.24-0.5-0.3l-1.18-0.38c-0.21-0.07-0.43-0.08-0.65-0.03l0.09-0.22c0.05-0.15,0.08-0.32,0.08-0.48l-0.03-1.19
                  l0.24-1.12c0.06-0.26,0.03-0.53-0.08-0.77l-0.45-1l0.25-0.84l0.6-1.12l0.82-1.13l0.25-0.36l1.14,0.36l0.55,0.83
                  c0.06,0.08,0.13,0.16,0.21,0.23l1,0.89c0.34,0.31,0.82,0.4,1.25,0.25l1.29-0.46c0.36-0.13,0.63-0.41,0.76-0.77l0.45-1.27
                  c0.03-0.09,0.05-0.18,0.06-0.27l0.15-1.22c0.02-0.19,0-0.38-0.06-0.56l-0.41-1.18c-0.04-0.11-0.09-0.22-0.16-0.32l-0.78-1.08
                  l-0.6-1.12c-0.08-0.15-0.19-0.28-0.32-0.39l-1.11-0.89c-0.05-0.03-0.1-0.07-0.15-0.1l-0.93-0.54l-0.83-0.82
                  c-0.14-0.15-0.33-0.26-0.53-0.32l-1.17-0.34l-0.7-0.56l-0.8-0.83c-0.08-0.09-0.18-0.17-0.29-0.23l-2.63-1.49
                  c-0.17-0.09-0.37-0.15-0.56-0.16l-1.12-0.05l-0.87-0.41l-0.6-0.42c0.29-0.19,0.49-0.49,0.56-0.83c0.07-0.35-0.02-0.72-0.25-1
                  l-0.89-1.13c-0.1-0.14-0.23-0.24-0.38-0.32l0.45-0.91c0.18-0.36,0.17-0.79-0.02-1.15l-0.61-1.12c-0.15-0.27-0.4-0.48-0.7-0.58
                  l-1.27-0.43l-1.09-0.28l-1.49-0.89l0.37-0.28c0.04-0.03,0.07-0.06,0.1-0.08l2.2-2.08c0.33-0.32,0.46-0.79,0.33-1.24l-0.34-1.21
                  c-0.01-0.06-0.04-0.12-0.06-0.18l-1.72-3.81c-0.01-0.04-0.03-0.07-0.05-0.1l-0.54-1.02l-0.22-0.67l0.31-0.77
                  c0.02-0.05,0.03-0.09,0.04-0.14l0.3-1.06l0.48-0.94l0.77-0.95c0.25-0.31,0.34-0.72,0.24-1.1l-0.28-1.06l0.04-1.1
                  c0.01-0.28-0.08-0.56-0.25-0.78l-0.73-1c-0.09-0.12-0.2-0.22-0.31-0.3l-1.03-0.69l-0.71-0.76l-0.6-1.1l-0.74-1.9l0.23-0.99
                  c0.07-0.32,0.02-0.65-0.15-0.93l-0.64-1.05c-0.02-0.03-0.04-0.06-0.06-0.09l-0.9-1.23l-0.32-0.41l0.26,0.09
                  c0.3,0.09,0.62,0.08,0.9-0.05l1.04-0.46l1.23-0.22c0.04-0.01,0.09-0.02,0.13-0.03l0.96-0.27l0.68,0.34l0.93,0.6l0.45,0.67l0.27,0.94
                  l0.21,1.17c0.02,0.12,0.06,0.24,0.12,0.35l0.56,1.08l0.48,1.13c0.25,0.61,0.93,0.91,1.55,0.71l1.52-0.51
                  c0.59-0.2,0.95-0.82,0.83-1.43l-0.22-1.11l0.74-3.34l0.25-0.95l0.52-0.17c0.4-0.13,0.71-0.45,0.82-0.85l0.33-1.14l0.85-0.07
                  c0.35-0.03,0.67-0.2,0.88-0.48c0.22-0.27,0.31-0.62,0.25-0.96l-0.15-0.98l0.33-0.48l0.78-0.14c0.4-0.08,0.74-0.34,0.91-0.71
                  s0.15-0.8-0.06-1.15l-0.96-1.65c0.19-0.03,0.37-0.09,0.54-0.2l1.28-0.86c0.14-0.1,0.25-0.22,0.35-0.36l0.52-0.79l0.89-0.47
                  c0.2-0.11,0.36-0.26,0.48-0.44l0.61-0.97l0.64-0.35l1.11,0.3c0.26,0.06,0.53,0.05,0.78-0.04l1.52-0.59l1.34-0.57l1.78-0.39
                  c0.05-0.01,0.1-0.03,0.15-0.04l1.44-0.52l1.39-0.15c0.36-0.03,0.69-0.23,0.9-0.52c0.2-0.3,0.27-0.68,0.18-1.03l-0.03-0.14
                  c0.37-0.08,0.69-0.34,0.85-0.69c0.18-0.37,0.16-0.8-0.05-1.16l-0.64-1.1c-0.07-0.11-0.15-0.22-0.25-0.3l-0.53-0.48
                  c0.23-0.03,0.45-0.12,0.64-0.27l1.99-1.62c0.29-0.24,0.46-0.59,0.46-0.96s-0.16-0.72-0.44-0.96l-1.05-0.89
                  c-0.03-0.03-0.06-0.05-0.09-0.07l-1.03-0.72c-0.1-0.07-0.21-0.13-0.33-0.17l-1.12-0.37l-0.72-0.31l-0.46-0.79
                  c-0.04-0.07-0.09-0.13-0.14-0.19l-0.91-1.05c-0.06-0.08-0.14-0.15-0.23-0.21l-0.92-0.64l-0.66-0.88c-0.14-0.2-0.34-0.35-0.57-0.43
                  l-1.29-0.47c-0.2-0.08-0.41-0.1-0.62-0.07l-0.93,0.14l-0.59-0.29l-0.37-0.33c0.1-0.06,0.2-0.13,0.27-0.22l0.79-0.86l0.92-0.54
                  c0.06-0.04,0.12-0.08,0.17-0.13l0.85-0.72l0.99-0.54c0.06-0.03,0.12-0.07,0.17-0.11l1-0.77c0.1-0.08,0.19-0.17,0.26-0.27l0.64-0.9
                  l0.69-0.59l0.81-0.33l1.2-0.16c0.13-0.02,0.25-0.05,0.36-0.1l1.14-0.52c0.06-0.03,0.11-0.06,0.17-0.09l1.06-0.7l4.77-2.1l1.33-0.54
                  c0.13-0.06,0.25-0.13,0.35-0.22l0.42,0.35l1.05,1.11l1.12,1.09c0.09,0.09,0.2,0.16,0.31,0.22l1.29,0.64c0.2,0.1,0.42,0.14,0.64,0.13
                  l1.17-0.08l0.93,0.12l1.07,0.59l1.12,0.52l0.37,0.5l0.1,0.91c0.01,0.09,0.03,0.17,0.05,0.25l0.44,1.33
                  c0.14,0.41,0.47,0.72,0.89,0.82l1.35,0.34c0.08,0.02,0.16,0.03,0.24,0.04l0.92,0.04l0.81,0.5c0.09,0.05,0.19,0.1,0.29,0.13
                  l0.46,0.14l0.06,0.43c0.05,0.34,0.24,0.65,0.52,0.85l1.15,0.81c0.05,0.04,0.11,0.07,0.16,0.1l1.16,0.58
                  c0.07,0.03,0.15,0.06,0.23,0.08l0.86,0.24l0.33,0.44l0.2,1.11c0.02,0.09,0.04,0.19,0.08,0.27l0.52,1.21
                  c0.03,0.06,0.06,0.12,0.09,0.17l0.66,1.05c0.09,0.15,0.22,0.28,0.36,0.37l0.85,0.58l0.72,0.93l0.62,1.19
                  c0.17,0.32,0.46,0.55,0.82,0.63l1.2,0.29c0.15,0.04,0.32,0.05,0.48,0.03l1.05-0.16l1.19,0.19l0.81,0.33l0.85,0.89
                  c0.05,0.06,0.1,0.1,0.16,0.14l0.9,0.67l1.04,1.93c0.09,0.19,0.23,0.34,0.4,0.46l0.88,0.6l0.38,0.6l0.09,1.22l0.11,0.99l-0.37,0.95
                  c-0.07,0.17-0.1,0.36-0.09,0.54l0.08,1.16l-0.15,0.52l-0.44,0.25l-1.02,0.18c-0.33,0.06-0.62,0.24-0.81,0.52l-0.71,1.04l-1.62,2.01
                  c-0.03,0.03-0.05,0.06-0.07,0.09l-0.92,1.38c-0.08-0.05-0.18-0.09-0.28-0.12l-1.23-0.33c-0.05-0.01-0.1-0.02-0.16-0.03l-1.27-0.17
                  c-0.11-0.01-0.21-0.01-0.31,0l-1.27,0.14c-0.21,0.02-0.41,0.1-0.59,0.22l-1.03,0.74c-0.14,0.11-0.26,0.23-0.34,0.38l-0.61,1.02
                  l-0.88,1.33c-0.04,0.05-0.07,0.11-0.1,0.17l-0.51,1.11c-0.04,0.09-0.07,0.18-0.09,0.27l-0.27,1.35c-0.01,0.08-0.02,0.17-0.02,0.25
                  l-0.03,2.66c-0.01,0.23,0.05,0.46,0.17,0.66l0.65,1.11c0.08,0.14,0.2,0.27,0.33,0.37l0.98,0.74c0.44,0.33,1.05,0.33,1.49,0
                  l0.86-0.63l0.07-0.01l0.06,0.25c0.04,0.12,0.09,0.23,0.15,0.34l0.68,1.08c0.02,0.03,0.05,0.07,0.07,0.1l0.81,1.03l1.65,1.87
                  l0.68,0.91l0.45,1.05c0.06,0.13,0.14,0.25,0.24,0.36l1.84,1.92l0.94,0.84c0.11,0.11,0.25,0.19,0.4,0.24l1.05,0.39l0.77,0.61
                  l0.64,0.71l0.35,0.95l0.24,1.12c0.03,0.13,0.08,0.26,0.14,0.38l0.65,1.08c0.15,0.26,0.39,0.45,0.68,0.55l1.01,0.33l0.59,0.4
                  l0.5,1.21l0.13,0.45l-0.39,0.43c-0.25,0.26-0.36,0.62-0.32,0.98c0.04,0.37,0.24,0.69,0.54,0.89l0.66,0.46l-0.71,0.86
                  c-0.05,0.06-0.09,0.12-0.12,0.18l-1.26,2.2c-0.1,0.16-0.15,0.34-0.17,0.52l-0.1,1.26c-0.02,0.36,0.1,0.71,0.35,0.97l0.78,0.81
                  l0.55,0.9l0.47,1.14l0.57,1.17c0.04,0.09,0.1,0.18,0.17,0.27l0.82,0.95c0.06,0.07,0.13,0.13,0.21,0.19l0.99,0.73
                  c0.03,0.02,0.06,0.04,0.1,0.06l1.24,0.74c0.2,0.12,0.43,0.19,0.67,0.18l1.36-0.03c0.11,0,0.22-0.02,0.33-0.05l0.03-0.01
                  L802.23,466.94z"/>
                <title>Poltár</title>
              </Link>

              {/* Detva Banskobystricky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Detva")}>
                <path
                  d="M748.6,403.08l-0.24,0.2l-0.5-0.2c-0.38-0.15-0.82-0.11-1.16,0.12c-0.34,0.23-0.55,0.62-0.55,1.03l-0.01,2.19
                  c0,0.36,0.15,0.7,0.42,0.94l0.42,0.38c-0.09,0.07-0.17,0.16-0.23,0.26c-0.19,0.29-0.25,0.65-0.16,0.99l0.01,0.05l-0.1,0.01
                  c-0.1,0.01-0.2,0.03-0.29,0.06l-1.51,0.55l-1.81,0.39c-0.07,0.01-0.15,0.04-0.22,0.07l-1.41,0.6l-1.1,0.43l-1.2-0.33
                  c-0.31-0.08-0.65-0.04-0.93,0.11l-1.37,0.75c-0.19,0.11-0.34,0.25-0.46,0.43l-0.6,0.96l-0.88,0.46c-0.19,0.1-0.35,0.25-0.47,0.43
                  l-0.56,0.85l-0.33,0.23l-0.28-0.21c-0.35-0.26-0.81-0.32-1.22-0.16l-1.17,0.47c-0.33,0.14-0.59,0.41-0.71,0.75
                  c-0.12,0.35-0.08,0.72,0.1,1.04l1.35,2.33c-0.15,0.09-0.29,0.21-0.39,0.37l-0.9,1.32c-0.18,0.26-0.25,0.58-0.2,0.89l0.02,0.13
                  l-0.38,0.03c-0.52,0.05-0.96,0.41-1.1,0.91l-0.38,1.34l-0.54,0.17c-0.41,0.13-0.72,0.46-0.83,0.88l-0.43,1.66l-0.7,3.12l-0.21-1.1
                  c-0.01-0.04-0.02-0.09-0.03-0.13l-0.35-1.19c-0.04-0.13-0.09-0.24-0.16-0.35l-0.7-1.04c-0.1-0.14-0.22-0.26-0.36-0.35l-1.2-0.78
                  c-0.04-0.02-0.08-0.05-0.12-0.07l-1.17-0.58c-0.28-0.14-0.6-0.17-0.9-0.08l-1.37,0.38l-1.32,0.23c-0.11,0.02-0.21,0.05-0.3,0.09
                  l-0.74,0.33l-0.76-0.25c-0.12-0.04-0.24-0.06-0.37-0.06l-1.25-0.02c-0.08,0-0.15,0-0.22,0.02l-1.31,0.22l-0.75,0.18l-0.13-0.1
                  l-0.07-0.64l0.06-1.22c0.02-0.4-0.16-0.78-0.47-1.03s-0.72-0.34-1.1-0.24l-1.34,0.36c-0.25,0.07-0.46,0.2-0.62,0.39
                  c-0.25-0.06-0.52-0.03-0.77,0.08l-1.04,0.47l-2.59,0.41c-0.29,0.05-0.55,0.19-0.74,0.41l-1.05,1.18c-0.08,0.08-0.14,0.18-0.19,0.28
                  l-0.44,0.9l-0.79,0.52c-0.08,0.05-0.15,0.11-0.22,0.18l-0.98,1.04c-0.17,0.18-0.28,0.4-0.32,0.64l-0.08,0.43l-0.17,0.05l-0.81-0.28
                  c-0.22-0.08-0.45-0.09-0.68-0.04l-1.53,0.34c-0.16,0.03-0.3,0.1-0.43,0.19l-2.57,1.78c-0.18,0.12-0.32,0.29-0.41,0.48l-0.55,1.16
                  c-0.11,0.23-0.14,0.48-0.11,0.72l0.16,1.03l-0.32,2.12l-0.25,0.4l-0.72,0.18c-0.09,0.02-0.17,0.05-0.25,0.09l-1.13,0.55
                  c-0.06,0.03-0.11,0.06-0.17,0.1l-0.85,0.6l-1.05,0.01c-0.34,0-0.66,0.15-0.89,0.4l-1.02,1.09c-0.09,0.09-0.16,0.2-0.22,0.31
                  l-1.03,2.14l-0.72,0.49l-0.77,0.25l-0.12-0.05l-0.03-0.28l0.21-1.07c0.11-0.51-0.12-1.04-0.56-1.31l-0.87-0.54l-0.66-0.81
                  c-0.09-0.12-0.2-0.22-0.33-0.29l-1.2-0.72c-0.29-0.18-0.64-0.23-0.97-0.13c-0.33,0.09-0.61,0.31-0.77,0.61l-0.65,1.22
                  c-0.07,0.13-0.11,0.26-0.13,0.4l-0.26,1.64l-0.35,1.18l-0.24,1.1l-0.73,0.68c-0.15,0.15-0.27,0.33-0.33,0.54l-0.08,0.23l-0.2-0.28
                  c-0.17-0.24-0.41-0.41-0.69-0.49l-1.22-0.33c-0.26-0.07-0.54-0.05-0.79,0.05l-1.2,0.48c-0.09,0.03-0.18,0.08-0.26,0.14l-0.99,0.69
                  l-0.75,0.11l-1.11-0.25l-1.09-0.34l-1-0.53c-0.11-0.06-0.23-0.1-0.35-0.13l-4.44-0.82l-1.27-0.14c-0.23-0.03-0.46,0.01-0.67,0.11
                  l-1.38,0.65c-0.37,0.17-0.63,0.52-0.7,0.92c-0.07,0.41,0.06,0.82,0.36,1.11l1.02,0.99l0.6,0.91c0.19,0.3,0.51,0.5,0.86,0.55
                  l1.11,0.16l0.97,0.39l2.03,1.11l-1.9,1.76c-0.35,0.33-0.48,0.82-0.35,1.28l0.35,1.18c0.08,0.26,0.25,0.5,0.48,0.66l0.89,0.63
                  l-4.65,0.35c-0.49,0.05-0.91,0.38-1.07,0.85c-0.16,0.46-0.03,0.98,0.33,1.32l0.36,0.33l-0.7,0.52c-0.52,0.36-0.68,1.05-0.38,1.61
                  l0.59,1.11c0.07,0.14,0.18,0.27,0.31,0.38l0.9,0.73l0.66,0.7l0.36,0.94c0.04,0.09,0.08,0.18,0.14,0.26l0.85,1.23l0.55,1.19l0.6,1.12
                  l0.48,1l0.21,1.1c0.03,0.16,0.1,0.31,0.19,0.45l0.57,0.87l0.05,0.65l-0.39,1c-0.06,0.16-0.09,0.33-0.08,0.5l0.05,1.17l-0.1,1.17
                  c-0.01,0.15,0.01,0.3,0.05,0.45l0.39,1.34c0.05,0.18,0.14,0.34,0.27,0.48l0.64,0.73l0.09,0.73c-0.02,0.01-0.03,0.02-0.04,0.04
                  l-0.82,1.06l-1.01,0.84c-0.06,0.05-0.11,0.1-0.16,0.16l-0.77,0.92l-0.77,0.76l-0.84,0.62l-0.84,0.52l-0.8-0.01l-0.73-0.1l-0.49-0.68
                  l-0.72-0.86l-0.32-0.89l-0.26-1.27l-0.71-2.68l-0.1-1.16l0.14-1.11c0.02-0.17,0.01-0.34-0.04-0.5l-0.39-1.37
                  c-0.04-0.15-0.11-0.3-0.21-0.43l-0.66-0.85l-0.3-0.92l-0.27-1.2c-0.05-0.23-0.17-0.44-0.33-0.61l-0.73-0.73l-0.42-1.01
                  c-0.08-0.2-0.21-0.37-0.38-0.5l-1-0.79l-0.36-1.04c-0.09-0.25-0.26-0.47-0.48-0.62l-1.12-0.76l-0.64-1.04
                  c-0.24-0.4-0.67-0.62-1.14-0.6l-1.18,0.07l-1.23-0.14c-0.21-0.03-0.42,0-0.61,0.08l-0.72,0.29l-1.66-0.58l-0.61-1.09
                  c-0.3-0.52-0.92-0.76-1.49-0.57l-1.1,0.37l-1.08-0.52c-0.08-0.04-0.17-0.07-0.26-0.09l-0.81-0.19l-0.2-1.86l0.08-1.05l0.19-1.13
                  l0.47-0.93c0.03-0.06,0.05-0.13,0.08-0.19l0.36-1.19c0.04-0.14,0.06-0.3,0.05-0.45l-0.1-1.34c-0.03-0.31-0.16-0.59-0.38-0.8l-1-0.98
                  c-0.13-0.13-0.29-0.23-0.47-0.29l-1.33-0.45c-0.23-0.08-0.47-0.09-0.7-0.03l-1.07,0.26l-1.98-0.28l-0.56-0.49l-0.47-0.64l-0.18-1.07
                  c-0.03-0.18-0.1-0.35-0.2-0.5l-0.79-1.15c-0.11-0.16-0.25-0.29-0.42-0.38l-0.82-0.46l-0.31-0.51l-0.04-0.81l0.1-0.71l0.63-0.61
                  c0.19-0.17,0.31-0.4,0.36-0.64l0.24-1.13l0.1-0.2l0.31,0.05l1.04,0.38c0.07,0.02,0.13,0.03,0.19,0.05l1.12,0.21l1.2,0.53
                  c0.5,0.21,1.08,0.08,1.43-0.32c0.36-0.41,0.42-1,0.15-1.46l-0.57-0.97l-0.03-0.15h0.16l0.73-0.05l0.34,0.54
                  c0.2,0.33,0.54,0.54,0.93,0.58l1.23,0.13c0.59,0.07,1.12-0.28,1.31-0.83l0.34-0.97l0.74-0.81c0.09-0.09,0.16-0.19,0.21-0.29
                  l0.66-1.33c0.04-0.08,0.07-0.17,0.09-0.25l0.39-1.56c0.02-0.08,0.04-0.17,0.04-0.26l0.04-1.23c0-0.18-0.03-0.37-0.1-0.54l-0.56-1.28
                  l-0.58-1.2c-0.04-0.08-0.09-0.16-0.14-0.22l-0.95-1.23c-0.06-0.07-0.12-0.14-0.19-0.2l-0.74-0.62l-0.15-0.37l1.22-1.36l0.21,0.13
                  c0.47,0.29,1.08,0.23,1.49-0.14c0.41-0.38,0.53-0.98,0.28-1.48l-0.54-1.11c-0.05-0.12-0.13-0.22-0.22-0.32l-0.8-0.83l-0.04-0.53
                  l0.47-0.66c0.28-0.39,0.31-0.91,0.07-1.33c-0.2-0.36-0.56-0.59-0.97-0.63l1.04-1.03c0.36-0.34,0.47-0.86,0.3-1.32l-0.24-0.65
                  l0.15-0.27l0.8-0.36c0.33-0.14,0.58-0.42,0.69-0.76l0.39-1.22c0.01-0.04,0.02-0.08,0.03-0.12l0.23-1.08l0.64-0.77
                  c0.13-0.16,0.22-0.34,0.26-0.54l2.17,0.34c0.14,0.03,0.28,0.02,0.42,0l1.38-0.25l0.63,0.21l0.39,0.91l0.38,1.13l0.16,1.12
                  c0.02,0.13,0.06,0.26,0.12,0.38l0.61,1.23c0.06,0.12,0.15,0.24,0.25,0.34l0.96,0.93c0.41,0.39,1.03,0.46,1.51,0.17l0.79-0.47
                  l0.94,0.02l1.05,0.34c0.04,0.01,0.07,0.02,0.11,0.03l1.27,0.29l1.12,0.2l0.97,0.5l0.51,0.78l0.44,0.85l0.11,1.04
                  c0.05,0.54,0.45,0.99,0.99,1.09l1.27,0.26c0.09,0.02,0.19,0.03,0.28,0.03l1.3-0.03l1.29,0.01c0.51,0.02,0.97-0.3,1.16-0.77
                  c0.2-0.47,0.09-1.01-0.27-1.37l-0.99-0.97l-1.3-1.47c-0.09-0.1-0.19-0.19-0.3-0.25l-1.03-0.62l-1.06-0.81l-0.91-0.83l-0.54-0.78
                  l-0.03-0.26l0.65-0.54c0.07-0.06,0.13-0.12,0.19-0.2l0.76-0.99c0.36-0.47,0.34-1.12-0.04-1.57l-0.97-1.14
                  c-0.11-0.13-0.24-0.24-0.4-0.31l-2.27-1.12c-0.04-0.02-0.08-0.04-0.12-0.05l-1.21-0.44l-1.13-0.51l-0.7-0.73
                  c-0.27-0.27-0.63-0.41-1.02-0.37l-0.74,0.07l-0.33-0.45c-0.06-0.08-0.13-0.15-0.21-0.22l-0.94-0.79c-0.1-0.08-0.21-0.15-0.33-0.2
                  l-1.21-0.49l-0.24-0.15l0.81-1.53l1.35-0.55c0.63-0.26,0.94-0.97,0.7-1.61l-0.22-0.56l0.24,0.05c0.1,0.02,0.21,0.04,0.31,0.03
                  l1.65-0.07l1.02-0.11l0.85,0.33c0.4,0.15,0.85,0.09,1.2-0.17l1.16-0.87c0.03-0.03,0.07-0.05,0.1-0.08l1.77-1.62l0.8-0.38l1.11-0.06
                  c0.46-0.03,0.86-0.3,1.06-0.71l0.4-0.85l1.44-1l0.72,0.1l0.95,0.24l0.77,0.62c0.14,0.11,0.29,0.19,0.46,0.23l1.23,0.34
                  c0.29,0.09,0.61,0.06,0.88-0.08l0.14-0.07l0.1,0.14c0.05,0.08,0.11,0.15,0.17,0.21l0.57,0.53l0.04,0.85c0.03,0.6,0.49,1.1,1.1,1.17
                  l2.57,0.29c0.15,0.02,0.3,0.01,0.44-0.03l1.2-0.31c0.42-0.11,0.75-0.42,0.88-0.83l0.27-0.85l0.73-0.5c0.21-0.13,0.36-0.33,0.46-0.56
                  l0.49-1.2l0.41-1.14l0.64-0.98c0.03-0.04,0.05-0.09,0.08-0.14l0.55-1.17l0.57-0.88l0.81-0.73c0.26-0.23,0.41-0.56,0.41-0.91
                  l0.01-0.74l0.48-0.33c0.1-0.07,0.18-0.15,0.26-0.24l1.73-2.14l0.89-0.65l1.22-0.57c0.22-0.1,0.41-0.27,0.53-0.48l0.57-0.91
                  l0.96-0.49l1.24-0.74c0.07-0.04,0.14-0.09,0.2-0.14l0.61-0.56l0.98-0.05c0.08-0.01,0.16-0.02,0.24-0.04l1.15-0.3l0.24,0.44
                  l1.08-0.59l1.07-0.46c0.05-0.02,0.1-0.05,0.15-0.08l0.77-0.46l1.02,0.04c0.19,0.01,0.39-0.03,0.56-0.12l1.2-0.55l1.12-0.24
                  l1.26,0.09c0.13,0.01,0.25,0,0.38-0.03l1.06-0.25l0.77-0.02l0.43,0.49c0.16,0.19,0.38,0.33,0.62,0.39l1.2,0.31
                  c0.03,0.01,0.07,0.02,0.11,0.02l1.08,0.18l0.84,0.66c0.47,0.37,1.14,0.36,1.59-0.04l0.82-0.7l0.3-0.06l0.4,0.54
                  c0.15,0.22,0.38,0.38,0.63,0.46l1.2,0.38c0.04,0.01,0.08,0.02,0.12,0.03l1.95,0.42c0.06,0.02,0.13,0.03,0.2,0.03l1.33,0.07
                  l1.31,0.18l1.07,0.28l0.75,0.31l0.3,0.74c0.1,0.24,0.26,0.44,0.47,0.57l1.05,0.69c0.17,0.11,0.36,0.18,0.56,0.2l1.48,0.16
                  c0.11,0.02,0.24,0.01,0.35-0.01l0.71-0.13l0.52,0.48c0.13,0.11,0.27,0.2,0.43,0.26l1.26,0.45c0.11,0.04,0.22,0.06,0.34,0.07
                  l1.35,0.09h0.18l1.5-0.12l0.8,0.01l0.18,0.16l-0.01,0.71l-0.27,0.55l-0.77,0.33c-0.2,0.09-0.38,0.23-0.51,0.41l-0.74,1
                  c-0.22,0.28-0.3,0.65-0.22,1l0.28,1.33c0.08,0.38,0.33,0.7,0.69,0.87l1.17,0.55c0.14,0.07,0.29,0.11,0.44,0.12l1.37,0.1h0.18
                  l1.04-0.08l0.96,0.28l1.13,0.41l1.01,0.59c0.12,0.08,0.25,0.13,0.39,0.15l0.67,0.14l0.07,0.28c0.06,0.23,0.19,0.44,0.37,0.61
                  l1.03,0.93c0.09,0.07,0.18,0.14,0.28,0.19l1.1,0.55c0.23,0.11,0.49,0.15,0.75,0.12l1-0.16l0.64,0.24l0.56,0.75
                  c0.08,0.1,0.17,0.2,0.28,0.28l0.97,0.67l0.73,0.84l0.61,1.04c0.14,0.23,0.34,0.41,0.58,0.51l1.14,0.5c0.03,0.02,0.07,0.03,0.11,0.05
                  l1,0.33L748.6,403.08z"/>
                <title>Detva</title>
              </Link>


              {/* Lucenec - Banskobystricky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Lučenec")}>
                <path style={{ fill: crimesInRegionBanskobystricky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionBanskobystricky[2].crimePercent)] ? crimesInRegionBanskobystricky[2].color : "#D3D3D3" }}
                  d="M806.15,543.43c-0.15,0.02-0.3,0.07-0.44,0.15l-1.08,0.6c-0.38,0.21-0.62,0.6-0.64,1.03l-0.06,1.18l-0.1,1.15
                  l-0.37,1.07l-0.5,1.2c-0.04,0.09-0.07,0.19-0.09,0.3l-0.18,1.21c-0.02,0.18-0.01,0.37,0.04,0.54l0.3,0.99l-0.15,0.81l-0.34,0.86
                  l-0.97,1.21c-0.16,0.21-0.26,0.46-0.27,0.72l-0.35-0.02c-0.4-0.01-0.77,0.14-1.02,0.44l-0.85,1c-0.09,0.1-0.16,0.22-0.21,0.34
                  l-0.32,0.79l-0.35,0.21l-0.57-0.2l-1.25-0.54c-0.1-0.05-0.21-0.08-0.32-0.1l-1.31-0.19c-0.44-0.06-0.89,0.12-1.16,0.47
                  c-0.28,0.35-0.35,0.82-0.18,1.24l0.62,1.54l-0.12,1.13c-0.03,0.21,0,0.41,0.08,0.61l0.38,0.93l0.02,0.73l-1.18,2.72l-0.73,1.23
                  c-0.04,0.07-0.07,0.14-0.09,0.21c-0.27-0.17-0.61-0.24-0.95-0.17l-0.9,0.19l-0.8-0.33c-0.21-0.09-0.43-0.12-0.65-0.09l-1.27,0.17
                  c-0.08,0.01-0.16,0.03-0.24,0.06l-0.16,0.05c0.14-0.34,0.13-0.72-0.04-1.05l-0.57-1.11c-0.31-0.61-1.05-0.85-1.66-0.55l-1.13,0.55
                  c-0.35,0.17-0.61,0.5-0.68,0.89l-0.18,0.96l-0.54,0.69l-0.86,0.83c-0.27,0.26-0.41,0.63-0.38,1l0.06,0.73l-0.12,0.15l-0.55-0.02
                  l-0.43-0.11l-0.15-0.53c-0.08-0.31-0.28-0.57-0.55-0.74l-1.12-0.68c-0.09-0.05-0.18-0.09-0.27-0.12l-1.24-0.39
                  c-0.27-0.09-0.56-0.08-0.83,0.03l-1.17,0.46c-0.17,0.06-0.33,0.17-0.45,0.3l-0.86,0.92l-0.88,0.77c-0.16,0.13-0.28,0.3-0.35,0.49
                  l-0.5,1.3c-0.04,0.1-0.06,0.2-0.07,0.31l-0.09,0.72l-0.4,0.33l-0.79,0.24l-0.49-0.07l-1.85-2.66c0.12-0.08,0.23-0.18,0.31-0.3
                  l0.77-1.09c0.2-0.28,0.27-0.63,0.2-0.97l-0.14-0.7l0.53-0.5c0.03-0.02,0.05-0.04,0.07-0.07l0.76-0.83l0.94-0.62
                  c0.08-0.06,0.15-0.11,0.21-0.18l1.07-1.1c0.15-0.15,0.25-0.33,0.31-0.53l0.38-1.35c0.1-0.36,0.04-0.75-0.18-1.06l-0.55-0.78
                  l-0.12-1.1c-0.03-0.26-0.14-0.5-0.31-0.7l-0.85-0.94c-0.33-0.36-0.85-0.5-1.32-0.35l-1.52,0.49l-0.19-0.4
                  c-0.02-0.06-0.05-0.11-0.09-0.16l-0.68-1.04c-0.02-0.04-0.05-0.08-0.08-0.12l-0.79-0.95c-0.13-0.16-0.31-0.28-0.5-0.36l-0.89-0.36
                  l-0.66-0.76c-0.24-0.28-0.58-0.43-0.93-0.44l-0.8-0.01l-0.31-0.28l-0.15-0.86c-0.02-0.14-0.07-0.28-0.14-0.4l-0.62-1.11
                  c-0.19-0.34-0.52-0.57-0.91-0.63l-1.17-0.17l-1.04-0.31l-1.18-0.51c-0.19-0.09-0.4-0.12-0.61-0.11l-2.6,0.29
                  c-0.25,0.03-0.47,0.13-0.66,0.3l-1.04,0.9c-0.08,0.07-0.16,0.15-0.22,0.24l-0.69,1.02c-0.06,0.09-0.11,0.2-0.15,0.3l-0.33,1.01
                  l-0.6,0.8l-0.55,0.6l-0.85,0.14h-1.26c-0.13,0-0.25,0.02-0.37,0.06l-0.8,0.24l-0.71-0.3l-1.29-0.72c-0.18-0.1-0.39-0.16-0.59-0.16
                  l-0.6-0.01l-0.28-0.52c-0.07-0.14-0.17-0.26-0.28-0.35l-1.8-1.54l-0.43-0.92l-0.45-1.22c-0.07-0.2-0.2-0.38-0.36-0.52l-0.38-0.33
                  l0.13-0.64c0.04-0.23,0.02-0.47-0.07-0.69l-0.52-1.36l-0.23-1.06l-0.37-2.5c-0.02-0.11-0.05-0.23-0.1-0.33l-0.57-1.21
                  c-0.15-0.32-0.43-0.56-0.77-0.67l-1.51-0.46c-0.11-0.03-0.22-0.05-0.33-0.05l-1.23-0.03c-0.35,0-0.71,0.14-0.96,0.41l-0.59,0.65
                  l-0.65,0.12l-0.41-0.13l-0.18-0.62c-0.07-0.22-0.19-0.42-0.36-0.57l-3.06-2.86c-0.24-0.24-0.56-0.36-0.9-0.36
                  c-0.34,0.01-0.66,0.15-0.89,0.4l-0.71,0.77l-0.93,0.54c-0.12,0.07-0.22,0.15-0.31,0.26l-0.73,0.83l-0.83,0.61l-0.95,0.53l-1.09,0.41
                  c-0.28,0.1-0.52,0.31-0.66,0.57l-0.65,1.2l-0.43,0.91l-1.77,1.24c-0.04,0.03-0.08,0.06-0.12,0.1l-0.99,0.88
                  c-0.07,0.06-0.14,0.13-0.19,0.21l-1.39,1.89l-0.88,0.72c-0.05,0.04-0.11,0.09-0.15,0.15l-0.68,0.78l-0.63,0.35l-0.99,0.03
                  c-0.14,0-0.28,0.03-0.42,0.09l-1.15,0.45c-0.06,0.02-0.12,0.05-0.17,0.08l-1.15,0.67l-1.02,0.7c-0.1,0.06-0.18,0.14-0.26,0.22
                  l-0.62,0.75l-0.87,0.41c-0.07-0.1-0.15-0.18-0.24-0.25l0.59-1.03c0.09-0.15,0.14-0.31,0.16-0.48l0.14-1.21l0.21-1.08l0.43-1.12
                  c0.05-0.12,0.07-0.25,0.08-0.38l0.06-1.23c0.01-0.2-0.03-0.4-0.11-0.58l-0.51-1.12c-0.02-0.05-0.05-0.09-0.08-0.14l-0.6-0.97
                  l-0.33-0.84l0.06-0.82l0.79-2.36c0.03-0.08,0.05-0.16,0.06-0.23l0.2-1.4c0.03-0.26-0.01-0.52-0.13-0.75l-0.57-1.13l-0.25-0.99
                  l-0.28-2.38l0.16-0.33l0.54-0.21l0.96,0.1c0.1,0.01,0.2,0.01,0.29,0l1.29-0.16c0.03,0,0.07-0.01,0.11-0.02l1.26-0.28
                  c0.53-0.12,0.92-0.55,0.97-1.09c0.06-0.53-0.23-1.04-0.72-1.27l-0.87-0.4l-0.71-0.8l-0.69-0.67l-0.35-0.92
                  c-0.03-0.08-0.07-0.16-0.12-0.23l-0.67-1.03c-0.12-0.18-0.27-0.32-0.46-0.42l-0.89-0.48l-0.67-0.77c-0.23-0.27-0.57-0.43-0.92-0.43
                  l-1.44-0.02c-0.06,0-0.11,0-0.17,0.01l-1.24,0.15c-0.1,0.01-0.19,0.03-0.29,0.07l-1.18,0.44c-0.33,0.12-0.6,0.39-0.73,0.72
                  l-0.46,1.19c-0.06,0.16-0.09,0.34-0.08,0.51l0.01,0.11l-1.91-0.47l-0.32-0.15v-0.43c0-0.06,0-0.13-0.01-0.19l-0.19-1.33
                  c-0.03-0.21-0.12-0.41-0.25-0.58l-0.58-0.75l-0.18-0.98c-0.01-0.07-0.03-0.13-0.05-0.19l-0.41-1.14l-0.28-1.16l0.1-1.08
                  c0.01-0.12,0.01-0.23-0.01-0.34l-0.35-1.99c-0.04-0.22-0.14-0.43-0.28-0.6l-1.08-1.25c-0.15-0.17-0.34-0.3-0.56-0.37l-0.97-0.32
                  l-0.19-0.28l0.14-0.79c0.1-0.59-0.22-1.16-0.77-1.38l-1.14-0.45c-0.13-0.05-0.26-0.08-0.4-0.09l-0.47-0.02l-0.16-0.4l-0.26-1.14
                  c-0.04-0.18-0.11-0.34-0.22-0.48l-0.75-1c-0.13-0.17-0.29-0.3-0.48-0.39l-0.61-0.28l-0.7-2.99l-0.18-1.28
                  c-0.03-0.2-0.1-0.39-0.22-0.55l-0.64-0.91l-0.29-0.98c-0.06-0.22-0.18-0.41-0.34-0.56l-0.98-0.92c-0.4-0.37-0.99-0.45-1.47-0.18
                  l-1,0.55l-0.92,0.29l-1.15-0.06c-0.17-0.01-0.35,0.02-0.52,0.09l-1.02,0.4l0.12-0.77c0.01-0.1,0.01-0.19,0-0.29l-0.08-0.96
                  l1.52-3.15l0.64-1.06c0.14-0.24,0.2-0.51,0.17-0.78l-0.13-1.22c-0.06-0.61-0.55-1.08-1.16-1.12l-1.24-0.08
                  c-0.19-0.01-0.37,0.02-0.54,0.09l-0.77,0.3l-1.49-0.5l-0.54-0.79c-0.04-0.05-0.08-0.1-0.12-0.15l-0.85-0.9
                  c-0.13-0.14-0.3-0.25-0.48-0.31l-0.83-0.31l-0.27-0.61l-0.21-1.19c-0.04-0.23-0.14-0.45-0.3-0.62l-0.89-0.99l-0.66-0.82l-0.72-2.15
                  c-0.06-0.19-0.17-0.37-0.31-0.51l-0.85-0.84l0.6-0.07l-0.15-1.32c-0.03-0.26-0.14-0.5-0.31-0.69l-0.72-0.81l-0.25-0.85l0.09-1.02
                  v-0.15l-0.04-0.98l0.4-1.02c0.06-0.17,0.09-0.36,0.08-0.55l-0.1-1.26c-0.02-0.21-0.09-0.41-0.21-0.59l-0.61-0.93l-0.19-1.01
                  c-0.02-0.1-0.06-0.2-0.1-0.3l-0.58-1.2l-0.59-1.1l-0.56-1.22c-0.03-0.07-0.07-0.13-0.11-0.19l-0.83-1.2l-0.39-1.03
                  c-0.06-0.15-0.15-0.29-0.26-0.41l-0.89-0.94c-0.04-0.04-0.08-0.08-0.12-0.11l-0.69-0.56l0.07-0.05c0.2-0.14,0.35-0.34,0.44-0.56
                  l0.46-1.17c0.04-0.1,0.07-0.2,0.08-0.3l3.93-0.29l1.27-0.15c0.42-0.05,0.79-0.31,0.98-0.7c0.18-0.38,0.16-0.83-0.07-1.2l-0.71-1.15
                  c-0.09-0.15-0.23-0.29-0.38-0.39l-1.03-0.66l-0.77-0.55l-0.01-0.02l2.33-2.18c0.25-0.23,0.4-0.55,0.4-0.89
                  c0-0.35-0.13-0.68-0.38-0.91l-0.89-0.87c-0.07-0.08-0.16-0.14-0.25-0.19l-2.49-1.38c-0.04-0.02-0.08-0.04-0.12-0.06l-1.17-0.47
                  c-0.1-0.04-0.19-0.06-0.29-0.08l-0.7-0.1l-0.16-0.24l4.2,0.78l0.94,0.5c0.07,0.04,0.14,0.07,0.22,0.09l1.25,0.39
                  c0.03,0.01,0.06,0.02,0.1,0.03l1.38,0.31c0.15,0.03,0.3,0.04,0.45,0.02l1.28-0.19c0.2-0.03,0.38-0.1,0.54-0.22l1.11-0.77l0.68-0.27
                  l0.38,0.1l0.52,0.73c0.27,0.38,0.72,0.58,1.18,0.52l1.33-0.17c0.48-0.06,0.89-0.4,1.03-0.87l0.29-0.92l0.77-0.72
                  c0.18-0.17,0.31-0.4,0.36-0.64l0.3-1.39l0.35-1.17c0.01-0.06,0.03-0.11,0.04-0.17l0.19-1.27l0.51,0.62
                  c0.09,0.11,0.19,0.21,0.31,0.28l0.31,0.19l-0.08,0.42c-0.03,0.13-0.04,0.27-0.02,0.41l0.16,1.24c0.06,0.46,0.37,0.85,0.8,1.01
                  l1.23,0.46c0.27,0.1,0.56,0.11,0.82,0.02l1.36-0.44c0.12-0.04,0.23-0.09,0.32-0.16l1.13-0.77c0.18-0.12,0.32-0.28,0.42-0.47
                  l1.12-2.3l0.52-0.56l0.9-0.01c0.25,0,0.49-0.08,0.7-0.23l1.09-0.76l0.92-0.45l1.08-0.27c0.31-0.07,0.58-0.27,0.76-0.54l0.67-1.06
                  c0.09-0.16,0.16-0.33,0.18-0.52l0.15-1.22l0.21-1.27c0.02-0.12,0.03-0.25,0.01-0.38L694.4,434l0.25-0.52l2.07-1.43l0.95-0.21
                  l0.84,0.29c0.24,0.08,0.5,0.09,0.74,0.02l1.3-0.35c0.47-0.13,0.81-0.51,0.9-0.99l0.15-0.84l0.63-0.67l0.95-0.63
                  c0.19-0.12,0.33-0.29,0.43-0.49l0.52-1.05l0.63-0.71l2.35-0.38c0.1-0.02,0.21-0.05,0.31-0.09l0.52-0.24l0.57,0.42
                  c0.28,0.21,0.63,0.29,0.96,0.23c0.06,0.29,0.23,0.56,0.47,0.74l1.04,0.78c0.3,0.22,0.68,0.3,1.05,0.22l1.04-0.27l0.34,0.67
                  c0.04,0.08,0.09,0.15,0.14,0.22l0.87,1.09l0.84,1.15l0.35,0.57l-0.2,0.86c-0.05,0.24-0.04,0.5,0.05,0.73l0.9,2.34
                  c0.02,0.05,0.05,0.1,0.07,0.15l0.72,1.31c0.05,0.09,0.11,0.18,0.18,0.25l0.91,0.98c0.07,0.07,0.14,0.14,0.23,0.19l0.96,0.65
                  l0.36,0.47l-0.03,0.85c0,0.12,0.01,0.24,0.04,0.35l0.16,0.62l-0.45,0.56c-0.06,0.07-0.1,0.14-0.14,0.22l-0.6,1.17
                  c-0.04,0.07-0.07,0.15-0.09,0.24l-0.32,1.12l-0.45,1.12c-0.11,0.27-0.12,0.58-0.03,0.85l0.39,1.21c0.02,0.08,0.05,0.14,0.09,0.21
                  l0.57,1.07l1.68,3.73l0.11,0.4l-1.57,1.48l-1.47,1.11c-0.28,0.21-0.46,0.53-0.49,0.87c-0.04,0.35,0.07,0.7,0.3,0.96l0.81,0.92
                  c0.08,0.09,0.17,0.16,0.28,0.23l2.13,1.29c0.1,0.06,0.21,0.1,0.32,0.13l1.17,0.29l0.72,0.25l0.06,0.12l-0.86,1.67
                  c-0.18,0.35-0.19,0.76-0.02,1.11c0.16,0.35,0.48,0.61,0.86,0.69l0.29,0.06c-0.1,0.14-0.18,0.29-0.22,0.46
                  c-0.08,0.32-0.03,0.66,0.14,0.95l0.69,1.18c0.09,0.15,0.22,0.28,0.36,0.38l1.05,0.74c0.06,0.04,0.13,0.08,0.19,0.11l1.2,0.56
                  c0.15,0.07,0.31,0.11,0.47,0.12l1.06,0.05l2.23,1.25l0.72,0.75c0.03,0.04,0.08,0.08,0.12,0.12l0.96,0.77
                  c0.13,0.1,0.27,0.17,0.43,0.22l1.1,0.32l0.72,0.71c0.07,0.08,0.16,0.15,0.25,0.2l0.99,0.57l0.84,0.68l0.52,0.96
                  c0.02,0.05,0.05,0.09,0.08,0.14l0.72,1l0.25,0.74l-0.09,0.79l-0.21,0.59l-0.03,0.01l-0.33-0.29l-0.69-1.03
                  c-0.15-0.22-0.36-0.38-0.61-0.47l-1.2-0.44c-0.05-0.02-0.09-0.03-0.14-0.05l-1.31-0.31c-0.51-0.12-1.04,0.09-1.33,0.52l-1.54,2.22
                  c-0.03,0.04-0.06,0.09-0.09,0.14l-0.7,1.3c-0.04,0.07-0.07,0.15-0.1,0.23l-0.42,1.41c-0.08,0.29-0.06,0.6,0.06,0.87l0.47,1.05
                  l-0.18,0.86c-0.02,0.09-0.03,0.19-0.03,0.29l0.02,1.09l-0.42,1.1c-0.14,0.37-0.1,0.79,0.12,1.12l0.69,1.08
                  c0.33,0.52,0.99,0.72,1.56,0.47l0.73-0.32l0.45,0.14l0.78,0.77l0.71,0.88l0.7,1.37c0.08,0.17,0.21,0.32,0.36,0.43l0.72,0.54
                  l0.38,1.21c0.08,0.27,0.25,0.5,0.49,0.66l1.07,0.73c0.06,0.05,0.13,0.09,0.21,0.12l1.31,0.56c0.15,0.07,0.32,0.1,0.49,0.1
                  c0.09,0,0.17-0.01,0.25-0.03l1.64-0.34c0.24-0.05,0.46-0.16,0.63-0.33l0.82-0.81l0.81-0.56l0.81,0.07l1.29,0.32l1.47,0.27
                  c0.21,0.04,0.42,0.02,0.62-0.04l1.3-0.43c0.19-0.06,0.35-0.17,0.49-0.3l0.66-0.65l0.63-0.11l0.63,0.52
                  c0.19,0.15,0.41,0.24,0.64,0.27l1.27,0.15c0.25,0.03,0.49-0.01,0.7-0.12c0.18,0.17,0.4,0.28,0.64,0.33
                  c0.38,0.07,0.78-0.04,1.07-0.31l0.98-0.89l0.73-0.76l0.92-0.51c0.53-0.29,0.77-0.92,0.57-1.49l-0.39-1.15l-0.25-0.85l0.06-0.21
                  l0.61-0.03c0.17-0.01,0.35-0.06,0.5-0.14l0.79-0.42l0.79,0.14c0.04,0.01,0.09,0.01,0.13,0.02l1.12,0.07l2.58,0.7
                  c0.54,0.13,1.09-0.09,1.38-0.56l0.52-0.85l0.8-0.56c0.2-0.14,0.36-0.33,0.45-0.56l0.3-0.74l0.78-0.38l1.02-0.6l0.27-0.12l0.88,1.23
                  c0.26,0.38,0.72,0.59,1.18,0.53l1.3-0.16c0.08-0.01,0.15-0.03,0.22-0.05l1.72-0.54c0.49-0.15,0.82-0.58,0.87-1.06l1.42,0.06
                  l0.17,0.49l0.03,1.24c0.01,0.32,0.14,0.63,0.38,0.86l0.94,0.93l1.22,2.21c0.18,0.31,0.49,0.54,0.85,0.61l1.1,0.21l2.06,0.79
                  l0.32,0.44l0.05,0.87c0.01,0.16,0.05,0.33,0.12,0.47l0.14,0.28l-0.2,0.17c-0.36,0.3-0.53,0.79-0.42,1.25l0.29,1.22
                  c0.05,0.2,0.15,0.39,0.29,0.54l0.25,0.29l-0.45,0.71l-1.01,1.35l-0.75,1.07c-0.17,0.24-0.25,0.52-0.23,0.81l0.09,1.22
                  c0.01,0.06,0.02,0.11,0.03,0.17l0.26,1.24c0.05,0.23,0.16,0.44,0.32,0.61l1.43,1.52l0.16,0.96c0.03,0.24,0.15,0.47,0.32,0.66
                  l1.17,1.24l0.57,0.94l0.2,1.14c0.01,0.08,0.03,0.15,0.05,0.22l0.82,2.34l0.25,1.26c0.12,0.55,0.58,0.96,1.14,1h0.04l-0.01,0.09v0.12
                  l0.02,1.24l-0.02,1.4c0,0.18,0.03,0.36,0.1,0.52l0.83,1.91c0.13,0.3,0.38,0.53,0.68,0.66l0.56,0.23l0.14,0.79
                  c0.07,0.43,0.37,0.79,0.78,0.95l1.04,0.4l0.84,0.7c0.1,0.09,0.22,0.15,0.34,0.2l0.63,0.25l0.18,0.52l0.19,1.4
                  c0.03,0.2,0.1,0.38,0.21,0.54l0.73,1.05c0.03,0.04,0.05,0.07,0.08,0.1l0.66,0.78l0.16,0.51l-0.4,0.78c-0.08,0.15-0.13,0.33-0.14,0.5
                  l-0.07,1.36l0.03,2.85c0.01,0.27,0.1,0.53,0.26,0.74l1.64,2.13c0.22,0.28,0.53,0.46,0.88,0.49l1.15,0.09l1.26,0.42
                  c0.03,0.01,0.06,0.02,0.1,0.03l1.25,0.3l0.71,0.38l0.55,0.74l-0.13,0.85c-0.04,0.31,0.03,0.64,0.22,0.9L806.15,543.43z"/>
                <title>Lučenec</title>
              </Link>

              {/* Ilava - Trenciansky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Ilava")}>
                <path
                  d="M433.18,268.96l-0.01,0.58l-0.97,1.52l-0.9,0.29c-0.32,0.1-0.58,0.32-0.73,0.62l-0.8,1.56
                  c-0.12,0.23-0.16,0.5-0.13,0.75l0.2,1.34l-0.42,0.96l-1.57,1.58l-3.81,0.62c-0.14,0.02-0.28,0.07-0.41,0.14l-1.21,0.68
                  c-0.09,0.05-0.18,0.11-0.26,0.19l-1.01,0.97l-0.86,0.89c-0.09,0.1-0.17,0.21-0.23,0.33l-0.43,0.91l-0.31,0.29l-0.28-0.05l-1.51-1.17
                  l-0.22-0.76l-0.26-1.72l-0.27-1.2c-0.12-0.52-0.55-0.91-1.07-0.97l-2.76-0.3l-1.07-0.45l-1.38-0.68l-1.23-0.5l-0.55-0.62l-0.27-0.64
                  l0.13-1c0.02-0.15,0.01-0.31-0.03-0.45l-0.29-1.2c-0.05-0.21-0.15-0.41-0.3-0.56l-0.84-0.9c-0.13-0.14-0.29-0.25-0.47-0.32
                  l-1.26-0.47c-0.18-0.07-0.37-0.09-0.56-0.07l-0.7,0.06l-0.5-0.56l-0.82-1.12c-0.25-0.34-0.65-0.53-1.06-0.51l-1.25,0.06l-1.13-0.18
                  c-0.46-0.07-0.91,0.12-1.18,0.48l-0.77,1.01c-0.11,0.14-0.19,0.31-0.23,0.48l-0.23,0.98l-0.54,0.71l-0.89,0.76l-0.65,0.49
                  l-0.43-0.07l-2.07-1.41c-0.14-0.09-0.3-0.15-0.47-0.18l-1.33-0.22l-1.24-0.13c-0.37-0.04-0.73,0.08-1,0.34l-0.92,0.89
                  c-0.23,0.22-0.37,0.53-0.38,0.85l-0.03,0.72l-0.04-0.02c-0.27-0.12-0.59-0.14-0.88-0.06l-1.04,0.31l-1.1-0.03
                  c-0.07,0-0.13,0-0.19,0.01l-0.92,0.11l-0.93-0.35c-0.12-0.05-0.25-0.07-0.39-0.08l-1.84-0.08h-0.15l-1.32,0.11l-0.96,0.13
                  l-1.04-0.31c-0.19-0.06-0.39-0.07-0.59-0.03l-1.26,0.25c-0.29,0.06-0.55,0.22-0.73,0.44l-0.67,0.83l-0.81,0.43l-3.65,1.24
                  c-0.3,0.11-0.55,0.33-0.69,0.61l-0.38,0.73l-0.26,0.1l-1.73-0.8l-1.07-0.62c-0.15-0.09-0.32-0.15-0.5-0.16l-1.09-0.12l-2.3-0.8
                  c-0.33-0.11-0.68-0.09-0.99,0.07l-0.79,0.41l-0.81-0.07l-0.61-0.64c-0.36-0.37-0.91-0.48-1.39-0.28l-1.42,0.6
                  c-0.16,0.07-0.3,0.17-0.42,0.3l-0.77,0.82l-0.99,0.41l-1.25,0.61l-0.64,0.36l-0.58-0.16l-1.01-0.61c-0.05-0.03-0.09-0.05-0.14-0.07
                  l-0.89-0.4l-0.62-0.73l-0.43-1.11c-0.08-0.2-0.21-0.37-0.37-0.51l-0.94-0.78l-0.81-0.77l-0.66-0.92c-0.38-0.54-1.1-0.69-1.66-0.35
                  l-0.77,0.46l-0.86,0.02l-0.95-0.14l-0.8-0.42l-0.72-0.63l-0.64-0.94l-0.3-0.6l0.27-0.59l0.57-0.93l0.77-0.84l0.92-0.87l0.95-0.64
                  c0.35-0.24,0.56-0.64,0.55-1.07s-0.24-0.82-0.6-1.04l-1.02-0.62l-0.85-0.63l-0.38-0.54l0.07-0.47l2.07-2.46
                  c0.12-0.15,0.21-0.32,0.25-0.51l0.36-1.46c0.09-0.34,0.02-0.7-0.17-0.99c-0.19-0.29-0.5-0.49-0.85-0.55l-1.19-0.18l-1.66-1.31
                  l-1.7-1.57l-0.33-0.84l-0.95-2.95l-0.15-1.13l0.01-1.29c0-0.19-0.04-0.38-0.12-0.54l-0.51-1.08l-0.59-1.53
                  c-0.07-0.18-0.18-0.34-0.33-0.47l-0.81-0.74l-0.46-0.76l-0.3-1.05c-0.07-0.25-0.22-0.47-0.42-0.63l-0.7-0.55l-0.17-0.85l-0.34-1.25
                  c-0.09-0.3-0.28-0.55-0.52-0.72c0.13-0.06,0.25-0.14,0.36-0.25c0.25-0.25,0.38-0.6,0.36-0.95l-0.07-1.26
                  c-0.01-0.21-0.08-0.41-0.18-0.58l-0.65-1.05l-0.44-0.94l-0.06-0.56l1.39-1.63c0.43-0.51,0.39-1.26-0.09-1.72l-0.3-0.28l0.69-1.57
                  c0.1-0.23,0.14-0.48,0.09-0.73l-0.21-1.21c-0.02-0.15-0.07-0.29-0.15-0.42l-0.75-1.29l-0.55-1.04l-0.03-0.82l0.42-0.94l-0.03-0.01
                  v-0.01l3.38-7.02l0.79-1.07c0.03-0.04,0.05-0.08,0.08-0.12l0.61-1.07c0.13-0.23,0.18-0.51,0.15-0.77l-0.2-1.56l-0.76-3.88
                  l-0.05-1.22c0-0.05-0.01-0.11-0.02-0.17l-0.21-1.15l0.12-0.85l0.23-0.59l0.8-0.43c0.23-0.13,0.41-0.32,0.53-0.56l0.53-1.1
                  c0.16-0.33,0.16-0.72,0.01-1.06l-0.52-1.13c-0.05-0.09-0.1-0.18-0.16-0.25l-0.78-0.99c-0.07-0.1-0.16-0.18-0.25-0.25l-0.83-0.58
                  l-0.43-0.66l-0.13-0.58l0.32-0.76l0.59-0.81l0.87-0.78c0.12-0.11,0.22-0.25,0.28-0.39l0.06,0.12l0.49-0.24l2.07,0.21l1.15,0.32
                  c0.12,0.04,0.24,0.05,0.35,0.05h0.52l0.34,0.56c0.17,0.29,0.44,0.49,0.76,0.57l1.18,0.31l0.27,0.27l-0.12,0.61
                  c-0.02,0.1-0.02,0.2-0.02,0.3l0.09,1.86c0,0.05,0.01,0.11,0.02,0.16l0.22,1.24c0.01,0.03,0.01,0.07,0.02,0.1l0.47,1.76
                  c0.04,0.15,0.1,0.28,0.19,0.4l0.87,1.23c0.27,0.39,0.73,0.59,1.2,0.52l1.35-0.2l0.48-0.09l0.49,0.63c0.02,0.03,0.05,0.06,0.07,0.09
                  l1.19,1.28l0.53,0.72l0.05,0.92c0.02,0.28,0.13,0.55,0.33,0.76l0.94,1.03c0.12,0.13,0.26,0.24,0.42,0.31l0.78,0.33l0.27,0.67
                  c0.07,0.16,0.16,0.3,0.29,0.42l0.88,0.87l0.54,0.79l0.05,0.88c0.01,0.16,0.05,0.31,0.11,0.45l0.56,1.22
                  c0.06,0.12,0.13,0.23,0.23,0.33l0.94,1.01c0.03,0.03,0.06,0.07,0.09,0.1l0.77,0.66l0.28,0.87c0.06,0.18,0.15,0.34,0.28,0.47
                  l0.74,0.8l0.56,1.03l0.4,1.09l0.06,1.14c0,0.03,0.01,0.07,0.01,0.11l0.19,1.31c0.01,0.06,0.03,0.12,0.04,0.18l0.41,1.35
                  c0.07,0.22,0.2,0.42,0.37,0.57l1.01,0.9c0.09,0.08,0.19,0.15,0.3,0.2l1.34,0.63c0.22,0.11,0.47,0.14,0.72,0.11l1.24-0.2l0.94-0.09
                  l0.17,0.18l0.18,1c0.03,0.13,0.07,0.26,0.14,0.38l0.53,0.95l0.29,1.27c0.04,0.15,0.1,0.29,0.18,0.42l0.75,1.11
                  c0.15,0.21,0.35,0.37,0.59,0.47l1,0.38l0.77,0.58l2.58,2.83c0.35,0.39,0.91,0.52,1.4,0.33s0.8-0.67,0.79-1.19l-0.04-1.5
                  c0-0.11-0.02-0.22-0.05-0.33l-0.36-1.21l-0.13-1.03l0.24-0.5l0.76-0.49l2.61-1.15l0.58,0.3c0.09,0.04,0.19,0.08,0.29,0.1l0.88,0.19
                  l0.76,0.76c0.08,0.09,0.18,0.16,0.29,0.22l0.57,0.31l-0.02,0.44c0,0.08,0,0.17,0.02,0.25l0.21,1.33c0.03,0.25,0.14,0.47,0.31,0.66
                  l0.86,0.93l0.92,1.14l0.5,1.06c0.06,0.12,0.14,0.24,0.23,0.34l0.86,0.89c0.04,0.04,0.08,0.07,0.12,0.1l1.04,0.84l0.69,0.9
                  c0.02,0.03,0.04,0.06,0.07,0.08l3.15,3.63c0.3,0.36,0.78,0.51,1.24,0.41l1.37-0.33l0.32-0.03l-0.04,0.06
                  c-0.19,0.35-0.21,0.76-0.05,1.12c0.15,0.36,0.47,0.63,0.86,0.72l0.8,0.18c-0.07,0.27-0.05,0.56,0.07,0.82
                  c0.19,0.41,0.57,0.68,1.01,0.72l1.28,0.13c0.1,0.01,0.19,0.01,0.29,0l0.14-0.02l0.02,0.1c0.03,0.15,0.08,0.28,0.15,0.41l0.87,1.49
                  l0.62,1.36l1.12,2.09l0.09,1.64l-0.6,1.39c-0.14,0.31-0.14,0.67-0.01,0.99c0.14,0.32,0.4,0.57,0.72,0.68l1.18,0.43
                  c0.08,0.03,0.15,0.05,0.23,0.06l1.13,0.19l1.04,0.63c0.07,0.04,0.15,0.08,0.22,0.1l0.95,0.35l1.79,1.77
                  c0.24,0.24,0.56,0.36,0.88,0.36c0.12,0,0.24-0.02,0.36-0.05l0.95-0.29l2.31,0.21c0.11,0,0.22,0,0.33-0.02l1.31-0.24
                  c0.12-0.02,0.24-0.06,0.35-0.12l1.55-0.81c0.14-0.07,0.26-0.17,0.36-0.29l0.81-0.93l0.9-0.89c0.12-0.11,0.21-0.24,0.28-0.39
                  l0.67-1.54l0.55,0.54l0.32-0.32l0.21,0.05l0.65,0.65l0.75,0.93c0.09,0.12,0.2,0.22,0.33,0.29l1.05,0.64l0.53,0.52l0.17,0.82
                  l-0.18,1.17l-0.15,1.59c-0.03,0.25,0.02,0.5,0.14,0.72l0.57,1.03l0.53,1.33l-0.25,1c-0.04,0.15-0.05,0.29-0.03,0.44l0.13,1.24
                  c0.02,0.21,0.1,0.42,0.22,0.59l0.93,1.31l0.93,1.14l0.81,1.11c0.24,0.32,0.63,0.52,1.03,0.51l0.95-0.02l0.81,0.44
                  c0.33,0.18,0.73,0.2,1.08,0.05l0.97-0.42l1.08-0.07l2.21,0.31L433.18,268.96z"/>
                <title>Ilava</title>
              </Link>

              {/* Puchov - Trenciansky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Púchov")}>
                <path
                  d="M414.12,250.63l-0.34,0.78l-0.76,0.75c-0.03,0.02-0.05,0.05-0.07,0.07l-0.69,0.79l-1.17,0.61l-0.96,0.18
                  l-2.36-0.21c-0.16-0.02-0.33,0-0.48,0.04l-0.47,0.15l-1.45-1.43c-0.13-0.13-0.28-0.23-0.45-0.29l-1.1-0.4l-1.14-0.7
                  c-0.14-0.08-0.29-0.14-0.45-0.16l-1.05-0.17l0.17-0.41c0.08-0.18,0.12-0.38,0.11-0.57l-0.13-2.21c-0.01-0.18-0.06-0.35-0.14-0.51
                  l-1.22-2.29l-0.61-1.34c-0.02-0.04-0.04-0.07-0.06-0.11l-0.79-1.35l-0.19-1.05c-0.12-0.66-0.73-1.1-1.39-1.02l-0.46,0.06
                  c-0.01-0.09-0.03-0.18-0.06-0.27c-0.14-0.38-0.45-0.67-0.84-0.78l-0.96-0.27c0.09-0.42-0.03-0.86-0.35-1.17l-0.94-0.91
                  c-0.27-0.26-0.64-0.38-1.01-0.34l-1.27,0.14c-0.05,0-0.1,0.01-0.15,0.02l-0.7,0.17l-1.87-2.18l-0.81-0.89l-0.75-0.98
                  c-0.06-0.08-0.13-0.15-0.21-0.22l-1.1-0.88l-0.66-0.68l-0.47-1.01c-0.04-0.09-0.09-0.18-0.16-0.26l-1.07-1.32l-0.65-0.71l-0.14-0.83
                  l0.06-1.1c0.02-0.48-0.23-0.93-0.66-1.16l-1.11-0.59l-0.89-0.89c-0.17-0.17-0.38-0.29-0.61-0.34l-1.08-0.24l-0.97-0.49
                  c-0.33-0.16-0.72-0.17-1.07-0.02l-3.22,1.41c-0.06,0.03-0.12,0.06-0.18,0.1l-1.15,0.74c-0.19,0.12-0.35,0.3-0.45,0.51l-0.55,1.16
                  c-0.1,0.22-0.14,0.46-0.11,0.7l0.04,0.29c-0.04-0.03-0.08-0.07-0.12-0.1l-1-0.73c-0.09-0.07-0.18-0.12-0.29-0.17l-0.79-0.3l-0.4-0.6
                  l-0.28-1.21c-0.03-0.11-0.07-0.23-0.13-0.33l-0.52-0.93l-0.21-1.16c-0.04-0.23-0.15-0.45-0.32-0.62l-0.84-0.9
                  c-0.26-0.29-0.65-0.43-1.04-0.39l-1.62,0.16l-0.92,0.15l-0.83-0.39l-0.62-0.55l-0.28-0.92l-0.17-1.16l-0.06-1.28
                  c-0.01-0.12-0.03-0.25-0.08-0.36l-0.5-1.35c-0.02-0.06-0.04-0.11-0.07-0.17l-0.68-1.25c-0.05-0.09-0.11-0.18-0.19-0.25l-0.66-0.71
                  l-0.3-0.94c-0.07-0.22-0.2-0.42-0.37-0.57l-0.98-0.84l-0.76-0.81l-0.38-0.83l-0.05-0.99c-0.02-0.23-0.09-0.45-0.22-0.64l-0.81-1.18
                  c-0.05-0.06-0.1-0.13-0.16-0.18l-0.78-0.77l-0.36-0.9c-0.12-0.3-0.36-0.55-0.66-0.68l-1.01-0.43l-0.46-0.51l-0.05-0.85
                  c-0.02-0.24-0.1-0.47-0.24-0.66l-0.79-1.07c-0.03-0.04-0.06-0.08-0.09-0.11l-1.19-1.29l-0.92-1.19c-0.28-0.37-0.75-0.55-1.22-0.47
                  l-1.73,0.3l-0.3-0.42l-0.4-1.49l-0.2-1.11l-0.07-1.63l0.22-1.11c0.08-0.4-0.05-0.82-0.34-1.12l-0.98-0.99
                  c-0.16-0.16-0.36-0.27-0.58-0.33l-1.02-0.26l-0.45-0.74c-0.23-0.38-0.63-0.61-1.07-0.61H339l-1.05,0.01l-1.08-0.3
                  c-0.07-0.02-0.13-0.04-0.2-0.04l-2.49-0.25l-0.01-0.26l0.06-0.7l0.4-0.36l1-0.42c0.13-0.05,0.25-0.12,0.35-0.22l0.92-0.82
                  c0.08-0.06,0.14-0.14,0.2-0.22l0.56-0.82l0.81-0.4c0.31-0.15,0.54-0.42,0.64-0.75c0.11-0.33,0.07-0.69-0.1-0.99l-0.69-1.2
                  l-0.58-0.89l-0.28-1.04l0.06-2.3l0.16-1.14c0-0.05,0.01-0.1,0.01-0.15l0.02-1.28l-0.02-1.14l0.06-0.96l2.27-2.28
                  c0.1-0.1,0.18-0.21,0.24-0.33l0.63-1.22c0.03-0.05,0.05-0.1,0.07-0.15l0.39-1.09l0.57-0.97c0.07-0.11,0.12-0.24,0.14-0.38l0.26-1.25
                  c0.03-0.1,0.03-0.21,0.03-0.33l-0.12-1.8l0.34-0.16c0.41-0.19,0.68-0.58,0.72-1.03l0.09-1.19l0.77-3.63
                  c0.12-0.54-0.13-1.11-0.62-1.38l-1.08-0.59c-0.21-0.11-0.44-0.16-0.67-0.15l0.18-0.49c0.04-0.11,0.06-0.23,0.07-0.35l0.07-1.26
                  v-0.11l-0.04-1.29c0-0.08-0.01-0.16-0.03-0.25l-0.25-1.07l0.08-0.75l0.68-0.7l0.82-0.59l1.03-0.41c0.28-0.12,0.52-0.33,0.65-0.61
                  l0.57-1.16c0.07-0.14,0.11-0.29,0.12-0.44l0.05-0.56l0.47-0.25l1.12-0.42c0.13-0.05,0.25-0.12,0.35-0.2l1.04-0.85
                  c0.07-0.06,0.13-0.12,0.18-0.18l0.63-0.78l0.86-0.39c0.26-0.12,0.47-0.33,0.6-0.58l0.55-1.12c0.1-0.2,0.15-0.44,0.12-0.67
                  l-0.12-1.25l0.06-1.06l0.28-1.4c0.03-0.19,0.03-0.38-0.02-0.56l-0.19-0.71l0.28-0.39l0.67-0.56l0.7,0.07
                  c0.33,0.04,0.65-0.06,0.9-0.27l0.93-0.74l0.36-0.26l0.38,0.25c0.41,0.27,0.94,0.27,1.36,0l0.81-0.52l0.69,0.19
                  c0.43,0.12,0.9,0,1.22-0.32l0.43-0.43l0.54,0.16c0.12,0.03,0.25,0.06,0.36,0.05l1.23-0.01c0.08,0,0.16-0.01,0.24-0.03l1.05-0.21
                  l0.96,0.2c0.11,0.03,0.23,0.04,0.35,0.03l0.67-0.05l-0.08,1.45l-0.14,1.39c-0.01,0.1-0.01,0.19,0,0.28l0.14,1.19l0.09,1.23
                  l-0.05,1.53c-0.01,0.19,0.03,0.37,0.1,0.54l0.53,1.22c0.04,0.08,0.08,0.16,0.14,0.23l0.72,1c0.08,0.12,0.19,0.23,0.32,0.31
                  l1.25,0.83l0.46,0.39l-0.09,0.52c-0.02,0.09-0.02,0.18-0.02,0.28l0.07,1.16l-0.15,1.25c-0.02,0.17-0.01,0.35,0.04,0.51l0.47,1.56
                  c0.03,0.08,0.06,0.16,0.1,0.24l0.61,1.11l0.11,1.09c0.01,0.1,0.03,0.19,0.06,0.28l0.38,1.12l0.32,1.2c0.01,0.05,0.03,0.1,0.05,0.14
                  l0.62,1.56c0.07,0.18,0.19,0.35,0.33,0.48l1.09,0.96c0.23,0.2,0.52,0.31,0.82,0.31h0.52l0.24,0.5l0.43,1.21
                  c0.02,0.06,0.04,0.11,0.07,0.16l0.47,0.91l0.08,2.29c0.01,0.51,0.33,0.96,0.81,1.14l1.19,0.45c0.38,0.14,0.8,0.09,1.13-0.13
                  l0.96-0.63l0.64-0.28l0.81,0.24c0.15,0.04,0.31,0.06,0.47,0.04l1.15-0.1l1.09,0.12l1.14,0.24c0.09,0.02,0.18,0.03,0.27,0.03
                  l1.05-0.01l0.02,0.01l-0.22,0.43c-0.05,0.1-0.09,0.21-0.11,0.32l-0.25,1.23c-0.01,0.04-0.02,0.07-0.02,0.11l-0.14,1.22l-0.37,1.23
                  c-0.04,0.15-0.06,0.31-0.05,0.46l0.12,1.39l-0.2,1.21c-0.04,0.23-0.02,0.46,0.07,0.67l0.46,1.16l-0.03,1.07c0,0.1,0.01,0.2,0.03,0.3
                  l0.36,1.69c0.12,0.56,0.6,0.97,1.17,0.99l0.99,0.04l0.77,0.51l0.74,0.63l0.42,0.89c0.11,0.23,0.28,0.41,0.5,0.54l1.08,0.63
                  c0.13,0.08,0.28,0.13,0.42,0.15l1.25,0.21l0.53,0.12l0.01,0.06l-0.4,0.99c-0.08,0.21-0.11,0.43-0.08,0.65l0.19,1.33
                  c0.04,0.29,0.19,0.56,0.41,0.76l0.97,0.86c0.05,0.04,0.11,0.09,0.18,0.12l0.84,0.52l0.34,0.68l0.23,1.03l0.12,1.48l-0.22,1.42
                  c-0.05,0.29,0.01,0.58,0.15,0.83l0.69,1.17c0.15,0.25,0.37,0.44,0.63,0.54l0.78,0.3l0.45,1.84c0.01,0.06,0.03,0.11,0.05,0.17
                  l0.89,2.41l0.12,0.75l-0.41,0.72c-0.22,0.37-0.23,0.84-0.02,1.22c0.21,0.38,0.6,0.62,1.03,0.65l1.24,0.07
                  c0.13,0.01,0.27-0.01,0.4-0.04l1.11-0.3l0.45-0.02l0.15,0.19l0.02,0.81c0,0.1,0.02,0.2,0.04,0.3l0.13,0.47l-0.34,0.28l-1.1,0.64
                  l-1.02,0.68c-0.16,0.1-0.29,0.24-0.38,0.4l-0.64,1.07c-0.32,0.54-0.2,1.23,0.29,1.62l1.17,0.93c0.05,0.03,0.09,0.07,0.14,0.1
                  l1.15,0.67l0.22,0.15l-0.81,0.95c-0.09,0.11-0.16,0.22-0.22,0.35l-0.47,1.14c-0.04,0.12-0.07,0.24-0.08,0.37l-0.12,1.22
                  c-0.01,0.11,0,0.22,0.02,0.34l0.21,1.13v1.12c0,0.14,0.02,0.29,0.07,0.42l0.39,1.08l0.06,1.08c0.01,0.17,0.05,0.33,0.13,0.48
                  l0.32,0.67l-0.28,0.56c-0.26,0.51-0.14,1.14,0.29,1.51l0.44,0.39l-0.09,0.44l-0.32,0.48l-0.93,0.31c-0.54,0.19-0.89,0.71-0.85,1.28
                  l0.09,1.21l-0.13,0.98l-0.63,1.74c-0.1,0.27-0.11,0.57-0.01,0.84l0.55,1.58c0.02,0.07,0.06,0.14,0.09,0.2l1.31,2.42l0.38,1.35
                  l0.14,1.29c0.02,0.2,0.09,0.4,0.2,0.57l0.84,1.25c0.04,0.05,0.08,0.11,0.13,0.16l0.81,0.86l0.3,0.54l-0.23,0.81
                  c-0.04,0.12-0.05,0.25-0.05,0.38l0.12,2.61l-0.41,0.81c-0.19,0.36-0.18,0.79,0,1.15l0.61,1.16c0.11,0.2,0.26,0.37,0.46,0.49
                  l1.76,1.07l-0.06,0.59c-0.04,0.41,0.11,0.81,0.42,1.07c0.31,0.27,0.72,0.37,1.12,0.27l0.84-0.2l-0.01,0.02
                  c0,0.05-0.01,0.09-0.01,0.14l-0.05,1.34c-0.01,0.42,0.17,0.81,0.51,1.05l1,0.75c0.07,0.05,0.15,0.1,0.24,0.14l0.95,0.41l0.47,0.76
                 c0.05,0.07,0.1,0.14,0.16,0.2L414.12,250.63z"/>
                <title>Púchov</title>
              </Link>

              {/* povazska bystrica - Trenciansky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Považská Bystrica")}>
                <path style={{ fill: crimesInRegionTrenciansky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionTrenciansky[3].crimePercent)] ? crimesInRegionTrenciansky[3].color : "#D3D3D3" }}
                  d="M457.42,229.14l-0.16,1.27c-0.02,0.13-0.01,0.26,0.01,0.39l0.18,0.94l-0.37,0.76c-0.04,0.09-0.08,0.17-0.1,0.26
                  l-0.23,1.01l-0.43,0.47l-0.92,0.28c-0.29,0.09-0.53,0.28-0.69,0.53l-0.73,1.14c-0.16,0.24-0.23,0.54-0.19,0.83l0.1,0.82l-0.36,0.58
                  l-1.63,1.73c-0.1,0.1-0.18,0.22-0.23,0.35l-0.49,1.05l-1.52,2.05l-1.88,1.08l-0.97,0.07c-0.13,0.01-0.26,0.04-0.38,0.09l-1.14,0.47
                  l-1.2,0.14c-0.12,0.01-0.22,0.04-0.33,0.08l-1,0.41l-0.92-0.05l-1.25-0.16c-0.42-0.06-0.83,0.1-1.1,0.42l-0.78,0.89l-0.83,0.61
                  l-2.42,1.25c-0.06,0.03-0.12,0.07-0.18,0.11l-1.03,0.75c-0.28,0.2-0.46,0.52-0.5,0.86l-0.32,2.62l-0.45,1.07l-0.62,0.95
                  c-0.02,0.03-0.04,0.06-0.06,0.1l-0.52,0.99l-0.52,0.59l-0.89-0.09c-0.18-0.02-0.37,0-0.54,0.06l-2.47,0.88
                  c-0.12,0.04-0.23,0.1-0.33,0.18l-0.98,0.75c-0.05,0.03-0.1,0.08-0.14,0.12l-0.6,0.62l-1.06,0.2l-0.37-0.93
                  c-0.02-0.05-0.04-0.09-0.06-0.14l-0.42-0.76l0.1-1.13l0.21-1.32c0.03-0.15,0.03-0.3-0.01-0.45l-0.29-1.42
                  c-0.05-0.25-0.17-0.47-0.35-0.64l-0.91-0.89c-0.07-0.07-0.14-0.13-0.23-0.18l-0.99-0.6l-0.65-0.8c-0.02-0.04-0.05-0.07-0.08-0.1
                  l-0.94-0.95c-0.16-0.16-0.37-0.28-0.6-0.34l-1.25-0.3c-0.42-0.1-0.88,0.03-1.18,0.35l-0.07,0.07l-0.43-0.44l-0.61-0.97
                  c-0.13-0.21-0.33-0.37-0.56-0.48l-1.17-0.51l-0.36-0.26l0.03-0.61l0.18-1.15c0.09-0.63-0.3-1.23-0.91-1.4l-1.2-0.33
                  c-0.1-0.02-0.21-0.04-0.32-0.04c-0.06-0.35-0.26-0.66-0.58-0.86l-2.13-1.31l-0.15-0.28l0.27-0.54c0.11-0.2,0.15-0.43,0.14-0.65
                  l-0.1-1.54l-0.03-1.14l0.31-1.12c0.09-0.32,0.05-0.66-0.11-0.95l-0.62-1.12c-0.05-0.09-0.11-0.17-0.18-0.25l-0.85-0.9l-0.61-0.91
                  l-0.12-1.09c0-0.07-0.02-0.14-0.04-0.21l-0.44-1.57c-0.02-0.08-0.05-0.16-0.09-0.24l-0.8-1.52l-0.56-0.99l-0.36-1.05l0.52-1.45
                  c0.04-0.09,0.06-0.17,0.07-0.26l0.17-1.24c0.01-0.09,0.01-0.18,0.01-0.26l-0.03-0.37l0.43-0.14c0.27-0.09,0.5-0.28,0.65-0.52
                  l0.67-1.05c0.08-0.12,0.14-0.26,0.17-0.4l0.3-1.38c0.1-0.45-0.06-0.91-0.4-1.21l-0.26-0.23l0.12-0.25c0.18-0.35,0.19-0.77,0.01-1.12
                  l-0.49-1l-0.05-1.01c-0.01-0.12-0.03-0.24-0.07-0.35l-0.38-1.05v-1.01c0-0.08-0.01-0.15-0.02-0.23l-0.2-1.08l0.08-0.86l0.32-0.77
                  l0.79-0.9c0.11-0.12,0.2-0.26,0.25-0.42l0.43-1.23c0.19-0.54-0.01-1.14-0.49-1.45l-1.08-0.72l-1.13-0.66l-0.2-0.16l0.6-0.41
                  l1.13-0.65c0.07-0.03,0.13-0.07,0.18-0.12l1.04-0.87c0.38-0.32,0.53-0.82,0.41-1.29l-0.3-1.09l-0.03-1.09
                  c-0.01-0.28-0.1-0.54-0.28-0.76l-0.8-0.99c-0.24-0.3-0.63-0.49-1-0.46l-1.22,0.03c-0.1,0-0.2,0.02-0.3,0.04l-0.32,0.09l-0.18-1.2
                  c-0.02-0.08-0.04-0.16-0.07-0.24l-0.91-2.47l-0.29-1.31l-0.36-1.21c-0.11-0.37-0.39-0.67-0.75-0.81l-0.94-0.36l-0.24-0.41l0.17-1.12
                  c0.02-0.1,0.02-0.2,0.02-0.3l-0.14-1.72c-0.01-0.06-0.02-0.11-0.03-0.17l-0.28-1.27c-0.02-0.1-0.06-0.21-0.11-0.3l-0.58-1.13
                  c-0.1-0.2-0.26-0.38-0.46-0.49l-1.04-0.65l-0.55-0.48l-0.07-0.54l0.4-1c0.08-0.21,0.11-0.44,0.08-0.66l-0.19-1.24
                  c-0.08-0.51-0.46-0.92-0.96-1.03l-1.43-0.32l-1.1-0.19l-0.55-0.32l-0.37-0.78c-0.08-0.16-0.18-0.3-0.32-0.41l-0.99-0.85
                  c-0.04-0.03-0.08-0.06-0.12-0.09l-1.13-0.75c-0.19-0.13-0.41-0.2-0.64-0.21l-0.37-0.01l-0.13-0.59l0.04-1.18
                  c0-0.17-0.03-0.34-0.09-0.5l-0.43-1.08l0.17-1.02c0.02-0.11,0.02-0.21,0.02-0.32l-0.11-1.3l0.33-1.11c0.02-0.07,0.03-0.15,0.04-0.22
                  l0.15-1.28l0.2-1l0.75-1.46c0.16-0.3,0.18-0.66,0.07-0.99c-0.12-0.32-0.36-0.59-0.68-0.72l-1.48-0.65
                  c-0.16-0.08-0.34-0.11-0.51-0.11l-1.18,0.01l-1.07-0.23c-0.04-0.01-0.08-0.02-0.12-0.02l-1.28-0.14c-0.09-0.01-0.17-0.01-0.26,0
                  l-1.04,0.09l-1.01-0.29c-0.28-0.08-0.57-0.06-0.84,0.05l-1.16,0.49c-0.07,0.03-0.14,0.07-0.2,0.11l-0.32,0.21l-0.08-1.58
                  c-0.01-0.17-0.06-0.34-0.14-0.5l-0.55-1.07l-0.42-1.2c-0.02-0.05-0.03-0.09-0.06-0.13l-0.6-1.26c-0.21-0.43-0.64-0.71-1.12-0.71
                  l-0.83-0.01l-0.52-0.45l-0.48-1.21l-0.33-1.21l-0.36-1.07l-0.12-1.19c-0.01-0.17-0.06-0.33-0.14-0.48l-0.67-1.22l-0.36-1.19
                  l0.13-1.1c0.01-0.08,0.01-0.15,0.01-0.22l-0.06-1.13l0.18-1.09c0.08-0.44-0.09-0.88-0.43-1.17l-1.06-0.89
                  c-0.03-0.03-0.07-0.06-0.11-0.08l-1.12-0.75l-0.51-0.7l-0.36-0.84l0.04-1.31v-0.13l-0.1-1.36l-0.13-1.11l0.13-1.31l0.1-1.69
                  c0-0.06,0-0.12-0.01-0.18l-0.04-0.51l0.75-0.16c0.12-0.03,0.24-0.07,0.35-0.13l0.86-0.49l1.03-0.07c0.2-0.02,0.4-0.08,0.57-0.19
                  l1.09-0.68c0.03-0.02,0.07-0.04,0.1-0.07l0.79-0.6l2.14-0.49l1.32-0.03c0.06,0,0.12-0.01,0.18-0.02l1.16-0.2l1.54-0.19
                  c0.11-0.01,0.22-0.04,0.32-0.08l1.24-0.5c0.6-0.25,0.92-0.92,0.72-1.54l-0.11-0.35l0.14-0.11l2.26-0.68
                  c0.05-0.02,0.11-0.04,0.16-0.06l1.12-0.51c0.05-0.02,0.1-0.05,0.14-0.08c0,0,0,0,0.01,0c0.1,0.03,0.2,0.05,0.3,0.05l1.24,0.04
                  c0.13,0,0.25-0.01,0.37-0.05l1.14-0.31l1.3-0.23c0.06-0.01,0.12-0.03,0.18-0.05l1.34-0.45l1.24-0.49l1.07-0.2l1-0.08l0.92,0.46
                  c0.34,0.17,0.75,0.17,1.1,0l0.98-0.47h0.42l0.1,0.19l-0.12,1.12l-0.07,1.24c-0.01,0.15,0.01,0.3,0.06,0.44l0.83,2.61
                  c0.04,0.13,0.11,0.25,0.19,0.36l1.61,2.16c0.16,0.23,0.4,0.39,0.67,0.47l1.02,0.27l0.58,0.45l0.36,0.85l0.42,1.21l0.33,1.2
                  c0.05,0.17,0.13,0.32,0.24,0.45l1.53,1.9l0.5,1.17c0.03,0.07,0.06,0.13,0.1,0.19l0.55,0.84l0.24,1.11c0.06,0.28,0.22,0.54,0.45,0.72
                  l2.02,1.54l0.79,0.75l0.45,0.87l0.51,1.15c0.04,0.09,0.09,0.17,0.15,0.25l0.65,0.85l0.18,0.67l-0.31,0.92
                  c-0.06,0.2-0.08,0.42-0.04,0.62l0.31,1.62c0.05,0.23,0.15,0.45,0.31,0.62l0.76,0.81l1.09,2.04l0.89,2.25l-0.17,0.85
                  c-0.03,0.13-0.04,0.27-0.02,0.4l0.15,1.23c0.02,0.19,0.09,0.37,0.19,0.53l0.91,1.39c0.09,0.13,0.19,0.24,0.31,0.32l0.68,0.51
                  l0.06,0.16l-0.5,0.57c-0.26,0.31-0.36,0.72-0.27,1.11l0.29,1.2c0.11,0.43,0.43,0.77,0.84,0.9l1.13,0.36l0.9,0.46l1.28,1.28
                  l-0.12,0.72c-0.01,0.04-0.01,0.08-0.02,0.12l-0.1,1.44c-0.01,0.13,0,0.25,0.03,0.38l0.34,1.44c0.05,0.18,0.13,0.35,0.25,0.49
                  l1.74,2.11c0.24,0.28,0.62,0.47,0.96,0.44l1.03-0.01l0.66,0.3l0.57,0.65l0.75,1.01c0.09,0.14,0.21,0.24,0.35,0.33l2.33,1.35
                  c0.07,0.04,0.14,0.07,0.21,0.1l1.14,0.37l0.97,0.46l0.87,0.58l0.69,0.68l0.52,0.94c0.03,0.06,0.08,0.13,0.13,0.19l1.05,1.27
                  c0.09,0.11,0.2,0.2,0.32,0.27l1.07,0.64c0.09,0.05,0.18,0.1,0.28,0.13l1.28,0.39c0.2,0.06,0.42,0.07,0.63,0.02l0.75-0.17l0.41,0.27
                  l0.53,0.85c0.14,0.21,0.33,0.38,0.57,0.48l1.21,0.52c0.11,0.05,0.22,0.08,0.34,0.09l0.93,0.11l0.46,0.32l0.24,0.71l-0.25,1.17
                  c-0.08,0.36,0,0.74,0.23,1.03l0.78,1.02c0.15,0.19,0.35,0.34,0.58,0.42l2.65,0.91c0.06,0.02,0.11,0.03,0.16,0.04l1.2,0.24l0.28,0.08
                  l0.01,0.98c0,0.18,0.04,0.36,0.12,0.52l0.42,0.89l-0.23,0.9c-0.02,0.09-0.04,0.18-0.04,0.27l-0.03,1.09l-0.39,1.26
                  c-0.17,0.55,0.05,1.14,0.54,1.44l1.3,0.79c0.11,0.07,0.24,0.12,0.37,0.15l0.9,0.21l0.72,0.72l0.32,0.55l-0.13,0.65l-0.58,1
                  l-0.67,0.82l-0.71,0.67l-0.93,0.42c-0.24,0.11-0.45,0.29-0.58,0.52l-0.44,0.79l-1.04,0.6c-0.11,0.06-0.22,0.14-0.3,0.24l-1.04,1.14
                  l-1.19,0.83c-0.19,0.13-0.34,0.32-0.43,0.54l-0.49,1.17c-0.04,0.09-0.07,0.19-0.09,0.29l-0.19,1.24c-0.05,0.38,0.07,0.77,0.33,1.05
                  c0.27,0.28,0.64,0.42,1.03,0.38l1.43-0.14l1.2,0.07l1.18,0.12l0.97,0.28l1.84,1.34l0.55,0.46l0.12,1.09
                  c0.03,0.3,0.17,0.58,0.38,0.78l0.99,0.93c0.06,0.05,0.11,0.09,0.17,0.13l0.83,0.55l0.2,0.85c0.02,0.07,0.04,0.14,0.07,0.21
                  l0.33,0.76l-0.46,2.28c-0.03,0.19-0.03,0.38,0.03,0.57l0.38,1.36l0.11,0.69l-0.38,0.45l-0.89,0.67c-0.42,0.31-0.6,0.86-0.45,1.36
                  l0.38,1.27c0.06,0.2,0.17,0.38,0.32,0.53l0.63,0.62l0.09,0.68L457.42,229.14z"/>
                <title>Považská Bystrica</title>
              </Link>

              {/*Bytca - Zilinsky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Bytča")}>
                <path
                  d="M467.31,144.79l0.23,1.04l-0.21,0.75l-0.62,0.5l-1.28,0.59l-1.02,0.34l-1.2-0.03c-0.25-0.01-0.5,0.07-0.71,0.2
                  l-1,0.65l-1.23,0.61c-0.2,0.1-0.38,0.26-0.5,0.46l-0.84,1.34c-0.03,0.04-0.06,0.09-0.08,0.14l-0.48,1.07l-0.9,0.59l-1.54,0.81
                  c-0.31,0.17-0.54,0.45-0.63,0.79c-0.09,0.35-0.03,0.71,0.16,1l0.67,1.03c0.08,0.12,0.18,0.22,0.29,0.31l0.93,0.71l0.92,0.82
                  c0.21,0.19,0.47,0.3,0.75,0.32l0.22,0.01l0.13,1.57c0.03,0.31,0.17,0.6,0.4,0.81l0.42,0.4l-0.18,0.49
                  c-0.03,0.07-0.05,0.15-0.06,0.23l-0.19,1.15l-0.58,0.9c-0.06,0.1-0.11,0.2-0.14,0.31l-0.23,0.71l-0.67,0.35
                  c-0.29,0.16-0.51,0.42-0.61,0.74c-0.1,0.31-0.07,0.66,0.08,0.95l0.4,0.76l-0.28,0.65c-0.16,0.39-0.12,0.83,0.1,1.18
                  c0.23,0.35,0.62,0.56,1.04,0.56l0.9,0.01l0.13,0.09l-0.02,0.71c-0.01,0.2,0.04,0.4,0.12,0.58l0.41,0.85l-0.11,0.86
                  c-0.02,0.15-0.01,0.29,0.02,0.44l0.22,0.94l-0.29,1.2c-0.03,0.14-0.04,0.28-0.02,0.42l0.15,1.39l0.18,1.19l0.1,1.17l-0.18,1.09
                  c-0.01,0.04-0.01,0.08-0.02,0.12l-0.2,2.3l-0.39,1.02c-0.06,0.16-0.09,0.33-0.08,0.5l0.05,1.36c0.01,0.24,0.09,0.47,0.22,0.66
                  l0.71,1.02c0.14,0.2,0.33,0.35,0.55,0.45l0.63,0.26l0.17,0.5l0.02,1.16c0.01,0.49,0.31,0.94,0.76,1.13l0.44,0.19l0.01,0.09
                  l-0.11,0.06l-0.74-0.04l-0.87-0.44c-0.34-0.17-0.74-0.17-1.09-0.01s-0.6,0.47-0.69,0.84l-0.24,1.02l-0.45,0.53l-2.46,0.73
                  c-0.21,0.06-0.39,0.18-0.54,0.33l-0.76-0.76c-0.17-0.17-0.38-0.28-0.61-0.34l-1.05-0.24l-0.3-0.18l0.15-0.49
                  c0.04-0.11,0.06-0.22,0.06-0.33l0.04-1.12l0.3-1.2c0.07-0.28,0.04-0.58-0.08-0.84l-0.49-1.06l-0.02-1.64
                  c-0.01-0.56-0.38-1.05-0.92-1.19l-1.27-0.36l-1.2-0.24l-2.22-0.76l-0.2-0.26l0.21-0.94c0.05-0.23,0.04-0.46-0.04-0.67l-0.49-1.44
                  c-0.08-0.25-0.25-0.46-0.47-0.62l-1.05-0.73c-0.16-0.11-0.36-0.19-0.56-0.21l-1.06-0.13l-0.69-0.3l-0.47-0.75
                  c-0.1-0.15-0.23-0.28-0.38-0.38l-1.08-0.7c-0.28-0.18-0.62-0.24-0.95-0.17l-0.94,0.21l-0.82-0.25l-0.75-0.45l-0.84-1.02l-0.56-1
                  c-0.06-0.1-0.13-0.2-0.22-0.28l-0.9-0.88c-0.05-0.06-0.11-0.11-0.17-0.15l-1.04-0.7c-0.06-0.03-0.11-0.06-0.17-0.09l-1.13-0.53
                  c-0.04-0.02-0.09-0.04-0.14-0.06l-1.08-0.36l-1.94-1.12l-0.66-0.88l-0.8-0.94c-0.12-0.14-0.27-0.26-0.44-0.33l-1.17-0.52
                  c-0.17-0.07-0.35-0.12-0.52-0.11l-0.72,0.01l-1.14-1.39l-0.23-0.98l0.08-1.19l0.22-1.28c0.07-0.39-0.05-0.79-0.33-1.08l-1.91-1.91
                  c-0.08-0.09-0.19-0.16-0.3-0.22l-1.16-0.6c-0.06-0.03-0.13-0.06-0.19-0.08l-0.47-0.15l0.47-0.54c0.29-0.34,0.39-0.8,0.24-1.22
                  l-0.41-1.21c-0.08-0.24-0.23-0.45-0.44-0.6l-0.82-0.61l-0.62-0.96l-0.09-0.74l0.2-1.02c0.05-0.24,0.03-0.49-0.06-0.71l-1.06-2.66
                  c-0.02-0.05-0.04-0.1-0.06-0.14l-1.18-2.2c-0.05-0.1-0.12-0.2-0.19-0.28l-0.63-0.68l-0.18-0.95l0.32-0.96
                  c0.07-0.23,0.08-0.48,0.02-0.71l-0.34-1.27c-0.04-0.16-0.12-0.31-0.22-0.44l-0.71-0.93l-0.48-1.08l-0.58-1.12
                  c-0.06-0.13-0.15-0.24-0.25-0.34l-0.99-0.94c-0.03-0.03-0.07-0.06-0.11-0.09l-1.69-1.29l-0.19-0.89c-0.03-0.14-0.09-0.28-0.17-0.41
                  l-0.61-0.94l-0.53-1.23c-0.05-0.11-0.11-0.21-0.18-0.3l-1.47-1.82l-0.29-1.03l-0.48-1.37l-0.52-1.23c-0.08-0.19-0.21-0.37-0.38-0.5
                  l-1.03-0.8c-0.13-0.1-0.28-0.17-0.44-0.22l-0.84-0.22l-1.24-1.66l-0.69-2.17l0.2-2.38c0.03-0.24-0.02-0.48-0.13-0.7l-0.26-0.5
                  l0.26-0.35l0.8-0.38l1.18-0.03c0.32-0.01,0.62-0.14,0.85-0.36l1.08-1.07c0.04-0.04,0.08-0.09,0.12-0.14l0.6-0.8l1.07-0.52
                  c0.32-0.15,0.56-0.44,0.66-0.79l0.23-0.87l1.19-0.54l0.63,1.08c0.07,0.13,0.17,0.25,0.28,0.35l0.97,0.84
                  c0.26,0.22,0.6,0.33,0.95,0.29l1.23-0.13c0.07,0,0.14-0.02,0.21-0.04l2.02-0.57c0.06-0.01,0.12-0.03,0.17-0.06l1.39-0.62l1.04-0.42
                  l0.78,0.35c0.04,0.02,0.09,0.04,0.13,0.05l1.43,0.45c0.4,0.13,0.83,0.05,1.16-0.21l0.93-0.74l0.57-0.31l0.73,0.22
                  c0.65,0.19,1.34-0.18,1.54-0.83l0.17-0.55l0.45,0.03c0.11,0.01,0.23,0,0.34-0.03l0.79-0.17l0.7,0.37c0.45,0.23,1,0.17,1.39-0.16
                  l1.16-0.99l0.87-0.86l0.84-0.5l1.04-0.22c0.46-0.09,0.82-0.44,0.95-0.89l0.23-0.85l0.56-0.5l0.97-0.48c0.06,0.07,0.13,0.14,0.21,0.2
                  l1.03,0.78c0.14,0.1,0.3,0.18,0.47,0.22l1.34,0.3c0.44,0.1,0.9-0.05,1.2-0.39l0.78-0.86l0.77-0.53l1.06-0.37
                  c0.34-0.11,0.61-0.37,0.75-0.7l0.51-1.2l0.46-0.58l0.52-0.07l0.99,0.2c0.07,0.02,0.14,0.03,0.21,0.03l0.99,0.03l0.74,0.32l0.78,0.7
                  c0.17,0.16,0.38,0.26,0.61,0.3l1.23,0.22l1.16,0.5c0.58,0.25,1.26,0.02,1.57-0.52l0.66-1.14c0.02-0.03,0.03-0.06,0.05-0.09
                  c0.24,0.03,0.48-0.01,0.7-0.11c0.34-0.16,0.58-0.45,0.68-0.81l0.05-0.21l0.07,0.06c0.16,0.16,0.37,0.27,0.6,0.32l1.34,0.29
                  c0.25,0.06,0.52,0.03,0.76-0.07l-0.04,0.54c-0.01,0.16,0.01,0.32,0.06,0.47l0.18,0.54l-0.02,0.01l-0.9,0.5l-1.07,0.34
                  c-0.12,0.04-0.23,0.09-0.33,0.16l-1.07,0.74c-0.47,0.32-0.66,0.92-0.47,1.45l0.42,1.17c0.03,0.08,0.06,0.15,0.1,0.21l0.36,0.6
                  l-0.29,0.74c-0.01,0.04-0.02,0.08-0.04,0.12l-0.34,1.23c-0.07,0.25-0.05,0.53,0.04,0.77l0.49,1.29c0.12,0.33,0.37,0.58,0.69,0.72
                  l0.75,0.3l0.83,2.94l-0.43,2.18c-0.04,0.23-0.02,0.47,0.07,0.69l0.43,1.05v1.17c0,0.14,0.02,0.28,0.07,0.41l0.42,1.21l0.37,1.32
                  c0.01,0.05,0.02,0.09,0.04,0.13l0.49,1.24l0.25,2.36l0.04,1.5l0.12,1.34c0.02,0.12,0.04,0.23,0.08,0.34l0.53,1.39
                  c0.08,0.2,0.21,0.38,0.38,0.52l0.78,0.64l0.47,0.89c0.06,0.1,0.13,0.2,0.2,0.28l0.74,0.77l0.2,0.95c0.03,0.15,0.08,0.28,0.15,0.4
                  l1.27,2.13l0.35,0.8l-0.05,0.96c-0.03,0.67,0.47,1.25,1.14,1.31l0.96,0.08l0.9,0.59l0.3,0.28l-0.21,0.54
                  C467.28,144.3,467.26,144.55,467.31,144.79z"/>
                <title>Bytča</title>
              </Link>

              {/*Zilina - Zilinsky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Žilina")}>
                <path style={{ fill: crimesInRegionZilinsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionZilinsky[5].crimePercent)] ? crimesInRegionZilinsky[5].color : "#D3D3D3" }}
                  d="M591.64,121.65c-0.09,0.14-0.15,0.3-0.18,0.47l-0.13,0.93l-0.61,0.7c-0.09,0.1-0.17,0.23-0.22,0.36l-0.48,1.2
                  l-0.56,1.01c-0.03,0.05-0.06,0.11-0.08,0.16l-0.39,1.04l-0.67,0.79c-0.28,0.33-0.37,0.78-0.24,1.19l0.34,1.09l-0.74,2.42
                  c-0.1,0.32-0.07,0.67,0.09,0.97l0.57,1.05l0.3,0.94l0.09,0.97l-0.12,1.2c-0.01,0.03-0.01,0.07-0.01,0.1l-0.03,1.25
                  c-0.01,0.25,0.07,0.5,0.2,0.71l0.67,1.02l0.28,0.77l-0.26,2.69l-0.22,1.19c-0.03,0.15-0.03,0.29,0,0.43l0.16,0.98l-0.7,2.05
                  l-0.51,1.12c-0.07,0.17-0.11,0.36-0.11,0.54l0.03,1.8c0,0.13,0.02,0.26,0.07,0.38l0.38,1.12l-0.05,0.9l-0.36,1l-0.49,1.09
                  l-0.14,0.18l-1.27-0.61l-1.01-0.81c-0.37-0.29-0.87-0.35-1.3-0.16l-1.4,0.64c-0.14,0.07-0.27,0.16-0.38,0.27l-0.85,0.88l-0.91,0.35
                  l-1.32,0.43c-0.57,0.19-0.93,0.76-0.85,1.36l0.22,1.59l0.03,1.29l-0.05,1.3l-0.77,2.51l-1.03,0.64l-0.59,0.43l-1.1-0.25l-1.03-0.55
                  l-1.14-0.52c-0.51-0.23-1.12-0.09-1.49,0.35l-0.4,0.48l-0.14-0.09c-0.15-0.1-0.32-0.16-0.49-0.19l-1.32-0.2
                  c-0.09-0.01-0.18-0.01-0.27-0.01l-0.6,0.04l-0.38-0.45c-0.18-0.21-0.41-0.36-0.68-0.42l-1.26-0.29c-0.22-0.05-0.45-0.04-0.67,0.03
                  l-1.54,0.5c-0.37,0.12-0.66,0.41-0.79,0.78l-0.39,1.1l-1.31,1.88l-0.7,0.09l-2.51-0.13h-0.11l-1.31,0.06
                  c-0.13,0.01-0.25,0.03-0.38,0.08l-1.18,0.44c-0.13,0.05-0.25,0.12-0.35,0.2l-0.99,0.81c-0.13,0.11-0.24,0.24-0.32,0.39l-0.56,1.08
                  l-0.6,0.99l-0.98,0.73c-0.19,0.15-0.34,0.35-0.42,0.59l-0.31,0.87l-0.87,0.35c-0.1,0.03-0.19,0.08-0.27,0.14l-0.71,0.49l-0.35-0.05
                  l-0.74-0.66l-1.05-0.82c-0.35-0.27-0.81-0.33-1.22-0.18l-1.22,0.47c-0.19,0.08-0.35,0.19-0.49,0.34l-0.51,0.58l-0.74,0.06
                  c-0.09,0.01-0.18,0.03-0.27,0.06l-1.33,0.42c-0.32,0.1-0.59,0.33-0.74,0.64l-0.41,0.83l-0.58,0.39l-0.51,0.13l-0.68-0.38
                  c-0.07-0.04-0.14-0.07-0.21-0.09l-1.16-0.39c-0.61-0.21-1.27,0.08-1.54,0.66l-0.46,1.02l-0.59,0.78l-0.84,0.68
                  c-0.28,0.23-0.45,0.57-0.46,0.94l-0.01,0.38l-1.46,0.46l-1.13,0.2l-1.06-0.24l-1.22-0.36c-0.18-0.05-0.37-0.06-0.55-0.03l-1.39,0.23
                  c-0.1,0.01-0.2,0.04-0.29,0.08l-1.32,0.56c-0.09,0.04-0.17,0.09-0.25,0.14l-1.03,0.76c-0.25,0.18-0.42,0.45-0.48,0.74l-0.31,1.41
                  c-0.02,0.08-0.03,0.16-0.03,0.24l-0.03,1.16l-0.3,1.09l-0.54,0.88l-1.76,1.65c-0.08,0.08-0.16,0.17-0.22,0.27l-0.56,0.89l-1.03,0.37
                  c-0.55,0.2-0.89,0.76-0.82,1.34l0.17,1.29l0.06,0.79l-0.59,0.81c-0.04,0.05-0.08,0.11-0.11,0.17l-0.68,1.32
                  c-0.08,0.15-0.13,0.32-0.14,0.49l-0.06,0.94l-0.68,0.99c-0.09,0.13-0.15,0.27-0.18,0.41l-0.29,1.19c-0.02,0.05-0.02,0.1-0.03,0.14
                  l-0.16,1.28c-0.01,0.1-0.01,0.2,0,0.3l0.09,0.84l-0.5,0.77c-0.11,0.17-0.17,0.36-0.19,0.56l-0.15,1.56c-0.02,0.16,0,0.33,0.04,0.49
                  l0.18,0.58l-1.21,1.54l-1.48,2.09l-2.08,1.78c-0.03,0.03-0.06,0.06-0.09,0.09l-0.63,0.69l-0.95,0.3c-0.18,0.06-0.34,0.15-0.48,0.28
                  l-0.85,0.8l-1.31,0.86l-0.77,0.45l-0.85-0.13c-0.33-0.06-0.67,0.02-0.93,0.22l-1.04,0.75l-0.93,0.51l-1.19,0.35
                  c-0.29,0.09-0.54,0.28-0.71,0.53l-0.44,0.7l-1.94,0.03c-0.16,0-0.32,0.03-0.46,0.09l-1.15,0.47c-0.48,0.2-0.79,0.67-0.78,1.19
                  l0.03,1.26c0,0.17,0.04,0.34,0.12,0.5l0.5,1.07l0.23,0.82l-0.29,0.99c-0.08,0.26-0.07,0.54,0.03,0.79l0.38,1.02l0.22,2.25
                  l-0.27,0.58l-0.75,0.7l-0.86,0.66l-0.98,0.36c-0.09,0.03-0.17,0.07-0.25,0.12l-1.07,0.7c-0.5,0.33-0.7,0.95-0.49,1.49l0.46,1.21
                  c0.02,0.06,0.05,0.11,0.08,0.16l0.39,0.73l-0.09,0.86c-0.01,0.04-0.01,0.09-0.01,0.13v0.29l-0.28,0.03
                  c-0.11,0.01-0.21,0.03-0.31,0.07l-1.22,0.45c-0.19,0.07-0.35,0.18-0.49,0.32l-0.83,0.9c-0.15,0.16-0.25,0.36-0.3,0.58l-0.55,2.58
                  l-0.3,0.63l-0.64,0.32l-0.98,0.03l-1.17-0.16c-0.24-0.03-0.48,0.01-0.69,0.1l-0.81,0.38l-1.54-0.45l-0.35-0.76
                  c-0.09-0.21-0.25-0.39-0.44-0.51l-1.03-0.68c-0.16-0.11-0.34-0.18-0.53-0.2l-1.35-0.17c-0.25-0.03-0.49,0.01-0.7,0.11l-1.43,0.69
                  c-0.21,0.1-0.38,0.26-0.51,0.45l-0.68,1.05l-0.69,0.94l-2.15,1.91c-0.02,0.03-0.05,0.05-0.08,0.08l-0.8,0.86l-0.97,0.59
                  c-0.51,0.3-0.73,0.91-0.54,1.47l0.36,1.08l-0.1,0.88l-0.41,0.95l-0.56,1.13l-0.53,0.95l-0.08,0.08h-0.01l-0.86-0.02
                  c-0.19,0-0.38,0.04-0.56,0.12l-1.19,0.55l-0.97,0.12l-1.27-0.31c-0.13-0.04-0.28-0.05-0.41-0.03l-1.41,0.13
                  c-0.07,0-0.14,0.01-0.2,0.03l-1.21,0.31c-0.43,0.11-0.77,0.44-0.89,0.87l-0.33,1.16l-0.04,0.06l-0.39-0.63l-0.55-1.14
                  c-0.03-0.05-0.06-0.1-0.09-0.15l-0.56-0.86l-0.21-1.05c-0.08-0.36-0.31-0.67-0.64-0.85l-1.15-0.62c-0.26-0.14-0.56-0.19-0.85-0.12
                  l-1.21,0.26c-0.5,0.1-0.88,0.5-0.97,0.99l-0.17,0.94l-0.63,0.42c-0.1,0.07-0.19,0.15-0.27,0.24l-1.14,1.37
                  c-0.03,0.03-0.05,0.07-0.08,0.1l-0.8,1.19c-0.03,0.05-0.06,0.1-0.08,0.15l-0.62,1.26c-0.14,0.28-0.17,0.61-0.08,0.91l0.23,0.74
                  l-0.21,0.35l-0.67,0.21l-1.15,0.04c-0.03,0-0.06,0-0.1,0.01l-1.59,0.19c-0.11,0.01-0.21,0.04-0.31,0.08l-1.25,0.5
                  c-0.1,0.04-0.2,0.09-0.29,0.16l-1.06,0.79c-0.08,0.06-0.15,0.13-0.21,0.2l-0.91,1.1l-0.74,0.75l-0.88,0.52l-0.79,0.19l-0.8-0.1
                  l-0.98-0.6l-1.18-0.62c-0.12-0.06-0.26-0.11-0.39-0.13l-2.69-0.37c-0.08-0.01-0.16-0.01-0.24-0.01l-1.41,0.09
                  c-0.14,0.01-0.28,0.04-0.41,0.1l-0.62,0.26l-0.57-0.3c-0.19-0.1-0.4-0.15-0.61-0.15l-0.62,0.01l-1.39-1.76l-0.69-0.98l-0.07-0.69
                  l0.07-0.29l1.7-0.3c0.27-0.04,0.51-0.17,0.7-0.36l0.82-0.85l0.76-0.58l1.99-0.71l1.26,0.13c0.41,0.05,0.8-0.11,1.07-0.42l1.04-1.19
                  c0.07-0.07,0.12-0.15,0.17-0.24l0.56-1.07l0.65-0.99c0.04-0.06,0.07-0.13,0.1-0.2l0.56-1.33c0.05-0.1,0.08-0.21,0.09-0.33l0.28-2.26
                  l0.5-0.37l2.46-1.28c0.05-0.02,0.1-0.05,0.14-0.09l1.02-0.74c0.08-0.05,0.15-0.12,0.21-0.19l0.43-0.49l0.64,0.08
                  c0.03,0,0.07,0.01,0.1,0.01l1.24,0.06c0.18,0.01,0.36-0.02,0.53-0.09l1.1-0.45l1.21-0.14c0.12-0.01,0.23-0.04,0.34-0.08l1.1-0.46
                  l1.05-0.07c0.19-0.02,0.37-0.07,0.53-0.17l2.35-1.35c0.15-0.08,0.28-0.19,0.38-0.33l1.77-2.39c0.05-0.06,0.09-0.13,0.12-0.21
                  l0.46-0.97l1.6-1.72c0.05-0.05,0.1-0.11,0.14-0.18l0.66-1.06c0.15-0.24,0.22-0.53,0.18-0.82l-0.1-0.81l0.23-0.37l0.81-0.24
                  c0.22-0.07,0.41-0.19,0.57-0.36l0.88-0.97c0.14-0.16,0.24-0.35,0.29-0.55l0.27-1.19l0.49-1c0.12-0.24,0.16-0.52,0.11-0.78
                  l-0.22-1.16l0.15-1.14l0.09-1.23c0-0.09,0-0.17-0.01-0.26l-0.17-1.23c-0.04-0.27-0.17-0.53-0.36-0.72l-0.7-0.69l-0.04-0.13l0.3-0.23
                  c0.07-0.06,0.14-0.12,0.2-0.19l0.85-1c0.24-0.28,0.34-0.65,0.28-1.01l-0.22-1.33c0-0.05-0.01-0.09-0.03-0.14l-0.32-1.15l0.48-2.31
                  c0.06-0.26,0.03-0.53-0.07-0.77l-0.45-1.03l-0.29-1.22c-0.08-0.31-0.27-0.58-0.53-0.75l-1.15-0.76l-0.58-0.54l-0.12-1.13
                  c-0.03-0.32-0.19-0.62-0.44-0.83l-1.01-0.85l-2.07-1.5c-0.12-0.09-0.25-0.16-0.4-0.2l-1.29-0.37c-0.07-0.02-0.14-0.04-0.21-0.04
                  l-1.35-0.14l-1.34-0.08l0.08-0.19l1.01-0.71c0.07-0.05,0.14-0.11,0.2-0.18l1-1.09l1.17-0.68c0.2-0.11,0.36-0.27,0.47-0.47l0.4-0.71
                  l0.76-0.34c0.13-0.06,0.25-0.14,0.35-0.24l0.92-0.88c0.04-0.03,0.08-0.07,0.11-0.11l0.78-0.96c0.04-0.05,0.08-0.11,0.11-0.16
                  l0.74-1.27c0.07-0.12,0.12-0.26,0.15-0.4l0.04-0.22l0.58-0.9c0.08-0.12,0.13-0.25,0.16-0.38l0.13-0.5l2.06-0.59
                  c0.25-0.07,0.47-0.21,0.63-0.4l0.9-1.06c0.09-0.1,0.16-0.22,0.21-0.35c0.06,0.02,0.12,0.02,0.18,0.03l1.34,0.08
                  c0.22,0.01,0.45-0.04,0.65-0.14l1.15-0.6c0.44-0.23,0.7-0.71,0.67-1.21l-0.13-1.68c-0.04-0.46-0.33-0.87-0.77-1.05l-0.38-0.17
                  l-0.01-0.53c0-0.13-0.02-0.25-0.06-0.38l-0.41-1.23c-0.12-0.34-0.38-0.62-0.71-0.76l-0.82-0.34l-0.29-0.41l-0.03-0.74l0.36-0.94
                  c0.04-0.11,0.06-0.21,0.07-0.32l0.22-2.48l0.2-1.19c0.02-0.11,0.02-0.21,0.02-0.31l-0.12-1.41l-0.2-1.22l-0.11-1.12l0.3-1.27
                  c0.04-0.19,0.04-0.38,0-0.57l-0.23-1.01l0.13-1.01c0.03-0.24-0.01-0.48-0.11-0.7l-0.44-0.91l0.03-1.06
                  c0.01-0.42-0.18-0.81-0.52-1.05l-0.99-0.72c-0.08-0.06-0.17-0.11-0.27-0.15c0.01-0.22-0.04-0.43-0.14-0.63l-0.09-0.17l0.02-0.01
                  c0.29-0.16,0.51-0.42,0.61-0.73l0.33-1.06l0.63-0.97c0.09-0.15,0.16-0.31,0.18-0.48l0.21-1.29l0.43-1.16c0.18-0.47,0.05-1-0.32-1.34
                  l-0.67-0.63l-0.22-2.2c-0.06-0.61-0.55-1.08-1.16-1.12l-0.84-0.05l-0.67-0.59l-0.52-0.4l0.27-0.14c0.04-0.02,0.08-0.05,0.11-0.07
                  l1.25-0.83c0.2-0.13,0.35-0.31,0.45-0.52l0.59-1.32l0.61-0.97l0.97-0.48c0.05-0.02,0.09-0.05,0.13-0.07l0.74-0.48l1.02,0.02
                  c0.14,0.01,0.28-0.02,0.41-0.06l1.3-0.42c0.05-0.02,0.1-0.04,0.15-0.06l1.48-0.69c0.09-0.04,0.17-0.09,0.25-0.15l1.05-0.84
                  c0.2-0.17,0.35-0.39,0.42-0.64l0.41-1.44c0.06-0.2,0.06-0.41,0.02-0.61l-0.22-0.98l0.38-0.97c0.19-0.48,0.07-1.02-0.3-1.37
                  l-0.99-0.93c-0.06-0.05-0.11-0.09-0.17-0.13l-1.25-0.83c-0.17-0.12-0.37-0.19-0.58-0.21h-0.08v-0.05c0.01-0.19-0.03-0.38-0.1-0.56
                  l-0.49-1.13c-0.02-0.04-0.04-0.08-0.07-0.12l-1.22-2.07l-0.23-1.08c-0.04-0.23-0.15-0.44-0.31-0.61l-0.87-0.91l-0.5-0.96
                  c-0.08-0.15-0.19-0.28-0.32-0.39l-0.72-0.59l-0.35-0.91l-0.1-1.09l-0.04-1.49c0-0.05-0.01-0.09-0.01-0.13l-0.15-1.21l-0.11-1.29
                  c-0.02-0.12-0.04-0.24-0.09-0.35l-0.53-1.35l-0.38-1.34l-0.37-1.08v-1.2c0-0.16-0.03-0.32-0.09-0.47l-0.39-0.94l0.42-2.04
                  c0.04-0.2,0.04-0.41-0.02-0.61l-0.69-2.6c-0.01-0.04-0.02-0.08-0.03-0.12l-0.43-1.2c-0.12-0.33-0.38-0.6-0.7-0.73l-0.76-0.32
                  l-0.16-0.4l0.22-0.78l0.48-1.24c0.13-0.36,0.1-0.76-0.09-1.08l-0.6-1.02l-0.06-0.16l1.14-0.41c0.08-0.02,0.15-0.06,0.23-0.1
                  l1.1-0.61c0.06-0.03,0.12-0.07,0.17-0.12l0.54-0.43l1.82,0.34l1,0.5c0.46,0.23,1.01,0.15,1.39-0.19l0.93-0.82l1-0.76
                  c0.15-0.12,0.27-0.27,0.36-0.44l0.61-1.24c0.07-0.13,0.11-0.29,0.12-0.44l0.11-1.22l0.19-0.55l0.52-0.23l0.35,0.04l0.24,0.49
                  c0.04,0.08,0.09,0.16,0.15,0.23l0.96,1.18c0.22,0.27,0.56,0.44,0.91,0.46l1.16,0.06l0.85,0.14l0.61,0.69
                  c0.08,0.09,0.17,0.17,0.27,0.23l1.17,0.75c0.08,0.06,0.17,0.1,0.27,0.13l0.78,0.27l0.2,0.56l0.43,2.51c0.04,0.27,0.18,0.51,0.37,0.7
                  l0.89,0.84c0.09,0.08,0.18,0.15,0.29,0.2l0.42,1.01c0.04,0.1,0.09,0.19,0.15,0.27l0.83,1.1c0.13,0.17,0.3,0.31,0.5,0.39l1,0.45
                  l0.11,0.56c0.01,0.06,0.03,0.12,0.05,0.18l0.44,1.27c0.08,0.24,0.24,0.45,0.45,0.6l0.51,0.38v0.59c0,0.09,0.01,0.17,0.02,0.26
                  l0.27,1.29l0.28,1.17l0.24,1.23c0.02,0.09,0.05,0.17,0.08,0.25l0.49,1.13l0.29,1.11c0.03,0.09,0.06,0.18,0.11,0.27l0.62,1.16
                  c0.07,0.14,0.17,0.26,0.29,0.37l0.6,0.5l0.02,0.67c0.01,0.27,0.11,0.54,0.28,0.75l1.42,1.73l0.1,0.92c0.02,0.25,0.12,0.48,0.27,0.67
                  l0.41,0.51l-0.12,0.64c-0.06,0.34,0.02,0.69,0.23,0.97l0.67,0.92l0.38,0.91l0.26,1.24c0.01,0.08,0.04,0.16,0.07,0.24l0.51,1.2
                  c0.07,0.15,0.16,0.29,0.28,0.41l0.72,0.69l0.34,0.98c0.19,0.54,0.7,0.88,1.27,0.84l2.44-0.19l1.67,0.16
                  c0.39,0.04,0.77-0.1,1.04-0.39l0.86-0.93c0.06-0.07,0.12-0.15,0.17-0.24l0.9-1.6c0.03-0.06,0.06-0.12,0.09-0.19l0.26-0.73l0.04,0.04
                  c0.08,0.1,0.18,0.17,0.29,0.24l1.18,0.67c0.16,0.09,0.33,0.14,0.51,0.16l0.3,0.02v0.13c0.01,0.14,0.04,0.28,0.09,0.41l0.15,0.39
                  l-0.31,0.33c-0.39,0.39-0.47,1-0.2,1.48l0.65,1.16c0.07,0.12,0.15,0.23,0.26,0.32l1.79,1.66l0.64,0.92c0.1,0.15,0.23,0.28,0.38,0.37
                  l1.12,0.68c0.05,0.03,0.09,0.05,0.14,0.07l0.79,0.35l0.24,0.81c0.18,0.58,0.75,0.95,1.35,0.88l1.26-0.15
                  c0.53-0.06,0.96-0.46,1.07-0.98l0.17-0.76l0.78-0.61c0.09-0.07,0.17-0.16,0.24-0.25l0.78-1.07l0.85-1.25c0.29-0.42,0.29-0.98,0-1.41
                  l-0.43-0.62l0.07-0.47l0.16-0.25l0.72,0.01l1.04,0.09l0.15,0.05l-0.01,0.43c-0.02,0.59,0.38,1.11,0.94,1.25l1.11,0.28l0.88,0.56
                  c0.25,0.16,0.55,0.23,0.84,0.19l1.3-0.18c0.06-0.01,0.11-0.02,0.17-0.04l0.93-0.26l0.35,0.08l0.76,1.71l0.34,0.71l-0.23,0.7
                  c-0.2,0.59,0.06,1.23,0.61,1.51l1.16,0.59c0.18,0.09,0.38,0.14,0.57,0.14c0.3,0,0.6-0.11,0.84-0.32c0.19,0.22,0.44,0.36,0.72,0.41
                  l1.22,0.22c0.07,0.01,0.15,0.02,0.22,0.02c0.24,0,0.49-0.07,0.69-0.21l1.09-0.72l0.46-0.21l0.34,0.32c0.29,0.26,0.68,0.38,1.06,0.31
                  l1.33-0.23l1.23-0.07c0.23-0.02,0.45-0.09,0.64-0.22l1.19-0.83c0.02-0.02,0.05-0.05,0.08-0.07l1.01-0.84
                  c0.04-0.03,0.08-0.07,0.11-0.1l0.9-0.96l1.69-1.39l1.31-0.43l1.42-0.55c0.36-0.14,0.63-0.43,0.74-0.8l0.42-1.35
                  c0.02-0.06,0.04-0.12,0.05-0.18l0.2-1.3c0.02-0.14,0.01-0.27-0.01-0.41l-0.18-1.04l0.15-1.01c0.02-0.06,0.02-0.13,0.02-0.2
                  l-0.01-1.25l0.08-1.1l0.24-1.16c0.02-0.12,0.03-0.24,0.01-0.37l-0.14-1.51c-0.02-0.3-0.17-0.59-0.39-0.8l-0.91-0.84
                  c-0.17-0.16-0.39-0.26-0.61-0.31l-0.49-0.09l-0.16-0.6c-0.02-0.07-0.05-0.15-0.08-0.21l-0.04-0.08l1.54,0.52
                  c0.4,0.14,0.84,0.06,1.18-0.2l0.97-0.77c0.16-0.13,0.28-0.29,0.36-0.48l0.63-1.44c0.02-0.03,0.03-0.07,0.05-0.1l0.39-1.17l0.3-0.38
                  l0.7-0.06c0.14-0.02,0.27-0.05,0.39-0.1l1.19-0.52c0.11-0.05,0.22-0.12,0.31-0.2l1.05-0.9l0.91-0.36l1.06-0.33l1.15-0.03l1.09,0.28
                  c0.04,0.01,0.08,0.02,0.12,0.03l0.51,0.07l0.28,0.74c0.02,0.03,0.03,0.07,0.05,0.1l0.58,1.18c0.16,0.34,0.47,0.58,0.83,0.67
                  l1.27,0.3c0.19,0.04,0.37,0.04,0.56,0l1.15-0.25l0.94-0.09l1.15,0.31c0.4,0.12,0.83,0.02,1.14-0.25l0.44-0.38
                  c0.27,0.24,0.64,0.37,1,0.32l0.73-0.09l0.7,0.64c0.17,0.15,0.38,0.26,0.61,0.3l1.21,0.22l1.02,0.45c0.35,0.15,0.75,0.13,1.09-0.05
                  l1.11-0.59c0.19-0.1,0.36-0.26,0.48-0.45l0.27,0.79c0.12,0.35,0.39,0.62,0.73,0.76l1.17,0.45c0.45,0.17,0.96,0.07,1.31-0.26
                  l0.48-0.46l1.46,0.14l0.5,0.56c0.09,0.09,0.19,0.18,0.3,0.24l1.09,0.63c0.44,0.25,0.99,0.22,1.39-0.08l0.79-0.61l0.94-0.23
                  c0.08-0.01,0.15-0.04,0.22-0.07l2.32-1.03l1.21-0.31c0.33-0.09,0.62-0.3,0.78-0.6l0.66-1.18l0.17-0.34l0.57,0.11
                  c0.1,0.02,0.19,0.02,0.29,0.02l0.89-0.04l0.99,0.55C591.61,121.64,591.63,121.64,591.64,121.65z"/>
                <title>Žilina</title>
              </Link>

              {/*Martin - opravit*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Martin")}>
                <path style={{ fill: crimesInRegionZilinsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionZilinsky[3].crimePercent)] ? crimesInRegionZilinsky[3].color : "#D3D3D3" }}
                  d="M598.47,193.01l-0.03,0.92l-0.29,0.71l-1.63,1.64c-0.04,0.05-0.09,0.1-0.12,0.15l-0.45,0.62l-0.77,0.16
                  c-0.17,0.03-0.32,0.1-0.46,0.19l-1.05,0.72c-0.21,0.14-0.37,0.35-0.46,0.59l-0.43,1.15c-0.07,0.18-0.09,0.36-0.08,0.55l0.13,1.54
                  c0.01,0.1,0.03,0.2,0.07,0.29l0.3,0.89l-0.27,0.85l-1.06,2.29c-0.1,0.22-0.14,0.47-0.11,0.71l0.18,1.3
                  c0.01,0.08,0.03,0.16,0.06,0.23l1.16,3.39l-0.2,0.98c-0.02,0.09-0.03,0.18-0.03,0.27l0.02,1.03l-0.36,1
                  c-0.09,0.27-0.09,0.55-0.01,0.81l0.35,1.04l0.02,1.06c0,0.05,0,0.09,0.01,0.14l0.19,1.42c0.01,0.08,0.03,0.16,0.06,0.24l0.22,0.66
                  l-0.19,0.26l-0.92,0.46c-0.23,0.12-0.42,0.31-0.55,0.54l-0.6,1.14c-0.04,0.09-0.08,0.19-0.1,0.29l-0.33,1.34l-0.24,1.26
                  c-0.01,0.06-0.02,0.12-0.02,0.19l-0.05,1.47c0,0.13,0.01,0.25,0.05,0.38l0.26,0.93l-0.84,2.2c-0.15,0.43-0.06,0.92,0.26,1.26
                  l0.43,0.48l-0.13,0.54c-0.05,0.22-0.04,0.44,0.02,0.65l0.32,1.07l0.02,1.06l-0.03,1.15l-0.36,1.15c-0.06,0.17-0.07,0.34-0.05,0.52
                  l0.14,1.14l-0.04,1.02l-0.26,0.75l-0.83,0.57c-0.1,0.07-0.19,0.15-0.26,0.25l-0.78,0.97c-0.12,0.14-0.2,0.31-0.25,0.49l-0.33,1.37
                  c-0.05,0.22-0.04,0.44,0.03,0.66l0.38,1.23c0.03,0.11,0.08,0.23,0.15,0.33l0.54,0.8l0.05,0.28l-0.41,0.35
                  c-0.26,0.24-0.42,0.57-0.42,0.93l-0.01,1.18l-0.1,1.39l-0.01,1.37c0,0.2,0.05,0.4,0.14,0.58l0.58,1.13c0.08,0.15,0.18,0.29,0.32,0.4
                  l2.37,2l1.05,2.36c0.03,0.08,0.08,0.15,0.13,0.22l0.84,1.11c0.21,0.28,0.52,0.45,0.86,0.49l1.28,0.15l0.63,0.17l0.27,0.58
                  c0.09,0.2,0.24,0.38,0.42,0.5l0.68,0.47l0.11,0.6l0.02,0.97l-0.27,0.64l-0.73,0.61c-0.06,0.05-0.11,0.1-0.16,0.15l-0.81,0.96
                  c-0.08,0.1-0.14,0.21-0.19,0.32l-0.38,0.88l-0.43,0.37l-0.79,0.12l-1.22-0.01c-0.37,0.02-0.75,0.17-0.99,0.48l-0.65,0.82l-0.89,0.62
                  l-1.09,0.47l-1.32,0.67c-0.04,0.02-0.08,0.04-0.12,0.07l-1.07,0.71c-0.24,0.16-0.42,0.4-0.51,0.68l-0.3,0.99l-0.72,0.83
                  c-0.14,0.16-0.24,0.35-0.28,0.55l-0.28,1.28c-0.01,0.06-0.02,0.12-0.03,0.17l-0.03,0.46l-0.42,0.08c-0.38,0.08-0.71,0.33-0.88,0.69
                  l-0.58,1.2c-0.05,0.1-0.08,0.22-0.1,0.34l-0.25,1.51c-0.03,0.16-0.03,0.32,0.01,0.48l0.38,1.62l-0.44,1.11
                  c-0.13,0.32-0.12,0.67,0.03,0.98l0.71,1.56l-0.22,0.8c-0.02,0.06-0.03,0.11-0.04,0.17l-0.26,1.86l-0.04,0.17
                  c-0.06-0.02-0.11-0.02-0.17-0.01l-1.46,0.11l-1.06-0.15l-1.11-0.36l-1.12-0.7l-0.89-0.95c-0.26-0.29-0.65-0.43-1.04-0.39l-1.34,0.14
                  c-0.06,0-0.11,0.01-0.17,0.03l-1.41,0.35c-0.11,0.02-0.21,0.06-0.3,0.11l-1.08,0.59c-0.04,0.03-0.08,0.05-0.12,0.08l-0.39,0.28
                  l-0.3-0.33l-0.27-1.23c-0.14-0.64-0.73-1.06-1.38-0.98l-1.27,0.16c-0.03,0-0.07,0.01-0.11,0.02l-1.33,0.29
                  c-0.16,0.03-0.31,0.1-0.45,0.2l-1.05,0.74c-0.08,0.05-0.15,0.12-0.21,0.19l-0.83,0.93l-0.63,0.53l-0.91,0.13
                  c-0.05,0.01-0.1,0.02-0.14,0.03l-0.83,0.21l-0.84-0.31c-0.24-0.09-0.49-0.1-0.73-0.05l-1.16,0.28l-0.7,0.05l-0.48-0.37l-0.7-0.91
                  c-0.05-0.07-0.11-0.13-0.17-0.19l-0.69-0.59l-0.31-0.9c-0.1-0.31-0.32-0.57-0.61-0.71l-0.7-0.36l-0.33-0.78
                  c-0.02-0.04-0.04-0.09-0.06-0.13l-0.61-1.07c-0.14-0.24-0.35-0.42-0.6-0.53l-0.78-0.33l-0.25-0.48l-0.07-0.99
                  c-0.01-0.09-0.03-0.18-0.05-0.27l-0.35-1.18c-0.02-0.07-0.05-0.14-0.09-0.21l-1.17-2.28c-0.02-0.04-0.04-0.08-0.07-0.12l-1.49-2.35
                  c-0.09-0.14-0.2-0.26-0.33-0.36l-0.97-0.71l-0.78-0.89c-0.2-0.22-0.47-0.37-0.77-0.41l-2.87-0.38l-1.09-0.51l-1.26-0.87l-2.2-2
                  l-0.9-0.98c-0.04-0.04-0.09-0.09-0.15-0.13l-0.99-0.78c-0.13-0.11-0.28-0.18-0.44-0.23l-1.28-0.35c-0.12-0.03-0.24-0.05-0.36-0.04
                  l-1.09,0.03l-1.61-0.58c-0.06-0.02-0.12-0.04-0.19-0.05l-1.57-0.3c-0.07-0.01-0.15-0.02-0.22-0.02l-1.11-0.01l-0.52-0.21l-0.36-0.69
                  c-0.18-0.34-0.51-0.58-0.89-0.65l-0.68-0.12l-1.01-1.92c-0.24-0.45-0.73-0.7-1.23-0.64c-0.51,0.06-0.93,0.41-1.06,0.9l-0.09,0.32
                  l-0.11-0.14c-0.19-0.26-0.48-0.43-0.8-0.47l-0.45-0.08l-0.13-0.56c-0.09-0.36-0.34-0.67-0.67-0.83c-0.34-0.17-0.73-0.17-1.07-0.02
                  l-0.97,0.44l-1.03-0.07c-0.28-0.02-0.57,0.06-0.81,0.23l-0.4,0.29l-0.42-0.21c-0.06-0.03-0.12-0.06-0.18-0.08l-1.15-0.37l-1.11-0.43
                  l-1.33-0.62l-0.68-0.77c-0.12-0.14-0.27-0.25-0.44-0.32l-0.92-0.41l-0.62-0.73c-0.2-0.23-0.48-0.38-0.78-0.42l-1.01-0.14l-0.8-0.43
                  l-0.9-0.76c-0.2-0.17-0.44-0.27-0.7-0.29l-1.45-0.14l-1.24-0.55c-0.37-0.17-0.8-0.14-1.16,0.07c-0.35,0.21-0.57,0.58-0.6,0.99
                  l-0.09,1.51l0.01,0.67l-0.53,0.44l-1.39,0.81c-0.14,0.08-0.26,0.18-0.36,0.3l-0.64,0.8l-1.03,0.34c-0.14,0.05-0.26,0.11-0.37,0.2
                  l-1.14,0.88c-0.04,0.03-0.07,0.06-0.11,0.09l-0.99,0.95c-0.02,0.03-0.05,0.05-0.08,0.08l-0.75,0.88l-1.43,0.83
                  c-0.27,0.16-0.47,0.41-0.56,0.7l-0.21,0.66l-0.11,0.03c-0.01-0.07-0.02-0.13-0.03-0.19l-0.29-1.21c-0.02-0.08-0.05-0.17-0.09-0.24
                  l-0.56-1.19c-0.05-0.11-0.12-0.21-0.2-0.3l-0.86-0.98c-0.15-0.16-0.33-0.28-0.53-0.35l-1.02-0.36l-0.35-0.67
                  c-0.03-0.05-0.06-0.1-0.09-0.14l-0.75-1.06c-0.07-0.11-0.16-0.2-0.26-0.27l-0.97-0.74c-0.14-0.11-0.3-0.18-0.47-0.23l-1.29-0.31
                  c-0.15-0.03-0.31-0.04-0.47-0.02l-1.23,0.18c-0.07,0.01-0.14,0.03-0.2,0.05l-1.25,0.4l-1.11,0.26l-1.16-0.25
                  c-0.23-0.05-0.48-0.04-0.71,0.05l-0.73,0.28l-0.86-0.33c-0.05-0.02-0.1-0.03-0.15-0.04l-3.16-0.76l-1.24-0.36l-0.91-0.47l-0.23-0.21
                  l0.58-1.04l0.62-1.26l0.51-1.19c0.05-0.11,0.08-0.23,0.09-0.35l0.15-1.34c0.02-0.18,0-0.36-0.05-0.54l-0.14-0.39l0.27-0.16
                  c0.1-0.06,0.2-0.13,0.28-0.22l0.88-0.95l2.18-1.94c0.07-0.06,0.13-0.13,0.19-0.2l0.81-1.11l0.53-0.82l0.78-0.37l0.69,0.08l0.5,0.33
                  l0.43,0.93c0.14,0.31,0.42,0.56,0.75,0.66l1.31,0.42l1.29,0.35c0.29,0.07,0.58,0.05,0.85-0.07l0.89-0.41l0.91,0.12
                  c0.07,0.01,0.14,0.01,0.21,0.01l1.36-0.04c0.18-0.01,0.36-0.05,0.52-0.13l1.27-0.64c0.25-0.13,0.45-0.33,0.57-0.58l0.55-1.15
                  c0.04-0.09,0.07-0.19,0.1-0.29l0.5-2.36l0.39-0.43l0.79-0.29l1.24-0.13c0.64-0.06,1.13-0.59,1.13-1.23l0.01-1.35l0.13-1.19
                  c0.03-0.26-0.02-0.52-0.15-0.74l-0.54-1l-0.07-0.18l1.12-0.45c0.12-0.04,0.23-0.1,0.32-0.17l1.07-0.81
                  c0.04-0.03,0.07-0.06,0.11-0.09l0.97-0.91c0.11-0.11,0.2-0.24,0.27-0.38l0.52-1.11c0.1-0.2,0.14-0.43,0.11-0.65l-0.27-2.76
                  c-0.01-0.11-0.03-0.22-0.07-0.32l-0.29-0.78l0.27-0.94c0.07-0.23,0.07-0.47,0-0.69l-0.36-1.26c-0.02-0.07-0.04-0.13-0.07-0.19
                  l-0.43-0.92v-0.14l2.57-0.11c0.41-0.02,0.79-0.23,1.01-0.58l0.54-0.85l0.86-0.26c0.09-0.03,0.17-0.06,0.25-0.1l1.12-0.61
                  c0.05-0.03,0.09-0.06,0.13-0.09l0.68-0.49l0.78,0.12c0.28,0.05,0.58,0,0.83-0.15l1.21-0.71l1.46-0.95c0.07-0.04,0.12-0.09,0.18-0.14
                  l0.72-0.69l0.99-0.31c0.21-0.06,0.39-0.18,0.54-0.34l0.81-0.88l2.1-1.8c0.08-0.07,0.15-0.14,0.21-0.22l1.56-2.2l1.58-2
                  c0.25-0.32,0.33-0.75,0.21-1.14l-0.3-0.97l0.1-1.01l0.57-0.89c0.16-0.24,0.22-0.53,0.19-0.82l-0.12-1.13l0.13-1.06l0.22-0.9
                  l0.75-1.09c0.13-0.19,0.2-0.41,0.22-0.63l0.06-1.02l0.52-1l0.82-1.11c0.18-0.24,0.26-0.54,0.24-0.85l-0.12-1.31l-0.04-0.34l0.5-0.18
                  c0.26-0.1,0.48-0.28,0.63-0.52l0.7-1.11l1.81-1.7c0.08-0.08,0.14-0.16,0.2-0.25l0.71-1.17c0.06-0.1,0.1-0.21,0.13-0.32l0.39-1.41
                  c0.03-0.1,0.05-0.2,0.05-0.3l0.03-1.19l0.18-0.83l0.53-0.4l1.05-0.44l0.96-0.16l1.03,0.3l1.38,0.31c0.16,0.03,0.32,0.04,0.48,0.01
                  l1.45-0.25c0.05-0.01,0.1-0.02,0.15-0.03l1.26-0.39l1.21-0.44c0.48-0.17,0.81-0.62,0.82-1.14l0.02-0.65l0.51-0.42
                  c0.08-0.06,0.15-0.14,0.21-0.22l0.76-1.01c0.05-0.07,0.1-0.15,0.14-0.23l0.05-0.11l0.99,0.55c0.28,0.16,0.61,0.2,0.91,0.12l1.2-0.3
                  c0.14-0.03,0.27-0.09,0.39-0.17l1.05-0.7c0.19-0.12,0.34-0.29,0.43-0.49l0.32-0.65l0.69-0.22l1.1-0.09
                  c0.32-0.03,0.61-0.18,0.83-0.42l0.63-0.72l0.28-0.11l1.39,1.19c0.18,0.17,0.41,0.27,0.66,0.31l1.22,0.17
                  c0.31,0.04,0.63-0.03,0.89-0.22l0.99-0.69l1.26-0.5c0.34-0.13,0.61-0.4,0.73-0.74l0.37-1.07l0.87-0.65c0.12-0.1,0.22-0.21,0.31-0.35
                  l0.77-1.26l0.48-0.92l0.63-0.52l0.8-0.3l1.07-0.05l2.5,0.14c0.09,0,0.18,0,0.26-0.01l1.36-0.19c0.34-0.05,0.64-0.23,0.84-0.5
                  l0.97-1.33l0.8-1.19c0.06-0.09,0.1-0.19,0.14-0.28l0.23-0.67l0.63-0.2l0.51,0.11l0.52,0.62c0.26,0.31,0.65,0.47,1.04,0.45l1.1-0.07
                  l0.92,0.14l0.85,0.55c0.53,0.34,1.24,0.24,1.65-0.26l0.5-0.61l1.3,0.68c0.1,0.06,0.21,0.1,0.31,0.12l1.81,0.41
                  c0.35,0.08,0.72,0.01,1.01-0.2l0.98-0.71l1.37-0.85c0.26-0.16,0.46-0.42,0.54-0.71l0.49-1.71l0.44-1.35
                  c0.03-0.11,0.05-0.23,0.06-0.34l0.06-1.55l-0.03-1.44c0-0.04,0-0.09-0.01-0.14l-0.09-0.63l0.91-0.33l-0.01,0.03
                  c-0.03,0.11-0.05,0.22-0.05,0.34l-0.01,1.24c0,0.1,0.01,0.19,0.03,0.29l0.28,1.18l0.2,1.21c0.03,0.22,0.12,0.42,0.26,0.59l0.44,0.54
                  l-0.1,0.73c-0.02,0.17-0.01,0.35,0.04,0.51l0.64,2.21v1.05c0,0.08,0.01,0.15,0.02,0.23l0.17,0.9l-0.29,0.77l-0.57,1.08l-0.53,1.16
                  c-0.03,0.07-0.05,0.14-0.07,0.21l-0.31,1.22c-0.06,0.25-0.05,0.5,0.04,0.74l0.46,1.26l0.5,1.17c0.06,0.14,0.15,0.27,0.25,0.38
                  l0.68,0.7l0.34,0.91c0.03,0.08,0.07,0.15,0.11,0.22l1.38,2.21c0.06,0.1,0.13,0.19,0.22,0.26l0.69,0.63l0.28,0.87l0.24,1.36
                  c0,0.04,0.01,0.08,0.02,0.12l0.33,1.21c0.05,0.16,0.13,0.31,0.23,0.45l0.84,1.06c0.24,0.3,0.65,0.48,1.01,0.47l1.28-0.03l1.39,0.04
                  c0.17,0.01,0.34-0.02,0.49-0.08l1.39-0.54l0.71-0.08l0.69,0.39l0.98,0.67c0.13,0.09,0.28,0.15,0.44,0.18l1.22,0.26l-0.2,0.83
                  c-0.07,0.29-0.02,0.59,0.12,0.86L598.47,193.01z"/>
                <title>Martin</title>
              </Link>

              {/*Banska bystrica - banskobystricky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Banská Bystrica")}>
                <path style={{ fill: crimesInRegionBanskobystricky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionBanskobystricky[0].crimePercent)] ? crimesInRegionBanskobystricky[0].color : "#D3D3D3" }}
                  d="M692.52,352.49l-0.68,1.57c-0.03,0.07-0.06,0.15-0.07,0.22l-0.24,1.12l-0.52,0.99
                  c-0.15,0.29-0.18,0.64-0.08,0.96l0.35,1.1l-0.32,0.54l-0.82,1.16c-0.04,0.06-0.08,0.12-0.11,0.18l-0.56,1.17
                  c-0.01,0.03-0.03,0.07-0.04,0.1l-0.4,1.07l-0.67,0.63l-0.92,0.75l-0.77,0.44l-1.41,0.19c-0.07,0.01-0.14,0.03-0.21,0.05l-1.23,0.39
                  c-0.31,0.1-0.57,0.32-0.73,0.61l-0.78,1.49c-0.18,0.34-0.19,0.75-0.03,1.1l0.57,1.23l0.61,1.11c0.05,0.09,0.11,0.17,0.18,0.24
                  l0.78,0.86l0.78,1.08c0.16,0.23,0.39,0.39,0.66,0.47l0.6,0.18l0.12,0.37l-0.23-0.14l-0.3,0.5l-2.04,2.15l-0.84,0.3l-0.69,0.15
                  l-0.78-0.38c-0.31-0.16-0.68-0.17-1-0.04l-1.03,0.4l-1,0.16l-2.41,0.07c-0.39,0.01-0.76,0.2-0.99,0.53l-0.74,1.04
                  c-0.09,0.12-0.15,0.27-0.19,0.42l-0.2,0.8l-0.22,0.13l-0.71-0.14c-0.11-0.03-0.23-0.04-0.35-0.03l-1.23,0.1
                  c-0.12,0.01-0.23,0.04-0.33,0.08l-1.01,0.37l-0.67-0.31c-0.12-0.05-0.25-0.09-0.39-0.1l-1.14-0.13l-1.12-0.43l-1.54-0.47
                  c-0.06-0.01-0.11-0.02-0.16-0.03l-1.26-0.21l-2.23-0.89c-0.13-0.05-0.26-0.08-0.39-0.09l-1.27-0.08c-0.11,0-0.23,0-0.34,0.03
                  l-1.29,0.27c-0.04,0-0.08,0.02-0.12,0.03l-1.25,0.4c-0.03,0.01-0.07,0.02-0.1,0.04l-1.33,0.55l-1.13,0.57
                  c-0.04,0.02-0.08,0.04-0.11,0.07l-0.99,0.63l-0.98,0.24l-1.28,0.13l-2.59,0.46l-1.22,0.3c-0.07,0.01-0.14,0.04-0.2,0.07l-1.23,0.54
                  c-0.17,0.07-0.31,0.17-0.43,0.3l-0.8,0.89l-1.05,0.79c-0.06,0.04-0.11,0.08-0.16,0.13l-0.87,0.91l-0.91,0.53
                  c-0.12,0.07-0.22,0.15-0.31,0.25l-0.52,0.6l-0.52,0.04l-0.77-0.2l-0.95-0.72l-0.62-0.93c-0.09-0.14-0.21-0.26-0.35-0.35l-1.07-0.71
                  c-0.56-0.38-1.32-0.23-1.71,0.32l-0.72,1.03l-1.95,1.43c-0.07,0.05-0.14,0.12-0.2,0.18l-1.59,1.7l-1.03,0.55
                  c-0.14,0.07-0.26,0.16-0.36,0.28l-0.47,0.54l-2.71,0.1c-0.04,0-0.08,0.01-0.13,0.02l-1.37,0.22c-0.17,0.02-0.34,0.09-0.48,0.18
                  l-1.03,0.67c-0.24,0.16-0.42,0.39-0.51,0.67l-0.14,0.44l-0.22-0.46l-0.17-0.7l0.36-0.77c0.02-0.04,0.03-0.07,0.05-0.11l0.42-1.19
                  l0.59-1.15l0.6-1.37c0.11-0.24,0.14-0.51,0.08-0.77l-0.27-1.19l0.03-1.21c0.01-0.31-0.1-0.62-0.31-0.85l-0.81-0.93
                  c-0.08-0.09-0.17-0.17-0.27-0.23l-1.06-0.68c-0.14-0.09-0.3-0.16-0.47-0.18l-1.31-0.21c-0.08-0.02-0.17-0.02-0.25-0.02l-0.57,0.02
                  l-0.27-0.4l-0.53-1.04c-0.13-0.25-0.34-0.45-0.6-0.57l-1.12-0.5c-0.35-0.16-0.76-0.14-1.1,0.04l-1.08,0.58
                  c-0.15,0.08-0.28,0.19-0.39,0.32l-0.96,1.21c-0.09,0.11-0.15,0.23-0.2,0.37c-0.46-0.16-0.97-0.04-1.3,0.32l-1.82,1.82
                  c-0.06,0.06-0.12,0.13-0.17,0.21l-1.27,1.85l-0.82,0.51l-0.97,0.3l-2.68,0.21l-1.19-0.02c-0.29,0.02-0.6,0.1-0.83,0.3l-0.34,0.29
                  l-0.3-1.27c-0.06-0.23-0.18-0.44-0.35-0.61l-0.71-0.69l-0.38-0.92c-0.14-0.32-0.39-0.57-0.72-0.69c-0.32-0.12-0.68-0.1-0.99,0.05
                  l-1.47,0.72c-0.41,0.2-0.68,0.61-0.7,1.08l-0.03,0.85l-0.94-0.87c-0.06-0.05-0.11-0.09-0.17-0.13l-1.09-0.71
                  c-0.28-0.18-0.63-0.24-0.97-0.17l-1.08,0.26l-1,0.05l-0.66-0.2l-0.55-0.54l-0.52-0.92c-0.04-0.06-0.08-0.12-0.13-0.18l-0.77-0.92
                  l-0.74-0.98c-0.04-0.05-0.08-0.09-0.12-0.13l-0.9-0.9c-0.04-0.05-0.09-0.09-0.14-0.13l-1.05-0.77c-0.2-0.15-0.44-0.23-0.69-0.24
                  l-0.54-0.02l-0.25-0.47c-0.02-0.03-0.04-0.07-0.06-0.1l-1.61-2.37c-0.15-0.21-0.36-0.38-0.6-0.47l-1.17-0.42l-0.9-0.69
                  c-0.05-0.04-0.1-0.07-0.16-0.1l-1.1-0.61l-0.9-0.88c-0.02-0.03-0.05-0.06-0.08-0.08l-1.01-0.82l-0.96-0.98
                  c-0.13-0.13-0.29-0.23-0.47-0.3l-1.55-0.55c-0.17-0.05-0.34-0.07-0.51-0.07l0.21-0.28l-0.17-0.12l-0.61-0.75l-0.34-1
                  c-0.01-0.03-0.02-0.07-0.03-0.1l-0.51-1.17c-0.03-0.06-0.06-0.12-0.1-0.17l-0.58-0.91c0.02-0.01,0.04-0.02,0.05-0.03l1.06-0.8
                  c0.36-0.27,0.54-0.7,0.49-1.14s-0.33-0.82-0.73-1l-1.16-0.52c-0.19-0.09-0.4-0.12-0.61-0.11l-0.92,0.08l-0.92-0.43
                  c-0.28-0.13-0.6-0.16-0.89-0.07l-0.53,0.16l-0.31-0.35l-0.02-0.66l0.2-1.26c0.02-0.11,0.02-0.22,0.01-0.33l-0.29-2.79
                  c0-0.04,0-0.07-0.01-0.1l-0.24-1.31l-0.17-1.29c-0.02-0.12-0.05-0.24-0.1-0.35l-0.36-0.81l0.13-1c0.02-0.18,0.01-0.37-0.05-0.54
                  l-0.4-1.26c-0.07-0.21-0.19-0.41-0.36-0.56l-0.5-0.43l0.02-0.74l-0.04-1.11l0.31-1l-0.24-0.07l0.59-0.85
                  c0.03-0.05,0.06-0.09,0.09-0.14l0.59-1.13c0.03-0.06,0.05-0.12,0.08-0.18l0.71-2.11l0.7-0.62c0.16-0.15,0.29-0.35,0.35-0.56
                  l0.45-1.45c0.04-0.12,0.06-0.24,0.06-0.36l0.01-1.26c0-0.05,0-0.1-0.01-0.16l-0.13-1.08l0.77-2.4c0.03-0.1,0.05-0.21,0.06-0.32
                  l0.06-1.25l0.29-1.44l0.17-1.3c0.06-0.5-0.18-0.99-0.61-1.24l-0.9-0.52l-0.67-0.79c-0.1-0.12-0.22-0.22-0.35-0.29l-1.09-0.59
                  l-0.96-0.6l-0.87-0.84c-0.03-0.03-0.06-0.05-0.09-0.08l-0.69-0.55l-0.14-0.65l0.1-1.01l0.37-1.04c0.01-0.04,0.02-0.07,0.03-0.1
                  l0.2-0.78l0.56-0.24c0.23-0.09,0.42-0.25,0.56-0.46l0.81-1.22c0.11-0.15,0.17-0.33,0.2-0.52l0.17-1.22
                  c0.01-0.09,0.01-0.18,0.01-0.27l-0.11-1.26l0.01-1.23l-0.01-1.28c0-0.23-0.06-0.45-0.18-0.64l-0.57-0.94l-0.3-1.02
                  c-0.01-0.04-0.02-0.08-0.04-0.12l-0.5-1.25c-0.06-0.16-0.15-0.3-0.27-0.41l-0.41-0.43l0.02-0.1l0.35-0.27l0.89-0.02
                  c0.09,0,0.18-0.01,0.27-0.04l1.29-0.32c0.25-0.06,0.47-0.2,0.64-0.39l1.72-2.03c0.04-0.03,0.06-0.07,0.09-0.11l0.61-0.96l0.81-1.06
                  c0.03-0.03,0.05-0.06,0.07-0.09l0.7-1.11c0.04-0.07,0.08-0.15,0.11-0.22l0.45-1.17c0.06-0.17,0.09-0.36,0.08-0.54l-0.21-2.45
                  l0.22-0.94l0.97-0.61l0.98-0.7l0.88-0.48l1.17-0.29l0.63-0.06l0.58,0.61c0.07,0.08,0.15,0.15,0.25,0.21l1.39,0.87
                  c0.08,0.05,0.18,0.1,0.28,0.13l1.36,0.44c0.06,0.02,0.13,0.04,0.2,0.05l1.3,0.19c0.09,0.01,0.18,0.01,0.27,0.01l1.26-0.1l1.03,0.5
                  c0.11,0.05,0.22,0.09,0.33,0.11l1.28,0.23c0.35,0.06,0.72-0.03,1-0.26l0.63-0.5l1.03,0.01l1.29-0.01c0.24,0,0.48-0.07,0.68-0.21
                  l0.95-0.63l1.26-0.26l1.19-0.14l2.48,0.05c0.06-0.01,0.13,0,0.2-0.01l1.25-0.19l1.16-0.05c0.11-0.01,0.23-0.03,0.34-0.06l1.21-0.4
                  l1.01-0.09l0.69,0.48l0.81,0.8c0.36,0.36,0.9,0.46,1.37,0.26l1.17-0.5c0.25-0.11,0.45-0.28,0.59-0.51l0.59-1.01l0.37-0.35l0.52,0.07
                  l2.48,0.9c0.23,0.09,0.47,0.1,0.71,0.05l1.22-0.28c0.29-0.07,0.55-0.24,0.73-0.48l0.46-0.63l2.08-0.42c0.07-0.01,0.14-0.03,0.2-0.06
                  l1.07-0.39l1.11-0.2c0.2-0.04,0.39-0.12,0.55-0.24l0.85-0.67l0.93-0.34l1.61,0.04l1.24,0.47c0.36,0.13,0.76,0.1,1.08-0.1l1.1-0.66
                  c0.29-0.17,0.5-0.45,0.58-0.78l0.07-0.32l0.5-0.03c0.08-0.01,0.15-0.02,0.22-0.04l0.84-0.2l0.64,0.28l0.6,0.47l0.36,0.77l0.07,0.99
                  c0.04,0.49,0.36,0.92,0.82,1.08l1.09,0.4l0.7,0.3l0.19,0.37l-0.17,0.94c-0.02,0.13-0.03,0.26-0.01,0.4l0.25,1.74l0.24,1.18
                  l0.05,0.83l-0.41,0.73l-0.74,0.79l-0.95,0.91l-0.95,0.83c-0.38,0.33-0.53,0.86-0.37,1.33c0.16,0.48,0.59,0.82,1.09,0.86l1.44,0.11
                  c0.32,0.02,0.61-0.07,0.86-0.25l0.87-0.67l0.85,0.13c0.16,0.02,0.33,0.01,0.49-0.03l2.81-0.7l1.09-0.32l0.89,0.12l1.47,0.36
                  c0.21,0.06,0.43,0.05,0.63-0.01l1.48-0.41c0.14-0.03,0.27-0.1,0.39-0.18l1.8-1.27l1.01-0.76c0.09-0.07,0.17-0.15,0.24-0.24l0.9-1.18
                  c0.06-0.08,0.12-0.17,0.16-0.26l0.55-1.26c0.04-0.11,0.07-0.23,0.09-0.34l0.18-1.43c0.01-0.07,0.01-0.13,0.01-0.2l-0.03-1.01
                  l1.45-2.44l0.56-1.14l1.82-2.09l0.86-0.65l1.39-0.18l0.31-0.02l0.29,0.64c0.02,0.05,0.04,0.09,0.06,0.13l1.49,2.52
                  c0.08,0.14,0.19,0.26,0.32,0.36l1.35,1.04c0.22,0.17,0.5,0.28,0.79,0.26l1.61-0.03l1.01,0.42l1.16,0.61l0.58,0.75l0.04,0.94
                  c0.01,0.28,0.12,0.55,0.31,0.76l1.63,1.85l0.49,1.09c0.07,0.16,0.17,0.29,0.29,0.41l0.74,0.68l0.33,0.93l0.01,1.31
                  c0,0.17,0.04,0.34,0.1,0.49l0.47,1.07l0.05,0.77l-0.56,1.18l-4.16,5.46c-0.14,0.19-0.23,0.41-0.25,0.65l-0.15,1.85
                  c0,0.09,0,0.17,0.01,0.26l0.35,2.88l-0.01,1.28c0,0.26,0.08,0.52,0.23,0.74l0.8,1.12c0.04,0.05,0.09,0.1,0.13,0.15l1.25,1.25
                  l0.24,1.05l-0.03,0.69l-0.59,0.79l-0.86,0.81c-0.28,0.25-0.42,0.62-0.4,0.99c0.03,0.38,0.22,0.72,0.53,0.94l1.03,0.73
                  c0.24,0.17,0.54,0.25,0.85,0.22l1.38-0.14c0.12-0.01,0.23-0.04,0.35-0.08l0.74-0.31l0.4,0.11l0.32,0.45l-0.01,0.97
                  c0,0.3,0.1,0.59,0.3,0.82l0.84,0.98c0.18,0.21,0.41,0.35,0.67,0.41l1.26,0.29l0.46,0.15l0.07,0.54c0.04,0.34,0.22,0.64,0.49,0.85
                  l1.22,0.92l0.18,0.93c0.04,0.18,0.12,0.35,0.23,0.49l0.75,1.02l0.73,1.12c0.14,0.22,0.35,0.39,0.6,0.48l1.92,0.75
                  c0.12,0.05,0.25,0.08,0.38,0.09l1.37,0.08c0.15,0.01,0.31-0.01,0.47-0.06l0.89-0.31l-0.02,0.04c-0.06,0.11-0.1,0.23-0.13,0.36
                  l-0.32,1.62l-0.21,1.42c-0.02,0.12-0.01,0.25,0.01,0.38l0.21,1.28c0.09,0.55,0.53,0.97,1.08,1.04l1.59,0.19
                  c0.18,0.02,0.35,0.01,0.52-0.05l1.06-0.33l0.49-0.01l2,3.42c0.02,0.04,0.04,0.07,0.06,0.1l0.77,1.1l0.64,1.01l0.22,1.21
                  c0.04,0.22,0.14,0.43,0.29,0.6l0.89,1.02c0.05,0.06,0.11,0.11,0.17,0.16l1.04,0.82l1.26,0.89L692.52,352.49z"/>
                <title>Banská Bystrica</title>
              </Link>

              {/*Zvolen - banskobystricky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Zvolen")}>
                <path style={{ fill: crimesInRegionBanskobystricky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionBanskobystricky[6].crimePercent)] ? crimesInRegionBanskobystricky[6].color : "#D3D3D3" }}
                  d="M690.11,378.93l-1.28,0.07c-0.29,0.02-0.56,0.13-0.77,0.32l-0.85,0.77l-1.05,0.63l-1.2,0.6
                  c-0.21,0.11-0.38,0.27-0.51,0.47l-0.55,0.9l-0.99,0.46c-0.07,0.03-0.14,0.07-0.21,0.12l-1.12,0.82c-0.09,0.07-0.17,0.14-0.24,0.23
                  l-1.71,2.11l-0.86,0.6c-0.33,0.23-0.53,0.61-0.54,1.01l-0.01,0.84l-0.53,0.47c-0.08,0.08-0.15,0.16-0.21,0.25l-0.7,1.09
                  c-0.03,0.05-0.06,0.09-0.08,0.14l-0.55,1.17l-0.68,1.03c-0.05,0.08-0.09,0.17-0.13,0.26l-0.44,1.23l-0.32,0.8l-0.78,0.53
                  c-0.23,0.15-0.41,0.38-0.49,0.65l-0.19,0.6l-0.32,0.08l-1.26-0.13l-0.01-0.3c-0.02-0.32-0.16-0.62-0.39-0.84l-0.83-0.79l-0.64-0.92
                  c-0.35-0.52-1.02-0.7-1.58-0.42l-0.69,0.34l-0.52-0.15l-0.78-0.63c-0.15-0.12-0.31-0.2-0.49-0.24l-1.29-0.32
                  c-0.04-0.01-0.09-0.02-0.13-0.03l-1.27-0.17c-0.32-0.04-0.64,0.04-0.89,0.22l-2.08,1.43c-0.18,0.13-0.33,0.3-0.43,0.5l-0.23,0.5
                  l-0.62,0.03c-0.16,0.01-0.32,0.05-0.47,0.12l-1.18,0.56c-0.1,0.05-0.2,0.12-0.29,0.2l-1.89,1.72l-0.56,0.41l-0.49-0.19
                  c-0.19-0.07-0.39-0.09-0.59-0.07l-1.23,0.14l-1.4,0.06l-1.19-0.25c-0.58-0.12-1.17,0.18-1.41,0.72l-0.56,1.28
                  c-0.13,0.3-0.13,0.64-0.02,0.95l0.01,0.03l-0.64,0.26c-0.27,0.11-0.5,0.32-0.64,0.58l-1.54,2.98c-0.3,0.58-0.1,1.28,0.44,1.63
                  l1.3,0.82c0.07,0.04,0.13,0.07,0.2,0.1l1.13,0.45l0.67,0.57l0.66,0.9c0.26,0.35,0.69,0.55,1.13,0.5l0.85-0.08l0.45,0.47
                  c0.11,0.11,0.24,0.2,0.39,0.27l1.43,0.64l1.23,0.45l1.97,0.98l0.15,0.17l-1.11,0.95c-0.32,0.26-0.49,0.67-0.44,1.09l0.13,1.25
                  c0,0.04,0.01,0.09,0.02,0.13l-1.26-0.29l-1.18-0.38c-0.12-0.04-0.24-0.06-0.37-0.06l-1.48-0.03c-0.23,0-0.46,0.06-0.66,0.18
                  l-0.28,0.16l-0.12-0.11l-0.42-0.86l-0.15-1.03c-0.01-0.08-0.03-0.15-0.06-0.22l-0.45-1.33l-0.64-1.52c-0.14-0.33-0.42-0.59-0.76-0.7
                  l-1.47-0.48c-0.2-0.06-0.41-0.08-0.61-0.04l-1.48,0.26l-2.54-0.39l-1.4-0.14c-0.43-0.04-0.86,0.14-1.12,0.49s-0.32,0.81-0.16,1.22
                  l0.2,0.49l-0.36,0.44c-0.13,0.16-0.22,0.34-0.26,0.54l-0.28,1.32l-0.2,0.62l-0.69,0.31c-0.26,0.11-0.48,0.31-0.61,0.57l-0.6,1.16
                  c-0.16,0.31-0.18,0.67-0.06,1l0.15,0.41l-0.96,0.93c-0.23,0.23-0.37,0.55-0.37,0.88l-0.01,1.73c0,0.35,0.14,0.68,0.39,0.92
                  c0.04,0.04,0.09,0.08,0.14,0.11c0,0.01,0,0.01,0,0.01l0.09,1.43c0.02,0.29,0.14,0.57,0.35,0.79l0.23,0.24l-2.12,2.37
                  c-0.32,0.35-0.41,0.85-0.23,1.29l0.54,1.38c0.07,0.19,0.2,0.36,0.36,0.5l0.88,0.74l0.79,1.02l0.93,2.02l-0.03,0.81l-0.32,1.29
                  l-0.52,1.05l-0.81,0.87c-0.1,0.11-0.18,0.23-0.23,0.37l-0.17-0.27c-0.24-0.39-0.67-0.62-1.15-0.59l-1.14,0.08l-0.89-0.43
                  c-0.34-0.17-0.74-0.17-1.08-0.01c-0.35,0.16-0.6,0.47-0.69,0.84l-0.29,1.24c-0.05,0.18-0.05,0.37-0.01,0.55l0.01,0.07l-1.17-0.41
                  c-0.08-0.02-0.16-0.04-0.23-0.05l-1.32-0.21c-0.54-0.09-1.07,0.18-1.31,0.66l-0.59,1.16c-0.05,0.1-0.09,0.21-0.11,0.31l-0.19,0.92
                  l-0.68,0.64c-0.2,0.19-0.33,0.45-0.37,0.73l-0.17,1.25c-0.01,0.07-0.01,0.15-0.01,0.22l0.05,1.24c0.01,0.21,0.07,0.42,0.18,0.6
                  l0.65,1.07c0.11,0.18,0.27,0.34,0.46,0.44l0.85,0.48l0.47,0.68l0.19,1.09c0.03,0.19,0.11,0.37,0.22,0.53l0.72,0.99
                  c0.05,0.07,0.12,0.14,0.19,0.2l0.94,0.82c0.17,0.16,0.39,0.26,0.62,0.29l2.66,0.38c0.15,0.02,0.3,0.01,0.45-0.03l0.94-0.23
                  l0.71,0.24l0.46,0.45l0.05,0.64l-0.27,0.85l-0.5,1.01c-0.05,0.11-0.09,0.23-0.11,0.35l-0.23,1.37c-0.01,0.04-0.01,0.08-0.02,0.12
                  l-0.09,1.23c0,0.08,0,0.16,0.01,0.24l0.14,1.17l0.12,1.61c0.04,0.55,0.43,1,0.97,1.13l1.57,0.37l1.41,0.68
                  c0.29,0.14,0.63,0.16,0.94,0.06l0.61-0.21l0.34,0.6c0.15,0.26,0.39,0.46,0.67,0.56l2.55,0.89c0.28,0.1,0.59,0.09,0.87-0.02
                  l0.86-0.34l1.03,0.12c0.08,0.01,0.15,0.01,0.23,0.01l0.54-0.04l0.39,0.63c0.09,0.15,0.21,0.28,0.36,0.38l1,0.68l0.34,0.99
                  c0.08,0.23,0.22,0.42,0.4,0.57l1.04,0.82l0.38,0.93c0.07,0.15,0.16,0.28,0.27,0.4l0.65,0.65l0.2,0.91c0.01,0.04,0.02,0.08,0.03,0.12
                  l0.39,1.19c0.05,0.14,0.11,0.26,0.2,0.38l0.64,0.82l0.26,0.9l-0.13,0.99c-0.01,0.09-0.01,0.18,0,0.27l0.13,1.41
                  c0,0.08,0.02,0.16,0.04,0.23l0.7,2.65l0.26,1.31c0.02,0.06,0.03,0.13,0.05,0.18l0.08,0.22l-0.1,1.06c-0.01,0.11-0.01,0.23,0.01,0.34
                  l0.24,1.37c0.01,0.04,0.02,0.08,0.03,0.12l0.36,1.27c0.04,0.15,0.11,0.29,0.21,0.42l0.83,1.08l1.6,1.84
                  c0.05,0.07,0.12,0.13,0.19,0.18l1.03,0.79c0.11,0.09,0.24,0.15,0.37,0.2l1.12,0.36l0.38,0.36l0.07,0.54l-0.29,0.99
                  c-0.02,0.08-0.04,0.15-0.04,0.23l-0.11,1.17l-0.75,1.47l-0.64,0.15c-0.05,0.01-0.1,0.03-0.15,0.04l-1.16,0.42
                  c-0.05,0.02-0.1,0.05-0.16,0.08l-2.2,1.22c-0.25,0.14-0.43,0.35-0.54,0.61l-0.93,2.39c-0.04,0.1-0.06,0.2-0.07,0.3l-0.17,1.53
                  l-0.15,0.92l-0.58,0.76c-0.07,0.09-0.12,0.18-0.16,0.28l-0.4,0.96l-0.39,0.43l-0.8,0.11c-0.63,0.09-1.09,0.65-1.07,1.29l0.05,1.38
                  c0,0.07,0.01,0.14,0.03,0.21l0.22,1.12l-0.16,1.28c0,0.04-0.01,0.08-0.01,0.11l-0.05,1.36v0.11l0.06,1.29
                  c0,0.05,0.01,0.11,0.02,0.16l0.27,1.49c0.01,0.04,0.02,0.08,0.03,0.12l0.39,1.39c0.02,0.08,0.05,0.16,0.09,0.24l0.66,1.27
                  c0.05,0.09,0.11,0.18,0.18,0.26l0.69,0.76l0.04,0.82c0.02,0.38,0.21,0.72,0.51,0.95l0.24,0.17c-0.3,0.06-0.58,0.23-0.77,0.48
                  l-0.34,0.45l-0.46-0.02l-1.31-0.37c-0.18-0.05-0.36-0.06-0.54-0.03l-2.28,0.34l-0.86-0.25l-0.88-0.61
                  c-0.16-0.11-0.34-0.18-0.54-0.21l-1.09-0.15l-0.83-0.39l-0.77-0.61l-0.63-0.88c-0.26-0.37-0.69-0.57-1.14-0.52l-1.3,0.13
                  c-0.1,0.01-0.19,0.02-0.28,0.05l-0.74,0.25l-0.32-0.33l-0.67-1.02c-0.1-0.15-0.23-0.28-0.39-0.38l-1.13-0.68
                  c-0.04-0.02-0.08-0.04-0.11-0.06l-0.64-0.3l-0.2-0.74c-0.07-0.24-0.21-0.46-0.41-0.63l-0.17-0.14l0.11-0.21
                  c0.15-0.3,0.18-0.63,0.09-0.95l-0.36-1.16l-0.27-1.15l0.17-1c0.02-0.13,0.03-0.27,0.01-0.4l-0.17-1.18l-0.07-1.18
                  c-0.01-0.21-0.08-0.42-0.2-0.6l-0.43-0.68l0.08-0.8c0.01-0.04,0.01-0.09,0.01-0.13v-1.29c0-0.2-0.05-0.39-0.14-0.57l-0.62-1.2
                  l-0.62-1.04l-0.46-1.13c-0.1-0.24-0.27-0.45-0.49-0.58l-0.68-0.44l-0.06-0.92c-0.01-0.08-0.02-0.15-0.04-0.23l-0.34-1.28
                  c-0.14-0.52-0.6-0.89-1.13-0.93l-0.75-0.04l-0.27-0.55c-0.1-0.2-0.26-0.37-0.45-0.5l-1.17-0.73l-0.86-1.01
                  c-0.03-0.03-0.05-0.06-0.08-0.09l-0.26-0.25l-0.18,0.18l-0.76-1.04c-0.03-0.04-0.07-0.08-0.11-0.13l-3.08-3.11
                  c-0.17-0.18-0.39-0.3-0.63-0.35l-1.17-0.26l-1.05-0.56c-0.17-0.09-0.35-0.14-0.53-0.15l-0.21-0.01l-0.03-0.54
                  c-0.02-0.26-0.12-0.52-0.29-0.72l-0.86-1.04l-0.56-1.24c-0.09-0.2-0.24-0.38-0.43-0.51l-1.19-0.83l-1.18-0.7
                  c-0.08-0.04-0.16-0.08-0.24-0.11l-1.77-0.59l-0.92-0.58c-0.21-0.13-0.44-0.18-0.68-0.19l-1.26,0.01c-0.32,0-0.63,0.13-0.86,0.35
                  l-1.02,0.98l-0.25,0.26l-0.03-0.14c-0.13-0.63-0.72-1.05-1.36-0.98l-1.22,0.14c-0.07,0.01-0.14,0.02-0.21,0.04l-1.18,0.34
                  c-0.22,0.06-0.42,0.19-0.58,0.36l-0.56,0.63l-0.49-0.12c-0.05-0.01-0.1-0.02-0.16-0.02l-1.56-0.16l-0.33-0.06l-0.02-0.32
                  c-0.01-0.1-0.03-0.21-0.07-0.31l-0.43-1.27c-0.05-0.16-0.13-0.3-0.24-0.42l-0.72-0.83l-0.58-1.05l-0.45-0.68l0.09-0.86
                  c0.01-0.19-0.01-0.37-0.07-0.54l-0.33-0.93l0.11-0.97c0.01-0.07,0.01-0.14,0.01-0.2l-0.06-1.39c-0.03-0.64-0.52-1.15-1.15-1.2
                  l-0.58-0.04l-0.25-0.53c-0.12-0.24-0.3-0.43-0.53-0.55l-1.16-0.64c-0.14-0.08-0.3-0.13-0.46-0.15l-1.27-0.14
                  c-0.23-0.03-0.47,0.01-0.67,0.11l-2.24,1.01c-0.31,0.14-0.55,0.39-0.67,0.7l-0.39,1.02l-0.39,0.19l-0.58-0.1l-0.64-0.62
                  c-0.19-0.2-0.45-0.32-0.72-0.35l-1.2-0.15l-1.02-0.2l-2.19-1.08l-1.07-0.67l-0.84-0.58l-0.45-0.67l-0.33-1.55l-0.37-1.42
                  c-0.03-0.09-0.06-0.18-0.11-0.27l-1.26-2.36c-0.19-0.37-0.56-0.61-0.97-0.65l-1.26-0.13c-0.14-0.02-0.27-0.01-0.41,0.02l-1.2,0.27
                  l-1.15,0.12c-0.13,0.01-0.26,0.04-0.39,0.1l-1.31,0.58c-0.1,0.04-0.19,0.1-0.27,0.17l-0.15,0.11v-0.28c0-0.07-0.01-0.14-0.02-0.21
                  l-0.23-1.31l-0.01-1.23c0-0.1-0.01-0.2-0.04-0.3l-0.37-1.45c-0.03-0.13-0.08-0.25-0.15-0.36l-0.66-1.05l-0.49-1.01l-0.31-0.84
                  l0.05-0.76l0.55-1.08l1.57-3.65c0.09-0.21,0.12-0.44,0.09-0.66l-0.18-1.29c-0.04-0.25-0.14-0.47-0.3-0.66l-1.06-1.2
                  c-0.11-0.12-0.24-0.21-0.38-0.29l-1.07-0.54l-0.31-0.47l-0.07-1.03c-0.01-0.11-0.03-0.23-0.07-0.33l-0.14-0.37l0.27-0.22
                  c0.08-0.06,0.14-0.13,0.2-0.2l0.8-1.03c0.07-0.1,0.14-0.21,0.18-0.33l0.47-1.25c0.04-0.11,0.07-0.23,0.08-0.34l0.13-1.71l0.22-0.81
                  l0.66-0.73c0.17-0.18,0.28-0.4,0.32-0.64l0.22-1.39c0.03-0.21,0.01-0.42-0.06-0.62l-0.42-1.18c-0.01-0.03-0.02-0.06-0.04-0.09
                  c0.52-0.22,0.83-0.77,0.75-1.33l-0.15-0.98l0.36-1c0.06-0.17,0.08-0.36,0.06-0.54l-0.09-1.03l0.28-1.03
                  c0.01-0.04,0.01-0.07,0.02-0.11l0.22-1.21c0.02-0.08,0.02-0.16,0.02-0.24l-0.03-1.57c-0.01-0.37-0.18-0.72-0.46-0.95l-0.99-0.8
                  c-0.32-0.26-0.74-0.34-1.14-0.23l-1.31,0.38c-0.05,0.01-0.1,0.03-0.15,0.05l-1.06,0.47l-1.45,0.38c-0.2,0.05-0.37,0.15-0.52,0.29
                  l-0.84,0.76l-0.52-1.57l-0.41-1.01l-0.17-1.16c-0.01-0.08-0.03-0.16-0.06-0.23l-0.41-1.19l-0.25-1.27
                  c-0.03-0.12-0.07-0.24-0.12-0.34l-0.62-1.18c-0.01-0.01-0.01-0.02-0.03-0.03v-0.01c0.02-0.03,0.03-0.06,0.04-0.09l0.25-0.86
                  l0.78-0.67c0.11-0.09,0.2-0.2,0.27-0.31l0.67-1.15l0.27-0.27l0.33,0.14c0.08,0.03,0.16,0.06,0.24,0.07l1.21,0.25
                  c0.4,0.08,0.81-0.03,1.11-0.31l0.89-0.84c0.17-0.16,0.29-0.37,0.35-0.6l0.24-0.94l0.63-0.77c0.03-0.04,0.06-0.08,0.09-0.12
                  l0.54-0.87l0.81-0.62c0.07-0.05,0.13-0.11,0.19-0.17l0.81-0.94c0.15-0.18,0.25-0.39,0.28-0.62l0.2-1.22
                  c0.04-0.23,0.01-0.47-0.08-0.69l-0.49-1.15c-0.08-0.18-0.19-0.34-0.34-0.46l-0.94-0.8c-0.11-0.09-0.23-0.17-0.36-0.22l-1.09-0.42
                  l-1.02-0.6l-0.84-1.16c-0.05-0.07-0.1-0.14-0.17-0.19l-0.83-0.77l-0.26-1.06l-0.44-1.5l-0.03-0.93l0.34-1.09
                  c0.04-0.11,0.06-0.23,0.06-0.35l0.03-1.5l0.08-1.23l0.26-1.35l0.19-1.35l0.54-0.85c0.02-0.03,0.03-0.06,0.05-0.09l0.68-1.31
                  c0.03-0.07,0.06-0.13,0.08-0.19l0.46-1.42c0.1-0.31,0.08-0.65-0.07-0.94l-0.56-1.14c-0.06-0.12-0.13-0.23-0.22-0.32l-0.76-0.78
                  l-0.29-0.58l0.16-0.9c0.01-0.08,0.02-0.15,0.02-0.23l-0.01-0.84l0.52-0.73c0.04-0.06,0.08-0.12,0.11-0.19l0.59-1.25l0.59-0.82
                  l0.72-0.71l1-0.52c0.21-0.11,0.39-0.28,0.51-0.49l0.72-1.27l0.39-0.54l0.67-0.12l1.11,0.04c0.34,0.02,0.65-0.11,0.89-0.33l1.02-0.94
                  c0.15-0.14,0.27-0.33,0.34-0.53l0.33-1.01l0.42-0.42l0.51-0.06l1,0.35l0.81,0.83c0.03,0.03,0.07,0.06,0.1,0.09l1.02,0.82l0.98,0.96
                  c0.08,0.08,0.17,0.15,0.27,0.2l1.16,0.65l0.98,0.75c0.1,0.08,0.21,0.14,0.33,0.19l0.98,0.35l1.33,1.97l0.57,1.04
                  c0.22,0.39,0.61,0.63,1.05,0.65l0.86,0.03l0.66,0.49l0.78,0.77l0.72,0.96l0.74,0.89l0.55,0.97c0.06,0.1,0.13,0.19,0.21,0.27
                  l0.89,0.88c0.15,0.14,0.33,0.25,0.52,0.31l1.17,0.35c0.14,0.04,0.28,0.06,0.43,0.05l1.33-0.07c0.07-0.01,0.14-0.02,0.22-0.03
                  l0.67-0.16l0.55,0.36l0.73,0.67l0.51,1.12c0.06,0.12,0.13,0.23,0.22,0.33l0.94,1c0.3,0.33,0.75,0.47,1.18,0.37s0.78-0.41,0.92-0.83
                  l0.42-1.29c0.03-0.11,0.05-0.22,0.06-0.34l0.06-1.43l0.52,0.5l0.19,0.84l0.04,1.12l-0.02,0.93l-0.52,0.56
                  c-0.38,0.4-0.45,0.99-0.18,1.47c0.28,0.48,0.83,0.72,1.36,0.6l1.35-0.3c0.18-0.04,0.34-0.11,0.48-0.22l1.06-0.8
                  c0.37-0.28,0.55-0.72,0.49-1.17l-0.1-0.69l0.1-0.08l0.78,0.01h0.12l2.87-0.22c0.1-0.01,0.19-0.03,0.29-0.06l1.26-0.4
                  c0.1-0.03,0.19-0.07,0.28-0.13l1.16-0.72c0.14-0.09,0.26-0.2,0.36-0.34l1.39-2.01l1.2-1.19l0.37,0.15c0.31,0.13,0.67,0.12,0.98-0.02
                  s0.55-0.41,0.66-0.73l0.36-1.03l0.67-0.84l0.31-0.17l0.16,0.07l0.36,0.71c0.02,0.04,0.04,0.08,0.07,0.12l0.7,1.05
                  c0.24,0.37,0.66,0.57,1.09,0.56l1.14-0.05l0.93,0.15l0.69,0.45l0.38,0.43l-0.02,0.88c0,0.1,0.01,0.21,0.03,0.3l0.21,0.94l-0.4,0.91
                  l-0.6,1.15c-0.03,0.05-0.05,0.11-0.07,0.16l-0.43,1.21l-0.52,1.12c-0.12,0.26-0.15,0.55-0.08,0.83l0.31,1.25
                  c0.02,0.08,0.04,0.16,0.08,0.23l0.61,1.3c0.07,0.16,0.17,0.29,0.3,0.41l0.96,0.85c0.26,0.22,0.59,0.34,0.94,0.31
                  c0.34-0.04,0.66-0.21,0.87-0.48l0.78-0.99c0.09-0.11,0.16-0.25,0.21-0.39l0.24-0.75l0.44-0.29l1.04-0.17l1.55-0.08l1.54-0.01
                  c0.36,0,0.7-0.16,0.93-0.43l0.69-0.79l1-0.54c0.12-0.06,0.22-0.13,0.31-0.23l0.97-0.98l0.74-0.82l1.08-0.77l0.98-0.76
                  c0.09-0.08,0.18-0.17,0.25-0.27l0.09-0.12l0.49,0.73c0.08,0.12,0.17,0.22,0.29,0.31l1.32,1c0.13,0.1,0.28,0.17,0.44,0.21l1.21,0.31
                  c0.13,0.03,0.26,0.05,0.4,0.04l1.23-0.09c0.33-0.03,0.63-0.18,0.85-0.43l0.73-0.82l0.88-0.52c0.1-0.06,0.19-0.13,0.27-0.21
                  l0.92-0.96l1.07-0.8c0.07-0.05,0.13-0.1,0.19-0.17l0.7-0.77l0.88-0.39l1.05-0.26l2.38-0.43l1.29-0.12c0.06,0,0.12-0.01,0.19-0.03
                  l1.27-0.32c0.13-0.03,0.25-0.08,0.36-0.16l1.11-0.7l0.99-0.51l1.2-0.5l1.14-0.36l1.06-0.22l0.9,0.05l2.2,0.87
                  c0.08,0.04,0.16,0.06,0.24,0.07l1.3,0.22l1.38,0.42l1.19,0.46c0.1,0.04,0.21,0.06,0.32,0.07l1.11,0.12l0.95,0.44
                  c0.3,0.13,0.64,0.15,0.95,0.03l1.33-0.49l0.88-0.07l1.04,0.21c0.32,0.07,0.65,0.01,0.92-0.17l1.06-0.68
                  c0.27-0.17,0.46-0.44,0.54-0.75l0.27-1.05l0.24-0.34l1.88-0.05c0.05,0,0.11-0.01,0.16-0.02l1.21-0.2c0.09-0.01,0.17-0.03,0.26-0.07
                  l0.63-0.25l0.67,0.33c0.25,0.12,0.53,0.16,0.8,0.1l1.2-0.25c0.06-0.01,0.12-0.02,0.18-0.05l1.21-0.44c0.18-0.06,0.35-0.18,0.49-0.32
                  l2.11-2.23l0.61,0.4l0.49,0.9c0.12,0.22,0.31,0.41,0.54,0.52l0.64,0.31L690.11,378.93z"/>
                <title>Zvolen</title>
              </Link>

              {/*Turcianske teplice - zilinsky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Turčianske Teplice")}>
                <path
                  d="M567.77,293.9c-0.04,0.14-0.05,0.29-0.03,0.43l0.2,2.31l-0.3,0.8l-0.61,0.96l-0.83,1.09l-0.63,0.98l-1.37,1.62
                  l-0.78,0.19l-1.16,0.03c-0.26,0.01-0.52,0.1-0.73,0.26l-1.06,0.81c-0.25,0.19-0.42,0.47-0.47,0.78l-0.21,1.22
                  c-0.07,0.39,0.06,0.8,0.34,1.08l0.68,0.71l0.39,0.95l0.32,1.13c0.03,0.11,0.07,0.22,0.13,0.31l0.48,0.79l0.01,0.91l-0.01,1.27v0.11
                  l0.1,1.18l-0.11,0.8l-0.44,0.66l-0.78,0.33c-0.36,0.15-0.63,0.46-0.73,0.83l-0.35,1.33l-0.4,1.14c-0.03,0.1-0.05,0.2-0.06,0.3
                  l-0.13,1.35c-0.02,0.13-0.01,0.25,0.02,0.38l0.27,1.28c0.06,0.28,0.21,0.54,0.44,0.72l1,0.79l0.92,0.89
                  c0.07,0.06,0.13,0.11,0.2,0.16l1.13,0.71l0.96,0.52l0.66,0.77c0.09,0.11,0.2,0.2,0.32,0.27l0.36,0.21l-0.34,1.83
                  c-0.01,0.06-0.02,0.12-0.02,0.19l-0.06,1.18l-0.8,2.51c-0.05,0.17-0.07,0.35-0.05,0.53l0.15,1.27l-0.01,1l-0.29,0.94l-0.68,0.61
                  c-0.15,0.14-0.27,0.32-0.34,0.52l-0.8,2.34l-0.51,0.97l-0.47,0.68l-0.64,0.04c-0.39,0.03-0.73,0.23-0.95,0.55l-0.43,0.65l-0.35,0.05
                  l-0.7-0.4c-0.07-0.04-0.14-0.07-0.22-0.09l-1.26-0.43c-0.17-0.06-0.35-0.08-0.53-0.06l-1.28,0.13c-0.11,0.01-0.22,0.04-0.33,0.08
                  l-0.89,0.35l-0.82-0.1l-1.2-0.32c-0.05-0.01-0.11-0.02-0.16-0.03l-1.24-0.16c-0.06-0.01-0.13-0.01-0.19-0.01l-1.25,0.03
                  c-0.04,0-0.07,0-0.11,0.01l-1.34,0.15c-0.26,0.03-0.51,0.14-0.7,0.31l-0.67,0.61l-0.89,0.11c-0.41,0.05-0.76,0.28-0.96,0.64
                  l-0.25,0.46h-0.47l-1.96-0.26l-0.39-0.42l-0.23-0.91c-0.08-0.32-0.28-0.59-0.57-0.76l-1.1-0.66c-0.16-0.1-0.34-0.16-0.53-0.17
                  l-1.24-0.11l-1.29-0.22c0-0.17-0.02-0.34-0.09-0.5l-0.49-1.23c-0.07-0.17-0.17-0.33-0.31-0.45l-0.8-0.75l-0.5-0.87l-0.18-1.06
                  c-0.03-0.18-0.1-0.35-0.2-0.5l-0.89-1.29c-0.28-0.4-0.77-0.61-1.25-0.52l-1.28,0.23c-0.13,0.02-0.26,0.07-0.38,0.13l-0.88,0.49
                  l-0.95,0.05c-0.34,0.02-0.66,0.18-0.88,0.44l-0.62,0.72l-0.96,0.29c-0.17,0.05-0.32,0.14-0.45,0.24l-1.02,0.86
                  c-0.19,0.17-0.32,0.38-0.39,0.62l-0.23,0.79l-0.67,0.41c-0.17,0.1-0.32,0.24-0.42,0.41l-1.23,1.91l-0.73,0.42l-1.15,0.43
                  c-0.08,0.02-0.15,0.06-0.23,0.11l-0.62,0.38l0.01-0.1c0-0.06,0-0.13-0.01-0.19l-0.12-1.24c-0.02-0.24-0.11-0.47-0.26-0.66
                  l-0.94-1.18c-0.11-0.14-0.25-0.25-0.41-0.33l-0.86-0.44l-0.44-0.76c-0.02-0.03-0.04-0.06-0.05-0.09l-0.66-0.93l-0.3-0.93l-0.17-1.33
                  c-0.03-0.26-0.15-0.5-0.32-0.69l-4.48-4.98c-0.04-0.04-0.08-0.08-0.12-0.11l-0.98-0.83c-0.04-0.04-0.08-0.07-0.12-0.09l-0.98-0.65
                  l-0.82-0.72l-0.57-0.66l-0.03-0.74l0.7-2.45c0.06-0.21,0.07-0.44,0.01-0.66l-0.31-1.19c-0.12-0.44-0.46-0.78-0.91-0.89l-0.82-0.21
                  l-0.07-0.07l0.99-0.45c0.52-0.21,0.84-0.74,0.77-1.3c-0.06-0.57-0.5-1.01-1.05-1.1l-1.21-0.18l-1.21-0.23l-1.22-0.43l-0.73-0.72
                  c-0.18-0.17-0.41-0.29-0.65-0.34l-0.64-0.12l-0.01-0.04l0.28-0.58c0.16-0.31,0.17-0.68,0.05-1l-0.49-1.26
                  c-0.14-0.36-0.43-0.63-0.8-0.74l-1.2-0.38l-0.75-0.39l-0.6-0.75c-0.28-0.37-0.75-0.54-1.21-0.46l-1.11,0.21l-0.79-0.14l-0.46-0.38
                  l-0.35-0.95c-0.07-0.2-0.19-0.38-0.36-0.52l-1.02-0.87c-0.11-0.1-0.25-0.18-0.39-0.23l-1.17-0.42l-0.99-0.64l-1.03-0.76
                  c-0.05-0.03-0.1-0.07-0.16-0.1l-1.04-0.54l-0.19-0.16l0.18-0.54c0.06-0.19,0.08-0.39,0.05-0.58l-0.18-1.15l-0.03-1.07l0.12-1.21
                  l0.06-1.24v-0.14l-0.08-1.19l0.12-1.22l0.04-1.58c0.01-0.23-0.05-0.46-0.17-0.67l-0.61-1.03l-0.2-0.52l0.3-0.32
                  c0.05-0.05,0.1-0.1,0.14-0.16l0.72-1.05c0.06-0.09,0.11-0.19,0.15-0.29l0.4-1.11l1.04-2.39c0.06-0.11,0.09-0.24,0.1-0.37l0.15-1.38
                  c0.04-0.36-0.08-0.73-0.33-0.99l-0.87-0.91l-0.53-0.98c-0.02-0.03-0.04-0.06-0.05-0.08l-0.85-1.3c-0.09-0.14-0.2-0.25-0.34-0.35
                  l-0.72-0.5l-0.05-0.26l0.4-0.66c0.05-0.07,0.08-0.14,0.11-0.22l0.48-1.26c0.13-0.33,0.1-0.7-0.06-1.01l-0.42-0.82l0.08-0.93
                  c0-0.01,0-0.02,0-0.02c0.34,0.18,0.75,0.19,1.1,0.02c0.39-0.17,0.65-0.53,0.72-0.94c0.44,0.04,0.89-0.17,1.15-0.56l1.47-2.2
                  l0.83-0.98c0.05-0.06,0.09-0.12,0.13-0.18l0.14,0.48l1.38-0.4c0.4-0.12,0.71-0.42,0.84-0.82l0.27-0.83l1.19-0.7
                  c0.12-0.07,0.23-0.16,0.32-0.26l0.85-0.99l0.9-0.86l0.92-0.71l1.18-0.39c0.23-0.08,0.44-0.22,0.59-0.41l0.72-0.91l1.28-0.74
                  c0.07-0.03,0.12-0.07,0.18-0.12l1.07-0.89c0.29-0.24,0.46-0.6,0.45-0.98l-0.02-0.8c0.06,0.01,0.11,0.02,0.16,0.03l1.26,0.11
                  l0.7,0.59c0.07,0.06,0.14,0.11,0.22,0.15l1.12,0.6c0.13,0.07,0.27,0.12,0.42,0.14l0.76,0.1l0.5,0.59c0.12,0.14,0.28,0.25,0.45,0.32
                  l0.92,0.41l0.67,0.76c0.12,0.14,0.26,0.24,0.42,0.31l1.64,0.76l1.24,0.49l1.13,0.37l1.01,0.51c0.42,0.21,0.92,0.18,1.3-0.09
                  l0.65-0.47l0.9,0.06c0.16,0.01,0.32-0.01,0.48-0.06c0.14,0.45,0.52,0.78,1,0.85l0.78,0.13l0.45,0.58c0.28,0.36,0.73,0.54,1.19,0.47
                  l1.25-0.2c0.04,0,0.08-0.01,0.12-0.02l0.18,0.37c0.17,0.37,0.51,0.63,0.91,0.7l0.72,0.13l0.28,0.55c0.14,0.26,0.36,0.47,0.64,0.58
                  l1.17,0.48c0.14,0.06,0.3,0.09,0.46,0.09l1.24,0.01l1.36,0.26l1.74,0.62c0.15,0.05,0.3,0.08,0.45,0.07l1.14-0.03l0.86,0.24
                  l0.71,0.56l0.9,0.97l1.35,1.27l1.02,0.9c0.04,0.03,0.08,0.06,0.12,0.09l1.41,0.97c0.06,0.04,0.12,0.07,0.18,0.1l1.36,0.64
                  c0.12,0.06,0.25,0.1,0.38,0.11l2.57,0.33l0.56,0.64c0.06,0.07,0.13,0.13,0.2,0.19l0.88,0.64l1.38,2.16l1.08,2.12l0.28,0.94
                  l0.08,1.11c0.02,0.17,0.06,0.34,0.14,0.49l0.58,1.1c0.14,0.26,0.35,0.46,0.62,0.57l0.8,0.34l0.37,0.65l0.48,1.13
                  c0.11,0.27,0.32,0.49,0.58,0.62l0.65,0.33l0.26,0.75c0.07,0.21,0.2,0.4,0.36,0.55l0.85,0.72l0.72,0.94
                  c0.06,0.09,0.14,0.16,0.22,0.23l0.98,0.76c0.25,0.19,0.55,0.28,0.86,0.26l1.28-0.09c0.07-0.01,0.13-0.02,0.2-0.03l0.89-0.22
                  l0.85,0.32c0.14,0.05,0.29,0.08,0.44,0.08c0.1,0,0.21-0.01,0.31-0.04l1.14-0.29l1.21-0.17c0.24-0.04,0.46-0.14,0.64-0.29l0.97-0.83
                  c0.05-0.04,0.09-0.08,0.12-0.12l0.79-0.88l0.72-0.51l1.03-0.23l0.08-0.01l0.1,0.45c0.05,0.22,0.15,0.42,0.3,0.59l0.26,0.28
                  c-0.19,0.16-0.32,0.38-0.37,0.62L567.77,293.9z"/>
                <title>Turčianske Teplice</title>
              </Link>


              {/*Ziar nad hronom - banskobystricky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Žiar nad Hronom")}>
                <path style={{ fill: crimesInRegionBanskobystricky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionBanskobystricky[7].crimePercent)] ? crimesInRegionBanskobystricky[7].color : "#D3D3D3" }}
                  d="M569.72,368.44l-0.7,0.69c-0.14,0.14-0.24,0.31-0.31,0.5l-0.33,0.99l-0.4,0.37l-0.73-0.02
                  c-0.08,0-0.17,0-0.25,0.02l-1.29,0.22c-0.32,0.05-0.61,0.23-0.8,0.5l-0.73,1.01c-0.03,0.03-0.05,0.07-0.08,0.11l-0.57,1.01
                  l-0.84,0.44c-0.11,0.06-0.21,0.13-0.29,0.22l-0.93,0.91c-0.06,0.05-0.1,0.1-0.14,0.16l-0.72,1c-0.05,0.06-0.09,0.13-0.12,0.2
                  l-0.59,1.26l-0.69,0.97c-0.15,0.21-0.23,0.47-0.23,0.73l0.01,1.13l-0.21,1.19c-0.05,0.27-0.01,0.54,0.11,0.78l0.56,1.12
                  c0.06,0.11,0.13,0.22,0.22,0.31l0.76,0.78l0.24,0.51l-0.27,0.84l-0.61,1.17l-0.65,1.03c-0.09,0.15-0.15,0.32-0.18,0.49l-0.22,1.56
                  l-0.26,1.37c-0.01,0.05-0.01,0.09-0.02,0.14l-0.09,1.37l-0.03,1.39l-0.34,1.11c-0.04,0.13-0.06,0.26-0.06,0.4l0.03,1.3
                  c0,0.11,0.02,0.22,0.05,0.32l0.47,1.6l0.33,1.38c0.06,0.24,0.19,0.46,0.38,0.63l1.02,0.94l0.92,1.26c0.1,0.14,0.22,0.26,0.37,0.34
                  l1.33,0.79c0.06,0.04,0.12,0.07,0.19,0.1l0.99,0.38l0.56,0.47l0.23,0.55l-0.09,0.51l-0.49,0.58l-0.89,0.68
                  c-0.12,0.09-0.22,0.2-0.3,0.32l-0.62,0.99l-0.75,0.93c-0.11,0.14-0.2,0.3-0.24,0.48l-0.22,0.85l-0.15,0.14l-0.46-0.09l-1.01-0.43
                  c-0.48-0.21-1.03-0.09-1.39,0.28l-0.96,1c-0.07,0.07-0.13,0.15-0.18,0.23l-0.64,1.1l-0.9,0.77c-0.19,0.16-0.32,0.36-0.39,0.6
                  l-0.29,1l-0.57,0.75l-0.52,0.43l-0.87-0.04l-1.01-0.22l-0.48-0.41l-0.12-0.74l-0.04-1.28c-0.02-0.55-0.4-1.03-0.93-1.17
                  s-1.09,0.09-1.38,0.55l-0.17-0.11c-0.32-0.22-0.73-0.28-1.11-0.15l-1.36,0.47c-0.14,0.04-0.26,0.11-0.37,0.2l-1.09,0.86
                  c-0.24,0.18-0.4,0.45-0.46,0.74l-0.16,0.85l-0.72,0.6c-0.03,0.03-0.06,0.05-0.08,0.08l-1.8,1.74c-0.24,0.22-0.38,0.53-0.4,0.85
                  l-0.04,0.87l-0.53,0.73l-0.83,1c-0.09,0.11-0.17,0.24-0.22,0.38l-0.11,0.31l-0.32-0.06c-0.64-0.09-1.24,0.31-1.4,0.93l-0.17,0.65
                  l-0.65,0.23c-0.42,0.14-0.74,0.5-0.83,0.94l-0.55,2.92l-0.5,2.25l-0.67,1.05c-0.06,0.11-0.12,0.23-0.15,0.35l-0.28,1.08l-0.81,0.92
                  l-0.36,0.38l-0.29-0.14c-0.31-0.15-0.66-0.17-0.98-0.05l-0.93,0.34l-1.13-0.09l-1.15-0.01l-1.25-0.1c-0.36-0.03-0.72,0.1-0.98,0.36
                  c-0.25,0.25-0.39,0.61-0.37,0.97l0.09,1.36l-0.05,0.99l-0.46,0.96l-0.76,0.83l-0.71-0.02c-0.14,0-0.28,0.02-0.41,0.06l-1.35,0.43
                  c-0.2,0.06-0.39,0.18-0.53,0.34l-0.59,0.62l-0.61-0.08c-0.31-0.04-0.63,0.03-0.89,0.22l-0.62,0.43l-0.7-0.12
                  c-0.21-0.04-0.42-0.02-0.63,0.05l-1.33,0.47c-0.09,0.03-0.17,0.07-0.25,0.13l-1.1,0.7c-0.06,0.03-0.12,0.08-0.17,0.13l-0.92,0.84
                  c-0.25,0.22-0.39,0.53-0.41,0.86l-0.02,0.5c-0.31-0.18-0.68-0.22-1.02-0.11l-1.27,0.42c-0.35,0.12-0.63,0.38-0.77,0.73l-0.4,0.99
                  l-0.1,0.11l-1.13-0.81c-0.17-0.13-0.38-0.21-0.59-0.24l-1.26-0.16c-0.1-0.01-0.19-0.01-0.29,0l-1.03,0.1l-0.81-0.32l-0.71-0.56
                  l-0.64-0.86c-0.14-0.18-0.31-0.32-0.51-0.41l-1.06-0.46l-1.06-0.63c-0.11-0.06-0.23-0.11-0.35-0.14l-1.19-0.28
                  c-0.14-0.03-0.28-0.04-0.42-0.02l-1.68,0.17c-0.11,0.01-0.22,0.03-0.32,0.07l-0.94,0.35l-1.59-0.31l-0.28-1.98l0.06-1.26
                  c0-0.1,0-0.2-0.02-0.3l-0.24-1.17l-0.07-1.53l1.49-1.39c0.37-0.33,0.51-0.85,0.36-1.33l-0.65-1.95c-0.08-0.22-0.21-0.41-0.39-0.56
                  l-1.03-0.86c-0.05-0.04-0.1-0.08-0.16-0.11l-1.16-0.7l-0.67-1.09c-0.13-0.22-0.34-0.39-0.58-0.49l-1.15-0.48
                  c-0.04-0.02-0.09-0.04-0.13-0.05l-1.18-0.34c-0.11-0.03-0.22-0.05-0.33-0.05l-0.97-0.01l-0.78-0.52c-0.12-0.08-0.26-0.14-0.41-0.18
                  l-0.82-0.18l-0.03-0.08l0.25-0.59l0.62-0.95c0.26-0.39,0.28-0.89,0.06-1.29l-0.47-0.84l-0.09-1.58l0.3-1.08l0.29-1.28
                  c0.01-0.06,0.02-0.11,0.03-0.16l0.29-2.71l0.37-1.18c0.07-0.21,0.08-0.43,0.03-0.64l-0.25-1.16l-0.04-1.16
                  c-0.01-0.36-0.19-0.7-0.47-0.93l-1.02-0.81c-0.05-0.03-0.09-0.07-0.14-0.1l-0.86-0.5l-0.52-0.74l-0.48-0.98l-0.4-1.08l-0.3-1.15
                  l-0.24-1.33c-0.02-0.12-0.06-0.23-0.11-0.34l-0.55-1.09l-0.53-1.19c-0.02-0.05-0.05-0.1-0.08-0.15l-0.52-0.85l-0.06-2.19
                  c0-0.11-0.02-0.22-0.05-0.32l-0.43-1.51c-0.06-0.21-0.17-0.4-0.33-0.56l-1.13-1.09l-0.95-0.81l-1.25-2.04l-0.29-1.1
                  c-0.04-0.14-0.1-0.28-0.18-0.39l-0.88-1.26l-0.77-1.7c-0.15-0.33-0.43-0.57-0.77-0.67l0.63-0.15l1.52,1.26
                  c0.13,0.12,0.29,0.2,0.46,0.25l3.6,1.12c0.17,0.06,0.34,0.08,0.51,0.06l1.03-0.09l0.89,0.35c0.33,0.12,0.71,0.1,1.03-0.06l0.7-0.37
                  l0.66,0.2l0.79,0.61c0.04,0.04,0.09,0.07,0.15,0.1l1.15,0.65c0.12,0.07,0.25,0.11,0.38,0.14l1.36,0.25
                  c0.13,0.02,0.25,0.02,0.37,0.01l1.22-0.14c0.54-0.06,0.97-0.46,1.08-0.98l0.31-1.46c0.04-0.17,0.04-0.34,0.01-0.51l-0.26-1.29
                  c-0.02-0.1-0.06-0.19-0.1-0.29l-0.4-0.83l0.08-0.92c0.01-0.11,0-0.22-0.02-0.32l-0.22-1.21c-0.02-0.11-0.06-0.22-0.1-0.32
                  l-0.17-0.35l2.03-1.81l0.85-0.35l1.21-0.4c0.26-0.09,0.47-0.25,0.63-0.47l0.77-1.08l0.77-0.87c0.09-0.09,0.16-0.2,0.21-0.31
                  l0.99-2.24l0.65-0.89c0.12-0.17,0.2-0.37,0.23-0.58l0.15-1.18l0.29-1.35c0.02-0.07,0.03-0.15,0.03-0.22l0.03-1.02l0.52-0.77
                  c0.2-0.31,0.26-0.7,0.16-1.05l-0.26-0.89l0.36-0.59c0.03-0.05,0.06-0.11,0.09-0.16l0.27-0.64l0.64-0.18
                  c0.29-0.08,0.54-0.27,0.71-0.52l0.58-0.89l0.51-0.3l0.71,0.1l2.42,1.01c0.4,0.17,0.87,0.12,1.22-0.15l3.13-2.2
                  c0.06-0.04,0.12-0.09,0.17-0.14l0.89-0.9c0.08-0.08,0.14-0.17,0.2-0.26l0.68-1.2c0.04-0.08,0.08-0.17,0.11-0.25l0.29-0.97l0.73-0.59
                  c0.15-0.12,0.26-0.27,0.34-0.44l0.6-1.28l0.43-1.07l0.58-0.99c0.06-0.11,0.11-0.22,0.14-0.34l0.27-1.13l0.94-1.17
                  c0.13-0.17,0.22-0.38,0.26-0.6l0.16-1.1l0.49-0.81l0.82-0.81c0.08-0.08,0.15-0.18,0.21-0.28l0.63-1.13c0.1-0.18,0.16-0.39,0.16-0.6
                  l0.01-1.15l0.07-0.86l0.49-0.5l0.87-0.53l1.14-0.42c0.07-0.02,0.14-0.05,0.2-0.09l1.07-0.63c0.17-0.1,0.3-0.23,0.41-0.38l1.27-1.96
                  l0.84-0.51c0.28-0.16,0.48-0.42,0.56-0.73l0.26-0.9l0.53-0.45l1.06-0.32c0.23-0.07,0.43-0.21,0.59-0.39l0.51-0.59l0.7-0.04
                  c0.19-0.01,0.37-0.06,0.53-0.15l0.95-0.53l0.29-0.05l0.27,0.4l0.18,1.01c0.02,0.15,0.07,0.28,0.15,0.41l0.7,1.22
                  c0.06,0.11,0.14,0.21,0.23,0.29l0.73,0.69l0.24,0.6l-0.22,0.86c-0.1,0.38-0.01,0.77,0.22,1.08c0.24,0.3,0.6,0.48,0.99,0.48h1.18
                  l1.36,0.23l1.05,0.09l0.42,0.25l0.18,0.72c0.05,0.2,0.15,0.39,0.29,0.54l0.9,0.97c0.2,0.21,0.46,0.35,0.74,0.39l2.52,0.34
                  c0.05,0.01,0.11,0.01,0.16,0.01h1.29c0.46,0,0.88-0.25,1.1-0.65l0.3-0.54l0.64-0.08c0.26-0.03,0.51-0.14,0.7-0.31l0.67-0.61
                  l0.88-0.1l1.1-0.03l1.07,0.14l1.2,0.32c0.05,0.01,0.11,0.02,0.17,0.03l1.23,0.15c0.21,0.03,0.42,0,0.61-0.08l1.02-0.4l0.83-0.09
                  l0.89,0.3l0.99,0.57c0.25,0.14,0.54,0.19,0.83,0.14l1.32-0.22c0.18-0.03,0.35-0.1,0.5-0.2l0.01,0.33l-0.03,1.24
                  c-0.01,0.37,0.15,0.72,0.42,0.97l0.67,0.59l0.22,0.67l-0.14,1.07c-0.03,0.23,0.01,0.46,0.1,0.67l0.42,0.96l0.16,1.17l0.24,1.32
                  l0.27,2.6l-0.19,1.21c-0.02,0.08-0.02,0.15-0.02,0.23l0.03,1.23c0.01,0.3,0.12,0.58,0.32,0.8l1.15,1.29
                  c0.32,0.36,0.83,0.51,1.29,0.37l0.83-0.26l0.27,0.13c-0.14,0.33-0.12,0.71,0.06,1.04l0.64,1.19l0.82,1.3l0.45,1.03l0.4,1.16
                  c0.04,0.14,0.12,0.27,0.21,0.39L569.72,368.44z"/>
                <title>Žiar nad Hronom</title>
              </Link>

              {/*Prievidza - trenciansky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Prievidza")}>
                <path style={{ fill: crimesInRegionTrenciansky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionTrenciansky[4].crimePercent)] ? crimesInRegionTrenciansky[4].color : "#D3D3D3" }}
                  d="M516.34,345.1l-0.09,1.14l-0.01,0.92l-0.39,0.7l-0.8,0.79c-0.07,0.07-0.14,0.16-0.19,0.24l-0.7,1.16
                  c-0.09,0.15-0.14,0.3-0.17,0.47l-0.15,1.01l-0.9,1.13c-0.11,0.14-0.19,0.31-0.23,0.48l-0.3,1.23l-0.53,0.91
                  c-0.03,0.05-0.05,0.1-0.08,0.15l-0.44,1.09l-0.45,0.97l-0.8,0.65c-0.19,0.16-0.33,0.37-0.4,0.6l-0.37,1.2l-0.54,0.94l-0.69,0.7
                  l-2.04,1.4l-0.47,0.35l-1.91-0.8c-0.1-0.04-0.21-0.07-0.32-0.09l-1.3-0.18c-0.28-0.04-0.56,0.02-0.8,0.16l-1.14,0.67
                  c-0.17,0.1-0.31,0.24-0.42,0.4l-0.48,0.74l-0.76,0.22c-0.36,0.1-0.66,0.36-0.81,0.7l-0.47,1.1l-0.61,0.98
                  c-0.19,0.3-0.24,0.67-0.14,1.01l0.26,0.87l-0.4,0.58c-0.13,0.2-0.2,0.43-0.21,0.66l-0.04,1.27l-0.27,1.29
                  c-0.01,0.03-0.02,0.06-0.02,0.1l-0.12,0.91l-0.55,0.75c-0.06,0.08-0.1,0.16-0.14,0.25l-0.94,2.14l-0.69,0.78
                  c-0.03,0.03-0.06,0.07-0.09,0.11l-0.57,0.8l-0.91,0.3l-1.14,0.47c-0.13,0.06-0.25,0.14-0.36,0.23l-2,1.79l-0.89,0.85
                  c-0.4,0.37-0.5,0.96-0.27,1.44l0.49,1.01l0.16,0.88l-0.09,1.09c-0.01,0.22,0.03,0.44,0.12,0.64l0.47,1l0.18,0.88l-0.07,0.33
                  l-0.14,0.02l-0.97-0.18l-0.89-0.5l-0.9-0.7c-0.12-0.09-0.25-0.16-0.4-0.21l-1.37-0.41c-0.31-0.09-0.65-0.06-0.94,0.09l-0.65,0.34
                  l-0.63-0.25c-0.18-0.06-0.37-0.09-0.56-0.08l-1.06,0.1l-3.19-0.99l-1.85-1.52c-0.3-0.24-0.69-0.32-1.06-0.24l-1.68,0.39
                  c-0.28,0.07-0.52,0.22-0.7,0.44l-0.98,1.23l-0.92,0.46c-0.14,0.07-0.26,0.16-0.37,0.27l-0.84,0.91c-0.05,0.06-0.1,0.12-0.14,0.19
                  l-1.57,2.54c-0.09,0.14-0.14,0.29-0.17,0.45l-0.19,1.1l-0.61,1l-0.81,1.01c-0.03,0.03-0.06,0.06-0.08,0.1l-0.9,1.39
                  c-0.03,0.05-0.06,0.1-0.08,0.15l-0.75,1.59l-0.69,0.73l-1.33,1.19c-0.06,0.05-0.12,0.11-0.17,0.18l-0.72,0.96l-0.97,1.1
                  c-0.04,0.05-0.08,0.1-0.11,0.15l-1.13,1.76c-0.05,0.08-0.09,0.16-0.12,0.24l-0.5,1.32c-0.12,0.33-0.1,0.7,0.06,1.01l0.53,1.03
                  l-0.09,0.73l-0.37,1.15l-0.08,0.16l-0.34-0.1c-0.05-0.01-0.09-0.02-0.13-0.03l-1.42-0.26c-0.17-0.03-0.33-0.03-0.49,0.01l-0.89,0.19
                  l-0.85-0.33c-0.45-0.17-0.96-0.07-1.31,0.26l-1.05,0.99l-2.09,1.89c-0.27,0.23-0.42,0.57-0.43,0.91l-1.04-0.08
                  c-0.2-0.01-0.39,0.02-0.58,0.1l-1.21,0.51c-0.12,0.05-0.24,0.13-0.34,0.22l-0.41,0.36l-0.43-0.2c-0.32-0.15-0.68-0.16-1-0.03
                  l-1.04,0.4l-1.07,0.02c-0.24,0-0.48,0.08-0.68,0.22l-0.54,0.36l-0.7-0.23c-0.08-0.02-0.16-0.04-0.25-0.05l-2.47-0.33
                  c-0.15-0.02-0.32-0.01-0.47,0.02l-1.12,0.27l-0.77,0.07l-1.66-1.27c-0.02-0.01-0.03-0.03-0.05-0.03l0.65-0.41l-0.72-1.13l-1.29-2.93
                  c-0.1-0.22-0.26-0.41-0.46-0.54l-1.18-0.76l-0.87-0.76c-0.05-0.04-0.1-0.08-0.16-0.12l-1.08-0.68l-0.59-0.65l-0.19-0.58l0.2-0.86
                  c0.05-0.23,0.04-0.46-0.04-0.69l-0.43-1.25c-0.02-0.07-0.06-0.15-0.1-0.22l-1.18-2.02l-0.01-0.78l0.33-1.12l1.25-1.96
                  c0.24-0.37,0.26-0.85,0.06-1.25l-0.58-1.11c-0.11-0.2-0.26-0.36-0.45-0.48l-0.97-0.61l-0.87-0.75c-0.06-0.05-0.12-0.1-0.19-0.14
                  l-1.01-0.59l-1.01-0.75l-1.65-1.49l-0.49-0.92c-0.08-0.15-0.19-0.29-0.33-0.39l-0.16-0.13l0.37-0.07c0.3-0.05,0.57-0.21,0.75-0.45
                  l0.77-0.95l0.81-0.65l1.02-0.72c0.21-0.15,0.36-0.36,0.45-0.61l0.38-1.09l0.75-0.8c0.36-0.4,0.44-0.98,0.17-1.46l-0.57-1.03
                  l-0.47-1.15c-0.04-0.09-0.09-0.18-0.14-0.25l-0.46-0.66l0.2-2.22c0.03-0.22-0.01-0.44-0.1-0.64l-0.34-0.74l0.3-0.88
                  c0.05-0.14,0.07-0.3,0.07-0.45l-0.04-1.23c0-0.09-0.02-0.18-0.04-0.27l-0.34-1.32c-0.03-0.14-0.09-0.27-0.17-0.38l-0.83-1.25
                  c-0.17-0.26-0.42-0.44-0.72-0.52l-1.36-0.36c-0.08-0.02-0.16-0.03-0.24-0.04l-1.39-0.09c-0.43-0.02-0.85,0.17-1.1,0.53l-0.45,0.64
                  l-1.98,0.42c-0.26,0.05-0.49,0.18-0.66,0.38l-0.41,0.45l-1.51-0.18l-0.49-0.49l-1.16-2.01l-0.11-0.66l0.31-0.85
                  c0.17-0.44,0.07-0.94-0.26-1.28l-0.86-0.92c-0.16-0.17-0.36-0.3-0.58-0.36l-1.33-0.36c-0.2-0.05-0.41-0.05-0.61-0.01l-1.29,0.3
                  l-1.21,0.2c-0.24,0.04-0.46,0.14-0.64,0.3l-0.43-0.94c-0.02-0.04-0.05-0.09-0.07-0.13l-0.63-1.02l-0.42-0.99l-0.4-1.19
                  c-0.05-0.17-0.15-0.33-0.27-0.46l-0.86-0.9l-0.71-0.87l-0.47-0.91l-0.28-1.09c-0.02-0.08-0.05-0.16-0.09-0.24l-0.51-1.04L398.1,353
                  l0.1-0.73l0.63-0.62c0.21-0.21,0.34-0.49,0.37-0.79l0.06-0.85l0.29-0.23l0.8,0.01c0.01,0,0.01,0,0.02,0c0.43,0,0.82-0.22,1.05-0.58
                  l0.25-0.38l0.51,0.08c0.26,0.03,0.52-0.01,0.75-0.13l1.15-0.59c0.05-0.02,0.1-0.05,0.14-0.08l0.96-0.67l0.56-0.06l0.94,0.49
                  c0.15,0.08,0.32,0.12,0.49,0.14l1.65,0.12c0.38,0.03,0.76-0.13,1.02-0.42l0.85-0.95c0.04-0.04,0.07-0.08,0.11-0.13l0.59-0.88
                  l0.61-0.33l1.17-0.1c0.48-0.05,0.89-0.36,1.06-0.81l0.32-0.86l0.8-0.59c0.26-0.19,0.44-0.48,0.49-0.8l0.21-1.22
                  c0.01-0.07,0.02-0.13,0.02-0.2l0.01-1.31l-0.01-1.06l0.31-0.52l0.93-0.62c0.32-0.22,0.53-0.58,0.55-0.97l0.07-1.22
                  c0.02-0.45-0.2-0.89-0.58-1.13l-0.66-0.41l-0.05-0.43l0.19-0.92l0.45-1.08c0.02-0.03,0.03-0.07,0.05-0.11l0.38-1.24
                  c0.17-0.57-0.08-1.17-0.59-1.46l-1.27-0.71c-0.03-0.02-0.06-0.03-0.09-0.05l-0.73-0.33l-0.26-0.55l-0.38-2.34
                  c-0.02-0.11-0.05-0.21-0.09-0.31l-0.51-1.17c-0.03-0.05-0.05-0.1-0.08-0.15l-0.73-1.19c-0.18-0.3-0.47-0.5-0.81-0.57l-1.02-0.22
                  l-0.59-0.42l-0.11-0.18l0.25-0.42c0.16-0.26,0.22-0.58,0.16-0.89l-0.25-1.23c-0.03-0.15-0.09-0.29-0.17-0.41l-0.49-0.78l-0.01-0.73
                  l0.44-1.05c0.06-0.13,0.09-0.28,0.1-0.42l0.05-1.01l0.54-0.99c0.09-0.16,0.14-0.34,0.15-0.53l0.07-1.28l0.59-1.06
                  c0.05-0.08,0.08-0.16,0.11-0.25l0.71-2.46c0.07-0.26,0.05-0.53-0.04-0.77l-0.4-1.04l0.03-1.13c0.01-0.48-0.26-0.93-0.69-1.15
                  l-0.84-0.42l-0.42-0.93c-0.12-0.26-0.33-0.48-0.59-0.6l-1.53-0.75c-0.14-0.07-0.29-0.11-0.45-0.13l-1.09-0.08l-0.42-0.42l-0.46-1.28
                  c-0.13-0.34-0.39-0.61-0.72-0.74l-1.68-0.67c-0.2-0.08-0.42-0.11-0.64-0.08l-1.8,0.27l-0.95-0.08l-0.97-0.43l-0.68-0.54l-0.11-0.58
                  l0.11-1.12c0.02-0.11,0.01-0.23-0.01-0.34l-0.23-1.29c-0.04-0.2-0.12-0.4-0.25-0.56l-0.79-0.99c-0.08-0.09-0.16-0.17-0.25-0.24
                  l-1.06-0.75l-0.43-0.54l-0.24-2.16c-0.02-0.23-0.11-0.44-0.25-0.62l-0.77-1c-0.2-0.26-0.48-0.43-0.8-0.48l-1.24-0.18l-2.08-0.62
                  l-0.19-0.92c-0.05-0.23-0.16-0.44-0.33-0.61l-0.63-0.65l-0.12-0.85c-0.02-0.14-0.07-0.28-0.14-0.41l-0.22-0.42l0.59-0.77
                  c0.18-0.24,0.27-0.54,0.25-0.84l-0.08-1.32c-0.01-0.05-0.01-0.09-0.02-0.14l-0.26-1.46l-0.05-1.11l0.55-0.91
                  c0.11-0.18,0.17-0.39,0.18-0.6l0.03-0.73l0.14-0.14l1.58,0.23l1.99,1.38c0.16,0.11,0.34,0.18,0.53,0.21l1.25,0.2
                  c0.34,0.06,0.68-0.03,0.95-0.23l1.13-0.86l1.05-0.9c0.07-0.06,0.13-0.12,0.18-0.18l0.79-1.02c0.11-0.15,0.19-0.31,0.23-0.49
                  l0.23-0.99l0.15-0.2l0.52,0.08c0.08,0.01,0.16,0.01,0.24,0.01l0.71-0.03l0.45,0.62c0.02,0.03,0.05,0.06,0.07,0.09l0.96,1.09
                  c0.27,0.3,0.66,0.45,1.06,0.41l1.05-0.1l0.72,0.27l0.41,0.44l0.16,0.66l-0.14,1.11c-0.03,0.22,0,0.45,0.09,0.65l0.48,1.13
                  c0.05,0.12,0.12,0.24,0.21,0.34l0.87,0.99c0.13,0.14,0.29,0.25,0.47,0.33l1.42,0.58l1.36,0.67l1.32,0.56
                  c0.12,0.05,0.25,0.08,0.38,0.1l2.01,0.2l0.31,1.96c0.01,0.05,0.02,0.1,0.04,0.15l0.35,1.25c0.07,0.27,0.23,0.5,0.46,0.67l1.05,0.77
                  l0.97,0.79c0.17,0.13,0.37,0.23,0.58,0.26l1.22,0.21c0.39,0.07,0.79-0.05,1.07-0.33l0.93-0.89c0.11-0.1,0.2-0.22,0.27-0.36
                  l0.44-0.93l1.55-1.54l0.88-0.49l2.48-0.44l1.44-0.18c0.26-0.03,0.52-0.15,0.71-0.34l2.09-2.1c0.1-0.11,0.19-0.23,0.25-0.37
                  l0.65-1.49c0.09-0.21,0.12-0.45,0.09-0.68l-0.19-1.3l0.37-0.74l0.86-0.27c0.29-0.09,0.53-0.29,0.69-0.55l0.65-1.07l0.71-1.05
                  c0.11-0.17,0.19-0.37,0.2-0.58l1.28,0.16c0.15,0.02,0.3,0.01,0.44-0.03l1.2-0.29c0.13-0.02,0.24-0.07,0.35-0.14l1.17-0.7
                  c0.09-0.05,0.17-0.11,0.24-0.19l0.9-0.9c0.03-0.02,0.06-0.05,0.08-0.09l0.85-1.02l0.81-0.6l0.94-0.38l1.38-0.16l1.26-0.05
                  c0.11,0,0.22-0.02,0.32-0.06l1.29-0.4c0.3-0.09,0.55-0.29,0.7-0.55l0.74-1.23c0.17-0.3,0.22-0.66,0.12-1l-0.24-0.8l0.36-0.74
                  l0.72-1.07l0.98-1.18l0.92-0.61c0.28-0.19,0.48-0.48,0.54-0.81l0.11-0.61l0.18,0.1l0.14,0.72c0.04,0.15,0.1,0.3,0.19,0.44l0.65,0.99
                  l0.54,1.12c0.02,0.04,0.04,0.08,0.07,0.12l0.71,1.15c0.16,0.25,0.4,0.45,0.69,0.53l1.27,0.39c0.53,0.16,1.1-0.04,1.41-0.49l0.7-1.04
                  c0.07-0.11,0.13-0.23,0.16-0.36l0.19-0.66l0.41-0.1l1.1-0.11l1.29,0.32c0.15,0.04,0.3,0.05,0.45,0.03l1.4-0.17
                  c0.13-0.02,0.26-0.05,0.38-0.11l1.1-0.51l0.61,0.01l0.94,0.86c0.08,0.07,0.17,0.13,0.26,0.18l1.16,0.61
                  c0.08,0.04,0.16,0.07,0.24,0.09l1.43,0.41l3.11,0.75l1.23,0.47c0.29,0.11,0.61,0.11,0.9,0l0.83-0.32l1.07,0.23
                  c0.17,0.04,0.36,0.04,0.54,0l1.43-0.33c0.04-0.01,0.07-0.02,0.1-0.03l1.2-0.39l0.89-0.13l0.8,0.19l0.61,0.47l0.59,0.83l0.54,1.04
                  c0.15,0.28,0.4,0.5,0.7,0.6l1.18,0.41l0.54,0.6l0.42,0.92l0.24,0.98l0.06,0.76l-0.55,0.65c-0.03,0.04-0.06,0.08-0.09,0.12
                  l-0.95,1.42l-0.26-0.1c-0.37-0.15-0.78-0.11-1.11,0.09c-0.22,0.14-0.39,0.33-0.49,0.56c-0.27-0.04-0.56,0.02-0.81,0.17l-1.06,0.64
                  c-0.49,0.3-0.72,0.9-0.54,1.45l0.3,0.94l-0.09,1.04c-0.02,0.24,0.03,0.47,0.14,0.68l0.33,0.65l-0.24,0.62l-0.59,0.97
                  c-0.17,0.27-0.23,0.58-0.17,0.89l0.23,1.24c0.06,0.32,0.25,0.61,0.52,0.8l0.95,0.66l0.69,1.05l0.58,1.08
                  c0.05,0.09,0.12,0.18,0.19,0.26l0.58,0.61l-0.06,0.61l-1.01,2.29l-0.37,1.04l-0.57,0.83l-0.79,0.82c-0.35,0.35-0.45,0.86-0.28,1.32
                  l0.53,1.37c0.03,0.06,0.06,0.13,0.09,0.18l0.48,0.82l-0.02,1.13l-0.12,1.23c-0.01,0.06-0.01,0.13-0.01,0.2l0.08,1.22l-0.05,1.11
                  l-0.12,1.23c-0.01,0.05-0.01,0.1-0.01,0.15l0.03,1.22c0,0.05,0.01,0.11,0.01,0.16l0.15,0.93l-0.35,1.05
                  c-0.16,0.48-0.01,1.01,0.38,1.34l0.93,0.8c0.07,0.06,0.15,0.11,0.23,0.16l1.09,0.56l1.01,0.75l1.17,0.76
                  c0.08,0.05,0.17,0.09,0.26,0.13l1.09,0.39l0.6,0.51l0.35,0.95c0.07,0.2,0.2,0.39,0.37,0.53l0.97,0.81c0.17,0.14,0.37,0.23,0.58,0.27
                  l1.35,0.24c0.15,0.03,0.3,0.03,0.45,0l0.58-0.11l0.3,0.37c0.11,0.14,0.25,0.26,0.41,0.34l1.09,0.56c0.06,0.03,0.13,0.06,0.2,0.08
                  l0.73,0.23l0.08,0.19l-0.3,0.61c-0.16,0.33-0.17,0.71-0.03,1.05l0.51,1.18c0.17,0.38,0.51,0.65,0.92,0.73l0.91,0.17l0.66,0.65
                  c0.13,0.13,0.29,0.23,0.47,0.29l1.52,0.53l-0.09,0.12c-0.38,0.47-0.37,1.14,0.02,1.6l1,1.17c0.17,0.2,0.4,0.34,0.65,0.4l0.49,0.12
                  l0.04,0.15l-0.67,2.3c-0.04,0.13-0.05,0.27-0.05,0.4l0.06,1.38c0.01,0.29,0.12,0.56,0.31,0.77l0.91,1.05
                  c0.03,0.04,0.07,0.08,0.11,0.12l0.95,0.84c0.05,0.04,0.09,0.07,0.14,0.1l0.99,0.65l0.86,0.73l1.68,1.93l2.49,2.71l0.14,1.06
                  c0.01,0.08,0.03,0.15,0.05,0.22l0.39,1.21c0.04,0.12,0.09,0.23,0.16,0.34l0.74,1.04l0.6,1.04c0.12,0.21,0.3,0.38,0.51,0.48
                  l0.94,0.48l0.54,0.68l0.08,0.78l-0.07,1.16c-0.01,0.22,0.04,0.45,0.14,0.64L516.34,345.1z"/>
                <title>Prievidza</title>
              </Link>

              {/*Zarnovica - banskobystricky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Žarnovica")}>
                <path
                  d="M528.21,455.9l0.33,1.07l-0.06,0.83l-0.71,1.46c-0.01,0.01-0.01,0.01-0.01,0.02c-0.14-0.04-0.28-0.06-0.42-0.06
                  c-0.46,0.01-0.87,0.27-1.08,0.68l-0.54,1.05l-0.69,0.96l-0.98,0.42c-0.13,0.06-0.25,0.13-0.35,0.23l-0.91,0.83
                  c-0.06,0.04-0.1,0.1-0.15,0.15l-0.72,0.93l-0.48,0.49l-0.55,0.01l-1.05-0.42l-1.23-0.41c-0.13-0.04-0.26-0.06-0.41-0.06l-1.38,0.02
                  l-1.15-0.15c-0.39-0.06-0.78,0.09-1.06,0.37c-0.27,0.28-0.4,0.68-0.33,1.07l0.2,1.22c0.06,0.36,0.27,0.68,0.59,0.87l1.05,0.64
                  l0.66,0.52l0.22,0.62l-0.03,0.35l-0.45,0.26l-1.05,0.45c-0.19,0.08-0.36,0.21-0.49,0.38l-0.31,0.39l-0.18-0.03l-1.46-1.5
                  c-0.17-0.17-0.38-0.29-0.61-0.35l-1.23-0.29c-0.28-0.06-0.57-0.03-0.83,0.09l-0.79,0.38l-0.35-0.06l-0.36-0.46l-0.36-0.97
                  c-0.04-0.12-0.11-0.24-0.18-0.34l-0.85-1.09c-0.2-0.25-0.48-0.41-0.79-0.46l-1.36-0.22l-3.62-0.9c-0.35-0.09-0.72-0.03-1.01,0.17
                  c-0.3,0.2-0.49,0.52-0.54,0.87l-0.13,1l-0.53,0.87c-0.11,0.16-0.17,0.35-0.19,0.54l-0.17,2.44l-0.17,1.11l-0.33,1.04l-0.47,0.86
                  l-0.04-0.08c-0.21-0.43-0.62-0.68-1.14-0.7l-1.34,0.02l-1.12,0.06l-1.27-0.28c-0.5-0.11-1.02,0.09-1.31,0.52l-0.75,1.12l-0.96,1.21
                  l-0.88,0.75c-0.08,0.06-0.14,0.13-0.2,0.21l-0.81,1.11c-0.07,0.11-0.13,0.22-0.17,0.34l-0.39,1.13l-0.57,0.64l-0.9,0.47
                  c-0.05,0.03-0.1,0.06-0.14,0.09l-1.09,0.77l-1.49,0.9c-0.34,0.21-0.57,0.57-0.6,0.98c-0.02,0.38,0.12,0.76,0.41,1.01
                  c-0.08,0.08-0.14,0.17-0.19,0.26l-0.53,0.94l-0.71,0.67l-0.98,0.61c-0.39,0.23-0.62,0.66-0.6,1.12l0.06,1.3
                  c0,0.03,0.01,0.07,0.01,0.1l0.05,0.4l-0.16,0.06l-1.02,0.05c-0.33,0.02-0.64,0.16-0.87,0.41c-0.22,0.25-0.34,0.57-0.32,0.91
                  l0.09,1.71c0,0.04,0.01,0.08,0.01,0.12l0.19,1.18l0.05,0.96l-0.52,0.69c-0.07,0.1-0.13,0.21-0.17,0.32l-0.31,0.83l-0.89,0.61
                  l-1.03,0.33c-0.14,0.04-0.28,0.12-0.4,0.21l-1.08,0.86c-0.04,0.03-0.08,0.07-0.11,0.1l-0.71,0.72c-0.07-0.07-0.16-0.13-0.25-0.19
                  l-1.71-0.97c-0.06-0.03-0.11-0.06-0.17-0.08l-1.49-0.57c-0.05-0.01-0.09-0.03-0.14-0.04l-1.45-0.37c-0.51-0.13-1.04,0.07-1.34,0.5
                  l-0.99,1.45l-0.52,0.38l-0.88-0.11c-0.33-0.04-0.67,0.05-0.94,0.26l-1.18,0.94l-0.54,0.39l-1.19-0.29c-0.27-0.07-0.55-0.05-0.8,0.07
                  l-0.99,0.43l-0.62-0.03l-0.79-0.46c-0.14-0.09-0.3-0.14-0.46-0.17l-1.24-0.18c-0.16-0.02-0.32-0.01-0.47,0.02l-0.92,0.23l-0.58-0.22
                  l-0.71-0.67c-0.45-0.42-1.14-0.46-1.62-0.08l-1.01,0.78c-0.2,0.15-0.34,0.35-0.42,0.58l-0.38,1.1l-0.46,0.61l-0.71,0.11
                  c-0.44,0.07-0.8,0.36-0.97,0.77l-0.47,1.16c-0.05,0.14-0.09,0.29-0.09,0.44l-0.03,1.19l-0.15,0.95l-0.42,0.57l-2.14,1.2
                  c-0.05,0.02-0.1,0.05-0.14,0.09c-0.09-0.38-0.34-0.69-0.69-0.85l-0.5-0.23c0.16-0.09,0.29-0.22,0.4-0.37l1.19-1.71
                  c0.36-0.52,0.27-1.23-0.21-1.65l-0.37-0.33l0.3-0.08c0.09-0.03,0.17-0.06,0.26-0.11l1.09-0.58c0.35-0.18,0.59-0.53,0.65-0.93
                  l0.18-1.26c0.04-0.29-0.02-0.58-0.17-0.82l-0.14-0.25l0.21-0.22l1.02-0.51c0.1-0.05,0.19-0.11,0.27-0.19l0.82-0.72l1.18-0.21
                  c0.31-0.05,0.59-0.22,0.78-0.47l1.27-1.67c0.2-0.27,0.29-0.61,0.24-0.94l-0.18-1.24l-0.02-1.01l0.28-0.8l0.92-1.01
                  c0.16-0.16,0.26-0.37,0.31-0.59l0.26-1.29c0.04-0.21,0.03-0.42-0.03-0.62l-0.34-1.09l-0.24-0.98l0.33-0.77
                  c0.19-0.45,0.1-0.97-0.22-1.33l-0.67-0.73l-0.25-0.8l-0.08-1l0.45-2.68c0.09-0.48-0.12-0.98-0.53-1.25l-0.65-0.45l-0.28-0.95
                  l-0.42-1.22l-0.4-1.41c-0.03-0.14-0.1-0.28-0.18-0.4l-0.73-1.02c-0.26-0.36-0.69-0.56-1.14-0.51l-2.53,0.25l-1.17,0.03h-0.38
                  l0.24-0.81l0.49-1.09l0.61-0.98c0.04-0.06,0.07-0.12,0.1-0.19l0.5-1.22c0.06-0.16,0.09-0.33,0.09-0.5l-0.03-1.26
                  c-0.01-0.4-0.21-0.78-0.55-1l-1.03-0.7c-0.04-0.03-0.08-0.06-0.12-0.08l-0.92-0.48l-0.32-0.42l0.01-0.97l-0.05-1.26
                  c0-0.1-0.02-0.21-0.05-0.31l-0.36-1.2c-0.12-0.38-0.4-0.68-0.77-0.82l-1.18-0.42c-0.05-0.02-0.1-0.04-0.15-0.05l1.03-1.4
                  c0.15-0.21,0.24-0.47,0.24-0.74v-0.89l0.31-0.49l0.81-0.4c0.12-0.06,0.23-0.14,0.32-0.24l1.05-1.05l1.6-2.2
                  c0.27-0.38,0.31-0.89,0.08-1.31c-0.22-0.41-0.65-0.65-1.13-0.66l-1.26,0.03c-0.18,0-0.36,0.05-0.52,0.13l-0.41,0.2l0.08-0.43
                  c0.01-0.06,0.02-0.12,0.02-0.18l0.04-1l0.45-0.88c0.08-0.15,0.13-0.33,0.14-0.51l0.05-1.05l0.36-1.01c0.03-0.08,0.05-0.16,0.06-0.24
                  l0.31-2.51c0.03-0.2-0.01-0.41-0.08-0.61l-0.47-1.14c-0.05-0.1-0.11-0.2-0.18-0.29l-0.62-0.8l-0.33-1
                  c-0.13-0.39-0.44-0.69-0.83-0.81c-0.39-0.11-0.81-0.04-1.13,0.22l-0.39,0.3l-0.38-0.17l-0.97-0.71c-0.17-0.12-0.36-0.2-0.56-0.23
                  l-1.39-0.2c-0.05,0-0.09-0.01-0.14-0.01l-1.46-0.05c-0.13-0.01-0.27,0.01-0.4,0.05l-2.5,0.75c-0.23,0.07-0.44,0.21-0.6,0.4
                  l-0.89,1.06c-0.19,0.23-0.29,0.52-0.29,0.82l0.01,0.58l-0.47,0.25l-0.68,0.05l1.55-2.85l0.75-0.76c0.05-0.05,0.09-0.1,0.13-0.15
                  l0.65-0.91l0.8-0.76c0.09-0.08,0.16-0.18,0.23-0.29l0.56-0.98l0.67-0.74l0.98-0.6l0.82-0.57l1.01-0.23c0.64-0.15,1.05-0.76,0.96-1.4
                  c-0.1-0.65-0.67-1.12-1.32-1.07l-1.59,0.1c-0.25,0.02-0.48,0.11-0.68,0.26l-0.61,0.46l-0.15-0.05l-0.49-1.03
                  c-0.07-0.15-0.17-0.28-0.3-0.39l-0.84-0.75l-0.57-1.08l-0.38-1.76c-0.03-0.14-0.08-0.27-0.15-0.39l-0.65-1.07
                  c-0.07-0.12-0.16-0.22-0.27-0.31l-0.43-0.36l0.51-0.48c0.13-0.13,0.24-0.28,0.31-0.46l0.51-1.33l0.37-1.15l0.47-1.1
                  c0.02-0.04,0.03-0.08,0.04-0.12l0.28-0.91l0.71,0.52c0.24,0.19,0.54,0.27,0.85,0.25l1.33-0.12l0.18-0.03l0.98-0.24l2.18,0.29
                  l1.17,0.38c0.37,0.12,0.77,0.06,1.09-0.16l0.74-0.5l0.92-0.01c0.15,0,0.3-0.03,0.44-0.09l0.74-0.29l0.69,0.33
                  c0.46,0.21,1,0.14,1.37-0.2l0.88-0.78l0.74-0.31l1.21,0.09l1.34,0.02c0.41,0,0.78-0.18,1.02-0.5c0.24-0.33,0.31-0.74,0.19-1.13
                  l-0.15-0.49l2.03-1.86l0.46,0.18c0.23,0.09,0.48,0.1,0.71,0.05l1.01-0.22l1.11,0.21l1.31,0.39c0.6,0.18,1.23-0.11,1.49-0.67
                  l0.56-1.21c0.02-0.05,0.04-0.09,0.06-0.14l0.44-1.34c0.02-0.08,0.04-0.16,0.05-0.25l0.14-1.24c0.03-0.24-0.02-0.49-0.13-0.71
                  l-0.44-0.87l0.25-0.67l1.01-1.58l0.97-1.1l0.71-0.94l1.27-1.14c0.03-0.02,0.05-0.04,0.07-0.07l0.87-0.91
                  c0.1-0.09,0.18-0.21,0.23-0.33l0.8-1.7l0.81-1.27l0.82-1.02c0.04-0.04,0.07-0.08,0.09-0.13l0.79-1.27c0.08-0.13,0.14-0.28,0.16-0.44
                  l0.19-1.1l1.38-2.23l0.39-0.43l1.3,0.18l0.53,1.17c0.03,0.07,0.07,0.13,0.11,0.19l0.82,1.17l0.28,1.06
                  c0.03,0.11,0.07,0.21,0.13,0.31l0.78,1.33l0.71,1.1c0.07,0.1,0.15,0.19,0.24,0.27l1.03,0.88l0.83,0.8l0.29,1.03l0.07,2.42
                  c0.01,0.21,0.07,0.43,0.19,0.61l0.65,1.06l0.52,1.16l0.49,0.98l0.23,1.24l0.34,1.31c0.01,0.04,0.02,0.08,0.04,0.12l0.44,1.19
                  c0.01,0.04,0.03,0.08,0.05,0.12l0.55,1.13c0.03,0.06,0.06,0.12,0.1,0.17l0.74,1.05c0.1,0.14,0.23,0.26,0.38,0.35l1.01,0.59l0.5,0.4
                  l0.03,0.69c0,0.07,0.01,0.14,0.03,0.21l0.2,0.95l-0.31,0.97c-0.02,0.07-0.04,0.15-0.05,0.23l-0.31,2.81l-0.25,1.14l-0.34,1.22
                  c-0.04,0.14-0.06,0.27-0.05,0.41l0.12,2.07c0.01,0.19,0.07,0.37,0.15,0.53l0.24,0.43l-0.27,0.42c-0.05,0.07-0.08,0.14-0.11,0.21
                  l-0.49,1.18c-0.13,0.31-0.13,0.65-0.01,0.95l0.47,1.15c0.16,0.38,0.49,0.66,0.88,0.75l1.23,0.28l0.89,0.59
                  c0.2,0.13,0.43,0.21,0.67,0.21l1.17,0.02l0.95,0.27l0.72,0.3l0.62,1c0.1,0.18,0.25,0.32,0.42,0.42l1.33,0.8l0.69,0.57l0.27,0.84
                  l-1.36,1.31c-0.26,0.25-0.4,0.6-0.38,0.95l0.11,2.18c0,0.06,0.01,0.12,0.02,0.18l0.22,1.11l-0.06,1.25c0,0.09,0,0.18,0.02,0.27
                  l0.21,1.22l0.18,1.65c0.06,0.53,0.45,0.97,0.98,1.08l1.6,0.34l1.29,0.23c0.22,0.04,0.45,0.02,0.66-0.06l1.11-0.41l1.32-0.14
                  l0.8,0.19l0.96,0.57c0.05,0.03,0.09,0.06,0.14,0.08l0.83,0.35l0.54,0.73c0.06,0.09,0.14,0.17,0.23,0.24l0.99,0.78
                  c0.09,0.08,0.2,0.14,0.31,0.18l1.27,0.5c0.19,0.08,0.39,0.11,0.59,0.08l1.19-0.12l0.78,0.1l0.79,0.59c0.06,0.04,0.12,0.08,0.19,0.12
                  l1.13,0.57c0.18,0.09,0.37,0.13,0.56,0.13c0.35,0,0.69-0.15,0.93-0.42l0.88-0.99c0.1-0.11,0.18-0.23,0.23-0.37l0.26-0.66l0.08-0.02
                  l0.5,0.41c0.15,0.12,0.31,0.2,0.48,0.23v0.06c-0.03,0.49,0.23,0.96,0.67,1.2l1.1,0.58c0.3,0.16,0.67,0.18,0.99,0.07
                  c0.33-0.12,0.6-0.36,0.74-0.68l0.36-0.84l0.63-0.34l0.46,0.05l0.56,0.56c0.07,0.08,0.16,0.15,0.26,0.21l1.07,0.62l0.93,0.8
                  c0.17,0.14,0.38,0.23,0.6,0.27l0.46,0.08l0.82,2.29c0.14,0.36,0.43,0.64,0.8,0.76c0.38,0.11,0.78,0.04,1.09-0.18l0.74-0.52l0.9-0.03
                  c0.04,0,0.07,0,0.11-0.01l1.22-0.15c0.05,0,0.09-0.01,0.13-0.02l0.6-0.14l-0.38,1.01C528.14,455.36,528.13,455.64,528.21,455.9z"/>
                <title>Žarnovica</title>
              </Link>

              {/*Banska stiavnica - banskobystricky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Banská Štiavnica")}>
                <path
                  d="M564.65,428.79c-0.04,0.15-0.05,0.3-0.03,0.45l0.09,0.98l-0.37,1.04c-0.07,0.2-0.09,0.4-0.06,0.6l0.04,0.28
                  l-0.25,0.1c-0.33,0.12-0.59,0.37-0.73,0.69c-0.13,0.33-0.12,0.69,0.03,1l0.53,1.16l0.3,0.83l-0.12,0.7l-0.61,0.68
                  c-0.14,0.14-0.23,0.32-0.29,0.51l-0.33,1.22c-0.02,0.08-0.03,0.16-0.04,0.23l-0.12,1.65l-0.34,0.9l-0.6,0.77l-0.84,0.69
                  c-0.41,0.34-0.56,0.89-0.39,1.39l0.38,1.04l0.08,1.18c0.02,0.21,0.09,0.42,0.2,0.59l0.67,1.03c0.12,0.19,0.29,0.34,0.49,0.44
                  l1.15,0.58l0.65,0.74l0.08,0.57l-1.39,3.25l-0.63,1.23c-0.08,0.15-0.13,0.31-0.14,0.48l-0.09,1.28c-0.01,0.18,0.02,0.36,0.08,0.53
                  l0.43,1.15c0.01,0.03,0.03,0.07,0.04,0.1l0.54,1.13c0.02,0.04,0.05,0.09,0.07,0.13l0.6,0.94l0.28,1.11l0.01,1.18
                  c0,0.07,0.01,0.14,0.02,0.21l0.23,1.31l0.01,1.11l-0.12,0.94l-0.57,0.87l-0.71,0.8l-1.08,0.78c-0.11,0.07-0.21,0.17-0.28,0.28
                  l-0.72,1c-0.15,0.21-0.23,0.45-0.24,0.68h-0.04l-0.07-0.05l-0.03,0.04l-0.11-0.01l-0.03,0.23l-0.64,1.01l-0.64,1.14l-0.82,1.01
                  l-0.8,0.64l-1.03,0.55c-0.19,0.1-0.35,0.25-0.47,0.43l-0.51,0.83l-0.66,0.3l-1.02,0.08c-0.11,0.01-0.22,0.04-0.32,0.07l-1.15,0.41
                  c-0.53,0.19-0.87,0.72-0.83,1.29l0.1,1.06l-0.31,1.08l-0.64,1.45c-0.02,0.03-0.03,0.07-0.04,0.1l-0.56,1.61
                  c-0.07,0.21-0.09,0.43-0.05,0.64c-0.06-0.02-0.13-0.04-0.2-0.05l-1.7-0.31c-0.42-0.08-0.86,0.07-1.15,0.39
                  c-0.29,0.33-0.39,0.78-0.27,1.2l0.48,1.62c0.09,0.3,0.29,0.55,0.56,0.71l0.93,0.56l0.85,0.87l0.28,0.25l-0.64,1.13l-0.49,0.47
                  l-0.81,0.09c-0.41,0.04-0.78,0.28-0.97,0.65l-0.68,1.27c-0.17,0.32-0.2,0.7-0.07,1.04l0.16,0.42l-0.3,0.32
                  c-0.16,0.16-0.27,0.36-0.33,0.58l-0.29,1.22c-0.08,0.34-0.01,0.7,0.18,0.99l0.7,1.04c0.03,0.04,0.06,0.08,0.09,0.12l0.76,0.87
                  l0.54,0.86l0.37,1.04c0.07,0.19,0.18,0.36,0.33,0.5l0.74,0.68l0.31,0.65l-0.04,0.36l-0.01,0.01l-0.38-0.14l-0.87-0.64
                  c-0.4-0.29-0.93-0.32-1.35-0.08l-1.07,0.6l-1.18,0.48c-0.15,0.06-0.28,0.15-0.4,0.26l-0.89,0.86c-0.19,0.19-0.32,0.44-0.36,0.7
                  l-0.06,0.33l-0.36,0.05c-0.42,0.05-0.78,0.31-0.97,0.68l-0.43,0.87l-0.77,0.64c-0.19,0.17-0.33,0.38-0.39,0.62l-0.34,1.21
                  c-0.11,0.38-0.03,0.78,0.2,1.09l0.51,0.69l-0.31,0.1c-0.16,0.05-0.3,0.13-0.42,0.23l-0.8,0.67l-0.42,0.1l-0.26-0.21l-0.36-0.97
                  c-0.23-0.62-0.9-0.95-1.53-0.76l-0.87,0.26l-0.42-0.12l-0.34-0.53l-0.37-1.05c-0.21-0.59-0.83-0.94-1.44-0.8l-1.2,0.26
                  c-0.39,0.08-0.71,0.34-0.87,0.69c-0.17,0.36-0.16,0.77,0.03,1.12l0.76,1.44l0.09,0.62l-0.49,0.72l-0.73-0.74
                  c-0.25-0.27-0.61-0.4-0.97-0.38c-0.37,0.03-0.7,0.21-0.92,0.5l-0.88,1.17l-0.87,0.87l-0.62,0.15l-0.81-0.18l-0.41-1.5
                  c-0.01-0.06-0.03-0.11-0.05-0.16l-0.52-1.27l-0.26-1.4c-0.07-0.36-0.3-0.68-0.63-0.86l-1.07-0.59c-0.08-0.05-0.17-0.09-0.26-0.11
                  l-1.19-0.34c-0.03-0.01-0.06-0.02-0.1-0.03l-0.82-0.16l-0.38-0.46l-0.32-0.95c-0.01-0.04-0.03-0.08-0.04-0.12l-0.5-1.08l-0.3-0.83
                  l0.18-0.97c0.03-0.18,0.03-0.36-0.02-0.53l-0.29-1.21l-0.04-1.16c-0.01-0.17-0.05-0.34-0.12-0.49l-0.95-2.08l-0.15-1.14
                  c-0.01-0.09-0.03-0.18-0.06-0.26l-0.44-1.22l0.04-1.42c0-0.15-0.02-0.29-0.06-0.43l-0.46-1.41c-0.05-0.13-0.11-0.26-0.2-0.37
                  l-1.08-1.43l-0.65-1c-0.19-0.31-0.52-0.52-0.88-0.57l-1.02-0.14l-0.94-0.51c-0.06-0.03-0.12-0.06-0.18-0.08l-1.25-0.45l-0.68-0.71
                  c-0.05-0.06-0.11-0.12-0.18-0.16l-1.04-0.75c-0.17-0.13-0.36-0.2-0.56-0.23l-0.55-0.07l-0.67-1.65l-0.34-1.16
                  c-0.05-0.16-0.12-0.31-0.22-0.43l-0.82-1.01c-0.05-0.07-0.12-0.14-0.19-0.2l-0.92-0.73l-1.33-2.07c-0.06-0.1-0.14-0.18-0.23-0.26
                  l-0.54-0.48l0.01-0.65c0-0.14-0.02-0.28-0.07-0.42l-0.47-1.39c-0.07-0.23-0.22-0.43-0.41-0.58l-0.73-0.57l0.48-0.69
                  c0.03-0.04,0.05-0.07,0.07-0.11l0.6-1.07c0.04-0.07,0.08-0.16,0.1-0.24l0.39-1.26c0.02-0.06,0.04-0.12,0.05-0.18l0.19-1.26
                  c0-0.04,0.01-0.08,0.01-0.12l0.15-2.13l0.51-0.84c0.07-0.11,0.12-0.23,0.16-0.36l2.39,0.58l0.96,0.16l0.44,0.56l0.36,0.97
                  c0.04,0.12,0.11,0.24,0.18,0.34l0.77,0.99c0.19,0.24,0.47,0.41,0.78,0.46l1.22,0.21c0.25,0.05,0.52,0.01,0.75-0.1l0.75-0.36
                  l0.45,0.1l1.51,1.55c0.19,0.19,0.43,0.31,0.69,0.35l1.31,0.21c0.45,0.08,0.9-0.1,1.18-0.46l0.56-0.72l0.84-0.35
                  c0.05-0.02,0.1-0.05,0.15-0.08l1.06-0.63c0.34-0.2,0.57-0.55,0.6-0.95l0.12-1.25c0.02-0.18,0-0.36-0.06-0.54l-0.43-1.22
                  c-0.08-0.22-0.22-0.41-0.4-0.56l-0.29-0.23l1.87,0.71c0.16,0.06,0.34,0.11,0.51,0.09l1.31-0.04c0.32-0.01,0.62-0.14,0.85-0.37
                  l0.88-0.89c0.03-0.04,0.07-0.07,0.1-0.11l0.7-0.91l0.68-0.61l1.1-0.48c0.21-0.09,0.39-0.24,0.52-0.42l0.67-0.93
                  c0.32,0.13,0.68,0.14,1.02-0.02l1.25-0.58c0.57-0.26,0.85-0.91,0.66-1.5l-0.22-0.71l0.6-1.23c0.07-0.15,0.12-0.3,0.13-0.46l0.09-1.3
                  c0.01-0.15-0.01-0.31-0.05-0.45l-0.27-0.89l0.3-0.8c0.02-0.05,0.03-0.09,0.04-0.14l0.35-1.42c0.09-0.34,0.03-0.7-0.17-0.99
                  c-0.19-0.29-0.49-0.49-0.84-0.54l-1.23-0.2c-0.16-0.03-0.33-0.02-0.48,0.01l-1.18,0.28l-1.1,0.13l-1.22,0.04
                  c-0.13,0-0.26,0.03-0.39,0.07l-0.43-1.39c-0.14-0.45-0.52-0.79-0.99-0.87l-0.88-0.16l-0.77-0.64c-0.05-0.04-0.11-0.08-0.17-0.12
                  l-1.01-0.59l-0.76-0.77c-0.2-0.21-0.47-0.34-0.77-0.37l-1.29-0.13c-0.24-0.03-0.49,0.02-0.71,0.14l-1.35,0.72
                  c-0.04,0.02-0.08,0.04-0.11,0.07c0.09-0.17,0.15-0.35,0.16-0.55l0.04-0.89l0.46-0.42l0.89-0.57l0.89-0.31l0.89,0.15
                  c0.32,0.06,0.66-0.02,0.93-0.21l0.64-0.45l0.76,0.11c0.4,0.06,0.81-0.09,1.08-0.39l0.81-0.86l0.84-0.26l1.07,0.02
                  c0.38-0.01,0.71-0.14,0.95-0.4l1.27-1.38c0.09-0.1,0.16-0.2,0.21-0.32l0.64-1.36c0.07-0.14,0.11-0.3,0.12-0.46l0.07-1.31v-0.15h1.05
                  l1.31,0.11c0.18,0.03,0.36,0,0.53-0.07l0.69-0.25l0.63,0.3c0.49,0.24,1.08,0.14,1.45-0.25l1.03-1.08l1.04-1.19
                  c0.13-0.14,0.22-0.31,0.27-0.5l0.31-1.17l0.68-1.07c0.07-0.12,0.12-0.25,0.16-0.38l0.98-4.76l0.61-0.21
                  c0.4-0.13,0.71-0.46,0.81-0.87l0.05-0.21l0.22,0.04c0.58,0.08,1.16-0.25,1.37-0.82l0.38-1.07l0.74-0.89l0.8-1.1
                  c0.14-0.2,0.23-0.44,0.24-0.68l0.03-0.73l1.46-1.42l1.01-0.85c0.22-0.19,0.37-0.44,0.43-0.72l0.16-0.83l0.56-0.44l0.57-0.19
                  l0.75,0.5c0.09,0.07,0.19,0.12,0.3,0.15l0.09,0.53c0.04,0.29,0.19,0.56,0.42,0.75l1.06,0.91c0.15,0.13,0.34,0.23,0.54,0.27
                  l1.42,0.31c0.07,0.02,0.13,0.02,0.2,0.03l1.46,0.08c0.31,0.01,0.63-0.09,0.87-0.29l0.42-0.35l0.09,0.16l0.23,1.18
                  c0.02,0.06,0.03,0.12,0.05,0.17l0.4,1.16l0.17,1.19c0.02,0.1,0.04,0.2,0.08,0.29l0.44,1.06l0.4,1.24l0.47,1.27
                  c0.06,0.16,0.15,0.3,0.26,0.43l0.99,1.05c0.3,0.31,0.74,0.45,1.16,0.37c0.43-0.09,0.78-0.39,0.93-0.8l0.36-1l0.6-0.55l1.24-0.33
                  c0.06-0.02,0.12-0.04,0.18-0.06l1.08-0.47l0.6-0.18l0.05,0.89l-0.19,1.03L564.65,428.79z"/>
                <title>Banská Štiavnica</title>
              </Link>

              {/*Krupina - banskobystricky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Krupina")}>
                <path
                  d="M622.01,534.27l-0.71,0.21c-0.26,0.09-0.49,0.25-0.65,0.48l-0.72,1.02c-0.12,0.16-0.19,0.35-0.22,0.54
                  l-0.16,1.12l-0.35,0.86l-0.41,0.44l-0.7,0.03l-1.15-0.1c-0.39-0.03-0.79,0.13-1.05,0.43l-0.51,0.6l-0.88,0.13
                  c-0.14,0.03-0.27,0.07-0.4,0.14l-1.09,0.58c-0.26,0.14-0.47,0.37-0.58,0.65l-0.38,1.01l-0.67,0.89c-0.08,0.11-0.14,0.23-0.18,0.35
                  l-0.05,0.15l-0.68-1.05c-0.16-0.25-0.41-0.43-0.69-0.52l-1.28-0.38c-0.45-0.13-0.94,0-1.26,0.33l-0.92,0.96
                  c-0.21,0.22-0.33,0.5-0.35,0.79l-0.06,1.01l-0.47,0.89c-0.08,0.15-0.12,0.32-0.14,0.49l-0.19,2.48l-0.26,0.84l-0.33,0.33l-0.55,0.01
                  l-0.55-0.18l-0.23-0.53l-0.16-1.09c-0.09-0.58-0.58-1.02-1.17-1.06l-1.39-0.08l-0.97-0.02l-0.18-0.14l0.02-0.58
                  c0.02-0.56-0.33-1.06-0.86-1.23l-1.17-0.38c-0.6-0.19-1.24,0.08-1.51,0.64l-0.54,1.1l-0.75,0.74c-0.02,0.03-0.05,0.05-0.07,0.08
                  l-0.83,0.98c-0.19,0.22-0.3,0.5-0.3,0.79l-0.01,0.83l-1.25,1.64c-0.07,0.1-0.13,0.2-0.17,0.31l-0.18,0.46l-0.35,0.04l-0.61-0.1
                  l-0.46-0.6c-0.11-0.13-0.24-0.25-0.4-0.33l-0.83-0.45l-0.58-0.83c-0.22-0.31-0.56-0.51-0.93-0.54l-0.91-0.07l-0.69-0.57
                  c-0.19-0.16-0.42-0.26-0.67-0.28l-0.93-0.1l-0.82-0.54c-0.2-0.13-0.43-0.21-0.67-0.21l-1.29-0.02c-0.59,0-1.09,0.38-1.23,0.94
                  l-0.22,0.85l-0.68,0.66c-0.2,0.19-0.32,0.44-0.37,0.71l-0.11,0.76l-0.57,0.37c-0.1,0.06-0.19,0.14-0.27,0.23l-0.79,0.9l-0.94,0.74
                  c-0.16,0.13-0.29,0.3-0.37,0.49l-0.4,0.93l-0.72,0.76l0.08,0.08c-0.01,0.01-0.01,0.01-0.02,0.02l-1.06,1.15
                  c-0.04,0.05-0.07,0.09-0.11,0.14l-0.72,1.05l-0.77,0.76l-1.06,0.69l-0.82,0.62l-0.99,0.28c-0.07,0.02-0.14,0.05-0.21,0.08
                  l-0.85,0.42l-0.52-0.21l-1.37-0.87c-0.29-0.18-0.63-0.23-0.96-0.16c-0.33,0.08-0.61,0.29-0.78,0.58l-0.46,0.76l-1,0.3l-2.04,0.27
                  c-0.11,0.01-0.22,0.04-0.32,0.09l-1.06,0.44l-1.14,0.16c-0.19,0.03-0.37,0.1-0.53,0.21l-1.74,1.23l-0.83-0.07
                  c-0.31-0.02-0.63,0.07-0.87,0.27l-0.56,0.44l-2.12-0.32c-0.44-0.06-0.89,0.12-1.16,0.48l-0.78,1.01c-0.07,0.09-0.13,0.2-0.18,0.31
                  l-0.34,0.9l-0.02,0.01l-0.33-0.19c-0.1-0.06-0.2-0.1-0.3-0.13l-1.22-0.34c-0.33-0.09-0.67-0.05-0.96,0.12l-1.15,0.66
                  c-0.11,0.06-0.21,0.14-0.3,0.23l-0.92,0.99c-0.13,0.14-0.23,0.31-0.28,0.5l-0.34,1.18l-0.44,1.11c-0.09,0.25-0.11,0.53-0.03,0.8
                  l0.31,1.09l-1.94,0.1l-1.05-0.11l-1.48-0.33c-0.16-0.04-0.33-0.04-0.49-0.01l-1.29,0.23c-0.03,0.01-0.07,0.01-0.1,0.02l-0.89,0.24
                  l-0.81-0.43c-0.1-0.05-0.2-0.09-0.3-0.11l-1.24-0.28c-0.35-0.08-0.72,0-1.01,0.2l-0.33,0.24l-0.36-0.22
                  c-0.48-0.29-1.08-0.22-1.48,0.13c-0.02-0.01-0.04-0.03-0.06-0.05l-0.5-0.35l0.03-0.6c0.03-0.59-0.37-1.13-0.94-1.27l-1.27-0.32
                  c-0.1-0.03-0.21-0.04-0.31-0.04l-2.14,0.02l-1.2-1.61c-0.11-0.15-0.25-0.27-0.42-0.36l-2.26-1.2c-0.09-0.04-0.18-0.08-0.27-0.1
                  l-2.67-0.65c-0.25-0.06-0.5-0.04-0.74,0.06l-1.21,0.48c-0.22,0.08-0.41,0.23-0.55,0.42l-0.73,1c-0.21,0.3-0.29,0.67-0.21,1.02
                  l0.21,0.88l-0.12,0.32l-1.44,0.4l-0.42-0.11l-0.3-0.61c-0.04-0.08-0.09-0.16-0.15-0.23l-0.6-0.74l-0.09-0.89l0.12-1.22
                  c0.02-0.11,0.01-0.23-0.01-0.34l-0.23-1.34c-0.01-0.1-0.04-0.19-0.08-0.29l-0.34-0.78l0.25-0.66l1.08-1.9l0.78-0.2l1.05,0.17
                  c0.66,0.09,1.28-0.33,1.41-0.98l0.37-1.7l0.11,0.01c0.36,0.05,0.72-0.07,0.99-0.32l0.91-0.83c0.16-0.14,0.28-0.32,0.34-0.52
                  l0.41-1.2c0.12-0.34,0.08-0.73-0.11-1.04l-0.73-1.22c-0.05-0.1-0.12-0.18-0.2-0.26l-0.82-0.79l-0.51-0.87l-0.49-1.11
                  c-0.03-0.07-0.07-0.14-0.12-0.21l-0.68-0.98l-0.36-1.08c-0.05-0.13-0.12-0.26-0.2-0.37l-0.62-0.79l-0.04-0.37l1.08-0.04
                  c0.66,0,1.2-0.51,1.25-1.16l0.09-1.27c0.02-0.33-0.08-0.65-0.29-0.9l-0.79-0.93c-0.15-0.17-0.33-0.3-0.54-0.38l-1.06-0.38l-0.62-0.5
                  l-0.18-0.32l0.21-0.39l0.87-1c0.13-0.15,0.22-0.34,0.27-0.53l0.29-1.23c0.01-0.08,0.03-0.16,0.03-0.24l0.04-0.94l0.64-0.76
                  l0.92-0.86l1.01-0.61l0.98-0.53l1.05-0.15c0.11-0.02,0.22-0.05,0.33-0.1l1.41-0.64c0.4-0.19,0.68-0.57,0.72-1.02l0.12-1.21
                  l0.59-0.92c0.17-0.26,0.23-0.57,0.18-0.88l-0.22-1.32c-0.02-0.14-0.07-0.28-0.15-0.41l-0.47-0.82l-0.06-0.75l0.22-0.8l0.62-0.94
                  c0.17-0.26,0.24-0.57,0.2-0.88l-0.19-1.23l0.08-0.94l0.39-0.75l0.79-0.79c0.11-0.11,0.2-0.24,0.26-0.38l0.21-0.48l0.59,0.13
                  c0.09,0.02,0.18,0.03,0.27,0.03c0.1,0,0.2-0.01,0.29-0.03l1.24-0.31c0.23-0.05,0.43-0.16,0.59-0.32l1.19-1.18
                  c0.04-0.05,0.08-0.09,0.12-0.14l0.07-0.09l1.07,0.75c0.28,0.2,0.62,0.27,0.94,0.21c0.33-0.06,0.62-0.25,0.81-0.53l1.49-2.16
                  c0.08-0.12,0.15-0.26,0.18-0.41l0.88,0.27c0.23,0.07,0.48,0.07,0.72,0l0.11-0.04l0.07,0.19c0.08,0.2,0.2,0.38,0.37,0.52l1,0.83
                  c0.31,0.26,0.72,0.35,1.1,0.25l1.32-0.33c0.18-0.04,0.35-0.13,0.49-0.25l0.83-0.69l0.98-0.32c0.53-0.17,0.88-0.67,0.87-1.22
                  l-0.03-1.32c-0.01-0.26-0.09-0.51-0.25-0.72l-0.39-0.52l0.07-0.25l0.67-0.56c0.13-0.11,0.23-0.25,0.31-0.4l0.25-0.5l0.63-0.08
                  c0.56-0.07,1-0.49,1.08-1.04l0.14-0.85l0.42-0.4l1.02-0.42c0.05-0.02,0.1-0.05,0.14-0.07l0.44-0.25l0.35,0.26
                  c0.09,0.06,0.18,0.11,0.28,0.15l1.17,0.46c0.4,0.16,0.85,0.1,1.2-0.15l1.02-0.75c0.28-0.21,0.47-0.53,0.5-0.88l0.13-1.26
                  c0.03-0.23-0.01-0.46-0.11-0.67l-0.56-1.18c-0.07-0.14-0.16-0.27-0.28-0.38l-0.7-0.64l-0.31-0.88c-0.03-0.09-0.07-0.17-0.12-0.24
                  l-0.66-1.06c-0.04-0.05-0.07-0.1-0.11-0.15l-0.77-0.89l-0.36-0.52l0.09-0.35l0.62-0.65c0.35-0.35,0.45-0.86,0.28-1.32l-0.25-0.63
                  l0.1-0.19l0.58-0.06c0.28-0.03,0.54-0.15,0.74-0.34l0.91-0.88c0.07-0.07,0.14-0.15,0.19-0.24l0.67-1.08
                  c0.04-0.06,0.07-0.13,0.1-0.19l0.47-1.17c0.19-0.49,0.07-1.04-0.31-1.39l-0.5-0.46c0.37,0.06,0.75-0.05,1.03-0.31
                  c0.33-0.29,0.47-0.74,0.39-1.17l-0.28-1.36l-0.22-0.86l0.42-1.21l0.66-1.48c0.03-0.06,0.05-0.11,0.06-0.17l0.39-1.39
                  c0.04-0.15,0.06-0.3,0.05-0.45l-0.03-0.33l1.17-0.11c0.15-0.02,0.3-0.05,0.43-0.12l1.19-0.56c0.22-0.1,0.4-0.26,0.53-0.47l0.53-0.84
                  l0.84-0.45c0.07-0.03,0.13-0.08,0.2-0.13l0.99-0.8c0.06-0.05,0.12-0.11,0.17-0.17l0.99-1.2c0.04-0.05,0.09-0.12,0.12-0.18l0.65-1.17
                  l0.77-1.2c0.07-0.11,0.12-0.23,0.15-0.35l2.08-1.41c0.03-0.02,0.07-0.04,0.1-0.07l1.03-0.85c0.05-0.04,0.1-0.09,0.14-0.14l0.95-1.08
                  c0.07-0.08,0.14-0.17,0.19-0.28l0.43-0.9l0.74-0.59l0.97-0.44l1.03-0.1c0.05,0,0.11-0.01,0.16-0.02l1.08-0.25l0.39,0.04l0.88,1.65
                  l0.32,1.22l0.37,1.72c0.03,0.16,0.09,0.3,0.18,0.43l0.71,1.07c0.09,0.13,0.2,0.25,0.33,0.34l1.09,0.75l1.17,0.73
                  c0.03,0.02,0.06,0.04,0.1,0.06l2.42,1.19c0.09,0.04,0.19,0.07,0.29,0.09l1.22,0.25c0.03,0.01,0.07,0.02,0.1,0.02l0.83,0.1l0.61,0.59
                  c0.18,0.18,0.4,0.3,0.65,0.34l1.35,0.24c0.26,0.04,0.52,0.01,0.75-0.1l1.21-0.57c0.29-0.14,0.52-0.38,0.64-0.68l0.38-1l1.5-0.69
                  l0.68,0.07l0.59,0.33l0.39,0.82c0.2,0.4,0.59,0.67,1.03,0.71l0.19,0.01l0.01,0.18l-0.14,1.16c-0.02,0.19,0,0.39,0.06,0.57l0.34,0.95
                  l-0.09,1.02c-0.03,0.29,0.04,0.57,0.19,0.8l0.64,0.96l0.59,1.09c0.05,0.08,0.1,0.15,0.16,0.22l0.65,0.74l0.29,0.89l0.08,1.12
                  c0.04,0.56,0.46,1.03,1.02,1.14l1.33,0.25c0.04,0.01,0.07,0.01,0.11,0.01l1.54,0.16l1.12,0.26c0.44,0.1,0.9-0.04,1.21-0.38
                  l0.82-0.91l0.72-0.21l0.03,0.12c0.01,0.06,0.03,0.11,0.05,0.17l0.43,1.18c0.15,0.42,0.52,0.73,0.96,0.8
                  c0.45,0.08,0.89-0.09,1.18-0.44l0.79-0.97l1.45-1.44l0.39-0.01l0.74,0.47c0.09,0.05,0.18,0.09,0.27,0.13l1.79,0.59l0.99,0.59
                  l0.83,0.58l0.49,1.08c0.05,0.1,0.11,0.19,0.18,0.28l0.71,0.86l0.08,1.25c0.05,0.64,0.56,1.14,1.19,1.17l1.04,0.05l0.95,0.5
                  c0.1,0.06,0.21,0.1,0.32,0.12l0.97,0.22l1.87,1.85l0.89,0.93l0.49,0.66l-0.23,0.44c-0.06,0.12-0.11,0.25-0.13,0.39l-0.18,1.22
                  c-0.01,0.12-0.01,0.24,0,0.36l0.18,1.22c0.01,0.06,0.02,0.12,0.04,0.17l0.15,0.51l-0.11,0.11l-2.25,0.55l-1.16,0.39
                  c-0.2,0.06-0.37,0.18-0.52,0.33l-0.88,0.95c-0.14,0.16-0.25,0.35-0.29,0.56l-0.31,1.25c-0.02,0.08-0.03,0.17-0.03,0.25l-0.04,1.17
                  l-0.18,1.15c-0.02,0.14-0.01,0.28,0.01,0.41l0.22,1.21c0.01,0.05,0.02,0.1,0.04,0.15l0.31,1l-0.03,0.92l-0.21,1.07l-0.22,0.67
                  l-0.68,0.41c-0.32,0.18-0.53,0.5-0.59,0.87l-0.19,1.15l-0.34,1.33l-0.24,1.27c-0.01,0.08-0.02,0.17-0.02,0.26l0.03,1.27
                  c0,0.12,0.02,0.24,0.06,0.36l0.43,1.31c0.14,0.41,0.48,0.73,0.91,0.83l0.56,0.13l0.21,0.53c0.02,0.06,0.05,0.11,0.08,0.16l0.55,0.96
                  l0.08,1.02c0.01,0.03,0.01,0.07,0.02,0.1l0.21,1.23c0.01,0.09,0.04,0.18,0.07,0.26l0.38,0.94l-0.02,0.98c0,0.11,0.01,0.22,0.04,0.32
                  l0.33,1.36c0.06,0.25,0.19,0.48,0.38,0.65l0.78,0.68l0.57,0.95c0.08,0.13,0.19,0.25,0.32,0.35l1.08,0.81
                  c0.06,0.05,0.13,0.09,0.2,0.12l0.81,0.39L622.01,534.27z"/>
                <title>Krupina</title>
              </Link>

              {/*Velky krtis - banskobystricky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Veľký Krtíš")}>
                <path style={{ fill: crimesInRegionBanskobystricky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionBanskobystricky[5].crimePercent)] ? crimesInRegionBanskobystricky[5].color : "#D3D3D3" }}
                  d="M706.37,531.06l-0.51-0.06c-0.2-0.02-0.4,0-0.59,0.07l-1.3,0.5c-0.3,0.12-0.54,0.35-0.68,0.64l-0.53,1.11
                  c-0.1,0.22-0.14,0.47-0.11,0.71l0.34,2.77c0,0.05,0.01,0.11,0.03,0.16l0.3,1.21c0.02,0.09,0.05,0.18,0.1,0.27l0.46,0.9l-0.13,0.9
                  l-0.81,2.44c-0.03,0.09-0.05,0.18-0.06,0.28l-0.1,1.25c-0.01,0.19,0.02,0.38,0.09,0.56l0.48,1.21c0.03,0.07,0.06,0.13,0.1,0.2
                  l0.61,1l0.36,0.77l-0.04,0.73l-0.39,1.03c-0.02,0.06-0.04,0.13-0.06,0.19l-0.25,1.24c0,0.04-0.01,0.08-0.01,0.11l-0.11,1l-0.63,1.1
                  c-0.06,0.1-0.11,0.21-0.13,0.32l-0.08,0.31c-0.16,0.07-0.31,0.18-0.43,0.32l-0.59,0.68l-0.92,0.32l-1.15,0.48
                  c-0.27,0.11-0.49,0.31-0.62,0.56l-0.58,1.08c-0.19,0.35-0.2,0.77-0.03,1.12l0.55,1.18l0.44,0.82l-0.07,0.77l-0.2,0.78l-0.66,0.69
                  c-0.12,0.13-0.22,0.29-0.28,0.46l-0.13,0.39l-0.42,0.04c-0.31,0.02-0.6,0.17-0.81,0.4l-0.8,0.86l-0.98,0.72
                  c-0.12,0.09-0.23,0.2-0.31,0.33l-0.68,1.04l-0.73,0.93c-0.35,0.46-0.35,1.1,0.01,1.55l0.69,0.86l0.34,0.89l0.34,1.14l0.16,0.93
                  l-0.21,0.97c-0.06,0.26-0.03,0.53,0.07,0.78l0.3,0.66l-0.97,2.39l-0.61,0.19c-0.26,0.08-0.48,0.24-0.64,0.46l-0.58,0.8l-0.81,0.31
                  c-0.19,0.08-0.37,0.2-0.51,0.36l-0.81,0.96c-0.42,0.51-0.38,1.26,0.09,1.71l0.55,0.52l0.06,0.69l-0.06,1.17l-0.28,1.06
                  c-0.04,0.16-0.05,0.32-0.03,0.47l0.14,1.15l-0.01,0.84l-0.44,0.81c-0.12,0.23-0.17,0.49-0.14,0.75l0.1,0.89l-0.37,0.79
                  c-0.19,0.42-0.14,0.9,0.13,1.27v0.01l-1.08,0.93c-0.1,0.09-0.19,0.21-0.26,0.33l-0.61,1.09c-0.25,0.44-0.21,0.99,0.11,1.38
                  l0.03,0.05l-0.05,0.05c-0.08,0.06-0.15,0.14-0.2,0.22l-0.52,0.71l-0.66,0.23l-1.06,0.12c-0.31,0.03-0.59,0.17-0.79,0.4l-0.87,0.95
                  c-0.14,0.14-0.23,0.32-0.29,0.51l-0.34,1.23c0,0.01-0.01,0.03-0.01,0.05c-0.01-0.02-0.03-0.02-0.04-0.03l-0.8-0.55l-0.5-0.79
                  l-0.05-0.1l0.23-0.16c0.43-0.3,0.62-0.84,0.48-1.35l-0.32-1.18c-0.12-0.45-0.48-0.79-0.94-0.89l-2.47-0.58
                  c-0.29-0.07-0.59-0.04-0.85,0.08l-0.88,0.42l-0.99,0.04c-0.5,0.02-0.93,0.33-1.11,0.79l-0.44,1.12l-0.49,0.88l-0.8,0.66
                  c-0.12,0.1-0.22,0.22-0.3,0.36l-0.29,0.53l-0.64,0.08c-0.08,0.01-0.16,0.03-0.24,0.06l-0.76,0.25l-0.79-0.26
                  c-0.34-0.12-0.72-0.08-1.03,0.1l-0.99,0.57l-1.09,0.39c-0.17,0.06-0.32,0.16-0.44,0.28l-1.68-1.22l-0.76-0.83
                  c-0.17-0.19-0.41-0.33-0.66-0.38l-1.21-0.26c-0.24-0.05-0.48-0.03-0.71,0.05l-1.11,0.42l-1.18,0.3c-0.38,0.09-0.68,0.35-0.84,0.69
                  l-0.79-0.07l-0.57-0.54c-0.33-0.3-0.79-0.42-1.22-0.29l-1.07,0.32l-0.95,0.11l-0.98-0.23c-0.2-0.05-0.41-0.05-0.61,0l-1,0.27
                  l-1.22-0.18l-1.18-0.32c-0.18-0.06-0.38-0.06-0.56-0.03l-1.43,0.27c-0.1,0.02-0.2,0.05-0.29,0.09l-1.28,0.59l-1.06,0.23l-1.05-0.27
                  c-0.03-0.01-0.06-0.02-0.1-0.02l-1.21-0.21c-0.24-0.04-0.48-0.01-0.71,0.08l-1.28,0.55c-0.03,0.02-0.06,0.03-0.09,0.05l-3.21,1.72
                  c-0.1,0.06-0.2,0.13-0.28,0.21l-1.06,1.04c-0.15,0.15-0.26,0.33-0.32,0.54l-0.37,1.27l-0.29,0.28h-0.98l-1.41-0.1
                  c-0.18-0.01-0.35,0.01-0.52,0.08l-1.82,0.66c-0.11,0.04-0.22,0.09-0.32,0.17l-1.03,0.77c-0.18,0.14-0.33,0.32-0.41,0.53l-0.43,1.07
                  l-0.59,0.95c-0.06,0.09-0.11,0.2-0.14,0.31l-0.37,1.25c-0.03,0.1-0.05,0.21-0.05,0.32l-0.02,0.82l-0.51,0.59l-0.3,0.28l-0.71-0.27
                  c-0.05-0.02-0.09-0.03-0.14-0.04l-1.19-0.3l-0.82-0.67l-0.48-0.93c-0.15-0.27-0.39-0.49-0.68-0.59l-2.32-0.9
                  c-0.15-0.06-0.31-0.09-0.47-0.09h-1.32c-0.1,0-0.19,0.01-0.29,0.03l-1.16,0.27l-1.27,0.14c-0.11,0.01-0.22,0.04-0.33,0.08l-1.99,0.8
                  l-0.92-0.27c-0.11-0.03-0.22-0.05-0.33-0.05l-0.9-0.01l-0.79-0.52c-0.14-0.09-0.3-0.15-0.47-0.18l-0.98-0.17l-0.87-0.52
                  c-0.17-0.11-0.37-0.17-0.57-0.18l-1.24-0.07c-0.08,0-0.15,0-0.22,0.01l-0.89,0.11l-0.74-0.41c-0.04-0.02-0.09-0.04-0.14-0.06
                  l-1.13-0.45l-0.63-0.3l-0.23-0.67c-0.13-0.4-0.47-0.71-0.88-0.81l-1.25-0.31c-0.03-0.01-0.07-0.02-0.1-0.02l-1.3-0.21l-1.26-0.11
                  c-0.35-0.03-0.71,0.08-0.96,0.33l-1.82,1.64c-0.12,0.1-0.22,0.23-0.29,0.37l-0.25,0.48h-0.46c-0.07,0-0.13,0.01-0.19,0.02
                  l-1.13,0.18l-1.03-0.01l-1.02-0.32l-0.91-0.88c-0.05-0.05-0.1-0.09-0.15-0.13l-1.18-0.83c-0.17-0.12-0.36-0.19-0.56-0.22l-1.54-0.2
                  c-0.35-0.05-0.71,0.06-0.98,0.3l-1.06,0.92c-0.15,0.13-0.27,0.29-0.34,0.47l-0.3,0.74l-0.72,0.39l-1.14,0.19
                  c-0.21,0.03-0.41,0.12-0.58,0.25l-1.14,0.91c-0.15,0.12-0.27,0.27-0.35,0.44l-0.39,0.81l-1.04-1.19l-0.91-1.21
                  c-0.35-0.46-0.96-0.63-1.49-0.4l-0.71,0.3l-0.84-0.32c-0.16-0.07-0.34-0.1-0.5-0.09c-0.11-0.2-0.27-0.37-0.48-0.48l-1.06-0.62
                  l-0.67-0.87c-0.22-0.29-0.56-0.46-0.9-0.49c-0.08-0.11-0.17-0.22-0.28-0.3l-0.97-0.76c-0.14-0.11-0.29-0.18-0.45-0.22l-0.15-1.04
                  l-0.35-1.66l0.05-1.45l0.15-0.58l0.45-0.24l1.01-0.2c0.25-0.05,0.48-0.18,0.65-0.36l0.89-0.91c0.34-0.34,0.45-0.84,0.29-1.29
                  l-0.29-0.83l0.19-0.76l0.57-0.8l0.71-0.68l1-0.52c0.07-0.03,0.12-0.07,0.18-0.11l0.89-0.67l1.02-0.46c0.06-0.03,0.12-0.07,0.18-0.1
                  l1.08-0.73c0.22-0.15,0.38-0.36,0.47-0.61l0.38-1.03l1.55-1.91c0.03-0.03,0.05-0.07,0.08-0.11l0.66-1.05
                  c0.04-0.07,0.08-0.14,0.1-0.21l0.47-1.2l0.54-1.12c0.03-0.07,0.06-0.16,0.08-0.24l0.37-1.5c0.03-0.11,0.04-0.23,0.04-0.34
                  l-0.05-1.38c0-0.07-0.01-0.14-0.03-0.21l-0.3-1.47c-0.04-0.22-0.14-0.42-0.29-0.59l-0.53-0.58l-0.03-0.88
                  c-0.02-0.4-0.23-0.78-0.57-1l-0.58-0.37l-0.08-0.64c-0.02-0.15-0.07-0.29-0.13-0.42l-0.6-1.14c-0.05-0.08-0.1-0.16-0.16-0.23
                  l0.8-0.59c0.26-0.19,0.44-0.48,0.49-0.8l0.19-1.13l0.38-1.09c0.1-0.27,0.09-0.57,0-0.84l-0.42-1.13l0.09-0.71l0.39-0.76l0.58-0.56
                  l0.93-0.4c0.57-0.24,0.87-0.86,0.72-1.46l-0.31-1.22c-0.05-0.18-0.13-0.35-0.25-0.5l0.48-0.44c0.33-0.3,0.47-0.76,0.37-1.2
                  l-0.33-1.46c-0.07-0.3-0.25-0.56-0.5-0.74l-3.51-2.43c0.06-0.08,0.11-0.16,0.15-0.25l0.36-0.85l0.78-0.61
                  c0.06-0.05,0.11-0.1,0.16-0.16l0.75-0.85l0.9-0.58c0.31-0.19,0.51-0.51,0.57-0.86l0.13-0.91l0.64-0.61
                  c0.17-0.16,0.29-0.37,0.34-0.59l0.05-0.2l0.72,0.47c0.17,0.11,0.36,0.18,0.56,0.2l0.85,0.09l0.71,0.59c0.2,0.17,0.45,0.27,0.7,0.29
                  l0.71,0.05l0.41,0.59c0.12,0.16,0.27,0.3,0.44,0.39l0.86,0.47l0.6,0.77c0.2,0.25,0.48,0.41,0.79,0.46l1.27,0.21
                  c0.11,0.02,0.23,0.03,0.35,0.01l1.27-0.15c0.45-0.05,0.85-0.35,1.01-0.78l0.39-0.99l1.41-1.87c0.15-0.21,0.24-0.46,0.24-0.72
                  l0.01-0.78l0.51-0.6l0.71-0.7c0.08,0.11,0.17,0.21,0.29,0.3l1.03,0.76c0.21,0.15,0.46,0.23,0.71,0.24l1.66,0.05l0.03,0.25
                  c0.02,0.1,0.05,0.2,0.09,0.3l0.51,1.19c0.14,0.33,0.42,0.59,0.76,0.7l1.29,0.42c0.13,0.04,0.28,0.06,0.41,0.06l1.27-0.02
                  c0.32,0,0.63-0.14,0.86-0.37l0.9-0.9c0.15-0.14,0.25-0.31,0.31-0.5l0.4-1.27c0.03-0.09,0.05-0.19,0.06-0.28l0.18-2.38l0.47-0.9
                  c0.08-0.16,0.13-0.33,0.14-0.5l0.05-0.81l0.07-0.08l0.11,0.03l0.32,0.49l0.22,1c0.07,0.31,0.25,0.58,0.51,0.76l1.02,0.7
                  c0.3,0.21,0.67,0.27,1.03,0.18c0.35-0.09,0.64-0.33,0.8-0.66l0.57-1.15c0.02-0.05,0.05-0.1,0.06-0.15l0.35-1.03l0.65-0.87
                  c0.07-0.09,0.13-0.19,0.17-0.3l0.28-0.74l0.49-0.26l1.12-0.16c0.29-0.05,0.56-0.2,0.76-0.42l0.4-0.47l0.59,0.05h0.15l1.29-0.05
                  c0.33-0.01,0.64-0.16,0.86-0.39l0.92-0.98c0.11-0.12,0.19-0.25,0.25-0.39l0.5-1.22c0.04-0.09,0.06-0.19,0.08-0.29l0.14-0.98
                  l0.3-0.42l0.86-0.26c0.35-0.11,0.63-0.37,0.78-0.71l0.49-1.14c0.16-0.38,0.12-0.83-0.11-1.18l-0.68-1.03
                  c-0.12-0.19-0.29-0.34-0.49-0.43l-1.01-0.49l-0.79-0.59l-0.54-0.91c-0.07-0.1-0.15-0.2-0.24-0.29l-0.63-0.55l-0.2-0.82l0.03-1.07
                  c0-0.17-0.03-0.34-0.09-0.5l-0.43-1.06l-0.18-1.04l-0.1-1.24c-0.02-0.18-0.07-0.35-0.17-0.51l-0.64-1.12l-0.42-1.06
                  c-0.15-0.38-0.48-0.66-0.88-0.76l-0.51-0.12l-0.15-0.46l-0.03-0.96l0.2-1.06l0.33-1.3c0.01-0.04,0.02-0.07,0.02-0.11l0.11-0.63
                  l0.59-0.36c0.26-0.15,0.46-0.4,0.55-0.69l0.38-1.18c0.01-0.04,0.03-0.09,0.04-0.14l0.25-1.25c0.01-0.07,0.02-0.14,0.02-0.21
                  l0.03-1.24c0-0.14-0.02-0.27-0.06-0.4l-0.34-1.12l-0.17-0.93l0.16-1.01c0-0.05,0.01-0.1,0.01-0.15l0.04-1.12l0.19-0.81l0.44-0.48
                  l0.77-0.26l2.45-0.58c0.25-0.05,0.48-0.18,0.65-0.37l0.86-0.92c0.31-0.32,0.41-0.78,0.29-1.2l-0.33-1.11l-0.14-0.95l0.05-0.3
                  c0.04,0.03,0.07,0.05,0.11,0.08l1.04,0.65l0.43,0.88c0.2,0.4,0.6,0.66,1.04,0.69l0.57,0.03l0.08,0.3l0.1,1.43
                  c0.03,0.39,0.25,0.75,0.58,0.97l0.87,0.55l0.35,0.85c0.03,0.06,0.06,0.12,0.09,0.17l0.63,1.05l0.44,0.86v0.93l-0.12,1.16
                  c-0.03,0.28,0.03,0.56,0.19,0.8l0.49,0.78l0.05,0.91c0,0.04,0.01,0.07,0.01,0.11l0.15,1.03l-0.18,1.05
                  c-0.03,0.17-0.03,0.34,0.01,0.51l0.35,1.49l0.24,0.75l-0.34,0.64c-0.27,0.52-0.14,1.16,0.31,1.54l0.64,0.53l0.24,0.92
                  c0.1,0.35,0.34,0.64,0.68,0.8l1.1,0.52l0.83,0.5l0.58,0.88c0.04,0.06,0.09,0.12,0.14,0.17l0.95,1c0.33,0.34,0.84,0.47,1.3,0.32
                  l1.35-0.46l0.44-0.04l0.31,0.43c0.07,0.1,0.15,0.19,0.25,0.26l1.03,0.81c0.07,0.06,0.15,0.11,0.24,0.15l1.13,0.53
                  c0.11,0.05,0.23,0.09,0.36,0.11l0.99,0.14l0.8,0.55c0.11,0.08,0.23,0.13,0.36,0.17l1.3,0.38c0.17,0.05,0.34,0.06,0.52,0.04
                  l2.34-0.34l1.18,0.33c0.09,0.03,0.19,0.04,0.28,0.05l1.26,0.06h0.06c0.39,0,0.76-0.18,1-0.5l0.35-0.46l0.78,0.03
                  c0.3,0.02,0.6-0.09,0.84-0.29l1.23-1.02c0.32-0.27,0.49-0.68,0.44-1.1c-0.04-0.42-0.3-0.78-0.67-0.97l-1.29-0.66l-0.45-0.33
                  l-0.03-0.66c-0.01-0.29-0.13-0.56-0.32-0.78l-0.88-0.98l-0.53-1.01l-0.34-1.21l-0.25-1.35l-0.05-1.16l0.05-1.25l0.18-1.43
                  c0.02-0.14,0.01-0.28-0.02-0.41l-0.24-1.22l-0.01-0.16l0.13-0.02c0.29-0.04,0.55-0.18,0.74-0.4l0.84-0.92
                  c0.1-0.11,0.18-0.23,0.23-0.36l0.42-1.01l0.67-0.88c0.13-0.17,0.21-0.36,0.24-0.56l0.21-1.29l0.16-1.44l0.7-1.78l1.68-0.92
                  l1.01-0.37l1.12-0.26c0.36-0.09,0.66-0.32,0.83-0.64l1.14-2.24c0.07-0.13,0.11-0.28,0.12-0.44l0.12-1.27l0.33-1.12
                  c0.05-0.16,0.06-0.33,0.04-0.5l-0.15-1.26c-0.03-0.29-0.17-0.57-0.39-0.77l-0.92-0.85c-0.13-0.12-0.29-0.21-0.46-0.27l-1.18-0.38
                  l-0.75-0.58l-1.5-1.71l-0.12-0.16l0.91,0.13c0.05,0.01,0.11,0.01,0.16,0.01l1.25,0.02c0.23,0,0.48-0.06,0.68-0.19l1.23-0.77
                  l0.99-0.73c0.05-0.04,0.09-0.08,0.13-0.12l0.88-0.86c0.03-0.03,0.06-0.06,0.09-0.09l0.74-0.88l1.03-0.86
                  c0.02-0.02,0.05-0.04,0.07-0.07l0.58,1.66c0.05,0.13,0.12,0.26,0.21,0.36l0.83,1.03l0.69,0.78l0.17,0.98c0.02,0.1,0.05,0.2,0.09,0.3
                  l0.55,1.24c0.13,0.31,0.39,0.55,0.71,0.66l1.05,0.39l0.58,0.62l0.72,1.04c0.15,0.21,0.36,0.37,0.6,0.46l1.24,0.46l1.18,0.35
                  c0.27,0.08,0.56,0.07,0.82-0.04l0.56-0.22l-0.22,0.36c-0.02,0.03-0.04,0.07-0.05,0.1l-1.7,3.5c-0.1,0.21-0.15,0.44-0.12,0.67
                  l0.1,1.16l-0.16,1.08c-0.02,0.16-0.01,0.33,0.03,0.49l0.34,1.31c0.08,0.33,0.3,0.61,0.59,0.77c0.29,0.17,0.63,0.22,0.96,0.12
                  l1.25-0.35c0.04-0.01,0.08-0.02,0.12-0.04l0.94-0.37l1.1,0.06c0.14,0.01,0.29-0.01,0.43-0.06l1.25-0.39
                  c0.08-0.02,0.16-0.06,0.24-0.1l0.32-0.18l0.32,0.92c0.04,0.14,0.1,0.26,0.18,0.37l0.58,0.83l0.16,1.08l0.29,1.33l0.3,1.15l0.26,1.21
                  c0.08,0.39,0.34,0.71,0.7,0.88l0.89,0.4l0.4,0.53l0.22,0.99c0.02,0.07,0.04,0.14,0.07,0.21l0.51,1.23c0.18,0.45,0.61,0.75,1.09,0.77
                  l1.05,0.05l-0.05,0.28c-0.06,0.32,0.01,0.65,0.2,0.92l0.72,1.06c0.15,0.23,0.38,0.4,0.64,0.49l1.04,0.34l0.63,0.73l0.26,1.48
                  l-0.1,1.12c-0.02,0.14-0.01,0.28,0.02,0.41l0.34,1.43c0.01,0.04,0.03,0.09,0.04,0.13l0.4,1.11l0.22,1.17
                  c0.04,0.19,0.12,0.38,0.24,0.53l0.56,0.73l0.13,0.91l-0.01,1.16c0,0.5,0.29,0.95,0.74,1.15l1.16,0.52c0.06,0.03,0.12,0.05,0.19,0.07
                  l1.25,0.33l1.22,0.27c0.15,0.03,0.32,0.04,0.47,0.01l1.21-0.2c0.63-0.1,1.08-0.66,1.05-1.29l-0.05-1.01l0.16-0.42l0.52-0.19
                  l1.01-0.12l0.79,0.01l0.46,0.52c0.1,0.11,0.21,0.21,0.34,0.28l0.82,0.44l0.42,0.65l0.4,1.06c0.07,0.17,0.17,0.32,0.3,0.45
                  L706.37,531.06z"/>
                <title>Veľký Krtíš</title>
              </Link>

              {/* Banovce nad bebravou - trenciansky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Bánovce nad Bebravou")}>
                <path style={{ fill: crimesInRegionTrenciansky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionTrenciansky[0].crimePercent)] ? crimesInRegionTrenciansky[0].color : "#D3D3D3" }}
                  d="M430.63,414.32c-0.05,0.06-0.08,0.13-0.1,0.2l-0.85,2.58l-0.46,1.09c-0.01,0.03-0.03,0.07-0.04,0.11l-0.36,1.14
                  l-0.39,0.99l-0.49,0.46l-0.9,0.17c-0.11,0.02-0.22,0.06-0.32,0.11l-0.14-0.33l-1.09,0.47l-1.37,0.23c-0.42,0.06-0.78,0.35-0.95,0.74
                  l-0.46,1.08l-0.49,0.85l-0.64,0.59l-1.08,0.44c-0.06,0.03-0.11,0.05-0.16,0.08l-1.18,0.69c-0.21,0.12-0.38,0.31-0.49,0.52
                  l-0.52,1.06l-1.72,1.21l-1.09,0.31c-0.04,0.01-0.08,0.02-0.12,0.04l-1.33,0.53c-0.05,0.02-0.09,0.04-0.13,0.06l-1.84,0.98
                  l-1.09,0.37c-0.19,0.06-0.36,0.17-0.5,0.32l-1.01,1.06c-0.06,0.05-0.11,0.12-0.15,0.18l-0.55,0.86l-0.92,0.57
                  c-0.04,0.02-0.08,0.05-0.12,0.07l-1.03,0.8c-0.07,0.06-0.14,0.13-0.21,0.21l-0.71,0.89l-1.05,0.84c-0.08,0.07-0.16,0.15-0.22,0.23
                  l-0.91,1.22l-0.6,0.93l-0.82,0.59l-1.08,0.55l-1.23,0.54c-0.1,0.04-0.2,0.1-0.28,0.17l-0.93,0.74l-0.98,0.29
                  c-0.47,0.14-0.82,0.54-0.89,1.02l-0.06,0.42l-0.74-0.31l-0.55-0.44l-0.32-1.01c-0.01-0.06-0.03-0.11-0.06-0.17l-0.55-1.16
                  c-0.24-0.49-0.77-0.78-1.31-0.7s-0.97,0.51-1.05,1.05l-0.2,1.3l-0.09,0.86l-0.42,0.51l-0.49,0.28l-0.41-0.06l-0.74-0.66
                  c-0.13-0.12-0.29-0.21-0.46-0.27l-2.62-0.77l-1.09-0.24l-0.57-0.32l-0.15-0.38l0.26-0.71c0.16-0.41,0.08-0.88-0.2-1.22l-0.54-0.66
                  l-0.09-0.88c-0.01-0.12-0.04-0.25-0.09-0.36l-0.39-0.95l-0.05-1.03c-0.01-0.25-0.1-0.5-0.26-0.7l-0.58-0.76l-0.23-1.02
                  c-0.07-0.32-0.27-0.6-0.56-0.78l-0.77-0.48l-0.41-0.83c-0.02-0.04-0.05-0.08-0.07-0.12l-0.66-1.01l-0.45-1.01l-0.41-1.29
                  c-0.01-0.03-0.02-0.06-0.03-0.09l-0.52-1.27c-0.02-0.05-0.04-0.1-0.07-0.14l-0.62-1.11c-0.06-0.11-0.14-0.21-0.23-0.29l-0.91-0.87
                  c-0.1-0.1-0.22-0.18-0.35-0.24l-0.91-0.41l-0.72-0.72c-0.1-0.11-0.22-0.2-0.35-0.26l-0.83-0.39l-0.45-0.81
                  c-0.14-0.24-0.36-0.43-0.62-0.54l-1.4-0.57l-1.81-1.51c-0.18-0.15-0.4-0.25-0.63-0.28l-1.26-0.17c-0.24-0.03-0.48,0.01-0.7,0.11
                  l-1.13,0.52l-1.07,0.3l-1.18,0.11c-0.08,0-0.15,0.02-0.23,0.04l0.82-1.81l0.47-1.18c0.12-0.29,0.12-0.62,0-0.92l-0.5-1.27
                  c-0.13-0.34-0.42-0.61-0.77-0.73c-0.35-0.11-0.74-0.07-1.05,0.13l-0.73,0.46l-1.28-1.07l-0.51-0.73l-0.07-0.31l4.2-4.13h0.74
                  c0.45,0,0.87-0.25,1.09-0.64l1.26-2.26c0.08-0.14,0.13-0.3,0.15-0.46l0.12-0.98l1.27-0.9c0.2-0.15,0.35-0.35,0.44-0.58l0.61-1.62
                  l0.64-0.94c0.36-0.54,0.27-1.25-0.22-1.67l-0.91-0.75l-0.86-0.9l-1.86-1.69l-2.63-3.2c-0.03-0.03-0.05-0.06-0.08-0.09l-0.97-0.98
                  c-0.32-0.32-0.8-0.45-1.23-0.32l-1.06,0.3l-0.75,0.01l-0.74-0.4l0.04-0.07c0.2-0.31,0.24-0.69,0.13-1.04l-0.28-0.84l0.31-1.14
                  c0.01-0.04,0.02-0.08,0.02-0.12l0.24-1.46c0.06-0.34-0.03-0.69-0.24-0.96l-0.76-0.99l-0.13-0.21l0.72-0.19l1.16-0.15
                  c0.22-0.03,0.43-0.12,0.61-0.25l0.66-0.52l0.45,0.03l0.81,0.48c0.03,0.02,0.07,0.04,0.1,0.06l1.11,0.53
                  c0.2,0.09,0.42,0.13,0.64,0.12l1.01-0.09l0.76,0.22l0.9,0.66c0.1,0.08,0.21,0.14,0.33,0.18l1.22,0.42c0.27,0.09,0.56,0.09,0.83,0
                  l1.08-0.38l1.11-0.19c0.28-0.04,0.53-0.18,0.72-0.38l0.7-0.76l0.94-0.55c0.27-0.16,0.48-0.42,0.57-0.73l0.36-1.24l0.82-2.18
                  l0.23-0.31l0.24,0.01l0.91,0.55l1.22,0.64c0.21,0.11,0.44,0.16,0.68,0.14l1.35-0.11c0.15-0.02,0.3-0.06,0.44-0.12l1.38-0.66
                  l-0.09,0.57c-0.09,0.51,0.15,1.01,0.59,1.27l1.04,0.62l1.06,0.78l0.56,0.7l0.46,1.26l0.5,1.12c0.2,0.44,0.63,0.73,1.11,0.74
                  l1.36,0.03c0.41,0.03,0.81-0.19,1.05-0.53l0.73-1.04c0.11-0.15,0.18-0.33,0.21-0.51l0.2-1.14l0.39-0.62l0.82-0.53
                  c0.33-0.21,0.54-0.55,0.57-0.93l0.12-1.27c0.01-0.09,0.01-0.19,0-0.28l-0.15-1.15l0.2-1.28l0.38-1.1c0.05-0.13,0.07-0.28,0.07-0.42
                  l-0.07-3.95c0-0.24-0.07-0.47-0.2-0.67l-1.25-1.85l0.02-0.31l0.34-0.19l1.05-0.19c0.06-0.01,0.12-0.03,0.18-0.05l1.17-0.41
                  c0.3-0.11,0.55-0.32,0.7-0.6l0.58-1.1c0.09-0.18,0.14-0.38,0.14-0.59v-0.48l0.4-0.2c0.17-0.08,0.32-0.2,0.43-0.35l0.55-0.69
                  l0.47-0.11l0.86,0.27c0.18,0.06,0.37,0.07,0.56,0.05l0.52-0.08l0.16,0.38c0.02,0.05,0.05,0.11,0.08,0.16l0.63,1.04l0.48,1.05
                  c0.06,0.12,0.14,0.24,0.23,0.33l1.05,1.13c0.23,0.25,0.56,0.39,0.9,0.39c0.01,0,0.01,0,0.01,0c0.34,0,0.67-0.14,0.9-0.39l0.57-0.59
                  l1.93-0.38l0.69,0.18l0.09,0.1l-0.16,0.44c-0.08,0.2-0.1,0.43-0.06,0.64l0.21,1.21c0.02,0.15,0.07,0.29,0.15,0.41l1.35,2.35
                  c0.05,0.1,0.12,0.19,0.2,0.26l0.92,0.92c0.2,0.21,0.47,0.33,0.75,0.36l2.53,0.3c0.41,0.05,0.81-0.1,1.08-0.4l0.58-0.63l2.07-0.44
                  c0.31-0.06,0.58-0.24,0.76-0.5l0.33-0.47l0.57,0.04l0.78,0.21l0.45,0.68l0.26,0.98l0.02,0.86l-0.39,1.13
                  c-0.11,0.3-0.09,0.64,0.04,0.93l0.41,0.9l-0.21,2.35c-0.03,0.3,0.05,0.6,0.22,0.84l0.65,0.92l0.44,1.08
                  c0.02,0.05,0.04,0.09,0.07,0.14l0.17,0.3l-0.31,0.33c-0.11,0.13-0.2,0.27-0.26,0.44l-0.34,0.96l-1.72,1.31
                  c-0.07,0.06-0.14,0.13-0.2,0.2l-0.55,0.69l-0.76,0.14c-0.05,0.01-0.09,0.02-0.13,0.03l-1.19,0.35c-0.38,0.11-0.68,0.39-0.82,0.76
                  c-0.14,0.36-0.1,0.77,0.11,1.1l0.67,1.07c0.08,0.13,0.17,0.23,0.29,0.32l0.77,0.61l0.47,0.88c0.06,0.12,0.15,0.24,0.26,0.34
                  l1.86,1.68c0.03,0.03,0.06,0.05,0.1,0.08l1.11,0.82c0.03,0.02,0.07,0.05,0.11,0.07l0.97,0.57l0.85,0.74
                  c0.05,0.05,0.1,0.09,0.16,0.12l0.77,0.48l0.09,0.17l-0.96,1.48c-0.07,0.1-0.12,0.21-0.15,0.33l-0.43,1.48
                  c-0.04,0.12-0.05,0.24-0.05,0.37l0.02,1.29c0,0.21,0.06,0.42,0.17,0.6l1.28,2.21l0.27,0.8l-0.2,0.85c-0.05,0.22-0.04,0.46,0.03,0.68
                  l0.39,1.17c0.06,0.16,0.15,0.31,0.26,0.44l0.88,0.97c0.08,0.08,0.17,0.16,0.27,0.22l1.14,0.72l0.87,0.75
                  c0.04,0.04,0.09,0.08,0.14,0.11l0.94,0.61l1.16,2.65c0.03,0.06,0.06,0.11,0.09,0.17L430.63,414.32z"/>
                <title>Bánovce nad Bebravou</title>
              </Link>

              {/* Partizanske - trenciansky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Partizánske")}>
                <path style={{ fill: crimesInRegionTrenciansky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionTrenciansky[2].crimePercent)] ? crimesInRegionTrenciansky[2].color : "#D3D3D3" }}
                  d="M415.29,335.67l-0.54,0.36c-0.15,0.11-0.28,0.24-0.37,0.4l-0.63,1.06c-0.12,0.2-0.18,0.42-0.18,0.65l0.01,1.39
                  l-0.01,1.19l-0.11,0.62l-0.69,0.51c-0.2,0.15-0.35,0.35-0.43,0.57l-0.18,0.48l-0.64,0.05c-0.17,0.02-0.33,0.07-0.48,0.15l-1.1,0.59
                  c-0.18,0.09-0.33,0.23-0.45,0.4l-0.72,1.07l-0.38,0.43l-0.78-0.06l-1.06-0.55c-0.23-0.12-0.48-0.16-0.73-0.13l-1.25,0.15
                  c-0.2,0.02-0.4,0.1-0.56,0.21l-1.14,0.8l-0.73,0.37l-0.91-0.14c-0.48-0.07-0.97,0.15-1.23,0.56l-0.31,0.48l-0.56-0.01
                  c-0.29-0.01-0.58,0.1-0.81,0.28l-1.05,0.86c-0.27,0.22-0.43,0.53-0.46,0.87l-0.07,0.92l-0.6,0.59c-0.2,0.2-0.32,0.45-0.36,0.72
                  l-0.18,1.36c-0.02,0.12-0.01,0.25,0.01,0.38l0.22,1.25c0.02,0.11,0.06,0.23,0.11,0.33l0.53,1.08l0.28,1.11
                  c0.02,0.09,0.06,0.18,0.1,0.27l0.6,1.15c0.04,0.07,0.09,0.14,0.14,0.21l0.71,0.87c-0.21-0.06-0.42-0.07-0.63-0.02l-1.23,0.29
                  c-0.27,0.07-0.52,0.22-0.69,0.45l-0.65,0.82l-0.85,0.42c-0.43,0.21-0.7,0.65-0.7,1.13l0.01,0.95l-0.21,0.38l-0.61,0.22l-1.16,0.21
                  c-0.13,0.03-0.27,0.07-0.39,0.14l-1.09,0.62c-0.35,0.21-0.59,0.57-0.62,0.97l-0.14,1.41c-0.02,0.3,0.06,0.6,0.23,0.84l1.26,1.85
                  l0.07,3.36l-0.35,1.01c-0.03,0.07-0.04,0.15-0.06,0.22l-0.24,1.57c-0.01,0.12-0.01,0.23,0,0.35l0.15,1.18l-0.05,0.53l-0.55,0.35
                  c-0.15,0.1-0.28,0.23-0.38,0.38l-0.67,1.06c-0.08,0.14-0.14,0.3-0.17,0.46l-0.19,1.11l-0.13,0.18l-0.61-1.6
                  c-0.04-0.13-0.11-0.24-0.19-0.35l-0.79-0.99c-0.07-0.09-0.15-0.16-0.24-0.23l-1.24-0.91c-0.03-0.02-0.07-0.04-0.1-0.06l-0.36-0.22
                  l0.07-0.46c0.04-0.22,0.02-0.45-0.07-0.66l-0.52-1.31c-0.13-0.33-0.39-0.59-0.73-0.71c-0.33-0.13-0.7-0.1-1.01,0.06l-1.2,0.63
                  l-1.3,0.62l-0.76,0.06l-0.83-0.43l-1.11-0.67c-0.18-0.11-0.38-0.17-0.59-0.18l-1.23-0.06c-0.43-0.01-0.82,0.17-1.07,0.51l-0.73,1
                  c-0.07,0.1-0.12,0.2-0.16,0.31l-0.89,2.35c-0.01,0.03-0.02,0.06-0.03,0.1l-0.24,0.81l-0.67,0.39c-0.11,0.06-0.2,0.14-0.29,0.23
                  l-0.54,0.59l-0.79,0.13c-0.07,0.01-0.15,0.03-0.22,0.05l-0.78,0.28l-0.62-0.22l-0.93-0.68c-0.12-0.09-0.25-0.16-0.4-0.2l-1.19-0.34
                  c-0.14-0.04-0.29-0.06-0.44-0.05l-0.91,0.08l-0.75-0.36l-1.02-0.61c-0.17-0.11-0.37-0.17-0.57-0.18l-1.23-0.07
                  c-0.3-0.02-0.6,0.08-0.84,0.26l-0.76,0.59l-0.9,0.12c-0.06,0.01-0.11,0.02-0.16,0.03l-1.23,0.33c-0.25,0.07-0.48,0.21-0.64,0.41
                  l-0.8,0.96c-0.34,0.41-0.39,1-0.1,1.46l0.65,1.05c0.02,0.03,0.04,0.07,0.07,0.1l0.47,0.61l-0.14,0.87l-0.39,1.43
                  c-0.06,0.23-0.05,0.48,0.02,0.71l0.21,0.65l-0.06,0.1l-0.43-0.07l-0.46-0.3l-0.1-1.28c-0.05-0.63-0.57-1.12-1.2-1.15l-0.9-0.04
                  l-0.6-0.39l-0.89-1.17l-0.71-1.04c-0.24-0.36-0.64-0.56-1.07-0.55l-1.23,0.04c-0.05,0-0.1,0.01-0.16,0.02l-1.32,0.21
                  c-0.11,0.01-0.21,0.05-0.31,0.09l-0.78,0.35l-0.31-0.07l-0.27-0.62c-0.02-0.04-0.04-0.08-0.06-0.12l-0.64-1.15
                  c-0.1-0.19-0.25-0.35-0.44-0.46l-1.05-0.64c-0.03-0.02-0.06-0.03-0.09-0.05l-0.88-0.43l-0.98-2l-0.5-0.93l0.08-0.86
                  c0.02-0.27-0.04-0.53-0.18-0.76l-0.64-1.05c-0.18-0.29-0.47-0.5-0.81-0.57l-0.8-0.18c0.02-0.15,0.01-0.31-0.02-0.47l-0.28-1.22
                  c-0.07-0.29-0.24-0.55-0.48-0.73l-1.79-1.33l-0.51-0.88c-0.03-0.05-0.06-0.1-0.1-0.14l-0.62-0.79l-0.22-0.93
                  c-0.08-0.3-0.26-0.56-0.51-0.74l-0.96-0.66l-0.92-0.71l-0.73-0.78l-0.8-0.97c-0.24-0.3-0.61-0.45-1-0.45
                  c-0.39,0.01-0.75,0.21-0.98,0.53l-0.58,0.82l-0.7,0.49l-0.8,0.21l-0.56-0.07l-0.59-0.57c-0.04-0.05-0.1-0.09-0.15-0.13l-1.01-0.71
                  c-0.11-0.08-0.23-0.14-0.35-0.17l-1.33-0.41c-0.21-0.07-0.44-0.08-0.66-0.03l-0.9,0.22l-2.12-0.76c-0.2-0.07-0.4-0.09-0.6-0.06
                  l-0.92,0.14l-0.88-0.39c-0.04-0.01-0.08-0.03-0.13-0.04l-0.79-0.25l-0.2-0.71c-0.09-0.33-0.32-0.61-0.62-0.77l-1.11-0.58
                  c-0.22-0.11-0.47-0.16-0.72-0.13l-1.26,0.14c-0.12,0.01-0.25,0.05-0.37,0.1l-1.06,0.47l-0.47,0.14l-0.22-0.21l-0.12-0.57l0.64-1.12
                  c0.16-0.3,0.21-0.65,0.11-0.98c-0.1-0.32-0.32-0.6-0.63-0.75l-1.11-0.57c-0.24-0.13-0.52-0.17-0.78-0.12l-2.78,0.47l-0.06-0.51
                  c-0.03-0.26-0.14-0.51-0.32-0.7l-1.33-1.51l-0.19-2.36l0.29-0.93c0.06-0.18,0.07-0.37,0.05-0.55l-0.15-1.06l0.35-0.91
                  c0.07-0.2,0.1-0.42,0.07-0.63l-0.11-0.69l0.47-0.59c0.12-0.16,0.21-0.34,0.25-0.54l0.19-0.98c0.09-0.01,0.17-0.04,0.25-0.08
                  l1.21-0.57l1.12-0.44l1.19-0.18c0.11-0.02,0.21-0.05,0.31-0.09l1.18-0.51c0.08-0.04,0.15-0.08,0.22-0.13l1.03-0.73
                  c0.17-0.12,0.31-0.28,0.4-0.47l0.66-1.34c0.03-0.05,0.05-0.1,0.06-0.15l0.18-0.5l0.55,0.47c0.03,0.02,0.06,0.05,0.09,0.07l1.08,0.76
                  c0.13,0.09,0.28,0.16,0.43,0.2l1.13,0.27l0.85,0.63c0.19,0.14,0.41,0.23,0.64,0.25l1.11,0.09l0.9,0.39l0.96,0.69
                  c0.21,0.15,0.46,0.23,0.72,0.23l0.83,0.01l0.52,0.42l0.58,0.82l0.57,1.06c0.02,0.05,0.05,0.09,0.09,0.14l0.72,1
                  c0.03,0.05,0.08,0.11,0.13,0.15l0.96,0.97l0.93,0.84c0.21,0.18,0.46,0.29,0.74,0.32l0.97,0.08l0.78,0.55
                  c0.21,0.15,0.47,0.23,0.72,0.23c0.2,0,0.39-0.05,0.57-0.14c0.42-0.21,0.68-0.64,0.68-1.11v-0.7l0.2-0.19l2.13-0.18
                  c0.16-0.01,0.31-0.06,0.45-0.13l1-0.52l1.09-0.28c0.42-0.11,0.75-0.42,0.88-0.83l0.34-1.07l0.53-0.91l0.77-0.92
                  c0.17-0.2,0.27-0.46,0.29-0.73l0.07-1.23v-0.15l0.83,0.22c0.21,0.05,0.43,0.05,0.63,0l1.19-0.31c0.12-0.03,0.22-0.07,0.32-0.13
                  l1.15-0.67c0.05-0.03,0.1-0.07,0.15-0.1l0.96-0.77c0.12-0.1,0.22-0.21,0.3-0.34l0.71-1.2c0.2-0.35,0.23-0.78,0.06-1.15l-0.5-1.13
                  c-0.06-0.14-0.15-0.28-0.27-0.39l0.15,0.06c0.15,0.06,0.31,0.1,0.47,0.11l0.3,0.01l0.08,0.29c0.11,0.46,0.48,0.82,0.94,0.92
                  l1.5,0.33c0.48,0.11,0.99-0.08,1.28-0.48l0.76-1.04c0.04-0.06,0.07-0.11,0.1-0.17l0.17-0.33l0.1,0.15c0.18,0.26,0.46,0.45,0.78,0.51
                  l1.06,0.22l0.74,0.62c0.06,0.05,0.13,0.1,0.21,0.14l1.24,0.67c0.25,0.14,0.55,0.18,0.84,0.12l1.23-0.25
                  c0.26-0.05,0.49-0.18,0.67-0.37l1.8-2.01l0.76-0.74l1.02-0.5l1.07-0.6c0.52-0.29,0.76-0.91,0.58-1.47l-0.46-1.45l-0.11-0.88
                  l0.37-0.94c0.04-0.08,0.06-0.17,0.07-0.26l0.23-1.44c0.03-0.18,0.02-0.36-0.03-0.54l-0.38-1.35c-0.02-0.06-0.04-0.13-0.07-0.19
                  l-0.51-1.09l-0.35-1.17c-0.08-0.25-0.23-0.47-0.44-0.63l-0.88-0.68l-0.32-0.52l0.43-3.73c0.02-0.14,0.01-0.29-0.02-0.43l-0.32-1.4
                  l0.17-1.16c0.01-0.08,0.01-0.15,0.01-0.23l-0.05-1.17l0.03-1.29c0-0.06,0-0.13-0.01-0.19l-0.31-2.28l0.34-0.93
                  c0.02-0.04,0.03-0.09,0.04-0.14l0.24-0.95l0.73-0.89c0.13-0.16,0.22-0.35,0.26-0.54l0.27-1.3l0.26-1.01l0.57-0.82l0.96-0.93
                  c0.12-0.12,0.21-0.26,0.28-0.41l0.52-1.21c0.1-0.24,0.13-0.5,0.07-0.76l-0.3-1.37l0.05-0.69l0.35-0.42l1.79-0.45
                  c0.16-0.04,0.3-0.1,0.43-0.2l1.23-0.88l1.48-0.73l1.69-0.48c0.07-0.02,0.13-0.04,0.2-0.07l1.16-0.56l1.15-0.61l1.26-0.41l1.28-0.25
                  c0.06-0.02,0.11-0.03,0.17-0.05l1.06-0.38l0.46-0.07l0.44,0.54c0.06,0.07,0.12,0.13,0.18,0.18l0.97,0.79
                  c0.09,0.07,0.19,0.13,0.29,0.18l1.11,0.48l1.01,0.65c0.3,0.19,0.66,0.25,1,0.16l1.38-0.36l1.4-0.21c0.6-0.09,1.04-0.59,1.07-1.18
                  l0.04-0.97l0.69-0.96l0.73-0.81l0.9-0.46c0.13-0.06,0.25-0.16,0.36-0.27l0.88-0.97c0.05-0.06,0.1-0.12,0.14-0.2l2.09,0.62
                  c0.06,0.02,0.12,0.04,0.18,0.05l0.84,0.12l0.25,0.33l0.23,2.16c0.02,0.24,0.12,0.47,0.27,0.66l0.78,0.96
                  c0.07,0.09,0.15,0.16,0.24,0.23l1.05,0.75l0.49,0.6l0.14,0.81l-0.11,1.11c-0.02,0.12-0.01,0.24,0.01,0.35l0.22,1.22
                  c0.05,0.3,0.22,0.57,0.46,0.76l1.18,0.93c0.08,0.07,0.17,0.12,0.26,0.16l1.31,0.58c0.13,0.06,0.27,0.09,0.4,0.11l1.3,0.11
                  c0.1,0,0.2,0,0.3-0.01l1.6-0.24l0.84,0.33l0.37,1.02c0.07,0.18,0.17,0.34,0.3,0.47l0.95,0.94c0.21,0.21,0.49,0.33,0.78,0.36l1.3,0.1
                  l0.92,0.45l0.42,0.92c0.12,0.26,0.32,0.47,0.58,0.6l0.52,0.26l-0.01,0.59c0,0.16,0.02,0.32,0.08,0.47l0.34,0.88l-0.55,1.88
                  l-0.66,1.19c-0.09,0.17-0.15,0.35-0.16,0.54l-0.07,1.29l-0.55,0.99c-0.09,0.17-0.14,0.35-0.15,0.54l-0.05,1.08l-0.45,1.09
                  c-0.07,0.16-0.1,0.33-0.1,0.5l0.02,1.34c0,0.23,0.07,0.45,0.19,0.64l0.55,0.88l0.11,0.53l-0.38,0.64c-0.24,0.39-0.24,0.88-0.01,1.27
                  l0.62,1.06c0.09,0.16,0.21,0.29,0.36,0.39l1.02,0.72c0.14,0.1,0.29,0.17,0.46,0.2l0.75,0.16l0.4,0.66l0.41,0.94l0.4,2.4
                  c0.02,0.11,0.05,0.21,0.1,0.31l0.52,1.12c0.12,0.27,0.34,0.49,0.61,0.61l1.11,0.5l0.34,0.19l-0.54,1.35
                  c-0.03,0.07-0.06,0.15-0.07,0.22l-0.26,1.24c-0.03,0.14-0.04,0.28-0.02,0.41l0.15,1.22c0.04,0.38,0.26,0.71,0.57,0.91L415.29,335.67
                  z"/>
                <title>Partizánske</title>
              </Link>

              {/* Trencin - trenciansky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Trenčín")}>
                <path style={{ fill: crimesInRegionTrenciansky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionTrenciansky[5].crimePercent)] ? crimesInRegionTrenciansky[5].color : "#D3D3D3" }}
                  d="M387.51,284.7l-0.31,0.56l-0.62,0.69l-0.9,0.46c-0.13,0.06-0.25,0.16-0.36,0.27l-0.94,1.04
                  c-0.03,0.04-0.06,0.08-0.09,0.11l-0.94,1.33c-0.14,0.19-0.22,0.42-0.23,0.66l-0.01,0.31l-0.45,0.06c-0.05,0.01-0.09,0.02-0.14,0.03
                  l-0.91,0.24l-0.64-0.41c-0.06-0.04-0.12-0.07-0.18-0.1l-1.04-0.45l-0.74-0.6l-0.82-1c-0.28-0.34-0.73-0.51-1.16-0.44l-1.28,0.2
                  c-0.08,0.01-0.16,0.03-0.23,0.05l-1.09,0.39l-1.26,0.25c-0.04,0.01-0.09,0.03-0.13,0.04l-1.43,0.46c-0.07,0.02-0.14,0.05-0.21,0.09
                  l-1.2,0.64l-1.01,0.49l-1.7,0.48c-0.07,0.02-0.14,0.04-0.21,0.08l-1.68,0.82c-0.06,0.03-0.13,0.07-0.18,0.11l-1.12,0.81l-1.95,0.49
                  c-0.26,0.06-0.49,0.21-0.66,0.41l-0.87,1.04c-0.17,0.2-0.27,0.45-0.29,0.71l-0.09,1.27c-0.01,0.12,0,0.24,0.03,0.36l0.25,1.16
                  l-0.26,0.61l-0.87,0.84c-0.06,0.06-0.11,0.12-0.15,0.18l-0.77,1.1c-0.09,0.13-0.16,0.27-0.19,0.42l-0.32,1.28l-0.22,1.04l-0.72,0.87
                  c-0.12,0.15-0.21,0.32-0.25,0.51l-0.29,1.17l-0.42,1.15c-0.07,0.19-0.1,0.4-0.07,0.6l0.34,2.48l-0.03,1.27l0.05,1.14l-0.19,1.27
                  c-0.02,0.15-0.01,0.31,0.02,0.46l0.32,1.41l-0.45,3.98c-0.03,0.27,0.03,0.55,0.17,0.78l0.66,1.08c0.08,0.13,0.19,0.25,0.31,0.34
                  l0.75,0.58l0.26,0.87c0.02,0.06,0.04,0.12,0.07,0.17l0.5,1.08l0.28,0.98l-0.16,1.04l-0.44,1.1c-0.08,0.19-0.11,0.4-0.08,0.61
                  l0.16,1.32c0.01,0.08,0.03,0.16,0.05,0.23l0.19,0.59l-1.26,0.63c-0.12,0.06-0.23,0.13-0.32,0.23l-0.96,0.94l-1.57,1.74l-0.38,0.07
                  l-0.73-0.39l-0.89-0.74c-0.16-0.13-0.35-0.22-0.55-0.26l-0.88-0.18l-1.21-1.68c-0.28-0.38-0.76-0.57-1.22-0.48
                  c-0.47,0.08-0.85,0.42-0.98,0.88l-0.34,1.14l-0.47,0.92l-0.18,0.25l-0.1-0.38c-0.13-0.54-0.61-0.93-1.16-0.95l-0.99-0.04l-0.92-0.42
                  c-0.27-0.12-0.57-0.14-0.86-0.06l-1.25,0.35c-0.51,0.14-0.88,0.6-0.91,1.13l-0.07,1.26c-0.02,0.36,0.12,0.72,0.38,0.97l0.75,0.72
                  l0.14,0.32l-0.27,0.45l-0.71,0.57l-0.92,0.53l-0.71,0.19l-0.87-0.23c-0.11-0.03-0.23-0.04-0.34-0.04l-1.26,0.02
                  c-0.34,0.01-0.66,0.15-0.89,0.39c-0.23,0.24-0.35,0.57-0.34,0.91l0.05,1.3l-0.04,0.75l-0.58,0.69c-0.04,0.05-0.09,0.11-0.12,0.17
                  l-0.65,1.12c-0.05,0.08-0.08,0.17-0.11,0.25l-0.17,0.54l-0.56,0.15c-0.09,0.02-0.18,0.06-0.26,0.1l-0.91,0.47l-2.24,0.17
                  c-0.29,0.02-0.57,0.14-0.78,0.34l-0.93,0.87c-0.05,0.04-0.1,0.1-0.14,0.15l-0.86-0.07l-1.41-1.36l-0.62-0.85l-0.56-1.06
                  c-0.02-0.05-0.05-0.09-0.08-0.14l-0.73-1.02c-0.07-0.09-0.14-0.17-0.23-0.24l-0.99-0.81c-0.22-0.18-0.49-0.28-0.78-0.28l-0.87-0.01
                  l-0.75-0.53c-0.07-0.06-0.15-0.1-0.24-0.14l-1.21-0.52c-0.12-0.05-0.25-0.08-0.39-0.1l-0.95-0.08l-0.77-0.57
                  c-0.14-0.1-0.29-0.18-0.46-0.22l-1.15-0.27l-0.83-0.59l-0.79-0.67l-0.35-0.87c-0.19-0.45-0.61-0.75-1.09-0.78s-0.94,0.23-1.17,0.65
                  l-0.78,1.43c-0.03,0.07-0.06,0.13-0.08,0.2l-0.39,1.13l-0.48,0.98l-0.67,0.47l-0.91,0.4l-1.17,0.19c-0.09,0.01-0.18,0.03-0.27,0.07
                  l-1.32,0.52l-1.09,0.51l-0.54,0.06l-0.66-0.49l-1.06-0.66c-0.23-0.14-0.5-0.21-0.77-0.19l-1.25,0.11c-0.18,0.02-0.36,0.08-0.52,0.17
                  l-0.29,0.17l-3.97-8.89l0.39-2.06c0-0.05,0.01-0.1,0.02-0.15l0.1-1.24l0.24-1.17c0.03-0.19,0.03-0.37-0.02-0.56l-0.3-1.19
                  c-0.02-0.09-0.06-0.19-0.11-0.28l-0.58-1.09c-0.24-0.46-0.75-0.72-1.27-0.65l-1.56,0.21c-0.03,0.01-0.07,0.01-0.11,0.02l-1.33,0.3
                  l-2.53-0.15c-0.07,0-0.15,0-0.22,0.01l-0.74,0.08l-0.57-0.46c-0.09-0.08-0.19-0.14-0.29-0.19l-0.47-0.2l-0.04-0.37l0.16-0.87
                  l0.45-0.98c0.2-0.45,0.12-0.98-0.21-1.35l-0.78-0.88l-0.81-1c-0.23-0.28-0.56-0.45-0.93-0.46l-1.5-0.05l-2.6,0.05l-1.18-0.12
                  l-1.24-0.34c-0.12-0.04-0.24-0.06-0.36-0.05l-1.53,0.04l-1.16-0.13c-0.41-0.05-0.81,0.11-1.08,0.41l-1.14,1.3l-1.21,0.79
                  c-0.09,0.06-0.17,0.13-0.24,0.21l-0.34,0.38l-0.08-0.31c-0.04-0.13-0.09-0.25-0.17-0.37l-0.46-0.7l0.02-0.72l0.66-0.98l0.65-1.06
                  c0.11-0.18,0.17-0.39,0.18-0.6l0.04-1.13l0.28-1l0.45-1.16c0.03-0.1,0.06-0.2,0.07-0.3l0.17-1.38c0.07-0.57-0.25-1.11-0.79-1.32
                  l-0.82-0.31l-0.25-0.76l-0.2-1.18c-0.02-0.16-0.08-0.31-0.16-0.45l-0.78-1.29c-0.04-0.06-0.08-0.11-0.12-0.17l-0.67-0.77l-0.18-0.93
                  c-0.03-0.12-0.08-0.25-0.14-0.36l-0.62-1.1c-0.08-0.14-0.18-0.26-0.3-0.36l-0.67-0.54l-0.18-0.81c-0.06-0.25-0.19-0.48-0.38-0.65
                  l-0.89-0.79l-2.22-2.94l-0.94-3.44c-0.01-0.04-0.02-0.08-0.03-0.12l-1.34-3.9l-0.14-1.05l0.19-0.87l0.48-0.95
                  c0.01-0.03,0.03-0.07,0.05-0.11l0.34-0.9l0.43-0.44l0.87-0.17c0.43-0.09,0.79-0.41,0.93-0.83l0.4-1.18
                  c0.05-0.14,0.07-0.29,0.07-0.45l-0.05-1.36c-0.01-0.17-0.05-0.34-0.12-0.49l-0.5-1.03l-0.16-0.7l0.87-2.35
                  c0.09-0.25,0.1-0.52,0.03-0.77l-0.1-0.34l0.15,0.01l2.28,1.1l1.17,0.46c0.14,0.06,0.28,0.09,0.43,0.09l1.27,0.03
                  c0.33,0.01,0.62-0.11,0.86-0.31l0.88-0.79l0.97-0.43l1.2-0.26c0.41-0.09,0.75-0.39,0.9-0.78l0.44-1.18c0.06-0.15,0.08-0.3,0.08-0.46
                  l-0.02-1.24l0.52-2.4c0-0.04,0.01-0.08,0.01-0.12l0.1-0.95l1.23-1.8c0.04-0.06,0.08-0.14,0.12-0.21l0.46-1.07l0.6-1.05
                  c0.05-0.08,0.09-0.18,0.12-0.28l1.05-3.68c0.07-0.22,0.06-0.46,0-0.68l-0.63-2.11l0.32-0.81c0.06-0.17,0.09-0.35,0.08-0.53
                  l-0.07-1.07l0.26-1.21l0.14-1.03l0.44-0.88l0.66-0.85l0.86-0.89c0.14-0.15,0.25-0.33,0.3-0.54l0.42-1.48l0.31-1.31
                  c0.04-0.17,0.04-0.35,0.01-0.52l-0.22-1.14l-0.13-2.68l0.13-0.75l0.65-0.74l0.86-0.77c0.11-0.1,0.21-0.22,0.28-0.35l0.46-0.88
                  l0.72-0.57l0.94-0.59l1.17-0.4l1.07,0.04h0.14l1.28-0.09c0.11-0.01,0.22-0.03,0.32-0.07l1.32-0.46l1.28,0.3
                  c0.1,0.02,0.22,0.03,0.33,0.03l1.32-0.05l2.51,0.6c0.21,0.05,0.43,0.04,0.64-0.02l1.24-0.37c0.26-0.08,0.48-0.24,0.64-0.46
                  l0.73-0.97l0.57-0.3l0.62,0.01l0.78,0.46l1.02,0.97c0.06,0.05,0.12,0.1,0.19,0.15l1.39,0.88c0.35,0.22,0.78,0.25,1.16,0.09
                  l0.84-0.36l0.86,0.24c0.05,0.02,0.1,0.03,0.15,0.03l1.27,0.2c0.25,0.05,0.52,0,0.75-0.11l1.13-0.57c0.26-0.13,0.46-0.34,0.58-0.6
                  l0.3-0.66l0.55-0.18l1.19-0.03c0.16-0.01,0.32-0.04,0.47-0.11l1.2-0.54c0.06-0.03,0.12-0.06,0.17-0.09l1.08-0.7l0.38-0.17l0.35,0.3
                  c0.32,0.26,0.75,0.36,1.14,0.24c0.4-0.11,0.72-0.41,0.85-0.8l0.28-0.8l0.59-0.4l1.03-0.44c0.09-0.04,0.17-0.09,0.25-0.15l1.02-0.76
                  c0.17-0.13,0.31-0.3,0.39-0.5l0.38-0.85l0.88-0.7l0.91-0.84c0.03-0.04,0.07-0.07,0.1-0.11l0.81-0.95c0.2-0.23,0.3-0.52,0.3-0.82
                  l-0.01-0.95l0.27-0.58l0.75-0.54c0.09-0.07,0.17-0.15,0.24-0.24l0.64-0.8l0.16-0.1l0.39,0.68l0.1,0.6l-0.39,0.86l-0.49,1.3
                  c-0.18,0.47-0.05,1,0.31,1.35l0.04,0.03l-0.97,1.12c-0.22,0.26-0.33,0.61-0.29,0.95l0.14,1.31c0.01,0.14,0.05,0.28,0.11,0.4
                  l0.56,1.19c0.02,0.04,0.04,0.08,0.06,0.12l0.28,0.46c-0.34,0.09-0.63,0.32-0.8,0.65l-0.6,1.2c-0.14,0.29-0.17,0.61-0.08,0.92
                  l0.36,1.2c0.18,0.59,0.75,0.96,1.36,0.88l0.22-0.03l0.29,1.34c0.05,0.29,0.21,0.55,0.45,0.73l0.73,0.58l0.24,0.83
                  c0.03,0.1,0.07,0.21,0.13,0.3l0.64,1.05c0.06,0.1,0.14,0.19,0.23,0.27l0.72,0.66l0.52,1.35l0.43,0.9l-0.01,1.09
                  c0,0.06,0,0.11,0.01,0.17l0.17,1.32c0.01,0.08,0.03,0.15,0.05,0.22l1.02,3.15l0.46,1.17c0.07,0.17,0.17,0.33,0.31,0.46l2,1.83
                  l1.97,1.57c0.17,0.13,0.37,0.22,0.59,0.25l0.09,0.02l-2,2.39c-0.15,0.18-0.24,0.39-0.28,0.62l-0.19,1.32
                  c-0.04,0.32,0.04,0.64,0.22,0.9l0.78,1.1c0.08,0.11,0.17,0.2,0.27,0.28l0.69,0.51l-0.76,0.73l-0.91,1c-0.06,0.06-0.1,0.12-0.14,0.19
                  l-0.67,1.08c-0.03,0.05-0.05,0.09-0.07,0.13l-0.56,1.21c-0.17,0.35-0.16,0.75,0.02,1.1l0.61,1.2c0.02,0.04,0.04,0.08,0.07,0.13
                  l0.77,1.15c0.06,0.09,0.14,0.17,0.22,0.25l0.96,0.83c0.07,0.06,0.15,0.11,0.24,0.16l1.12,0.59c0.12,0.06,0.26,0.11,0.4,0.13
                  l1.27,0.18c0.07,0.01,0.14,0.01,0.21,0.01l1.29-0.03c0.22-0.01,0.43-0.07,0.61-0.18l0.06-0.03l0.06,0.08
                  c0.05,0.07,0.11,0.14,0.17,0.19l0.96,0.9l0.74,0.62l0.39,1.01c0.06,0.13,0.13,0.25,0.22,0.36l0.94,1.11
                  c0.12,0.14,0.27,0.26,0.44,0.33l1.08,0.48l1.09,0.66c0.1,0.06,0.21,0.11,0.32,0.14l1.24,0.34c0.11,0.03,0.22,0.04,0.33,0.04
                  c0.21,0,0.42-0.05,0.61-0.16l1.03-0.58l1.11-0.54l1.17-0.48c0.17-0.07,0.32-0.18,0.44-0.31l0.77-0.82l0.38-0.16l0.34,0.35
                  c0.21,0.21,0.49,0.35,0.79,0.37l1.64,0.15c0.23,0.03,0.47-0.02,0.69-0.13l0.64-0.33l1.91,0.67c0.1,0.03,0.19,0.05,0.29,0.06
                  l0.98,0.11l0.91,0.52l1.23,0.6l1.16,0.49c0.3,0.13,0.62,0.13,0.92,0.02l1.18-0.43c0.29-0.1,0.54-0.32,0.68-0.6l0.38-0.73l3.25-1.1
                  c0.06-0.02,0.12-0.05,0.18-0.08l1.12-0.6c0.15-0.08,0.28-0.18,0.38-0.31l0.55-0.67l0.51-0.1l1,0.29c0.16,0.05,0.34,0.06,0.51,0.04
                  l1.16-0.15l1.18-0.1l1.56,0.07l1.03,0.39c0.19,0.07,0.39,0.1,0.59,0.07l1.14-0.14l1.21,0.04c0.14,0,0.27-0.01,0.4-0.05l0.69-0.21
                  l0.04,0.95c0,0.05,0.01,0.11,0.02,0.16l0.27,1.47l0.04,0.78l-0.79,1.04c-0.29,0.39-0.33,0.92-0.1,1.35l0.49,0.93l0.15,1.04
                  c0.04,0.26,0.16,0.5,0.35,0.69l0.66,0.68L387.51,284.7z"/>
                <title>Trenčín</title>
              </Link>

              {/* Nove mesto nad vahom - trenciansky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Nové Mesto nad Váhom")}>
                <path style={{ fill: crimesInRegionTrenciansky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionTrenciansky[1].crimePercent)] ? crimesInRegionTrenciansky[1].color : "#D3D3D3" }}
                  d="M306.53,387.14l-0.29,0.41l-0.84,0.85c-0.28,0.27-0.41,0.67-0.36,1.06l0.1,0.7c-0.13-0.08-0.27-0.13-0.43-0.16
                  l-1.21-0.22c-0.49-0.09-0.98,0.12-1.26,0.54c-0.28,0.41-0.28,0.95-0.01,1.37l0.1,0.15l-0.21,0.19c-0.04,0.04-0.08,0.09-0.12,0.13
                  l-0.59,0.72l-1.05,0.14l-1.21,0.07c-0.35,0.02-0.67,0.18-0.89,0.45l-1.75,2.1c-0.02,0.02-0.04,0.04-0.05,0.07v0.01l-0.99,0.26
                  l-1.1,0.18l-0.48-0.03l-0.18-0.44c-0.07-0.16-0.16-0.3-0.28-0.42l-0.37-0.37l0.2-0.54c0.2-0.53,0.02-1.13-0.44-1.46l-1.02-0.73
                  c-0.24-0.17-0.54-0.25-0.83-0.23l-1.56,0.13h-1.32c-0.07,0-0.15,0.01-0.22,0.02l-0.87,0.16l-0.98-0.37
                  c-0.14-0.05-0.29-0.08-0.44-0.08h-0.94l-2.05-0.98c-0.13-0.06-0.26-0.09-0.41-0.11l-1.46-0.12h-0.17l-1.23,0.07l-0.96-0.16
                  l-0.75-0.54l-0.83-0.81c-0.22-0.21-0.5-0.33-0.8-0.35l-1.9-0.11c-0.12,0-0.24,0.01-0.35,0.03l-1.2,0.28
                  c-0.06,0.02-0.12,0.03-0.17,0.05l-0.12-0.69c-0.05-0.35-0.25-0.65-0.54-0.85l-1.19-0.78c-0.04-0.02-0.07-0.03-0.1-0.05l-1.12-0.6
                  c-0.13-0.07-0.28-0.12-0.43-0.14l-1.23-0.16c-0.06-0.01-0.12-0.01-0.18-0.01l-1.93,0.03c-0.49,0.01-0.93,0.3-1.13,0.75l-0.14,0.34
                  c-0.08-0.07-0.16-0.12-0.25-0.17l-0.65-0.33l-0.26-0.82c-0.09-0.31-0.31-0.58-0.6-0.73l-0.74-0.4l-0.2-0.45l0.09-0.94
                  c0.02-0.13,0.01-0.26-0.02-0.39l-0.28-1.27c-0.1-0.46-0.45-0.82-0.91-0.94l-2.71-0.68c-0.08-0.01-0.16-0.03-0.24-0.03l-1.32-0.05
                  c-0.56-0.02-1.07,0.34-1.24,0.87l-0.37,1.17c-0.09,0.27-0.08,0.57,0.02,0.83l0.37,0.94v0.97l-0.09,0.42l-0.3,0.1l-0.99-0.1
                  c-0.21-0.02-0.42,0.01-0.61,0.09l-1.88,0.79l-0.79-0.29l-1.2-0.34c-0.31-0.09-0.64-0.06-0.93,0.1l-0.73,0.39l-0.6-0.14l-0.78-0.6
                  c-0.09-0.07-0.18-0.13-0.29-0.17l-2.62-1.06l-1.44-0.53l-1.98-0.99l-0.76-0.77c-0.11-0.11-0.25-0.2-0.4-0.27l-1.28-0.55
                  c-0.06-0.02-0.12-0.05-0.18-0.06l-0.72-0.18l-0.37-0.62c-0.05-0.09-0.11-0.17-0.18-0.24l-0.97-0.98c-0.03-0.02-0.05-0.05-0.08-0.07
                  l-0.87-0.74l-0.53-0.62l-0.3-1.37c-0.01-0.05-0.02-0.09-0.04-0.14l-0.44-1.34c-0.07-0.19-0.18-0.36-0.32-0.5l-2.87-2.81l0.18-0.39
                  c0.1-0.22,0.13-0.46,0.1-0.69l-0.15-1.03l0.14-0.78l0.71-0.83c0.22-0.25,0.32-0.58,0.3-0.91c-0.03-0.34-0.19-0.64-0.45-0.86
                  l-1.05-0.88c-0.03-0.02-0.06-0.05-0.1-0.07l-0.91-0.63l-0.3-0.33l0.14-0.67l0.35-1.2c0.12-0.42,0.02-0.87-0.28-1.19l-0.64-0.71
                  l-0.26-0.83l-0.22-1.27c-0.02-0.15-0.08-0.3-0.16-0.43l-0.54-0.9l-0.07-0.58l0.44-0.66c0.17-0.26,0.23-0.57,0.18-0.88l-0.11-0.73
                  l0.37-0.52l0.09-0.1l0.07,0.06c0.43,0.36,1.06,0.39,1.52,0.06c0.47-0.33,0.65-0.93,0.45-1.46l-0.28-0.75l0.24-0.79
                  c0.07-0.23,0.07-0.49,0-0.72l-0.28-0.91l0.22-1.03c0.06-0.28,0.02-0.57-0.11-0.83l-0.56-1.09c-0.02-0.04-0.04-0.07-0.06-0.11
                  l-0.6-0.92l-0.56-1.5c-0.08-0.2-0.2-0.38-0.37-0.52l-1.07-0.9l-0.62-0.86c-0.21-0.29-0.53-0.48-0.88-0.52l-0.8-0.09l-0.55-0.58
                  l-0.79-0.98c-0.02-0.04-0.05-0.07-0.09-0.1l-0.98-0.98l-0.93-0.83c-0.19-0.18-0.44-0.28-0.7-0.31l-1.3-0.14
                  c-0.29-0.04-0.59,0.04-0.84,0.21l-1.05,0.73l-0.62,0.47l-0.69-0.03l-1.14-0.22c-0.19-0.03-0.39-0.03-0.57,0.03l-0.1,0.03
                  c0.16-0.19,0.27-0.43,0.3-0.69c0.06-0.56-0.25-1.09-0.78-1.3l-0.53-0.21l-0.12-0.56c-0.07-0.31-0.26-0.59-0.53-0.77l-0.96-0.64
                  l-0.93-0.76c-0.32-0.27-0.75-0.36-1.15-0.24l-0.77,0.24l-0.3-0.12l-0.66-1.27l0.26-0.54c0.06-0.12,0.1-0.25,0.11-0.39l0.11-0.94
                  l0.53-0.81c0.26-0.4,0.27-0.92,0.01-1.34l-0.72-1.17c-0.05-0.09-0.12-0.17-0.2-0.25l-0.85-0.8l-0.53-0.72l-0.16-0.93
                  c0-0.04-0.01-0.07-0.02-0.11l-0.3-1.15l-0.12-0.87l0.29-0.92c0.04-0.12,0.06-0.25,0.06-0.38v-1.24c0-0.19-0.04-0.37-0.12-0.54
                  l-0.47-0.97l-0.12-0.95l0.07-1.15c0.01-0.2-0.03-0.4-0.11-0.58L203.9,313c-0.05-0.13-0.12-0.24-0.21-0.34l-0.5-0.55l0.16-1.8l0.35-1
                  l0.47-1.01l0.61-1.09c0.11-0.19,0.16-0.4,0.16-0.61v-0.33h-0.01l-0.06-1.14l0.34-0.69l1.59-1.57l0.64-0.43l0.84-0.02l1.11,0.11
                  l1.24,0.2c0.22,0.03,0.44,0,0.65-0.08l1-0.4l0.89-0.09l2.44,0.58c0.33,0.07,0.66,0.01,0.94-0.17l1.32-0.83l1.08-0.64
                  c0.03-0.02,0.06-0.04,0.09-0.07l0.98-0.7l1.87-0.71l0.86,0.1l1.11,0.25c0.12,0.03,0.24,0.03,0.36,0.03l1.23-0.08
                  c0.31-0.02,0.61-0.16,0.82-0.39l0.87-0.9l0.89-0.72c0.04-0.03,0.07-0.07,0.11-0.1l0.85-0.88c0.08-0.09,0.15-0.18,0.21-0.29
                  l1.09-2.01l0.73-0.66l0.82-0.57l1.04-0.41c0.07-0.02,0.14-0.06,0.2-0.1l1.06-0.66c0.11-0.07,0.2-0.15,0.28-0.24l0.81-0.93
                  c0.06-0.07,0.11-0.14,0.15-0.22l0.62-1.11c0.06-0.11,0.1-0.22,0.12-0.34l0.18-0.84l0.63-0.42c0.17-0.11,0.32-0.27,0.41-0.45
                  l0.58-1.09c0.03-0.06,0.06-0.12,0.08-0.19l0.7-2.12l0.6-0.63l0.94-0.85c0.04-0.04,0.08-0.09,0.12-0.13l0.75-0.91l1.85-1.66
                  l0.77-0.62l1.07-0.32c0.16-0.05,0.31-0.13,0.44-0.24l1.04-0.87c0.18-0.15,0.31-0.35,0.39-0.57l0.44-1.34
                  c0.04-0.15,0.07-0.3,0.06-0.45l-0.05-0.89l0.16-0.16l0.75,0.01l2.58-0.03l1.24,0.02h0.12l2.51-0.16c0.2-0.01,0.4-0.07,0.58-0.18
                  l0.88-0.52l4.98,0.34l0.09,0.27l-0.86,2.3c-0.09,0.23-0.1,0.48-0.05,0.71l0.27,1.2c0.02,0.1,0.05,0.19,0.09,0.27l0.45,0.93
                  l0.03,0.87l-0.09,0.28l-0.55,0.1c-0.24,0.05-0.47,0.18-0.65,0.36l-0.87,0.9c-0.12,0.12-0.21,0.26-0.27,0.42l-0.41,1.08l-0.53,1.04
                  c-0.04,0.09-0.08,0.19-0.1,0.29l-0.27,1.24c-0.03,0.15-0.04,0.29-0.02,0.44l0.19,1.39c0.01,0.08,0.03,0.16,0.06,0.24l1.36,3.98
                  l1.01,3.7c0.04,0.16,0.12,0.3,0.22,0.43l0.79,1.01l1.61,2.15c0.05,0.07,0.11,0.14,0.17,0.2l0.69,0.61l0.19,0.85
                  c0.06,0.27,0.22,0.52,0.43,0.69l0.82,0.66l0.4,0.72l0.21,1.06c0.05,0.21,0.14,0.4,0.28,0.57l0.82,0.94l0.6,0.99l0.17,1.04
                  c0.01,0.07,0.02,0.13,0.04,0.2l0.47,1.41c0.12,0.35,0.39,0.63,0.74,0.77l0.46,0.17l-0.03,0.26l-0.42,1.08
                  c-0.01,0.04-0.02,0.08-0.03,0.12l-0.34,1.2c-0.02,0.09-0.04,0.19-0.04,0.28l-0.04,0.95l-1.28,1.96c-0.13,0.2-0.2,0.43-0.21,0.67
                  l-0.04,1.48c-0.01,0.25,0.07,0.5,0.21,0.72l0.56,0.86l0.26,0.98c0.1,0.36,0.35,0.67,0.7,0.82l1.14,0.51c0.5,0.22,1.08,0.1,1.44-0.31
                  l1-1.13l1.21-0.79c0.09-0.06,0.17-0.13,0.25-0.21l0.82-0.93l0.6,0.06c0.06,0.01,0.11,0.01,0.17,0.01l1.44-0.04l1.16,0.33
                  c0.06,0.01,0.13,0.03,0.2,0.03l1.36,0.14c0.05,0.01,0.1,0.01,0.15,0.01l2.62-0.05l0.86,0.03l0.76,0.91l-0.17,0.38
                  c-0.04,0.1-0.07,0.2-0.09,0.3l-0.22,1.21c-0.02,0.12-0.03,0.24-0.01,0.36l0.14,1.27c0.05,0.44,0.33,0.83,0.74,1.01l0.97,0.42
                  l0.84,0.68c0.25,0.22,0.59,0.32,0.93,0.28l1.16-0.13l2.62,0.16c0.11,0,0.23-0.01,0.35-0.03l1.44-0.33l0.65-0.08l0.1,0.18l0.19,0.77
                  l-0.2,0.96c-0.01,0.05-0.01,0.1-0.02,0.15l-0.1,1.24l-0.45,2.45c-0.04,0.25-0.01,0.5,0.09,0.72l3.14,6.87l1.03,2.52
                  c0.07,0.16,0.18,0.31,0.31,0.44l0.91,0.84c0.4,0.37,1,0.44,1.48,0.16l0.85-0.49l0.55-0.05l0.62,0.39l0.73,0.53l-0.09,0.46
                  l-0.62,0.78c-0.22,0.28-0.31,0.63-0.26,0.97l0.14,0.9l-0.34,0.91c-0.07,0.19-0.1,0.4-0.07,0.61l0.15,1.09l-0.28,0.9
                  c-0.05,0.16-0.07,0.32-0.06,0.48l0.25,3.01c0.03,0.25,0.13,0.5,0.29,0.69l0.83,0.98l0.55,0.61l0.04,0.31l-0.95,0.63
                  c-0.07,0.05-0.14,0.11-0.21,0.18l-0.78,0.83l-0.92,0.61c-0.26,0.18-0.45,0.46-0.52,0.77l-0.28,1.28c-0.04,0.16-0.04,0.32-0.01,0.48
                  l0.21,1.23c0.01,0.09,0.04,0.18,0.07,0.26l0.44,1.07l0.17,1.21c0.02,0.09,0.04,0.18,0.07,0.26l0.42,1.09l0.15,0.78l-0.34,0.95
                  c-0.06,0.14-0.08,0.3-0.08,0.45l0.02,1.29c0.01,0.33,0.15,0.65,0.39,0.89l0.75,0.71l0.15,0.63l-0.3,0.98c-0.14,0.48,0.02,1,0.4,1.32
                  l0.6,0.49l0.07,1c0.01,0.09,0.03,0.19,0.06,0.28l0.8,2.6c0.07,0.2,0.18,0.38,0.34,0.53l0.95,0.91c0.08,0.09,0.18,0.16,0.29,0.21
                  l1.19,0.61l0.15,0.28l-0.28,0.67c-0.13,0.3-0.13,0.64-0.01,0.95l0.39,1l0.04,1.05c0,0.07,0.01,0.14,0.03,0.21l0.28,1.29
                  c0.02,0.08,0.04,0.16,0.07,0.23L306.53,387.14z"/>
                <title>Nové Mesto nad Váhom</title>
              </Link>

              {/* Piestany - trnavsky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Piešťany")}>
                <path
                  d="M297.25,401.47l-0.11,0.28l-1.68,1.48c-0.14,0.12-0.25,0.26-0.32,0.42l-0.19,0.43l-4.61,0.09
                  c-0.23,0-0.44,0.07-0.64,0.19l-1.12,0.69c-0.57,0.35-0.76,1.09-0.43,1.67l0.41,0.72l-4.27,0.27l-1.21,0.02l-1.52-1.72
                  c-0.09-0.11-0.2-0.19-0.31-0.26l-1.07-0.62c-0.03-0.02-0.07-0.04-0.1-0.05l-1.06-0.5l-1.02-0.63c-0.09-0.06-0.18-0.1-0.28-0.13
                  l-1.17-0.37c-0.37-0.12-0.77-0.06-1.09,0.16l-1.03,0.71c-0.12,0.08-0.22,0.18-0.3,0.29l-0.72,0.99c-0.14,0.19-0.22,0.41-0.24,0.64
                  l-0.09,1.18l-0.19,1.02l-0.49,1.19l-0.79,2.35l-0.28,0.6l-0.71,0.26c-0.3,0.11-0.55,0.34-0.69,0.63l-0.46,0.98l-1.49,1.83
                  c-0.18,0.22-0.28,0.5-0.28,0.79v1.05l-0.4,1.22c-0.02,0.05-0.03,0.11-0.04,0.16l-0.24,1.33l-0.62,1.15
                  c-0.02,0.03-0.04,0.07-0.05,0.1l-0.49,1.16c-0.03,0.07-0.05,0.14-0.07,0.21l-0.28,1.24c-0.08,0.36,0,0.74,0.22,1.03l0.45,0.6
                  l-0.06,0.69l-0.52,1.02c-0.01,0.03-0.03,0.06-0.04,0.09l-0.38,0.96l-0.71,0.77l-2.26,1.37c-0.21,0.13-0.38,0.32-0.48,0.55
                  l-0.08,0.18l-0.21-0.29l-0.37,0.27l-0.45-0.29c-0.24-0.16-0.53-0.22-0.82-0.19l-1.28,0.14c-0.17,0.02-0.34,0.07-0.49,0.16
                  l-0.79,0.46l-0.91-0.03c-0.36-0.02-0.72,0.14-0.96,0.41l-0.85,0.93c-0.12,0.13-0.22,0.29-0.27,0.46l-0.4,1.26
                  c-0.06,0.2-0.08,0.4-0.04,0.6l0.23,1.29c0.01,0.03,0.01,0.07,0.02,0.1l0.47,1.78l-1.53,0.75c-0.11,0.05-0.2,0.12-0.29,0.2
                  l-0.69,0.63l-0.89,0.23l-0.49,0.05l-0.36-0.44c-0.06-0.08-0.14-0.15-0.22-0.21l-0.53-0.39l-0.05-0.66c-0.01-0.07-0.02-0.14-0.03-0.2
                  l-0.28-1.14l-0.16-1.22c-0.04-0.32-0.21-0.61-0.47-0.81l-0.97-0.76c-0.09-0.07-0.18-0.13-0.29-0.17l-1-0.42l-0.96-0.71l-1.05-0.7
                  c-0.32-0.22-0.73-0.27-1.09-0.14l-1.26,0.43c-0.41,0.14-0.72,0.47-0.82,0.89l-0.7,2.94c-0.09,0.36-0.02,0.75,0.21,1.05
                  c0.22,0.3,0.57,0.48,0.94,0.5l0.27,0.01l-0.61,0.94c-0.05,0.08-0.09,0.15-0.12,0.23l-0.46,1.2c-0.09,0.24-0.1,0.5-0.05,0.74
                  l0.23,0.93l-0.35,1.77l-0.4,0.3l-2.07,0.24c-0.37,0.03-0.7,0.23-0.91,0.53l-0.7,1.02c-0.06,0.08-0.1,0.17-0.14,0.27l-0.86,2.4
                  c-0.03,0.06-0.04,0.13-0.05,0.2l-0.18,1.1l-0.09,0.17l-3.14-0.58l-2.39-0.38l-1.13-0.57l-1.2-0.51l-0.89-0.54l-2.71-2.61l-0.93-0.79
                  l-0.81-0.83l-0.82-0.94c-0.29-0.34-0.75-0.49-1.19-0.4l-0.6-0.77c-0.03-0.03-0.05-0.06-0.08-0.08l-0.82-0.88l-0.68-0.93l-0.65-1.05
                  l-1.05-2.22c-0.09-0.18-0.21-0.34-0.37-0.47l-1.07-0.83c-0.27-0.2-0.6-0.29-0.93-0.25c-0.33,0.04-0.63,0.21-0.83,0.47l-0.18,0.25
                  l-1.73-1.13l-1.07-1.01l-0.85-0.91l-0.55-0.82l-0.11-0.64l0.42-0.88c0.24-0.51,0.11-1.12-0.31-1.49l-1.02-0.87l-0.57-1.03
                  c-0.2-0.37-0.58-0.61-1-0.64h-0.08c0.04-0.56-0.29-1.08-0.82-1.27l-2.06-0.73l-0.28-0.37l-0.01-0.85c0-0.27-0.1-0.53-0.26-0.75
                  l-2.38-3.05c-0.03-0.04-0.07-0.08-0.11-0.12l-0.74-0.73l-0.33-0.93c-0.03-0.09-0.07-0.17-0.12-0.25l-0.7-1.11l-0.52-1.2
                  c-0.02-0.04-0.04-0.08-0.06-0.12l-0.69-1.22c-0.15-0.25-0.38-0.45-0.65-0.55l-1.09-0.41l-0.56-0.28l-0.2-0.76
                  c-0.09-0.33-0.32-0.61-0.62-0.77l-1.15-0.61c-0.17-0.09-0.35-0.14-0.53-0.15l-1.23-0.06c-0.17-0.01-0.34,0.02-0.49,0.08l-1.15,0.42
                  l-0.36,0.1l0.04-0.71c0-0.09,0-0.18-0.02-0.27l-0.16-0.96l0.35-0.9c0.02-0.06,0.04-0.13,0.05-0.19l0.25-1.14l0.42-0.8l0.57-0.5h0.81
                  c0.07,0,0.14-0.01,0.21-0.02l1.26-0.22c0.65-0.11,1.1-0.71,1.03-1.36l-0.16-1.59l-0.28-1.98c-0.04-0.27-0.16-0.51-0.34-0.7
                  l-0.8-0.82l0.13-0.32l1.29-1.1c0.08-0.08,0.16-0.16,0.22-0.25l0.68-1.02l0.77-0.99c0.21-0.27,0.3-0.61,0.25-0.95
                  c-0.05-0.34-0.24-0.64-0.52-0.84l-1.06-0.76l-0.26-0.55l0.05-0.75l0.47-1.1c0.06-0.13,0.09-0.28,0.1-0.42l0.07-1.27
                  c0-0.03,0-0.07-0.01-0.1l1.85-0.1c0.12-0.01,0.25-0.03,0.37-0.08l1.18-0.42c0.07-0.03,0.15-0.07,0.21-0.11l0.74-0.44l0.87,0.13
                  c0.19,0.03,0.38,0.02,0.56-0.03l1.11-0.34l1.21-0.22c0.06-0.01,0.12-0.02,0.17-0.04l1.49-0.49c0.08-0.03,0.15-0.06,0.22-0.1
                  l1.5-0.84c0.39-0.22,0.63-0.62,0.64-1.07c0.01-0.44-0.22-0.86-0.6-1.09l-0.66-0.4l-0.21-0.61l0.09-0.98
                  c0.03-0.25-0.02-0.51-0.15-0.73l-0.56-1.01l-0.41-0.89l0.03-0.95l0.12-1l1,0.41l0.75,0.49l0.51,0.91c0.19,0.36,0.54,0.59,0.94,0.64
                  c0.41,0.05,0.8-0.1,1.08-0.4l0.39-0.43l0.46,0.18c0.12,0.04,0.26,0.07,0.39,0.08l1.27,0.06c0.25,0.01,0.5-0.05,0.72-0.19l1.07-0.66
                  c0.22-0.13,0.39-0.34,0.49-0.57l0.75,0.19c0.25,0.06,0.5,0.05,0.74-0.03l1.27-0.46c0.07-0.03,0.14-0.06,0.2-0.1l0.86-0.51l0.97-0.11
                  c0.21-0.02,0.41-0.1,0.58-0.22l1.27-0.9c0.08-0.06,0.15-0.12,0.22-0.19l0.87-0.99c0.04-0.06,0.09-0.11,0.12-0.17l0.46-0.74
                  l0.17-0.08l0.56,0.35c0.55,0.36,1.28,0.22,1.67-0.3l0.79-1.07l0.4-0.61l0.11-0.02l0.46,0.44c0.06,0.05,0.11,0.1,0.18,0.14l1.04,0.68
                  c0.05,0.03,0.11,0.06,0.16,0.09l0.98,0.44l2.62,2.57l0.33,0.99l0.35,1.61c0.04,0.21,0.14,0.4,0.27,0.56l0.81,0.94
                  c0.05,0.04,0.09,0.09,0.14,0.13l0.9,0.77l0.82,0.83l0.56,0.93c0.17,0.28,0.44,0.49,0.76,0.57l1.13,0.29l0.96,0.41l0.73,0.74
                  c0.09,0.09,0.2,0.17,0.31,0.23l2.28,1.14c0.04,0.02,0.07,0.03,0.11,0.04l1.46,0.54l2.42,0.98l0.87,0.67
                  c0.14,0.11,0.31,0.19,0.48,0.23l1.32,0.3c0.3,0.07,0.6,0.02,0.87-0.12l0.7-0.37l0.62,0.17l1.16,0.43c0.29,0.11,0.62,0.11,0.91-0.02
                  l2.04-0.85l0.93,0.09c0.18,0.02,0.35,0,0.52-0.05l1.23-0.4c0.42-0.14,0.73-0.49,0.83-0.92l0.28-1.25c0.02-0.09,0.03-0.18,0.03-0.27
                  v-1.34c0-0.15-0.03-0.31-0.08-0.45l-0.23-0.59l1.93,0.45l0.08,0.33l-0.1,1.06c-0.02,0.21,0.01,0.42,0.09,0.62l0.5,1.15
                  c0.12,0.26,0.31,0.47,0.56,0.6l0.67,0.36l0.26,0.84c0.1,0.32,0.32,0.59,0.63,0.75l0.76,0.38l0.44,0.85
                  c0.17,0.34,0.49,0.58,0.86,0.65c0.37,0.08,0.76-0.02,1.05-0.26l0.96-0.8c0.15-0.13,0.27-0.28,0.35-0.46l0.2-0.46l1.03-0.02
                  l0.91,0.12l0.87,0.47l0.69,0.44l0.15,0.91c0.02,0.17,0.08,0.32,0.17,0.46l0.66,1.06c0.2,0.32,0.53,0.53,0.9,0.58
                  s0.75-0.07,1.02-0.33l0.65-0.62l0.7-0.16l1.25,0.07l0.57,0.55c0.04,0.04,0.09,0.08,0.14,0.11l1.05,0.76
                  c0.15,0.11,0.33,0.19,0.52,0.22l1.39,0.24c0.09,0.02,0.19,0.02,0.28,0.02l1.29-0.08l1.15,0.1l0.9,0.4l1.11,0.57
                  c0.18,0.09,0.37,0.14,0.57,0.14h1.01l1.09,0.41c0.21,0.08,0.44,0.1,0.66,0.06l1.1-0.2h1.36l0.98-0.08l-0.11,0.29
                  c-0.17,0.46-0.06,0.98,0.29,1.33l0.77,0.76l0.38,0.93c0.18,0.45,0.6,0.75,1.08,0.78l1.41,0.09h0.08c0.07,0,0.14-0.01,0.21-0.02
                  l1.25-0.21l0.1,0.91c0.02,0.2,0.09,0.39,0.2,0.56L297.25,401.47z"/>
                <title>Piešťany</title>
              </Link>

              {/* Topolcany - nitriansky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Topoľčany")}>
                <path style={{ fill: crimesInRegionNitriansky.length >= 5 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionNitriansky[5].crimePercent)] ? crimesInRegionNitriansky[5].color : "#D3D3D3" }}
                  d="M382.26,441.59l0.04,0.11l-0.18,0.14l-0.99,0.17l-1.17-0.06c-0.14-0.01-0.29,0.01-0.42,0.05l-1.29,0.39
                  c-0.19,0.06-0.36,0.16-0.5,0.29l-0.85,0.8l-0.92,0.73l-0.81,0.56l-1.3-0.01c-0.09,0-0.17,0.01-0.26,0.03l-2.8,0.55
                  c-0.2,0.04-0.39,0.13-0.55,0.25l-1.13,0.9c-0.05,0.05-0.11,0.1-0.15,0.15l-1.05,1.18c-0.1,0.11-0.18,0.24-0.24,0.38l-0.52,1.35
                  l-0.55,0.7l-0.8,0.63l-0.99,0.59c-0.19,0.11-0.35,0.28-0.46,0.48l-0.61,1.14c-0.04,0.08-0.08,0.16-0.1,0.24l-0.16,0.57l-0.77,0.27
                  c-0.1,0.04-0.19,0.08-0.27,0.14l-1.71,1.13c-0.15,0.1-0.28,0.24-0.38,0.4l-0.83,1.38c-0.1,0.15-0.15,0.33-0.17,0.51l-0.07,0.66
                  l-0.47,0.3l-1.09,0.54c-0.09,0.05-0.17,0.1-0.24,0.16l-1.07,0.9c-0.08,0.07-0.15,0.14-0.2,0.22l-0.59,0.79l-2.03,1.19
                  c-0.17,0.11-0.32,0.26-0.43,0.44l-0.51,0.88l-0.46,0.25l-0.69-0.23c-0.15-0.06-0.31-0.08-0.47-0.07l-1.03,0.06l-0.95-0.25
                  l-1.08-0.43c-0.1-0.04-0.21-0.07-0.32-0.08l-1.27-0.14c-0.08-0.01-0.15-0.01-0.23-0.01l-0.92,0.07l-0.91-0.44
                  c-0.09-0.04-0.17-0.08-0.27-0.1l-1.2-0.28c-0.04-0.01-0.09-0.02-0.13-0.02l-2.45-0.32l-2.16-0.51l-0.76-0.61
                  c-0.36-0.28-0.83-0.35-1.25-0.18s-0.71,0.56-0.77,1.01l-0.15,1.18l-0.32,1.06l-2.73,2.74c-0.05,0.04-0.1,0.09-0.14,0.15l-0.73,0.99
                  c-0.04,0.06-0.08,0.12-0.11,0.19l-0.55,1.12c-0.12,0.24-0.16,0.51-0.11,0.77l0.2,1.09l-0.01,0.08l-0.05,0.01l-1-0.13l-1.45-0.39
                  l-0.96-0.63c-0.07-0.04-0.14-0.08-0.21-0.11l-1.24-0.5l-1.95-0.69l-1.5-0.49l-2.78-0.74l-0.82-0.62c-0.03-0.02-0.06-0.04-0.09-0.06
                  l-0.76-0.49l-0.37-0.68l0.88-1.64c0.05-0.09,0.08-0.18,0.11-0.27l0.32-1.19c0.04-0.15,0.05-0.32,0.03-0.48l-0.16-1.24
                  c-0.08-0.59-0.55-1.04-1.14-1.09l-1.15-0.09l-1.01-0.23l-0.91-0.39l-0.59-0.39l-0.23-0.71l-0.25-1.16
                  c-0.07-0.35-0.29-0.64-0.59-0.82s-0.67-0.22-1-0.11l-0.9,0.28l-0.44-0.08l-0.48-0.6c-0.1-0.13-0.22-0.24-0.36-0.32l-1.11-0.63
                  c-0.24-0.13-0.52-0.19-0.8-0.15l-1.04,0.15l-1.03-0.19c-0.13-0.02-0.26-0.03-0.39-0.01l-1.23,0.16c-0.19,0.02-0.36,0.09-0.52,0.19
                  l-1.05,0.68c-0.51,0.33-0.71,0.97-0.48,1.53l0.26,0.61l-0.07-0.01h-0.17l-1.21,0.08l-1.06-0.07l-1.13-0.24
                  c-0.12-0.03-0.25-0.04-0.38-0.02l-1.15,0.11l-1.07-0.05l-1.81-0.35c0.22-0.33,0.28-0.77,0.12-1.16l-0.47-1.17
                  c-0.09-0.22-0.24-0.41-0.44-0.55l-0.97-0.68l-0.85-0.75l-0.91-0.92c-0.05-0.05-0.1-0.09-0.15-0.13l-0.99-0.73
                  c-0.03-0.02-0.06-0.04-0.09-0.06l-1.15-0.7c-0.07-0.04-0.14-0.07-0.21-0.1l-0.93-0.35l-0.5-0.69c-0.05-0.08-0.11-0.14-0.18-0.21
                  l-1.96-1.79l-0.92-1.02c-0.07-0.08-0.15-0.15-0.24-0.21l-0.83-0.56l-1.25-1.92c-0.08-0.14-0.2-0.26-0.33-0.35l-1.07-0.78
                  c-0.04-0.03-0.09-0.06-0.14-0.08l-1.1-0.59l-1.11-0.69l-0.87-0.83c-0.06-0.06-0.12-0.11-0.19-0.16l-2.17-1.41
                  c-0.25-0.16-0.55-0.24-0.84-0.2l-1.09,0.12l-1.05-0.22l-1.3-0.38l-3.36-1.2l-0.66-0.65l-0.79-0.97c-0.27-0.33-0.68-0.51-1.11-0.45
                  l-0.65,0.07l0.08-0.31l0.32-0.72l2.04-1.23c0.1-0.07,0.19-0.14,0.28-0.23l0.99-1.07c0.1-0.11,0.18-0.24,0.24-0.38l0.45-1.12
                  l0.6-1.19c0.07-0.14,0.11-0.29,0.12-0.45l0.13-1.4c0.03-0.3-0.05-0.61-0.24-0.86l-0.39-0.53l0.12-0.57l0.43-1l0.68-1.27
                  c0.06-0.11,0.11-0.24,0.13-0.36l0.26-1.44l0.44-1.33c0.04-0.13,0.06-0.26,0.06-0.39v-0.81l1.3-1.61c0.06-0.07,0.12-0.15,0.16-0.24
                  l0.31-0.66l0.71-0.26c0.3-0.11,0.55-0.34,0.69-0.63l0.53-1.11c0.02-0.04,0.04-0.09,0.05-0.13l0.79-2.37l0.52-1.23
                  c0.03-0.08,0.05-0.17,0.07-0.26l0.22-1.21c0.01-0.04,0.01-0.08,0.02-0.12l0.07-0.89l0.39-0.53l0.35-0.24l0.43,0.13l0.95,0.59
                  c0.04,0.03,0.08,0.05,0.13,0.07l1.08,0.51l0.85,0.49l1.76,2.01c0.25,0.27,0.57,0.41,0.96,0.42l1.85-0.03l4.26-0.26
                  c0.03,0,0.07-0.01,0.1-0.01l1.58-0.22c0.35-0.05,0.66-0.24,0.86-0.53c0.2-0.29,0.27-0.66,0.19-1l-0.3-1.27
                  c-0.03-0.09-0.06-0.18-0.1-0.26l1.98-0.08l2.81-0.01c0.49,0,0.93-0.29,1.13-0.73l0.41-0.91l1.71-1.5c0.16-0.13,0.27-0.3,0.35-0.49
                  l0.45-1.17c0.14-0.38,0.1-0.8-0.13-1.14l-1.24-1.87l-0.08-0.71l0.25-1.03l1.21-1.46l0.72-0.04c0.03,0,0.07-0.01,0.1-0.01l1.58-0.22
                  c0.31-0.05,0.59-0.2,0.79-0.44l0.84-1.02l0.89-0.8l0.19,0.16c0.37,0.29,0.87,0.35,1.29,0.16l1.15-0.52c0.6-0.27,0.89-0.95,0.67-1.56
                  l-0.37-1.03l-0.07-0.52l0.47-0.48c0.05-0.05,0.1-0.1,0.14-0.16l0.76-1.07c0.25-0.35,0.3-0.81,0.13-1.21l-0.44-1.03l-0.24-1.07
                  l-0.04-1.15c-0.01-0.14-0.03-0.28-0.09-0.41l-0.28-0.72l0.31-0.74c0.15-0.35,0.13-0.73-0.04-1.06l-0.6-1.16
                  c-0.12-0.24-0.31-0.43-0.54-0.54l-1.39-0.71l-0.58-0.56l-0.67-2.17l-0.11-1.38c-0.03-0.34-0.19-0.65-0.45-0.86l-0.36-0.3l0.15-0.51
                  c0.06-0.21,0.07-0.43,0.02-0.65l-0.31-1.32c-0.06-0.23-0.18-0.45-0.36-0.62l-0.64-0.6l-0.01-0.54l0.39-1.06
                  c0.08-0.22,0.1-0.45,0.06-0.68l-0.25-1.23c-0.02-0.07-0.04-0.13-0.06-0.2l-0.4-1.05l-0.18-1.22c-0.02-0.1-0.04-0.2-0.08-0.29
                  l-0.44-1.08l-0.15-0.86l0.12-0.55l0.62-0.41c0.07-0.05,0.14-0.11,0.21-0.18l0.78-0.83l0.76-0.51l1.34-0.1
                  c0.03-0.01,0.07-0.01,0.11-0.02l1.87-0.31l-0.05,0.08c-0.15,0.27-0.2,0.59-0.13,0.89l0.31,1.39c0.05,0.23,0.17,0.44,0.33,0.61
                  l1,1.01c0.33,0.33,0.81,0.45,1.26,0.31l1.27-0.39c0.04-0.01,0.09-0.03,0.14-0.05l0.95-0.42l0.69-0.08l0.29,0.16l0.24,0.84
                  c0.11,0.4,0.42,0.73,0.83,0.85l1.37,0.43l1.15,0.5c0.21,0.1,0.45,0.13,0.69,0.1l0.96-0.15l2.17,0.78c0.23,0.08,0.47,0.09,0.71,0.04
                  l0.93-0.23l0.81,0.26l0.77,0.53l0.82,0.8c0.19,0.2,0.45,0.32,0.72,0.35l1.22,0.15c0.16,0.02,0.32,0.01,0.47-0.03l1.24-0.33
                  c0.14-0.04,0.28-0.1,0.4-0.19l1.06-0.74c0.01-0.01,0.02-0.01,0.04-0.03l0.69,0.74c0.04,0.05,0.09,0.1,0.15,0.14l1.05,0.81l0.62,0.43
                  l0.18,0.73c0.04,0.18,0.12,0.34,0.23,0.48l0.73,0.94l0.6,1.02c0.08,0.14,0.19,0.27,0.33,0.37l1.64,1.22l0.02,0.12l-0.38,0.4
                  c-0.29,0.31-0.41,0.75-0.3,1.17c0.11,0.41,0.42,0.75,0.82,0.88l1.23,0.4c0.04,0.01,0.08,0.02,0.13,0.03l0.86,0.19l0.15,0.24
                  l-0.08,0.82c-0.02,0.24,0.03,0.49,0.15,0.7l1.81,3.57c0.13,0.24,0.32,0.44,0.56,0.56l1.21,0.6l0.72,0.44l0.45,0.8l0.49,1.15
                  c0.16,0.37,0.49,0.64,0.88,0.73l1.35,0.3c0.26,0.06,0.54,0.03,0.78-0.08l1-0.45l1.08-0.17l0.47-0.02l1.4,1.91
                  c0.09,0.12,0.19,0.21,0.31,0.29l1.07,0.71c0.19,0.13,0.41,0.2,0.64,0.21h0.14l0.06,0.8c0.03,0.39,0.25,0.74,0.58,0.95l1.21,0.77
                  c0.14,0.1,0.3,0.16,0.47,0.18l1.12,0.19l1.13,0.39l1.12,0.6c0.18,0.1,0.38,0.14,0.6,0.15l1.24-0.01c0.11,0,0.23-0.02,0.33-0.05
                  l0.51-0.14l0.41,0.41l1.74,2.17l0.95,1.09c0.03,0.03,0.06,0.07,0.09,0.1l1.87,1.7l0.85,0.88c0.03,0.03,0.06,0.06,0.1,0.09l0.08,0.07
                  l-0.09,0.13c-0.06,0.09-0.11,0.18-0.14,0.27l-0.53,1.41l-1.41,1c-0.28,0.21-0.47,0.52-0.51,0.87l-0.15,1.28l-0.79,1.4h-0.51
                  c-0.33,0-0.64,0.13-0.88,0.36l-4.15,4.07l-0.92,0.93c-0.3,0.31-0.42,0.74-0.33,1.16l0.28,1.21c0.04,0.15,0.1,0.3,0.19,0.43
                  l0.73,1.06c0.06,0.08,0.13,0.16,0.21,0.23l1.14,1c0.03,0.03,0.07,0.06,0.11,0.09l1.05,0.72c0.28,0.19,0.62,0.26,0.94,0.2l-0.94,2.06
                  c-0.1,0.23-0.13,0.47-0.09,0.72l0.21,1.32c0.03,0.21,0.12,0.41,0.25,0.58l0.8,1.01c0.25,0.31,0.64,0.49,1.05,0.47
                  c0.4-0.02,0.77-0.24,0.99-0.58l0.37-0.59l0.67-0.07c0.07,0,0.14-0.02,0.21-0.03l1.28-0.35c0.07-0.02,0.13-0.05,0.2-0.08l0.89-0.41
                  l0.55,0.07l1.66,1.4c0.1,0.08,0.22,0.15,0.34,0.2l1.19,0.48l0.43,0.78c0.13,0.22,0.32,0.4,0.55,0.51l0.98,0.46l0.73,0.74
                  c0.11,0.12,0.24,0.21,0.38,0.27l0.93,0.41l0.62,0.59l0.49,0.88l0.47,1.16l0.42,1.31c0.01,0.04,0.03,0.09,0.05,0.13l0.52,1.16
                  c0.03,0.06,0.06,0.12,0.09,0.17l0.67,1.03l0.54,1.08c0.11,0.2,0.26,0.38,0.46,0.5l0.63,0.39l0.17,0.78
                  c0.04,0.17,0.12,0.34,0.23,0.48l0.51,0.67l0.04,0.86c0.01,0.14,0.04,0.29,0.1,0.42l0.4,0.98l0.11,1.06
                  c0.02,0.24,0.12,0.48,0.27,0.67l0.33,0.4l-0.18,0.49C382.14,440.98,382.14,441.3,382.26,441.59z"/>
                <title>Topoľčany</title>
              </Link>

              {/* Myjava - trenciansky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Myjava")}>
                <path
                  d="M227.66,366.5l-0.11,0.13c-0.15,0.17-0.24,0.37-0.28,0.59l-0.23,1.31c-0.02,0.13-0.03,0.26-0.01,0.39l0.09,0.63
                  l-0.15-0.1l-0.82-0.77c-0.28-0.26-0.66-0.38-1.04-0.33l-1.26,0.18c-0.35,0.05-0.67,0.25-0.86,0.55l-0.67,1.02l-0.06,0.08l-0.16-0.1
                  c-0.36-0.24-0.82-0.26-1.21-0.08l-1.13,0.54c-0.21,0.11-0.4,0.27-0.52,0.47l-0.6,0.96l-0.7,0.81l-0.89,0.62l-0.91,0.11
                  c-0.17,0.02-0.34,0.07-0.49,0.16l-0.99,0.58l-0.81,0.29l-0.81-0.2l-1.21-0.39c-0.42-0.14-0.89-0.04-1.22,0.26
                  c-0.33,0.3-0.48,0.75-0.38,1.19l0.08,0.37l-0.68-0.03l-1.07-0.41c-0.4-0.16-0.86-0.1-1.2,0.16c-0.05-0.06-0.12-0.11-0.18-0.15
                  l-0.55-0.36c0.03-0.08,0.06-0.16,0.07-0.24c0.08-0.35-0.01-0.73-0.23-1.01l-0.81-1.05c-0.22-0.28-0.54-0.46-0.89-0.49
                  c-0.35-0.02-0.7,0.1-0.96,0.34l-0.74,0.7l-0.93,0.42c-0.43,0.19-0.71,0.6-0.74,1.06l-0.07,1.16l-0.15,1.24
                  c-0.01,0.04-0.01,0.07-0.01,0.11l-0.04,1.29c-0.01,0.19,0.03,0.38,0.11,0.56l0.58,1.25l0.42,0.76l-0.08,0.86
                  c-0.02,0.18,0,0.36,0.06,0.52l0.44,1.29c0,0,0,0,0,0.01l-1.07,0.35l-1.19,0.21c-0.05,0.01-0.1,0.02-0.14,0.03l-0.91,0.28l-1.03-0.16
                  c-0.29-0.05-0.59,0.01-0.84,0.16l-1.03,0.62l-0.89,0.32l-2.21,0.11l-0.64-0.29l-0.66-0.76c-0.14-0.16-0.31-0.29-0.51-0.36
                  l-1.37-0.51l-0.92-0.61c-0.56-0.37-1.32-0.23-1.71,0.32l-0.61,0.86l-0.9,0.67l-1.02,0.4l-1.13,0.18c-0.56,0.08-0.99,0.54-1.04,1.1
                  l-0.12,1.08l-0.42,1.06l-0.09,0.26l-0.31-0.03c-0.42-0.03-0.82,0.14-1.08,0.46l-1-1.2l-1.5-1.64l-0.87-1.12l-0.87-1.21l-0.8-1.36
                  c-0.1-0.16-0.23-0.3-0.38-0.4l-0.11-0.08l0.08-0.15c0.1-0.19,0.15-0.4,0.14-0.62l-0.05-1.33c-0.01-0.13-0.03-0.27-0.08-0.39
                  l-0.41-1.09l-0.31-1.29c-0.04-0.14-0.1-0.28-0.18-0.4l-0.76-1.15c-0.02-0.03-0.04-0.06-0.06-0.09l-0.77-0.97
                  c-0.07-0.08-0.15-0.16-0.24-0.23l-0.99-0.72l-0.65-0.58l-0.27-0.85c-0.07-0.24-0.22-0.46-0.42-0.61l-0.83-0.65l-0.4-0.66l-0.18-1.07
                  c-0.04-0.22-0.13-0.43-0.28-0.61l-0.69-0.79l-0.17-0.44l0.36-0.67c0.13-0.24,0.18-0.52,0.14-0.79l-0.19-1.25
                  c-0.05-0.3-0.2-0.57-0.44-0.77l-1.01-0.84l-1.12-0.81l-0.36-0.29l0.12-0.34c0.06-0.17,0.08-0.35,0.07-0.53l-0.08-0.84l1.13-1.88
                  c0.13-0.2,0.19-0.43,0.19-0.67l-0.01-1.02l0.38-1l0.37-1.2c0.07-0.21,0.08-0.44,0.03-0.65l-0.07-0.3l0.45,0.15
                  c0.42,0.14,0.89,0.04,1.22-0.26l0.92-0.82c0.29-0.26,0.45-0.64,0.42-1.03l-0.07-0.87l1-1.77c0.18-0.29,0.22-0.64,0.14-0.96
                  l-0.32-1.2c-0.01-0.03-0.02-0.07-0.03-0.1l-0.42-1.18c-0.05-0.12-0.11-0.24-0.19-0.34l-0.77-1c-0.09-0.11-0.19-0.21-0.31-0.29
                  l-1.04-0.68c-0.03-0.02-0.07-0.04-0.11-0.06l-0.74-0.39l-0.3-0.58l-0.22-1.06c-0.01-0.05-0.02-0.11-0.04-0.16l-0.4-1.16
                  c-0.02-0.06-0.05-0.12-0.08-0.19l-0.51-0.94l-0.24-1.03c-0.14-0.56-0.66-0.98-1.23-0.96l-1.22,0.01l-2.08-0.18l-0.75-0.52
                  c-0.03-0.02-0.07-0.04-0.1-0.06l-0.59-0.33l-0.12-0.64c-0.08-0.39-0.34-0.72-0.7-0.89c-0.35-0.17-0.77-0.16-1.12,0.03l-0.7,0.38
                  l-0.6-0.11l-0.91-0.51c-0.14-0.09-0.3-0.14-0.47-0.16l-0.8-0.1l-0.54-0.56c-0.03-0.03-0.06-0.06-0.09-0.08l-0.08-0.07l0.07-0.1
                  c0.18-0.26,0.25-0.59,0.21-0.9l-0.19-1.22c-0.02-0.09-0.04-0.17-0.07-0.25l-0.87-2.33c-0.07-0.2-0.19-0.37-0.35-0.51l-0.94-0.82
                  c-0.37-0.33-0.91-0.4-1.36-0.19l-0.75,0.36l-0.77-0.18c-0.45-0.1-0.93,0.05-1.23,0.41l-0.82,0.96c-0.05,0.06-0.1,0.12-0.13,0.19
                  l-0.63,1.1c-0.07,0.12-0.12,0.25-0.15,0.39l-0.14,0.76l-0.63,0.43c-0.05,0.03-0.09,0.07-0.13,0.1l-1.6,1.46l-0.74,0.03l-1.04-0.02
                  l-0.96-0.39c-0.22-0.09-0.47-0.11-0.7-0.07l-0.64,0.12l-0.23-0.2l-0.27-0.85c-0.1-0.32-0.32-0.59-0.62-0.74
                  c-0.3-0.16-0.65-0.18-0.97-0.07l-0.04,0.01l0.46-0.58l0.78-0.67l0.94-0.55l2.28-0.94c0.2-0.08,0.36-0.2,0.49-0.37l0.41-0.5
                  l0.72,0.01c0.28,0,0.59-0.1,0.82-0.3l0.94-0.8c0.29-0.24,0.45-0.61,0.44-0.99l-0.04-1.25c0-0.04-0.01-0.08-0.01-0.12l-0.08-0.63
                  l1.32-1.47c0.09-0.1,0.16-0.21,0.21-0.33l0.49-1.15c0.12-0.28,0.13-0.6,0.03-0.9l-0.42-1.2c0.22-0.13,0.39-0.3,0.51-0.53l0.6-1.16
                  l0.42-0.7l0.77-0.29c0.18-0.07,0.33-0.17,0.46-0.3l1.7-1.82l0.19-0.16l0.25,0.17c0.36,0.25,0.83,0.29,1.23,0.1l1.3-0.6
                  c0.27-0.12,0.48-0.33,0.61-0.6l1.3-2.79c0.04-0.07,0.07-0.15,0.08-0.22l0.09-0.36l0.43-0.05c0.15-0.02,0.3-0.07,0.44-0.14l1.13-0.62
                  c0.06-0.03,0.11-0.07,0.16-0.11l1.05-0.8c0.14-0.11,0.26-0.25,0.35-0.41l0.22-0.43l1.66,0.17l1.04,0.35l1.27,0.54
                  c0.05,0.02,0.1,0.04,0.15,0.05l1.19,0.34l0.81,0.36l0.67,0.82c0.03,0.03,0.05,0.06,0.08,0.08l6.48,6.74
                  c0.15,0.15,0.33,0.26,0.54,0.33l1.19,0.36c0.11,0.03,0.22,0.05,0.34,0.05l0.21,0.57c0.02,0.04,0.04,0.09,0.06,0.13l0.64,1.24
                  c0.18,0.35,0.5,0.59,0.88,0.66l1.49,0.28c0.27,0.05,0.54,0.01,0.78-0.11l1.19-0.59c0.11-0.05,0.21-0.12,0.3-0.2l1.53-1.42l1.99,0.5
                  c0.38,0.09,0.78-0.01,1.08-0.26l1.07-0.89c0.09-0.08,0.18-0.17,0.24-0.27l0.75-1.14c0.28-0.42,0.28-0.96,0.01-1.37l-0.2-0.31
                  l0.98,0.72c0.09,0.06,0.19,0.12,0.29,0.16l1.22,0.47c0.13,0.05,0.28,0.08,0.42,0.08l1.23,0.03c0.09,0,0.19-0.01,0.28-0.03l1.3-0.26
                  l1.02-0.27l1.1,0.12c0.34,0.04,0.68-0.06,0.94-0.28l1.23-1.02c0.11-0.09,0.21-0.21,0.28-0.34l0.73-1.27
                  c0.08-0.13,0.13-0.27,0.15-0.42l0.21-1.29l0.28-1.09l0.31-0.45l0.77-0.18l1.57-0.14l-0.26,0.55c-0.02,0.04-0.04,0.08-0.05,0.12
                  l-0.42,1.2c-0.03,0.09-0.06,0.2-0.07,0.3l-0.22,2.49c-0.04,0.35,0.08,0.7,0.31,0.96l0.74,0.81l0.3,0.7l-0.06,0.97
                  c0,0.08,0,0.17,0.01,0.25l0.17,1.26c0.02,0.13,0.06,0.25,0.11,0.37l0.43,0.89v0.76l-0.32,1.01c-0.06,0.18-0.07,0.37-0.05,0.55
                  l0.17,1.23c0.01,0.05,0.02,0.1,0.03,0.15l0.31,1.16l0.2,1.17c0.03,0.19,0.11,0.37,0.22,0.53l0.77,1.05c0.05,0.06,0.1,0.12,0.15,0.17
                  l0.81,0.76l0.22,0.36l-0.26,0.4c-0.1,0.16-0.17,0.35-0.19,0.54l-0.12,1.02l-0.44,0.94c-0.17,0.36-0.16,0.78,0.04,1.14l0.58,1.04
                  l0.54,1.11c0.13,0.28,0.37,0.5,0.67,0.61l1.16,0.45c0.26,0.11,0.55,0.12,0.81,0.04l0.54-0.17l0.46,0.37
                  c0.03,0.03,0.06,0.06,0.1,0.08l0.04,0.03l-0.18-0.07c-0.62-0.23-1.3,0.05-1.58,0.65l-0.52,1.14c-0.19,0.42-0.13,0.91,0.15,1.28
                  l0.75,1c0.16,0.2,0.37,0.35,0.62,0.43l1.18,0.38c0.2,0.07,0.42,0.08,0.63,0.03l0.97-0.2l1.02,0.2c0.19,0.04,0.39,0.03,0.57-0.03
                  l0.91-0.26l0.93,0.18c0.05,0.01,0.11,0.02,0.17,0.02l1.23,0.06c0.3,0.01,0.58-0.08,0.81-0.25l1.57-1.15l0.44,0.05l1.46,1.4l0.81,1
                  l0.93,0.99c0.2,0.21,0.48,0.35,0.77,0.38l0.69,0.08l0.39,0.54c0.06,0.09,0.14,0.17,0.22,0.24l0.95,0.79l0.49,1.31
                  c0.03,0.08,0.07,0.16,0.12,0.24l0.64,0.99l0.32,0.63l-0.19,0.91c-0.05,0.2-0.04,0.42,0.02,0.62l0.26,0.86l-0.12,0.4
                  c-0.13,0.06-0.26,0.15-0.38,0.27l-0.96,1.01c-0.04,0.04-0.08,0.09-0.11,0.14l-0.72,1.02c-0.19,0.27-0.27,0.6-0.21,0.92l0.12,0.75
                  l-0.42,0.64c-0.16,0.24-0.23,0.53-0.19,0.82l0.15,1.29c0.02,0.18,0.08,0.35,0.17,0.5l0.56,0.93l0.19,1.12
                  c0.01,0.06,0.02,0.11,0.04,0.16l0.37,1.18c0.05,0.18,0.14,0.34,0.27,0.47l0.35,0.4l-0.46,1.99c-0.08,0.39,0.02,0.8,0.29,1.09
                  l0.82,0.92c0.06,0.08,0.14,0.15,0.23,0.2l0.98,0.68L227.66,366.5z"/>
                <title>Myjava</title>
              </Link>

              {/* Skalica - trnavsky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Skalica")}>
                <path style={{ fill: crimesInRegionTrnavsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionTrnavsky[3].crimePercent)] ? crimesInRegionTrnavsky[3].color : "#D3D3D3" }}
                  d="M144.53,319.59l-0.22,0.53l-1.53,1.67c-0.26,0.27-0.39,0.65-0.34,1.02l0.15,1.16l0.02,0.59l-0.13,0.11
                  l-0.86-0.01c-0.38,0.01-0.74,0.17-0.98,0.47l-0.59,0.72l-2.03,0.83c-0.06,0.02-0.12,0.05-0.17,0.08l-1.12,0.66
                  c-0.07,0.04-0.13,0.09-0.19,0.13l-0.95,0.82c-0.05,0.05-0.11,0.11-0.15,0.17l-0.62,0.76l-0.93,0.44c-0.35,0.17-0.61,0.5-0.68,0.88
                  l-0.04,0.19l-0.96,0.58c-0.17,0.1-0.32,0.24-0.42,0.41l-0.65,1.05c-0.07,0.11-0.12,0.23-0.15,0.35l-0.31,1.21
                  c-0.03,0.13-0.05,0.26-0.04,0.39l0.05,0.83l-0.45,0.64c-0.18,0.25-0.26,0.55-0.23,0.86l0.12,1.19l-0.01,0.94l-0.45,0.79
                  c-0.01,0.03-0.03,0.07-0.05,0.1l-0.49,1.07l-0.61,0.73l-0.87,0.74c-0.15,0.13-0.26,0.29-0.34,0.48l-0.38,0.92l-0.74,0.6
                  c-0.11,0.09-0.22,0.21-0.29,0.34l-0.52,0.91l-0.56,0.47l-0.87,0.11c-0.08,0.01-0.16,0.03-0.23,0.05l-1.16,0.37l-1.07,0.24
                  l-1.11,0.09l-1.17-0.09c-0.1,0-0.2,0-0.29,0.02l-1.27,0.21c-0.05,0-0.1,0.01-0.14,0.03l-1.21,0.34l-2.69,0.5
                  c-0.06,0.01-0.11,0.03-0.16,0.05l-1.11,0.38l-1.32,0.37c-0.08,0.02-0.16,0.05-0.23,0.09l-1,0.51l-1.08,0.23
                  c-0.35,0.07-0.65,0.29-0.82,0.6l-0.63,1.1c-0.2,0.34-0.22,0.75-0.07,1.12l0.5,1.14l0.21,1.11c0.02,0.08,0.04,0.16,0.07,0.23
                  l0.31,0.77l-0.3,0.62c-0.08,0.16-0.12,0.35-0.12,0.53l-0.01,1.62l-1.56,0.44l-0.97,0.13l-1.26-0.24l-1.16-0.36
                  c-0.05-0.02-0.1-0.03-0.15-0.04l-1.32-0.24l-1.09-0.14l-0.96-0.44c-0.09-0.04-0.2-0.07-0.3-0.09l-1.32-0.23l-1.3-0.15
                  c-0.09-0.01-0.19-0.01-0.29,0l-1.25,0.15c-0.27,0.03-0.52,0.15-0.72,0.34l-0.93,0.9c-0.09,0.08-0.16,0.18-0.22,0.28l-0.48,0.86
                  l-0.72,0.48l-0.97,0.44l-1.11,0.29c-0.23,0.06-0.44,0.19-0.61,0.37l-0.87,0.96c-0.04,0.05-0.08,0.1-0.11,0.15l-0.71,1.07
                  c-0.12,0.18-0.19,0.38-0.21,0.59l-0.1,1.31l-0.03,0.97l-0.07,0.14l-0.28-0.02l-1.21-0.42c-0.35-0.12-0.74-0.08-1.06,0.11l-0.63,0.39
                  l0.15-0.79c0.06-0.31,0-0.63-0.17-0.9L83.19,364c-0.05-0.07-0.1-0.13-0.16-0.2l-0.76-0.79l-0.43-0.96l-0.15-1.12
                  c-0.06-0.42-0.33-0.78-0.71-0.96s-0.83-0.16-1.18,0.06l-1.09,0.67c-0.07,0.05-0.13,0.09-0.19,0.14l-0.9,0.82l-1.87,1.31l-1.09,0.41
                  c-0.04,0.02-0.09,0.04-0.13,0.06l-1.07,0.57l-0.76,0.33l-0.86-0.23l-0.95-0.2l-0.83-0.7c-0.23-0.19-0.53-0.31-0.83-0.29l-1.33,0.03
                  l-1.29,0.11c-0.19,0.02-0.38,0.08-0.55,0.19l-1.08,0.67l-0.93-0.06c-0.16-0.01-0.32,0.01-0.47,0.06l-1.36,0.45
                  c-0.15,0.05-0.3,0.13-0.42,0.24l-1.03,0.87l-0.99,0.56c-0.07,0.04-0.13,0.09-0.19,0.14l-0.54,0.46l-0.68-0.23
                  c-0.05-0.01-0.1-0.02-0.15-0.04l-1.28-0.25c-0.06-0.02-0.12-0.02-0.17-0.03l-1.32-0.08c-0.22-0.01-0.44,0.03-0.63,0.13l-1.41,0.69
                  c-0.26,0.13-0.47,0.34-0.59,0.61l-0.97,2.1l-0.7,0.6l-0.77,0.44l-0.96,0.11l-1.1-0.16c-0.05,0-0.1-0.01-0.14-0.01l-1.24-0.04
                  l-1.11-0.23c-0.1-0.02-0.2-0.03-0.3-0.03l-1.28,0.04c-0.04,0-0.08,0.01-0.12,0.01l-1.23,0.16c-0.25,0.03-0.48,0.14-0.66,0.3
                  l-0.93,0.81c-0.04,0.03-0.07,0.06-0.1,0.1l-0.61,0.66l-0.67,0.21l-0.23-0.02l-0.45-1.53c-0.09-0.32-0.3-0.59-0.59-0.76l-0.56-0.32
                  l0.31-0.35l2.23-1.42c0.05-0.04,0.1-0.08,0.15-0.12l1.01-0.88c0.12-0.1,0.21-0.22,0.29-0.36l0.57-1.08
                  c0.07-0.13,0.11-0.27,0.13-0.42l0.16-1.26c0.01-0.12,0.01-0.24-0.01-0.36l-0.49-2.91l0.01-0.86l0.36-0.81l0.7-0.7l2-1.45
                  c0.15-0.11,0.27-0.25,0.35-0.4l0.61-1.1c0.09-0.16,0.14-0.33,0.16-0.51l0.11-1.51l0.01-1.21l0.25-0.82l2.43-3
                  c0.06-0.08,0.12-0.17,0.16-0.25l0.55-1.17c0.05-0.11,0.09-0.24,0.11-0.36l0.16-1.18l0.31-0.73l0.73-0.62l1.04-0.71
                  c0.14-0.1,0.26-0.23,0.36-0.38l0.66-1.08c0.08-0.14,0.14-0.3,0.17-0.47l0.2-1.43l0.13-1.36l0.29-0.81l0.58-0.66l1.1-0.58l1.24-0.53
                  c0.09-0.04,0.17-0.09,0.25-0.14l1.12-0.82c0.1-0.08,0.19-0.17,0.27-0.27l0.73-0.99c0.02-0.04,0.05-0.07,0.07-0.12l0.59-1.02
                  l0.61-0.62l0.98-0.37l1.3-0.37l1.25-0.45c0.14-0.05,0.27-0.13,0.38-0.22l0.95-0.79c0.14-0.12,0.25-0.26,0.33-0.42l0.54-1.15
                  c0.08-0.16,0.12-0.33,0.12-0.51l0.04-2.61l0.29-0.62l0.86-0.71l1-0.7c0.08-0.06,0.15-0.12,0.22-0.19l0.84-0.93
                  c0.09-0.11,0.17-0.23,0.22-0.36l0.54-1.29c0.05-0.1,0.08-0.22,0.09-0.33l0.28-2.55c0.01-0.04,0.01-0.08,0.01-0.12l0.01-1.26
                  c0-0.13-0.02-0.26-0.06-0.38l-0.34-1.12l-0.03-1.03l0.4-1.02l0.53-0.84l0.71-0.67l2.33-1.08l1.37-0.73l2.55-1.44l1-0.47l1.04-0.32
                  l1.39-0.31c0.25-0.06,0.48-0.19,0.66-0.38l0.86-0.95c0.1-0.11,0.18-0.24,0.23-0.38l0.42-1.07l0.62-0.83l0.77-0.64l0.99-0.44
                  c0.09-0.04,0.17-0.09,0.25-0.15l1.15-0.88c0.1-0.07,0.19-0.17,0.26-0.27l1.48-2.02l0.82-0.96c0.06-0.07,0.11-0.15,0.16-0.23
                  l0.57-1.1l0.69-0.99l0.73-0.82l1.54-0.92l0.61,0.1l2.13,1.55l1.13,0.73c0.21,0.13,0.47,0.21,0.7,0.2l1.32-0.02
                  c0.03,0,0.07,0,0.1-0.01l0.61-0.06l0.46,0.5c0.18,0.2,0.42,0.33,0.68,0.39l1.22,0.24c0.13,0.02,0.26,0.03,0.39,0.01l1.02-0.12
                  l0.77,0.17l0.65,0.47l0.63,0.86c0.09,0.12,0.19,0.22,0.31,0.3l1.02,0.69c0.09,0.06,0.18,0.1,0.29,0.14l1.17,0.41
                  c0.11,0.04,0.24,0.06,0.36,0.07l1.23,0.05c0.21,0,0.41-0.04,0.59-0.12l0.65-0.32l1.22,0.55l0.18,0.69c0.07,0.24,0.21,0.45,0.39,0.62
                  l0.95,0.82c0.08,0.07,0.17,0.12,0.27,0.17l1,0.5l0.9,0.67c0.13,0.1,0.28,0.17,0.44,0.21l0.93,0.24l0.55,0.49l0.51,0.94
                  c0.17,0.31,0.46,0.53,0.8,0.61l2.44,0.61c0.28,0.07,0.59,0.04,0.85-0.09l0.97-0.47l0.02-0.01l0.64,2.5c0.07,0.23,0.2,0.45,0.38,0.61
                  l0.84,0.73l0.7,0.89c0.09,0.12,0.2,0.22,0.32,0.29l1,0.62l0.98,0.75c0.1,0.07,0.2,0.13,0.31,0.17l1.1,0.42l0.71,0.35l0.43,0.77
                  c0.12,0.23,0.32,0.41,0.56,0.52l0.83,0.39l0.43,0.63l0.32,1.01c0.07,0.2,0.18,0.38,0.34,0.53l0.91,0.84c0.33,0.3,0.78,0.4,1.2,0.28
                  l1.2-0.35c0.14-0.04,0.26-0.1,0.38-0.19l1.17-0.83l0.78-0.47l0.59,0.19l0.94,0.58c0.17,0.11,0.35,0.17,0.55,0.18l1.01,0.1l0.79,0.53
                  c0.03,0.02,0.05,0.03,0.08,0.04L144.53,319.59z"/>
                <title>Skalica</title>
              </Link>

              {/* Senica - trnavsky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Senica")}>
                <path style={{ fill: crimesInRegionTrnavsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionTrnavsky[2].crimePercent)] ? crimesInRegionTrnavsky[2].color : "#D3D3D3" }}
                  d="M178.53,390.66c-0.32,0.06-0.61,0.25-0.79,0.53l-0.66,0.99l-0.76,0.88c-0.06,0.06-0.11,0.13-0.15,0.21
                  l-0.66,1.17l-1.56,2.12l-1.47,2.26c-0.05,0.08-0.09,0.17-0.12,0.25l-0.37,1.07l-1.45,1.96c-0.18,0.25-0.26,0.55-0.23,0.86l0.11,1.1
                  l-0.14,0.71l-0.2,0.16l-0.67-0.13c-0.48-0.09-0.98,0.11-1.26,0.51l-0.71,1c-0.06,0.08-0.11,0.18-0.15,0.28l-0.3,0.79l-0.28,0.18
                  l-0.77-0.12c-0.18-0.03-0.37-0.02-0.54,0.03l-1.23,0.35c-0.59,0.16-0.97,0.73-0.9,1.33l0.12,1.19l-0.08,1.17c0,0.1,0,0.2,0.02,0.29
                  l0.09,0.59l-1.28,1.41c-0.04,0.05-0.08,0.09-0.11,0.14l-0.73,1.14l-0.3,0.42l-0.44-0.73c-0.18-0.29-0.47-0.5-0.8-0.57l-1.31-0.29
                  c-0.29-0.06-0.59-0.02-0.85,0.11l-1.21,0.64l-1.06,0.67c-0.08,0.06-0.16,0.12-0.23,0.19l-0.69,0.71l-0.98,0.44
                  c-0.12,0.05-0.23,0.13-0.33,0.22l-0.36,0.32l-0.47-0.16c-0.17-0.06-0.35-0.08-0.53-0.06l-0.92,0.08l-0.83-0.36
                  c-0.05-0.02-0.11-0.04-0.16-0.05l-1.07-0.3l-1.04-0.6c-0.25-0.13-0.53-0.19-0.8-0.15l-0.93,0.14l-0.91-0.3
                  c-0.1-0.03-0.21-0.05-0.31-0.06l-1.31-0.08c-0.34-0.01-0.66,0.1-0.91,0.32l-0.87,0.77l-1.1,0.69c-0.42,0.27-0.64,0.76-0.57,1.25
                  l0.05,0.29l-0.29,0.12c-0.1,0.04-0.2,0.09-0.29,0.16l-1.11,0.83c-0.11,0.08-0.21,0.19-0.29,0.31l-0.63,0.95l-0.71,0.8l-0.9,0.86
                  c-0.08,0.06-0.14,0.14-0.19,0.23l-0.76,1.18l-0.61,1.13l-1.13,2.38c-0.09,0.21-0.13,0.44-0.1,0.67l0.13,1.07l-0.14,0.94l-0.43,1.08
                  c-0.08,0.21-0.11,0.44-0.08,0.66l0.17,1.07l-0.29,0.48l-0.77,0.29c-0.06,0.03-0.13,0.06-0.19,0.09l-0.85,0.51l-0.89-0.55
                  c-0.2-0.13-0.43-0.19-0.66-0.19h-0.99l-1.05-0.46l-1.1-0.91c-0.03-0.02-0.06-0.05-0.1-0.07l-0.76-0.51l-1.21-3.65
                  c-0.08-0.28-0.26-0.52-0.51-0.68l-2.47-1.6c-0.11-0.07-0.22-0.12-0.35-0.15l-2.79-0.78l-0.97-0.82l0.71-0.56
                  c0.06-0.05,0.13-0.12,0.18-0.18l0.96-1.15l2.33-1.95l0.88-0.66l2.16-0.81c0.06-0.02,0.11-0.04,0.16-0.07l1.09-0.59
                  c0.2-0.11,0.37-0.28,0.49-0.48l0.62-1.09c0.1-0.19,0.16-0.4,0.16-0.61v-0.27l0.4,0.2l0.41,0.73c0.31,0.56,0.99,0.79,1.58,0.54
                  c0.58-0.25,0.89-0.9,0.71-1.51l-0.35-1.18c-0.09-0.29-0.28-0.54-0.55-0.71l-0.73-0.45l-0.15-0.54h0.11
                  c0.06-0.01,0.12-0.01,0.17-0.03l1.22-0.25c0.25-0.05,0.48-0.17,0.65-0.35l0.86-0.9l1.05-0.76c0.17-0.11,0.3-0.27,0.39-0.46
                  l0.51-1.03l0.59-1l0.82-0.98l0.57-0.59l0.92-0.01c0.51,0,0.96-0.31,1.15-0.78c0.19-0.47,0.08-1.01-0.29-1.36l-0.03-0.03l0.96-0.73
                  c0.04-0.02,0.08-0.06,0.12-0.09l1.8-1.66c0.06-0.06,0.11-0.12,0.16-0.18l0.85-1.16c0.07-0.1,0.13-0.22,0.17-0.34l0.4-1.18
                  c0.04-0.1,0.06-0.2,0.07-0.3l0.11-1.35c0-0.09,0-0.17-0.01-0.26l-0.17-1.31c-0.01-0.04-0.01-0.09-0.02-0.13l-0.28-1.13l-0.29-2.47
                  c-0.01-0.11-0.04-0.22-0.09-0.33l-0.48-1.14l-0.4-1.32c-0.07-0.23-0.21-0.44-0.39-0.6l-0.9-0.75l-0.62-0.68l-0.35-0.93
                  c-0.03-0.07-0.07-0.14-0.11-0.21l-0.64-1.04l-0.62-1.13c-0.06-0.09-0.12-0.18-0.19-0.26l-0.88-0.93c-0.32-0.33-0.78-0.47-1.23-0.35
                  l-1.08,0.28l-0.92-0.08l-0.35-0.27l-0.21-0.85c-0.09-0.38-0.35-0.69-0.71-0.85l-2.47-1.07c-0.62-0.26-1.34,0.01-1.63,0.61l-8,17.29
                  l-2.12,0.04c-0.22,0.01-0.44,0.07-0.63,0.19l-1.14,0.71c-0.07,0.04-0.13,0.09-0.19,0.14l-0.84,0.78l-1.03,0.75
                  c-0.04,0.02-0.07,0.05-0.1,0.08l-0.94,0.84c-0.18,0.16-0.32,0.37-0.38,0.61l-0.34,1.28c-0.02,0.06-0.03,0.13-0.03,0.2l-0.16,1.63
                  l-0.13,0.48l-1.03,0.42l-2.5,1.17l-1.19,0.43c-0.09,0.04-0.18,0.08-0.26,0.13l-0.98,0.63l-1.04,0.39c-0.36,0.13-0.63,0.41-0.75,0.77
                  l-0.4,1.18c-0.04,0.11-0.06,0.22-0.07,0.34l-0.07,1.51l-0.19,1.19l-0.69-0.14c-0.11-0.03-0.23-0.04-0.35-0.03l-1.24,0.1
                  c-0.11,0.01-0.21,0.04-0.31,0.07l-1.22,0.43c-0.05,0.02-0.1,0.04-0.14,0.06l-0.99,0.48l-1,0.23l-1.14,0.12
                  c-0.27,0.02-0.52,0.13-0.72,0.32l-0.9,0.81l-0.95,0.55l-2.26,0.74l-2.27,0.44l-1.25,0.12c-0.28,0.02-0.54,0.14-0.74,0.34l-0.81,0.77
                  l-1.87,0.94l-0.98-0.04c-0.11-0.01-0.23,0.01-0.34,0.03l-1.25,0.3c-0.14,0.04-0.26,0.09-0.38,0.16l-0.77,0.49l-1.03,0.07l-0.21,0.03
                  l-1.21,0.29c-0.04,0.01-0.08,0.03-0.12,0.04l-1.26,0.44l-0.35-0.14l-0.37-0.74c-0.04-0.1-0.1-0.18-0.17-0.26l-0.67-0.79l-0.35-0.97
                  c-0.07-0.2-0.19-0.37-0.34-0.51l-1.14-1.03c-0.03-0.02-0.06-0.05-0.09-0.07l-1.98-1.56c-0.38-0.31-0.92-0.37-1.36-0.14l-1.13,0.58
                  c-0.11,0.05-0.2,0.12-0.29,0.2l-0.74,0.7l-0.57,0.22l-0.85-0.31c-0.06-0.02-0.12-0.03-0.18-0.05l-1.24-0.24
                  c-0.04-0.01-0.09-0.01-0.13-0.02l-1.26-0.1l-1.1-0.34c-0.05-0.02-0.1-0.03-0.15-0.04l-1.31-0.24l-1.14-0.33
                  c-0.06-0.02-0.13-0.03-0.2-0.04l-1.23-0.15c-0.66-0.08-1.27,0.37-1.38,1.02l-0.24,1.37c-0.04,0.25-0.01,0.5,0.09,0.73l0.18,0.42
                  l-0.19,0.05l-0.73-0.32c-0.14-0.06-0.29-0.1-0.44-0.11l-2.24-0.13c-0.12,0-0.24,0.01-0.36,0.03l-2.56,0.56l-6.05,0.51l-4.78,0.47
                  c-0.17,0.01-0.34,0.07-0.5,0.16l-1.09,0.63c-0.13,0.08-0.25,0.18-0.35,0.31l-0.45,0.57l-0.64,0.05h-1.25
                  c-0.57,0-1.07,0.39-1.21,0.95l-0.32,1.29c-0.06,0.23-0.05,0.47,0.03,0.7l0.28,0.84l-0.8-0.12c-0.17-0.02-0.33-0.01-0.49,0.03
                  L31,425.08c0.01-0.01,0.01-0.02,0.01-0.03l0.18-1.29c0.06-0.41-0.1-0.83-0.42-1.11l-1.84-1.6l-0.37-0.51l0.29-2.71
                  c0.03-0.28-0.04-0.57-0.2-0.81l-0.7-1.06c-0.02-0.03-0.04-0.06-0.06-0.09l-0.77-0.97l-0.46-1.3l-0.2-0.94l0.09-0.92l0.43-1.03
                  c0.03-0.06,0.05-0.12,0.06-0.18l0.3-1.23c0.04-0.14,0.05-0.27,0.04-0.41l-0.12-1.27c0-0.07-0.01-0.15-0.03-0.22l-0.35-1.26
                  l-0.44-2.73c-0.01-0.07-0.03-0.14-0.05-0.21l-0.36-0.99v-0.25l0.47-0.22c0.16-0.08,0.3-0.19,0.41-0.32l0.83-0.98
                  c0.17-0.2,0.27-0.43,0.29-0.69l0.13-1.33c0.02-0.18,0-0.37-0.06-0.54l-0.46-1.28l-0.06-0.67l0.46-0.69l0.59-0.63l2.21-0.69
                  c0.41-0.13,0.73-0.45,0.84-0.86l0.34-1.2l0.36-0.95l0.67-0.82c0.14-0.17,0.23-0.38,0.27-0.61l0.23-1.55c0-0.05,0.01-0.11,0.01-0.16
                  l0.01-0.95l0.5-0.77l0.75-0.9c0.14-0.17,0.24-0.38,0.27-0.6l0.21-1.28c0.03-0.17,0.02-0.34-0.02-0.51l-0.62-2.37l0.31-2.41
                  c0.02-0.14,0.01-0.28-0.02-0.41l-0.24-1.16l0.04-0.93l0.37-0.65l0.94-0.83c0.06-0.05,0.11-0.11,0.16-0.17l0.76-0.98
                  c0.05-0.07,0.09-0.14,0.13-0.21l0.05,0.19c0.01,0.04,0.03,0.07,0.04,0.11l0.43,1.15c0.17,0.44,0.57,0.75,1.04,0.8l1.26,0.13
                  c0.04,0.01,0.09,0.01,0.13,0.01c0.13,0,0.25-0.02,0.37-0.06l1.24-0.39c0.22-0.06,0.4-0.18,0.55-0.35l0.79-0.85l0.59-0.52l0.79-0.1
                  l1.07-0.04l1.07,0.23c0.07,0.02,0.15,0.03,0.22,0.03l1.28,0.04l1.19,0.17c0.11,0.01,0.22,0.01,0.33,0l1.37-0.16
                  c0.16-0.02,0.32-0.07,0.46-0.15l1.1-0.62c0.07-0.04,0.14-0.09,0.2-0.14l0.99-0.85c0.14-0.11,0.24-0.25,0.32-0.41l0.92-1.99
                  l0.71-0.34l0.91,0.05l1.12,0.23l1.27,0.43c0.42,0.13,0.88,0.05,1.21-0.24l0.99-0.84l0.99-0.56c0.07-0.04,0.13-0.09,0.19-0.14
                  l0.93-0.79l0.9-0.3l1.09,0.07c0.27,0.01,0.52-0.05,0.74-0.19l1.17-0.72l0.91-0.08l0.78-0.02l0.71,0.6c0.15,0.13,0.34,0.22,0.55,0.26
                  l1.18,0.25l1.21,0.33c0.28,0.07,0.57,0.05,0.83-0.07l1.24-0.54l1.09-0.57l1.15-0.44c0.09-0.04,0.17-0.08,0.25-0.14l2.09-1.45
                  c0.04-0.03,0.08-0.06,0.12-0.09l0.39-0.35l0.45,1.01c0.06,0.13,0.14,0.25,0.24,0.35l0.82,0.86l0.34,0.54l-0.15,0.79
                  c-0.02,0.09-0.02,0.19-0.02,0.28l0.08,1.83c0.02,0.43,0.25,0.81,0.62,1.03c0.36,0.21,0.81,0.23,1.19,0.04l1.24-0.62
                  c0.03-0.02,0.06-0.03,0.09-0.05l0.77-0.47l0.82,0.28c0.11,0.04,0.22,0.06,0.34,0.07l1.29,0.07c0.49,0.03,0.97-0.25,1.19-0.7
                  l0.55-1.12c0.08-0.16,0.12-0.33,0.13-0.51l0.04-1.18l0.07-0.93l0.48-0.72l0.56-0.62l0.85-0.22c0.07-0.02,0.13-0.04,0.19-0.07
                  l1.16-0.52c0.07-0.03,0.13-0.06,0.19-0.1l1.05-0.71c0.16-0.11,0.29-0.26,0.39-0.42l0.54-0.96l0.5-0.48l0.68-0.08l1.08,0.12
                  l1.08,0.19l0.99,0.46c0.11,0.05,0.24,0.08,0.36,0.1l1.22,0.15l1.18,0.22l1.15,0.35c0.04,0.02,0.09,0.03,0.13,0.04l1.54,0.3
                  c0.14,0.02,0.28,0.03,0.42,0.01l1.27-0.18c0.06-0.01,0.12-0.02,0.17-0.04l1.16-0.34l1.32-0.31c0.56-0.14,0.96-0.64,0.96-1.22
                  l0.01-2.31l0.42-0.87c0.15-0.32,0.16-0.68,0.03-1.01l-0.48-1.17l-0.21-1.12c-0.02-0.09-0.05-0.17-0.08-0.26l-0.3-0.69l0.04-0.07
                  l0.69-0.15c0.11-0.02,0.21-0.06,0.31-0.11l1.04-0.53l1.26-0.36l1.09-0.37l2.63-0.49c0.04-0.01,0.08-0.02,0.12-0.03l1.2-0.34
                  l1.06-0.17l1.11,0.08h0.19l1.3-0.1c0.06-0.01,0.11-0.02,0.17-0.03l1.21-0.27c0.04-0.01,0.08-0.02,0.11-0.03l1.1-0.35l1.12-0.14
                  c0.24-0.03,0.47-0.13,0.66-0.29l1.01-0.86c0.11-0.09,0.2-0.2,0.27-0.32l0.52-0.89l0.81-0.66c0.16-0.13,0.29-0.3,0.37-0.5l0.38-0.94
                  l0.72-0.61c0.05-0.05,0.09-0.09,0.14-0.15l0.79-0.94c0.07-0.08,0.13-0.18,0.18-0.28l0.53-1.17l0.59-1.02
                  c0.1-0.19,0.16-0.4,0.16-0.61l0.01-1.34c0-0.05,0-0.09-0.01-0.14l-0.08-0.79l0.44-0.61c0.17-0.23,0.26-0.52,0.24-0.81l-0.07-1.08
                  l0.21-0.83l0.33-0.54l0.4,0.43c0.41,0.44,1.08,0.53,1.59,0.2l0.82-0.52c0.07,0.19,0.19,0.36,0.34,0.5l0.96,0.83
                  c0.29,0.25,0.68,0.35,1.05,0.28l0.87-0.16l0.83,0.33c0.14,0.06,0.29,0.09,0.44,0.09l1.35,0.03l1.28-0.05
                  c0.3-0.01,0.58-0.13,0.8-0.33l1.86-1.7l0.98-0.68c0.27-0.19,0.46-0.47,0.52-0.8l0.2-1.05l0.47-0.82l0.25-0.29l0.46,0.11
                  c0.27,0.06,0.57,0.03,0.82-0.09l0.4-0.2l0.79,2l0.1,0.6l-0.43,0.62c-0.36,0.53-0.27,1.25,0.22,1.66l0.89,0.76l0.81,0.84
                  c0.2,0.2,0.47,0.34,0.75,0.37l0.99,0.12l0.88,0.51c0.13,0.07,0.27,0.12,0.41,0.14l1.24,0.21c0.15,0.03,0.29,0.02,0.43,0
                  c0.11,0.2,0.28,0.37,0.49,0.49l1.04,0.58l0.97,0.67c0.18,0.12,0.38,0.19,0.59,0.21l2.49,0.23h0.39l0.05,0.22
                  c0.03,0.1,0.07,0.21,0.12,0.31l0.54,1l0.34,0.98l0.24,1.14c0.02,0.11,0.05,0.21,0.1,0.31l0.55,1.1c0.12,0.24,0.31,0.43,0.55,0.55
                  l1.05,0.54l0.81,0.53l0.52,0.68l0.34,0.94l0.17,0.64l-0.97,1.75c-0.12,0.21-0.17,0.45-0.15,0.69l0.05,0.5l-0.48-0.16
                  c-0.37-0.12-0.78-0.06-1.1,0.15l-1.04,0.7c-0.43,0.29-0.63,0.82-0.52,1.32l0.24,1.02l-0.25,0.79l-0.44,1.14
                  c-0.05,0.15-0.08,0.3-0.08,0.46l0.01,0.9l-1.18,1.99c-0.13,0.22-0.19,0.48-0.16,0.73l0.08,0.96l-0.33,0.92
                  c-0.18,0.5-0.02,1.06,0.4,1.4l1.11,0.89l1.1,0.78l0.57,0.48l0.06,0.37l-0.44,0.81c-0.17,0.33-0.2,0.71-0.06,1.05l0.46,1.18
                  c0.05,0.14,0.12,0.26,0.21,0.37l0.61,0.69l0.16,0.96c0.02,0.15,0.08,0.3,0.16,0.44l0.64,1.06c0.08,0.13,0.18,0.24,0.3,0.33l0.7,0.55
                  l0.26,0.82c0.07,0.22,0.19,0.42,0.37,0.57l0.96,0.84c0.02,0.02,0.05,0.05,0.08,0.07l0.89,0.66l0.64,0.8l0.61,0.92l0.27,1.15
                  c0.02,0.05,0.03,0.1,0.05,0.15l0.37,0.97l0.03,0.8l-0.43,0.85c-0.29,0.57-0.11,1.25,0.41,1.61l0.79,0.53l0.68,1.16
                  c0.02,0.04,0.04,0.07,0.06,0.1l0.94,1.3l0.97,1.23l1.52,1.67L178.53,390.66z"/>
                <title>Senica</title>
              </Link>

              {/* Zlate moravce - nitriansky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Zlaté Moravce")}>
                <path
                  d="M450.71,489.09l-0.13,0.64l-0.85,0.94c-0.12,0.12-0.2,0.27-0.26,0.43l-0.44,1.25
                  c-0.05,0.13-0.07,0.28-0.07,0.43l0.02,1.32c0,0.05,0.01,0.11,0.01,0.16l0.12,0.8l-0.66,0.86l-1.05,0.19
                  c-0.23,0.04-0.44,0.14-0.61,0.3l-0.96,0.85l-1.06,0.53c-0.13,0.07-0.24,0.15-0.34,0.25l-1.03,1.06c-0.39,0.41-0.46,1.03-0.17,1.51
                  l0.41,0.69l-0.03,0.21l-0.4,0.21l-1.12,0.3c-0.47,0.13-0.83,0.53-0.9,1.01l-0.21,1.31c-0.01,0.01-0.01,0.02-0.01,0.03
                  c-0.15-0.04-0.3-0.05-0.45-0.03c-0.41,0.05-0.77,0.29-0.96,0.65l-0.07,0.13l-0.28-0.35c-0.39-0.48-1.07-0.6-1.6-0.29l-2.12,1.21
                  l-0.74,0.23l-0.94-0.27c-0.05-0.02-0.11-0.03-0.18-0.04l-1.09-0.14l-0.95-0.44c-0.23-0.1-0.47-0.14-0.72-0.09l-1.25,0.2
                  c-0.15,0.02-0.31,0.08-0.44,0.16l-0.87,0.51l-0.89,0.15l-1.07,0.01l-1.32-0.12c-0.51-0.04-0.99,0.23-1.22,0.68
                  c-0.23,0.45-0.17,0.99,0.16,1.38l0.85,1l0.61,0.98l0.41,0.75l-0.16,0.58l-0.31,0.59l-0.73,0.3c-0.56,0.23-0.87,0.81-0.76,1.4
                  l0.24,1.23c0.03,0.15,0.09,0.29,0.17,0.42l0.75,1.2l0.64,0.94l0.12,0.71l-0.48,1.31l-0.25-0.03c-0.17-0.02-0.33-0.01-0.49,0.04
                  l-1.43,0.41c-0.27,0.08-0.51,0.24-0.67,0.47l-1.16,1.61c-0.18,0.25-0.26,0.55-0.23,0.85l0.27,2.8c0.04,0.47,0.35,0.88,0.8,1.05
                  l1.15,0.43l0.82,0.63c0.06,0.05,0.13,0.09,0.2,0.13l0.77,0.39l0.23,0.39l-1.43,0.09l-1.7-1.68c-0.08-0.07-0.16-0.14-0.25-0.19
                  l-1.06-0.6l-2.66-1.84l-0.82-0.62l-0.53-0.87c-0.14-0.23-0.35-0.41-0.6-0.51l-1.28-0.52c-0.28-0.11-0.59-0.12-0.87-0.02l-0.68,0.23
                  l-0.63-0.36c-0.55-0.31-1.23-0.16-1.61,0.34l-0.48,0.64l-0.52-0.08c-0.33-0.04-0.67,0.04-0.93,0.23c-0.27,0.2-0.45,0.5-0.5,0.83
                  l-0.05,0.38l-0.31,0.02c-0.1,0.01-0.19,0.03-0.28,0.06l-1.6,0.52c-0.08,0.03-0.15,0.06-0.22,0.1l-1.08,0.61
                  c-0.54,0.3-0.77,0.94-0.57,1.51l0.45,1.25c0.02,0.04,0.03,0.07,0.05,0.11l1.16,2.45l0.46,0.84v0.02c-0.09-0.05-0.19-0.08-0.29-0.1
                  l-1.35-0.24l-1.16-0.32l-2.37-0.88c-0.08-0.03-0.16-0.05-0.25-0.07l-1.1-0.16l-1.34-0.46l-1.23-0.55l-1.15-0.45l-1.06-0.52
                  l-0.67-0.48l-0.24-0.47l0.13-0.77l0.27-0.76l0.73-0.62c0.16-0.13,0.28-0.3,0.35-0.49l0.48-1.21c0.22-0.55,0.02-1.19-0.48-1.51
                  l-1.27-0.82c-0.12-0.08-0.25-0.13-0.39-0.16l-1.32-0.32c-0.06-0.02-0.12-0.03-0.18-0.03l-1.42-0.12c-0.07-0.01-0.14-0.01-0.2-0.01
                  l-1.44,0.11l-1.03-0.27l-1.43-0.59c-0.11-0.05-0.22-0.08-0.33-0.09l-1.46-0.17c-0.05-0.01-0.11-0.01-0.17-0.01l-1.49,0.04
                  l-0.06-0.92l0.38-0.87c0.09-0.22,0.12-0.46,0.09-0.69l-0.2-1.23c-0.02-0.18-0.09-0.35-0.19-0.5l-0.69-1.04
                  c-0.25-0.38-0.69-0.6-1.16-0.55l-1.6,0.15c-0.1,0.01-0.2,0.03-0.29,0.06l-0.79,0.28l-0.3-0.12l-0.84-1.84
                  c-0.09-0.2-0.23-0.38-0.42-0.51l-1.04-0.74c-0.06-0.04-0.12-0.08-0.19-0.11l-1.12-0.52c-0.35-0.16-0.74-0.16-1.08,0.01l-1.18,0.58
                  c-0.18,0.09-0.33,0.21-0.45,0.37l-0.6,0.8l-0.38-0.85l-0.35-0.66l0.21-0.69c0.14-0.42,0.03-0.88-0.26-1.21l-0.84-0.93
                  c-0.1-0.11-0.22-0.2-0.34-0.26l-1.03-0.55l-0.78-0.67l-0.79-0.81c0.52-0.01,0.99-0.34,1.16-0.85l0.37-1.08l1.32-1.86
                  c0.03-0.04,0.05-0.08,0.08-0.13l0.3,0.2c0.3,0.2,0.68,0.26,1.03,0.16s0.64-0.34,0.79-0.67l0.56-1.21c0.19-0.4,0.15-0.87-0.1-1.23
                  l-1.94-2.83l0.04-0.64l0.45-0.89c0.03-0.07,0.06-0.13,0.08-0.2l0.36-1.22l0.45-1.15c0.16-0.39,0.1-0.83-0.13-1.17l-1.46-2.08
                  c-0.04-0.04-0.08-0.09-0.12-0.13l-2.93-3l-0.71-0.87l-0.48-0.85l-0.36-1.16l0.14-1.03c0.04-0.29-0.03-0.58-0.18-0.82l-0.83-1.32
                  c-0.11-0.17-0.25-0.31-0.42-0.41l-1.53-0.92c-0.07-0.04-0.14-0.08-0.22-0.11l-0.54-0.19l-0.11-1.55c-0.03-0.41-0.26-0.77-0.6-0.98
                  l-2.78-1.68l-2.28-2.78c-0.02-0.02-0.04-0.05-0.07-0.07l-1.96-2.05c-0.03-0.04-0.07-0.08-0.11-0.11l-0.88-0.71l-0.29-1.24l0.43-0.24
                  c0.2-0.11,0.36-0.27,0.48-0.47l0.53-0.9l1.93-1.13c0.15-0.09,0.28-0.21,0.38-0.35l0.66-0.88l0.84-0.71l1.01-0.5
                  c0.03-0.02,0.07-0.04,0.1-0.06l1.04-0.65c0.33-0.2,0.54-0.55,0.58-0.93l0.11-1l0.54-0.89l1.34-0.89l1.23-0.44
                  c0.38-0.14,0.67-0.45,0.78-0.83l0.3-1.06l0.39-0.71l0.76-0.46c0.05-0.02,0.09-0.05,0.13-0.09l0.98-0.77
                  c0.08-0.06,0.15-0.13,0.21-0.21l0.76-0.97c0.08-0.09,0.14-0.2,0.19-0.32l0.5-1.31l0.83-0.93l0.81-0.64l2.4-0.48l1.56,0.02
                  c0.25-0.01,0.51-0.07,0.72-0.22l1.2-0.84l1.03-0.81c0.03-0.02,0.06-0.05,0.09-0.07l0.67-0.64l0.81-0.24l1.09,0.05
                  c0.09,0,0.18,0,0.27-0.02l1.43-0.24c0.2-0.03,0.39-0.12,0.56-0.24l0.38-0.29l0.63,0.35c0.11,0.06,0.22,0.1,0.34,0.13l1.19,0.26
                  l2.24,0.67l0.82,0.72c0.18,0.16,0.41,0.27,0.65,0.31l1.21,0.18c0.28,0.04,0.56-0.02,0.8-0.16l1.06-0.61
                  c0.14-0.07,0.25-0.17,0.35-0.29l0.5-0.61l0.84,0.68c0.09,0.07,0.19,0.13,0.3,0.17l0.94,0.4l0.68,0.73c0.29,0.33,0.74,0.47,1.17,0.39
                  c0.43-0.09,0.78-0.4,0.93-0.81l0.44-1.23c0.02-0.08,0.05-0.16,0.06-0.24l0.06-0.44l0.45-0.13c0.16-0.05,0.31-0.12,0.43-0.23
                  l0.99-0.79l1.14-0.5l1.23-0.62c0.06-0.03,0.12-0.07,0.17-0.1l1.09-0.78c0.13-0.1,0.24-0.21,0.32-0.34l0.68-1.06l0.77-1.02l1.03-0.83
                  c0.07-0.06,0.14-0.13,0.2-0.2l0.7-0.88l0.86-0.67l1.11-0.69c0.17-0.09,0.3-0.23,0.4-0.38l0.64-1l0.71-0.74l0.9-0.31
                  c0.06-0.02,0.13-0.04,0.19-0.08l1.87-0.99l1.2-0.49l1.24-0.35c0.14-0.04,0.28-0.1,0.39-0.19l2.11-1.48
                  c0.18-0.12,0.32-0.28,0.42-0.47l0.5-1.02l0.79-0.46l1.21-0.49c0.13-0.06,0.26-0.14,0.36-0.24l0.95-0.87c0.1-0.08,0.18-0.19,0.24-0.3
                  l0.62-1.08c0.03-0.04,0.05-0.08,0.07-0.13l0.22-0.52l0.85-0.14c0.11-0.01,0.21-0.04,0.3-0.09l0.45-0.2l0.93,0.58l0.78,0.65
                  l0.43,0.71l0.37,1.72c0.02,0.11,0.06,0.22,0.12,0.33l0.76,1.43c0.07,0.13,0.16,0.24,0.27,0.34l0.82,0.73l0.61,1.3
                  c0.07,0.14,0.17,0.27,0.28,0.38l-0.28,0.3c-0.06,0.07-0.12,0.15-0.16,0.23l-0.54,0.94l-0.76,0.71c-0.06,0.06-0.11,0.12-0.16,0.18
                  l-0.66,0.93l-0.81,0.82c-0.08,0.09-0.16,0.19-0.21,0.29l-1.86,3.44c-0.07,0.13-0.11,0.26-0.14,0.4l-0.19,1.23
                  c-0.1,0.68,0.37,1.32,1.05,1.43l1.28,0.19c0.09,0.02,0.18,0.02,0.28,0.02l1.28-0.09c0.18-0.02,0.35-0.07,0.5-0.15l1.37-0.74
                  c0.41-0.22,0.67-0.65,0.66-1.12l-0.01-0.87l0.35-0.42l1.94-0.58l1.19,0.04l1.01,0.14l0.83,0.61c0.07,0.05,0.15,0.1,0.23,0.13
                  l1.19,0.53c0.29,0.13,0.6,0.14,0.89,0.05c0.01,0.01,0.01,0.01,0.02,0.02l0.65,0.84l0.28,0.68l-0.25,1.98l-0.38,1.07
                  c-0.04,0.12-0.06,0.24-0.07,0.36l-0.05,0.96l-0.45,0.88c-0.08,0.16-0.13,0.34-0.14,0.52l-0.05,1.19l-0.21,1.13
                  c-0.01,0.04-0.01,0.08-0.01,0.12l-0.11,1.23c-0.04,0.4,0.12,0.79,0.42,1.06c0.31,0.26,0.72,0.36,1.11,0.27l0.12-0.03l-0.89,0.44
                  c-0.21,0.1-0.39,0.26-0.51,0.46l-0.68,1.1c-0.13,0.2-0.19,0.43-0.19,0.66v0.83l-1.32,1.83c-0.15,0.21-0.23,0.47-0.23,0.73l0.01,1.68
                  c0,0.4,0.2,0.79,0.54,1.02s0.76,0.29,1.15,0.14l0.8-0.3l0.16,0.06l0.14,0.45l0.04,1.04l-0.01,1.33c0,0.27,0.09,0.54,0.25,0.76
                  l0.74,0.99c0.11,0.15,0.26,0.27,0.42,0.36l1.11,0.57l0.44,0.3v0.36l-0.36,0.88l-0.6,0.95c-0.03,0.05-0.06,0.11-0.08,0.16l-0.56,1.25
                  c-0.02,0.05-0.04,0.1-0.06,0.16l-0.37,1.26c-0.07,0.23-0.07,0.48,0,0.71l0.37,1.22c0.16,0.53,0.64,0.89,1.19,0.89l1.35,0.01
                  l1.26-0.03c0.04,0,0.07,0,0.11-0.01l1.83-0.19l0.19,0.26l0.35,1.27l0.43,1.24l0.38,1.32c0.08,0.28,0.26,0.52,0.5,0.68l0.36,0.25
                  l-0.34,2.09c-0.02,0.1-0.02,0.2-0.02,0.3l0.11,1.28c0.01,0.09,0.03,0.17,0.06,0.26l0.37,1.19c0.05,0.17,0.14,0.33,0.26,0.47
                  l0.32,0.35l-0.18,0.41c-0.1,0.25-0.13,0.52-0.07,0.78l0.36,1.46L450.71,489.09z"/>
                <title>Zlaté Moravce</title>
              </Link>


              {/* Levice - nitriansky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Levice")}>
                <path style={{ fill: crimesInRegionNitriansky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionNitriansky[1].crimePercent)] ? crimesInRegionNitriansky[1].color : "#D3D3D3" }}
                  d="M578.51,564.43l0.1,0.04c-0.04,0.03-0.09,0.07-0.12,0.11l-0.89,0.87c-0.1,0.09-0.18,0.2-0.24,0.32l-0.59,1.14
                  c-0.07,0.13-0.11,0.28-0.13,0.43l-0.14,1.23c-0.02,0.19,0,0.38,0.06,0.57l0.36,0.99l-0.26,0.77c-0.02,0.07-0.04,0.13-0.05,0.2
                  l-0.13,0.73l-1.62,1.27c-0.29,0.23-0.46,0.57-0.47,0.94c-0.01,0.36,0.14,0.72,0.42,0.96l0.81,0.73l0.39,0.74l0.13,1
                  c0.05,0.37,0.25,0.69,0.56,0.89l0.52,0.33l0.02,0.68c0.01,0.29,0.13,0.57,0.32,0.79l0.6,0.66l0.21,1.04l0.04,1.1l-0.29,1.21
                  l-0.52,1.09l-0.46,1.17l-0.56,0.88l-1.62,2c-0.09,0.11-0.16,0.23-0.2,0.36l-0.31,0.85l-0.66,0.45l-1.05,0.47
                  c-0.08,0.04-0.16,0.09-0.23,0.14l-0.92,0.69l-1.06,0.55c-0.11,0.05-0.21,0.12-0.29,0.21l-0.93,0.89c-0.06,0.05-0.11,0.11-0.16,0.18
                  l-0.77,1.09c-0.09,0.12-0.15,0.26-0.19,0.41l-0.35,1.35c-0.06,0.24-0.05,0.49,0.03,0.73l0.16,0.45l-0.07,0.08l-0.83,0.16
                  c-0.13,0.03-0.25,0.07-0.37,0.14l-1.08,0.6c-0.3,0.16-0.51,0.44-0.6,0.78l-0.32,1.23c-0.02,0.08-0.04,0.17-0.04,0.26l-0.07,1.74
                  c0,0.1,0.01,0.21,0.03,0.31l0.37,1.72l0.16,1.17l-1.04,0.63c-0.49,0.29-0.72,0.86-0.57,1.4l0.03,0.12l-1.66,0.25
                  c-0.25,0.04-0.47,0.15-0.65,0.32l-1.02,0.93c-0.19,0.17-0.32,0.39-0.38,0.64l-0.27,1.18l-0.29,0.99l-0.66,0.74l-0.56,0.45
                  l-0.65-0.08l-0.96-0.37l-1.15-0.62c-0.17-0.09-0.37-0.15-0.57-0.15l-1.54-0.03l-1.44,0.02l-1.43-0.06l-2.78-0.17
                  c-0.09,0-0.18,0-0.28,0.01l-1.35,0.2c-0.04,0.01-0.08,0.02-0.12,0.03l-1.98,0.5c-0.09,0.02-0.19,0.06-0.28,0.11l-1.26,0.67
                  l-1.37,0.61l-1.08,0.3l-1.04,0.22l-3.23-1.11l-0.99-0.85c-0.26-0.22-0.61-0.33-0.95-0.29c-0.34,0.03-0.65,0.21-0.86,0.49l-0.76,1.01
                  c-0.04,0.05-0.08,0.11-0.11,0.17l-0.55,1.05l-0.65,0.78l-0.68,0.47l-3.29-1.02c-0.36-0.1-0.75-0.05-1.06,0.16l-0.44,0.3l-1.98-1.25
                  c-0.39-0.25-0.89-0.27-1.29-0.04l-1.37,0.77c-0.25,0.14-0.44,0.36-0.55,0.62l-0.11,0.27h-0.01c-0.31-0.15-0.67-0.17-0.99-0.05
                  c-0.33,0.12-0.58,0.37-0.72,0.69l-0.61,1.49l-0.5-0.19c-0.58-0.22-1.23,0.02-1.53,0.56l-0.75,1.34c-0.22,0.39-0.21,0.87,0.02,1.26
                  l0.68,1.12c0.04,0.06,0.08,0.12,0.13,0.18l0.58,0.65l0.05,0.66l-0.47,0.92l-1.69,1.53c-0.27,0.25-0.43,0.61-0.41,0.98l0.06,1.51
                  c0,0.04,0,0.08,0.01,0.11l0.19,1.41l-0.05,1.4c-0.01,0.3,0.09,0.59,0.27,0.82l0.47,0.58l-0.08,0.57l-0.46,1.22
                  c-0.03,0.07-0.05,0.15-0.06,0.22l-0.26,1.46c-0.01,0.05-0.02,0.11-0.02,0.16l-0.04,0.93l-0.57,0.79l-0.86,0.92l-1.35,0.89l-0.95,0.3
                  l-1.35-0.42c-0.07-0.03-0.15-0.04-0.22-0.05l-1.25-0.15c-0.19-0.02-0.39,0-0.57,0.06l-2.11,0.78l-1.09-0.3
                  c-0.27-0.07-0.55-0.05-0.81,0.06l-1.22,0.52c-0.19,0.08-0.36,0.21-0.49,0.37l-0.75,0.95l-0.87,0.63c-0.06,0.05-0.12,0.1-0.17,0.15
                  l-0.87,0.92c-0.17,0.18-0.28,0.41-0.33,0.66l-0.23,1.39c-0.04,0.26,0,0.53,0.12,0.77l0.57,1.12c0.11,0.2,0.26,0.37,0.46,0.49
                  l1.04,0.65l0.78,1.2c0.11,0.17,0.27,0.32,0.45,0.42l0.86,0.47l0.79,1.37l0.43,0.67l-0.22,0.65l-0.63,0.89
                  c-0.11,0.15-0.18,0.32-0.21,0.5l-0.47-0.23c-0.18-0.09-0.4-0.13-0.56-0.13l-1.33,0.01l-1.38-0.02c-0.1,0-0.2,0.01-0.29,0.03
                  l-1.22,0.28l-1.03-0.42c-0.39-0.16-0.84-0.11-1.19,0.14l-0.83,0.58l-0.97,0.06l-0.88-0.03l-0.83-0.62c-0.17-0.12-0.36-0.2-0.56-0.24
                  l-1.3-0.2c-0.44-0.06-0.87,0.1-1.15,0.44l-0.32,0.38l-0.3-0.09l-1.34-0.67c-0.14-0.07-0.29-0.11-0.45-0.13l-1.31-0.11
                  c-0.08-0.01-0.16-0.01-0.25,0l-0.9,0.1l-1.1-0.53c-0.36-0.17-0.79-0.16-1.14,0.03l-1.24,0.68c-0.17,0.09-0.31,0.22-0.42,0.37
                  l-0.66,0.94l-1.73,1.73l-0.69,0.54l-2.2-0.24l-0.85-0.05l-0.92-0.69l-1.27-0.84c-0.09-0.06-0.19-0.11-0.29-0.14l-1.23-0.42
                  l-1.16-0.57l-1.35-0.73l-1.38-0.65c-0.55-0.26-1.21-0.08-1.56,0.42l-0.72,1.05l-0.51,0.56l-0.69-0.01c-0.23,0-0.48,0.06-0.68,0.19
                  l-1.23,0.77l-1.06,0.75c-0.05,0.03-0.1,0.07-0.14,0.11l-1.03,0.98c-0.07,0.07-0.12,0.13-0.17,0.2l-0.07-0.05
                  c-0.05-0.03-0.1-0.06-0.14-0.08l-1.3-0.69c-0.05-0.03-0.09-0.05-0.14-0.07l-1.37-0.53c-0.05-0.02-0.1-0.03-0.16-0.04l-1.2-0.29
                  c-0.05-0.02-0.11-0.03-0.17-0.03l-1.96-0.19l-1.53-0.04l-1.02-0.11l-0.64-0.6c-0.37-0.36-0.93-0.44-1.4-0.21l-1.31,0.64
                  c-0.12,0.05-0.23,0.13-0.32,0.22l-1.06,1.03l-1,1.1c-0.06,0.06-0.11,0.13-0.16,0.2l-0.93,1.58l-0.34-0.02l-1.19-0.51l-2.48-0.91
                  l-1.14-0.36l-2.71-1.12c-0.05-0.02-0.11-0.04-0.17-0.05l-1.37-0.33l-1.33-0.22l-1.26-0.27c-0.22-0.05-0.45-0.04-0.67,0.04
                  l-0.56,0.19l-1.32-1.03l-0.58-0.87c-0.04-0.05-0.09-0.11-0.14-0.16l-0.98-1.04c-0.24-0.25-0.56-0.39-0.9-0.39
                  c-0.32,0.02-0.61,0.11-0.84,0.31c0,0,0-0.01-0.01-0.01l-0.87-1.02l-0.8-1.06l-0.39-1.12l-0.35-1.19l-0.13-1.42l-0.17-1.22
                  c-0.01-0.07-0.03-0.14-0.05-0.21l-0.38-1.19l0.24-0.88c0.03-0.1,0.05-0.21,0.05-0.32l0.02-0.94l1.04-1.96l0.77-0.27
                  c0.33-0.11,0.59-0.34,0.73-0.65l0.55-1.18c0.04-0.07,0.06-0.15,0.08-0.22l0.3-1.19c0.07-0.27,0.05-0.56-0.06-0.81l-0.52-1.19
                  c-0.06-0.13-0.13-0.24-0.23-0.34l-0.83-0.91c-0.05-0.06-0.11-0.12-0.18-0.17l-0.37-0.27l0.02-0.12l0.6-0.54
                  c0.04-0.04,0.08-0.08,0.11-0.12l0.91-1.07c0.06-0.07,0.12-0.15,0.16-0.23l0.62-1.19c0.01-0.03,0.03-0.07,0.04-0.1l0.48-1.15
                  c0.22-0.51,0.07-1.09-0.36-1.44l-1.02-0.84c-0.03-0.04-0.07-0.06-0.11-0.09l-1.15-0.74c-0.08-0.05-0.16-0.09-0.24-0.12l-1.13-0.43
                  l-0.68-0.42l-0.36-0.89c-0.01-0.04-0.03-0.07-0.04-0.1l-0.55-1.1c-0.05-0.09-0.1-0.17-0.17-0.25l-1.34-1.64l-0.1-0.71l0.45-0.45
                  c0.07-0.07,0.13-0.15,0.18-0.24l0.68-1.14c0.09-0.14,0.14-0.29,0.16-0.45l0.2-1.29c0.02-0.08,0.02-0.17,0.02-0.26l-0.07-1.29
                  c-0.01-0.05-0.01-0.11-0.02-0.16l-0.23-1.18l-0.14-1.26c-0.03-0.27-0.14-0.52-0.33-0.71l-0.86-0.92c-0.09-0.1-0.19-0.18-0.3-0.24
                  l-1.11-0.62l-1.33-1.93l-0.83-2.1l-0.26-1.17l-0.31-1.13c0.06,0.02,0.11,0.03,0.17,0.03l1.3,0.15c0.04,0.01,0.08,0.01,0.13,0.01
                  l1.01,0.01l2.24,0.81c0.35,0.13,0.75,0.09,1.07-0.11s0.54-0.53,0.59-0.91l0.12-0.94l0.54-0.77c0.04-0.06,0.08-0.13,0.11-0.2
                  l0.55-1.23c0.09-0.2,0.13-0.42,0.1-0.63l-0.14-1.44v-0.57l0.55-0.38c0.13-0.09,0.24-0.2,0.32-0.33l0.69-1.05
                  c0.19-0.28,0.25-0.63,0.18-0.95l-0.27-1.25c-0.02-0.12-0.06-0.23-0.12-0.33l-0.52-0.97l-0.3-1.2c-0.01-0.05-0.03-0.11-0.05-0.16
                  l-0.47-1.15l-1.29-3.52c-0.03-0.08-0.06-0.16-0.11-0.23l-0.66-1.1c-0.08-0.14-0.19-0.27-0.33-0.36l-0.96-0.72l-0.86-0.9l-0.92-0.83
                  c-0.11-0.09-0.22-0.17-0.35-0.22l-1.27-0.54l-1.18-0.42c-0.06-0.02-0.12-0.04-0.19-0.05l-1.33-0.25l-1.17-0.16l-1.12-0.29
                  c-0.14-0.04-0.28-0.05-0.43-0.03l-0.99,0.09l-1.03-0.33c-0.34-0.11-0.71-0.07-1.02,0.12l-0.14,0.08l-0.11-0.13
                  c-0.08-0.1-0.18-0.19-0.29-0.26l-1.16-0.73c-0.12-0.07-0.24-0.12-0.37-0.15l-0.81-0.21l-0.34-0.72l-0.53-0.95l-0.08-1.03
                  c-0.03-0.33-0.19-0.64-0.44-0.85l-0.83-0.71l-0.46-0.93c-0.2-0.41-0.61-0.68-1.06-0.7l-1.21-0.06l-1.21-0.16l-0.95-0.29l-0.91-0.51
                  l-0.85-0.59l-0.48-0.72l-0.19-0.9l-0.14-2.39c-0.01-0.08-0.02-0.16-0.04-0.24l-0.32-1.2c-0.03-0.09-0.06-0.17-0.1-0.25l-0.52-1.02
                  l-0.07-2.03l0.37-0.55l0.69-0.57l0.99-0.47c0.18-0.08,0.34-0.21,0.46-0.36l0.82-1.06c0.13-0.18,0.22-0.39,0.25-0.61l0.16-1.24
                  c0.03-0.26-0.02-0.52-0.14-0.75l-0.62-1.15c-0.03-0.06-0.07-0.12-0.11-0.17l-0.76-0.99c-0.05-0.06-0.1-0.12-0.15-0.17l-0.72-0.64
                  l-0.32-0.75l-0.14-1.24c-0.01-0.09-0.03-0.17-0.06-0.26l-0.26-0.78l0.17-0.29l0.5-0.07l1.42,0.1c0.2,0.01,0.41-0.03,0.6-0.11
                  l0.45-0.21l0.66,0.5c0.08,0.06,0.16,0.11,0.25,0.15l1.32,0.59l2.27,0.84l0.87,0.5l0.69,0.8c0.38,0.43,0.99,0.55,1.5,0.3
                  s0.79-0.81,0.67-1.37l-0.19-0.96l0.15-0.59l0.84-0.61l0.78-0.39l3.1,0.11c0.4,0.02,0.78-0.16,1.03-0.47l1.16-1.43l1.55-2.15
                  c0.06-0.08,0.11-0.17,0.15-0.27l0.41-1.08l0.54-0.76l0.65-0.52l1.01-0.13c0.51-0.07,0.93-0.45,1.05-0.96l0.35-1.51
                  c0.06-0.25,0.03-0.52-0.07-0.77l-0.48-1.14c-0.02-0.06-0.06-0.12-0.09-0.17l-0.8-1.27l-0.48-1.07c-0.09-0.2-0.23-0.37-0.4-0.5
                  l-1.24-0.9l-0.95-0.95l-0.63-0.54l-0.06-0.81c-0.02-0.17-0.06-0.34-0.14-0.49l-0.59-1.12c-0.11-0.21-0.28-0.38-0.48-0.5l-1.24-0.73
                  c-0.15-0.09-0.33-0.15-0.5-0.16c0.01-0.23-0.04-0.45-0.15-0.65l-0.61-1.11l-1.09-2.31l-0.08-0.2l1.4-0.47l1.15-0.1
                  c0.58-0.06,1.04-0.5,1.13-1.07l0.01-0.11l0.02,0.01c0.46,0.06,0.91-0.12,1.19-0.49l0.27-0.36l0.17,0.09
                  c0.31,0.17,0.68,0.21,1.01,0.09l0.76-0.26l0.47,0.19l0.44,0.73c0.09,0.14,0.19,0.25,0.32,0.35l3.79,2.69
                  c0.03,0.02,0.06,0.04,0.09,0.06l0.98,0.55l1.93,1.93c0.26,0.27,0.62,0.4,0.99,0.38l2.51-0.16c0.09-0.01,0.18-0.02,0.26-0.05
                  l1.45-0.4c0.39-0.1,0.7-0.4,0.84-0.78c0.13-0.39,0.07-0.81-0.16-1.14l-0.73-1.02l-0.58-1.04c-0.13-0.21-0.31-0.39-0.53-0.5l-1-0.5
                  l-0.88-0.68c-0.1-0.08-0.21-0.14-0.32-0.18l-0.59-0.22l-0.15-1.55l0.63-0.88l0.77-0.22l1.01,0.12c0.58,0.07,1.14-0.28,1.33-0.84
                  l0.9-2.53c0.08-0.21,0.1-0.43,0.06-0.65l-0.23-1.3c-0.03-0.17-0.1-0.34-0.2-0.48l-0.76-1.12l-0.6-0.96l0.2-0.08
                  c0.27-0.11,0.5-0.32,0.64-0.58l0.59-1.12c0.04-0.08,0.07-0.16,0.09-0.25l0.33-1.19c0.09-0.31,0.05-0.64-0.1-0.93l-0.69-1.26
                  l-0.1-0.17h0.05c0.06,0,0.13-0.01,0.19-0.02l1.22-0.2c0.16-0.02,0.31-0.08,0.44-0.16l0.87-0.51l0.63-0.11l0.78,0.36
                  c0.11,0.05,0.23,0.08,0.36,0.1l1.18,0.16l1.21,0.34c0.23,0.07,0.47,0.07,0.7,0l1.22-0.37c0.09-0.03,0.17-0.07,0.25-0.11l1.35-0.77
                  l0.22,0.27l0.26-0.22c-0.05,0.23-0.03,0.48,0.06,0.71c0.13,0.35,0.41,0.61,0.75,0.73l0.71,0.25l0.33,0.66
                  c0.08,0.15,0.18,0.28,0.31,0.39c-0.06,0.13-0.1,0.27-0.11,0.41l-0.13,1.32c-0.01,0.1-0.01,0.21,0,0.31l0.17,1.1l-0.07,1.11
                  c-0.02,0.34,0.1,0.67,0.33,0.92l0.83,0.91c0.29,0.32,0.72,0.47,1.15,0.39c0.42-0.08,0.77-0.37,0.93-0.77l0.48-1.2l0.51-1.12
                  c0.01-0.04,0.03-0.08,0.04-0.12l0.43-1.25l0.39-0.71l1.03-0.56l1.09-0.65c0.14-0.08,0.26-0.19,0.36-0.32l0.76-1.02
                  c0.12-0.17,0.2-0.36,0.24-0.56l0.21-1.34c0-0.05,0.01-0.11,0.01-0.16l0.02-1.04l0.12-0.3l0.51-0.07c0.32-0.06,0.61-0.23,0.81-0.49
                  l0.89-1.18c0.08-0.1,0.14-0.22,0.18-0.34l0.26-0.76l0.05,0.05c0.13,0.12,0.27,0.2,0.43,0.26l1.17,0.43c0.23,0.09,0.48,0.1,0.72,0.05
                  l1.06-0.26l0.75,0.11l0.85,0.5c0.18,0.11,0.38,0.17,0.59,0.18l1.23,0.05c0.2,0.01,0.38-0.03,0.55-0.11l0.86-0.37l1.35,0.33
                  c0.36,0.09,0.74,0.02,1.04-0.2l1.05-0.77l0.8-0.64l0.87,0.11c0.33,0.05,0.65-0.05,0.92-0.24l1.08-0.82c0.1-0.08,0.19-0.18,0.27-0.29
                  l0.62-0.89l0.53,0.13l1.34,0.51l1.16,0.66l0.18,0.73c0.1,0.42,0.41,0.75,0.82,0.89c0.4,0.13,0.85,0.05,1.18-0.22l1.05-0.86
                  c0.04-0.03,0.07-0.06,0.1-0.09l1.06-1.08l0.84-0.67l0.98-0.31c0.11-0.04,0.22-0.09,0.32-0.16l1.38-0.94
                  c0.22-0.14,0.39-0.36,0.48-0.6l0.37-1.04l0.68-0.9c0.18-0.23,0.26-0.52,0.25-0.82l-0.08-1.48c0-0.04-0.01-0.08-0.01-0.12l-0.18-1.18
                  l-0.03-0.39c0.14-0.01,0.27-0.04,0.39-0.09l1.26-0.51c0.53-0.21,0.84-0.76,0.77-1.32l-0.17-1.3l-0.03-0.51l0.46-0.29
                  c0.08-0.04,0.14-0.09,0.2-0.15l0.95-0.89c0.1-0.09,0.18-0.19,0.24-0.3l0.53-0.95l0.83-0.82c0.26-0.24,0.39-0.59,0.38-0.94
                  c-0.01-0.22-0.07-0.42-0.18-0.6l1.22-0.85l1.02-0.54c0.13-0.07,0.25-0.16,0.35-0.27l0.89-1c0.11-0.12,0.2-0.27,0.25-0.43l0.41-1.2
                  l0.61-0.84l0.86-0.73c0.06-0.05,0.12-0.11,0.17-0.17l1.07-1.34c0.02-0.03,0.04-0.05,0.06-0.08l0.3-0.45l0.6,0.13
                  c0.11,0.03,0.22,0.03,0.34,0.03l1.74-0.08l0.24,0.48c0.05,0.1,0.11,0.19,0.18,0.27l1.01,1.15c0.05,0.06,0.11,0.12,0.17,0.16
                  l0.81,0.64l0.28,0.83l-0.02,1.01c-0.01,0.37,0.15,0.72,0.43,0.96l0.85,0.75l1.33,2.08c0.08,0.12,0.17,0.22,0.28,0.31l0.97,0.77
                  l0.57,0.71l0.29,0.98c0.01,0.04,0.03,0.08,0.04,0.12l0.96,2.38c0.17,0.41,0.55,0.71,0.99,0.77l0.96,0.13l0.69,0.49l0.8,0.85
                  c0.13,0.15,0.3,0.26,0.49,0.33l1.45,0.51l1.05,0.58c0.14,0.07,0.28,0.12,0.43,0.14l0.68,0.09l0.36,0.56
                  c0.02,0.03,0.04,0.06,0.06,0.09l0.99,1.3l0.32,1l-0.04,1.44c0,0.16,0.02,0.32,0.07,0.46l0.48,1.32l0.15,1.18
                  c0.02,0.12,0.05,0.24,0.1,0.35l0.94,2.06l0.03,1.03c0,0.09,0.02,0.17,0.04,0.26l0.26,1.08l-0.19,1.04
                  c-0.04,0.22-0.02,0.45,0.05,0.65l0.43,1.19c0.02,0.04,0.03,0.07,0.04,0.1l0.5,1.07l0.37,1.1c0.04,0.14,0.11,0.27,0.21,0.39l0.8,0.99
                  c0.18,0.22,0.44,0.38,0.73,0.44l1.22,0.24l1,0.28l0.43,0.24l0.17,0.94c0.02,0.08,0.04,0.16,0.07,0.25l0.54,1.31l0.58,2.1l-0.22,0.51
                  l-0.76,0.76c-0.09,0.09-0.16,0.19-0.22,0.3l-0.59,1.13c-0.07,0.15-0.12,0.31-0.13,0.47l-0.12,1.34c-0.01,0.1-0.01,0.2,0,0.3
                  l0.14,0.9l-0.47,0.71c-0.08,0.11-0.13,0.24-0.17,0.37l-0.32,1.21c-0.03,0.14-0.05,0.28-0.04,0.42l0.1,1.24
                  c0.02,0.18,0.07,0.36,0.17,0.52l0.49,0.87l0.11,0.62l-0.49,0.77c-0.11,0.16-0.17,0.35-0.19,0.55l-0.08,0.79l-0.6,0.27l-1.09,0.16
                  c-0.14,0.03-0.28,0.07-0.4,0.14l-1.23,0.66l-1.17,0.71c-0.07,0.04-0.13,0.09-0.19,0.15l-1.08,0.99c-0.05,0.04-0.08,0.08-0.12,0.12
                  l-0.96,1.15c-0.18,0.21-0.28,0.47-0.29,0.75l-0.05,1.25l-0.2,0.82l-0.75,0.86c-0.05,0.07-0.1,0.14-0.15,0.22l-0.61,1.11
                  c-0.2,0.37-0.2,0.83,0.01,1.2l0.63,1.14c0.07,0.15,0.18,0.27,0.31,0.37l0.98,0.79c0.11,0.09,0.23,0.16,0.36,0.21l0.78,0.28
                  c-0.05,0.01-0.11,0.03-0.16,0.06l-1.16,0.51c-0.5,0.22-0.8,0.73-0.74,1.28l0.18,1.64c0.02,0.22,0.11,0.44,0.25,0.63l0.7,0.9
                  l0.35,1.05c0.04,0.11,0.1,0.22,0.17,0.32l0.72,1.03l0.47,1.06c0.02,0.04,0.04,0.08,0.06,0.12l0.63,1.08
                  c0.06,0.1,0.13,0.19,0.21,0.27l0.82,0.79l0.35,0.58l-0.12,0.35l-0.25,0.23l-0.65-0.08c-0.63-0.08-1.22,0.33-1.37,0.95l-0.39,1.69
                  l-0.13-0.02c-0.16-0.02-0.33-0.01-0.5,0.03l-1.55,0.39c-0.33,0.08-0.62,0.3-0.78,0.61l-1.37,2.41c-0.03,0.06-0.06,0.12-0.09,0.18
                  l-0.47,1.23c-0.11,0.31-0.11,0.65,0.02,0.95l0.48,1.11l0.18,1.02l-0.12,1.18c-0.01,0.09-0.01,0.18,0,0.26l0.15,1.39
                  c0.02,0.24,0.12,0.47,0.27,0.66l0.74,0.92l0.5,0.99c0.16,0.32,0.45,0.55,0.79,0.65l1.29,0.35c0.22,0.06,0.45,0.05,0.67-0.01
                  l2.38-0.67c0.38-0.11,0.69-0.39,0.83-0.77l0.47-1.27c0.09-0.23,0.1-0.48,0.05-0.71l-0.16-0.69l0.18-0.24l0.51-0.2l2.09,0.51
                  l1.86,0.98l1.45,1.93c0.24,0.32,0.62,0.52,1.03,0.49l2.55-0.04l0.13,0.03l-0.01,0.26c-0.02,0.42,0.18,0.83,0.52,1.08l0.95,0.67
                  l0.86,0.94c0.26,0.28,0.65,0.42,1.03,0.4c0.39-0.04,0.74-0.25,0.95-0.58l0.01-0.02l0.01,0.01c0.42,0.26,0.97,0.24,1.38-0.05
                  l0.55-0.4l0.54,0.12l1.09,0.58c0.28,0.14,0.6,0.18,0.9,0.1l1.31-0.35l0.99-0.18l1.31,0.29c0.05,0.01,0.09,0.02,0.14,0.02l1.22,0.13
                  c0.07,0.01,0.14,0.01,0.21,0.01l1.38-0.09l1.86-0.04c0.33-0.01,0.65-0.15,0.88-0.39s0.35-0.56,0.34-0.9l-0.04-1.31
                  c0-0.1-0.02-0.21-0.05-0.31l-0.27-0.94l0.3-0.77c0.01-0.03,0.02-0.06,0.03-0.1l0.28-0.95l0.59-0.63l0.53-0.3l0.55,0.15l0.95,0.56
                  c0.45,0.27,1.03,0.23,1.44-0.11l0.95-0.79c0.16-0.14,0.29-0.31,0.37-0.51l0.39-1.03l0.23-0.29l1.88,0.29
                  c0.34,0.06,0.7-0.03,0.97-0.25l0.61-0.48l0.81,0.07c0.3,0.02,0.6-0.06,0.84-0.24l1.81-1.3l1-0.14c0.1-0.02,0.2-0.05,0.3-0.08
                  l1.06-0.44l1.97-0.26c0.07-0.01,0.14-0.02,0.2-0.04l1.56-0.47c0.3-0.09,0.55-0.29,0.71-0.55l0.05-0.09l0.39,0.25
                  c0.07,0.04,0.14,0.08,0.21,0.1l1.15,0.45c0.33,0.13,0.7,0.12,1.01-0.04l1.24-0.61l1.1-0.31c0.15-0.04,0.29-0.11,0.41-0.2l0.94-0.71
                  l1.1-0.71c0.07-0.05,0.14-0.1,0.2-0.17l0.94-0.94c0.06-0.05,0.11-0.11,0.15-0.17l0.74-1.07l0.26-0.28l3.3,2.28l0.06,0.28l-1.29,1.19
                  c-0.31,0.29-0.46,0.73-0.38,1.15S578.11,564.27,578.51,564.43z"/>
                <title>Levice</title>
              </Link>

              {/* Nove zamky - nitriansky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Nové Zámky")}>
                <path style={{ fill: crimesInRegionNitriansky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionNitriansky[3].crimePercent)] ? crimesInRegionNitriansky[3].color : "#D3D3D3" }}
                  d="M523.39,704.09l-0.03,0.39l-0.56,0.63c-0.12,0.14-0.21,0.3-0.26,0.48l-0.16,0.56l-1.07,0.23l-2.97-0.67
                  c-0.12-0.03-0.25-0.04-0.38-0.03l-1.66,0.15l-1.22-0.11l-0.92-0.57c-0.12-0.08-0.25-0.13-0.38-0.16l-3.83-0.87
                  c-0.14-0.03-0.28-0.04-0.42-0.02l-3.83,0.43c-0.04,0-0.07,0.01-0.11,0.02l-1.62,0.33c-0.22,0.04-0.42,0.14-0.58,0.29l-1,0.88
                  l-0.83,0.32l-3.38,0.44c-0.07,0.01-0.14,0.02-0.21,0.04l-2.94,0.9c-0.15,0.05-0.29,0.13-0.42,0.23l-1.47,1.19
                  c-0.16,0.13-0.28,0.29-0.36,0.48l-1.02,2.37c-0.06,0.13-0.09,0.28-0.1,0.43l-0.13,2.93l-0.58,1.76l-2.07,1.96l-1.5,0.52
                  c-0.08,0.03-0.16,0.06-0.24,0.11l-1.47,0.89c-0.09,0.05-0.17,0.12-0.24,0.19l-1.57,1.59l-0.92,0.76l-3.09,2.32l-2.25,0.86
                  l-1.25,0.56l-2.65,0.99l-1.16,0.14c-0.14,0.01-0.26,0.05-0.38,0.1l-1.37,0.63l-2.23,0.01c-0.04,0-0.08,0-0.12,0.01l-3.09,0.31
                  c-0.03,0-0.07,0.01-0.1,0.01l-1.47,0.27l-2.21-0.11l-1.49-0.41l-1.3-0.27l-1.66-0.69c-0.06-0.02-0.12-0.04-0.19-0.06l-3.05-0.71
                  l-1.62-0.43c-0.05-0.01-0.09-0.02-0.14-0.03l-2.94-0.43c-0.13-0.02-0.27-0.01-0.4,0.01l-2.54,0.44l-2.68,0.02
                  c-0.15,0-0.29,0.03-0.43,0.08l-1.61,0.6c-0.12,0.04-0.23,0.11-0.33,0.19l-1.77,1.38l-2.59,1.09l-0.19-3.71l-0.34-2.87l-0.67-4.13
                  l-0.05-0.61l0.41,0.13l4.3,2.36c0.34,0.19,0.75,0.21,1.1,0.06c0.36-0.16,0.62-0.47,0.72-0.84l0.31-1.21
                  c0.06-0.23,0.05-0.47-0.02-0.69l-0.72-2.36c-0.01-0.04-0.03-0.08-0.04-0.12l-0.96-2.33l-0.09-1.24l-0.04-1.21l0.21-1.25l0.37-1.17
                  c0.12-0.38,0.05-0.8-0.18-1.12c-0.24-0.32-0.61-0.51-1.01-0.51c0,0,0,0-0.01,0l-2.33,0.01l-1.63,0.06l-1.54-0.5l-1.03-0.75
                  l-1.97-2.29l-1.41-1.53c-0.03-0.03-0.07-0.07-0.11-0.1l-0.98-0.85l-1.4-2.01l-0.76-1.02c-0.07-0.1-0.16-0.19-0.26-0.26l-1.04-0.76
                  c-0.06-0.04-0.13-0.08-0.19-0.11l-1.17-0.57c-0.13-0.07-0.27-0.11-0.42-0.12l-1.12-0.12l-0.99-0.29l-0.78-0.44l-1.75-1.73
                  c-0.04-0.05-0.09-0.09-0.15-0.13l-0.98-0.69l-5.65-4.95c-0.15-0.14-0.34-0.23-0.55-0.28l-1.22-0.27c-0.05-0.01-0.11-0.02-0.16-0.03
                  l-1.15-0.1l-9.94-2.38l-0.76-0.76l-0.9-0.79l-0.71-0.81l-0.42-0.83l-0.2-1.16l0.37-1.16c0.14-0.44,0.02-0.94-0.31-1.27l-0.96-0.95
                  l-1.45-1.68l-0.16-0.56l1.9-4.31l0.52-1.05l0.72-1.21c0.32-0.54,0.2-1.23-0.29-1.62l-0.94-0.74l-2.78-2.54l-0.71-0.94
                  c-0.05-0.06-0.1-0.12-0.15-0.17l-0.96-0.89l-2.11-1.8c-0.52-0.43-1.28-0.38-1.74,0.12l-0.41,0.45l-0.19-0.04l-0.81-0.7
                  c-0.07-0.07-0.15-0.12-0.24-0.17l-1.25-0.65l-6.77-4.64c-0.51-0.36-1.21-0.27-1.63,0.19c-0.42,0.47-0.43,1.17-0.02,1.65l0.81,0.95
                  c0.11,0.13,0.25,0.24,0.4,0.31l1.13,0.55l0.9,0.61l1.17,0.89l2.3,1.51l1.37,1.05c0.04,0.03,0.09,0.06,0.13,0.09l0.81,0.47l0.03,0.1
                  l-1.24,1.62l-0.57,0.62l-0.22,0.03l-1.48-1.42c-0.04-0.04-0.08-0.08-0.13-0.11l-2.31-1.73l-1.22-0.99l-1.03-0.73
                  c-0.18-0.12-0.38-0.19-0.59-0.21l-1.15-0.12l-0.86-0.53c-0.05-0.03-0.1-0.06-0.16-0.08l-1.06-0.45l-0.96-0.61l-2.01-1.55l-1.09-0.95
                  l-1.7-1.92c-0.07-0.08-0.15-0.14-0.23-0.2l-1.61-1.1c-0.1-0.07-0.2-0.12-0.31-0.16l-2.06-0.69c-0.1-0.03-0.2-0.05-0.3-0.06
                  l-1.37-0.11l-1.43-0.41c-0.33-0.1-0.69-0.05-0.98,0.12c-0.3,0.18-0.51,0.47-0.59,0.8l-0.35,1.54l-0.43,0.57l-0.2,0.03l-0.81-0.82
                  c-0.38-0.4-0.99-0.49-1.48-0.23l-4.26,2.23l-1.19,0.48l-1.5,0.51l-1.53,0.62c-0.07,0.03-0.15,0.07-0.21,0.11l-0.25,0.16l-1.14-1.15
                  l-1.36-1.19l-0.05-0.81c-0.01-0.08-0.02-0.16-0.04-0.23l-0.39-1.57l-0.49-1.7c-0.05-0.17-0.14-0.34-0.26-0.48l-0.97-1.1
                  c-0.14-0.15-0.31-0.27-0.5-0.34l-0.94-0.36l-1.05-1.04c-0.15-0.15-0.35-0.26-0.56-0.32l-1.19-0.32c-0.36-0.1-0.76-0.02-1.07,0.21
                  l-0.45,0.34l-0.43-0.26c-0.19-0.12-0.38-0.18-0.65-0.18h-0.37l-0.11-0.3c-0.21-0.63-0.88-0.99-1.52-0.8l-1.21,0.34
                  c-0.34,0.09-0.62,0.33-0.78,0.65l-0.63,1.28c-0.03,0.06-0.06,0.13-0.08,0.2l-0.16,0.57l-0.25-0.02c-0.47-0.03-0.91,0.2-1.15,0.6
                  l-0.36,0.59l-0.61,0.05c-0.42,0.04-0.8,0.29-1,0.67l-0.57,1.09c-0.05,0.11-0.09,0.22-0.12,0.34l-0.18,0.94l-1.21,1.62
                  c-0.15-0.12-0.32-0.2-0.51-0.25l-1.39-0.32c-0.52-0.12-1.06,0.11-1.34,0.56l-0.51,0.81c-0.11-0.08-0.23-0.14-0.35-0.18l-1.25-0.4
                  l-1.15-0.45c-0.51-0.2-1.09-0.05-1.43,0.37l-0.78,0.96c-0.12,0.15-0.2,0.31-0.24,0.49l-0.11,0.43l-2.86,0.21
                  c-0.05,0-0.09,0.01-0.14,0.02l-1.16,0.2l-1.19,0.08c-0.09,0.01-0.18,0.02-0.26,0.05l-1.11,0.32l-1.16,0.09
                  c-0.09,0.01-0.17,0.03-0.26,0.05l-0.33-1.1l-0.07-0.67l0.26-0.35l2.05-0.83c0.35-0.14,0.62-0.43,0.73-0.79l0.37-1.21
                  c0.09-0.31,0.06-0.65-0.09-0.94l-1.07-2.13l-0.2-1.14c-0.02-0.14-0.08-0.28-0.15-0.41l-0.54-0.92l-0.21-1.24
                  c-0.03-0.18-0.1-0.35-0.2-0.5l-1-1.45c-0.36-0.52-1.05-0.69-1.61-0.4l-1.11,0.58c-0.25,0.13-0.44,0.34-0.56,0.59l-0.42,0.93
                  l-0.78,0.75c-0.13,0.13-0.23,0.29-0.3,0.45l-0.56,0.01c-0.15,0-0.29,0.03-0.43,0.08l-1.11,0.43l-1.02,0.01
                  c-0.18,0-0.36,0.04-0.52,0.12l-1.25,0.59l-1.19,0.51l-0.04-0.34c-0.01-0.04-0.01-0.07-0.02-0.11l-0.17-0.78l0.36-0.69l0.91-0.92
                  c0.14-0.15,0.25-0.33,0.31-0.52l0.28-0.94l0.76-0.38c0.45-0.23,0.72-0.71,0.69-1.21l-0.09-1.24c-0.01-0.05-0.01-0.09-0.02-0.14
                  l-0.14-0.72l0.83-0.89c0.05-0.06,0.09-0.12,0.13-0.18l0.71-1.11c0.05-0.07,0.09-0.15,0.12-0.24l0.44-1.19
                  c0.14-0.38,0.09-0.8-0.14-1.13l0.59-0.25l1.53-0.36l1.22-0.17c0.27-0.04,0.51-0.16,0.7-0.34l0.9-0.87c0.13-0.13,0.24-0.29,0.3-0.47
                  l0.31-0.82l0.73-0.55c0.08-0.06,0.15-0.12,0.21-0.2l1.14-1.36c0.35-0.41,0.39-1.01,0.1-1.47l-0.75-1.18l-2.95-4.19l0.42-2.31
                  c0.07-0.37-0.04-0.75-0.29-1.04l-0.99-1.14l-1.54-1.95l-0.79-1.1c-0.03-0.04-0.06-0.07-0.09-0.1l-1.96-2.26l-0.4-1.22l-0.14-1.13
                  c-0.01-0.09-0.03-0.18-0.07-0.27l-0.61-1.69l-0.03-0.93l0.45-1.24c0.01-0.04,0.02-0.07,0.03-0.11l1.11-4.21l0.78-1.43l0.73-1.21
                  l1.78-3.31l1.24-2.71c0.05-0.11,0.08-0.23,0.1-0.35l0.16-1.22c0.05-0.38-0.08-0.76-0.35-1.04l-1.05-1.07
                  c-0.02-0.02-0.05-0.04-0.07-0.07l-0.93-0.8c-0.04-0.03-0.09-0.06-0.13-0.09l-1.37-0.91l-1-0.73c-0.03-0.02-0.06-0.04-0.1-0.06
                  l-1.18-0.71l-1.24-0.67c-0.32-0.17-0.7-0.2-1.04-0.06l-1.02,0.39l-0.54-0.21l-0.4-0.34l0.53-1.37l8.23-10.65l0.81,0.78l0.81,0.99
                  l0.86,0.97l0.9,1.11c0.26,0.32,0.67,0.49,1.1,0.45l1.07-0.11l0.45,0.28l0.6,1.04l1.39,2.2c0.26,0.41,0.73,0.63,1.2,0.58l1.56-0.18
                  c0.26-0.03,0.49-0.13,0.69-0.3l7.92-7.07l3.67-2.67l0.98-0.32c0.15-0.05,0.28-0.12,0.4-0.22l5.9-4.73l4.47-2.75
                  c0.46-0.28,0.69-0.83,0.57-1.36l-0.07-0.3l2.54-1.04l0.39-0.18l0.31,0.27c0.22,0.2,0.49,0.31,0.79,0.32l1.41,0.05
                  c0.19,0.01,0.38-0.03,0.56-0.11l3.81-1.73c0.07-0.04,0.14-0.08,0.21-0.12l1-0.72l1-0.65l0.53-0.24l0.11,0.88
                  c0.02,0.16,0.07,0.32,0.15,0.46l0.61,1.08c0.07,0.12,0.15,0.22,0.24,0.31c-0.04,0.05-0.08,0.1-0.11,0.15l-0.6,0.9l-0.71,0.59
                  l-0.98,0.53c-0.27,0.14-0.48,0.38-0.58,0.66l-0.43,1.15c-0.19,0.5-0.04,1.06,0.37,1.4l0.68,0.57l0.18,0.58l-0.12,0.97
                  c-0.01,0.09-0.01,0.19,0,0.28l0.12,1.24c0.05,0.56,0.48,1.02,1.04,1.11l1,0.17l0.82,0.54c0.22,0.15,0.49,0.22,0.75,0.21l1.23-0.06
                  c0.19-0.01,0.37-0.06,0.53-0.15l1.89-1.01l1.02-0.35l1.21-0.27c0.22-0.05,0.43-0.16,0.59-0.32l0.77-0.75l0.83-0.37l0.91,0.08
                  c0.15,0.01,0.3-0.01,0.44-0.05l1.32-0.38l1.2-0.45c0.12-0.04,0.24-0.11,0.34-0.19l1.02-0.81c0.1-0.08,0.18-0.17,0.26-0.28l0.69-1.02
                  c0.13-0.2,0.21-0.44,0.21-0.69l0.01-1.42v-0.11l-0.12-1.4l-0.04-1.31c-0.01-0.16-0.04-0.32-0.11-0.47l-0.51-1.14l-0.2-0.78
                  l0.23,0.19c0.03,0.03,0.07,0.06,0.11,0.08l0.93,0.62l0.75,0.81c0.08,0.08,0.16,0.15,0.25,0.21l1.05,0.66
                  c0.08,0.05,0.17,0.09,0.25,0.12l1.14,0.4l1.26,0.55c0.06,0.02,0.12,0.04,0.19,0.06l1.24,0.32c0.04,0.01,0.08,0.02,0.12,0.02
                  l1.49,0.24l2.25,0.16l0.07,0.06l-0.09,0.49c0,0.03-0.01,0.07-0.01,0.11l-0.1,0.95l-0.48,0.86c-0.22,0.38-0.21,0.86,0.01,1.25
                  c0.23,0.38,0.63,0.6,1.1,0.61l1.47-0.02l1.07,0.24l1.34,0.46c0.08,0.03,0.16,0.05,0.24,0.06l1.32,0.17
                  c0.13,0.02,0.26,0.01,0.39-0.01l1.15-0.22l0.89-0.13l0.82,0.65c0.12,0.09,0.24,0.16,0.37,0.2l1.16,0.4
                  c0.15,0.05,0.31,0.08,0.46,0.07l2.31-0.12l0.11,0.41l0.14,2.34c0.01,0.06,0.01,0.12,0.02,0.18l0.25,1.23
                  c0.04,0.16,0.1,0.31,0.19,0.44l0.75,1.13c0.09,0.14,0.2,0.25,0.34,0.34l1.1,0.75c0.03,0.02,0.06,0.04,0.09,0.06l1.07,0.6
                  c0.07,0.04,0.15,0.08,0.24,0.1l1.18,0.37c0.06,0.02,0.14,0.04,0.21,0.05l1.37,0.18c0.03,0,0.07,0.01,0.1,0.01l0.52,0.02l0.25,0.5
                  c0.07,0.15,0.18,0.29,0.31,0.4l0.64,0.55l0.06,0.79c0.02,0.17,0.07,0.34,0.16,0.5l0.62,1.11l0.55,1.18
                  c0.16,0.34,0.46,0.59,0.82,0.68l1.2,0.3l0.83,0.52l0.66,0.8c0.39,0.47,1.07,0.59,1.6,0.27l0.57-0.34l0.74,0.24
                  c0.16,0.05,0.33,0.07,0.5,0.05l1.03-0.09l0.97,0.25c0.05,0.01,0.1,0.02,0.15,0.03l1.18,0.16l1.17,0.22l1.02,0.36l1.01,0.43l0.7,0.62
                  l0.87,0.91c0.04,0.06,0.1,0.1,0.15,0.14l0.86,0.64l0.46,0.77l0.37,1.08l1.36,3.51l0.32,1.26c0.02,0.09,0.06,0.19,0.11,0.28l0.5,0.95
                  l0.13,0.57l-0.27,0.41l-0.88,0.62c-0.33,0.23-0.53,0.61-0.53,1.01l-0.01,1.28c0,0.05,0,0.09,0.01,0.13l0.11,1.17l-0.36,0.82
                  l-0.65,0.93c-0.05,0.08-0.1,0.17-0.14,0.26l-0.99-0.36c-0.14-0.05-0.28-0.08-0.42-0.08l-1.16-0.01l-0.99-0.11l-0.93-0.51
                  c-0.06-0.04-0.13-0.07-0.2-0.09l-1.26-0.43c-0.45-0.16-0.96-0.04-1.29,0.3c-0.34,0.35-0.45,0.86-0.29,1.31l0.82,2.34l0.33,1.22
                  l0.27,1.21c0.01,0.06,0.03,0.11,0.05,0.16l0.46,1.24l0.48,1.15c0.03,0.08,0.07,0.15,0.11,0.22l1.6,2.31
                  c0.11,0.15,0.25,0.27,0.41,0.36l1.19,0.67l0.44,0.47l0.11,0.94l0.23,1.19l0.05,1.08l-0.14,0.92l-0.48,0.8l-0.77,0.78
                  c-0.27,0.28-0.4,0.67-0.35,1.05l0.23,1.66c0.03,0.22,0.12,0.43,0.26,0.6l1.51,1.86l0.46,0.92l0.49,1.23
                  c0.1,0.25,0.28,0.46,0.51,0.61l1.14,0.7c0.06,0.04,0.13,0.07,0.21,0.1l1.11,0.42l0.98,0.63l0.25,0.21l-0.63,1.26l-0.77,0.9
                  l-0.86,0.77c-0.21,0.19-0.35,0.45-0.39,0.72l-0.22,1.31c-0.08,0.47,0.11,0.94,0.49,1.22l0.89,0.65l0.61,0.67l0.26,0.61l-0.16,0.66
                  l-0.28,0.59l-0.72,0.25c-0.3,0.1-0.54,0.3-0.69,0.57l-1.45,2.72c-0.09,0.17-0.14,0.36-0.14,0.55l-0.02,1.07l-0.3,1.08
                  c-0.07,0.24-0.07,0.49,0.01,0.72l0.47,1.45l0.29,2.55c0,0.08,0.02,0.15,0.04,0.23l0.4,1.36l0.47,1.37c0.04,0.12,0.1,0.24,0.19,0.35
                  l0.97,1.27l0.84,0.99l0.66,1.13c0.2,0.33,0.54,0.55,0.93,0.6c0.38,0.05,0.77-0.08,1.04-0.36l0.16-0.17l0.66,0.98
                  c0.07,0.1,0.16,0.2,0.26,0.28l2.06,1.59c0.33,0.25,0.76,0.32,1.16,0.18l0.86-0.3l2.28,0.42l1.19,0.29l2.62,1.09
                  c0.04,0.02,0.07,0.03,0.11,0.04l1.15,0.36l2.36,0.87l1.33,0.58c0.14,0.05,0.28,0.09,0.43,0.1l1.31,0.07h0.07
                  c0.44,0,0.84-0.23,1.07-0.61l1.27-2.12l0.86-0.94l0.87-0.84l0.35-0.18l0.32,0.29c0.19,0.19,0.44,0.3,0.71,0.33l1.48,0.17
                  c0.03,0.01,0.07,0.01,0.11,0.01l1.5,0.03l1.78,0.18l1.04,0.25l1.22,0.47l1.16,0.62l1.09,0.78c0.28,0.2,0.64,0.27,0.98,0.21
                  c0.34-0.07,0.63-0.28,0.81-0.58l0.59-0.95l0.85-0.8l0.92-0.66l0.86-0.53l0.9,0.01c0.36-0.01,0.71-0.15,0.96-0.42l0.93-1.05
                  c0.03-0.04,0.06-0.08,0.09-0.12l0.17-0.24l1.68,0.89l1.28,0.63c0.05,0.03,0.1,0.05,0.15,0.06l1.16,0.39l1.06,0.71l1.16,0.88
                  c0.2,0.15,0.44,0.23,0.69,0.25l1.15,0.06l1.42,0.18l1.29,0.1c0.32,0.03,0.63-0.07,0.87-0.27l1.13-0.89c0.04-0.03,0.08-0.06,0.12-0.1
                  l1.84-1.84c0.05-0.05,0.1-0.11,0.14-0.16l0.56-0.79l0.43-0.24l0.84,0.41c0.21,0.1,0.45,0.14,0.68,0.11l1.13-0.12l0.95,0.08
                  l1.23,0.62c0.07,0.03,0.14,0.06,0.21,0.08l1.2,0.35c0.48,0.14,0.99-0.02,1.31-0.4l0.39-0.47l0.29,0.05l0.89,0.66
                  c0.21,0.15,0.45,0.24,0.7,0.25l1.33,0.05h0.12l1.39-0.08c0.23-0.02,0.46-0.09,0.65-0.23l0.56-0.4l0.76,0.31
                  c0.24,0.1,0.5,0.12,0.74,0.06l1.46-0.33l1.26,0.02l1.07-0.01l0.88,0.44l0.54,0.33c-0.01,0.09,0,0.2,0.03,0.29l0.24,1.16l-0.12,1.16
                  c-0.02,0.15-0.01,0.31,0.03,0.45l0.15,0.6l-1.64,1.99l-0.69,0.92l-0.94,0.69c-0.22,0.16-0.38,0.38-0.46,0.64l-0.4,1.28
                  c-0.08,0.27-0.07,0.57,0.04,0.83l0.52,1.31c0.01,0.04,0.03,0.07,0.05,0.11l0.37,0.73l-0.18,0.76l-0.49,1.2
                  c-0.22,0.54-0.04,1.14,0.42,1.48l-0.77,1.13c-0.33,0.5-0.27,1.17,0.16,1.59l0.62,0.62l0.13,1.01l0.65,3.22l0.2,1.13l-0.19,1.5
                  l-0.13,1.62c-0.03,0.35,0.1,0.7,0.34,0.96s0.58,0.39,0.94,0.39h0.12l0.02,0.17c0.08,0.63,0.63,1.13,1.26,1.09l1.31-0.02
                  c0.06,0,0.13-0.01,0.2-0.02l0.45-0.08l0.27,0.37c0.18,0.26,0.46,0.44,0.77,0.51l2.25,0.43l0.26,0.64l0.32,1.22
                  c0.1,0.36,0.35,0.67,0.7,0.82l1.18,0.53c0.07,0.03,0.14,0.06,0.22,0.08l2.13,0.5c0.05,0.01,0.1,0.02,0.15,0.02l0.92,0.1l1.5,1.39
                  l0.09,0.84l0.04,1.81c0,0.24,0.08,0.47,0.21,0.67l0.8,1.19l0.63,0.8l0.18,0.93c0.07,0.37,0.31,0.68,0.63,0.86l1.17,0.63l0.9,0.71
                  c0.17,0.13,0.37,0.22,0.58,0.25l1.59,0.26c0.18,0.03,0.37,0.02,0.55-0.03l0.74-0.22L523.39,704.09z"/>
                <title>Nové Zámky</title>
              </Link>


              {/*  Komarno - nitriansky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Komárno")}>
                <path style={{ fill: crimesInRegionNitriansky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionNitriansky[0].crimePercent)] ? crimesInRegionNitriansky[0].color : "#D3D3D3" }}
                  d="M439.6,717.97l-2.54-1.38c-0.07-0.04-0.14-0.07-0.22-0.09l-1.31-0.41c-0.44-0.14-0.92-0.03-1.25,0.3l-0.88,0.87
                  c-0.26,0.26-0.4,0.62-0.37,0.99l0.11,1.34c0.01,0.04,0.01,0.08,0.02,0.12l0.66,4.11l0.33,2.72l0.19,3.88c0,0.04,0.01,0.08,0.01,0.12
                  l0.03,0.2l-1.03,0.32l-2.21,0.28l-1.11-0.2l-3.3-2.71c-0.03-0.02-0.07-0.05-0.1-0.07l-1.33-0.88c-0.12-0.08-0.24-0.13-0.37-0.17
                  l-1.62-0.43c-0.04-0.01-0.08-0.02-0.13-0.02l-1.77-0.28c-0.16-0.03-0.32-0.02-0.48,0.01l-1.32,0.31c-0.04,0.01-0.08,0.02-0.11,0.04
                  l-1.32,0.45c-0.11,0.03-0.21,0.08-0.3,0.15l-1.83,1.24l-1.26,1l-0.87,0.56l-2.65,0.29c-0.07,0.01-0.14,0.02-0.21,0.04l-2.56,0.74
                  l-1.88,0.28l-1.53,0.01c-0.14,0-0.27,0.02-0.4,0.07l-2.2,0.76c-0.07,0.02-0.14,0.05-0.2,0.09l-1.66,0.93l-1.29,0.79l-1.78,0.43
                  l-4.23,1.22c-0.04,0.01-0.09,0.02-0.13,0.04l-1.41,0.58l-1.26,0.43l-1.21,0.28l-1.61,0.31c-0.07,0.02-0.14,0.04-0.21,0.06
                  l-1.05,0.41l-1.82,0.29l-4.4,0.93l-1.44,0.28l-1.36-0.1l-1.67-0.27l-1.04-0.34l-1.02-0.75c-0.1-0.07-0.22-0.13-0.34-0.17l-2.95-1
                  c-0.06-0.02-0.11-0.04-0.17-0.05l-3.68-0.7c-0.05-0.01-0.1-0.01-0.15-0.02l-3.45-0.25l-2.3-0.41c-0.05-0.01-0.09-0.01-0.13-0.02
                  l-1.76-0.13c-0.11-0.01-0.22,0-0.33,0.02l-1.65,0.32l-1.99,0.46l-1.12,0.13l-5.7-0.49l-1.09-0.48l-1.29-0.69
                  c-0.05-0.03-0.11-0.05-0.16-0.07l-1.28-0.46l-2.01-0.56l-1.39-0.45l-2.65-0.71c-0.06-0.01-0.12-0.02-0.18-0.03l-5.35-0.66
                  l-2.87-0.53l-3.65-1.13c-0.1-0.03-0.21-0.05-0.32-0.05l-3.62-0.1l-1.76-0.13h-0.11l-3.16,0.04l-2.43-0.1l-1.32-0.13
                  c-0.05-0.01-0.09-0.01-0.14-0.01l-1.47,0.02c-0.1,0-0.2,0.02-0.3,0.04l-1.76,0.47c-0.13,0.04-0.26,0.09-0.37,0.16l-1.57,1.04
                  l-1.51,0.93l-1.25,0.86l-1.12,0.58l-1.06,0.02l-0.95-0.41l-3.34-2.55l-1.04-0.72c-0.11-0.08-0.24-0.13-0.37-0.17l-1.47-0.42
                  c-0.28-0.08-0.59-0.06-0.86,0.06l-2.18,1c-0.06,0.03-0.11,0.06-0.16,0.09l-1.03,0.67c-0.08,0.05-0.16,0.11-0.22,0.18l-1.05,1.1
                  l-1.22,1.4l-0.77,0.59l-2.05,0.15l-3.82,0.05c-0.05,0-0.09,0-0.14,0.01l-1.32,0.17c-0.07,0.01-0.14,0.03-0.21,0.05l-2.49,0.77
                  c-0.07,0.02-0.14,0.05-0.21,0.08l-1.03,0.54l-0.98,0.23l-0.95-0.08l-1.51-0.47l-4.09-1.63c-0.07-0.03-0.15-0.05-0.23-0.07
                  l-1.31-0.25c-0.12-0.02-0.25-0.03-0.38-0.01l-1.45,0.17c-0.07,0.01-0.15,0.02-0.22,0.04l-4.59,1.37l-1.61,0.23
                  c-0.04,0.01-0.07,0.02-0.11,0.02l-1.35,0.32l-1.02,0.02l-1.59-0.75c-0.03-0.02-0.07-0.03-0.1-0.04l-1.59-0.58
                  c-0.08-0.03-0.17-0.06-0.25-0.07l-1.23-0.18c-0.08-0.01-0.17-0.01-0.26-0.01l-1.35,0.08c-0.17,0.01-0.33,0.06-0.49,0.14l-1.37,0.69
                  l-1.07,0.63l-1.07,0.35l-1.01,0.05l-0.71-0.28l-1.8-2.19c-0.07-0.08-0.15-0.15-0.23-0.21l-1.14-0.82c-0.13-0.09-0.27-0.16-0.41-0.2
                  l-1.48-0.39c-0.1-0.02-0.2-0.04-0.29-0.04l-2.57-0.05l-1-0.17l-1.35-0.56l-1.45-0.48c-0.06-0.02-0.12-0.03-0.18-0.04l-1.49-0.26
                  c-0.08-0.02-0.16-0.02-0.24-0.02l-1.18,0.03l-2.08-0.41l-0.73-0.38l0.35-0.48l0.89-0.8l0.8-0.83l2.03-1.4
                  c0.14-0.1,0.26-0.23,0.35-0.38l1.33-2.15c0.18-0.3,0.24-0.65,0.15-0.99l-0.34-1.3c-0.02-0.09-0.06-0.17-0.1-0.25l-0.09-0.17
                  l0.09-0.05c0.34-0.19,0.57-0.53,0.63-0.92c0.03-0.23,0-0.47-0.1-0.68c0.04-0.03,0.08-0.07,0.12-0.11l0.85-1.02
                  c0.25-0.29,0.34-0.69,0.26-1.07s-0.34-0.7-0.69-0.86l-0.87-0.41l-0.26-0.31l0.13-0.77l0.38-1.03c0.06-0.15,0.08-0.31,0.08-0.47
                  l-0.01-0.47l0.47-0.27c0.1-0.05,0.19-0.11,0.27-0.19c0.32,0.33,0.79,0.46,1.24,0.33l0.7-0.2l0.7,0.35c0.22,0.11,0.47,0.15,0.72,0.12
                  l0.57-0.07l0.33,0.43c0.12,0.15,0.27,0.27,0.44,0.35l1.12,0.55c0.31,0.16,0.68,0.17,1,0.05c0.33-0.13,0.59-0.39,0.71-0.71l0.43-1.09
                  l0.77-0.95c0.11-0.14,0.19-0.3,0.24-0.47l0.34-1.29l0.8-0.73l0.95-0.56l3.72-1.3c0.24-0.08,0.45-0.23,0.61-0.44l0.73-0.99
                  c0.04-0.06,0.08-0.13,0.12-0.2l0.58-1.21l0.47-1.15c0.05-0.12,0.08-0.25,0.09-0.38l0.09-1.25v-0.16l-0.07-1.28
                  c-0.01-0.1-0.03-0.2-0.06-0.3l-0.37-1.19l-0.05-1.07c0-0.07-0.01-0.13-0.03-0.19l-0.47-2.35l-0.11-1.02l0.22-1l1.13-1.99l0.67-1.01
                  l0.74-0.9c0.21-0.25,0.31-0.58,0.27-0.91l-0.04-0.49l0.39-0.26l1.12-0.38l0.71-0.3l0.39,0.31c0.4,0.31,0.95,0.36,1.39,0.11l1.61-0.9
                  c0.13-0.07,0.25-0.17,0.35-0.29l0.87-1.04c0.05-0.06,0.1-0.14,0.14-0.21l1.43-2.69l1.1-1.89l0.49-0.99l0.79-0.61l1.59-0.6
                  c0.08-0.03,0.14-0.06,0.21-0.1l1.26-0.77c0.04-0.03,0.08-0.05,0.12-0.08l1.6-1.24l1.33-0.79c0.05-0.03,0.09-0.06,0.13-0.1l1.11-0.87
                  l2.02-1.78c0.1-0.08,0.18-0.18,0.25-0.29l0.75-1.24c0.22-0.36,0.24-0.8,0.06-1.18l-0.35-0.75l0.17-0.8c0.12-0.53-0.13-1.07-0.6-1.34
                  l-0.99-0.58l-0.74-0.7l-0.86-0.94c-0.06-0.07-0.12-0.13-0.2-0.18l-0.47-0.33l0.03-0.22l0.48-0.72l0.8-0.92
                  c0.03-0.03,0.06-0.07,0.09-0.11l0.76-1.1l0.66-1.05c0.1-0.15,0.16-0.33,0.18-0.52l0.13-1.19l0.21-1.29
                  c0.02-0.08,0.02-0.16,0.02-0.24l-0.04-1.25c0-0.06-0.01-0.12-0.02-0.17l-0.21-1.21c-0.01-0.09-0.04-0.18-0.07-0.26l-0.47-1.16
                  c-0.09-0.22-0.25-0.42-0.45-0.56l-1.09-0.75l-0.5-0.33l-0.05-0.61l0.12-1.2c0.01-0.06,0.01-0.12,0.01-0.18l-0.06-2.4l0.59-2.34
                  l0.19-0.62l0.08,0.05c0.04,0.02,0.08,0.04,0.11,0.06l1.03,0.55l1,0.67c0.22,0.15,0.48,0.23,0.74,0.22l0.68-0.02l0.49,0.51
                  c0.3,0.31,0.73,0.44,1.15,0.35l1.26-0.26c0.06-0.01,0.12-0.03,0.18-0.05l1.04-0.38l1.08-0.06l0.85,0.07l2.87,2.29
                  c0.12,0.1,0.26,0.18,0.41,0.23l1.08,0.32l1.01,0.52c0.03,0.02,0.06,0.03,0.09,0.04l6.53,2.91c0.28,0.13,0.59,0.14,0.88,0.05
                  l1.26-0.39c0.24-0.07,0.46-0.22,0.61-0.42l0.61-0.77l2.08-0.93l0.97-0.01c0.15,0,0.3-0.03,0.44-0.08l1.11-0.43l0.98-0.02l1.2,0.15
                  c0.52,0.07,1.02-0.2,1.26-0.66c0.24-0.47,0.17-1.03-0.18-1.42l-0.05-0.05l0.08-0.08c0.11-0.11,0.2-0.24,0.27-0.38l0.2-0.45l0.1,0.14
                  l0.21,1.19c0.02,0.15,0.07,0.29,0.15,0.42l0.54,0.93l0.19,1.09c0.02,0.11,0.06,0.23,0.11,0.33l0.94,1.88l-0.05,0.16l-1.82,0.73
                  c-0.22,0.09-0.41,0.23-0.55,0.42l-0.76,1.03c-0.18,0.25-0.27,0.56-0.23,0.88l0.14,1.27c0.01,0.07,0.02,0.15,0.04,0.22l0.76,2.55
                  c0.1,0.35,0.35,0.63,0.67,0.78c0.33,0.15,0.71,0.16,1.03,0.01l0.97-0.42l1.08-0.08c0.08-0.01,0.17-0.03,0.25-0.05l1.1-0.32
                  l1.12-0.08c0.04-0.01,0.08-0.01,0.12-0.02l1.15-0.2l2.47-0.14c0.04,0,0.09-0.01,0.13-0.02l1.28-0.22c0.49-0.08,0.89-0.45,1-0.93
                  l0.24-0.95l0.03-0.04l1.35,0.47l0.71,0.81c0.26,0.3,0.65,0.46,1.04,0.43c0.4-0.04,0.76-0.26,0.97-0.6l0.9-1.46l0.79,1.13
                  c0.27,0.39,0.74,0.6,1.21,0.54c0.47-0.07,0.87-0.4,1.02-0.85l0.36-1.1l1.58-2.12c0.12-0.15,0.2-0.33,0.24-0.52l0.2-1.05l0.17-0.33
                  l0.56-0.05c0.4-0.03,0.75-0.25,0.96-0.59l0.29-0.48l0.5,0.03c0.61,0.04,1.12-0.34,1.28-0.9l0.41-1.43l0.07-0.14
                  c0.24,0.3,0.6,0.48,0.99,0.48h0.01l0.93-0.01l0.85,0.51c0.43,0.26,0.98,0.23,1.39-0.07l0.63-0.48l0.29,0.08l1,1
                  c0.13,0.12,0.27,0.22,0.44,0.28l0.9,0.34l0.58,0.66l0.4,1.38l0.35,1.41l0.07,1.22c0.02,0.34,0.18,0.65,0.43,0.87l1.69,1.47
                  l1.79,1.82c0.42,0.42,1.08,0.49,1.57,0.17l1-0.66l1.36-0.55l1.49-0.51l1.32-0.53c0.04-0.02,0.08-0.04,0.12-0.06l3.49-1.81l0.59,0.59
                  c0.27,0.29,0.67,0.42,1.06,0.37l1.34-0.18c0.33-0.05,0.63-0.22,0.83-0.49l0.91-1.2c0.1-0.14,0.18-0.3,0.22-0.47l0.12-0.51l0.27,0.08
                  c0.08,0.02,0.17,0.04,0.25,0.05l1.35,0.1l1.75,0.59l1.34,0.91l1.66,1.87c0.03,0.04,0.07,0.08,0.11,0.11l1.2,1.04l2.13,1.66
                  c0.03,0.02,0.06,0.04,0.09,0.06l1.09,0.69c0.06,0.03,0.12,0.06,0.18,0.09l1.07,0.45l1.02,0.63c0.16,0.09,0.34,0.15,0.52,0.17
                  l1.11,0.12l0.69,0.47l1.19,0.99l2.28,1.7l1.92,1.82c0.28,0.25,0.65,0.37,1.02,0.32l1.25-0.17c0.29-0.04,0.56-0.18,0.75-0.4l0.91-1
                  c0.03-0.03,0.05-0.06,0.08-0.09l1.63-2.11c0.14-0.19,0.23-0.42,0.25-0.65c0.25-0.06,0.47-0.19,0.65-0.38l0.07-0.08l1.13,0.98
                  l0.85,0.78l0.71,0.95c0.05,0.06,0.1,0.12,0.16,0.18l2.92,2.65l0.16,0.13l-0.8,1.53l-2.11,4.77c-0.12,0.28-0.14,0.58-0.06,0.87
                  l0.37,1.27c0.05,0.18,0.14,0.34,0.26,0.48l1.63,1.88c0.03,0.03,0.05,0.06,0.08,0.09l0.45,0.45l-0.23,0.71
                  c-0.06,0.2-0.08,0.41-0.04,0.61l0.29,1.65c0.02,0.12,0.06,0.24,0.12,0.35l0.58,1.14c0.04,0.09,0.1,0.18,0.17,0.25l0.86,0.98
                  c0.04,0.04,0.07,0.08,0.11,0.12l0.91,0.79l0.96,0.96c0.16,0.17,0.37,0.28,0.6,0.34l10.39,2.48c0.05,0.01,0.1,0.02,0.16,0.03
                  l1.15,0.1l0.83,0.18l5.46,4.78c0.03,0.03,0.06,0.06,0.1,0.08l0.95,0.67l1.76,1.76c0.08,0.09,0.18,0.16,0.29,0.22l1.07,0.59
                  c0.08,0.05,0.16,0.08,0.25,0.11l1.23,0.36c0.07,0.02,0.14,0.03,0.22,0.04l1.01,0.11l0.87,0.42l0.8,0.59l2.08,2.94
                  c0.06,0.09,0.14,0.17,0.22,0.24l1.05,0.9l1.32,1.43l1.19,1.42l0.89,0.99c0.06,0.06,0.12,0.12,0.19,0.17l1.29,0.95
                  c0.11,0.08,0.23,0.14,0.36,0.18l1.95,0.63c0.13,0.04,0.28,0.07,0.43,0.06l1.81-0.07h0.61l-0.2,1.26c-0.02,0.08-0.02,0.16-0.02,0.24
                  l0.04,1.38l0.11,1.49c0.01,0.14,0.04,0.27,0.1,0.39l1,2.44L439.6,717.97z"/>
                <title>Komárno</title>
              </Link>


              {/* Trnava - trnavsky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Trnava")}>
                <path style={{ fill: crimesInRegionTrnavsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionTrnavsky[4].crimePercent)] ? crimesInRegionTrnavsky[4].color : "#D3D3D3" }}
                  d="M237.86,504.2l-0.27,0.79c-0.01,0.05-0.03,0.1-0.04,0.15l-0.21,1.08l-0.8,0.94c-0.19,0.22-0.3,0.5-0.3,0.79
                  l-0.05,3.52l-0.02-0.02c-0.08-0.08-0.18-0.15-0.28-0.2l-3.2-1.64c-0.34-0.17-0.73-0.17-1.07-0.02c-0.34,0.15-0.6,0.45-0.7,0.81
                  l-0.12,0.44l-1.31,0.27l-1.43,0.23c-0.12,0.01-0.24,0.05-0.35,0.11l-1.47,0.71l-1.01,0.08c-0.22,0.02-0.43,0.1-0.61,0.22l-1.37,0.95
                  c-0.07,0.05-0.13,0.1-0.19,0.16l-1.1,1.14c-0.07,0.07-0.13,0.15-0.18,0.24l-0.84,1.43l-0.78,0.75c-0.26,0.26-0.4,0.62-0.38,0.99
                  c0.03,0.37,0.22,0.71,0.52,0.92l2.36,1.7l-0.69,0.76l-0.92,0.91c-0.09,0.09-0.16,0.19-0.22,0.29l-0.59,1.09
                  c-0.18,0.33-0.2,0.72-0.06,1.06l-0.39,0.16c-0.05,0.02-0.09,0.04-0.14,0.06l-1.25,0.68l-1.46,0.15l-1.39,0.25l-3,0.3
                  c-0.68,0.06-1.18,0.67-1.11,1.36l0.12,1.25c0.01,0.11,0.03,0.21,0.07,0.32l0.16,0.43l-0.33,0.33c-0.09,0.09-0.17,0.2-0.22,0.31
                  l-0.16-0.06c-0.15-0.05-0.32-0.08-0.48-0.08l-2.82,0.13l-1.74,0.2l-1.53,0.06l-1.38,0.14l-2.17-0.5c-0.21-0.05-0.43-0.04-0.64,0.02
                  l-1.99,0.6c-0.14,0.05-0.27,0.11-0.39,0.2l-1.11,0.83c-0.13,0.1-0.25,0.23-0.33,0.37l-1.47,2.52l-0.77,1.55l-1.11,1.28l-1.02,0.29
                  c-0.17,0.05-0.33,0.13-0.47,0.25l-1.75,1.49l-1.38,1.38l-0.23-0.01l-0.42-0.63l-1.89-2.44l-0.72-1.13l-1.69-2.24l-0.86-1.38
                  c-0.03-0.05-0.07-0.1-0.11-0.15l-2.27-2.72c-0.04-0.04-0.07-0.08-0.11-0.12l-2.49-2.32l-1.06-1.07l-0.09,0.08l-1.8-2.23l-0.65-1.04
                  c-0.06-0.1-0.14-0.2-0.23-0.28l-0.94-0.83c-0.47-0.41-1.17-0.41-1.64-0.01l-0.17,0.14l-0.03-0.04c-0.11-0.11-0.23-0.21-0.37-0.28
                  l-1.03-0.51l-0.82-0.64l-0.85-0.84c-0.05-0.05-0.1-0.1-0.16-0.14l-1-0.71l-0.73-0.62l-0.42-0.82l-0.99-2.38
                  c-0.23-0.56-0.83-0.88-1.43-0.75l-0.36,0.08c0.13-0.44,0.01-0.92-0.32-1.25l-0.95-0.94c-0.04-0.03-0.08-0.07-0.12-0.1l-0.9-0.7
                  l-0.17-0.2l0.64-1.15l0.65-0.12c0.29-0.06,0.55-0.21,0.74-0.44l0.88-1.08c0.11-0.14,0.19-0.3,0.24-0.47l0.36-1.36l0.42-1.02
                  l0.66-0.88l0.81-0.83c0.16-0.17,0.28-0.39,0.32-0.62l0.26-1.26c0.02-0.07,0.03-0.14,0.03-0.22l0.03-1.36l0.43-0.95
                  c0.08-0.17,0.12-0.36,0.11-0.55l-0.04-1.24c-0.01-0.35-0.17-0.68-0.44-0.91l-1.61-1.35l-0.16-0.84c-0.04-0.2-0.13-0.39-0.26-0.55
                  l-0.73-0.89l-0.76-1.07l-1.52-1.91l-0.65-0.97c-0.29-0.44-0.83-0.65-1.35-0.52l-1.08,0.27l-1.08,0.02c-0.27,0.01-0.53,0.1-0.75,0.26
                  l-0.67,0.53l-0.56,0.01l-0.71-0.39l-0.52-0.46l-0.02-0.11l0.35-0.27c0.43-0.32,0.6-0.88,0.44-1.38l-0.4-1.24
                  c-0.09-0.27-0.25-0.49-0.48-0.65l-0.8-0.55l-0.13-0.21l0.27-0.51c0.21-0.4,0.18-0.9-0.07-1.28l-1.22-1.86l-0.21-0.99
                  c-0.02-0.1-0.05-0.2-0.09-0.29l-0.55-1.15c-0.01-0.02-0.02-0.04-0.03-0.06l-0.2-1.14c-0.07-0.4-0.32-0.74-0.68-0.91l-1.2-0.59
                  c-0.13-0.06-0.26-0.1-0.4-0.12l-0.54-0.07l0.14-0.19c0.22-0.29,0.31-0.66,0.23-1.02l-0.28-1.32c-0.05-0.25-0.17-0.47-0.36-0.64
                  l-0.9-0.86c-0.19-0.19-0.45-0.31-0.72-0.34l-1.22-0.14c-0.03-0.01-0.07-0.01-0.11-0.01l-0.82-0.02l-0.41-0.36l-0.41-0.83l-0.26-0.67
                  l0.1-0.22l0.75-0.34c0.03-0.02,0.07-0.04,0.1-0.06l1.1-0.62c0.51-0.29,0.74-0.89,0.58-1.45l-0.36-1.2
                  c-0.03-0.09-0.07-0.17-0.11-0.25l-0.62-1.1c-0.21-0.38-0.61-0.62-1.04-0.64l-2.6-0.06h-1.33l-2.23,0.1l-0.6-0.25l-0.47-0.78
                  c-0.06-0.11-0.14-0.21-0.24-0.29l-1-0.9l-0.96-0.79l-1.78-1.76c-0.24-0.25-0.55-0.38-0.92-0.38l-5.69,0.12l-0.25-0.45
                  c-0.05-0.09-0.11-0.17-0.17-0.24l-2.79-3l-0.3-0.5l0.28-0.61l0.74-0.94l1.62-2.34c0.16-0.25,0.23-0.54,0.2-0.83l-0.02-0.22
                  l1.08,0.09l1.14,0.23c0.63,0.14,1.26-0.24,1.45-0.86l0.03-0.12l0.2,0.05c0.44,0.13,0.9,0,1.22-0.32s0.44-0.79,0.31-1.23l-0.15-0.53
                  l0.37-0.36c0.15-0.13,0.26-0.29,0.32-0.48l0.44-1.19c0.07-0.17,0.09-0.36,0.07-0.55l-0.12-1.3c-0.01-0.16-0.06-0.31-0.13-0.45
                  l-0.02-0.05l0.07-0.03c0.06-0.02,0.11-0.06,0.17-0.09l1.15-0.74l1.24-0.89c0.08-0.06,0.15-0.13,0.22-0.2l0.81-0.94
                  c0.15-0.19,0.25-0.39,0.28-0.62l0.17,0.15l0.82-0.95c0.07-0.09,0.13-0.19,0.18-0.29l0.52-1.12c0.19-0.4,0.15-0.87-0.09-1.23
                  c-0.25-0.36-0.67-0.56-1.11-0.55l-1.14,0.06l-0.3-0.05c0.01-0.01,0.01-0.02,0.02-0.04l0.74-1.23c0.15-0.24,0.21-0.54,0.17-0.83
                  l-0.18-1.18l0.36-0.9c0.04-0.1,0.06-0.19,0.08-0.29l0.18-1.26c0.01-0.11,0.01-0.22,0-0.33l-0.11-0.88l0.93-1.95l0.54-1.01l0.63-0.98
                  l0.86-0.82l0.84-0.93c0.04-0.05,0.08-0.1,0.11-0.15l0.56-0.85l0.8-0.6l1.07-0.42c0.54-0.21,0.87-0.78,0.77-1.35l-0.07-0.47l0.5-0.31
                  c0.05-0.04,0.11-0.08,0.16-0.13l0.55-0.49l0.63,0.04l1.04,0.34c0.19,0.06,0.38,0.07,0.57,0.05l0.8-0.12l0.8,0.46
                  c0.09,0.05,0.18,0.09,0.28,0.11l1.13,0.32l1.05,0.46c0.2,0.08,0.41,0.12,0.62,0.09l0.97-0.09l0.91,0.32
                  c0.43,0.15,0.91,0.05,1.25-0.26l0.76-0.69l1.02-0.45c0.14-0.06,0.28-0.16,0.39-0.27l0.76-0.79l0.84-0.53l0.72-0.38l0.34,0.08
                  l0.35,0.57l0.54,1.1c0.17,0.35,0.5,0.61,0.89,0.68s0.79-0.05,1.08-0.32l0.9-0.84c0.07-0.05,0.12-0.12,0.17-0.19l0.74-1.05l0.71-1.11
                  l0.7-0.8l0.87-0.88c0.28-0.29,0.41-0.69,0.34-1.08l-0.17-1.07l0.08-1.13c0-0.08,0-0.15-0.01-0.22l-0.02-0.23l0.92,0.14
                  c0.31,0.06,0.62-0.01,0.88-0.18l1.03-0.67c0.23-0.15,0.4-0.36,0.49-0.61l0.38-1.02l0.15-0.21l0.45,0.09
                  c0.36,0.06,0.73-0.03,1.02-0.25l1.01-0.81c0.23-0.19,0.39-0.45,0.45-0.74l0.26-1.34c0.02-0.12,0.03-0.24,0.01-0.36l-0.08-0.82
                  l1.25-1.68c0.08-0.11,0.15-0.22,0.19-0.35l0.39-1.11L176,398l1.56-2.14c0.04-0.04,0.06-0.08,0.09-0.13l0.64-1.12l0.73-0.85
                  c0.04-0.04,0.07-0.08,0.1-0.13l0.35-0.52l0.91-0.03c0.29-0.01,0.57-0.12,0.79-0.31l0.28-0.25h0.01c0.23-0.09,0.44-0.24,0.59-0.45
                  l0.34-0.46l0.58,0.05c0.58,0.04,1.11-0.31,1.29-0.85l0.38-1.12l0.47-1.15c0.04-0.11,0.07-0.22,0.08-0.34l0.03-0.31l0.32-0.05
                  c0.09-0.01,0.17-0.03,0.26-0.07l1.3-0.51c0.1-0.04,0.2-0.09,0.28-0.16l1.19-0.88c0.11-0.07,0.2-0.17,0.28-0.28l0.03-0.04l0.03,0.02
                  c0.08,0.05,0.16,0.1,0.25,0.13l1.2,0.45l0.63,0.73c0.1,0.11,0.21,0.21,0.34,0.27l0.19,0.8l-0.05,0.87l-0.46,1.09
                  c-0.05,0.13-0.09,0.26-0.1,0.4l-0.09,1.28c-0.01,0.21,0.03,0.42,0.11,0.61l0.53,1.15c0.1,0.2,0.24,0.37,0.42,0.5l0.27,0.2
                  l-0.65,0.97l-1.39,1.19c-0.15,0.13-0.26,0.29-0.34,0.48l-0.55,1.35c-0.18,0.46-0.08,0.98,0.26,1.34l1.08,1.12l0.22,1.53l0.04,0.39
                  h-1.19c-0.31,0-0.6,0.11-0.83,0.32l-1.09,0.97c-0.11,0.09-0.2,0.21-0.27,0.34l-0.61,1.15c-0.06,0.11-0.1,0.22-0.12,0.33l-0.26,1.21
                  l-0.44,1.13c-0.08,0.21-0.1,0.44-0.06,0.66l0.2,1.17l-0.06,1.11c-0.01,0.32,0.09,0.63,0.3,0.87l0.81,0.95
                  c0.32,0.37,0.82,0.52,1.29,0.39l1.21-0.34c0.03-0.01,0.06-0.02,0.09-0.03l0.95-0.35l0.7,0.03l0.43,0.23l0.2,0.77
                  c0.1,0.34,0.33,0.62,0.64,0.78l1.12,0.57c0.05,0.03,0.09,0.04,0.13,0.06l0.73,0.27l0.43,0.77l0.53,1.23
                  c0.03,0.06,0.06,0.11,0.09,0.17l0.68,1.07l0.38,1.07c0.07,0.18,0.17,0.34,0.31,0.47l0.89,0.87l2.06,2.66l0.01,0.84
                  c0,0.26,0.09,0.52,0.25,0.73l0.75,1c0.15,0.2,0.36,0.35,0.6,0.43l1.41,0.48l-0.04,0.24c-0.05,0.35,0.05,0.7,0.26,0.97
                  c0.22,0.27,0.54,0.44,0.89,0.47l0.76,0.05l0.35,0.63c0.07,0.13,0.16,0.24,0.27,0.34l0.46,0.39l-0.18,0.38
                  c-0.11,0.23-0.15,0.49-0.1,0.75l0.22,1.3c0.03,0.18,0.09,0.34,0.2,0.49l0.76,1.12c0.03,0.06,0.07,0.1,0.11,0.15l0.97,1.04l1.21,1.14
                  c0.05,0.05,0.11,0.09,0.16,0.13l1.74,1.17c0.06,0.04,0.12,0.07,0.19,0.1l1.15,0.52c0.5,0.23,1.09,0.09,1.46-0.32l0.89,1.86
                  c0.02,0.03,0.04,0.06,0.05,0.09l0.71,1.14c0.01,0.03,0.03,0.06,0.05,0.09l0.76,1.03c0.03,0.04,0.06,0.08,0.1,0.11l0.83,0.89
                  l0.63,0.82l0.96-0.75c-0.01,0.03-0.02,0.07-0.03,0.1l-0.25,1.25c-0.07,0.37,0.03,0.75,0.27,1.04l0.75,0.89l0.57,1.14l0.26,0.45
                  l-0.26,0.38l-0.8,0.83c-0.24,0.25-0.37,0.59-0.35,0.93l0.06,1.14l-0.37,0.98c-0.12,0.31-0.11,0.65,0.03,0.95l0.61,1.36l0.49,1.19
                  l0.28,0.99l-0.02,1.12v0.1l0.08,1.22l-0.37,0.91c-0.05,0.11-0.08,0.22-0.09,0.34l-0.1,0.88l-0.57,0.64
                  c-0.33,0.36-0.41,0.89-0.21,1.34l1.11,2.59l0.2,1.23c0.03,0.21,0.12,0.41,0.25,0.58l0.82,1.02l0.83,0.93
                  c0.14,0.16,0.33,0.29,0.53,0.36l1.19,0.39c0.13,0.05,0.29,0.08,0.41,0.07l1.41-0.01c0.08,0,0.16-0.01,0.24-0.02l1.32-0.27l1.6,0.36
                  l0.6,0.1l0.12,0.43c0.02,0.08,0.04,0.14,0.07,0.21l0.57,1.19c0.09,0.17,0.21,0.32,0.36,0.44l-0.73,0.91
                  c-0.13,0.17-0.22,0.37-0.25,0.58l-0.13,0.8l-0.59,0.55c-0.23,0.21-0.37,0.5-0.4,0.81l-0.12,1.48c-0.01,0.13,0,0.27,0.04,0.4
                  l0.31,1.26c0.01,0.06,0.03,0.11,0.05,0.17l0.33,0.8l-0.5,1.64l-0.26,1.13l-0.41,1.32c-0.06,0.19-0.08,0.39-0.04,0.58l0.23,1.33
                  l0.29,1.34c0.05,0.22,0.15,0.42,0.3,0.59l0.47,0.51l-0.01,0.16l-2.22,1.99l-0.76-0.02c-0.1,0-0.22,0.01-0.33,0.04l-1.29,0.32
                  c-0.52,0.13-0.9,0.57-0.95,1.11l-0.1,1.25v0.11l0.01,1.29c0,0.08,0.01,0.16,0.02,0.23l0.24,1.21c0.07,0.33,0.26,0.61,0.53,0.8
                  l1.03,0.69c0.15,0.1,0.32,0.16,0.5,0.19l1,0.17l0.93,0.61c0.05,0.03,0.1,0.06,0.15,0.09l1.19,0.57c0.09,0.04,0.18,0.07,0.27,0.09
                  l1.3,0.29c0.04,0.01,0.09,0.02,0.13,0.02l1.02,0.12l0.76,0.52c0.03,0.02,0.06,0.04,0.09,0.06l1.01,0.56l3.13,2.08
                  c0.22,0.15,0.5,0.23,0.77,0.21L237.86,504.2z"/>
                <title>Trnava</title>
              </Link>


              {/* Hlohovec - trnavsky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Hlohovec")}>
                <path
                  d="M290.88,455.02c-0.06,0.02-0.12,0.03-0.18,0.05c-0.33,0.12-0.59,0.37-0.73,0.69l-0.98,2.21l-0.64,0.95
                  l-0.81,0.9c-0.09,0.11-0.17,0.23-0.22,0.36l-0.58,1.39c-0.08,0.18-0.11,0.38-0.1,0.58l0.05,0.55l-0.12,0.08l-0.88-0.04
                  c-0.29-0.01-0.57,0.07-0.8,0.24l-1,0.74c-0.39,0.29-0.58,0.77-0.49,1.24l0.16,0.87l-0.25,0.61l-0.6,0.63l-0.94,0.57
                  c-0.14,0.09-0.26,0.2-0.36,0.33l-0.8,1.09c-0.15,0.21-0.23,0.46-0.24,0.71l-0.02,0.98l-0.52,0.79c-0.07,0.1-0.12,0.21-0.16,0.33
                  l-0.32,1.1l-0.4,1.08l-1.27,2.04c-0.05,0.09-0.1,0.19-0.13,0.3l-0.77,2.6c-0.01,0.04-0.02,0.09-0.03,0.14l-0.17,1.12l-0.54,0.94
                  l-0.65,0.98l-0.8,0.92c-0.05,0.05-0.09,0.11-0.13,0.17l-0.62,1.02l-0.71,0.9c-0.1,0.12-0.17,0.25-0.22,0.4l-0.33,1.02l-0.56,0.86
                  l-0.86,1.01c-0.02,0.04-0.05,0.07-0.07,0.1l-0.74,1.07c-0.06,0.09-0.11,0.2-0.15,0.3l-0.42,1.22c-0.03,0.1-0.06,0.2-0.06,0.3
                  l-0.08,0.79l-0.47,0.5l-0.97,0.6c-0.31,0.19-0.53,0.52-0.58,0.88l-0.18,1.27c-0.01,0.07-0.01,0.13-0.01,0.2l0.01,0.45l-0.37,0.15
                  c-0.06,0.02-0.12,0.05-0.18,0.09l-1.12,0.67c-0.08,0.05-0.16,0.1-0.22,0.17l-0.91,0.87c-0.14,0.13-0.25,0.3-0.32,0.48l-0.43,1.21
                  c-0.07,0.21-0.09,0.44-0.04,0.65l-0.31,0.2l-1.03,0.43c-0.04,0.01-0.08,0.03-0.11,0.05c-0.12-0.26-0.32-0.47-0.57-0.61l-1.07-0.56
                  l-0.69-0.68l-0.66-0.93c-0.09-0.12-0.2-0.23-0.33-0.32l-1.11-0.72c-0.22-0.14-0.47-0.22-0.72-0.21l-1.29,0.03
                  c-0.37,0.01-0.73,0.18-0.96,0.48l-0.18,0.24l-0.27-0.11c-0.31-0.13-0.67-0.13-0.98,0l-1.22,0.53c-0.08,0.04-0.16,0.08-0.23,0.14
                  l-1,0.72c-0.13,0.09-0.24,0.21-0.33,0.34l-0.68,1.08c-0.2,0.32-0.24,0.72-0.12,1.07l0.87,2.53c0.02,0.05,0.05,0.11,0.07,0.16l0.53,1
                  l0.17,1.04c0.02,0.12,0.05,0.23,0.1,0.34l0.27,0.55l-0.14,0.2l-0.78,0.39l-1.15,0.27c-0.09,0.02-0.17,0.05-0.25,0.09l-1.15,0.55
                  c-0.03,0.02-0.07,0.04-0.1,0.06l-0.65,0.39l-0.44-0.16l-1.62-1.66c-0.14-0.14-0.32-0.25-0.51-0.31l-0.63-0.2l-0.18-0.57
                  c-0.04-0.14-0.11-0.28-0.2-0.4l-0.79-1l-0.73-1.11c-0.13-0.2-0.32-0.36-0.54-0.46l-1.2-0.52c-0.04-0.01-0.09-0.03-0.13-0.04
                  l-0.92-0.29l-0.04-0.08c-0.21-0.48-0.71-0.78-1.22-0.74l-0.85,0.06l-2.86-1.9l-1.03-0.58l-0.97-0.67c-0.17-0.12-0.37-0.19-0.57-0.21
                  l-1.26-0.14l-1.1-0.25l-0.98-0.47l-1.08-0.71c-0.15-0.1-0.31-0.17-0.49-0.19l-1-0.17l-0.37-0.25l-0.12-0.59l-0.01-1.12l0.02-0.29
                  l0.26-0.07l1.09,0.03c0.32,0.01,0.63-0.11,0.86-0.32l1.93-1.76l1-0.84c0.26-0.22,0.42-0.53,0.45-0.87l0.09-1.23
                  c0.02-0.34-0.1-0.68-0.33-0.94l-0.61-0.66l-0.38-1.94l0.35-1.11l0.27-1.14l0.6-1.99c0.08-0.27,0.07-0.56-0.04-0.83l-0.46-1.13
                  l-0.24-0.97l0.07-0.8l0.54-0.51c0.2-0.18,0.34-0.44,0.38-0.71l0.15-0.9l0.53-0.67l0.85-0.87c0.25-0.27,0.38-0.63,0.34-1
                  c-0.03-0.36-0.23-0.69-0.53-0.9l-0.87-0.59l-0.38-0.79l-0.3-1.08c-0.12-0.47-0.51-0.82-0.98-0.9l-1.31-0.23l-1.81-0.41
                  c-0.18-0.04-0.35-0.04-0.53-0.01l-1.46,0.3l-1.08,0.01l-0.68-0.23l-0.56-0.63l-0.57-0.71l-0.17-1.05c-0.01-0.09-0.04-0.19-0.07-0.28
                  l-0.87-2.05l0.3-0.34c0.18-0.19,0.28-0.43,0.31-0.69l0.12-1.1l0.42-1.01c0.08-0.18,0.11-0.37,0.1-0.56l-0.1-1.46l0.02-1.25
                  c0-0.12-0.01-0.24-0.05-0.36l-0.35-1.24c-0.01-0.04-0.02-0.09-0.04-0.13l-0.95-2.22l0.28-0.74c0.06-0.16,0.09-0.33,0.08-0.5
                  l-0.04-0.86l0.5-0.52c0.05-0.05,0.1-0.11,0.14-0.17l0.76-1.13c0.1-0.15,0.16-0.31,0.19-0.48l1.46,1.43
                  c0.07,0.07,0.16,0.14,0.25,0.19l1.1,0.66c0.04,0.03,0.1,0.06,0.15,0.08l1.2,0.51l1.22,0.62c0.12,0.06,0.24,0.1,0.36,0.12l5.35,0.95
                  l1.33,0.16c0.51,0.06,1-0.19,1.25-0.64l0.6-1.1c0.07-0.13,0.11-0.26,0.13-0.4l0.2-1.21l0.74-2.08l0.29-0.41l1.91-0.23
                  c0.22-0.03,0.43-0.12,0.6-0.25l1.05-0.81c0.25-0.19,0.42-0.47,0.47-0.78l0.2-1.18l0.27-1.23c0.04-0.19,0.04-0.38,0-0.56l-0.2-0.83
                  l0.27-0.72l0.57-0.88l0.72-0.91c0.09-0.11,0.16-0.24,0.2-0.37l0.4-1.16c0.1-0.29,0.09-0.59-0.02-0.86l0.91,0.38l0.44,0.34l0.1,0.77
                  c0.01,0.05,0.02,0.09,0.03,0.13l0.26,1.1l0.08,1.13c0.03,0.36,0.21,0.7,0.51,0.91l0.88,0.65l0.69,0.84
                  c0.27,0.32,0.67,0.49,1.09,0.44l1.24-0.13c0.06,0,0.12-0.01,0.18-0.03l1.28-0.33c0.2-0.05,0.38-0.15,0.53-0.29l0.79-0.71l2.3-1.13
                  c0.54-0.26,0.81-0.87,0.66-1.45l-0.73-2.71l-0.17-0.94l0.23-0.7l0.27-0.3l0.7,0.02c0.24,0.02,0.47-0.05,0.67-0.17l0.87-0.51
                  l0.58-0.06l0.8,0.52c0.11,0.07,0.23,0.12,0.35,0.15v0.01c-0.01,0.36,0.15,0.71,0.43,0.95l0.93,0.81c0.26,0.23,0.61,0.34,0.96,0.3
                  l0.6-0.06l0.41,0.5c0.03,0.04,0.06,0.07,0.1,0.1l0.91,0.89c0.13,0.14,0.29,0.24,0.47,0.29l3.65,1.31l1.47,0.42l1.34,0.29
                  c0.13,0.03,0.27,0.04,0.4,0.02l0.84-0.09l1.74,1.12l0.87,0.83c0.06,0.06,0.13,0.12,0.2,0.16l1.29,0.8l1.1,0.59l0.8,0.58l1.31,2
                  c0.09,0.14,0.2,0.25,0.34,0.34l0.91,0.6l0.84,0.94c0.02,0.03,0.05,0.05,0.07,0.08l1.96,1.79l0.64,0.89c0.14,0.2,0.34,0.35,0.57,0.44
                  l1.17,0.44l1,0.61l0.87,0.64l0.91,0.91l0.97,0.85C290.87,455.01,290.87,455.02,290.88,455.02z"/>
                <title>Hlohovec</title>
              </Link>

              {/* Nitra - nitriansky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Nitra")}>
                <path style={{ fill: crimesInRegionNitriansky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionNitriansky[2].crimePercent)] ? crimesInRegionNitriansky[2].color : "#D3D3D3" }}
                  d="M414.93,542.13l-0.06,0.28l-0.49,0.06c-0.22,0.03-0.43,0.12-0.6,0.26l-1.07,0.84c-0.1,0.07-0.18,0.16-0.25,0.26
                  l-0.73,1.04c-0.06,0.09-0.11,0.18-0.15,0.27l-0.41,1.08l-1.4,1.94l-0.73,0.89l-2.83-0.09c-0.2-0.01-0.41,0.04-0.59,0.13l-1.15,0.58
                  c-0.06,0.03-0.12,0.07-0.18,0.11l-1.29,0.94c-0.23,0.17-0.4,0.42-0.47,0.7l-0.06,0.24l-0.17-0.09c-0.06-0.04-0.13-0.07-0.19-0.09
                  l-2.28-0.84l-1.12-0.5l-1.14-0.87c-0.37-0.28-0.86-0.34-1.28-0.15l-0.84,0.39l-1.22-0.08c-0.08,0-0.15,0-0.23,0.01l-1.25,0.15
                  c-0.39,0.05-0.74,0.28-0.94,0.63l-0.75,1.33c-0.17,0.3-0.21,0.67-0.1,1.01l0.4,1.18l0.15,1.29c0.01,0.12,0.04,0.24,0.09,0.35
                  l0.5,1.17c0.07,0.17,0.18,0.32,0.31,0.44l0.84,0.75l0.63,0.82l0.38,0.71l-0.07,0.5l-0.43,0.56l-0.87,0.41
                  c-0.09,0.05-0.18,0.1-0.26,0.17l-0.94,0.78c-0.09,0.07-0.17,0.16-0.23,0.26l-0.7,1.03c-0.15,0.21-0.23,0.47-0.22,0.73l0.08,2.49
                  l-1.3,0.07l-0.73-0.25l-1.07-0.85c-0.27-0.21-0.62-0.31-0.95-0.26l-1.48,0.21l-1.02,0.19l-0.99-0.12l-1.29-0.44
                  c-0.04-0.02-0.08-0.03-0.12-0.04l-1.28-0.29c-0.01,0-0.01,0-0.02,0l0.09-0.88l0.22-1.16c0.08-0.44-0.08-0.9-0.43-1.19l-0.95-0.79
                  c-0.2-0.17-0.46-0.27-0.72-0.29l-2.53-0.17l-1.34-0.22l-1.08-0.28l-1.25-0.54l-1.09-0.38l-0.79-0.5l-0.74-0.8
                  c-0.07-0.07-0.15-0.14-0.23-0.19l-1-0.67l-0.92-0.76c-0.14-0.11-0.29-0.2-0.47-0.24l-1.19-0.33c-0.43-0.13-0.89,0-1.21,0.31
                  l-0.87,0.86c-0.43,0.43-0.49,1.1-0.15,1.6l0.58,0.84l0.26,1.01c0.02,0.06,0.04,0.13,0.07,0.19l0.45,1.01l0.03,1.13l0.12,1.41
                  l-0.01,0.98l-0.37,0.55l-0.72,0.57l-0.91,0.35l-1.01,0.29l-1-0.09c-0.21-0.01-0.42,0.02-0.61,0.11l-1.32,0.59
                  c-0.13,0.06-0.25,0.14-0.36,0.24l-0.68,0.66l-0.93,0.21c-0.04,0.01-0.08,0.02-0.12,0.04l-1.18,0.4c-0.07,0.02-0.13,0.05-0.19,0.08
                  l-1.73,0.93l-0.54,0.02l-0.7-0.46c-0.15-0.1-0.31-0.16-0.49-0.19l-0.32-0.06l-0.01-0.14l0.14-1.11c0.02-0.18,0.01-0.37-0.05-0.54
                  l-0.38-1.19c-0.07-0.23-0.21-0.43-0.39-0.58l-0.21-0.17l0.56-0.31c0.08-0.04,0.14-0.08,0.2-0.13l0.95-0.78
                  c0.1-0.08,0.18-0.18,0.25-0.28l0.65-0.98l0.78-0.86c0.23-0.24,0.35-0.57,0.33-0.9c-0.02-0.34-0.17-0.65-0.42-0.87l-0.77-0.7
                  l-0.39-0.68l-0.11-0.9l0.02-1.14c0.01-0.4-0.18-0.77-0.49-1.01c-0.32-0.24-0.73-0.32-1.11-0.21l-1.18,0.34
                  c-0.05,0.02-0.11,0.04-0.17,0.06l-1.17,0.53c-0.05,0.03-0.11,0.06-0.16,0.09l-1.13,0.73l-0.95,0.68l-3.43,1.56l-0.66-0.02
                  l-0.59-0.52c-0.37-0.33-0.9-0.41-1.35-0.21l-1.08,0.5l-2.4,0.98l-1.15,0.56c-0.52,0.25-0.8,0.84-0.67,1.41l0.09,0.38l-3.76,2.33
                  c-0.03,0.02-0.06,0.04-0.09,0.06l-5.84,4.68l-0.95,0.31c-0.13,0.04-0.24,0.1-0.35,0.18l-3.86,2.82c-0.04,0.02-0.07,0.05-0.1,0.08
                  l-7.67,6.83l-0.39,0.05l-0.95-1.5l-0.72-1.27c-0.11-0.18-0.26-0.34-0.44-0.45l-1.09-0.66c-0.23-0.14-0.5-0.2-0.78-0.17l-0.81,0.08
                  l-1.38-1.6l-0.82-1.01c-0.03-0.04-0.07-0.08-0.11-0.11l-1.89-1.8c-0.08-0.07-0.16-0.13-0.25-0.17l-0.73-0.64l-0.8-1.04l-0.72-1.04
                  l-0.6-1.11l-0.68-1.12c-0.05-0.07-0.1-0.14-0.15-0.2l-2.75-2.93c-0.05-0.06-0.1-0.1-0.16-0.15c0.01-0.01,0.02-0.03,0.03-0.04
                  l2.1-3.38l0.82-0.92c0.04-0.05,0.07-0.1,0.11-0.15l0.76-1.18l0.79-1.43c0.2-0.34,0.21-0.76,0.05-1.12l-0.5-1.1l-0.36-1.3l-0.28-1.78
                  l-0.14-2.45c-0.01-0.13-0.04-0.25-0.08-0.37l-0.45-1.17c-0.02-0.04-0.03-0.08-0.05-0.11l-0.48-0.95l0.11-0.31l0.69-0.23
                  c0.33-0.11,0.59-0.35,0.74-0.65c0.15-0.31,0.16-0.67,0.04-0.99l-2.04-5.29c-0.25-0.63-0.95-0.96-1.58-0.73l-1.32,0.47l-2.27-0.36
                  c-0.07-0.01-0.14-0.01-0.21-0.01l-1.06,0.03l-1.28-0.49c-0.1-0.04-0.21-0.06-0.32-0.07l-1.18-0.12l-1.05-0.48l-2.7-1.63l-0.75-0.8
                  c-0.06-0.06-0.13-0.12-0.2-0.17l-1.2-0.84l-2.02-1.74c-0.17-0.15-0.38-0.25-0.6-0.29l-0.87-0.16l-0.96-1.01l-1.03-0.97
                  c-0.26-0.24-0.61-0.37-0.96-0.34l-0.05,0.01l-0.07-0.59c-0.03-0.24-0.13-0.46-0.28-0.65l-0.82-0.99c-0.43-0.51-1.18-0.6-1.72-0.19
                  l-0.97,0.74l-0.63-1.29c-0.16-0.33-0.47-0.58-0.83-0.67c-0.36-0.09-0.75,0-1.05,0.23l-1.03,0.78l-1.36,0.28l-0.25-0.64
                  c-0.06-0.15-0.15-0.3-0.26-0.42l-0.93-0.99c-0.14-0.14-0.31-0.26-0.51-0.32l-1.38-0.47c-0.15-0.06-0.31-0.07-0.47-0.07l-1.2,0.07
                  l-1.2-0.05l-0.83-0.08l-0.35-0.31l-0.23-0.89c-0.01-0.05-0.02-0.09-0.04-0.14l-0.17-0.45l0.33-0.36l1.95-1.64
                  c0.08-0.07,0.14-0.15,0.2-0.23l0.71-1.01c0.15-0.21,0.23-0.46,0.23-0.71l0.01-1.27v-0.11l-0.1-1.18l0.02-1.7l0.38-0.15
                  c0.31-0.13,0.55-0.37,0.68-0.68c0.13-0.3,0.13-0.65,0.01-0.96l-0.44-1.04l-0.2-0.91l0.08-0.77l0.34-0.65l2-1.37
                  c0.42-0.3,0.62-0.82,0.49-1.32l-0.25-1l0.22-0.95c0.05-0.22,0.04-0.44-0.03-0.65l-0.66-2.04l0.19-0.52l0.59-0.57l0.91-0.54
                  l1.08-0.43c0.48-0.19,0.8-0.66,0.79-1.18l-0.02-1.22l0.09-0.59l0.6-0.38c0.09-0.05,0.17-0.12,0.24-0.2l0.89-0.93
                  c0.2-0.2,0.32-0.47,0.35-0.75l0.09-1.08l0.32-0.91l0.6-0.88l0.87-1.03c0.03-0.03,0.06-0.07,0.08-0.11l0.7-1.06
                  c0.07-0.1,0.12-0.2,0.15-0.31l0.31-0.97l0.63-0.78c0.03-0.04,0.06-0.09,0.09-0.13l0.6-1l0.78-0.9c0.04-0.04,0.07-0.08,0.1-0.12
                  l0.74-1.12l0.7-1.22c0.08-0.13,0.13-0.28,0.16-0.43l0.19-1.28l0.67-2.29l1.27-2.04c0.04-0.07,0.08-0.14,0.1-0.22l0.47-1.27l0.29-1
                  l0.62-0.93c0.13-0.2,0.2-0.43,0.21-0.66l0.02-0.95l0.42-0.57l0.87-0.53c0.09-0.06,0.18-0.12,0.25-0.2l0.88-0.91
                  c0.11-0.12,0.2-0.25,0.26-0.4l0.48-1.18c0.09-0.22,0.11-0.46,0.07-0.7l-0.09-0.46l0.02-0.01l0.87,0.04
                  c0.29,0.01,0.56-0.07,0.79-0.24l1.04-0.75c0.36-0.25,0.55-0.67,0.52-1.11l-0.08-0.95l0.38-0.91l0.73-0.82
                  c0.04-0.04,0.07-0.08,0.11-0.13l0.74-1.1c0.03-0.06,0.07-0.12,0.1-0.18l0.52-1.14c0.05,0.02,0.1,0.04,0.15,0.05l0.63,0.62
                  c0.18,0.17,0.4,0.29,0.64,0.34l2.47,0.48c0.06,0.01,0.12,0.02,0.18,0.02l1.25,0.06c0.06,0,0.12,0,0.18-0.01l1.05-0.1l1.03,0.22
                  c0.06,0.02,0.12,0.02,0.18,0.03l1.23,0.08h0.16l1.2-0.08l1.14,0.09c0.61,0.03,1.13-0.34,1.29-0.91l0.34-1.21
                  c0.08-0.27,0.06-0.56-0.04-0.82l-0.1-0.22l0.69-0.09l1.03,0.2c0.14,0.02,0.27,0.03,0.41,0.01l0.82-0.12l0.53,0.3l0.62,0.78
                  c0.19,0.25,0.46,0.41,0.77,0.46l1.22,0.21c0.08,0.02,0.15,0.02,0.23,0.02s0.15,0,0.23-0.02l0.38,1.18c0.09,0.27,0.26,0.5,0.49,0.66
                  l1.05,0.71c0.07,0.04,0.14,0.08,0.22,0.11l1.13,0.47c0.06,0.03,0.13,0.05,0.2,0.07l1.2,0.28l0.18,0.03l0.23,0.01l-0.22,0.81
                  l-1.13,2.09c-0.2,0.37-0.2,0.83,0,1.2l0.85,1.56c0.1,0.18,0.25,0.34,0.43,0.45l0.98,0.63l0.97,0.74c0.13,0.1,0.27,0.17,0.43,0.22
                  l2.97,0.79l3.27,1.12l1.08,0.43l1.02,0.67c0.11,0.07,0.23,0.12,0.35,0.16l1.73,0.47c0.06,0.01,0.12,0.02,0.17,0.03l1.27,0.16
                  c0.06,0.01,0.11,0.01,0.16,0.01c0.07,0,0.14-0.01,0.21-0.02l1.23-0.21c0.58-0.1,1.01-0.59,1.04-1.18l0.05-1.23
                  c0-0.09,0-0.18-0.02-0.27l-0.15-0.82l0.32-0.65l0.61-0.82l1.69-1.65l1.23-1.28c0.14-0.15,0.24-0.32,0.3-0.51l0.32-1.05l2.33,0.55
                  l2.53,0.33l1,0.24l1.08,0.52c0.2,0.1,0.42,0.14,0.64,0.13l1.15-0.08l0.98,0.1l1,0.4c0.04,0.02,0.09,0.04,0.14,0.05l1.21,0.32
                  c0.13,0.03,0.26,0.04,0.39,0.04l0.99-0.06l0.28,0.09l0.15,0.71l0.33,1.27c0.07,0.26,0.22,0.49,0.42,0.66l1.14,0.92l1.87,1.96
                  l2.4,2.92c0.09,0.11,0.19,0.2,0.31,0.27l2.41,1.46l0.13,1.73c0.04,0.49,0.37,0.91,0.83,1.08l1.18,0.42l1.17,0.7l0.43,0.68
                  l-0.11,0.85c-0.02,0.17-0.01,0.35,0.04,0.52l0.47,1.56c0.03,0.09,0.07,0.18,0.12,0.26l0.61,1.06c0.03,0.06,0.07,0.12,0.11,0.17
                  l0.81,1c0.02,0.03,0.05,0.06,0.08,0.09l2.88,2.94l1.01,1.42l-0.22,0.58c-0.02,0.04-0.03,0.07-0.04,0.1l-0.35,1.17l-0.51,1.02
                  c-0.07,0.15-0.12,0.32-0.13,0.48l-0.08,1.31c-0.01,0.28,0.06,0.55,0.21,0.77l0.97,1.44c-0.08,0.01-0.16,0.02-0.24,0.05
                  c-0.35,0.11-0.64,0.37-0.79,0.71l-0.46,1.12l-1.38,1.96c-0.06,0.09-0.11,0.19-0.14,0.3l-0.07,0.2l-0.16-0.04
                  c-0.61-0.11-1.21,0.22-1.42,0.8l-0.46,1.26c-0.15,0.39-0.09,0.83,0.16,1.16l0.73,1.01c0.03,0.05,0.07,0.1,0.11,0.14l0.92,0.95
                  c0.03,0.03,0.06,0.05,0.08,0.08l0.93,0.8c0.08,0.06,0.15,0.11,0.23,0.15l0.96,0.51l0.21,0.24l-0.15,0.5
                  c-0.1,0.32-0.07,0.66,0.09,0.96l0.56,1.04l0.5,1.14c0.03,0.08,0.07,0.15,0.12,0.22l0.72,1.02c0.2,0.29,0.51,0.48,0.86,0.52
                  c0.34,0.05,0.7-0.06,0.96-0.28l1.07-0.9c0.08-0.06,0.14-0.13,0.2-0.21l0.61-0.81l0.38-0.18l0.47,0.21l0.67,0.48l0.93,2.02
                  c0.13,0.29,0.37,0.52,0.67,0.63l1.19,0.47c0.28,0.11,0.59,0.12,0.87,0.02l1.09-0.38l0.7-0.07l0.13,0.19l0.09,0.6l-0.36,0.82
                  c-0.08,0.18-0.11,0.38-0.1,0.58l0.14,2.44c0.02,0.33,0.17,0.65,0.41,0.87c0.25,0.22,0.57,0.33,0.91,0.32l2.58-0.11l1.19,0.14
                  l1.35,0.56c0.05,0.03,0.11,0.05,0.17,0.06l1.32,0.34c0.13,0.03,0.27,0.05,0.4,0.04l1.54-0.12l1.23,0.11l1.02,0.24l0.22,0.14
                  l-0.72,0.62c-0.17,0.14-0.29,0.32-0.37,0.53l-0.42,1.17c-0.02,0.07-0.04,0.14-0.05,0.21l-0.22,1.29c-0.05,0.27-0.01,0.54,0.12,0.78
                  l0.57,1.11c0.09,0.18,0.22,0.33,0.38,0.45l1,0.71c0.06,0.05,0.12,0.08,0.18,0.11l1.24,0.6l1.19,0.47l1.23,0.55
                  c0.03,0.02,0.07,0.03,0.1,0.04l1.5,0.52c0.07,0.03,0.15,0.05,0.23,0.06l1.09,0.16l2.27,0.85c0.04,0.01,0.08,0.02,0.12,0.04
                  l1.27,0.34c0.04,0.02,0.07,0.02,0.11,0.03l0.86,0.15l0.34,0.57c0.19,0.31,0.5,0.52,0.86,0.58c0.35,0.07,0.72-0.03,1-0.26l0.85-0.69
                  l0.44-0.12l0.48,0.28l0.3,0.57l0.08,1.07c0.03,0.34,0.18,0.64,0.44,0.86l0.96,0.81l0.95,0.95c0.04,0.05,0.09,0.09,0.14,0.13
                  l1.05,0.76l0.38,0.86c0.02,0.05,0.05,0.1,0.08,0.14l0.79,1.26L414.93,542.13z"/>
                <title>Nitra</title>
              </Link>

              {/* Sala - nitriansky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Šaľa")}>
                <path style={{ fill: crimesInRegionNitriansky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionNitriansky[4].crimePercent)] ? crimesInRegionNitriansky[4].color : "#D3D3D3" }}
                  d="M305.76,629.07l-0.46,0.56l-0.92,0.68c-0.19,0.14-0.34,0.34-0.42,0.57l-0.33,0.9l-0.41,0.39l-0.87,0.12
                  c-0.04,0.01-0.08,0.01-0.12,0.02l-1.68,0.4c-0.06,0.02-0.12,0.04-0.18,0.06l-1.15,0.47c-0.12,0.05-0.23,0.12-0.33,0.2l-0.96,0.8
                  c-0.27,0.22-0.43,0.55-0.45,0.9c-0.01,0.35,0.12,0.7,0.37,0.94l0.3,0.31l-0.12,0.31l-0.57,0.9l-1.18,1.28
                  c-0.27,0.29-0.38,0.69-0.31,1.08l0.24,1.26l0.02,0.34l-0.49,0.24c-0.31,0.16-0.54,0.43-0.64,0.76l-0.35,1.16l-0.82,0.84
                  c-0.09,0.09-0.16,0.19-0.22,0.3l-0.66,1.25c-0.13,0.26-0.17,0.56-0.11,0.85l0.26,1.18l0.11,0.86l-4.19-1.9l-1.11-0.46l-1.07-0.54
                  c-0.06-0.04-0.13-0.06-0.2-0.09l-0.96-0.29l-1.87-1.54l-1.16-0.87c-0.19-0.14-0.42-0.23-0.65-0.25l-1.29-0.1h-0.16l-1.35,0.07
                  c-0.13,0.01-0.25,0.03-0.37,0.08l-1.13,0.41l-0.5,0.11l-0.4-0.42c-0.25-0.25-0.59-0.4-0.94-0.38l-0.83,0.03l-0.71-0.49
                  c-0.04-0.02-0.07-0.04-0.11-0.06l-1.04-0.55l-0.75-0.51c-0.01-0.1-0.03-0.21-0.07-0.32l-0.7-1.86l-1.64-4.1v-1.79l0.58-2.72
                  c0.05-0.21,0.04-0.42-0.02-0.62l-0.39-1.3c-0.06-0.18-0.15-0.34-0.27-0.48l-0.16-0.18l1.17-1.41l0.94-0.95l0.91-1.01
                  c0.19-0.22,0.31-0.49,0.32-0.78l0.06-1.3c0.02-0.37-0.13-0.72-0.39-0.97l-0.56-0.52l-0.03-0.54l0.37-0.89
                  c0.16-0.41,0.11-0.88-0.16-1.23l-0.83-1.11c-0.02-0.03-0.04-0.05-0.06-0.08l-1-1.13c-0.05-0.05-0.1-0.1-0.16-0.14l-0.97-0.78
                  c-0.31-0.26-0.73-0.34-1.12-0.23l-1.05,0.29l-0.83-0.02l-0.8-0.4l-1.16-0.72l-2.1-1.83l1.56-1.07c0.46-0.31,0.66-0.88,0.49-1.4
                  l-0.58-1.85c-0.04-0.13-0.1-0.25-0.17-0.36l-0.72-1.01c-0.31-0.43-0.86-0.62-1.37-0.47l-2.01,0.59c-0.1,0.03-0.2,0.07-0.28,0.12
                  l-4.49,2.66h-0.01c-0.01-0.02-0.02-0.04-0.03-0.06l-0.69-1.03c-0.28-0.42-0.79-0.62-1.29-0.53l-0.25,0.06l-0.22-0.91l0.28-0.97
                  l0.55-2.48l0.39-1.08l1.38-2.3l0.65-1.22l0.67-1.46c0.01-0.03,0.02-0.06,0.04-0.1l0.42-1.17c0.2-0.56-0.03-1.2-0.55-1.5l-0.73-0.43
                  l-0.35-0.72l-0.08-1.18c-0.03-0.32-0.18-0.63-0.42-0.84l-0.91-0.81l-2.39-2.8l-1.71-1.87c-0.03-0.05-0.08-0.08-0.12-0.12l-1.99-1.6
                  l-1.52-2.07c-0.03-0.05-0.07-0.09-0.11-0.12l-0.88-0.9c-0.1-0.11-0.21-0.19-0.34-0.25l-1.11-0.54c-0.04-0.02-0.08-0.04-0.12-0.06
                  l-1.04-0.37l-1.13-0.71l-0.63-0.35l2.62-1.86l0.89-0.28l1.38-0.17l1.03,0.26c0.09,0.02,0.19,0.04,0.28,0.04l0.83,0.02l0.31,0.36
                  l0.41,0.97c0.04,0.11,0.1,0.21,0.17,0.3l0.84,1.05c0.34,0.43,0.92,0.58,1.43,0.39l1.15-0.44c0.1-0.04,0.19-0.09,0.28-0.16l3.13-2.22
                  c0.41-0.28,0.61-0.79,0.5-1.28l-0.16-0.79l0.93-1.52l0.88-0.43c0.52-0.25,0.8-0.84,0.67-1.4l-0.39-1.67
                  c-0.05-0.22-0.16-0.42-0.32-0.58l-1.26-1.32l-1.84-2.07c-0.03-0.04-0.08-0.08-0.12-0.12l-0.87-0.74l-0.79-0.93
                  c-0.06-0.06-0.12-0.12-0.19-0.17l-0.58-0.45l0.03-0.54l0.27-0.73l0.38-0.36l0.86-0.08c0.22-0.02,0.43-0.1,0.62-0.23l1.07-0.78
                  c0.08-0.06,0.15-0.13,0.22-0.21l0.14-0.17c0.08,0.1,0.16,0.19,0.26,0.26l1.61,1.2l1.4,3.26c0.18,0.41,0.56,0.68,1,0.73l1.34,0.15
                  c0.34,0.04,0.67-0.06,0.93-0.27c0.26-0.22,0.43-0.52,0.46-0.86l0.08-0.9l0.49-0.51l1.2-0.63l1.59-0.98l0.98-0.64l1.17-0.5l1.24-0.36
                  c0.12-0.04,0.24-0.09,0.35-0.17l1.06-0.72c0.12-0.07,0.21-0.17,0.3-0.27l0.82-1.08c0.09-0.12,0.16-0.26,0.2-0.4l0.36-1.23l0.47-1.24
                  c0.07-0.19,0.1-0.4,0.07-0.6l-0.15-1.18l-0.06-1.36c-0.01-0.25-0.09-0.48-0.23-0.68l-0.84-1.16l-0.81-1
                  c-0.02-0.02-0.04-0.05-0.07-0.07l-0.89-0.93c-0.13-0.15-0.3-0.25-0.49-0.32l-0.92-0.32l-0.78-0.73c-0.03-0.03-0.07-0.06-0.1-0.09
                  l-1.13-0.84c-0.06-0.04-0.11-0.08-0.17-0.11l-0.75-0.39l-0.28-0.7l-0.8-2.98c-0.01-0.06-0.03-0.11-0.05-0.17l-0.24-0.58l0.34-0.46
                  l0.96-0.68l1.04-0.83c0.3-0.24,0.47-0.61,0.47-1l-0.02-1.51c0-0.19-0.05-0.38-0.14-0.55l-0.7-1.37c-0.08-0.17-0.2-0.31-0.35-0.42
                  l0.72-0.55c0.14-0.11,0.25-0.24,0.34-0.39l0.73-1.29l0.58-1.17c0.05-0.1,0.08-0.21,0.11-0.31l0.24-1.22l0.53-0.99
                  c0.06-0.11,0.1-0.23,0.12-0.35l1.4-0.29c0.19-0.03,0.36-0.11,0.51-0.23l0.04-0.03l0.55,1.27c0.15,0.36,0.46,0.62,0.83,0.72
                  c0.38,0.1,0.77,0.02,1.08-0.22l1.14-0.87l0.14,1.09c0.08,0.67,0.68,1.15,1.34,1.1l0.69-0.06l1.78,1.8c0.18,0.18,0.41,0.31,0.67,0.36
                  l0.92,0.17l1.86,1.6c0.03,0.02,0.06,0.04,0.09,0.07l1.14,0.79l0.78,0.83c0.08,0.08,0.16,0.15,0.26,0.21l2.92,1.76
                  c0.04,0.03,0.08,0.05,0.12,0.07l1.3,0.59c0.13,0.05,0.26,0.09,0.39,0.1l1.23,0.13l1.35,0.51c0.15,0.06,0.31,0.08,0.47,0.08l1.2-0.03
                  l2.44,0.38c0.21,0.04,0.42,0.02,0.62-0.05l0.47-0.17l1.13,2.93l-0.02,0.01c-0.37,0.12-0.66,0.41-0.79,0.77l-0.5,1.39
                  c-0.11,0.32-0.09,0.68,0.06,0.98l0.7,1.38l0.36,0.93l0.12,2.29c0,0.05,0.01,0.09,0.02,0.14l0.31,1.91c0,0.04,0.01,0.09,0.02,0.13
                  l0.4,1.47c0.02,0.06,0.04,0.13,0.07,0.19l0.28,0.62l-0.44,0.8l-0.67,1.02l-0.83,0.94c-0.05,0.06-0.09,0.12-0.13,0.18l-2.08,3.35
                  l-0.93,1.15c-0.25,0.31-0.34,0.71-0.24,1.09c0.09,0.38,0.36,0.7,0.72,0.85l1.02,0.43l2.45,2.63l0.58,0.95l0.6,1.12
                  c0.02,0.04,0.05,0.08,0.07,0.12l0.8,1.15l0.92,1.18c0.05,0.07,0.11,0.13,0.17,0.18l0.13,0.12l-4.88,6.45l-3.53,4.43
                  c-0.08,0.1-0.15,0.22-0.2,0.34l-0.92,2.37c-0.19,0.49-0.05,1.04,0.35,1.39l1.22,1.07c0.11,0.1,0.24,0.18,0.38,0.23l1.21,0.46
                  c0.29,0.11,0.61,0.11,0.89-0.01l0.93-0.35l1.76,1.01l1,0.73l1.35,0.9l0.83,0.71l0.59,0.6l-0.06,0.44l-1.12,2.44l-1.71,3.18
                  l-0.73,1.21l-0.88,1.62c-0.05,0.09-0.09,0.19-0.11,0.29l-1.14,4.3l-0.5,1.41c-0.05,0.14-0.07,0.3-0.07,0.45l0.03,1.37
                  c0,0.14,0.03,0.27,0.08,0.4l0.64,1.75l0.14,1.11c0.01,0.08,0.03,0.15,0.05,0.23l0.51,1.56c0.05,0.15,0.13,0.3,0.24,0.42l2.11,2.44
                  l0.79,1.1l1.62,2.03l0.63,0.74l-0.41,2.22c-0.06,0.33,0.01,0.68,0.21,0.95l3.22,4.57L305.76,629.07z"/>
                <title>Šaľa</title>
              </Link>


              {/* Galanta - trnavsky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Galanta")}>
                <path style={{ fill: crimesInRegionTrnavsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionTrnavsky[1].crimePercent)] ? crimesInRegionTrnavsky[1].color : "#D3D3D3" }}
                  d="M267.23,627.08c-0.41,0.48-0.4,1.19,0.02,1.66l0.71,0.79l0.22,0.72l-0.54,2.52c-0.02,0.09-0.03,0.18-0.03,0.27
                  v2.18c0,0.17,0.03,0.33,0.1,0.48l0.6,1.44l1.65,4.27l-0.36,1.15l-0.99,3.76c-0.03,0.11-0.04,0.21-0.04,0.32l0.06,2.55l-0.07,0.63
                  l-0.61-0.73l-0.5-0.98c-0.03-0.06-0.06-0.11-0.1-0.17l-0.72-0.99c-0.02-0.02-0.04-0.05-0.07-0.08l-0.78-0.9l-0.59-0.97
                  c-0.33-0.53-0.98-0.74-1.54-0.51l-0.45,0.19l-0.6-1.14c-0.28-0.57-0.95-0.84-1.55-0.63l-0.33,0.12l-1.14-1.32l-0.17-0.91
                  c-0.02-0.08-0.04-0.15-0.07-0.23l-0.47-1.16c-0.1-0.24-0.26-0.44-0.48-0.58l-0.82-0.54l-1.18-1.82l-0.58-1.01l-0.29-1.17
                  c-0.1-0.43-0.43-0.77-0.86-0.9l-1.23-0.36c-0.14-0.04-0.3-0.06-0.45-0.05l-0.92,0.08l-0.24-0.2l-0.17-0.79
                  c-0.04-0.19-0.12-0.36-0.24-0.51l-0.59-0.76l-0.25-0.98c-0.07-0.29-0.24-0.54-0.48-0.71l-1.05-0.76c-0.14-0.1-0.3-0.17-0.46-0.21
                  l-1.24-0.27c-0.04-0.01-0.08-0.02-0.12-0.02l-0.42-0.05l-0.1-0.47c-0.02-0.13-0.08-0.26-0.15-0.38l-0.62-1.04l-0.6-1.12
                  c-0.04-0.08-0.09-0.15-0.15-0.22l-0.81-0.95c-0.19-0.23-0.46-0.38-0.75-0.42l-0.92-0.15l-0.43-0.41l-0.04-0.1l0.26-0.22
                  c0.25-0.21,0.41-0.5,0.44-0.82l0.15-1.3c0.06-0.54-0.24-1.07-0.74-1.29l-0.54-0.23l-0.44-1.94c-0.02-0.1-0.05-0.19-0.09-0.27
                  l-0.6-1.19c-0.19-0.37-0.55-0.63-0.96-0.68l-2.36-0.26l-0.93-0.43c-0.1-0.04-0.2-0.07-0.31-0.09l-1.12-0.19l-1.02-0.39l-1.08-0.52
                  c-0.11-0.06-0.22-0.09-0.34-0.11l-1.14-0.19l-1.12-0.28l-1.06-0.44c-0.15-0.07-0.32-0.11-0.49-0.1l-1.25,0.01
                  c-0.22,0-0.43,0.06-0.62,0.17l-0.69,0.4l-1.76-0.28l-0.49-0.61l-0.66-0.98c-0.16-0.23-0.39-0.4-0.65-0.49l-1.17-0.38
                  c-0.32-0.1-0.65-0.07-0.94,0.07l-0.32,0.16l0.36-0.93c0.13-0.32,0.12-0.67-0.02-0.98l-0.51-1.12c-0.2-0.44-0.64-0.72-1.12-0.73
                  l-1.83-0.05l-0.04-0.05l0.14-0.6l0.44-1.14c0.11-0.29,0.11-0.61,0-0.9l-0.47-1.22c-0.21-0.53-0.75-0.87-1.32-0.79l-1.29,0.16
                  c-0.16,0.02-0.31,0.07-0.44,0.14l-1,0.53l-0.38,0.06l-0.44-0.57c-0.03-0.05-0.07-0.09-0.12-0.13l-0.89-0.87
                  c-0.07-0.08-0.15-0.14-0.24-0.19l-1.07-0.63c-0.07-0.04-0.14-0.08-0.22-0.1l-1.25-0.43c-0.08-0.03-0.15-0.05-0.23-0.06l-1.31-0.19
                  c-0.05-0.01-0.11-0.01-0.16-0.01l-1.27-0.02h-0.11l-1.11,0.08l-1.06-0.28l0.47-0.5c0.18-0.18,0.29-0.41,0.33-0.66l0.2-1.26
                  c0.04-0.22,0.02-0.44-0.06-0.64l-0.45-1.2c-0.04-0.11-0.1-0.21-0.17-0.31l-0.82-1.1c-0.11-0.15-0.25-0.27-0.41-0.35l-1.11-0.59
                  c-0.34-0.18-0.74-0.2-1.08-0.05l-1.16,0.5c-0.37,0.16-0.64,0.48-0.73,0.87l-0.3,1.3c-0.02,0.1-0.03,0.2-0.03,0.3l0.01,0.39
                  l-0.36,0.14c-0.39,0.16-0.67,0.51-0.75,0.93l-0.19,1.01l-0.03,0.05l-0.45-0.77l-0.15-1.05c-0.04-0.25-0.15-0.47-0.31-0.66
                  l-0.83-0.92c-0.16-0.17-0.37-0.3-0.6-0.36l-1.27-0.36c-0.21-0.06-0.44-0.05-0.65,0c-0.02-0.17-0.07-0.34-0.15-0.5l-0.6-1.09
                  c-0.28-0.5-0.86-0.76-1.41-0.61l-1.27,0.33c-0.33,0.08-0.61,0.3-0.78,0.59l-0.47,0.83l-0.66,0.39l-2.41,0.45
                  c-0.07,0.01-0.15,0.03-0.22,0.06l-0.68,0.25l1.34-0.99c0.25-0.18,0.42-0.46,0.48-0.77l0.24-1.24c0.05-0.27,0.01-0.55-0.11-0.79
                  l-0.87-1.77c-0.22-0.45-0.69-0.75-1.18-0.7l-1.11,0.06l-1.19-0.15c-0.41-0.06-0.83,0.11-1.11,0.44l-0.84,1
                  c-0.19,0.23-0.29,0.52-0.29,0.82l0.01,0.79l-0.17,0.17l-0.43-0.04l-1.39-1.11l-0.13-0.77c-0.09-0.56-0.55-0.98-1.12-1.04l-1.22-0.11
                  c-0.1,0-0.19,0-0.29,0.01l-0.71,0.11l-0.59-0.45c-0.05-0.04-0.1-0.07-0.15-0.1l-1.07-0.6c-0.13-0.07-0.28-0.12-0.43-0.15l-0.65-0.09
                  l-0.19-0.3l-0.07-0.93c-0.02-0.15-0.06-0.3-0.12-0.44l-0.61-1.27l-0.28-0.87l0.09-1.06c0.05-0.65-0.4-1.22-1.04-1.33l-1.69-0.29
                  c-0.13-0.02-0.27-0.03-0.41,0l-0.92,0.15l-0.51-0.17l-0.07-1.99l0.08-0.69l-0.26-0.03l0.5-0.44c0.12-0.1,0.21-0.22,0.28-0.35
                  l0.8-1.5l0.53,0.11c0.54,0.1,1.08-0.16,1.34-0.64l0.63-1.18c0.12-0.23,0.17-0.48,0.14-0.74l-0.15-1.25
                  c-0.01-0.07-0.02-0.14-0.04-0.21l-0.05-0.17l1.59,0.36l1.01,0.54c0.48,0.26,1.09,0.17,1.48-0.22l1.16-1.17
                  c0.08-0.08,0.14-0.16,0.2-0.26l0.21-0.37l0.33,0.08c0.46,0.11,0.95-0.06,1.26-0.43l1.29-1.57c0.11-0.13,0.19-0.29,0.24-0.47
                  l0.8-3.03l0.55-2.54l0.48-0.15c0.33-0.1,0.6-0.34,0.75-0.65c0.15-0.32,0.16-0.68,0.03-1l-0.4-1.01l-0.12-1.05
                  c-0.04-0.41-0.29-0.77-0.65-0.96l-1.11-0.59c-0.31-0.17-0.67-0.19-1-0.08c-0.33,0.12-0.6,0.36-0.74,0.68l-0.19,0.45l-0.37,0.03
                  l-0.92-0.1l-0.42-0.23l-0.1-0.38l0.17-0.76l2.22-3.07l1.57-2.35c0.04-0.06,0.07-0.12,0.1-0.19l0.49-1.15
                  c0.14-0.33,0.13-0.71-0.03-1.04c-0.16-0.33-0.45-0.57-0.8-0.66l-2.68-0.73l-4.04-1.62c-0.07-0.03-0.15-0.06-0.23-0.07l-1.23-0.25
                  c-0.12-0.03-0.24-0.03-0.36-0.02l-0.92,0.08l-0.71-0.45c-0.07-0.05-0.15-0.09-0.24-0.12l-0.27-0.1l0.59-1.07
                  c0.14-0.23,0.19-0.5,0.16-0.77l-0.11-1.03l0.79-2.23l0.48-0.99l0.64-0.86l0.77-0.88l0.84-0.81c0.03-0.03,0.06-0.06,0.08-0.09
                  l0.83-0.98l0.81-1.03l2.2-3.07l0.71-0.92l0.13,0.13l2.45,2.28l2.2,2.63l0.84,1.35c0.02,0.03,0.04,0.06,0.06,0.09l1.67,2.2l0.7,1.1
                  c0.02,0.04,0.04,0.07,0.07,0.1l1.85,2.4l0.72,1.09c0.23,0.35,0.61,0.56,1.01,0.57l1.42,0.04h0.04c0.33,0,0.65-0.13,0.88-0.36
                  l1.69-1.69l1.47-1.25l1.12-0.32c0.23-0.06,0.44-0.2,0.6-0.38l1.46-1.68c0.07-0.08,0.13-0.17,0.18-0.26l0.8-1.62l1.3-2.23l0.73-0.55
                  l1.46-0.44l2.05,0.48c0.13,0.03,0.28,0.04,0.41,0.02l1.5-0.16l1.54-0.06l1.78-0.2l1.42-0.09l0.99-0.02l0.34,0.13l0.1,0.56
                  c0.08,0.49,0.44,0.88,0.92,1c0.49,0.12,0.99-0.05,1.3-0.44l0.76-0.98c0.26-0.35,0.33-0.8,0.18-1.21l-0.21-0.55l0.33-0.33
                  c0.34-0.35,0.45-0.86,0.28-1.32l-0.33-0.88l1.76-0.17c0.03,0,0.07,0,0.1-0.01l1.34-0.25l1.62-0.16c0.17-0.01,0.33-0.06,0.48-0.14
                  l1.4-0.77l1.54-0.61c0.32-0.13,0.57-0.38,0.7-0.69c0.13-0.32,0.12-0.68-0.02-0.99l-0.26-0.57l0.2-0.38l0.84-0.83l0.6-0.67l0.87-0.16
                  c0.45-0.09,0.82-0.41,0.96-0.85s0.03-0.92-0.3-1.25l-0.92-0.94c-0.05-0.06-0.11-0.11-0.17-0.15l-1.87-1.33
                  c0.02-0.03,0.04-0.06,0.06-0.09l0.85-1.45l0.91-0.94l1-0.69l0.91-0.07c0.16-0.02,0.31-0.06,0.45-0.13l1.51-0.74l1.3-0.21l2.12-0.42
                  c0.36-0.08,0.67-0.31,0.85-0.63l1.65,0.86l0.82,0.8c0.24,0.23,0.55,0.34,0.9,0.35c0.33-0.01,0.65-0.15,0.88-0.39l1.18-1.24
                  c0.21-0.23,0.33-0.53,0.34-0.84l0.05-3.61l0.72-0.84c0.14-0.16,0.23-0.35,0.28-0.56l0.26-1.33l0.18-0.53l0.63,0.28l0.58,0.88
                  l0.7,0.9l0.31,0.97c0.12,0.39,0.42,0.7,0.81,0.82l0.96,0.31l1.59,1.63c0.14,0.14,0.3,0.24,0.48,0.31l1.27,0.45
                  c0.14,0.05,0.28,0.07,0.42,0.07c0.22,0,0.45-0.06,0.64-0.18l1.1-0.66l0.98-0.47l1.16-0.27c0.09-0.03,0.18-0.06,0.27-0.1l1.2-0.6
                  c0.19-0.1,0.35-0.24,0.47-0.41l0.73-1.06c0.25-0.37,0.29-0.85,0.1-1.25l-0.5-1.04l-0.18-1.06c-0.02-0.13-0.06-0.26-0.12-0.38
                  l-0.58-1.1l-0.64-1.85l0.23-0.37l0.69-0.5l0.61-0.26l0.66,0.27c0.52,0.22,1.12,0.06,1.47-0.38l0.4-0.52l0.31-0.01l0.59,0.39
                  l0.58,0.82c0.04,0.05,0.09,0.11,0.14,0.16l0.9,0.89c0.09,0.09,0.19,0.16,0.3,0.22l0.71,0.37l0.13,0.69c0.08,0.4,0.36,0.75,0.74,0.91
                  l1.21,0.52c0.34,0.14,0.72,0.13,1.05-0.03c0.33-0.17,0.57-0.48,0.66-0.84l0.13-0.57l0.45-0.19l-0.15,0.66
                  c-0.04,0.19-0.04,0.38,0.01,0.57l0.11,0.46l-1.49,1c-0.18,0.12-0.33,0.28-0.42,0.46l-0.61,1.15c-0.08,0.15-0.13,0.3-0.14,0.46
                  l-0.13,1.22c-0.02,0.14-0.01,0.28,0.02,0.41l0.28,1.21c0.02,0.06,0.03,0.12,0.05,0.17c-0.44,0.2-0.74,0.63-0.75,1.12l-0.04,2.62
                  v0.11l0.1,1.18l-0.01,0.82l-0.39,0.57l-1.85,1.55c-0.05,0.04-0.09,0.08-0.13,0.12l-0.91,0.98c-0.32,0.35-0.42,0.84-0.25,1.29
                  l0.41,1.11l0.31,1.21c0.06,0.25,0.2,0.48,0.39,0.65l0.96,0.83c0.2,0.17,0.45,0.27,0.71,0.3l1.28,0.11l1.32,0.06h0.13l1.02-0.06
                  l0.85,0.29l0.55,0.58l0.64,1.62l-0.34,0.63c-0.06,0.11-0.1,0.22-0.13,0.35l-0.24,1.23l-0.48,0.97l-0.57,1.01l-1.02,0.77
                  c-0.07,0.05-0.13,0.11-0.19,0.17l-0.81,0.92c-0.25,0.29-0.36,0.68-0.29,1.06c0.07,0.37,0.31,0.7,0.65,0.88l0.96,0.5l0.38,0.76
                  l0.01,0.61l-1.57,1.16c-0.1,0.08-0.19,0.17-0.27,0.27l-0.89,1.18c-0.26,0.35-0.32,0.82-0.16,1.23l0.48,1.16l0.81,3
                  c0.01,0.05,0.02,0.09,0.04,0.14l0.48,1.19c0.11,0.27,0.32,0.5,0.58,0.64l1.07,0.56l1,0.74l0.92,0.86c0.12,0.12,0.27,0.21,0.44,0.27
                  l0.88,0.31l0.65,0.68l0.73,0.9l0.58,0.8l0.04,1.04c0,0.04,0.01,0.08,0.01,0.11l0.12,0.92l-0.39,1.03l-0.32,1.09l-0.56,0.74
                  l-0.73,0.49l-1.12,0.33c-0.05,0.01-0.09,0.03-0.14,0.05l-1.34,0.57c-0.07,0.03-0.13,0.06-0.2,0.1l-2.53,1.61l-1.29,0.67
                  c-0.11,0.06-0.22,0.14-0.31,0.24l-0.95,0.97c-0.03,0.02-0.06,0.05-0.08,0.08l-0.86-2.04c-0.08-0.21-0.22-0.39-0.4-0.52l-1.63-1.21
                  l-0.57-1.27c-0.18-0.39-0.55-0.66-0.97-0.72c-0.43-0.06-0.85,0.11-1.13,0.44l-1.3,1.55l-0.67,0.49l-0.95,0.09
                  c-0.28,0.02-0.54,0.14-0.74,0.34l-0.9,0.86c-0.14,0.13-0.25,0.29-0.31,0.47l-0.43,1.16c-0.04,0.11-0.07,0.22-0.08,0.34l-0.1,1.4
                  c-0.03,0.42,0.16,0.83,0.49,1.08l1.02,0.78l0.77,0.9c0.04,0.04,0.09,0.09,0.14,0.13l0.87,0.75l1.82,2.03l1.05,1.11l0.09,0.36
                  l-0.32,0.16c-0.21,0.1-0.39,0.27-0.52,0.47l-1.37,2.23c-0.17,0.27-0.23,0.6-0.16,0.92l0.1,0.48l-2.35,1.67l-0.14,0.06l-0.16-0.2
                  l-0.42-0.99c-0.05-0.12-0.12-0.24-0.2-0.34l-0.81-0.94c-0.23-0.26-0.57-0.42-0.92-0.43l-1.24-0.03l-1.12-0.28
                  c-0.15-0.04-0.31-0.05-0.46-0.03l-1.73,0.21c-0.08,0.01-0.16,0.03-0.24,0.05l-1.17,0.38c-0.11,0.04-0.21,0.09-0.31,0.15l-2.5,1.74
                  l-1.03,0.81c-0.35,0.27-0.53,0.71-0.47,1.15l0.17,1.24c0.05,0.39,0.28,0.72,0.62,0.92l1.19,0.67l1.2,0.75
                  c0.07,0.05,0.15,0.08,0.23,0.12l1.1,0.39l0.86,0.42l0.67,0.69l1.54,2.1c0.07,0.09,0.15,0.18,0.24,0.25l2.03,1.63l0.77,0.83
                  l3.24,3.75c0.04,0.05,0.08,0.09,0.13,0.13l0.6,0.54l0.06,0.91c0.01,0.16,0.06,0.31,0.13,0.45l0.62,1.29c0.1,0.22,0.27,0.4,0.49,0.53
                  l0.16,0.09l-0.66,1.49l-0.58,1.09l-1.42,2.37c-0.05,0.07-0.08,0.15-0.11,0.22l-0.45,1.27c-0.02,0.05-0.03,0.1-0.04,0.16l-0.55,2.46
                  l-0.34,1.2c-0.06,0.19-0.06,0.39-0.03,0.58l0.24,1.21c0.02,0.09,0.05,0.18,0.09,0.27l0.52,1.17c0.24,0.53,0.81,0.83,1.39,0.72
                  l0.42-0.09l0.66,1.31c0.15,0.32,0.42,0.56,0.76,0.66c0.34,0.11,0.71,0.06,1.01-0.12l5.53-3.27l1.01-0.3l0.33,0.9l-2.13,1.47
                  c-0.32,0.22-0.52,0.58-0.54,0.96c-0.02,0.39,0.14,0.76,0.43,1.01l3.39,2.95c0.05,0.05,0.1,0.09,0.16,0.12l1.29,0.8
                  c0.03,0.02,0.07,0.04,0.1,0.06l1.1,0.55c0.16,0.08,0.34,0.12,0.52,0.13l1.3,0.04c0.13,0.01,0.26-0.01,0.38-0.05l0.61-0.17l0.38,0.31
                  l0.9,1.01l0.37,0.5l-0.21,0.5c-0.07,0.18-0.1,0.37-0.09,0.56l0.09,1.33c0.02,0.31,0.16,0.6,0.39,0.82l0.5,0.47l-0.02,0.28
                  l-1.51,1.58c-0.03,0.03-0.05,0.06-0.08,0.09L267.23,627.08z"/>
                <title>Galanta</title>
              </Link>

              {/* Dunajska streda - trnavsky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Dunajská Streda")}>
                <path style={{ fill: crimesInRegionTrnavsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionTrnavsky[0].crimePercent)] ? crimesInRegionTrnavsky[0].color : "#D3D3D3" }}
                  d="M272.03,670.3l-0.3,0.5l-1.83,1.61l-0.99,0.78l-1.33,0.78c-0.04,0.03-0.09,0.06-0.13,0.09l-1.6,1.25l-1.1,0.67
                  l-1.66,0.63c-0.12,0.04-0.23,0.11-0.33,0.18l-1.16,0.9c-0.14,0.12-0.26,0.26-0.35,0.43l-0.58,1.17l-1.08,1.86l-1.39,2.62l-0.64,0.76
                  l-0.68,0.38l-0.31-0.25c-0.36-0.28-0.85-0.35-1.27-0.17l-1.31,0.56l-1.19,0.4c-0.1,0.03-0.2,0.08-0.3,0.14l-1.15,0.77
                  c-0.38,0.26-0.59,0.7-0.54,1.16l0.07,0.72l-0.46,0.55c-0.02,0.03-0.05,0.07-0.07,0.1l-0.73,1.11l-0.69,1.16l-0.61,1.13
                  c-0.05,0.1-0.09,0.2-0.12,0.31l-0.31,1.37c-0.03,0.14-0.04,0.28-0.02,0.42l0.15,1.33l0.48,2.38l0.05,1.13
                  c0.01,0.11,0.03,0.21,0.06,0.31l0.37,1.19l0.06,1.05l-0.07,0.97l-0.37,0.89l-0.5,1.04l-0.42,0.58l-3.51,1.22
                  c-0.08,0.03-0.15,0.06-0.22,0.11l-1.16,0.69c-0.07,0.04-0.14,0.09-0.21,0.15l-1.16,1.07c-0.17,0.16-0.3,0.37-0.36,0.6l-0.37,1.38
                  l-0.72,0.89c-0.04,0.05-0.08,0.11-0.11,0.16l-0.32-0.41c-0.27-0.35-0.71-0.53-1.15-0.47l-0.9,0.11l-0.79-0.4
                  c-0.28-0.14-0.6-0.17-0.91-0.08l-0.44,0.13l-0.33-0.37c-0.23-0.25-0.56-0.39-0.9-0.4s-0.66,0.13-0.9,0.37l-0.78,0.77l-0.98,0.55
                  c-0.41,0.22-0.66,0.65-0.65,1.12l0.02,0.99l-0.34,0.92c-0.03,0.08-0.05,0.16-0.06,0.25l-0.23,1.46c-0.06,0.35,0.04,0.71,0.27,0.99
                  l0.75,0.91l-0.43,0.2c-0.37,0.17-0.63,0.51-0.7,0.91c-0.05,0.28,0,0.57,0.13,0.81c-0.41,0.38-0.54,0.99-0.27,1.5l0.58,1.12
                  l0.16,0.64l-0.93,1.52l-1.88,1.29c-0.07,0.05-0.14,0.11-0.2,0.17l-0.83,0.86l-0.92,0.83c-0.04,0.04-0.08,0.08-0.12,0.13l-1.05-1.88
                  c-0.02-0.04-0.04-0.07-0.07-0.1l-0.82-1.18c-0.04-0.06-0.08-0.11-0.13-0.17l-1.12-1.14c-0.04-0.04-0.09-0.08-0.13-0.11l-1.05-0.8
                  c-0.06-0.05-0.12-0.08-0.18-0.11l-1.25-0.67l-2.58-2.09l-1.79-1.56l-1.85-1.5l-1.35-0.97l-2.62-1.62l-1.38-0.97l-1.19-1l-2.94-3.74
                  l-1.43-1.78l-1.48-1.62c-0.19-0.21-0.44-0.34-0.71-0.39l-1.51-0.26c-0.19-0.04-0.39-0.02-0.58,0.04l-1.13,0.35l-1.35,0.1
                  c-0.05,0.01-0.11,0.02-0.16,0.03l-1.33,0.28c-0.18,0.03-0.35,0.11-0.49,0.22l-2.69,2.02l-0.55,0.17l-0.47-0.27l-0.99-1.21
                  l-0.76-1.72c-0.05-0.12-0.11-0.22-0.19-0.31l-1.89-2.2l-0.38-0.88l-0.47-2.11c-0.04-0.16-0.1-0.31-0.19-0.44l-0.67-0.97l-0.27-0.99
                  l0.15-1.11c0.02-0.14,0.01-0.28-0.02-0.42l-0.35-1.63c-0.06-0.3-0.24-0.57-0.49-0.75l-1.02-0.75l-1.13-1.09l-1.45-2.4
                  c-0.1-0.16-0.24-0.3-0.4-0.4l-1.22-0.78c-0.25-0.17-0.56-0.23-0.85-0.19l-1.57,0.23l-2.1,0.13l-1.08-0.11l-0.9-0.21l-3.22-2.76
                  l-1.03-0.82c-0.06-0.04-0.11-0.08-0.17-0.11l-1.35-0.75c-0.12-0.07-0.25-0.12-0.39-0.14l-1.46-0.27l-1.23-0.36l-1.15-0.6l-0.97-0.76
                  l-0.47-0.75l-0.25-1.36c-0.02-0.09-0.04-0.17-0.08-0.25l-0.87-2.09c-0.04-0.1-0.1-0.2-0.16-0.28l-0.78-1.01l-1.07-2.03l-0.45-1.05
                  l-0.19-1.46l-0.23-1.23c-0.02-0.09-0.04-0.17-0.08-0.25l-0.59-1.41c-0.03-0.08-0.07-0.16-0.12-0.23l-0.7-1.02
                  c-0.05-0.07-0.1-0.13-0.16-0.19l-0.96-0.93l-0.57-1.06c-0.06-0.12-0.14-0.22-0.24-0.31l-1-0.96c-0.09-0.09-0.19-0.16-0.3-0.22
                  l-0.98-0.49l-0.74-0.73l-0.58-0.88l-0.48-0.9l-0.27-1.22c-0.02-0.09-0.05-0.17-0.08-0.24l-0.64-1.42c-0.06-0.13-0.13-0.24-0.22-0.34
                  l-0.94-1.02c-0.12-0.12-0.26-0.22-0.42-0.29l-4.4-2.06l-1.18-0.85l-0.79-1.17l-1.27-2.1c-0.02-0.03-0.05-0.07-0.07-0.1l-1-1.33
                  c-0.04-0.06-0.09-0.11-0.14-0.16l-1.35-1.28l-1.45-1.19l-0.84-0.9l-0.68-0.88l-0.63-1.12c-0.03-0.04-0.06-0.09-0.1-0.14l-1.27-1.66
                  l-0.88-0.99c-0.08-0.1-0.18-0.18-0.3-0.25l-1.27-0.75c-0.1-0.06-0.21-0.1-0.32-0.13l-1.53-0.39c-0.12-0.03-0.23-0.05-0.35-0.04
                  l-1.68,0.05l-1.51-0.25c-0.03-0.01-0.07-0.01-0.11-0.02l-1.55-0.12h-0.12l-1.95,0.05c-0.1,0-0.19,0.02-0.28,0.04l-1.91,0.48
                  l-1.63,0.22l-1.03,0.01l-1.75-0.55l-1.53-0.59c-0.05-0.01-0.09-0.03-0.14-0.04l-1.17-0.3l-0.99-0.4l-0.94-0.56l-0.5-0.58l-0.21-0.81
                  l-0.02-1.58l2.68-3.72c0.21-0.28,0.28-0.63,0.22-0.95l0.01-0.01l1.86-0.92c0.18-0.09,0.34-0.22,0.46-0.38l2.03-2.76
                  c0.04-0.06,0.08-0.13,0.12-0.2l1.09-2.28l2.96-0.67l1.25-0.22c0.2-0.03,0.39-0.12,0.55-0.24l0.98-0.76
                  c0.26-0.21,0.43-0.51,0.47-0.84l0.15-1.23c0.04-0.32-0.05-0.65-0.25-0.91l-0.69-0.9l-0.41-0.89l-0.25-1.59l-0.18-0.91l1.04,0.86
                  l0.51,0.91c0.18,0.32,0.49,0.55,0.86,0.62c0.36,0.07,0.74-0.03,1.03-0.27l1.26-1.04c0.12-0.09,0.22-0.21,0.29-0.35l1.12-2l0.57,0.23
                  c0.32,0.13,0.68,0.12,1-0.03s0.56-0.42,0.66-0.75l1.39-4.36l0.37-0.49l1.26-0.28c0.3-0.07,0.56-0.24,0.74-0.48l2.2-3
                  c0.3-0.41,0.32-0.97,0.05-1.41c-0.28-0.43-0.79-0.66-1.3-0.56l-0.61,0.12l-0.8-1.12c-0.39-0.55-1.13-0.69-1.69-0.33l-3.47,2.21
                  l-0.83-0.99l-1.1-1.5c-0.16-0.22-0.39-0.38-0.65-0.46l-1.22-0.37l1.33-5.04c0.09-0.34,0.03-0.7-0.15-0.99
                  c0.02,0.01,0.05,0.02,0.08,0.03l1.79,0.57c0.27,0.09,0.56,0.08,0.83-0.02l0.84-0.32l2.21,0.43c0.43,0.08,0.86-0.06,1.16-0.38
                  l0.86-0.94l0.79-0.61l3.02-1.42c0.19-0.09,0.36-0.23,0.49-0.41l1.14-1.57c0.03-0.03,0.05-0.07,0.08-0.11l0.1-0.18l2.15,0.87
                  c0.53,0.22,1.14,0.04,1.48-0.42l1.2-1.65c0.04-0.06,0.08-0.12,0.11-0.18l0.64-1.27l0.99-1.25c0.22-0.28,0.31-0.64,0.25-0.99
                  s-0.26-0.66-0.56-0.85l-1.32-0.83c-0.07-0.04-0.15-0.08-0.22-0.11l-0.6-0.23l-0.21-1.64c-0.04-0.3-0.18-0.56-0.39-0.76
                  c0.14-0.11,0.26-0.26,0.34-0.43c0.15-0.3,0.17-0.66,0.05-0.98l-0.35-0.96l-0.01-0.96l0.57-0.69c0.23-0.27,0.33-0.63,0.28-0.97
                  l1.63,0.19c0.09,0.01,0.18,0.01,0.27,0l1.27-0.12c0.27-0.02,0.52-0.13,0.71-0.3l0.29,0.53c0.09,0.16,0.2,0.3,0.34,0.4l0.21,0.16
                  l-0.66,0.24c-0.15,0.06-0.29,0.14-0.4,0.24l-0.98,0.86c-0.17,0.15-0.3,0.34-0.37,0.55l-0.42,1.25c-0.13,0.41-0.05,0.85,0.21,1.18
                  l0.82,1.03c0.17,0.21,0.41,0.37,0.67,0.43l1.23,0.31c0.16,0.04,0.33,0.05,0.49,0.03l1.76-0.25c0.08-0.01,0.16-0.03,0.24-0.06
                  l0.9-0.32l0.74,0.35l0.75,0.83l1.14,1.5c0.14,0.19,0.33,0.33,0.56,0.41l1.22,0.45c0.24,0.09,0.5,0.1,0.75,0.04l1.35-0.36l1.24-0.21
                  c0.26-0.04,0.5-0.17,0.68-0.35l2.06-2.15c0.04-0.03,0.07-0.07,0.1-0.11l0.55-0.76l0.47-0.2l0.13,0.08l-0.02,0.21
                  c-0.01,0.05-0.01,0.11-0.01,0.17l0.03,1.41l0.1,1.6c0.03,0.5,0.37,0.94,0.85,1.1l1.59,0.54c0.19,0.07,0.4,0.09,0.6,0.05l1.02-0.16
                  l0.37,0.06l-0.02,0.16c-0.01,0.16,0.01,0.32,0.06,0.47l0.37,1.19c0.01,0.06,0.04,0.12,0.06,0.17l0.55,1.14l0.08,1
                  c0.02,0.2,0.08,0.39,0.19,0.56l0.65,1.05c0.19,0.31,0.51,0.52,0.88,0.58l1,0.14l0.79,0.45l0.94,0.71c0.27,0.21,0.61,0.3,0.94,0.25
                  l1.09-0.16l0.11,0.01l0.05,0.3c0.05,0.32,0.22,0.6,0.47,0.79l1,0.76l1,0.83c0.2,0.16,0.44,0.26,0.69,0.29l1.4,0.12
                  c0.37,0.02,0.73-0.1,0.99-0.36l0.96-0.95c0.24-0.24,0.38-0.57,0.37-0.91l-0.01-0.85l0.1-0.13l0.62,0.08
                  c0.07,0.01,0.14,0.01,0.21,0.01l0.4-0.02l0.32,0.66l-0.07,0.36l-1.79,1.31c-0.35,0.25-0.55,0.66-0.52,1.09l0.09,1.33
                  c0.04,0.51,0.39,0.96,0.89,1.11l1.43,0.43c0.26,0.07,0.54,0.06,0.8-0.03l1.1-0.41l2.55-0.47c0.14-0.03,0.27-0.08,0.4-0.16l0.2-0.12
                  c0,0.17,0.03,0.34,0.1,0.51l0.48,1.14c0.19,0.46,0.64,0.76,1.14,0.76l1.24,0.01c0.53-0.02,1-0.33,1.19-0.82l0.33-0.93l0.19-0.16
                  l0.29,0.08l0.33,0.37l0.13,0.91c0.03,0.16,0.08,0.31,0.16,0.45l0.56,0.96l0.36,1.12c0.08,0.25,0.24,0.47,0.45,0.63l1.01,0.74
                  c0.35,0.25,0.8,0.31,1.2,0.15c0.4-0.15,0.69-0.51,0.77-0.93l0.2-1.06l0.5-0.91c0.06-0.11,0.1-0.24,0.13-0.37l0.1-0.54l0.52-0.21
                  c0.47-0.2,0.78-0.67,0.77-1.18l-0.02-1.09l0.13-0.53l0.02-0.01l0.34,0.17l0.54,0.73l0.27,0.73l-0.08,0.55l-0.61,0.64
                  c-0.14,0.14-0.24,0.32-0.3,0.51l-0.37,1.27c-0.09,0.32-0.05,0.66,0.11,0.96c0.16,0.29,0.43,0.5,0.75,0.59l2.64,0.72
                  c0.13,0.03,0.27,0.05,0.41,0.04l1.26-0.09l1.14,0.02l1.11,0.16l1.02,0.35l0.83,0.49l0.72,0.7l0.83,1.08
                  c0.27,0.36,0.72,0.54,1.17,0.48l1.33-0.19c0.14-0.02,0.28-0.07,0.41-0.14l0.96-0.51l-0.24,0.6c-0.02,0.06-0.03,0.11-0.05,0.17
                  l-0.29,1.25c-0.08,0.36,0,0.73,0.22,1.03l0.74,0.99c0.22,0.3,0.57,0.48,0.94,0.5l1.64,0.07l-0.24,0.62
                  c-0.04,0.1-0.07,0.21-0.08,0.31l-0.15,1.27c-0.07,0.58,0.27,1.13,0.81,1.33l1.18,0.42c0.32,0.12,0.68,0.1,0.98-0.05l0.66-0.32
                  l0.28,0.09l0.44,0.66c0.02,0.03,0.05,0.06,0.07,0.08l0.83,1.03c0.19,0.25,0.48,0.41,0.79,0.46l2.64,0.42c0.29,0.05,0.59,0,0.84-0.15
                  l0.79-0.46l0.66-0.01l0.92,0.38c0.06,0.03,0.12,0.05,0.18,0.06l1.3,0.32l1.05,0.18l0.97,0.47c0.04,0.02,0.07,0.04,0.11,0.05
                  l1.19,0.45c0.07,0.03,0.15,0.05,0.23,0.06l1.08,0.19l0.97,0.44c0.13,0.06,0.26,0.09,0.4,0.1l1.83,0.2l0.24,0.47l0.26,1.07l0.23,1.25
                  c0.08,0.41,0.35,0.75,0.73,0.92l0.22,0.09l-0.49,0.41c-0.42,0.35-0.56,0.94-0.35,1.44l0.5,1.2c0.06,0.16,0.16,0.31,0.29,0.43
                  l0.9,0.85c0.18,0.17,0.41,0.28,0.66,0.32l0.85,0.14l0.42,0.5l0.57,1.05l0.54,0.91l0.24,1.14c0.11,0.52,0.54,0.92,1.07,0.98
                  l1.25,0.15l0.93,0.21l0.47,0.34l0.2,0.79c0.04,0.17,0.12,0.33,0.23,0.47l0.58,0.74l0.2,0.93c0.06,0.28,0.21,0.52,0.42,0.7l0.96,0.8
                  c0.25,0.21,0.57,0.32,0.9,0.29l1.2-0.1l0.32,0.1l0.16,0.64c0.02,0.11,0.07,0.22,0.13,0.33l2.03,3.29c0.09,0.15,0.22,0.29,0.38,0.39
                  l0.73,0.47l0.28,0.69l0.21,1.12c0.05,0.22,0.15,0.42,0.29,0.59l1.88,2.2c0.33,0.4,0.89,0.56,1.39,0.38l0.1-0.04l0.06,0.12
                  c0.04,0.08,0.08,0.16,0.14,0.23l0.77,0.97c0.35,0.43,0.94,0.59,1.45,0.38l0.34-0.14l0.09,0.15c0.04,0.06,0.08,0.12,0.13,0.17
                  l0.81,0.94l0.63,0.87l0.52,1.01c0.04,0.08,0.09,0.15,0.15,0.22l0.82,1.01l0.68,1.15c0.23,0.39,0.64,0.62,1.08,0.62h0.11l1.03-0.09
                  l1.25,0.83l0.28,0.7l0.17,0.99l0.04,1.04l-0.2,1.23l-0.11,0.97l-1.17,1.75l-0.81,0.93c-0.04,0.04-0.07,0.08-0.1,0.13l-0.69,1.04
                  c-0.11,0.16-0.18,0.35-0.2,0.55l-0.14,1.25c-0.05,0.45,0.15,0.9,0.52,1.16l0.96,0.68l0.82,0.9l0.9,0.87
                  c0.08,0.07,0.16,0.13,0.25,0.18l0.34,0.2l-0.07,0.32c-0.06,0.26-0.03,0.54,0.09,0.79L272.03,670.3z"/>
                <title>Dunajská Streda</title>
              </Link>

              {/* Malacky - bratislavsky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Malacky")}>
                <path style={{ fill: crimesInRegionBratislavsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionBratislavsky[5].crimePercent)] ? crimesInRegionBratislavsky[5].color : "#D3D3D3" }}
                  d="M138.63,397.14l-0.08,1.07l-0.29,0.85l-0.67,0.9l-1.65,1.53l-0.94,0.68c-0.08,0.05-0.15,0.11-0.21,0.18
                  l-0.95,1.08c-0.16,0.19-0.26,0.41-0.3,0.64c-0.08,0.06-0.16,0.12-0.23,0.19l-0.99,1.04l-0.95,1.13c-0.04,0.05-0.08,0.11-0.12,0.17
                  l-0.68,1.16l-0.41,0.84l-0.89,0.64c-0.06,0.04-0.12,0.09-0.17,0.15l-0.67,0.69l-0.76,0.16l-1.14,0.07
                  c-0.63,0.04-1.13,0.54-1.17,1.17l-0.08,1.24c-0.01,0.14,0.01,0.28,0.04,0.41l0.04,0.14l-0.56,0.21c-0.48,0.19-0.8,0.65-0.8,1.16
                  l-0.01,0.94l-0.28,0.49l-0.7,0.38l-2.24,0.84c-0.12,0.04-0.22,0.1-0.32,0.17l-1.08,0.81l-2.45,2.05c-0.06,0.05-0.12,0.11-0.17,0.17
                  l-0.95,1.14l-3.05,2.46c-0.52,0.43-0.6,1.21-0.18,1.74c0.43,0.53,1.2,0.63,1.74,0.21l0.47-0.36l1.54,1.3
                  c0.13,0.11,0.29,0.2,0.47,0.24l2.87,0.8l1.93,1.25l0.83,2.56l0.43,1.17c0.09,0.25,0.26,0.46,0.48,0.61l1.05,0.7l1.18,0.97
                  c0.08,0.07,0.18,0.13,0.28,0.17l1.44,0.64c0.16,0.07,0.34,0.11,0.51,0.11h0.7c-0.25,0.52-0.11,1.15,0.35,1.52l0.98,0.78
                  c0.19,0.15,0.41,0.24,0.65,0.26l1.05,0.11l0.57,0.36l-1.81,1.23l-1.19,0.53c-0.31,0.14-0.56,0.4-0.67,0.73
                  c-0.11,0.32-0.09,0.68,0.07,0.98l0.52,1.01l0.07,0.77l-0.24,0.65l-0.72,0.68c-0.17,0.16-0.29,0.35-0.35,0.57
                  c-0.46,0.07-0.85,0.4-1,0.87l-0.06,0.19l-0.09-0.01c-0.05-0.01-0.1-0.02-0.15-0.03l-1.39-0.11c-0.15-0.01-0.29,0.01-0.44,0.05
                  l-1.21,0.34c-0.59,0.16-0.98,0.72-0.91,1.33l0.08,0.82l-1.29,1.86l-0.8,1.02c-0.07,0.08-0.12,0.17-0.16,0.26l-0.6,1.34
                  c-0.17,0.37-0.14,0.79,0.06,1.14l0.69,1.18c0.05,0.08,0.11,0.16,0.17,0.23l0.73,0.78c-0.07,0.03-0.12,0.07-0.18,0.11l-1.13,0.77
                  c-0.1,0.06-0.19,0.15-0.27,0.24l-1.72,2.09c-0.16,0.2-0.26,0.44-0.29,0.69l-0.08,1.02l-0.55,0.73c-0.1,0.13-0.17,0.28-0.21,0.44
                  l-0.3,1.18l-0.53,0.89l-1.21,0.98c-0.08,0.06-0.14,0.12-0.2,0.19l-0.91,1.15c-0.03,0.04-0.06,0.08-0.09,0.13l-0.19,0.31l-2.23-0.47
                  c-0.05-0.01-0.1-0.02-0.15-0.02l-1.45-0.14c-0.41-0.04-0.81,0.12-1.07,0.43c-0.27,0.31-0.36,0.73-0.26,1.13l0.21,0.8l-0.25,0.53
                  l-0.74,0.74c-0.33,0.34-0.45,0.84-0.29,1.29l0.2,0.57l-0.33,0.34c-0.03,0.04-0.07,0.08-0.1,0.12l-0.75,1
                  c-0.25,0.34-0.32,0.77-0.18,1.17l0.42,1.18c0.1,0.26,0.28,0.49,0.51,0.64l0.51,0.31v0.12l-0.52,0.57c-0.12,0.13-0.21,0.29-0.26,0.46
                  l-0.35,1.08l-0.54,0.81l-0.79,1c-0.06,0.07-0.12,0.16-0.16,0.25l-0.58,1.28c-0.16,0.37-0.14,0.78,0.05,1.13l0.41,0.74l-0.23,0.77
                  c-0.03,0.11-0.05,0.23-0.05,0.34v0.18L104,486.6c-0.23-0.12-0.49-0.17-0.74-0.14l-1.23,0.14c-0.11,0.01-0.22,0.04-0.32,0.08
                  l-1.03,0.41l-1.13,0.22l-1.2-0.03h-0.13l-1.25,0.1c-0.25,0.02-0.49,0.12-0.69,0.28l-1.04,0.84c-0.02,0.02-0.05,0.04-0.08,0.07
                  l-0.97,0.94l-0.98,0.54c-0.28,0.15-0.49,0.42-0.59,0.73l-0.36,1.18l-0.5,1.18c-0.15,0.35-0.13,0.75,0.05,1.09l0.36,0.65l-0.26,0.25
                  c-0.08,0.07-0.15,0.16-0.21,0.25l-0.73,1.21l-0.57,1.09c-0.05,0.1-0.09,0.21-0.11,0.33l-0.25,1.21c-0.06,0.25-0.03,0.52,0.08,0.76
                  l0.99,2.26c0.04,0.09,0.08,0.17,0.14,0.24l0.3,0.43l-0.22,0.03c-0.38,0.06-0.72,0.29-0.91,0.63l-0.5,0.9l-0.77,0.65
                  c-0.22,0.2-0.37,0.47-0.42,0.77l-0.18,1.22c-0.05,0.33,0.04,0.68,0.25,0.95l0.76,0.97l0.08,0.71l-0.13,0.61l-0.68,0.48
                  c-0.16,0.11-0.29,0.26-0.38,0.43l-1.21,2.2l-0.17,0.21l-0.32-0.12c-0.09-0.03-0.18-0.05-0.27-0.06l-1.19-0.15l-1.13-0.33
                  c-0.5-0.14-1.05,0.04-1.36,0.46l-0.78,1.06c-0.08,0.12-0.15,0.25-0.19,0.39l-0.39,1.35l-0.23,0.99l-0.66,0.89
                  c-0.14,0.19-0.23,0.42-0.25,0.66l-0.09,1.23c-0.01,0.16,0.01,0.32,0.06,0.48l0.39,1.19l0.66,2.82c0.02,0.1,0.06,0.2,0.11,0.29
                  l0.63,1.17c0.04,0.08,0.09,0.14,0.14,0.21l0.83,0.99l0.3,0.66L83,528.3c-0.03,0.04-0.06,0.08-0.08,0.12l-0.63,1.08
                  c-0.03,0.05-0.05,0.1-0.08,0.15l-0.5,1.21c-0.06,0.16-0.09,0.33-0.09,0.51l0.02,0.88l-0.53,0.59c-0.16,0.19-0.27,0.43-0.3,0.68
                  l-0.11,0.88l-0.61,0.46c-0.23,0.17-0.39,0.42-0.46,0.69l-0.31,1.2l-1.22,1.76l-0.86,0.47l-0.59-0.2c-0.23-0.08-0.49-0.09-0.72-0.03
                  l-1.3,0.35c-0.17,0.05-0.33,0.13-0.47,0.24l-0.73,0.59l-0.52,0.01l-2.34-1.1c-0.08-0.03-0.16-0.06-0.24-0.08l-1.19-0.27l-1.27-0.35
                  c-0.34-0.09-0.71-0.03-1.01,0.16l-0.85,0.56l-0.56,0.09l-0.79-0.48c-0.53-0.31-1.2-0.19-1.59,0.27l-0.81,0.96
                  c-0.16,0.18-0.26,0.4-0.29,0.64l-0.12,0.89l-0.65,0.67c-0.17,0.18-0.29,0.41-0.33,0.65l-0.02,0.12l-0.12-0.14l-0.38-0.95
                  c-0.18-0.44-0.59-0.74-1.07-0.78l-1.08-0.07l-1.15-0.41l-1.89-0.49l-0.36-0.9c-0.12-0.28-0.33-0.51-0.61-0.64l-1.17-0.58
                  c-0.18-0.09-0.37-0.12-0.58-0.13l-1.27,0.03c-0.03,0-0.07,0-0.1,0.01l-1.19,0.13l-1.28-0.1c-0.17-0.01-0.34,0.01-0.5,0.07
                  l-1.11,0.39l-1.15,0.2l-1.74-0.54c-0.08-0.03-0.16-0.04-0.24-0.05l-1.16-0.13l-1.04-0.55c-0.23-0.13-0.5-0.18-0.77-0.14l-1.05,0.15
                  l-0.6-0.1l-3.32-3.27c-0.03-0.03-0.05-0.05-0.08-0.07l-0.95-0.79c-0.19-0.16-0.41-0.25-0.66-0.28l-1.28-0.15l-1.47-0.38
                  c-0.3-0.08-0.62-0.04-0.88,0.1l-0.64-0.78c-0.02-0.03-0.05-0.06-0.08-0.09L29,530.37c-0.2-0.19-0.45-0.32-0.72-0.35l-3.12-0.35
                  c-0.07-0.01-0.14-0.01-0.21-0.01l-1.12,0.07l-0.94-0.17l-0.36-0.22l-0.05-0.7c-0.02-0.22-0.09-0.44-0.22-0.62l-0.81-1.18
                  c-0.03-0.04-0.06-0.08-0.09-0.11l-0.9-1.04l-0.98-1.48l-0.52-0.98l-0.1-0.98c-0.01-0.18-0.07-0.35-0.16-0.51l-0.68-1.17
                  c-0.04-0.07-0.09-0.14-0.15-0.2l-0.62-0.69l-0.17-1.06l-0.33-1.4c-0.03-0.12-0.07-0.23-0.13-0.33l-0.6-1.07l-0.12-0.75l0.22-0.53
                  l0.73-0.57l1.07-0.71c0.15-0.1,0.28-0.23,0.38-0.38l0.75-1.23c0.04-0.07,0.08-0.15,0.11-0.24l0.42-1.21c0.17-0.5,0.01-1.05-0.4-1.38
                  l-0.97-0.78c-0.11-0.1-0.25-0.17-0.39-0.22l-1.07-0.35l-0.85-0.55l-1.02-0.75c-0.14-0.1-0.31-0.18-0.49-0.21l-0.85-0.18l-0.68-0.66
                  c-0.09-0.1-0.2-0.17-0.32-0.23l-1.24-0.61c-0.12-0.06-0.25-0.1-0.39-0.12l-1.02-0.13l-0.39-0.28l-0.01-0.04l0.43-0.39
                  c0.15-0.14,0.27-0.32,0.34-0.52l0.42-1.2c0.19-0.54-0.01-1.13-0.49-1.45l-1.08-0.72c-0.31-0.21-0.71-0.26-1.07-0.15l-0.78,0.25
                  l-0.75-0.3c-0.21-0.08-0.43-0.1-0.66-0.06l-2.28,0.41l-0.71-0.23l-0.48-0.42l-0.14-0.57l0.2-0.96c0.04-0.23,0.02-0.47-0.06-0.69
                  l-0.92-2.45l0.47-2.11c0.06-0.27,0.03-0.56-0.09-0.81l-0.55-1.17c-0.03-0.07-0.07-0.14-0.11-0.2l-1.11-1.54L0,488.17l0.34,0.06
                  l1.1,0.46c0.32,0.14,0.68,0.13,0.99-0.01l1.16-0.52c0.45-0.2,0.74-0.65,0.74-1.14l-0.01-2.29l0.59-0.82
                  c0.04-0.06,0.07-0.12,0.1-0.19l0.62-1.28c0.13-0.26,0.16-0.55,0.09-0.83l-0.38-1.57c-0.02-0.1-0.05-0.18-0.09-0.27l-0.59-1.18
                  l-0.3-1.04l0.06-0.9l0.46-0.66l1.51-1.03c0.09-0.06,0.17-0.13,0.24-0.21l0.82-0.95c0.35-0.41,0.4-1,0.12-1.46l-1.55-2.55l-1.01-2.35
                  l-0.37-1.04l-0.13-1.31l-0.15-2.64c0-0.04-0.01-0.07-0.01-0.11l-0.1-0.66l0.07-0.07l0.64,0.03c0.19,0.01,0.38-0.03,0.55-0.1
                  l1.14-0.49c0.31-0.13,0.55-0.39,0.68-0.7l0.46-1.19c0.19-0.49,0.04-1.06-0.36-1.4l-0.44-0.37l0.03-0.3l0.82-1.34l0.68-0.08
                  c0.24-0.02,0.47-0.12,0.66-0.28l1.04-0.87c0.2-0.17,0.34-0.39,0.41-0.63l0.28-1.07l0.56-0.97c0.02-0.02,0.04-0.05,0.05-0.09
                  l0.07-0.15l0.14,0.04c0.13,0.05,0.26,0.07,0.39,0.07l1.25,0.01c0.29,0,0.65-0.13,0.89-0.36c0.23-0.23,0.37-0.55,0.37-0.88l0.01-1.02
                  l0.47-0.66l0.76-0.88c0.27-0.32,0.37-0.75,0.26-1.15l-0.34-1.25c-0.07-0.26-0.23-0.49-0.44-0.65l-0.39-0.31l0.05-0.23l0.64-0.67
                  l0.83-0.52l0.41-0.04l0.38,0.46c0.1,0.12,0.23,0.23,0.36,0.3l1.15,0.63c0.37,0.21,0.83,0.21,1.2,0.01l1.11-0.61
                  c0.55-0.31,0.79-0.97,0.56-1.56l-0.47-1.18l-0.53-2.73l-0.02-0.88l0.05-0.1l0.4,0.03c0.6,0.05,1.16-0.33,1.32-0.92l0.34-1.3
                  c0.06-0.22,0.05-0.44-0.01-0.65l1.98-0.41l1.23,0.02c0.1,0,0.21-0.01,0.31-0.03l1.35-0.32c0.37-0.09,0.68-0.34,0.84-0.68
                  s0.16-0.74,0-1.08l-0.48-0.98l-0.06-0.54l0.83-1.07l0.84-0.73c0.2-0.18,0.34-0.42,0.39-0.68l0.02-0.09l0.8-0.3l0.96-0.25l1.01,0.15
                  c0.11,0.01,0.22,0.02,0.33,0l1.4-0.17c0.37-0.04,0.7-0.25,0.9-0.57c0.2-0.31,0.25-0.7,0.14-1.05l-0.66-2.03h0.41l1.23-0.1
                  c0.35-0.03,0.67-0.2,0.88-0.48l0.64-0.81l0.65-0.37l1.66-0.18l5.25-0.43l3.71-0.35c0.04,0,0.09-0.01,0.13-0.02l2.52-0.54l1.83,0.1
                  l0.94,0.42c0.27,0.12,0.57,0.14,0.86,0.06l2.01-0.58c0.34-0.1,0.63-0.34,0.78-0.67c0.16-0.33,0.16-0.71,0.01-1.04l-0.51-1.16
                  l0.92,0.27c0.04,0.01,0.08,0.02,0.13,0.03l1.3,0.24l1.16,0.35c0.09,0.03,0.17,0.05,0.26,0.06l1.33,0.11l1.08,0.21l1.19,0.43
                  c0.28,0.1,0.59,0.09,0.86-0.01l1.24-0.47c0.16-0.06,0.3-0.15,0.42-0.26l0.79-0.75l0.24-0.12l1.38,1.06l0.87,0.79l0.33,0.89
                  c0.05,0.15,0.12,0.28,0.22,0.39l0.72,0.84l0.51,1.04c0.14,0.28,0.38,0.5,0.67,0.62l1.24,0.48c0.28,0.1,0.58,0.11,0.86,0.01
                  l1.63-0.57l1.05-0.25l1.24-0.08c0.21-0.02,0.41-0.08,0.59-0.19l0.86-0.55l0.88-0.21l1.12,0.04c0.2,0.01,0.41-0.04,0.59-0.12
                  l2.37-1.19c0.11-0.05,0.21-0.12,0.3-0.21l0.62-0.6l0.91-0.09l2.53-0.48c0.04-0.01,0.09-0.02,0.14-0.03l2.42-0.8
                  c0.09-0.03,0.17-0.06,0.24-0.11l1.18-0.68c0.08-0.04,0.16-0.09,0.22-0.15l0.7-0.64l0.8-0.08c0.06,0,0.11-0.01,0.16-0.02l1.22-0.28
                  c0.09-0.02,0.19-0.06,0.27-0.1l1.05-0.52l1-0.35l0.9-0.07l1.04,0.21c0.05,0.02,0.1,0.02,0.16,0.03l1.47,0.11
                  c0.45,0.04,0.86-0.17,1.11-0.53c0.26-0.36,0.3-0.83,0.12-1.23l-0.37-0.83l0.16-0.96c0-0.05,0.01-0.09,0.01-0.14l0.07-1.4l0.15-0.45
                  l0.61-0.23c0.09-0.03,0.17-0.07,0.25-0.12l0.98-0.63l1.11-0.4c0.04-0.02,0.08-0.03,0.11-0.05l2.49-1.17l1.56-0.63
                  c0.37-0.15,0.65-0.47,0.74-0.85l0.3-1.19c0.02-0.06,0.03-0.13,0.03-0.19l0.16-1.62l0.22-0.82l0.62-0.55l1.03-0.75
                  c0.04-0.03,0.08-0.06,0.12-0.09l0.81-0.75l0.75-0.47l2.54-0.03c0.48,0,0.93-0.29,1.13-0.73l7.79-16.89l0.79,0.34l0.17,0.67
                  c0.06,0.27,0.22,0.51,0.44,0.69l0.98,0.76c0.19,0.14,0.42,0.23,0.65,0.25l1.5,0.14c0.15,0.02,0.3,0.01,0.44-0.03l0.58-0.15
                  l0.26,0.27l0.58,1.05l0.61,1l0.4,1.04c0.06,0.14,0.14,0.28,0.25,0.39l0.83,0.91c0.03,0.04,0.07,0.08,0.11,0.12l0.68,0.57l0.31,1.03
                  c0.02,0.04,0.03,0.09,0.05,0.13l0.44,1.04l0.28,2.34c0,0.05,0.01,0.1,0.03,0.15l0.27,1.14L138.63,397.14z"/>
                <title>Malacky</title>
              </Link>


              {/* Pezinok - bratislavsky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Pezinok")}>
                <path style={{ fill: crimesInRegionBratislavsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionBratislavsky[6].crimePercent)] ? crimesInRegionBratislavsky[6].color : "#D3D3D3" }}
                  d="M164.58,499.38l-0.41,0.92c-0.07,0.15-0.11,0.31-0.11,0.48l-0.04,1.5l-0.16,0.79l-0.61,0.63
                  c-0.03,0.04-0.07,0.08-0.1,0.12l-0.8,1.06c-0.06,0.08-0.11,0.17-0.15,0.27l-0.52,1.25c-0.03,0.05-0.04,0.11-0.06,0.16l-0.31,1.18
                  l-0.42,0.52l-0.76,0.14c-0.36,0.07-0.66,0.29-0.85,0.6l-0.69,1.17l-0.69,1.31c-0.23,0.45-0.17,1,0.15,1.38l0.8,0.95
                  c0.06,0.07,0.13,0.13,0.2,0.19l0.95,0.73l0.29,0.3l-0.2,0.49c-0.19,0.46-0.09,0.99,0.24,1.34l-0.32,0.3l-3.13,2.3l-1.32,1.07
                  l-1.11,0.98c-0.04,0.04-0.07,0.07-0.11,0.11l-0.91,1.03l-0.59,0.47l-0.54-0.05l-0.77-0.4l-0.89-0.75c-0.03-0.02-0.06-0.05-0.09-0.07
                  l-2.15-1.47c-0.39-0.26-0.88-0.28-1.29-0.06l-0.51,0.27l-0.52-0.29c-0.41-0.22-0.9-0.2-1.29,0.06l-2.17,1.46
                  c-0.15,0.1-0.27,0.24-0.36,0.39l-0.64,1.07c-0.26,0.42-0.24,0.95,0.05,1.36l0.21,0.3l-0.94,0.68c-0.1,0.07-0.19,0.15-0.26,0.24
                  l-0.66,0.8l-0.94,0.58c-0.03,0.02-0.06,0.04-0.09,0.06l-1.42,1.09l-1.63-0.64l-1.31-0.57c-0.28-0.12-0.59-0.13-0.88-0.04l-1.3,0.41
                  c-0.4,0.12-0.71,0.44-0.82,0.84l-0.36,1.25c-0.03,0.12-0.05,0.23-0.05,0.35l-0.01,2.77l0.05,1.22l-0.16,2.4l-0.16,0.58l-0.81,0.33
                  c-0.1,0.04-0.18,0.09-0.26,0.15l-1.08,0.78c-0.08,0.05-0.16,0.12-0.22,0.2l-0.99,1.15l-1.02,0.76l-1,0.86l-0.78,0.76l-0.76,0.37
                  l-2.24,0.29l-2.07,0.16l-0.75-0.47l-0.84-0.75c-0.19-0.17-0.43-0.28-0.68-0.31l-1.22-0.15c-0.35-0.04-0.69,0.06-0.96,0.29l-0.6,0.51
                  l-0.78-0.01c-0.07,0-0.13,0-0.19,0.01l-2.52,0.4l-0.78-0.05l-0.76-0.6c-0.53-0.43-1.31-0.35-1.74,0.17l-1.57,1.96l-0.95,0.8
                  c-0.07,0.06-0.13,0.12-0.18,0.18l-0.86,1.09l-0.64,0.7l-0.29,0.11l-1.59-1.04c-0.4-0.26-0.91-0.27-1.32-0.02l-1.3,0.79
                  c-0.24,0.15-0.43,0.38-0.53,0.64l-0.43,1.2c-0.04,0.12-0.07,0.25-0.07,0.39l-0.03,1.24c-0.01,0.44,0.22,0.86,0.59,1.09l0.87,0.54
                  l1.28,1.91c0.07,0.1,0.16,0.2,0.27,0.28l0.98,0.77c0.05,0.04,0.11,0.08,0.17,0.12l0.75,0.41l0.09,0.18l-0.1,0.04
                  c-0.14,0.04-0.27,0.12-0.38,0.21l-0.61,0.49l-0.41-0.03l-1.08-1.11l-0.68-1.13l-0.72-1.04c-0.03-0.04-0.06-0.09-0.1-0.13l-0.93-1.02
                  l-1.45-1.48c-0.04-0.05-0.09-0.1-0.15-0.14l-1.93-1.47l-0.92-0.84l-0.77-0.9c-0.06-0.06-0.12-0.12-0.19-0.17l-2.01-1.57
                  c-0.03-0.03-0.07-0.06-0.12-0.09l-1.1-0.69c-0.04-0.03-0.08-0.05-0.13-0.07l-2.11-0.98l-0.67-0.57l-0.53-0.87
                  c-0.13-0.22-0.32-0.39-0.55-0.5l-1.13-0.52c-0.56-0.25-1.24-0.06-1.57,0.47l-0.57,0.88l-0.4,0.34l-0.54-0.07l-0.44-0.17l-0.15-0.59
                  c-0.09-0.34-0.31-0.63-0.62-0.79c-0.13-0.07-0.28-0.12-0.44-0.13c0.14-0.09,0.26-0.2,0.35-0.33l1.53-2.19
                  c0.08-0.12,0.14-0.26,0.18-0.4l0.25-0.99l0.69-0.52c0.27-0.2,0.45-0.51,0.49-0.85l0.12-1.02l0.6-0.67c0.2-0.24,0.32-0.54,0.31-0.86
                  l-0.02-1.11l0.36-0.88l0.56-0.95l0.73-0.99c0.28-0.37,0.33-0.86,0.13-1.28l-0.69-1.48c-0.04-0.1-0.1-0.19-0.17-0.27l-0.85-1.02
                  l-0.49-0.92l-0.62-2.66c-0.01-0.05-0.02-0.09-0.03-0.13l-0.34-1.02l0.05-0.62l0.6-0.8c0.11-0.14,0.18-0.3,0.22-0.47l0.27-1.18
                  l0.31-1.07l0.13-0.17l0.39,0.11c0.06,0.02,0.13,0.03,0.2,0.04l1.15,0.14l1.03,0.37c0.5,0.17,1.06,0.02,1.39-0.4l0.78-0.97
                  c0.04-0.05,0.08-0.1,0.11-0.16l1.17-2.09l0.84-0.6c0.26-0.18,0.44-0.46,0.5-0.77l0.27-1.28c0.02-0.13,0.03-0.26,0.01-0.39L92,508.4
                  c-0.02-0.23-0.11-0.44-0.25-0.63l-0.65-0.82l0.04-0.24l0.58-0.49c0.11-0.1,0.2-0.21,0.27-0.34l0.31-0.55l0.7-0.1
                  c0.55-0.08,0.98-0.51,1.06-1.06l0.18-1.21c0.04-0.32-0.04-0.65-0.23-0.91l-0.66-0.92l-0.78-1.8l0.13-0.65l0.45-0.86l0.6-1l0.83-0.8
                  c0.41-0.39,0.51-1,0.24-1.5l-0.52-0.96l0.29-0.68c0.02-0.04,0.03-0.08,0.05-0.13l0.23-0.76l0.68-0.38c0.09-0.05,0.18-0.11,0.26-0.19
                  l1.05-1.01l0.69-0.56l0.81-0.07l1.27,0.03c0.09,0,0.18-0.01,0.27-0.02l1.37-0.27c0.08-0.02,0.15-0.04,0.22-0.07l0.99-0.39l0.67-0.07
                  l0.64,0.35l0.88,0.78c0.32,0.29,0.78,0.39,1.2,0.27c0.42-0.13,0.74-0.47,0.84-0.9l0.3-1.2c0.03-0.09,0.04-0.19,0.04-0.28l0.02-1.07
                  l0.33-1.11c0.09-0.33,0.06-0.68-0.11-0.97l-0.36-0.65l0.26-0.57l0.74-0.95l0.7-1.03c0.07-0.1,0.12-0.2,0.16-0.32l0.32-0.99
                  l0.66-0.73c0.21-0.23,0.32-0.54,0.32-0.85l-0.01-1.29c0-0.43-0.22-0.82-0.58-1.05l-0.72-0.45l-0.05-0.14l0.3-0.4l0.81-0.83
                  c0.33-0.34,0.44-0.83,0.28-1.28l-0.19-0.56l0.33-0.34c0.09-0.1,0.17-0.21,0.23-0.33l0.55-1.13c0.06-0.12,0.1-0.23,0.11-0.36
                  l2.76,0.58c0.51,0.1,1.03-0.13,1.31-0.58l0.61-0.99l0.77-0.98l1.28-1.04c0.12-0.09,0.22-0.2,0.29-0.33l0.74-1.24
                  c0.07-0.1,0.11-0.22,0.14-0.34l0.28-1.1l0.61-0.81c0.14-0.19,0.23-0.41,0.25-0.64l0.08-0.99l1.37-1.67l0.86-0.58l0.74-0.28l0.2,0.21
                  l0.54,0.97c0.22,0.4,0.63,0.63,1.1,0.64l5.93-0.12l3.33,3.05l0.59,0.98c0.13,0.23,0.34,0.41,0.6,0.52l1.24,0.51
                  c0.16,0.07,0.35,0.1,0.53,0.09l1.25-0.06l2.46-0.05l1.81,0.03l0.21,0.37l0.04,0.12l-1.31,0.61c-0.27,0.13-0.49,0.34-0.62,0.62
                  l-0.52,1.13c-0.14,0.31-0.15,0.66-0.02,0.98l0.48,1.22c0.01,0.03,0.02,0.06,0.04,0.09l0.54,1.1c0.07,0.15,0.17,0.28,0.3,0.39
                  l0.94,0.82c0.22,0.19,0.5,0.3,0.79,0.31l1.21,0.03l0.75,0.08l0.32,0.31l0.08,0.38l-0.4,0.52c-0.31,0.4-0.35,0.96-0.09,1.39
                  l0.66,1.12c0.2,0.34,0.54,0.56,0.93,0.61l1.12,0.14l0.42,0.2l-0.44,0.41c-0.47,0.44-0.53,1.16-0.15,1.67
                  c0.34,0.46,0.95,0.62,1.47,0.41l0.17,0.82c0.03,0.15,0.08,0.29,0.16,0.41l0.97,1.51l-0.26,0.48c-0.2,0.39-0.19,0.86,0.05,1.24
                  l0.64,1.04c0.09,0.14,0.21,0.27,0.35,0.37l0.66,0.45l-0.26,0.2c-0.38,0.29-0.57,0.77-0.48,1.24l0.25,1.29
                  c0.06,0.27,0.2,0.51,0.4,0.7l0.95,0.84c0.07,0.06,0.15,0.11,0.23,0.16l1.12,0.61c0.19,0.1,0.4,0.15,0.62,0.15l1.31-0.02
                  c0.27,0,0.53-0.1,0.75-0.26l0.67-0.53l0.81-0.01c0.1,0,0.19-0.01,0.29-0.04l0.36-0.09l0.19,0.28c0.02,0.04,0.05,0.07,0.07,0.1
                  l1.5,1.87l0.77,1.09l0.58,0.72l0.19,0.98c0.06,0.29,0.22,0.55,0.44,0.73l1.5,1.23L164.58,499.38z"/>
                <title>Pezinok</title>
              </Link>

              {/* Senec - bratislavsky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Senec")}>
                <path style={{ fill: crimesInRegionBratislavsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionBratislavsky[7].crimePercent)] ? crimesInRegionBratislavsky[7].color : "#D3D3D3" }}
                  d="M176.96,559.96c-0.22,0.18-0.39,0.43-0.45,0.73l-0.69,3.19l-0.72,2.71l-0.62,0.75l-0.48-0.12
                  c-0.54-0.13-1.1,0.12-1.38,0.6l-0.59,1.05l-0.39,0.39l-0.33-0.18c-0.09-0.05-0.2-0.09-0.31-0.12l-2.51-0.55
                  c-0.33-0.07-0.67-0.01-0.94,0.17l-1.06,0.69c-0.47,0.31-0.68,0.88-0.52,1.41l0.33,1.11l0.09,0.75l-0.01,0.02l-0.53-0.11
                  c-0.54-0.1-1.09,0.16-1.34,0.64l-1.13,2.11l-0.1,0.09l-0.3-0.17c-0.34-0.19-0.75-0.21-1.11-0.06l-1.36,0.58
                  c-0.21,0.09-0.39,0.23-0.52,0.42l-0.71,0.96l-1.68,1.76l-0.89,0.15c-0.04,0-0.07,0.01-0.11,0.02l-1.03,0.27l-0.5-0.19l-0.95-1.25
                  c-0.02-0.02-0.05-0.05-0.07-0.07l-0.95-1.06c-0.11-0.13-0.25-0.23-0.4-0.3l-1.44-0.67c-0.3-0.14-0.64-0.16-0.95-0.05l-1.26,0.45
                  l-1.39,0.2l-0.59-0.15l-0.13-0.17l0.1-0.29l0.55-0.48l1.05-0.38c0.33-0.12,0.6-0.38,0.73-0.7l0.55-1.32
                  c0.22-0.52,0.06-1.13-0.39-1.48l-0.83-0.62l-0.75-1.39c-0.12-0.22-0.3-0.4-0.51-0.51l-1.19-0.63c-0.37-0.2-0.81-0.2-1.18,0
                  c-0.36,0.19-0.61,0.55-0.65,0.96l-0.04,0.37l-0.12,0.01l-1.79-0.21c-0.12-0.01-0.24-0.01-0.36,0.01l-1.37,0.23
                  c-0.34,0.05-0.65,0.25-0.84,0.53c-0.19,0.29-0.26,0.64-0.18,0.98l0.14,0.62l-0.47,0.57c-0.19,0.23-0.29,0.52-0.29,0.82l0.02,1.63
                  c0,0.11,0.02,0.22,0.05,0.33c-0.42,0.17-0.73,0.55-0.79,1l-0.17,1.27c-0.09,0.65,0.34,1.26,0.98,1.39l0.41,0.09l0.19,1.5
                  c0.06,0.45,0.36,0.84,0.79,1.01l1.19,0.45l0.03,0.03l-0.2,0.25c-0.05,0.07-0.1,0.14-0.14,0.22l-0.65,1.3l-0.57,0.78l-2.29-0.91
                  c-0.58-0.22-1.23,0.01-1.54,0.55l-0.59,1.05l-0.91,1.25l-2.89,1.37c-0.07,0.03-0.15,0.08-0.22,0.13l-0.98,0.76
                  c-0.05,0.05-0.1,0.09-0.15,0.14l-0.46,0.5l-1.87-0.37c-0.23-0.04-0.47-0.02-0.69,0.06l-0.77,0.3l-1.29-0.41l-1.12-0.54
                  c-0.15-0.07-0.31-0.11-0.47-0.12l-1.51-0.08c-0.14-0.01-0.26,0.01-0.39,0.04l-1.42,0.37c-0.44,0.12-0.79,0.47-0.89,0.91
                  c-0.11,0.45,0.03,0.92,0.38,1.23l0.96,0.87c0.03,0.02,0.06,0.05,0.09,0.07l0.97,0.73l-1.43,5.4c-0.17,0.65,0.2,1.32,0.85,1.52
                  l1.98,0.6l0.9,1.22l1.58,1.9c0.4,0.48,1.1,0.59,1.63,0.25l3.4-2.16l0.57,0.8c0.02,0.04,0.05,0.07,0.08,0.1l-0.58,0.79l-1.25,0.28
                  c-0.29,0.06-0.54,0.23-0.72,0.46l-0.78,1.02c-0.09,0.12-0.16,0.24-0.2,0.38l-1.04,3.28l-0.34-0.14c-0.58-0.23-1.25,0-1.56,0.55
                  l-1.51,2.69c-0.04-0.05-0.08-0.09-0.13-0.13l-1.3-1.1c-0.09-0.08-0.2-0.15-0.31-0.2l-1.28-0.56c-0.52-0.22-1.13-0.07-1.48,0.38
                  l-0.82,1.04c-0.23,0.28-0.32,0.65-0.25,1.01l0.33,1.67l0.27,1.72c0.01,0.12,0.05,0.23,0.09,0.33l0.54,1.17
                  c0.04,0.09,0.09,0.17,0.15,0.24l0.47,0.61l-0.03,0.2l-0.32,0.25l-4.6,0.99c-0.38,0.08-0.69,0.33-0.86,0.68l-1.31,2.72l-1.78,2.42
                  l-0.54,0.28l-0.09-0.16c-0.29-0.47-0.85-0.71-1.39-0.57l-1.41,0.36c-0.39,0.1-0.71,0.38-0.86,0.76c-0.14,0.37-0.1,0.8,0.12,1.13
                  l0.93,1.43l-0.7,0.97l-0.06-0.22c-0.05-0.17-0.14-0.33-0.26-0.47l-0.91-1.05c-0.07-0.08-0.15-0.15-0.24-0.21l-0.64-0.79l-0.93-0.97
                  l-0.73-0.72l-0.53-1.07c-0.02-0.03-0.04-0.06-0.05-0.09l-1.32-2.21c-0.06-0.12-0.15-0.23-0.26-0.32l-0.97-0.85
                  c-0.06-0.06-0.13-0.1-0.2-0.15l-1.09-0.62c-0.05-0.02-0.1-0.05-0.15-0.07l-1.16-0.47l-1.06-0.52l-1.03-0.6l-1.1-0.78l-1.12-0.91
                  c-0.04-0.03-0.09-0.07-0.14-0.09l-0.48-0.3l0.1-1.01l0.28-0.63l1-0.04c0.57-0.03,1.06-0.45,1.17-1.01l0.23-1.2
                  c0.01-0.06,0.01-0.11,0.02-0.16l0.07-1.18l0.13-1.1l0.28-0.57l0.87-0.43c0.18-0.09,0.35-0.22,0.47-0.4l0.83-1.17
                  c0.18-0.25,0.26-0.55,0.22-0.85l-0.13-1.23c-0.02-0.26-0.13-0.5-0.31-0.7l-0.41-0.47l0.18-0.65l0.66-1.1l0.82-0.59
                  c0.25-0.18,0.43-0.44,0.5-0.74l0.28-1.21c0.03-0.16,0.04-0.32,0.01-0.48l-0.13-0.81l0.22-0.41l0.86-0.51l1.25-0.83l1.05-0.82
                  l0.74-0.65l0.95-0.14l0.71,0.06l0.7,0.53c0.08,0.06,0.18,0.12,0.28,0.16l2.46,0.97l0.8,0.48l0.75,0.86c0.11,0.13,0.25,0.23,0.4,0.31
                  l1.06,0.5l1.08,0.6c0.6,0.34,1.36,0.12,1.7-0.48l0.86-1.53c0.02-0.03,0.03-0.06,0.05-0.09l0.82-1.78c0.04-0.09,0.07-0.19,0.09-0.29
                  l0.59-3.08c0.07-0.36-0.02-0.73-0.25-1.02c-0.11-0.14-0.25-0.25-0.4-0.33c0.02-0.19,0-0.39-0.06-0.57l-0.49-1.36
                  c-0.21-0.59-0.82-0.93-1.43-0.8l-1.13,0.23l-0.98-0.24l-0.68-0.39l-0.36-1.15l-0.65-1.74c-0.05-0.12-0.11-0.24-0.19-0.34l-0.77-0.96
                  c-0.13-0.16-0.29-0.28-0.47-0.36l-1.4-0.62c-0.11-0.05-0.22-0.08-0.33-0.1l-1.75-0.26l-0.15-0.48c-0.05-0.16-0.14-0.3-0.25-0.43
                  l-0.39-0.45l0.24-0.95c0.04-0.13,0.05-0.27,0.04-0.41l-0.14-1.73c-0.03-0.32-0.17-0.61-0.41-0.82l-0.52-0.48l-0.11-2.07
                  c-0.02-0.19-0.08-0.38-0.17-0.55l-0.34-0.56l0.76-0.88c0.3-0.35,0.38-0.84,0.22-1.27c-0.17-0.43-0.56-0.73-1.02-0.79l-0.93-0.12
                  l-0.69-0.57l-0.68-0.77l-0.42-0.98c-0.03-0.07-0.06-0.13-0.1-0.19l-0.34-0.52l0.22-0.54l0.68-0.99c0.14-0.21,0.22-0.45,0.22-0.7
                  l0.01-1.25c0-0.05,0-0.1-0.01-0.14l-0.07-0.65l0.56-0.38c0.23-0.15,0.4-0.38,0.49-0.64l0.39-1.2c0.07-0.21,0.08-0.43,0.04-0.64
                  l-0.19-0.97c0.1-0.04,0.2-0.1,0.29-0.17l0.81-0.65l1.25-0.43c0.35-0.11,0.62-0.37,0.76-0.71c0.13-0.33,0.12-0.71-0.04-1.03
                  l-0.91-1.81c-0.12-0.23-0.3-0.41-0.52-0.54l-1-0.55l-0.74-0.58l-1.32-1.97c-0.1-0.15-0.23-0.27-0.38-0.36l-0.49-0.31v-0.32
                  l0.22-0.58l0.25-0.16l1.4,0.95c0.34,0.23,0.77,0.28,1.15,0.14l1.19-0.45c0.18-0.07,0.34-0.18,0.47-0.32l0.9-0.98l0.84-1.06
                  l0.96-0.81c0.07-0.06,0.13-0.13,0.19-0.2l0.81-1.04l0.1,0.07c0.2,0.17,0.44,0.26,0.7,0.28l1.33,0.09c0.1,0,0.2,0,0.29-0.02l2.51-0.4
                  l1.15,0.01c0.3-0.02,0.59-0.1,0.82-0.3l0.53-0.45l0.29,0.03l0.63,0.56c0.05,0.05,0.11,0.09,0.17,0.13l1.18,0.74
                  c0.22,0.14,0.48,0.21,0.74,0.19l2.56-0.19l2.54-0.33c0.13-0.02,0.25-0.06,0.37-0.12l1.12-0.55c0.12-0.06,0.23-0.13,0.32-0.23
                  l0.86-0.84l0.87-0.74l1.07-0.79c0.08-0.06,0.15-0.12,0.21-0.2l0.98-1.14l0.84-0.61l1.23-0.5c0.36-0.15,0.63-0.45,0.73-0.82
                  l0.37-1.29c0.03-0.08,0.04-0.17,0.05-0.26l0.17-2.6v-0.13l-0.04-3.78l0.13-0.44l0.21-0.07l3.23,1.33c0.42,0.17,0.9,0.1,1.26-0.18
                  l1.9-1.48l1.06-0.66c0.11-0.07,0.22-0.16,0.3-0.26l0.68-0.81l0.89-0.6c0.12-0.08,0.22-0.18,0.3-0.3l0.75-1
                  c0.31-0.43,0.32-1.02,0.01-1.46l-0.26-0.37l0.08-0.15l1.28-0.86l0.46,0.25c0.37,0.2,0.82,0.2,1.19,0l0.44-0.23l1.45,1l0.95,0.79
                  c0.06,0.05,0.14,0.1,0.21,0.14l1.11,0.59c0.15,0.08,0.32,0.13,0.49,0.15l1.28,0.1c0.31,0.02,0.62-0.07,0.87-0.26l1.07-0.83
                  c0.06-0.05,0.11-0.1,0.17-0.16l0.94-1.06l1.01-0.9l1.22-0.99l3.17-2.33c0.04-0.03,0.07-0.06,0.11-0.09l0.9-0.85l-0.43-0.45
                  c0.16,0.03,0.32,0.03,0.48-0.01l0.21-0.04l0.65,1.52l0.57,1.12c0.07,0.14,0.17,0.27,0.3,0.38l0.96,0.82
                  c0.03,0.03,0.06,0.05,0.09,0.07l0.96,0.68l0.83,0.82c0.03,0.04,0.07,0.08,0.11,0.11l0.98,0.76c0.07,0.05,0.14,0.09,0.21,0.13
                  l0.93,0.46l0.69,0.77c0.46,0.5,1.23,0.55,1.74,0.11l0.26-0.22l0.59,0.94c0.03,0.04,0.06,0.09,0.09,0.13l1.27,1.57l-2.33,3.25
                  l-1.52,1.99l-0.76,0.89l-0.84,0.81c-0.02,0.02-0.05,0.05-0.07,0.08l-0.84,0.96c-0.02,0.02-0.05,0.05-0.07,0.08l-0.73,0.99
                  c-0.04,0.06-0.08,0.12-0.11,0.19l-0.57,1.16c-0.03,0.05-0.05,0.1-0.06,0.15l-0.9,2.51c-0.07,0.19-0.09,0.39-0.07,0.58l0.1,0.92
                  l-0.51,0.87c-0.09,0.15-0.14,0.32-0.16,0.5l-0.14,1.3c-0.06,0.57,0.27,1.1,0.81,1.3l1.06,0.4l0.96,0.61
                  c0.23,0.14,0.5,0.21,0.78,0.19l1.16-0.11l0.93,0.19l4.03,1.62c0.04,0.02,0.08,0.03,0.12,0.04l1.33,0.38l-3.65,5.18
                  c-0.1,0.13-0.16,0.29-0.2,0.45l-0.29,1.31c-0.04,0.2-0.04,0.4,0.01,0.6l0.32,1.19c0.09,0.32,0.31,0.6,0.6,0.76l1.1,0.61
                  c0.15,0.08,0.31,0.13,0.48,0.15l1.3,0.14c0.08,0.01,0.16,0.01,0.24,0.01l1.23-0.11C176.86,559.98,176.91,559.97,176.96,559.96z"/>
                <title>Senec</title>
              </Link>

              {/* kosice - kosicky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Košice")}>
                <path style={{ fill: crimesInRegionKosicky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionKosicky[1].crimePercent)] ? crimesInRegionKosicky[1].color : "#D3D3D3" }}
                  d="M1146.46,356.99l-0.08,0.29c-0.03,0.1-0.05,0.2-0.05,0.3l-0.02,1.03l-0.4,0.87l-2.17,3.65l-1.3,1.62l-1.87,1.7
                  l-0.9,0.95c-0.04,0.05-0.09,0.1-0.12,0.16l-0.91,1.33c-0.07,0.11-0.13,0.24-0.17,0.37l-1.17,4.27c-0.16,0.56,0.08,1.15,0.59,1.44
                  l1.19,0.67l1.76,1.78c0.2,0.2,0.45,0.32,0.72,0.36l1.42,0.19l0.79,0.52c0.12,0.08,0.25,0.14,0.38,0.17l1.17,0.3l0.07,0.46l0.08,1.63
                  c0.01,0.1,0.02,0.2,0.05,0.3l0.06,0.21l-0.7-0.27c-0.25-0.09-0.52-0.11-0.78-0.04l-1.22,0.33c-0.15,0.04-0.29,0.11-0.42,0.21
                  l-1.42,1.07l-1.74,1.18c-0.45,0.3-0.65,0.86-0.5,1.38l0.22,0.75l-0.02,0.01c-0.52,0.11-0.91,0.54-0.98,1.06l-0.16,1.25
                  c-0.04,0.34,0.05,0.67,0.26,0.94l3.45,4.38l-0.23,0.83c-0.04,0.17-0.05,0.35-0.03,0.52l0.16,1.03l-0.3,0.99
                  c-0.03,0.09-0.05,0.17-0.06,0.26l-0.1,1.27c-0.03,0.28,0.04,0.57,0.2,0.8l0.56,0.84l0.13,0.95c0.01,0.08,0.03,0.16,0.06,0.23
                  l0.46,1.36l0.52,2.75c0.04,0.16,0.1,0.31,0.18,0.44l0.67,1.03l0.12,0.97c0,0.04,0.01,0.09,0.02,0.14l0.39,1.65
                  c0.02,0.07,0.04,0.14,0.07,0.21l0.05,0.12l-3.62,2.02l-8.85-3.85l-0.97-1.34l-0.49-0.71l0.13-0.13c0.38-0.34,0.5-0.89,0.31-1.36
                  l-0.54-1.39c-0.05-0.13-0.12-0.25-0.21-0.35l-0.79-0.93l0.01-0.82l0.25-1.13c0.07-0.31,0.02-0.63-0.14-0.91l-0.48-0.81l0.07-1.01
                  l0.28-1.41c0.09-0.46-0.09-0.93-0.45-1.22l-0.91-0.72l-0.7-0.86c-0.21-0.27-0.51-0.43-0.85-0.46l-4.55-0.48
                  c-0.05-0.01-0.1-0.01-0.15-0.01l-1.63,0.03l-3.95,0.29l0.16-0.93l0.47-0.81l0.76-1.11c0.07-0.1,0.12-0.21,0.16-0.32l0.5-1.51
                  c0.04-0.13,0.06-0.28,0.06-0.42l-0.04-1.46c0-0.06-0.01-0.12-0.02-0.17l-0.2-1.23c-0.01-0.07-0.02-0.13-0.05-0.2l-0.79-2.23
                  l-0.03-1.26c0-0.11-0.02-0.21-0.05-0.31l-0.09-0.33l3.61-2.55c-0.01,0.23,0.04,0.45,0.14,0.65l0.58,1.13
                  c0.15,0.29,0.41,0.51,0.71,0.62l2.53,0.79c0.05,0.02,0.11,0.03,0.17,0.04l1.68,0.25c0.09,0.01,0.18,0.01,0.28,0.01l1.19-0.1
                  l1.24,0.01h0.01c0.4,0,0.78-0.2,1.02-0.53l0.79-1.11l0.68-1.07c0.16-0.24,0.23-0.53,0.19-0.81l-0.13-1.09l0.48-2.27
                  c0.04-0.15,0.04-0.31,0.01-0.47l-0.46-2.61l0.13-1.04c0.01-0.1,0.01-0.21,0-0.31l-0.14-1.08l0.28-2.45
                  c0.02-0.12,0.01-0.25-0.01-0.37l-0.25-1.27c-0.01-0.05-0.03-0.11-0.05-0.16l-0.01-0.04l0.74,0.02l1.02,0.33l1.22,0.47
                  c0.4,0.15,0.86,0.09,1.2-0.17c0.35-0.26,0.53-0.68,0.5-1.11l-0.12-1.37c-0.02-0.2-0.09-0.39-0.19-0.56l-0.6-0.94l-0.48-1.2
                  c-0.06-0.15-0.15-0.29-0.26-0.41l-0.72-0.75l-0.49-0.98c-0.02-0.05-0.05-0.1-0.08-0.14l-0.42-0.64l0.12-0.8
                  c0.05-0.37-0.07-0.75-0.33-1.03l-0.73-0.78l-0.5-0.94c-0.06-0.12-0.14-0.22-0.24-0.31l-0.89-0.85c-0.17-0.17-0.39-0.28-0.63-0.33
                  l-0.76-0.14l-0.07-0.1l0.03-0.1l0.67-0.29c0.2-0.08,0.37-0.21,0.49-0.37l0.01-0.02l0.17-0.11l0.3,0.22c0.09,0.07,0.2,0.13,0.31,0.18
                  l1.1,0.42l1.13,0.51c0.05,0.02,0.1,0.04,0.15,0.06l0.94,0.28l0.89,0.72c0.16,0.13,0.35,0.22,0.56,0.26l1.21,0.23l2.16,1.13
                  c0.36,0.19,0.79,0.2,1.15,0.02s0.62-0.52,0.68-0.92l0.07-0.43l0.06,0.06c0.26,0.24,0.59,0.36,0.95,0.34l1.31-0.09
                  c0.12-0.01,0.24-0.04,0.36-0.08l1.03-0.4l0.33-0.05l0.2,0.28l0.26,0.78l-0.03,1.04c-0.01,0.33,0.12,0.65,0.35,0.89L1146.46,356.99z"/>
                <title>Košice</title>
              </Link>

              {/* kosice - kosicky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Košice")}>
                <path style={{ fill: crimesInRegionKosicky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionKosicky[1].crimePercent)] ? crimesInRegionKosicky[1].color : "#D3D3D3" }}
                  d="M1175.04,383.17v0.01l-0.84,0.12l-0.74-0.04l-0.94-0.66c-0.18-0.13-0.39-0.21-0.61-0.23l-0.9-0.07l-0.78-0.64
                  c-0.08-0.08-0.18-0.14-0.29-0.19l-2.09-0.91l-0.94-0.64c-0.47-0.31-1.09-0.27-1.52,0.09l-0.63,0.56l-3.08,0.16
                  c-0.09,0.01-0.18,0.02-0.27,0.05l-1.28,0.36c-0.07,0.02-0.15,0.05-0.21,0.08l-0.88,0.43l-0.05-0.08c-0.26-0.42-0.73-0.65-1.22-0.59
                  l-1.61,0.2c-0.19,0.02-0.37,0.09-0.53,0.19l-2.38,1.54l-0.66-0.18c-0.13-0.04-0.27-0.06-0.41-0.05l-3.04,0.21l-0.33-0.02l-0.32-0.36
                  l-0.53-1.73l-0.07-1.55c0-0.05-0.01-0.09-0.02-0.14l-0.22-1.34c-0.08-0.49-0.44-0.89-0.92-1.01l-1.76-0.45l-0.85-0.56
                  c-0.16-0.11-0.34-0.17-0.52-0.2l-1.28-0.17l-1.58-1.6c-0.08-0.08-0.18-0.15-0.28-0.21l-0.48-0.27l0.85-3.16l0.73-1.08l0.76-0.81
                  l1.87-1.69c0.05-0.05,0.09-0.09,0.13-0.14l1.42-1.76c0.04-0.05,0.07-0.1,0.1-0.15l2.26-3.8c0.02-0.03,0.04-0.07,0.06-0.11l0.53-1.16
                  c0.07-0.16,0.11-0.32,0.11-0.49l0.03-1.14l0.16-0.59l0.95,0.12c0.1,0.01,0.21,0.01,0.31,0l1.28-0.17c0.15-0.02,0.29-0.06,0.42-0.13
                  l1.02-0.53l1.09-0.28c0.19-0.05,0.37-0.14,0.52-0.27l0.92-0.8c0.12-0.1,0.22-0.23,0.29-0.37l0.64-1.26c0-0.01,0.01-0.01,0.01-0.02
                  l0.37,0.27c0.03,0.02,0.06,0.04,0.09,0.06l1.13,0.64c0.26,0.14,0.56,0.19,0.85,0.14l-0.19,0.73c-0.03,0.11-0.04,0.23-0.04,0.34
                  l0.02,1.23c0.01,0.41,0.22,0.79,0.56,1.02c0.34,0.23,0.78,0.27,1.16,0.12l0.46-0.19l0.35,0.32c0.15,0.13,0.33,0.23,0.52,0.28
                  l1.21,0.32c0.09,0.02,0.19,0.04,0.28,0.04l1.23,0.04h0.04c0.04,0,0.08,0,0.12-0.01l1.25-0.12l0.54,0.09l0.02-0.14l0.13,0.19
                  c0.05,0.07,0.1,0.13,0.16,0.19l0.8,0.82l0.41,0.48l-0.3,0.91c-0.1,0.32-0.07,0.65,0.07,0.95l0.45,0.9l0.06,1.05l-0.15,1.3
                  c-0.02,0.13-0.01,0.26,0.01,0.39l0.26,1.3c0.02,0.09,0.05,0.19,0.1,0.28l0.73,1.57c0.09,0.19,0.23,0.37,0.41,0.49l0.93,0.66
                  l0.53,0.71l0.35,1.1c0.12,0.4,0.44,0.71,0.84,0.83l0.57,0.17l0.31,1.19l0.09,3.32c0,0.17,0.04,0.33,0.11,0.48l0.63,1.4
                  c0.04,0.1,0.1,0.19,0.16,0.27l0.6,0.75l0.05,1.02c0,0.05,0.01,0.1,0.02,0.14l0.23,1.39C1174.98,383.01,1175,383.09,1175.04,383.17z"/>
                <title>Košice</title>
              </Link>

              {/* kosice - kosicky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Košice")}>
                <path style={{ fill: crimesInRegionKosicky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionKosicky[1].crimePercent)] ? crimesInRegionKosicky[1].color : "#D3D3D3" }}
                  d="M1165.57,353.95c-0.09,0.48,0.12,0.97,0.53,1.25l0.38,0.25l-0.57,0.76c-0.05,0-0.09,0-0.13,0.01l-1.34,0.13
                  l-1.01-0.03l-0.78-0.21l-0.7-0.64c-0.18-0.16-0.4-0.26-0.63-0.3l0.1-0.39c0.05-0.21,0.05-0.42,0-0.62l-0.35-1.43
                  c-0.08-0.33-0.29-0.61-0.58-0.78c-0.29-0.17-0.64-0.22-0.97-0.12l-0.71,0.2l-0.62-0.36l-1.11-0.77c-0.09-0.07-0.19-0.12-0.3-0.16
                  l-0.02-0.52c-0.01-0.15-0.04-0.3-0.1-0.45l-0.47-1.11l-0.41-1.09l-0.37-1.2c-0.08-0.26-0.24-0.49-0.47-0.65l-0.05-0.04l0.77-0.85
                  c0.1-0.11,0.19-0.24,0.24-0.38l0.91-2.38l0.49-1l0.72-1.2l0.69-1l0.74-0.94l0.93-0.83c0.09-0.08,0.16-0.18,0.22-0.28l0.52,1.75
                  c0.02,0.06,0.04,0.13,0.07,0.19l1.7,3.63c0.03,0.06,0.06,0.12,0.09,0.17l0.7,1.03l0.41,1.02c0.02,0.05,0.04,0.1,0.06,0.14l0.38,0.69
                  l-0.23,0.98c-0.12,0.49,0.07,1,0.48,1.3l1.02,0.74c0.1,0.08,0.21,0.13,0.33,0.18l0.63,0.21l0.08,0.41l-0.06,0.89l-0.9,2.19
                  c-0.04,0.08-0.07,0.17-0.08,0.27L1165.57,353.95z"/>
                <title>Košice</title>
              </Link>

              {/* kosice - kosicky*/}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Košice")}>
                <path style={{ fill: crimesInRegionKosicky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionKosicky[1].crimePercent)] ? crimesInRegionKosicky[1].color : "#D3D3D3" }}
                  d="M1160.01,327.8l-0.8,0.61c-0.11,0.09-0.2,0.19-0.28,0.31l-0.77,1.16c-0.22,0.34-0.27,0.76-0.13,1.13l0.88,2.39
                  l-0.49,2.1l-0.72,0.64c-0.05,0.05-0.1,0.1-0.14,0.15l-0.86,1.09l-0.78,1.14l-0.81,1.36l-0.57,1.15c-0.02,0.04-0.04,0.08-0.05,0.12
                  l-0.83,2.16l-0.72,0.8c-0.05,0.05-0.09,0.1-0.13,0.16l-0.68,1.06c-0.36,0.57-0.22,1.32,0.33,1.7l0.69,0.49l0.27,0.86l0.45,1.21
                  l0.4,0.94l0.03,0.89l-0.18,1.13l-0.44,0.87l-0.51,0.44l-0.95,0.24c-0.1,0.02-0.19,0.06-0.28,0.1l-0.95,0.5l-0.91,0.12l-1.15-0.14
                  c-0.23-0.03-0.45,0.01-0.66,0.1l-0.26,0.11l0.02-0.63c0-0.14-0.02-0.28-0.06-0.42l-0.38-1.16c-0.04-0.12-0.1-0.23-0.17-0.34
                  l-0.76-1.06c-0.28-0.38-0.73-0.57-1.2-0.51l-1.22,0.18c-0.09,0.02-0.19,0.04-0.27,0.07l-0.98,0.39l-0.58,0.04l-0.5-0.48
                  c-0.4-0.38-1-0.45-1.48-0.18l-1.11,0.63c-0.2,0.12-0.37,0.29-0.48,0.49l-0.91-0.45c-0.11-0.05-0.22-0.09-0.33-0.11l-1.07-0.2
                  l-0.83-0.68c-0.13-0.1-0.28-0.18-0.43-0.23l-1.1-0.32l-1.12-0.51l-0.99-0.38l-0.88-0.67c-0.43-0.33-1.03-0.35-1.47-0.04l-0.33,0.23
                  l-0.06-0.07c-0.28-0.3-0.68-0.45-1.09-0.4l-0.64,0.08l-0.26-0.34l-0.46-1.09c-0.05-0.11-0.11-0.22-0.19-0.31l-1.74-2.08l-0.73-0.99
                  c-0.06-0.07-0.12-0.14-0.18-0.2l-2.95-2.57l-0.33-0.92c-0.22-0.61-0.87-0.96-1.49-0.79l-0.76,0.2l-0.56-0.26l-0.85-0.77
                  c-0.06-0.05-0.12-0.09-0.18-0.13l-0.44-0.27l0.4-0.25c0.43-0.28,0.65-0.78,0.56-1.29l-0.24-1.31c-0.01-0.03-0.02-0.07-0.03-0.1
                  l-0.35-1.29l-0.02-1.1c0-0.06-0.01-0.13-0.02-0.19l-0.14-0.84l0.18-0.35l0.45-0.22l2.24,0.32c0.61,0.1,1.2-0.26,1.39-0.84l0.51-1.55
                  l0.61-0.84c0.28-0.4,0.31-0.93,0.06-1.35l-0.58-1.02l-0.57-1.12l-0.51-1.13c-0.05-0.11-0.12-0.22-0.2-0.31l-0.69-0.79l-0.36-1.15
                  l-0.34-0.94l0.01-0.62l0.52-0.69c0.08-0.1,0.13-0.2,0.18-0.31l0.35-0.92l0.64-0.6l1.28-0.8c0.13-0.07,0.25-0.18,0.34-0.29l0.68-0.88
                  l0.39-0.23l0.64,0.23l0.66,0.41l0.37,0.72l0.28,1.07c0.07,0.24,0.2,0.45,0.38,0.62l0.93,0.81l0.52,0.51l-0.04,0.85
                  c-0.01,0.35,0.12,0.7,0.38,0.94l0.92,0.9c0.04,0.04,0.08,0.08,0.13,0.12l1.12,0.82l1.03,0.96c0.07,0.06,0.15,0.12,0.23,0.17
                  l1.09,0.62c0.08,0.04,0.16,0.08,0.25,0.1l1.21,0.38c0.18,0.06,0.38,0.08,0.58,0.04l1.15-0.19l0.6-0.02l0.31,0.31l0.19,0.89
                  c0.08,0.38,0.33,0.7,0.68,0.87l0.97,0.46l0.65,0.63l0.43,0.89c0.12,0.24,0.3,0.43,0.53,0.56l1.03,0.55
                  c-0.16,0.41-0.1,0.87,0.17,1.22l0.62,0.81l0.21,0.99c0.12,0.59,0.66,1,1.24,1l1.11-0.02l0.57,0.14l0.38,0.6
                  c0.16,0.26,0.4,0.44,0.69,0.53l1.18,0.37c0.45,0.15,0.95,0.02,1.28-0.33c0.33-0.35,0.43-0.85,0.26-1.3l-0.52-1.37l-0.14-0.45h0.95
                  c0.33,0,0.64-0.13,0.87-0.35l1.3-1.26l0.82-0.36l1.12-0.25c0.43-0.1,0.77-0.41,0.91-0.83l0.48-1.45c0.05-0.17,0.07-0.35,0.05-0.53
                  l-0.07-0.61l0.09-0.07c0.11,0.07,0.23,0.12,0.36,0.15l1.2,0.28c0.34,0.08,0.7,0.01,0.99-0.19l1-0.69l0.47-0.29l0.52,0.25l0.94,0.57
                  l0.49,1.14L1160.01,327.8z"/>
                <title>Košice</title>
              </Link>

              {/* bratislava 2 - bratislavsky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Bratislava II")}>
                <path style={{ fill: crimesInRegionBratislavsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionBratislavsky[1].crimePercent)] ? crimesInRegionBratislavsky[1].color : "#D3D3D3" }}
                  d="M114.61,592.37l-0.29,1.53l-0.73,1.59l-0.23,0.41l-0.96-0.47l-0.71-0.81c-0.08-0.1-0.18-0.18-0.29-0.25
                  l-1.06-0.64c-0.07-0.04-0.13-0.07-0.2-0.1l-2.37-0.93l-0.85-0.64c-0.18-0.14-0.41-0.22-0.64-0.25l-1.22-0.1
                  c-0.1-0.01-0.2-0.01-0.3,0l-1.45,0.22c-0.23,0.04-0.45,0.14-0.63,0.29l-0.97,0.84l-0.92,0.73l-1.11,0.74l-1.11,0.65
                  c-0.2,0.12-0.37,0.29-0.48,0.5l-0.58,1.1c-0.12,0.24-0.17,0.51-0.12,0.78l0.16,0.98l-0.12,0.51l-0.65,0.46
                  c-0.15,0.1-0.26,0.23-0.35,0.38l-0.88,1.47c-0.06,0.09-0.1,0.2-0.13,0.3l-0.41,1.47c-0.12,0.41-0.02,0.85,0.27,1.17l0.6,0.68
                  l0.04,0.36l-0.37,0.52l-0.98,0.48c-0.25,0.12-0.45,0.32-0.58,0.58l-0.56,1.16c-0.06,0.12-0.1,0.26-0.11,0.4l-0.16,1.37l-0.07,1.16
                  l-0.03,0.16l-0.78,0.03c-0.47,0.02-0.88,0.31-1.08,0.73l-0.69,1.52c-0.06,0.13-0.09,0.27-0.11,0.41l-0.02,0.19l-0.11-0.06
                  c-0.04-0.02-0.08-0.04-0.13-0.06l-1.07-0.42l-0.82-0.49l-0.7-0.79l-0.62-0.9l-0.61-1.09c-0.06-0.1-0.14-0.2-0.23-0.28l-0.89-0.86
                  l-0.73-0.93l-0.68-0.96l-0.45-1.01l-0.29-1.08l-0.25-1.23c-0.01-0.07-0.03-0.14-0.06-0.21l-1.47-3.53c-0.02-0.05-0.04-0.1-0.07-0.15
                  l-1.28-2.15l-0.44-1.16l-0.19-1.32l-0.03-1.04l0.35-1.16l0.7-0.97c0.04-0.04,0.07-0.09,0.09-0.14l0.6-1.12
                  c0.07-0.12,0.11-0.24,0.13-0.38l0.25-1.44c0.02-0.08,0.02-0.16,0.02-0.24l-0.03-1.41c0-0.1-0.02-0.21-0.05-0.31l-0.39-1.36
                  c-0.03-0.12-0.08-0.24-0.15-0.34l-0.97-1.49c-0.08-0.12-0.18-0.23-0.3-0.32l-0.98-0.74c-0.09-0.07-0.19-0.12-0.3-0.16l-1.26-0.49
                  c-0.02-0.02-0.05-0.02-0.08-0.03c0.08-0.1,0.15-0.21,0.19-0.34l0.5-1.27c0.25-0.63-0.05-1.34-0.66-1.61l-0.69-0.29l-0.12-0.33
                  l0.18-0.87c0.04-0.22,0.02-0.44-0.05-0.65l-0.2-0.59l0.35-0.26l1.23-0.34c0.12-0.03,0.23-0.07,0.33-0.14l3.31-2.03l1.46-0.82
                  c0.1-0.06,0.19-0.13,0.27-0.21l0.96-0.96c0.05-0.04,0.08-0.08,0.12-0.13l2.4-3.16c0.03-0.04,0.06-0.08,0.09-0.13l1.89-3.28l0.6-0.47
                  l0.95-0.22l3.68-0.25l4.15-0.5l1.4-0.12l-0.01,0.51l-0.54,0.79c-0.05,0.08-0.09,0.16-0.13,0.24l-0.51,1.26
                  c-0.15,0.38-0.11,0.8,0.11,1.15l0.63,0.98l0.46,1.07c0.05,0.13,0.13,0.24,0.22,0.34l0.88,0.99c0.04,0.05,0.08,0.09,0.13,0.13
                  l0.12,0.1c-0.07,0.1-0.13,0.21-0.17,0.33L100,572.3c-0.15,0.4-0.08,0.85,0.18,1.19s0.68,0.53,1.11,0.48l0.46-0.04l0.08,0.14
                  l0.11,2.21c0.01,0.34,0.16,0.66,0.41,0.88l0.54,0.5l0.09,1.03l-0.36,1.38c-0.1,0.4,0,0.82,0.27,1.13l0.66,0.76l0.31,0.96
                  c0.15,0.44,0.53,0.77,0.99,0.84l2.37,0.37l0.97,0.43l0.45,0.57l0.56,1.49l0.49,1.56c0.09,0.3,0.3,0.55,0.58,0.71l1.26,0.71
                  c0.09,0.05,0.2,0.1,0.31,0.12l1.42,0.35c0.18,0.05,0.37,0.05,0.55,0.01l0.21-0.04l-0.27,0.58c-0.18,0.38-0.16,0.83,0.06,1.18
                  C113.99,592.09,114.28,592.3,114.61,592.37z"/>
                <title>Bratislava II</title>
              </Link>

              {/* bratislava 3 - bratislavsky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Bratislava III")}>
                <path style={{ fill: crimesInRegionBratislavsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionBratislavsky[2].crimePercent)] ? crimesInRegionBratislavsky[2].color : "#D3D3D3" }}
                  d="M100.61,559.45l-0.16,0.48l-0.82,0.56c-0.1,0.06-0.18,0.14-0.25,0.23l-0.27,0.02l-4.44,0.54l-4.93,0.36
                  c-0.07,0.01-0.13,0.02-0.2,0.03l-1.32,0.3c-0.18,0.04-0.34,0.12-0.49,0.24L86.72,563c-0.12,0.09-0.23,0.21-0.31,0.34l-1.97,3.43
                  l-2.32,3.05l-0.77,0.78L80,571.35l-3.21,1.97l-1.25,0.35c-0.13,0.03-0.25,0.09-0.36,0.16l-0.86,0.57l-0.29-0.17l-1.12-0.54
                  c-0.13-0.06-0.27-0.1-0.42-0.11l-1.26-0.13l-0.85-0.2l-0.82-0.62c-0.1-0.08-0.2-0.14-0.32-0.18L68.06,572
                  c-0.06-0.02-0.13-0.04-0.2-0.06l-1.24-0.24l-1.1-0.26l-0.78-0.36l-0.61-0.66l-0.56-1.01c-0.03-0.05-0.06-0.11-0.1-0.16l-0.76-0.98
                  c-0.17-0.22-0.41-0.37-0.68-0.44l0.06-0.06l-0.83-0.86c-0.12-0.12-0.27-0.22-0.43-0.29l-0.13-0.05l0.45-1.12
                  c0.07-0.18,0.1-0.36,0.09-0.55l-0.08-1.25c-0.01-0.15-0.05-0.3-0.11-0.43l-0.52-1.16c-0.02-0.03-0.04-0.07-0.06-0.1l-0.48-0.84
                  l-0.02-0.95l0.43-1.03c0.06-0.15,0.09-0.31,0.09-0.48l-0.01-1.27c0-0.13-0.02-0.27-0.07-0.39l-0.3-0.89l0.27-1.41l0.95-2.25
                  c0.06-0.14,0.1-0.3,0.11-0.45l0.03-0.7l0.27-0.19l0.7,0.02l1.05,0.38l1.22,0.6c0.38,0.19,0.84,0.17,1.21-0.06l1.05-0.65
                  c0.06-0.03,0.12-0.08,0.17-0.12l0.54-0.48l0.68,0.08c0.13,0.02,0.26,0.01,0.38-0.01l1.22-0.23c0.41-0.08,0.76-0.36,0.92-0.74
                  l0.49-1.16c0.07-0.15,0.1-0.32,0.1-0.48l0.01-1.23c0-0.11-0.01-0.21-0.04-0.32l-0.24-0.95l0.09-0.42l1.62-1.17l0.95-0.49l0.98-0.35
                  l0.99-0.18l0.96,0.03l1.26,0.22c0.19,0.03,0.38,0.02,0.56-0.03c0.13,0.34,0.39,0.61,0.73,0.75l1.18,0.46
                  c0.09,0.03,0.19,0.06,0.29,0.07l1.23,0.16c0.34,0.05,0.69-0.06,0.96-0.28l0.97-0.81c0.1-0.08,0.18-0.18,0.25-0.29l0.01-0.01l0.3,0.5
                  c0.07,0.12,0.16,0.22,0.26,0.31l0.96,0.82c0.09,0.08,0.19,0.14,0.29,0.19l2.18,1l0.97,0.61l1.9,1.49l0.73,0.85
                  c0.04,0.03,0.07,0.07,0.11,0.1l1.03,0.95l2,1.53l1.34,1.37l0.84,0.93l0.63,0.9l0.71,1.19c0.05,0.08,0.11,0.16,0.17,0.23l1.16,1.2
                  l0.59,0.8L100.61,559.45z"/>
                <title>Bratislava III</title>
              </Link>

              {/* bratislava 1 - bratislavsky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Bratislava I")}>
                <path style={{ fill: crimesInRegionBratislavsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionBratislavsky[0].crimePercent)] ? crimesInRegionBratislavsky[0].color : "#D3D3D3" }}
                  d="M74.46,580.63l-0.97-0.09c-0.42-0.04-0.84,0.14-1.11,0.47l-0.46,0.59c-0.14-0.05-0.28-0.08-0.44-0.09
                  l-2.44,0.03l-1.32-0.05l-1.14-0.19l-2.5-0.71l-1.14-0.25l0.05-0.13c0.03-0.08,0.05-0.17,0.07-0.26l0.18-1.29l0.13-1.37
                  c0.01-0.07,0.01-0.14,0.01-0.21l-0.09-1.2l-0.06-2.44l0.03-0.32c0.06,0.05,0.13,0.09,0.2,0.12l1.12,0.52
                  c0.08,0.04,0.16,0.07,0.24,0.09l1.27,0.3l1.19,0.23l0.9,0.34l0.89,0.68c0.14,0.11,0.3,0.19,0.48,0.23l1.2,0.28
                  c0.05,0.01,0.1,0.02,0.16,0.02l1.12,0.11l0.84,0.4l0.72,0.42l0.18,0.51l-0.18,0.88c-0.04,0.22-0.02,0.45,0.05,0.67l0.42,1.16
                  C74.14,580.3,74.28,580.49,74.46,580.63z"/>
                <title>Bratislava I</title>
              </Link>

              {/*bratislava 5 - bratislavsky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Bratislava V")}>
                <path style={{ fill: crimesInRegionBratislavsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionBratislavsky[4].crimePercent)] ? crimesInRegionBratislavsky[4].color : "#D3D3D3" }}
                  d="M103.41,627.92l-0.91,0.43l-1.2,0.49l-0.91,0.11l-1.21-0.41l-1.24-0.52c-0.15-0.07-0.31-0.1-0.47-0.1
                  l-1.08-0.01l-1.17-0.29c-0.36-0.09-0.75-0.02-1.05,0.21l-0.74,0.56l-0.69-0.13c-0.27-0.05-0.53-0.01-0.77,0.1l-4.14,1.94l-1.4,0.54
                  c-0.06,0.03-0.12,0.05-0.17,0.08l-2.36,1.38l-1-1.01l-0.82-0.72l-0.64-0.88l-0.6-1.02c-0.12-0.2-0.28-0.36-0.49-0.47l-0.99-0.54
                  l-0.83-0.8c-0.23-0.24-0.56-0.38-0.89-0.36l-1.28,0.02c-0.2,0-0.4,0.05-0.57,0.15l-0.47,0.25l-1.79-2.14
                  c-0.08-0.09-0.18-0.17-0.28-0.24l-1.04-0.66c-0.2-0.12-0.43-0.19-0.67-0.19h-1.39c-0.3,0-0.6,0.11-0.83,0.31l-3.63,3.07
                  c-0.05-0.06-0.11-0.12-0.18-0.18l-0.98-0.78c-0.05-0.04-0.11-0.08-0.17-0.11l-1.05-0.59l-1.36-0.89l-0.19-0.13l0.21-0.38l0.73-1.03
                  l1.81-1.74c0.03-0.03,0.05-0.05,0.07-0.08l1.05-1.24c0.25-0.29,0.35-0.68,0.28-1.05l-0.32-1.64c-0.07-0.36-0.3-0.67-0.62-0.85
                  l-1.2-0.67c-0.05-0.03-0.1-0.05-0.15-0.07l-1.18-0.46l-0.46-0.23l0.35-0.21c0.07-0.03,0.13-0.07,0.18-0.12l2.51-2.09
                  c0.28-0.24,0.45-0.59,0.45-0.96c0-0.36-0.16-0.72-0.44-0.96l-1.75-1.47c-0.07-0.06-0.14-0.11-0.22-0.15l-0.5-0.27l1.04-1.77
                  c0.35-0.57,0.18-1.31-0.38-1.68l-0.8-0.53l0.02-0.34l0.55-0.5l2.42-1.65l0.82,0.06c0.49,0.04,0.94-0.22,1.17-0.64
                  c0.24-0.42,0.21-0.94-0.07-1.33l-0.65-0.92l0.08-0.78c0.03-0.26-0.02-0.53-0.15-0.75l-0.84-1.49c-0.14-0.25-0.36-0.44-0.62-0.55
                  l-0.95-0.38l-0.61-0.54l-0.34-1.03c-0.03-0.07-0.06-0.14-0.09-0.21l-0.33-0.59l0.36-0.61c0.23-0.4,0.23-0.89-0.01-1.28
                  c-0.24-0.39-0.67-0.63-1.13-0.6l-0.86,0.04l-0.43-0.24l-0.42-0.78c-0.03-0.07-0.08-0.14-0.13-0.2c0.2-0.17,0.34-0.4,0.4-0.65
                  l0.32-1.3c0.09-0.36,0.02-0.73-0.2-1.03l-1.41-2.01l-0.3-0.74l0.13-1c0-0.02,0.01-0.05,0-0.07l0.71,0.16
                  c0.24,0.05,0.49,0.04,0.72-0.05l0.72-0.27h0.01l0.84,0.25c0.04,0.01,0.07,0.02,0.1,0.03l1.23,0.26l2.47,0.71
                  c0.04,0.01,0.09,0.02,0.14,0.03l1.29,0.22c0.05,0.01,0.11,0.02,0.16,0.02l1.47,0.05l1.97-0.03l0.6,0.6
                  c0.24,0.23,0.56,0.36,0.88,0.36c0.14,0,0.27-0.02,0.41-0.07c0.37-0.12,0.65-0.41,0.77-0.77l0.24,0.02l2.28,0.47l1.01,0.39l0.66,0.51
                  l0.75,1.14l0.29,1.03l0.03,1.12l-0.2,1.13l-0.46,0.87l-0.77,1.07c-0.08,0.11-0.14,0.23-0.18,0.36l-0.48,1.55
                  c-0.04,0.13-0.06,0.27-0.06,0.41l0.04,1.32c0,0.04,0.01,0.09,0.01,0.14l0.22,1.53c0.02,0.09,0.04,0.18,0.07,0.27l0.53,1.38
                  c0.03,0.06,0.06,0.12,0.09,0.18l1.31,2.21l1.36,3.27l0.24,1.19l0.33,1.25c0.02,0.06,0.04,0.13,0.07,0.19l0.54,1.21
                  c0.03,0.07,0.07,0.14,0.11,0.21l0.79,1.12l0.83,1.05c0.03,0.04,0.07,0.08,0.11,0.12l0.83,0.8l0.55,0.97
                  c0.02,0.03,0.04,0.06,0.06,0.08l0.69,1.02c0.03,0.04,0.06,0.08,0.1,0.12l0.88,0.99c0.08,0.09,0.17,0.17,0.28,0.24l1.07,0.65
                  c0.06,0.04,0.13,0.07,0.2,0.09l1.11,0.44l1.07,0.56l0.13-0.25c0.1,0.19,0.25,0.36,0.45,0.48l1.07,0.65l1.12,0.91l1.21,0.86
                  c0.03,0.02,0.06,0.04,0.09,0.06l1.15,0.67l1.22,0.6l1.17,0.48l0.91,0.51l0.72,0.63l1.22,2.05l0.6,1.21
                  c0.06,0.12,0.15,0.24,0.25,0.33l1.67,1.69L103.41,627.92z"/>
                <title>Bratislava V</title>
              </Link>

              {/* bratislava 4 - bratislavsky */}
              <Link to={"/crimes/search/-1"} onClick={() => saveDistrictToLocal("Bratislava IV")}>
                <path style={{ fill: crimesInRegionBratislavsky.length >= 3 && checkedCategories[getLegendIdFromCrimePercentage(crimesInRegionBratislavsky[3].crimePercent)] ? crimesInRegionBratislavsky[3].color : "#D3D3D3" }}
                  d="M71.56,542.07c-0.02,0.01-0.03,0.02-0.05,0.03l-2.05,1.46c-0.26,0.18-0.45,0.46-0.51,0.78l-0.25,1.2
                  c-0.04,0.18-0.04,0.37,0.01,0.56l0.27,1.07l-0.01,0.82l-0.13,0.32l-0.37,0.07l-1.05-0.13c-0.36-0.04-0.71,0.07-0.98,0.3l-0.88,0.78
                  l-0.37,0.23l-0.66-0.32c-0.04-0.02-0.08-0.04-0.12-0.05l-1.3-0.48c-0.13-0.05-0.26-0.08-0.4-0.08l-1.34-0.03
                  c-0.28,0.01-0.55,0.08-0.77,0.24l-1.09,0.8c-0.3,0.23-0.49,0.57-0.51,0.95l-0.05,1.06l-0.94,2.23c-0.03,0.08-0.05,0.16-0.07,0.24
                  l-0.35,1.85c-0.04,0.21-0.02,0.43,0.05,0.63l0.34,1.01l0.01,0.82l-0.43,1.04c-0.06,0.15-0.09,0.32-0.09,0.49l0.03,1.53
                  c0,0.22,0.06,0.42,0.17,0.6l0.61,1.06l0.4,0.89l0.04,0.74l-0.35,0.87c-0.02,0.04-0.03,0.08-0.04,0.12l-0.34,1.18
                  c-0.18,0.62,0.14,1.27,0.73,1.51l0.92,0.38l0.29,0.3c-0.22,0.25-0.33,0.56-0.31,0.89c0.02,0.34,0.18,0.66,0.44,0.88l0.65,0.56
                  l0.12,0.63l-0.1,1.19v0.11l0.01,1.32l0.14,2.44l-0.12,1.2l-0.16,1.1l-0.26,0.7l-0.29,0.11l-0.91-0.21
                  c-0.08-0.02-0.16-0.03-0.24-0.03l-1.29-0.04c-0.1-0.01-0.2,0.01-0.3,0.03l-0.57,0.12l0.01,0.05l-2.56,0.18
                  c-0.09,0.01-0.18,0.03-0.27,0.05l-1.26,0.39l-1.21,0.17l-1.2-0.14l-0.77-0.27L48.93,580l-0.82-1.46c-0.02-0.03-0.04-0.06-0.06-0.09
                  l-0.95-1.4c-0.04-0.07-0.09-0.12-0.14-0.18l-1.1-1.11c-0.07-0.07-0.14-0.12-0.22-0.17l-1.25-0.8l-1.75-1.02l-1.44-1.07l-1.89-1.87
                  l-1.11-0.98l-0.85-0.67l-0.41-0.79l0.05-0.95l0.35-1.14c0.06-0.17,0.07-0.35,0.05-0.53l-0.15-1.16l-0.05-1.27
                  c0-0.09-0.02-0.17-0.04-0.26l-0.37-1.44c-0.01-0.06-0.03-0.12-0.06-0.18l-1.08-2.54l-0.25-1.14c-0.03-0.11-0.07-0.22-0.12-0.32
                  l-0.53-0.95l-0.39-1.55c-0.02-0.09-0.05-0.16-0.08-0.24l-0.57-1.18l-0.32-1l0.06-1.19c0-0.12-0.01-0.24-0.04-0.36l-0.33-1.34
                  c-0.02-0.09-0.05-0.18-0.1-0.27l-0.56-1.09c-0.03-0.06-0.07-0.12-0.11-0.18l-0.77-1.03c-0.05-0.07-0.12-0.14-0.19-0.2l-1.19-1.03
                  l-0.68-0.79l-0.22-0.78l0.12-1.39l0.39-1.03c0.02-0.07,0.04-0.14,0.06-0.21l0.23-1.22c0.01-0.04,0.01-0.08,0.02-0.12l0.1-1.26
                  l0.2-1.21l0.26-0.95l0.7-0.93c0.05-0.06,0.08-0.12,0.12-0.18l0.56-1.05l0.4,0.1c0.05,0.02,0.11,0.03,0.17,0.03l1,0.12l3.18,2.97
                  l0.93,1.01c0.19,0.2,0.44,0.33,0.71,0.38l1.21,0.21c0.13,0.02,0.26,0.03,0.39,0.01l0.84-0.12l0.89,0.47
                  c0.14,0.08,0.3,0.13,0.46,0.14l1.29,0.14l1.91,0.59c0.19,0.06,0.39,0.08,0.58,0.04l1.56-0.27c0.06-0.01,0.13-0.03,0.19-0.05
                  l0.96-0.33l1.13,0.08c0.07,0,0.15,0,0.22-0.01l1.25-0.13l0.91-0.02l0.47,0.23l0.43,1.05c0.16,0.36,0.47,0.64,0.86,0.73l2.35,0.61
                  l1.22,0.43c0.11,0.04,0.22,0.06,0.33,0.07l0.47,0.03l0.17,0.42c0.05,0.12,0.12,0.24,0.21,0.34l0.96,1.13
                  c0.31,0.36,0.79,0.52,1.25,0.4l1.27-0.32c0.48-0.12,0.84-0.51,0.93-0.99l0.15-0.86l0.67-0.69c0.18-0.19,0.3-0.44,0.34-0.7l0.1-0.76
                  l0.19,0.12c0.25,0.14,0.55,0.2,0.83,0.15l1.27-0.2c0.18-0.02,0.35-0.09,0.49-0.18l0.6-0.4l1.9,0.47L71.56,542.07z"/>
                <title>Bratislava IV</title>
              </Link>



            </g>
          </svg>
        </Grid>

        <Grid xs={12} md={3} style={{ marginTop: '100px' }}>
          <div className="legend" style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              id="legend_dis_1"
              checked={checkedCategories.legend_dis_1}
              onChange={handleCheckboxChange}
              style={{ transform: "scale(1.5)", marginRight: "8px", marginBottom: '0.25em' }} // Adjust marginBottom as needed to align
            />
            <span className="dot" style={{ backgroundColor: "#006100" }}></span><h5>{t("dashboard.legend_dis_1")}</h5>
          </div>
          <div className="legend" style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              id="legend_dis_2"
              checked={checkedCategories.legend_dis_2}
              onChange={handleCheckboxChange}
              style={{ transform: "scale(1.5)", marginRight: "8px", marginBottom: '0.25em' }} // Adjust marginBottom as needed to align
            />
            <span className="dot" style={{ backgroundColor: "#498a00" }}></span><h5>{t("dashboard.legend_dis_2")}</h5>
          </div>
          {/* Repeat for all legend items */}
          <div className="legend" style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              id="legend_dis_3"
              checked={checkedCategories.legend_dis_3}
              onChange={handleCheckboxChange}
              style={{ transform: "scale(1.5)", marginRight: "8px", marginBottom: '0.25em' }} // Adjust marginBottom as needed to align
            />
            <span className="dot" style={{ backgroundColor: "#8bb500" }}></span><h5>{t("dashboard.legend_dis_3")}</h5>
          </div>
          <div className="legend" style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              id="legend_dis_4"
              checked={checkedCategories.legend_dis_4}
              onChange={handleCheckboxChange}
              style={{ transform: "scale(1.5)", marginRight: "8px", marginBottom: '0.25em' }} // Adjust marginBottom as needed to align
            />
            <span className="dot" style={{ backgroundColor: "#d6e600" }}></span><h5>{t("dashboard.legend_dis_4")}</h5>
          </div>
          <div className="legend" style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              id="legend_dis_5"
              checked={checkedCategories.legend_dis_5}
              onChange={handleCheckboxChange}
              style={{ transform: "scale(1.5)", marginRight: "8px", marginBottom: '0.25em' }} // Adjust marginBottom as needed to align
            />
            <span className="dot" style={{ backgroundColor: "#ffe500" }}></span><h5>{t("dashboard.legend_dis_5")}</h5>
          </div>
          <div className="legend" style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              id="legend_dis_6"
              checked={checkedCategories.legend_dis_6}
              onChange={handleCheckboxChange}
              style={{ transform: "scale(1.5)", marginRight: "8px", marginBottom: '0.25em' }} // Adjust marginBottom as needed to align
            />
            <span className="dot" style={{ backgroundColor: "#ffa600" }}></span><h5>{t("dashboard.legend_dis_6")}</h5>
          </div>
          <div className="legend" style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              id="legend_dis_7"
              checked={checkedCategories.legend_dis_7}
              onChange={handleCheckboxChange}
              style={{ transform: "scale(1.5)", marginRight: "8px", marginBottom: '0.25em' }} // Adjust marginBottom as needed to align
            />
            <span className="dot" style={{ backgroundColor: "#ff6f00" }}></span><h5>{t("dashboard.legend_dis_7")}</h5>
          </div>
          <div className="legend" style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              id="legend_dis_8"
              checked={checkedCategories.legend_dis_8}
              onChange={handleCheckboxChange}
              style={{ transform: "scale(1.5)", marginRight: "8px", marginBottom: '0.25em' }} // Adjust marginBottom as needed to align
            />
            <span className="dot" style={{ backgroundColor: "#ff2200" }}></span><h5>{t("dashboard.legend_dis_8")}</h5>
          </div>
          <div className="legend" style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              id="legend10"
              checked={checkedCategories.legend10}
              onChange={handleCheckboxChange}
              style={{ transform: "scale(1.5)", marginRight: "8px", marginBottom: '0.25em' }} // Adjust marginBottom as needed to align
            />
            <span className="dot" style={{ backgroundColor: "#000000" }}></span><h5>{t("dashboard.legend10")}</h5>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2} paddingBottom={2}>
        {yearRange && (
          <Grid item xs={12} style={{ marginTop: "-220px", padding: "0 20px" }}>
            <Typography gutterBottom>
              {t("Vyber rozsah rokov")}: {yearRange[0]} - {yearRange[1]}
            </Typography>
            <Slider
              value={yearRange}
              onChange={handleSliderChange}
              onChangeCommitted={handleSliderCommit} // Spustí refresh iba po pustení slidera
              min={minYear}
              max={maxYear}
              step={1}
              valueLabelDisplay="auto"
            />
            {loading && <p>Načítavam dáta...</p>}
          </Grid>
        )}
      </Grid>



      <div>
        <h3 style={{ textAlign: "left" }}>{t("dashboard.legend9")} {sumOfAllCrimes}</h3>
      </div>

      <ShowBox className="box-12 py-2" name={t("districtMap.hideStatistics") + ""}>
        <DistrictStatistics
          sumOfAllCrimes={sumOfAllCrimes}
          countOfDistricts={countOfDistricts}
          meanOfCrimes={meanOfCrimes}
          districtWithLeastCrimes={districtWithLeastCrimes}
          districtWithMostCrimes={districtWithMostCrimes}
          medianOfCrimes={medianOfCrimes}
          stdOfCrimes={stdOfCrimes} />
      </ShowBox>
    </PageForm >
  );
}