import { Tab, Box, Tabs, Alert, AlertTitle, Checkbox, FormControl, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, Tooltip, XAxis, YAxis, ResponsiveContainer } from "recharts";
import image from '../../../../assets/css/images/nahlasene.jpg';
import HeaderCarousel from '../../../../components/HeaderCarousel/HeaderCarousel';
import { COLORS, GET_ALL_CCO, GET_CCO_PREDICTION, LINK_PREDICTION_CCO, LINK_PREDICTION_CO, GET_ALL_STATES, ITEM_HEIGHT, ITEM_PADDING_TOP, GET_ALL_CCO_DISTINCT, LINK_PREDICTION_PRISON } from '../../../../interfaces/europe/Constants';
import { CriminalOffence, ConvictedCriminalOffence, ConvictedCriminalOffenceRequestPredictionDto, State, ConvictedCriminalOffenceCountryPredictionDto } from '../../../../interfaces/europe/interfaces';

import './CriminalOffencePredictionConvicted.css';
import { useTranslation } from "react-i18next";

export default function CriminalOffencePredictionConvicted() {
    const { id, code } = useParams();
    const { t } = useTranslation("europe");

    //RESTS
    const [predictions, setPredictions] = React.useState<ConvictedCriminalOffenceCountryPredictionDto[]>([]);
    const [stateIds, setStateIds] = React.useState<number[]>([]);
    const [states, setStates] = React.useState<State[]>([]);
    const [seriesLength, setSeriesLength] = React.useState<number>();
    const [trainSize, setTrainSize] = React.useState<number>();
    const [horizon, setHorizon] = React.useState<number>();
    const [confidenceLevel, setConfidenceLevel] = React.useState<number>();
    const [windowSize, setWindowSize] = React.useState<number>();
    const [cCriminalOffences, setConvictedCriminalOffences] = React.useState<ConvictedCriminalOffence[]>([]);
    const [cCriminalOffencesCodes, setConvictedCriminalOffenceCodes] = React.useState<string>();
    const [criminalOffences, setCriminalOffences] = React.useState<CriminalOffence[]>([]);
    const [criminalOffencesCodes, setCriminalOffenceCodes] = React.useState<string>();

    let requestFilter: ConvictedCriminalOffenceRequestPredictionDto = { "stateId": [10], "code": "T11TC", "windowSize": 2, "seriesLength": 5, "trainSize": 10, "horizon": 5, "confidenceLevel": 90 };

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL_EU + GET_ALL_STATES).then((response) => {
            setStates(response.data);
        });
    }, []);

    /*useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL_EU + GET_ALL_CCO).then((response) => {
            setConvictedCriminalOffences(response.data);
        });
    }, []);*/

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL_EU + GET_ALL_CCO).then((response) => {
            setCriminalOffences(response.data);
        });
    }, []);

    useEffect(() => {
        requestFilter.stateId = stateIds;
        if (cCriminalOffencesCodes != undefined) {
            requestFilter.code = cCriminalOffencesCodes;
        }
        if (confidenceLevel != undefined) {
            requestFilter.confidenceLevel = confidenceLevel;
        }
        if (windowSize != undefined) {
            requestFilter.windowSize = windowSize;
        }
        if (horizon != undefined) {
            requestFilter.horizon = horizon;
        }
        if (trainSize != undefined) {
            requestFilter.trainSize = trainSize;
        }
        if (seriesLength != undefined) {
            requestFilter.seriesLength = seriesLength;
        }

        axios.post(process.env.REACT_APP_API_URL + GET_CCO_PREDICTION, requestFilter).then((response) => {
            setPredictions(response.data);
        });
    }, [stateIds, cCriminalOffencesCodes, confidenceLevel, windowSize, horizon, trainSize, seriesLength]);

    const handleStateChange = (event: SelectChangeEvent<typeof stateIds>) => {
        const {
            target: { value },
        } = event;
        setStateIds(value as number[]);
    };

    const handleConvictedCriminalOffenceChange = (event: SelectChangeEvent<typeof cCriminalOffencesCodes>) => {
        const {
            target: { value },
        } = event;
        setConvictedCriminalOffenceCodes(
            value
        );
    };

    const handleWindowSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = parseInt(event.target.value, 10);

        // If the entered value is NaN or less than 2, default to 2
        if (isNaN(newValue) || newValue < 2) {
            newValue = 2;
        }

        // If the entered value is greater than 4, default to 4
        if (newValue > 4) {
            newValue = 4;
        }

        setWindowSize(newValue);

        let min = 4
        if (windowSize !== undefined) {
            min = 2 * windowSize + 1;
        }

        if (trainSize !== undefined) {


            if (isNaN(newValue) || trainSize < min) {
                setTrainSize(min);
            }
        }
    };

    const handleSeriesLengthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = parseInt(event.target.value, 10);

        if (isNaN(newValue) || newValue < 3) {
            newValue = 3;
        }

        setSeriesLength(newValue);
    };

    const handleHorizonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = parseInt(event.target.value, 10);
        if (isNaN(newValue) || newValue < 1) {
            newValue = 1;
        }
        setHorizon(newValue);
    };

    const handleTrainSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = parseInt(event.target.value, 10);
        let min = 4
        if (windowSize !== undefined) {
            min = 2 * windowSize + 1;
        }


        if (isNaN(newValue) || newValue < min) {
            newValue = min;
        }
        setTrainSize(newValue);
    };

    const handleConfidenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = parseInt(event.target.value, 10);
        if (isNaN(newValue) || newValue < 1) {
            newValue = 1;
        }

        if (newValue > 99) {
            newValue = 99;
        }

        setConfidenceLevel(newValue);
    };

    const [value, setValue] = React.useState(1);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function getState(x: number[]) {
        const filteredCourses = states.filter(state => x.slice(0, 3).some(number => number === state.id));
        const translated = filteredCourses.map(u => t(`states.${u.code}`));
        if (x.length > 3) {
            return translated.join(', ') + ",...";
        }
        return translated.join(', ');
    }

    return (
        <>
            <HeaderCarousel name={t("prediction.title")} image={image} />
            <div className='underLabel pb-2'>
                <div className="mb-2">
                    <Box sx={{ width: '100%' }}>
                        <Tabs
                            onChange={handleChange}
                            value={value}
                            aria-label="Tabs where selection follows focus"
                            selectionFollowsFocus>
                            <Link to={LINK_PREDICTION_CO}><Tab label={t(`stateInfo.criminalOffences`)}  ></Tab></Link>
                            <Link to={LINK_PREDICTION_CCO} className="text-primary"><Tab className="font-weight-bold" label={t(`prediction.convicted`)} ></Tab></Link>
                            <Link to={LINK_PREDICTION_PRISON}><Tab label={t(`stateInfo.prison`)}></Tab></Link>
                        </Tabs>
                    </Box>
                </div>
                <p className="label-states">{t("prediction.subTitle")}</p>
            </div>
            <div className='d-inline w-100'>


                <FormControl sx={{ m: 1, width: 400 }}>
                    <InputLabel id="multiple-checkbox-label-states">{t("prediction.states")}</InputLabel>
                    <Select
                        labelId="multiple-checkbox-label-states"
                        id="multiple-checkbox-states"
                        multiple
                        value={stateIds}
                        onChange={handleStateChange}
                        input={<OutlinedInput label={t("prediction.states")} />}
                        renderValue={selected => getState(selected)}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                    width: 250,
                                },
                            }
                        }
                        }
                    >
                        {states.map((state) => (
                            <MenuItem key={state.name.toString()} value={state.id} >
                                <Checkbox checked={stateIds.indexOf(state.id) > -1} />
                                <ListItemText primary={t(`states.${state.code}`)} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>


            </div>
            <div className='d-inline'>
                <FormControl sx={{ m: 1, width: 400 }}>
                    <InputLabel id="multiple-checkbox-label-criminal-offences">{t("prediction.cCriminalOffences")}</InputLabel>
                    <Select
                        labelId="multiple-checkbox-label-criminal-offences"
                        id="multiple-checkbox-criminal-offences"

                        value={cCriminalOffencesCodes}
                        onChange={handleConvictedCriminalOffenceChange}
                        input={<OutlinedInput label={t("prediction.cCriminalOffences")} />}
                        renderValue={(selected) => t(`criminalOffence.${selected}.name`)}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                    width: 1,
                                }
                            },
                        }
                        }
                    >
                        {criminalOffences.map((criminal) => (
                            <MenuItem key={criminal.code} value={criminal.code}>
                                <ListItemText primary={t(`criminalOffence.${criminal.code}.name`)} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            {/*<div className='d-inline'>
                <FormControl sx={{ m: 1, width: 355 }}>
                    <InputLabel id="multiple-checkbox-label-criminal-offences">{t("prediction.criminalOffences")}</InputLabel>
                    <Select
                        labelId="multiple-checkbox-label-criminal-offences"
                        id="multiple-checkbox-criminal-offences"

                        value={criminalOffencesCodes}
                        onChange={handleCriminalOffenceChange}
                        input={<OutlinedInput label={t("prediction.criminalOffences")} />}
                        renderValue={(selected) => getConvictedCriminalOffenceName(selected)}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                    width: 250,
                                }
                            },
                        }
                        }
                    >
                        {criminalOffences.map((criminal) => (
                            <MenuItem key={criminal.code} value={criminal.code}>
                                <ListItemText primary={t(`criminalOffence.${criminal.code}.name`)} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <br />*/}                
            <br />
            <div className='prediction-param-label'>{t("prediction.predictionParameters")}</div>

            <div className='d-inline'>
                <FormControl sx={{ m: 1, width: 120, height: 50 }}>
                    <TextField
                        id="outlined-number"
                        label={t("prediction.wSize")}
                        type="number"
                        defaultValue={"2"}
                        value={windowSize}
                        onChange={handleWindowSizeChange}

                        inputProps={{
                            step: 1,
                            min: 2,
                            max: 4
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}

                    />
                </FormControl>
            </div >

            <div className='d-inline'>
                <FormControl sx={{ m: 1, width: 120, height: 50 }}>
                    <TextField
                        id="outlined-number"
                        label={t("prediction.sLength")}
                        type="number"
                        defaultValue={"5"}
                        value={seriesLength}
                        onChange={handleSeriesLengthChange}

                        inputProps={{
                            step: 1,
                            min: 3
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}

                    />
                </FormControl>
            </div >

            <div className='d-inline'>
                <FormControl sx={{ m: 1, width: 120, height: 50 }}>
                    <TextField
                        id="outlined-number"
                        label={t("prediction.tSize")}
                        type="number"
                        defaultValue={"10"}
                        value={trainSize}
                        onChange={handleTrainSizeChange}

                        inputProps={{
                            step: 1,
                            min: windowSize !== undefined ? 2 * windowSize + 1 : 4
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}

                    />
                </FormControl>
            </div >

            <div className='d-inline'>
                <FormControl sx={{ m: 1, width: 120, height: 50 }}>
                    <TextField
                        id="outlined-number"
                        label={t("prediction.horizon")}
                        type="number"
                        defaultValue={"5"}
                        value={horizon}
                        onChange={handleHorizonChange}

                        inputProps={{
                            step: 1,
                            min: 1
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}

                    />
                </FormControl>
            </div >

            <div className='d-inline'>
                <FormControl sx={{ m: 1, width: 120, height: 50 }}>
                    <TextField
                        id="outlined-number"
                        label={t("prediction.confidenceLevel")}
                        type="number"
                        defaultValue={"90"}
                        value={confidenceLevel}
                        onChange={handleConfidenceChange}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                        inputProps={{
                            step: 1,
                            min: 1,
                            max: 90
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}

                    />
                </FormControl>
            </div >

            {predictions !== undefined && predictions[0] !== undefined  && predictions.length === 1 && predictions[0].error !== undefined && predictions[0].error !== null ? <>
                <Alert severity="error" className="mt-3">
                    <AlertTitle>Error</AlertTitle>
                    {predictions[0].error}
                </Alert></>
                : <></>
            }

            {stateIds.length > 0 && (predictions !== undefined && predictions[0] !== undefined && (predictions[0].error === undefined || predictions[0].error === null)) ? <>
                <div className="my-5 col-10 w-100 h-100">
                    <h3 className="mb-5 trendLabel">{t("prediction.totalPerYears")}</h3>
                    <div className="mt-5">
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}>
                                
                                <CartesianGrid strokeDasharray="2 2" />
                                <XAxis dataKey="year" type="number" domain={["dataMin", "dataMax"]} />
                                <YAxis type="number" domain={['auto', 'auto']} />
                                <Tooltip />
                                <Legend />
                                <ReferenceLine type="monotone" strokeDasharray="8 4" x={2024} stroke="BLACK"></ReferenceLine>

                                {
                                    predictions.map((state, index) => {
                                        return (<><Line key={state.code + "PREDICTION"} data={state.prediction} dataKey="countPredicted" name={`${t("states." + state.code)}` + " " + `${t("prediction.prediction")}`} stroke={COLORS[2]} strokeWidth={2} activeDot={{ r: 5 }}></Line>
                                            <Line key={state.code + "TOTAL"} name={`${t("states." + state.code)}`} data={state.prediction} dataKey="count" stroke={COLORS[index % COLORS.length]} strokeWidth={2} activeDot={{ r: 5 }}></Line>
                                            <Line key={state.code + "UPPER"} name={`${t("states." + state.code)}` + " " + `${t("prediction.upper")}`} data={state.prediction} dataKey="upperConfidenceCount" stroke={COLORS[3]} strokeWidth={2} activeDot={{ r: 5 }}></Line>
                                            <Line key={state.code + "LOWER"} name={`${t("states." + state.code)}` + " " + `${t("prediction.lower")}`} data={state.prediction} dataKey="lowerConfidenceCount" stroke={COLORS[3]} strokeWidth={2} activeDot={{ r: 5 }}></Line></>)
                                    })
                                }

                            </LineChart>
                        </ResponsiveContainer>
                    
                    </div>
                        </div></> : <></>}

            <div>
                <p>
                    <strong>{t("prediction.wSize")}:</strong> {t("prediction.wSizeInfo")}
                </p><p>
                    <strong>{t("prediction.sLength")}:</strong> {t("prediction.sLengthInfo")}
                </p><p>
                    <strong>{t("prediction.tSize")}:</strong> {t("prediction.tSizeInfo")}
                </p><p>
                    <strong>{t("prediction.horizon")}:</strong> {t("prediction.horizonInfo")}
                </p><p>
                    <strong>{t("prediction.confidenceLevel")}:</strong> {t("prediction.confidenceLevelInfo")}
                </p>
            </div>

            <div className='info'> {t("statistics.crimeIndex")}</div>

        </>

    );
}