import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import image from '../../assets/css/images/police.jpg';
import { BsGraphDown } from "react-icons/bs";
import { FaGlobeEurope, FaMap, FaPencilRuler, FaSearch } from "react-icons/fa";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { MdPostAdd } from "react-icons/md";
import CardLink from "../../components/Cards/CardLink";
import CardsBox from "../../components/Cards/CardsBox";
import PageForm from "../../components/PageHeader/PageForm";
import useUser from "../../use/useUser";

export default function Traffic() {

  const { t } = useTranslation("traffic");
  const [ user, { isAdmin, isLoggedIn, logout } ] = useUser();

  return (
    <PageForm label={t("Traffic.nadpisObr")} image={image}>
      <Typography variant="h4" textAlign={"center"} marginBottom={2}>
        {t("Traffic.nadpis")}
      </Typography>
      <Typography variant="body1" textAlign={"justify"} marginBottom={2}>
        {t("Traffic.popisStranky")}
      </Typography>
      <Typography variant="h4" textAlign={"center"} marginBottom={2}>
        {t("Traffic.menu")}
      </Typography>
      <CardsBox>
        {isLoggedIn() && <CardLink to="/traffic/report-center" icon={<FaPencilRuler />} text={t("Traffic.trafficReportCenter")} />}
        <CardLink to="/traffic/dashboard" icon={<FaMap />} text={t("Traffic.mapa")} />
        <CardLink to="/traffic/search-n" icon={<FaSearch />} text={t("Traffic.vyhladaj")} />
        <CardLink to="/traffic/graphs" icon={<BsGraphDown />} text={t("Traffic.graf")} />
        <CardLink to="/traffic/analysis" icon={<QueryStatsIcon style={{fontSize: '44px'}}  />} text={t("Traffic.analysis")} />
        <CardLink to="/traffic/euaccidents" icon={<FaGlobeEurope />} text={t("Traffic.europeAccidents")} />
        </CardsBox>
    </PageForm>
  );
}