
import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Tab, Tabs, Tooltip } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { BsChevronLeft } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { CODE_PARAM, COLORS, CONVICTED_PARAM, GET_ALL_CO, GET_ALL_REPORTED_YEARS, GET_ALL_STATES, ITEM_HEIGHT, ITEM_PADDING_TOP, LINK_STATE_COMAPRE_CO, LINK_STATE_COMAPRE_CONVICTED, LINK_STATE_COMAPRE_INFO, LINK_STATE_COMAPRE_POLICE, POLICE_PARAM, POST_STATE_COMPARE_CONVICTED_CRIME_COUNTS, POST_STATE_COMPARE_CONVICTED_CRIME_COUNTS_BY_CO, POST_STATE_COMPARE_CONVICTED_CRIME_COUNTS_FOR_YEARS, POST_STATE_COMPARE_CONVICTED_CRIME_COUNTS_STATISTICS, POST_STATE_COMPARE_CONVICTED_CRIME_INDEX_BY_CO, POST_STATE_COMPARE_CONVICTED_CRIME_INDEX_YEAR_BY_STATE, POST_STATE_COMPARE_CONVICTED_TOTAL_CO_IN_YEAR_BY_STATE, PRISON_PARAM, STATE_INFO_SITE, TABLE_PAGE_SIZE_5, TABLE_PAGE_SIZE_TEN } from "../../../../../interfaces/europe/Constants";
import { CustomToolbar, getIcon, getImage, round } from "../../../../../interfaces/europe/Functions";
import { CrimeIndexInYearForState, CriminalOffence, CriminalOffenceCountInYearAndState, CriminalOffenceCountInYearAndStateRequest, CriminalOffenceCountStatesRequest, ReportedYears, State, StateCompareCountForCategory, StateCompareIndexForCategory, StateCompareRequest, StateInfoDto, Statistics, TotalCrimesInYearForState } from "../../../../../interfaces/europe/interfaces";
import './StateCrimesOffences.css';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { FaTable, FaChartArea, FaInfo } from "react-icons/fa";
import generatePDF from "react-to-pdf";
import { LineChart, CartesianGrid, XAxis, YAxis, Legend, Line, BarChart, Bar, Cell, Tooltip as TooltipChart } from "recharts";

export default function StateConvictedCrimesOffences() {
    const { t } = useTranslation("europe");
    const { id, code } = useParams();

    //RESTS
    const [stateInfo, setStateInfo] = React.useState<StateInfoDto>();
    var flag = getImage(code);

    // PAGING
    const [valueMainTab, setValueMainTabe] = React.useState(3);

    const handleChangeMain = (_event: React.SyntheticEvent, newValue: number) => {
        setValueMainTabe(newValue);
    };


    const targetRef = useRef<HTMLDivElement>(null);
    const [valueIndexFilterCriminalOffenceCounts, setValueIndexFilterCriminalOffenceCounts] = React.useState('1');
    const [valueIndexFilterCriminalOffenceCountsEvolution, setValueIndexFilterCriminalOffenceCountsEvolution] = React.useState('1');
    const [valueIndexOrCounts, setValueIndexOrCounts] = React.useState('1');

    //RESTS FOR GET
    const [states, setStates] = React.useState<State[]>([]);
    const [reportedYears, setReportedYears] = React.useState<ReportedYears>();

    //RESTS POST 
    let stateCompareRequest: StateCompareRequest = { "states": [] };
    let requestFilterCount: CriminalOffenceCountInYearAndStateRequest = { "year": 2010, "code": "T11TC", "stateIds": [] };
    let stateCompareTotalCountRequest: CriminalOffenceCountStatesRequest = { "stateIds": [], "code": "T11TC" }

    const [stateTotalCriminalOffences, setStateTotalCriminalOffences] = React.useState<TotalCrimesInYearForState[]>([]);
    const [stateTotalCrimeIndexes, setStateTotalCrimeIndexes] = React.useState<CrimeIndexInYearForState[]>([]);
    const [stateIndexesByCategory, setStateIndexesByCategory] = React.useState<StateCompareIndexForCategory[]>([]);
    const [stateCountsByCategory, setStateCountsByCategory] = React.useState<StateCompareCountForCategory[]>([]);
    const [criminalOffencesCode, setCriminalOffenceCode] = React.useState<string>('T11TC');
    const [criminalOffencesCodeEvolution, setCriminalOffenceCodeEvolution] = React.useState<string>('T11TC');
    const [criminalOffenceCounts, setCriminalOffenceCounts] = React.useState<CriminalOffenceCountInYearAndState[]>();
    const [yearsResult, setYear] = React.useState<number>(2010);
    const [criminalOffences, setCriminalOffences] = React.useState<CriminalOffence[]>([]);
    const [statistics, setStatistics] = React.useState<Statistics>();
    const [totalSpecificCriminalOffences, setTotalSpecificCriminalOffences] = React.useState<TotalCrimesInYearForState[]>([]);

    let idArray: (string | undefined)[] = [id];

    // Convert array of strings to array of numbers, filtering out undefined values
    let numberArray: number[] = idArray
        .filter((id): id is string => id !== undefined) // Filter out undefined values
        .map((id) => parseInt(id!, 10));


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + GET_ALL_REPORTED_YEARS).then((response) => {
            setReportedYears(response.data);
        });
    }, []);

    useEffect(() => {
        stateCompareRequest.states = numberArray;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_CONVICTED_TOTAL_CO_IN_YEAR_BY_STATE, stateCompareRequest).then((response) => {
            setStateTotalCriminalOffences(response.data);
        });
    }, [id, code]);

    useEffect(() => {
        stateCompareRequest.states = numberArray;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_CONVICTED_CRIME_INDEX_YEAR_BY_STATE, stateCompareRequest).then((response) => {
            setStateTotalCrimeIndexes(response.data);
        });
    }, [id, code]);

    useEffect(() => {
        stateCompareRequest.states = numberArray;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_CONVICTED_CRIME_INDEX_BY_CO, stateCompareRequest).then((response) => {
            setStateIndexesByCategory(response.data);
        });
    }, [id, code]);

    useEffect(() => {
        stateCompareRequest.states = numberArray;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_CONVICTED_CRIME_COUNTS_BY_CO, stateCompareRequest).then((response) => {
            setStateCountsByCategory(response.data);
        });
    }, [id, code]);

    useEffect(() => {
        requestFilterCount.stateIds = numberArray;
        requestFilterCount.code = criminalOffencesCode;
        requestFilterCount.year = yearsResult;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_CONVICTED_CRIME_COUNTS, requestFilterCount).then((response) => {
            setCriminalOffenceCounts(response.data);
        });
    }, [id, code, criminalOffencesCode, yearsResult]);

    useEffect(() => {
        requestFilterCount.stateIds = numberArray;
        requestFilterCount.code = criminalOffencesCode;
        requestFilterCount.year = yearsResult;

        axios.post(process.env.REACT_APP_API_URL + POST_STATE_COMPARE_CONVICTED_CRIME_COUNTS_STATISTICS, requestFilterCount).then((response) => {
            setStatistics(response.data);
        });
    }, [id, code, criminalOffencesCode, yearsResult]);

    useEffect(() => {
        stateCompareTotalCountRequest.stateIds = numberArray;
        stateCompareTotalCountRequest.code = criminalOffencesCodeEvolution;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_CONVICTED_CRIME_COUNTS_FOR_YEARS, stateCompareTotalCountRequest).then((response) => {
            setTotalSpecificCriminalOffences(response.data);
        });
    }, [id, code, criminalOffencesCodeEvolution]);


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + GET_ALL_CO).then((response) => {
            setCriminalOffences(response.data);
        });
    }, []);

    const [value, setValue] = React.useState(3);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleYearChange = (event: SelectChangeEvent<typeof yearsResult>) => {
        const {
            target: { value },
        } = event;
        setYear(value as number);
    };

    const handleCriminalOffenceChange = (event: SelectChangeEvent) => {
        setCriminalOffenceCode(event.target.value as string);
    };

    const handleCriminalOffenceEvolutionChange = (event: SelectChangeEvent) => {
        setCriminalOffenceCodeEvolution(event.target.value as string);
    };

    const handleChangeCrimeOffenceCountsFilter = (_event: React.SyntheticEvent, newValue: string) => {
        setValueIndexFilterCriminalOffenceCounts(newValue);
    };

    const handleChangeCrimeOffenceCountsFilterEvolution = (_event: React.SyntheticEvent, newValue: string) => {
        setValueIndexFilterCriminalOffenceCountsEvolution(newValue);
    };

    const handleChangeCountsOrIndex = (_event: React.SyntheticEvent, newValue: string) => {
        setValueIndexOrCounts(newValue);
    };

    function getState(x: number[], l: number = 3) {
        const filteredCourses = states.filter(state => x.slice(0, 3).some(number => number === state.id));
        const translated = filteredCourses.map(u => t(`states.${u.code}`));
        if (x.length > 3) {
            return translated.join(', ') + ",...";
        }
        return translated.join(', ');
    }

    const columnsOFilteredCOCounts: GridColDef[] = [

        { field: 'year', headerName: 'Year', flex: 1 },
        { field: 'count', headerName: 'Count of criminal offences', flex: 1, align: 'center', headerAlign: 'center', description: 'Count of criminal offences.', type: 'number' },
        { field: 'stateName', headerName: 'State name', flex: 1, align: 'center', headerAlign: 'center', description: 'State name.' },

    ];

    const rowStatistics = [
        { icon: 'Maximum', name: 'Maximum', value: round(statistics?.maximum) },
        { icon: 'Minimum', name: 'Minimum', value: round(statistics?.minimum) },
        { icon: 'Mean', name: 'Mean', value: round(statistics?.mean) },
        { icon: 'Median', name: 'Median', value: round(statistics?.median) },
        { icon: 'Variance', name: 'Variance', value: round(statistics?.variance) },
        { icon: 'Kurtosis', name: 'Kurtosis', value: round(statistics?.kurtosis) },
        { icon: 'StandardDeviation', name: 'StandardDeviation', value: round(statistics?.standardDeviation) },
        { icon: 'Skewness', name: 'Skewness', value: round(statistics?.skewness) },
        { icon: 'Percentile25', name: 'Percentile(25)', value: round(statistics?.percentile25) },
        { icon: 'Percentile50', name: 'Percentile(50)', value: round(statistics?.percentile50) },
        { icon: 'Percentile75', name: 'Percentile(75)', value: round(statistics?.percentile75) }
    ]

    const columnsStatistics: GridColDef[] = [
        {
            field: 'icon', headerName: '', align: 'center', headerAlign: 'center', width: 75, sortable: false, disableExport: true, cellClassName: 'icon-cell',
            filterable: false, renderCell: (params) => {
                return (
                    <>{getIcon(params.row.icon)}</>
                )
            }
        },
        { field: 'name', headerName: 'Statistic', flex: 1, align: 'center', headerAlign: 'center', headerClassName: 'bold-cell', cellClassName: 'bold-cell' },
        { field: 'value', headerName: 'Value', width: 200, align: 'right', headerAlign: 'right' }
    ];

    const uniqueYears = Array.from(new Set(totalSpecificCriminalOffences.flatMap((state) => state.data.map((yearData) => yearData.year))));
    const uniqueStates = Array.from(new Set(totalSpecificCriminalOffences.map((state) => state.stateName)));


    const findMin = (arr: any[]) => {
        return arr.reduce((min, current) => (current < min ? current : min), Infinity);
    };
    const findMax = (arr: any[]) => {
        return arr.reduce((max, current) => (current > max ? current : max), -Infinity);
    };

    const rows = uniqueYears.map((year) => {
        const row: { id: string; year: number; stateName: string;[key: string]: number | string } = {
            id: year.toString(),
            year: year,
            stateName: 'States',
        };

        uniqueStates.forEach((state) => {
            const stateData = totalSpecificCriminalOffences.find((s) => s.stateName === state);
            const count = stateData?.data.find((yearData) => yearData.year === year)?.count || 0;

            row[state] = Number(count);
        });

        const valuesForRow = uniqueStates.map((state) => row[state]);
        const minForRow = findMin(valuesForRow);
        const maxForRow = findMax(valuesForRow);
        row['min'] = minForRow;
        row['max'] = maxForRow;

        return row;
    });

    const columns = [
        { field: 'year', headerName: 'Year', width: 120 },
        ...uniqueStates.map((state) => ({
            field: state, headerName: state, flex: 1, minWidth: 100,
            renderCell: (params: any) => {
                const propertyName = params.colDef.headerName;
                const value = params.row[propertyName];

                if (params.row.max === value) {
                    return <span className="box-danger"> {value} </span>;
                } else if (params.row.min === value) {
                    return <span className="box-success"> {value} </span>;
                } else {
                    return <span> {value} </span>;
                }
            }
        })),
    ];


    const updatedCountsData = stateCountsByCategory.map((entry) => {
        const maxIndex = findMax(entry.statesCounts.map((s) => s.count));
        const minIndex = findMin(entry.statesCounts.map((s) => s.count));

        const indexes = entry.statesCounts.map((state, index) => ({
            ...state,
            min: state.count === minIndex,
            max: state.count === maxIndex,
        }));
        return { ...entry, statesCounts: indexes };
    });


    const updatedData = stateIndexesByCategory.map((entry) => {
        const maxIndex = findMax(entry.statesIndexes.map((s) => s.index));
        const minIndex = findMin(entry.statesIndexes.map((s) => s.index));

        const indexes = entry.statesIndexes.map((state, index) => ({
            ...state,
            min: state.index === minIndex,
            max: state.index === maxIndex,
        }));
        return { ...entry, statesIndexes: indexes };
    });

    const uniqueStateNames = Array.from(new Set(updatedData.flatMap(item => item.statesIndexes.map(state => state.name))));

    const columns2: GridColDef[] = [
        { field: 'criminalOffence', headerName: 'Criminal Offence', align: 'left', headerAlign: 'left', width: 280 },
        ...uniqueStateNames.map(stateName => ({
            field: stateName,
            headerName: stateName,
            flex: 1,
            renderCell: (params: any) => {
                const propertyName = params.colDef.headerName;
                const value = params.row[propertyName];

                if (params.row.max === value) {
                    const val = Number(value.toFixed(2));

                    return <span className="box-danger"> {val} </span>;
                } else if (params.row.min === value) {
                    const val = Number(value.toFixed(2));

                    return <span className="box-success"> {val} </span>;
                } else {
                    const val = Number(value.toFixed(2));

                    return <span> {val} </span>;
                }
            }

        })),
    ];

    const columns3: GridColDef[] = [
        { field: 'criminalOffence', headerName: 'Criminal Offence', align: 'left', headerAlign: 'left', width: 280 },
        ...uniqueStateNames.map(stateName => ({
            field: stateName,
            headerName: stateName,
            flex: 1,
            renderCell: (params: any) => {
                const propertyName = params.colDef.headerName;
                const value = params.row[propertyName];

                if (params.row.max === value) {
                    const val = Number(value.toFixed(2));

                    return <span className="box-danger"> {val} </span>;
                } else if (params.row.min === value) {
                    const val = Number(value.toFixed(2));

                    return <span className="box-success"> {val} </span>;
                } else {
                    const val = Number(value.toFixed(2));

                    return <span> {val} </span>;
                }
            }

        })),
    ];

    interface RowData {
        id: string;
        criminalOffence: string;
        [stateName: string]: string | number | DoubleRange;
    }

    const rows2: RowData[] = updatedData.map(item => {

        const maxTrueObject = item.statesIndexes.find(state => state.max === true);
        const minTrueObject = item.statesIndexes.find(state => state.min === true);

        const row: RowData = { id: item.criminalOffence.code, criminalOffence: item.criminalOffence.name, className: '', min: minTrueObject?.index ?? 0, max: maxTrueObject?.index ?? 0 };
        item.statesIndexes.forEach(state => {
            row[state.name] = state.index;
        });

        return row;
    });

    const rows3: RowData[] = updatedCountsData.map(item => {

        const maxTrueObject = item.statesCounts.find(state => state.max === true);
        const minTrueObject = item.statesCounts.find(state => state.min === true);

        const row: RowData = { id: item.criminalOffence.code, criminalOffence: item.criminalOffence.name, className: '', min: minTrueObject?.count ?? 0, max: maxTrueObject?.count ?? 0 };
        item.statesCounts.forEach(state => {
            row[state.name] = state.count;
        });

        return row;
    });




    return (
        <>
            <div className="returnLabel"><Link to="/europe/states"><BsChevronLeft className="returnIcon" />States</Link></div>

            <div className="mb-4">
                <h1 className="text-uppercase"><img className="mr-2 flag-title-image" src={flag} alt={"Flag"} /> {stateInfo?.name}</h1>
            </div>

            <Box sx={{ width: '100%' }}>
                <Tabs
                    onChange={handleChangeMain}
                    value={valueMainTab}
                >
                    <Link to={"/europe/states/info/id=" + id + "/code=" + code}><Tab label={t("stateCompare.information")}></Tab></Link>
                    <Link to={"/europe/states/info/id=" + id + "/code=" + code + "/crimeoffences"} ><Tab label={t("stateCompare.criminalOffences")}></Tab></Link>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code + POLICE_PARAM} ><Tab label={t("stateInfo.police")}></Tab></Link>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code + CONVICTED_PARAM} className="text-primary"><Tab label={t("stateInfo.convictedCriminalOffences")}></Tab></Link>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code + PRISON_PARAM} ><Tab label={t(`stateInfo.prison`)}></Tab></Link>


                </Tabs>
            </Box>

            <div>
                <div ref={targetRef}>
                    <div className="my-3 col-8 w-100 h-100">
                        <h3 className="mb-5 trendLabel">{t("state.convictedCrimes.title1")}</h3>
                        <div className="w-100 h-100">
                            <LineChart
                                width={800}
                                height={300}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}>
                                <CartesianGrid strokeDasharray="2 2" />
                                <XAxis allowDuplicatedCategory={false} dataKey="year" />
                                <YAxis />
                                <TooltipChart />
                                <Legend />
                                {
                                    stateTotalCriminalOffences.map((state, index) => {
                                        return (<Line key={state.stateId.toString()} name={state.stateName} data={state.data} dataKey="count" stroke={COLORS[index % COLORS.length]} strokeWidth={2} activeDot={{ r: 5 }}></Line>)
                                    })
                                }
                            </LineChart>
                        </div>
                    </div>
                    <div className="my-5 col-8 w-100 h-100">
                        <h3 className="mb-5 trendLabel">{t("state.convictedCrimes.title2")}</h3>
                        <div className="w-100 h-100">
                            <LineChart
                                width={800}
                                height={300}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}>
                                <CartesianGrid strokeDasharray="2 2" />
                                <XAxis allowDuplicatedCategory={false} dataKey="year" />
                                <YAxis />
                                <TooltipChart />
                                <Legend />
                                {
                                    stateTotalCrimeIndexes.map((state, index) => {
                                        return (<Line key={state.stateId.toString()} name={state.stateName} data={state.data} dataKey="index" stroke={COLORS[index % COLORS.length]} strokeWidth={2} activeDot={{ r: 5 }}></Line>)
                                    })
                                }
                            </LineChart>
                        </div>
                    </div>
                    <div className="my-5 col-8 w-100 h-100">

                        <h3 className="mb-5 trendLabel">{t("state.convictedCrimes.title3")}</h3>

                        <TabContext value={valueIndexOrCounts}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChangeCountsOrIndex} aria-label="lab API tabs example" className="revertTabs">
                                    <Tab icon={<FaTable />} value="1" />
                                    <Tab icon={<FaTable />} value="2" />

                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <div className="w-100 h-100">
                                    <div className="pb-3" style={{ padding: 24, height: 700, width: '100%' }}>
                                        <DataGrid
                                            rows={rows2}
                                            columns={columns2}
                                            disableColumnSelector
                                            disableSelectionOnClick={true}
                                            pageSize={TABLE_PAGE_SIZE_TEN}
                                            autoHeight={true}
                                            getRowId={(row) => row.id}
                                            components={{
                                                Toolbar: CustomToolbar,
                                            }}
                                            componentsProps={{ toolbar: { allColumns: true } }}
                                        />
                                    </div>
                                </div>

                            </TabPanel>

                            <TabPanel value="2">
                                <h5 className="mb-3 trendLabel">{t("state.convictedCrimes.title6")}</h5>

                                <div className="w-100 h-100">
                                    <div className="pb-3" style={{ padding: 24, height: 700, width: '100%' }}>
                                        <DataGrid
                                            rows={rows3}
                                            columns={columns3}
                                            disableColumnSelector
                                            disableSelectionOnClick={true}
                                            pageSize={TABLE_PAGE_SIZE_TEN}
                                            autoHeight={true}
                                            getRowId={(row) => row.id}
                                            components={{
                                                Toolbar: CustomToolbar,
                                            }}
                                            componentsProps={{ toolbar: { allColumns: true } }}
                                        />
                                    </div>
                                </div>

                            </TabPanel>


                        </TabContext>
                    </div>
                    <div className="my-5 col-8 w-100 h-100">
                        <h3 className="mb-5 trendLabel">{t("state.convictedCrimes.title5")}</h3>

                        <div>
                            <FormControl sx={{ m: 1, width: 384, height: 'fit-content', marginTop: 3, marginBottom: 3 }}>
                                <InputLabel id="demo-simple-select-label">{t("stateCompare.criminalOffences")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="selectCriminalOffenceFilter"
                                    value={criminalOffencesCodeEvolution}
                                    label="Criminal offences"
                                    onChange={handleCriminalOffenceEvolutionChange}
                                    defaultValue={"T11TC"}
                                >
                                    {criminalOffences.map((criminal) => (
                                        <MenuItem key={criminal.code} value={criminal.code}>
                                            <ListItemText primary={t(`criminalOffence.${criminal.code}.name`)} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <TabContext value={valueIndexFilterCriminalOffenceCountsEvolution}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChangeCrimeOffenceCountsFilterEvolution} aria-label="lab API tabs example" className="revertTabs">
                                        <Tab icon={<FaChartArea />} value="1" />
                                        <Tab icon={<FaTable />} value="2" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <LineChart
                                        width={800}
                                        height={300}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}>
                                        <CartesianGrid strokeDasharray="2 2" />
                                        <XAxis allowDuplicatedCategory={false} dataKey="year" />
                                        <YAxis />
                                        <TooltipChart />
                                        <Legend />
                                        {
                                            totalSpecificCriminalOffences.map((state, index) => {
                                                return (<Line key={state.stateId.toString()} name={state.stateName} data={state.data} dataKey="count" stroke={COLORS[index % COLORS.length]} strokeWidth={2} activeDot={{ r: 5 }}></Line>)
                                            })
                                        }
                                    </LineChart>
                                </TabPanel>
                                <TabPanel value="2">
                                    <div style={{ height: 370, width: '100%' }}>
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            disableColumnSelector
                                            disableSelectionOnClick={true}
                                            pageSize={TABLE_PAGE_SIZE_5}
                                            autoHeight={true}
                                            getRowId={(row) => row.id}
                                            components={{
                                                Toolbar: CustomToolbar,
                                            }}
                                            componentsProps={{ toolbar: { allColumns: true } }}
                                        />
                                    </div>
                                </TabPanel>
                            </TabContext>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}

