import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Tab, Tabs } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import { FaChartArea, FaInfo, FaTable } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { Bar, BarChart, CartesianGrid, Cell, Legend, Line, LineChart, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import image from '../../../../../assets/css/images/nahlasene.jpg';
import HeaderCarousel from '../../../../../components/HeaderCarousel/HeaderCarousel';
import { COLORS, GET_ALL_CO, GET_ALL_REPORTED_YEARS, GET_ALL_STATES, ITEM_HEIGHT, ITEM_PADDING_TOP, LINK_STATE_COMAPRE_CO, LINK_STATE_COMAPRE_CONVICTED, LINK_STATE_COMAPRE_INFO, LINK_STATE_COMAPRE_POLICE, LINK_STATE_COMAPRE_PRISON, POST_STATE_COMPARE_CRIME_COUNTS, POST_STATE_COMPARE_CRIME_COUNTS_BY_CO, POST_STATE_COMPARE_CRIME_COUNTS_FOR_YEARS, POST_STATE_COMPARE_CRIME_COUNTS_STATISTICS, POST_STATE_COMPARE_CRIME_INDEX_BY_CO, POST_STATE_COMPARE_CRIME_INDEX_YEAR_BY_STATE, POST_STATE_COMPARE_TOTAL_CO_IN_YEAR_BY_STATE, TABLE_PAGE_SIZE_5, TABLE_PAGE_SIZE_TEN } from '../../../../../interfaces/europe/Constants';
import { CustomToolbar, getIcon, round } from '../../../../../interfaces/europe/Functions';
import { CrimeIndexInYearForState, CriminalOffence, CriminalOffenceCountInYearAndState, CriminalOffenceCountInYearAndStateRequest, CriminalOffenceCountStatesRequest, ReportedYears, State, StateCompareCountForCategory, StateCompareIndexForCategory, StateCompareRequest, Statistics, TotalCrimesInYearForState } from '../../../../../interfaces/europe/interfaces';
import './StateCompareCriminalOffences.css';
import { useTranslation } from "react-i18next";
import generatePDF, { usePDF } from 'react-to-pdf';


export default function StateCompareCriminalOffences() {

    const targetRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation("europe");

    const columnsOfIndexes: GridColDef[] = [

        {
            field: 'name', headerName: 'Criminal offence', width: 300,
            flex: 1, renderCell: (params) => {
                return <span>{params.row.criminalOffence.name}</span>;
            }
        },
        {
            field: 'name', headerName: 'stat 1', width: 300,
            flex: 1, renderCell: (params) => {
                if (params.row.statesIndexes.stateId == 5) {
                    return <span className="box-danger"> +{params.row.statesIndexes.index} %</span>;
                }
            }
        }
        /* { field: 'index', headerName: 'State index', flex: 1, align: 'center', headerAlign: 'center', description: 'State index represent crime index in state.', type: 'number' },
         { field: 'indexEurope', headerName: 'Europe index', flex: 1, align: 'center', headerAlign: 'center', description: 'Europe index represent crime index in europe.' },
         {
             field: 'indexToEuropePercentageComparison', headerName: 'Comparison', align: 'center', headerAlign: 'center',
             flex: 1, renderCell: (params) => {
                 if (params.row.indexToEuropePercentageComparison > 0) {
                     return <span className="box-danger"> +{params.row.indexToEuropePercentageComparison} %</span>;
                 } else {
                     return <span className="box-success"> {params.row.indexToEuropePercentageComparison} %</span>;
                 }
             }
         },*/
    ];

    const { codes } = useParams<string>();
    const [valueIndexFilterCriminalOffenceCounts, setValueIndexFilterCriminalOffenceCounts] = React.useState('1');
    const [valueIndexFilterCriminalOffenceCountsEvolution, setValueIndexFilterCriminalOffenceCountsEvolution] = React.useState('1');
    const [valueIndexOrCounts, setValueIndexOrCounts] = React.useState('1');

    //RESTS FOR GET
    const [states, setStates] = React.useState<State[]>([]);
    const [reportedYears, setReportedYears] = React.useState<ReportedYears>();

    //RESTS POST 
    let stateCompareRequest: StateCompareRequest = { "states": [] };
    let requestFilterCount: CriminalOffenceCountInYearAndStateRequest = { "year": 2010, "code": "T11TC", "stateIds": [] };
    let stateCompareTotalCountRequest: CriminalOffenceCountStatesRequest = { "stateIds": [], "code": "T11TC" }

    const [stateIds, setStateIds] = React.useState<number[]>([]);
    const [stateTotalCriminalOffences, setStateTotalCriminalOffences] = React.useState<TotalCrimesInYearForState[]>([]);
    const [stateTotalCrimeIndexes, setStateTotalCrimeIndexes] = React.useState<CrimeIndexInYearForState[]>([]);
    const [stateIndexesByCategory, setStateIndexesByCategory] = React.useState<StateCompareIndexForCategory[]>([]);
    const [stateCountsByCategory, setStateCountsByCategory] = React.useState<StateCompareCountForCategory[]>([]);
    const [criminalOffencesCode, setCriminalOffenceCode] = React.useState<string>('T11TC');
    const [criminalOffencesCodeEvolution, setCriminalOffenceCodeEvolution] = React.useState<string>('T11TC');
    const [criminalOffenceCounts, setCriminalOffenceCounts] = React.useState<CriminalOffenceCountInYearAndState[]>();
    const [yearsResult, setYear] = React.useState<number>(2010);
    const [criminalOffences, setCriminalOffences] = React.useState<CriminalOffence[]>([]);
    const [statistics, setStatistics] = React.useState<Statistics>();
    const [totalSpecificCriminalOffences, setTotalSpecificCriminalOffences] = React.useState<TotalCrimesInYearForState[]>([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL_EU + GET_ALL_STATES).then((response) => {
            setStates(response.data);
        });
    }, []);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + GET_ALL_REPORTED_YEARS).then((response) => {
            setReportedYears(response.data);
        });
    }, []);

    useEffect(() => {
        stateCompareRequest.states = stateIds;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_TOTAL_CO_IN_YEAR_BY_STATE, stateCompareRequest).then((response) => {
            setStateTotalCriminalOffences(response.data);
        });
    }, [stateIds]);

    useEffect(() => {
        stateCompareRequest.states = stateIds;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_CRIME_INDEX_YEAR_BY_STATE, stateCompareRequest).then((response) => {
            setStateTotalCrimeIndexes(response.data);
        });
    }, [stateIds]);

    useEffect(() => {
        stateCompareRequest.states = stateIds;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_CRIME_INDEX_BY_CO, stateCompareRequest).then((response) => {
            setStateIndexesByCategory(response.data);
        });
    }, [stateIds]);

    useEffect(() => {
        stateCompareRequest.states = stateIds;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_CRIME_COUNTS_BY_CO, stateCompareRequest).then((response) => {
            setStateCountsByCategory(response.data);
        });
    }, [stateIds]);

    useEffect(() => {
        requestFilterCount.stateIds = stateIds;
        requestFilterCount.code = criminalOffencesCode;
        requestFilterCount.year = yearsResult;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_CRIME_COUNTS, requestFilterCount).then((response) => {
            setCriminalOffenceCounts(response.data);
        });
    }, [stateIds, criminalOffencesCode, yearsResult]);

    useEffect(() => {
        requestFilterCount.stateIds = stateIds;
        requestFilterCount.code = criminalOffencesCode;
        requestFilterCount.year = yearsResult;

        axios.post(process.env.REACT_APP_API_URL + POST_STATE_COMPARE_CRIME_COUNTS_STATISTICS, requestFilterCount).then((response) => {
            setStatistics(response.data);
        });
    }, [stateIds, criminalOffencesCode, yearsResult]);

    useEffect(() => {
        stateCompareTotalCountRequest.stateIds = stateIds;
        stateCompareTotalCountRequest.code = criminalOffencesCodeEvolution;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_CRIME_COUNTS_FOR_YEARS, stateCompareTotalCountRequest).then((response) => {
            setTotalSpecificCriminalOffences(response.data);
        });
    }, [stateIds, criminalOffencesCodeEvolution]);

    useEffect(() => {
        if (codes !== undefined) {
            setStateIds([...codes.split(',').map(Number)]);
        }
    }, []);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + GET_ALL_CO).then((response) => {
            setCriminalOffences(response.data);
        });
    }, []);

    const [value, setValue] = React.useState(1);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleStateChange = (event: SelectChangeEvent<typeof stateIds>) => {
        const {
            target: { value },
        } = event;
        setStateIds(value as number[]);
    };

    const handleYearChange = (event: SelectChangeEvent<typeof yearsResult>) => {
        const {
            target: { value },
        } = event;
        setYear(value as number);
    };

    const handleCriminalOffenceChange = (event: SelectChangeEvent) => {
        setCriminalOffenceCode(event.target.value as string);
    };

    const handleCriminalOffenceEvolutionChange = (event: SelectChangeEvent) => {
        setCriminalOffenceCodeEvolution(event.target.value as string);
    };

    const handleChangeCrimeOffenceCountsFilter = (_event: React.SyntheticEvent, newValue: string) => {
        setValueIndexFilterCriminalOffenceCounts(newValue);
    };

    const handleChangeCrimeOffenceCountsFilterEvolution = (_event: React.SyntheticEvent, newValue: string) => {
        setValueIndexFilterCriminalOffenceCountsEvolution(newValue);
    };

    const handleChangeCountsOrIndex= (_event: React.SyntheticEvent, newValue: string) => {
        setValueIndexOrCounts(newValue);
    };


    function getState(x: number[], l: number = 3) {
        const filteredCourses = states.filter(state => x.slice(0, 3).some(number => number === state.id));
        const translated = filteredCourses.map(u => t(`states.${u.code}`));
        if (x.length > 3) {
            return translated.join(', ') + ",...";
        }
        return translated.join(', ');
    }

    const columnsOFilteredCOCounts: GridColDef[] = [

        { field: 'year', headerName: 'Year', flex: 1 },
        { field: 'count', headerName: 'Count of criminal offences', flex: 1, align: 'center', headerAlign: 'center', description: 'Count of criminal offences.', type: 'number' },
        { field: 'stateName', headerName: 'State name', flex: 1, align: 'center', headerAlign: 'center', description: 'State name.' },

    ];

    const rowStatistics = [
        { icon: 'Maximum', name: `${t("statistics.maximum")}`, value: round(statistics?.maximum) },
        { icon: 'Minimum', name: `${t("statistics.minimum")}`, value: round(statistics?.minimum) },
        { icon: 'Mean', name: `${t("statistics.mean")}`, value: round(statistics?.mean) },
        { icon: 'Median', name: `${t("statistics.median")}`, value: round(statistics?.median) },
        { icon: 'Variance', name: `${t("statistics.variance")}`, value: round(statistics?.variance) },
        { icon: 'Kurtosis', name: `${t("statistics.kurtosis")}`, value: round(statistics?.kurtosis) },
        { icon: 'StandardDeviation', name: `${t("statistics.standardDeviation")}`, value: round(statistics?.standardDeviation) },
        { icon: 'Skewness', name: `${t("statistics.skewness")}`, value: round(statistics?.skewness) },
        { icon: 'Percentile25', name: `${t("statistics.percentile25")}`, value: round(statistics?.percentile25) },
        { icon: 'Percentile50', name: `${t("statistics.percentile50")}`, value: round(statistics?.percentile50) },
        { icon: 'Percentile75', name: `${t("statistics.percentile75")}`, value: round(statistics?.percentile75) }
    ];

    const columnsStatistics: GridColDef[] = [
        {
            field: 'icon', headerName: '', align: 'center', headerAlign: 'center', width: 75, sortable: false, disableExport: true, cellClassName: 'icon-cell',
            filterable: false, renderCell: (params) => {
                return (
                    <>{getIcon(params.row.icon)}</>
                )
            }
        },
        { field: 'name', headerName: `${t("statistics.statistic")}`, flex: 1, align: 'center', headerAlign: 'center', headerClassName: 'bold-cell', cellClassName: 'bold-cell' },
        { field: 'value', headerName: `${t("statistics.value")}`, width: 200, align: 'right', headerAlign: 'right' }
    ];

    const uniqueYears = Array.from(new Set(totalSpecificCriminalOffences.flatMap((state) => state.data.map((yearData) => yearData.year))));
    const uniqueStates = Array.from(new Set(totalSpecificCriminalOffences.map((state) => state.stateName)));


    const findMin = (arr: any[]) => {
        return arr.reduce((min, current) => (current < min ? current : min), Infinity);
    };
    const findMax = (arr: any[]) => {
        return arr.reduce((max, current) => (current > max ? current : max), -Infinity);
    };

    const rows = uniqueYears.map((year) => {
        const row: { id: string; year: number; stateName: string;[key: string]: number | string } = {
            id: year.toString(),
            year: year,
            stateName: 'States',
        };

        uniqueStates.forEach((state) => {
            const stateData = totalSpecificCriminalOffences.find((s) => s.stateName === state);
            const count = stateData?.data.find((yearData) => yearData.year === year)?.count || 0;

            row[state] = Number(count);
        });

        const valuesForRow = uniqueStates.map((state) => row[state]);
        const minForRow = findMin(valuesForRow);
        const maxForRow = findMax(valuesForRow);
        row['min'] = minForRow;
        row['max'] = maxForRow;

        return row;
    });

    const columns = [
        { field: 'year', headerName: 'Year', width: 120 },
        ...uniqueStates.map((state) => ({
            field: state, headerName: state, flex: 1, minWidth: 100,
            renderCell: (params: any) => {
                const propertyName = params.colDef.headerName;
                const value = params.row[propertyName];

                if (params.row.max === value) {
                    return <span className="box-danger"> {value} </span>;
                } else if (params.row.min === value) {
                    return <span className="box-success"> {value} </span>;
                } else {
                    return <span> {value} </span>;
                }
            }
        })),
    ];


    const updatedCountsData = stateCountsByCategory.map((entry) => {
        const maxIndex = findMax(entry.statesCounts.map((s) => s.count));
        const minIndex = findMin(entry.statesCounts.map((s) => s.count));

        const indexes = entry.statesCounts.map((state, index) => ({
            ...state,
            min: state.count === minIndex,
            max: state.count === maxIndex,
        }));
        return { ...entry, statesCounts: indexes };
    });


    const updatedData = stateIndexesByCategory.map((entry) => {
        const maxIndex = findMax(entry.statesIndexes.map((s) => s.index));
        const minIndex = findMin(entry.statesIndexes.map((s) => s.index));

        const indexes = entry.statesIndexes.map((state, index) => ({
            ...state,
            min: state.index === minIndex,
            max: state.index === maxIndex,
        }));
        return { ...entry, statesIndexes: indexes };
    });

    const uniqueStateNames = Array.from(new Set(updatedData.flatMap(item => item.statesIndexes.map(state => state.name))));

    const columns2: GridColDef[] = [
        { field: 'criminalOffence', headerName: `${t("stateCompare.criminalOffense")}`, align: 'left', headerAlign: 'left', width: 280 },
        ...uniqueStateNames.map(stateName => ({
            field: stateName,
            headerName: stateName,
            flex: 1,
            renderCell: (params: any) => {
                const propertyName = params.colDef.headerName;
                const value = params.row[propertyName];

                if (params.row.max === value) {
                    const val = Number(value.toFixed(2));

                    return <span className="box-danger"> {val} </span>;
                } else if (params.row.min === value) {
                    const val = Number(value.toFixed(2));

                    return <span className="box-success"> {val} </span>;
                } else {
                    const val = Number(value.toFixed(2));

                    return <span> {val} </span>;
                }
            }

        })),
    ];

    const columns3: GridColDef[] = [
        { field: 'criminalOffence', headerName: `${t("stateCompare.criminalOffense")}`, align: 'left', headerAlign: 'left', width: 280 },
        ...uniqueStateNames.map(stateName => ({
            field: stateName,
            headerName: stateName,
            flex: 1,
            renderCell: (params: any) => {
                const propertyName = params.colDef.headerName;
                const value = params.row[propertyName];

                if (params.row.max === value) {
                    const val = Number(value.toFixed(2));

                    return <span className="box-danger"> {val} </span>;
                } else if (params.row.min === value) {
                    const val = Number(value.toFixed(2));

                    return <span className="box-success"> {val} </span>;
                } else {
                    const val = Number(value.toFixed(2));

                    return <span> {val} </span>;
                }
            }

        })),
    ];

    interface RowData {
        id: string;
        criminalOffence: string;
        [stateName: string]: string | number | DoubleRange; 
    }

    const rows2: RowData[] = updatedData.map(item => {

        const maxTrueObject = item.statesIndexes.find(state => state.max === true);
        const minTrueObject = item.statesIndexes.find(state => state.min === true);

        const row: RowData = { id: item.criminalOffence.code, criminalOffence: t(`criminalOffence.${item.criminalOffence.code}.name`), className: '', min: minTrueObject?.index ?? 0, max: maxTrueObject?.index ?? 0 };
        item.statesIndexes.forEach(state => {
            row[state.name] = state.index;
        });

        return row;
    });

    const rows3: RowData[] = updatedCountsData.map(item => {

        const maxTrueObject = item.statesCounts.find(state => state.max === true);
        const minTrueObject = item.statesCounts.find(state => state.min === true);

        const row: RowData = { id: item.criminalOffence.code, criminalOffence: t(`criminalOffence.${item.criminalOffence.code}.name`), className: '', min: minTrueObject?.count ?? 0, max: maxTrueObject?.count ?? 0 };
        item.statesCounts.forEach(state => {
            row[state.name] = state.count;
        });

        return row;
    });

    return (
        <>
            <HeaderCarousel name={t(`stateCompare.title`)} image={image} />

            <div className='underLabel pb-2'>
                {stateIds.length != 0 ? <button className='btn btn-secondary' onClick={() => generatePDF(targetRef, {filename: 'state-compare.pdf'})}>{t("stateCompare.downloadPDF")}</button> : <div></div> }
                <p>{t("stateCompare.subTitle")}</p>
            </div>
            <div className='d-inline w-100'>
                <FormControl sx={{ m: 1, width: 500 }}>
                    <InputLabel id="multiple-checkbox-label-states">{t("stateCompare.states")}</InputLabel>
                    <Select
                        labelId="multiple-checkbox-label-states"
                        id="multiple-checkbox-states"
                        multiple
                        value={stateIds}
                        onChange={handleStateChange}
                        input={<OutlinedInput label="States" />}
                        renderValue={selected => getState(selected)}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                    width: 250,
                                },
                            }
                        }
                        }
                    >
                        {states.map((state) => (
                            <MenuItem key={state.name.toString()} value={state.id} >
                                <Checkbox checked={stateIds.indexOf(state.id) > -1} />
                                <ListItemText primary={t(`states.${state.code}`)} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            {stateIds.length != 0 ?
                <div>
                    <div>
                        <Box sx={{ width: '100%' }}>
                            <Tabs
                                onChange={handleChange}
                                value={value}
                                aria-label="Tabs where selection follows focus"
                                selectionFollowsFocus>
                                <Link to={LINK_STATE_COMAPRE_INFO + stateIds}><Tab label={t(`stateInfo.information`)}></Tab></Link>
                                <Link to={LINK_STATE_COMAPRE_CO + stateIds} className="text-primary "><Tab label={t(`stateInfo.criminalOffences`)} className="font-weight-bold"></Tab></Link>
                                <Link to={LINK_STATE_COMAPRE_POLICE + stateIds}><Tab label={t(`stateInfo.police`)} ></Tab></Link>
                                <Link to={LINK_STATE_COMAPRE_CONVICTED + stateIds}><Tab label={t(`stateInfo.convictedCriminalOffences`)} ></Tab></Link>
                                <Link to={LINK_STATE_COMAPRE_PRISON + stateIds}><Tab label={t(`stateInfo.prison`)}></Tab></Link>

                            </Tabs>
                        </Box>
                    </div>
                    <div ref={targetRef}>
                        <div className="my-3 col-8">
                            <h5>{getState(stateIds, 10)}</h5>
                            <h3 className="mb-5 trendLabel">{t("stateCompare.crimes.title1")}</h3>
                            <div className="">
                                <ResponsiveContainer width='100%' height={500}>
                                    <LineChart
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}>
                                        <CartesianGrid strokeDasharray="2 2" />
                                        <XAxis allowDuplicatedCategory={false} dataKey="year" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        {
                                            stateTotalCriminalOffences.map((state, index) => {
                                                return (<Line key={state.stateId.toString()} name={state.stateName} data={state.data} dataKey="count" stroke={COLORS[index % COLORS.length]} strokeWidth={2} activeDot={{ r: 5 }}></Line>)
                                            })
                                        }
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className="my-5 col-8 w-100 h-100">
                            <h3 className="mb-5 trendLabel">{t("stateCompare.crimes.title2")}</h3>
                            <div className="w-100 h-100">
                                <ResponsiveContainer width='100%' height={500}>
                                    <LineChart
                                        
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}>
                                        <CartesianGrid strokeDasharray="2 2" />
                                        <XAxis allowDuplicatedCategory={false} dataKey="year" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        {
                                            stateTotalCrimeIndexes.map((state, index) => {
                                                return (<Line key={state.stateId.toString()} name={state.stateName} data={state.data} dataKey="index" stroke={COLORS[index % COLORS.length]} strokeWidth={2} activeDot={{ r: 5 }}></Line>)
                                            })
                                        }
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className="my-5 col-8 w-100 h-100">

                            <h3 className="mb-5 trendLabel">{t("stateCompare.crimes.title3")}</h3>
                            <ResponsiveContainer width='100%' height={750}>
                                <TabContext value={valueIndexOrCounts}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChangeCountsOrIndex} aria-label="lab API tabs example" className="revertTabs">
                                            <Tab icon={<FaTable />} value="1" />
                                            <Tab icon={<FaTable />} value="2" />
                                        
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                    <h5 className="mb-3 trendLabel">{t("stateCompare.crimes.title3small")}</h5>
                                        <div className="w-100 h-100">
                                            <div className="pb-3" style={{ height: 700, width: '60vw' }}>
                                                <DataGrid
                                                    rows={rows2}
                                                    columns={columns2}
                                                    disableColumnSelector
                                                    disableSelectionOnClick={true}
                                                    pageSize={TABLE_PAGE_SIZE_TEN}
                                                    autoHeight={true}
                                                    getRowId={(row) => row.id}
                                                    components={{
                                                        Toolbar: CustomToolbar,
                                                    }}
                                                    componentsProps={{ toolbar: { allColumns: true } }}
                                                />
                                            </div>
                                        </div>

                                    </TabPanel>

                                    <TabPanel value="2">
                                    <h5 className="mb-3 trendLabel">{t("stateCompare.crimes.title6")}</h5>

                                        <div className="w-100 h-100">
                                            <div className="pb-3" style={{ padding: 24, height: 700, width: '60vw' }}>
                                                <DataGrid
                                                    rows={rows3}
                                                    columns={columns3}
                                                    disableColumnSelector
                                                    disableSelectionOnClick={true}
                                                    pageSize={TABLE_PAGE_SIZE_TEN}
                                                    autoHeight={true}
                                                    getRowId={(row) => row.id}
                                                    components={{
                                                        Toolbar: CustomToolbar,
                                                    }}
                                                    componentsProps={{ toolbar: { allColumns: true } }}
                                                />
                                            </div>
                                        </div>

                                    </TabPanel>

                                    
                                </TabContext>
                            </ResponsiveContainer>
                        </div>
                        <div className="my-5 col-8 w-100 h-100">
                            <h3 className="mb-5 trendLabel">{t("stateCompare.crimes.title4")}</h3>
                            <div>
                                <div className='d-inline'>
                                    
                                    <FormControl sx={{ m: 1, width: 1, height: 'fit-content', marginTop: 3, marginBottom: 3 }}>
                                        <InputLabel id="demo-simple-select-label">{t("stateCompare.criminalOffences")}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="selectCriminalOffenceFilter"
                                            value={criminalOffencesCode}
                                            label="Criminal offences"
                                            onChange={handleCriminalOffenceChange}
                                            defaultValue={"T11TC"}
                                        >
                                            {criminalOffences.map((criminal) => (
                                                <MenuItem key={criminal.code} value={criminal.code}>
                                                    <ListItemText primary={t(`criminalOffence.${criminal.code}.name`)} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    
                                </div>
                                <div className='d-inline'>
                                    
                                    <FormControl sx={{ m: 1, width: 1, marginTop: 3, marginBottom: 3 }}>
                                        <InputLabel id="simple-select-years">{t("map.years")}</InputLabel>
                                        <Select
                                            labelId="simple-select-years"
                                            id="simple-select-years"
                                            value={yearsResult}
                                            onChange={(e) => { handleYearChange(e); }}
                                            input={<OutlinedInput label="Years" />}
                                            defaultValue={2010}
                                            sx={{ height: '56px' }}
                                        >
                                            {reportedYears?.years.map((year) => (
                                                <MenuItem key={year.toString()} value={year}>
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    
                                </div>
                            </div>
                            <div>
                                <ResponsiveContainer width='100%' height={500}>
                                    <TabContext value={valueIndexFilterCriminalOffenceCounts}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChangeCrimeOffenceCountsFilter} aria-label="lab API tabs example" className="revertTabs">
                                                <Tab icon={<FaChartArea />} value="1" />
                                                <Tab icon={<FaTable />} value="2" />
                                                <Tab icon={<FaInfo />} value="3" />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1">
                                            <BarChart
                                                width={600}
                                                height={370}
                                                data={criminalOffenceCounts}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 0,
                                                    bottom: 5,
                                                }}>
                                                <CartesianGrid strokeDasharray="5 5 " />
                                                <XAxis dataKey="stateName" height={130} angle={-30} interval={0} textAnchor="end" />
                                                <YAxis />
                                                <Tooltip />
                                                <Bar dataKey="count" fill="#00a0dc" >
                                                    {criminalOffenceCounts?.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                    ))}
                                                </Bar>
                                            </BarChart>
                                        </TabPanel>
                                        <TabPanel value="2">
                                            <div style={{ height: 370, width: '100%' }}>
                                                {criminalOffenceCounts !== undefined ?
                                                    <DataGrid
                                                        rows={criminalOffenceCounts}
                                                        columns={columnsOFilteredCOCounts}
                                                        disableColumnSelector
                                                        disableSelectionOnClick={true}
                                                        pageSize={5}
                                                        autoHeight={true}
                                                        getRowId={(row) => row.stateId}
                                                        components={{
                                                            Toolbar: CustomToolbar,
                                                        }}
                                                        componentsProps={{ toolbar: { allColumns: true } }}
                                                    /> : <></>}
                                            </div>
                                        </TabPanel>
                                        <TabPanel value="3">
                                            <div style={{ height: 370, width: '100%' }}>
                                                <div style={{ height: 370, width: '100%' }}>
                                                    {<DataGrid
                                                        rows={rowStatistics}
                                                        columns={columnsStatistics}
                                                        disableColumnSelector
                                                        disableSelectionOnClick={true}
                                                        pageSize={TABLE_PAGE_SIZE_5}
                                                        autoHeight={true}
                                                        getRowId={(row) => row.name}

                                                        initialState={{
                                                            sorting: {
                                                                sortModel: [{ field: 'index', sort: 'desc' }],
                                                            },
                                                        }}
                                                        components={{
                                                            Toolbar: CustomToolbar,
                                                        }}
                                                        componentsProps={{ toolbar: { allColumns: true } }}
                                                    />}
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </TabContext>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className="my-5 col-8 w-100 h-100">
                            <h3 className="mb-5 trendLabel">{t("stateCompare.crimes.title5")}</h3>

                            <div>
                                <FormControl sx={{ m: 1, width: 384, height: 'fit-content', marginTop: 3, marginBottom: 3 }}>
                                    <InputLabel id="demo-simple-select-label">{t("stateCompare.criminalOffences")}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="selectCriminalOffenceFilter"
                                        value={criminalOffencesCodeEvolution}
                                        label="Criminal offences"
                                        onChange={handleCriminalOffenceEvolutionChange}
                                        defaultValue={"T11TC"}
                                    >
                                        {criminalOffences.map((criminal) => (
                                            <MenuItem key={criminal.code} value={criminal.code}>
                                                <ListItemText primary={t(`criminalOffence.${criminal.code}.name`)} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                <ResponsiveContainer width='100%' height={300}>
                                    <TabContext value={valueIndexFilterCriminalOffenceCountsEvolution}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChangeCrimeOffenceCountsFilterEvolution} aria-label="lab API tabs example" className="revertTabs">
                                                <Tab icon={<FaChartArea />} value="1" />
                                                <Tab icon={<FaTable />} value="2" />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1">
                                            <LineChart
                                                width={600}
                                                height={370}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 0,
                                                    bottom: 5,
                                                }}>
                                                <CartesianGrid strokeDasharray="2 2" />
                                                <XAxis allowDuplicatedCategory={false} dataKey="year" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                {
                                                    totalSpecificCriminalOffences.map((state, index) => {
                                                        return (<Line key={state.stateId.toString()} name={state.stateName} data={state.data} dataKey="count" stroke={COLORS[index % COLORS.length]} strokeWidth={2} activeDot={{ r: 5 }}></Line>)
                                                    })
                                                }
                                            </LineChart>
                                        </TabPanel>
                                        <TabPanel value="2">
                                            <div style={{ height: 370, width: '100%' }}>
                                                <DataGrid
                                                    rows={rows}
                                                    columns={columns}
                                                    disableColumnSelector
                                                    disableSelectionOnClick={true}
                                                    pageSize={TABLE_PAGE_SIZE_5}
                                                    autoHeight={true}
                                                    getRowId={(row) => row.id}
                                                    components={{
                                                        Toolbar: CustomToolbar,
                                                    }}
                                                    componentsProps={{ toolbar: { allColumns: true } }}
                                                />
                                            </div>
                                        </TabPanel>
                                    </TabContext>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div></div>
            }


        </>

    );
}