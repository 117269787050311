import { Box, Tab, Tabs } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { GET_STATE_INFO, CODE_PARAM, CO_PARAM, POLICE_PARAM, CONVICTED_PARAM, PRISON_PARAM, STATE_INFO_SITE, POST_STATE_COMPARE_PRISON_COUNTS, POST_STATE_COMPARE_PRISON_INFO } from "../../../../../interfaces/europe/Constants";
import { getImage } from "../../../../../interfaces/europe/Functions";
import { PrisonCount, PrisonForState, StateCompareRequest, StateInfoDto } from "../../../../../interfaces/europe/interfaces";
import './StatePrison.css';
import { useTranslation } from "react-i18next";


export default function StatePrison() {
    const { t } = useTranslation("europe");

    const { id, code } = useParams();

    //RESTS
    const [stateInfo, setStateInfo] = React.useState<StateInfoDto>();
    const [prisonCount, setPrisonCount] = React.useState<PrisonCount[]>([]);
    const [prisonInfo, setPrisonInfo] = React.useState<PrisonForState | null>(null);

    var flag = getImage(code);


    //GET
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + GET_STATE_INFO + id).then((response) => {
            setStateInfo(response.data);
        });
    }, []);

    //POST
    let idArray: (string | undefined)[] = [id];

    let numberArray: number[] = idArray
    .filter((id): id is string => id !== undefined) // Filter out undefined values
    .map((id) => parseInt(id!, 10));

    let stateFilter: StateCompareRequest = { "states": numberArray }

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + POST_STATE_COMPARE_PRISON_COUNTS, stateFilter).then((response) => {
            setPrisonCount(response.data[0].data);
        });
    }, []);

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + POST_STATE_COMPARE_PRISON_INFO, stateFilter).then((response) => {
            setPrisonInfo(response.data[0]);
        });
    }, []);


    // PAGING
    const [valueMainTab, setValueMainTabe] = React.useState(4);

    const handleChangeMain = (_event: React.SyntheticEvent, newValue: number) => {
        setValueMainTabe(newValue);
    };

    return (
        <>
            <div className="returnLabel"><Link to="/europe/states"><BsChevronLeft className="returnIcon" />{t(`stateInfo.prison`)}</Link></div>

            <div className="mb-4">
                <h1 className="text-uppercase"><img className="mr-2 flag-title-image" src={flag} alt={"Flag"} /> {stateInfo?.name}</h1>
            </div>

            <Box sx={{ width: '100%' }}>
                <Tabs
                    onChange={handleChangeMain}
                    value={valueMainTab}
                >
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code} ><Tab label={t(`stateInfo.information`)} ></Tab></Link>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code + CO_PARAM}><Tab label={t(`stateInfo.criminalOffences`)} ></Tab></Link>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code + POLICE_PARAM} ><Tab label={t(`stateInfo.police`)}></Tab></Link>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code + CONVICTED_PARAM} ><Tab label={t(`stateInfo.convictedCriminalOffences`)}></Tab></Link>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code + PRISON_PARAM} className="text-primary"><Tab label={t(`stateInfo.prison`)} className="font-weight-bold"></Tab></Link>

                </Tabs>
            </Box>

            {(prisonInfo && prisonCount && prisonCount.length > 0) && (
                <div className="my-5 col-8">
                    <h3>{t("prison.additionalinfo")}</h3>
                    <table className="table table-striped mt-2">
                        <tbody>
                        <tr>
                            <th className="col-6">{t("prison.preTrialDetainees")}</th>
                            <th className="text-center">{prisonInfo.preTrialDetaineesPercentage}%</th>
                        </tr>
                        <tr>
                            <th className="col-6">{t("prison.femalePrisoners")}</th>
                            <th className="text-center">{prisonInfo.femalePrisonersPercentage}%</th>
                        </tr>
                        <tr>
                            <th className="col-6">{t("prison.juvenilesMinorsYoungPrisoners")}</th>
                            <th className="text-center">{prisonInfo.minorsPercentage}%</th>
                        </tr>
                        <tr>
                            <th className="col-6">{t("prison.foreignPrisoners")}</th>
                            <th className="text-center">{prisonInfo.foreignPrisonersPercentage}%</th>
                        </tr>
                        <tr>
                            <th className="col-6">{t("prison.numberOfEstablishments")}</th>
                            <th className="text-center">{prisonInfo.numberOfInstitutions}</th>
                        </tr>
                        <tr>
                            <th className="col-6">{t("prison.officialCapacity")}</th>
                            <th className="text-center">{prisonInfo.officialCapacity}</th>
                        </tr>
                        <tr>
                            <th className="col-6">{t("prison.usage")}</th>
                            <th className="text-center">{Math.round(prisonCount[prisonCount.length - 1].countOfPrisoners / prisonInfo.officialCapacity * 100)}%</th>
                        </tr>
                        </tbody>
                    </table>
                    <div className='info'>
                        {t('prison.asof')} {prisonCount[prisonCount.length - 1].year}
                    </div>

                    <div className='info my-0'>* {t(`prison.percentage`)}</div>

                </div>
            )}

            <div className='mt-5'>
                <h2>{t(`prison.prisonersovertime`)}</h2>
                <ResponsiveContainer width="75%" height={400}>
                    <LineChart data={prisonCount} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="year" />
                    <YAxis domain={["auto", "auto"]} />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="countOfPrisoners" stroke="#0000FF" strokeWidth={2} activeDot={{ r: 5 }} name={`${t("prison.countOfPrisoners")}`}  />
                    </LineChart>
                </ResponsiveContainer>

                <h2>{t(`prison.indexovertime`)}</h2>
                <ResponsiveContainer width="75%" height={400}>
                    <LineChart data={prisonCount} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="year" />
                    <YAxis domain={["auto", "auto"]} />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="indexOfPrisoners" stroke="#0000FF" strokeWidth={2} activeDot={{ r: 5 }} name={`${t("prison.indexOfPrisoners")}`} />
                    </LineChart>
                </ResponsiveContainer>
            </div>

            
            <div className='info'>{t(`statistics.crimeIndex`)}</div>

        </>

    );
}