import { Box, Tab, Tabs } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { Chart } from "react-google-charts";
import { BsChevronLeft } from "react-icons/bs";
import { FaCheckSquare, FaWindowClose } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { CODE_PARAM, COLORS, COUNTRY_EXTERNAL_INFO, CO_PARAM, STATES_SITE, GET_STATE_INFO, STATE_INFO_SITE, POLICE_PARAM, CONVICTED_PARAM, PRISON_PARAM } from "../../../../../interfaces/europe/Constants";
import { getImage } from "../../../../../interfaces/europe/Functions";
import { StateInfoDto } from "../../../../../interfaces/europe/interfaces";
import './StateInfo.css';
import { useTranslation } from "react-i18next";


export default function StateInfo() {

    const { id, code } = useParams();

    const { t } = useTranslation("europe");

    //RESTS
    const [stateInfo, setStateInfo] = React.useState<StateInfoDto>();
    const data = [['Country', 'Population'], [code, 1],];
    const [basicInfo, setBasicInfo] = React.useState<any[]>([]);

    var flag = getImage(code);

    
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + GET_STATE_INFO + id).then((response) => {
            setStateInfo(response.data);
        });
    }, []);

    useEffect(() => {
        axios.get(COUNTRY_EXTERNAL_INFO + code).then((response) => {
            setBasicInfo(response.data);
        });
    }, []);


    //TABLES WITH DATA
    const firearmData = stateInfo?.isFirearms.length !== undefined && stateInfo?.isFirearms.length > 0 ? <table className="table table-striped">
        <thead>
            <tr>
                
                <th scope="col-6">{t("stateCompare.weapon")}</th>
                <th scope="col-6" className="text-center">{t("stateCompare.consideredAWeapon")}</th>
            </tr>
        </thead>
        <tbody>
            {stateInfo?.isFirearms.map(((f) =>
                <tr key={f.name}><th className="col-6">{t(`stateCompare.weapons.${f.firearmId}`)} </th><th className="text-center">{f.allowed === true ? <FaCheckSquare className=" text-success" /> : <FaWindowClose className=" text-danger" />}</th></tr>
            ))}
        </tbody>
    </table> : <span className="ml-4">{t("stateCompare.dataNot")}</span>;

    const drugData = stateInfo?.drogsLimits.length !== undefined && stateInfo?.drogsLimits.length > 0 ? <table className="table table-striped mt-2">
        <thead>
            <tr>
                <th scope="col-6">{t("stateCompare.drug")}</th>
                <th scope="col-6" className="text-center">{t("stateCompare.allowedLimit")}</th>
            </tr>
        </thead>
        <tbody>
            {stateInfo?.drogsLimits.map(((d) =>
                <tr key={d.name}><th className="col-6">{d.name} </th><th className="text-center">{d.allowedLimit} g</th></tr>
            ))}
        </tbody>
    </table> : <span className="ml-4">{t("stateCompare.dataNot")}</span>;

    const BACData = stateInfo?.bac === undefined || stateInfo?.bac === null ? <span className="">{t("stateCompare.dataNot")}</span> : stateInfo?.bac +  ` ${t("stateInfo.perThousand")}`;

    const ageOfConsentData = stateInfo?.ageOfConsent === undefined || stateInfo?.bac === null ? <span className="">{t("stateCompare.dataNot")}</span> : stateInfo?.ageOfConsent + " year";

    const basicInfoTable = basicInfo.length > 0 ? <><h3 className="mb-3 mt-3">{t("stateCompare.basicInfo")}</h3>
        <table className="table table-striped mt-2">
            <tbody>
                <tr><th className="col-6">{t("stateCompare.region")}</th><th className="text-center">{basicInfo[0]!.region === undefined ? "" : basicInfo[0]!.region}</th></tr>
                <tr><th className="col-6">{t("stateCompare.subregion")}</th><th className="text-center">{basicInfo[0]!.subregion === undefined ? "" : basicInfo[0]!.subregion}</th></tr>
                <tr><th className="col-6">{t("stateCompare.area")}</th><th className="text-center">{basicInfo[0]!.area === undefined ? "" : basicInfo[0]!.area} km<sup>2</sup></th></tr>
                <tr><th className="col-6">{t("stateCompare.capital")}</th><th className="text-center">{basicInfo[0]!.capital === undefined ? "" : basicInfo[0]!.capital}</th></tr>
                <tr><th className="col-6">{t("stateCompare.car")}</th><th className="text-center">{basicInfo[0]?.car.side === undefined ? "" : t(`stateCompare.${basicInfo[0]?.car.side}`)}</th></tr>
                <tr><th className="col-6">{t("stateCompare.timezone")}</th><th className="text-center">{basicInfo[0]!.timezones === undefined ? "" : basicInfo[0]!.timezones}</th></tr>
                <tr><th className="col-6">{t("stateCompare.status")}</th><th className="text-center">{basicInfo[0]!.status === undefined ? "" : t(`stateInfo.${basicInfo[0]!.status}`)}</th></tr>
            </tbody>
        </table></> : '';

    //GRAPH OPTIONS
    const options = {
        region: 150,
        datalessRegionColor: '#fff',
        colorAxis: {
            colors: [COLORS[0], COLORS[0], COLORS[0]]
        },
        'tooltip': {
            trigger: 'none'
        }, legend: 'none'
    }

    //PAGINATION
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>

            <div className="returnLabel"><Link to={STATES_SITE}><BsChevronLeft className="returnIcon" />{t("stateCompare.states")}</Link></div>

            <div className="mb-4">
                <h1 className="text-uppercase"><img className="mr-2 flag-title-image" src={flag} alt={"Flag"} /> {stateInfo?.name}</h1>
            </div>


            <Box sx={{ width: '100%' }}>
                <Tabs
                    onChange={handleChange}
                    value={value}
                    aria-label="Tabs where selection follows focus"
                    selectionFollowsFocus>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code} className="text-primary "><Tab label={t("stateCompare.information")} className="font-weight-bold" ></Tab></Link>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code + CO_PARAM}><Tab label={t("stateCompare.criminalOffences")} ></Tab></Link>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code + POLICE_PARAM} ><Tab label={t("stateInfo.police")}></Tab></Link>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code + CONVICTED_PARAM} ><Tab label={t("stateInfo.convictedCriminalOffences")}></Tab></Link>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code + PRISON_PARAM} ><Tab label={t(`stateInfo.prison`)}></Tab></Link>


                </Tabs>
            </Box>

            <div className="my-3 col-8">
                <h3 className="mb-3">{t("stateCompare.location")}</h3>
                <Chart chartType="GeoChart" width="900px" height="400px" data={data} options={options} />
                <div>
                    {basicInfoTable}
                </div>
            </div>
            <div className="my-5 col-8 w-100 h-100">
                <h3 className="mb-4">{t("stateCompare.population")}</h3>
                <div className="w-100 h-100">
                    <LineChart
                        width={800}
                        height={400}
                        data={stateInfo?.population}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="2 2" />
                        <XAxis dataKey="year" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="population" name={t('stateInfo.population') + ""} stroke="#00a0dc" strokeWidth={2} activeDot={{ r: 5 }}></Line>
                    </LineChart>
                </div>
            </div>
            <div className="mb-5 col-8">
                <h3 className="mb-4">{t("stateCompare.rulebook")}</h3>
                <table className="table table-striped mt-2">
                    <tbody>
                        <tr><th className="col-6">BAC</th><th className="text-center">{BACData}</th></tr>
                        <tr><th className="col-6">{t("stateCompare.ageOfConsent")}</th><th className="text-center">{ageOfConsentData}</th></tr>
                    </tbody>
                </table>
            </div>
            <div className="mb-5 col-8">
                <h3 className="mb-4">{t("stateCompare.legalLimitOfDrugs")}</h3>
                {drugData}
            </div>
            <div className="mb-5 col-8">
                <h3 className="mb-4">{t("stateCompare.consideredAWeapon")}</h3>
                {firearmData}
            </div>
        </>

    );
}