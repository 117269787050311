
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Card, CardContent, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Pagination, Select, SelectChangeEvent, Stack, Tab, Tabs, Typography } from "@mui/material";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from "axios";
import React, { useEffect } from "react";
import { BsChevronLeft, BsSquare } from "react-icons/bs";
import { FaChartArea, FaChartBar, FaChartLine, FaChartPie, FaFingerprint, FaInfo, FaTable } from "react-icons/fa";
import { FiTrendingUp } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { Bar, BarChart, CartesianGrid, Cell, ComposedChart, Legend, Line, LineChart, Pie, PieChart, Tooltip, XAxis, YAxis } from "recharts";
import { COLORS, COUNT_OF_CARDS, GET_COUNT_OF_CO_BY_STATE_ID, GET_INDEXES_OF_CO_BY_STATE_ID, GET_LAST_INDEXES_CO_BY_STATE_ID, PRIMARY_COLOR, GET_STATE_INFO, GET_TOTAL_CO_IN_YEAR_BY_STATE_ID, GET_ALL_CO, ITEM_HEIGHT, ITEM_PADDING_TOP, POST_FILTER_CO_LINK, POST_FILTER_CO_IN_STATE_LINK, TABLE_PAGE_SIZE_15, POST_FILTER_CO_IN_STATE_STATS_LINK, CODE_PARAM, POLICE_PARAM, STATE_INFO_SITE, CONVICTED_PARAM, PRISON_PARAM } from "../../../../../interfaces/europe/Constants";
import { CustomToolbar, getIcon, getImage, numberWithSpaces, round } from "../../../../../interfaces/europe/Functions";
import { CountsOfCrimeOffences, CriminalOffence, FilterCriminalOffencesByStateRequest, FilterCriminalOffencesByStateResponse, FilterCriminalOffencesResponse, IndexesOfCrimeOffences, LastIndexesOfCrimeOffences, PoliceForState, StateInfoDto, Statistics, TotalCrimesInYear } from "../../../../../interfaces/europe/interfaces";
import './StateCrimesOffences.css';
import { useTranslation } from "react-i18next";


export default function StateCrimesOffences() {
    const { t } = useTranslation("europe");

    const { id, code } = useParams();

    //RESTS
    const [stateInfo, setStateInfo] = React.useState<StateInfoDto>();
    const [totalCrimesPerYear, setTotalCrimesPerYear] = React.useState<TotalCrimesInYear[]>([]);
    const [countsOfCrimeOffences, setCountsOfCrimeOffences] = React.useState<CountsOfCrimeOffences[]>([]);
    const [indexesOfCrimeOffences, setIndexesOfCrimeOffences] = React.useState<IndexesOfCrimeOffences[]>([]);
    const [lastIndexesOfCrimeOffences, setLastIndexesOfCrimeOffences] = React.useState<LastIndexesOfCrimeOffences[]>([]);
    const [criminalOffences, setCriminalOffences] = React.useState<CriminalOffence[]>([]);
    const [statistics, setStatistics] = React.useState<Statistics>();
    const [policeForState, setPoliceForState] = React.useState<PoliceForState>();
    

    var flag = getImage(code);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + GET_TOTAL_CO_IN_YEAR_BY_STATE_ID + id).then((response) => {
            setTotalCrimesPerYear(response.data);
        });
    }, []);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + GET_COUNT_OF_CO_BY_STATE_ID + id).then((response) => {
            setCountsOfCrimeOffences(response.data);
        });
    }, []);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + GET_STATE_INFO + id).then((response) => {
            setStateInfo(response.data);
        });
    }, []);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + GET_INDEXES_OF_CO_BY_STATE_ID + id).then((response) => {
            setIndexesOfCrimeOffences(response.data);
        });
    }, []);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + GET_LAST_INDEXES_CO_BY_STATE_ID + id).then((response) => {
            setLastIndexesOfCrimeOffences(response.data);
        });
    }, []);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + GET_ALL_CO).then((response) => {
            setCriminalOffences(response.data);
        });
    }, []);

    const translatedRows = indexesOfCrimeOffences.map(row => ({
        ...row,
        name: t(`criminalOffence.${row.criminalOffenceCode}.name`),
      }));

    const translatedCountsOfCrimeOffences = countsOfCrimeOffences.map(row => ({
        ...row,
        name: t(`criminalOffence.${row.criminalOffenceCode}.name`),
    }));

    //POST
    const [criminalOffencesCode, setCriminalOffenceCode] = React.useState<string>('T11TC');
    const [filteredCriminalOffence, setFilteredCriminalOffence] = React.useState<FilterCriminalOffencesByStateResponse>();
    let requestFilter: FilterCriminalOffencesByStateRequest = { "StateId": id !== undefined ? id : '', "Code": 'T11TC' };

    useEffect(() => {
        requestFilter.Code = criminalOffencesCode;

        axios.post(process.env.REACT_APP_API_URL + POST_FILTER_CO_IN_STATE_LINK, requestFilter).then((response) => {
            setFilteredCriminalOffence(response.data);
        });
    }, [criminalOffencesCode]);

    useEffect(() => {
        requestFilter.Code = criminalOffencesCode;

        axios.post(process.env.REACT_APP_API_URL + POST_FILTER_CO_IN_STATE_STATS_LINK, requestFilter).then((response) => {
            setStatistics(response.data);
        });
    }, [criminalOffencesCode]);


    // TABLES DEFININTIONS
    const columns: GridColDef[] = [

        { field: 'order', headerName: `${t("stateCompare.order")}`, width: 75 },
        { field: 'name', headerName: `${t("stateCompare.criminalOffense")}`, width: 300 },
        { field: 'count', headerName: `${t("stateCompare.count")}`, flex: 1 },

    ];

    const columnsOfIndexes: GridColDef[] = [

        { field: 'name', headerName: `${t("stateCompare.criminalOffesne")}`, width: 300 },
        { field: 'index', headerName: `${t("stateCompare.stateIndex")}`, flex: 1, align: 'center', headerAlign: 'center', description: `${t("stateCompare.stateIndexInfo")}`, type: 'number' },
        { field: 'indexEurope', headerName: `${t("stateCompare.europeIndex")}`, flex: 1, align: 'center', headerAlign: 'center', description: `${t("stateCompare.europeIndexInfo")}` },
        {
            field: 'indexToEuropePercentageComparison', headerName: `${t("stateCompare.comparison")}`, align: 'center', headerAlign: 'center',
            flex: 1, renderCell: (params) => {
                if (params.row.indexToEuropePercentageComparison > 0) {
                    return <span className="box-danger"> +{params.row.indexToEuropePercentageComparison} %</span>;
                } else {
                    return <span className="box-success"> {params.row.indexToEuropePercentageComparison} %</span>;
                }
            }
        },
    ];

    const columnsOFilteredCO: GridColDef[] = [

        { field: 'year', headerName: `${t("stateCompare.year")}`, flex: 1 },
        { field: 'index', headerName:  `${t("stateCompare.stateIndex")}`, flex: 1, align: 'center', headerAlign: 'center', description: `${t("stateCompare.stateIndexInfo")}`, type: 'number' },
        { field: 'indexEurope', headerName: `${t("stateCompare.europeIndex")}`, flex: 1, align: 'center', headerAlign: 'center', description: `${t("stateCompare.europeIndexInfo")}` },

    ];

    const columnsStatistics: GridColDef[] = [
        { field: 'icon', headerName: '', align: 'center', headerAlign: 'center', width: 75, sortable: false, disableExport: true,  cellClassName: 'icon-cell', 
        filterable: false, renderCell: (params) => {
            return (
                <>{getIcon(params.row.icon)}</>
            )
        } },
        { field: 'name', headerName: `${t("statistics.statistic")}`, flex: 1, align: 'center', headerAlign: 'center', headerClassName: 'bold-cell', cellClassName: 'bold-cell' },
        { field: 'value', headerName: `${t("statistics.value")}`, width: 200, align: 'right', headerAlign: 'right' }
    ];

    const rowStatistics = [
        { icon: 'Maximum', name: `${t("statistics.maximum")}`, value: round(statistics?.maximum) },
        { icon: 'Minimum', name: `${t("statistics.minimum")}`, value: round(statistics?.minimum) },
        { icon: 'Mean', name: `${t("statistics.mean")}`, value: round(statistics?.mean) },
        { icon: 'Median', name: `${t("statistics.median")}`, value: round(statistics?.median) },
        { icon: 'Variance', name: `${t("statistics.variance")}`, value: round(statistics?.variance) },
        { icon: 'Kurtosis', name: `${t("statistics.kurtosis")}`, value: round(statistics?.kurtosis) },
        { icon: 'StandardDeviation', name: `${t("statistics.standardDeviation")}`, value: round(statistics?.standardDeviation) },
        { icon: 'Skewness', name: `${t("statistics.skewness")}`, value: round(statistics?.skewness) },
        { icon: 'Percentile25', name: `${t("statistics.percentile25")}`, value: round(statistics?.percentile25) },
        { icon: 'Percentile50', name: `${t("statistics.percentile50")}`, value: round(statistics?.percentile50) },
        { icon: 'Percentile75', name: `${t("statistics.percentile75")}`, value: round(statistics?.percentile75) }
    ];

    // PAGING
    const [valueMainTab, setValueMainTabe] = React.useState(1);

    const [page, setPage] = React.useState(1);
    const [value, setValue] = React.useState('1');
    const [valueIndexComparisonWithEurope, setValueIndexComparisonWithEurope] = React.useState('1');
    const [valueIndeFilterCriminalOffenceEurope, setValueIndeFilterCriminalOffenceEurope] = React.useState('1');

    const handleChangePage = (_event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handleChangeEuropeIndex = (_event: React.SyntheticEvent, newValue: string) => {
        setValueIndexComparisonWithEurope(newValue);
    };

    const handleChangeCrimeOffenceFilter = (_event: React.SyntheticEvent, newValue: string) => {
        setValueIndeFilterCriminalOffenceEurope(newValue);
    };

    const handleChangeMain = (_event: React.SyntheticEvent, newValue: number) => {
        setValueMainTabe(newValue);
    };




    //CARD CAROUSEL
    const pageSize = Math.round(lastIndexesOfCrimeOffences.length / COUNT_OF_CARDS);
    const card1 = lastIndexesOfCrimeOffences !== undefined ? lastIndexesOfCrimeOffences.at(3 * (page - 1)) : undefined;
    const card2 = lastIndexesOfCrimeOffences !== undefined ? lastIndexesOfCrimeOffences.at(3 * (page - 1) + 1) : undefined;
    const card3 = lastIndexesOfCrimeOffences !== undefined ? lastIndexesOfCrimeOffences.at(3 * (page - 1) + 2) : undefined;



    const handleCriminalOffenceChange = (event: SelectChangeEvent) => {
        setCriminalOffenceCode(event.target.value as string);
    };

    const filteredTableRows = filteredCriminalOffence?.indexes;

    return (
        <>
            <div className="returnLabel"><Link to="/europe/states"><BsChevronLeft className="returnIcon" />{t(`stateCompare.states`)}</Link></div>

            <div className="mb-4">
                <h1 className="text-uppercase"><img className="mr-2 flag-title-image" src={flag} alt={"Flag"} /> {t(`states.${stateInfo?.code}`)}</h1>
            </div>

            <Box sx={{ width: '100%' }}>
                <Tabs
                    onChange={handleChangeMain}
                    value={valueMainTab}
                >
                    <Link to={"/europe/states/info/id=" + id + "/code=" + code}><Tab label={t("stateCompare.information")}></Tab></Link>
                    <Link to={"/europe/states/info/id=" + id + "/code=" + code + "/crimeoffences"} className="text-primary"><Tab label={t("stateCompare.criminalOffences")}></Tab></Link>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code + POLICE_PARAM} ><Tab label={t(`stateInfo.police`)}></Tab></Link>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code + CONVICTED_PARAM} ><Tab label={t(`stateInfo.convictedCriminalOffences`)}></Tab></Link>
                    <Link to={STATE_INFO_SITE + id + CODE_PARAM + code + PRISON_PARAM} ><Tab label={t(`stateInfo.prison`)}></Tab></Link>

                </Tabs>
            </Box>

            <div className="my-5 col-8 w-100 h-100">
                <h3 className="mb-5 trendLabel">{t(`stateCompare.totalPerYears`)}</h3>
                <div className="w-100 h-100">
                    <LineChart
                        width={800}
                        height={300}
                        data={totalCrimesPerYear}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}>
                        <CartesianGrid strokeDasharray="2 2" />
                        <XAxis dataKey="year" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="count" stroke={PRIMARY_COLOR} strokeWidth={2} activeDot={{ r: 5 }}></Line>
                    </LineChart>
                </div>
            </div>


            <div className="my-5 col-10 w-100 h-100">
                <h3 className="mb-4 trendLabel">{t(`stateCompare.reportedForYears`)}</h3>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" className="revertTabs">
                            <Tab icon={<FaChartPie />} value="1" />
                            <Tab icon={<FaTable />} value="2" />

                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <div className="d-flex inline justify-content-start">
                            <PieChart height={450} width={600}>
                                <Tooltip />
                                <Pie data={countsOfCrimeOffences.slice(0, 10)} dataKey="count" nameKey="name" cx={300}
                                    cy={200}
                                    innerRadius={110}
                                    outerRadius={140}

                                    paddingAngle={5}

                                    label

                                >
                                    {countsOfCrimeOffences.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>

                            </PieChart>


                            <div className="mt-3 ">
                                <strong> <h4 className="mb-4 trendLabel"><FiTrendingUp className="labelIcon" /> {t(`stateCompare.top10crimes`)} </h4></strong>
                                <div className="verticalLine">
                                    {countsOfCrimeOffences.slice(0, 10).map((c, index) => (
                                        <div key={c.criminalOffenceCode} className="ml-5 my-1 topItems"><BsSquare className="mr-2" style={{ fill: COLORS[index % COLORS.length], background: COLORS[index % COLORS.length] }} /><span>{c.order + ". "}</span><span>{t(`criminalOffence.${c.criminalOffenceCode}.name`) + " - "} </span><span style={{ color: '#555' }}><i>{numberWithSpaces(c.count)}</i></span></div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <h4 className="mb-5 trendLabel"><FaChartBar className="labelIcon" /> {t(`stateCompare.top10counts`)}</h4>
                        <div className="d-flex justify-content-start">
                            <div>
                                <BarChart
                                    width={1000}
                                    height={450}
                                    data={translatedCountsOfCrimeOffences.slice(0, 10)}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}>
                                    <CartesianGrid strokeDasharray="5 5 " />
                                    <XAxis dataKey="name" height={130} angle={-30} interval={0} textAnchor="end" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="count" fill="#00a0dc" >
                                        {countsOfCrimeOffences.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Bar>
                                </BarChart>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value="2">
                        <div style={{ height: 600, width: '100%', marginBottom: 50 }}>
                            <DataGrid

                                rows={countsOfCrimeOffences}
                                columns={columns}
                                disableColumnSelector
                                disableSelectionOnClick={true}
                                pageSize={10}
                                autoHeight={true}
                                getRowId={(row) => row.criminalOffenceCode}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                                componentsProps={{ toolbar: { allColumns: true } }}
                            ></DataGrid>
                        </div>
                    </TabPanel>
                </TabContext>
            </div >
            <div className="my-5 col-10 w-100 h-100">
                <h3 className="mb-4 trendLabel">{t(`stateCompare.comparedToEurope`)}</h3>
                <TabContext value={valueIndexComparisonWithEurope}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChangeEuropeIndex} aria-label="lab API tabs example" className="revertTabs">
                            <Tab icon={<FaTable />} value="1" />
                            <Tab icon={<FaChartLine />} value="2" />

                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <div style={{ padding: 24, height: 650, width: '100%', marginBottom: 50 }}>
                            <DataGrid
                                rows={translatedRows}
                                columns={columnsOfIndexes}
                                disableColumnSelector
                                disableSelectionOnClick={true}
                                pageSize={10}
                                autoHeight={true}
                                getRowId={(row) => row.criminalOffenceCode}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                                componentsProps={{ toolbar: { allColumns: true } }}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value="2">
                        <LineChart
                            width={900}
                            height={500}
                            data={indexesOfCrimeOffences}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="2 2" />
                            <XAxis dataKey="name" height={150} angle={-30} interval={0} textAnchor="end" />

                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" name="Index in state" dataKey="index" stroke="#00a0dc" strokeWidth={2} activeDot={{ r: 5 }}></Line>
                            <Line type="monotone" name="Index in europe" dataKey="indexEurope" stroke="#6146d9" strokeWidth={2} activeDot={{ r: 5 }}></Line>
                        </LineChart>
                    </TabPanel>
                </TabContext>
            </div>

            <div className="my-5 col-10 w-100 h-100 ">
                <h3 className="mb-5 trendLabel ">{t(`stateCompare.comparedLastYear`)}</h3>
                <Stack spacing={2} alignItems="center">
                    <div className="d-flex inline justify-content-center cardContainer mt-4">
                        <div>
                            <Card sx={{ width: 310, marginLeft: 1, marginRight: 1 }} className="statCard">

                                <CardContent >

                                    <div className="fingerprintIcon">
                                        <FaFingerprint />
                                    </div>

                                    <div className="cardName" style={{ height: 64 }}>
                                        <Typography className="mb-3" variant="h5" component="div">
                                            {card1 !== undefined ? t(`criminalOffence.${card1.criminalOffenceCode}.name`) : ""}
                                        </Typography>
                                    </div>

                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom className="ml-2">
                                    {t(`stateCompare.lastYearIndex`)} <strong>  {card1 !== undefined ? card1.indexLastYear : ""} </strong> ({card1 !== undefined ? card1.lastYear : ""})
                                    </Typography>


                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom className="ml-2">
                                    {t(`stateCompare.actualIndex`)} <strong>  {card1 !== undefined ? card1.indexActual : ""} </strong> ({card1 !== undefined ? card1.actualYear : ""})
                                    </Typography>

                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom className="text-right mt-4">
                                        {card1 !== undefined ? Number(card1.percentageComparison) <= 0 ? <span className="largebox-success "> {card1.percentageComparison} %</span> : <span className="largebox-danger "> +{card1.percentageComparison} %</span> : ""}

                                    </Typography>
                                </CardContent>

                            </Card>
                        </div>
                        <div>
                            <Card sx={{ width: 310, marginLeft: 1, marginRight: 1 }} className="statCard">
                                <CardContent>

                                    <div className="fingerprintIcon">
                                        <FaFingerprint />
                                    </div>

                                    <div className="cardName" style={{ height: 64 }}>
                                        <Typography className="mb-3" variant="h5" component="div">
                                            {card2 !== undefined ? t(`criminalOffence.${card2.criminalOffenceCode}.name`) : ""}
                                        </Typography>
                                    </div>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom className="ml-2">
                                    {t(`stateCompare.lastYearIndex`)} <strong> {card2 !== undefined ? card2.indexLastYear : ""}</strong> ({card2 !== undefined ? card2.lastYear : ""})
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom className="ml-2">
                                    {t(`stateCompare.actualIndex`)} <strong>{card2 !== undefined ? card2.indexActual : ""} </strong>({card2 !== undefined ? card2.actualYear : ""})
                                    </Typography>

                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom className="text-right mt-4">
                                        {card2 !== undefined ? Number(card2.percentageComparison) <= 0 ? <span className="largebox-success "> {card2.percentageComparison} %</span> : <span className="largebox-danger "> +{card2.percentageComparison} %</span> : ""}
                                    </Typography>
                                </CardContent>

                            </Card>
                        </div>

                        {card3 !== undefined ?
                            <div>
                                <Card sx={{ width: 310, marginLeft: 1, marginRight: 1 }} className="statCard">
                                    <CardContent >

                                        <div className="fingerprintIcon">
                                            <FaFingerprint />
                                        </div>

                                        <div className="cardName" style={{ height: 64 }}>
                                            <Typography className="mb-3" variant="h5" component="div">
                                                {card3 !== undefined ? t(`criminalOffence.${card3.criminalOffenceCode}.name`) : ""}
                                            </Typography>
                                        </div>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom className="ml-2">
                                        {t(`stateCompare.lastYearIndex`)} <strong> {card3 !== undefined ? card3.indexLastYear : ""}</strong> ({card3 !== undefined ? card3.lastYear : ""})
                                        </Typography>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom className="ml-2">
                                        {t(`stateCompare.actualIndex`)} <strong>{card3 !== undefined ? card3.indexActual : ""}</strong> ({card3 !== undefined ? card3.actualYear : ""})
                                        </Typography>

                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom className="text-right mt-4" >
                                            {card3 !== undefined ? Number(card3.percentageComparison) <= 0 ? <span className="largebox-success "> {card3.percentageComparison} %</span> : <span className="largebox-danger "> +{card3.percentageComparison} %</span> : ""}

                                        </Typography>
                                    </CardContent>

                                </Card>
                            </div>
                            : ""}


                    </div>
                    <Pagination className="mt-3" count={pageSize} page={page} onChange={handleChangePage} />
                </Stack>
            </div>

            <div className="my-5 col-10 w-100 h-100 ">
                <h3 className="mb-3 trendLabel ">{t(`stateCompare.filterCriminalOf`)}</h3>

                <FormControl sx={{ m: 1, width: 400, marginTop: 3, marginBottom: 3 }}>
                    <InputLabel id="demo-simple-select-label">{t(`prediction.criminalOffences`)}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="selectCriminalOffenceFilter"
                        value={criminalOffencesCode}
                        label="Criminal offences"
                        onChange={handleCriminalOffenceChange}
                        defaultValue={"T11TC"}
                    >
                        {criminalOffences.map((criminal) => (
                            <MenuItem key={criminal.code} value={criminal.code}>
                                <ListItemText primary={t(`criminalOffence.${criminal.code}.name`)} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>



                <TabContext value={valueIndeFilterCriminalOffenceEurope}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChangeCrimeOffenceFilter} aria-label="lab API tabs example" className="revertTabs">
                            <Tab icon={<FaChartArea />} value="1" />
                            <Tab icon={<FaTable />} value="2" />
                            <Tab icon={<FaInfo />} value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <ComposedChart
                            width={1000}
                            height={250}
                            data={filteredCriminalOffence?.indexes}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}>
                            <CartesianGrid strokeDasharray="5 5 " />
                            <XAxis dataKey="year" height={50} angle={-30} interval={0} textAnchor="end" />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="index" fill="#00a0dc" >
                                {countsOfCrimeOffences.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Bar>
                            <Line type="monotone" name="Index in europe" dataKey="indexEurope" stroke="#111" strokeWidth={3} activeDot={{ r: 5 }}></Line>

                        </ComposedChart>
                    </TabPanel>
                    <TabPanel value="2">
                        <div style={{ height: 370, width: '100%' }}>

                            {filteredTableRows !== undefined ?
                                <DataGrid
                                    rows={filteredTableRows}
                                    columns={columnsOFilteredCO}
                                    disableColumnSelector
                                    disableSelectionOnClick={true}
                                    pageSize={5}
                                    autoHeight={true}
                                    getRowId={(row) => row.year}
                                    components={{
                                        Toolbar: CustomToolbar,
                                    }}
                                    componentsProps={{ toolbar: { allColumns: true } }}
                                /> : <></>}
                        </div>
                    </TabPanel>

                    <TabPanel value="3">
                        <div style={{ height: 720, width: '100%' }}>



                            <div style={{ height: 720, width: '100%' }}>
                                <DataGrid
                                    rows={rowStatistics}
                                    columns={columnsStatistics}
                                    disableColumnSelector
                                    disableSelectionOnClick={true}
                                    pageSize={TABLE_PAGE_SIZE_15}
                                    autoHeight={true}
                                    getRowId={(row) => row.name}

                                    initialState={{
                                        sorting: {
                                            sortModel: [{ field: 'index', sort: 'desc' }],
                                        },
                                    }}
                                    components={{
                                        Toolbar: CustomToolbar,
                                    }}
                                    componentsProps={{ toolbar: { allColumns: true } }}
                                />
                            </div>


                        </div>
                    </TabPanel>
                </TabContext>


                {filteredCriminalOffence?.remark != null ? <div className='info'>
                    <strong> {t(`stateCompare.remark`)}: </strong>{filteredCriminalOffence?.remark}
                </div> : <></>}

            </div>


            <div className='info'> {t(`statistics.crimeIndex`)}</div>

        </>

    );
}

