import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import './TMap.css';
import css from '../../traffic/TReportCenter/Report.module.css';
import {Fragment, useEffect, useState} from "react";
import { HeatmapLayer } from "react-leaflet-heatmap-layer-v3";
import MapServiceTZ from '../../../services/MapServiceTZ';
import Settings from '../../../components/Settings/Settings';
import SFilterSettings from '../../../components/Search/SFilterSettings';
import ShowBox from '../../../components/ShowBox';
import SFilterValueDraw from '../../../components/Search/SFilterValueDraw';
import SearchServiceTZ from '../../../services/SearchServiceTZ';
import { SearchFilter, SearchFilterValue } from '../../../interfaces/SearchFilter';
import filterItemsTrafficAnimals from '../../../interfaces/ConstFilterTrafficAnimals';
import { MdLocalPolice } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';
import { FaMap } from 'react-icons/fa';
import { Grow, GrowProps, Snackbar, Stack } from '@mui/material';
// import CircularProgress from '@mui/material/CircularProgress';


interface TZMapData {
  id: string;
  x: string;
  y: string;
  celkovaHmotnaSkoda: string;
  usmrteneOsoby: string;
  tazkoZraneneOsoby: string;
  lahkoZraneneOsoby: string;
  pricinaNehody: string;
  druhZveri: string;
  datum: string;
  cas: string;
  zavaznost: string;
  kraj: string;
  viditelnost: string;
  alkohol: string;
  povrchVozovky: string;
  znackaVozidla: string;
}

export default function TZMap() {
  const { t } = useTranslation("traffic");
  const [ mapDataTZ, setMapDataTZ ] = useState<TZMapData[] | null>(null);
  const [ filter, setFilter ] = useState<SearchFilter[]>(filterItemsTrafficAnimals);
  const [ filterValues, setFilterValues ] = useState<SearchFilterValue[]>([{
    type: {
        value: "AND",
        label: "A súčastne"
    },
    value: [
        {
            type: {
                value: "AND",
                label: "A súčastne"
            },
            atribute: {
                value: "datum",
                label: "Dátum"
            },
            option: {
                value: "DATE_BETWEEN",
                label: "je v rozsahu dátumov"
            },
            value: [
                "2022-01-01T00:00:00.000Z",
                "2022-12-30T23:59:59.000Z"
            ]
        }
    ]
  }]);

  const [ loading , setLoading ] = useState(true);
  const [ showHeatmap, setShowHeatmap ] = useState(true);
  const [ showSnackbar, setShowSnackbar ] = useState(false);
  const [ countValues, setCountValues ] = useState(0);
  const [progress, setProgress] = useState(0);

  const handleFilterValuesChange = (newValues: SearchFilterValue[]) => {
    setFilterValues(newValues);
  }

  const handleFilterChange = (newFilter: SearchFilter[], newValues: SearchFilterValue[]) => {
    setFilter(newFilter);
    setFilterValues(newValues);
  }

  const handleImportData = (data: any) => {
      setFilter(data?.filter);
      setFilterValues(data?.filterValues);
  }

  useEffect(() => {
    setLoading(true);
    const fval = filterValues?.map((value) => ({
      type: value.type.value,
      value: value.value.map((val) => ({
          type: val.type.value,
          atr: val.atribute.value,
          option: val.option.value,
          value: val.value.map((valInVal) => valInVal.value ? valInVal.value : isNaN(valInVal) ? new Date(valInVal).toISOString() : valInVal) 
      }))
    }));

    MapServiceTZ.getMapData(JSON.stringify(fval))
    .then((resp: any) => {
        setMapDataTZ(resp.data.data);
        setCountValues(resp.data.data.length);
        setLoading(false);
        setShowSnackbar(true);
    })
    .catch(() => { setLoading(false); });
  }, [filterValues]);

  const heatmapOptions = {
    radius: 16,
    blur: 35,
    maxZoom: 18,
    minOpacity: 0.2,
    maxOpacity: 1
  };

  const dataToFile = {
    filter: filter,
    filterValues: filterValues
  };

  const toggleMap = () => {
    setShowHeatmap(!showHeatmap);
  };

  const heatmapData = mapDataTZ?.filter((data) => !isNaN(parseFloat(data.y)) && !isNaN(parseFloat(data.x)))
    .map((data: TZMapData) => [parseFloat(data.y), parseFloat(data.x)] as [number, number]);

  const icon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
    shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
  });
  


  const getMarkerColor = (zavaznost: string) => {
    switch (zavaznost) {
      case '3':
        return 'red';
      case '2':
        return 'orange';
      case '1':
        return 'yellow';
      case '0':
        return 'green';
      default:
        return 'blue'; 
    }
  };


  function MultipleMarkers() {
    if (!mapDataTZ) return null;
    return (
      <Fragment>
        {mapDataTZ?.filter((data) => !isNaN(parseFloat(data.y)) && !isNaN(parseFloat(data.x))).map((data, index) => (
          <Marker key={index} position={[parseFloat(data.y), parseFloat(data.x)]} icon={new L.Icon({
            iconSize: [25, 41],
            iconAnchor: [10, 41],
            popupAnchor: [2, -40],
            iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-${getMarkerColor(data.zavaznost)}.png`,
            shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
          })}>
            <Popup>
              <table>
                <tbody>
                  <tr>
                    <td><strong>{t("Map.kraj")}</strong></td>
                    <td>{data.kraj || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td><strong>{t("Map.nehodaDatum")}</strong></td>
                    <td>{data.datum || 'N/A'}   {data.cas || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td><strong>{t("Map.celkovaSkoda")}</strong></td>
                    <td>{data.celkovaHmotnaSkoda || 'N/A'} Kč</td>
                  </tr>
                  <tr>
                    <td><strong>{t("Map.osobyUsmrt")}</strong></td>
                    <td style={{ color: 'red' }}>{data.usmrteneOsoby || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td><strong>{t("Map.osobyTazZran")}</strong></td>
                    <td style={{ color: 'orange' }}>{data.tazkoZraneneOsoby || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td><strong>{t("Map.osobyLahZran")}</strong></td>
                    <td style={{ color: '#FFD700' }}>{data.lahkoZraneneOsoby || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td style={{ minWidth: '140px' }}><strong>{t("Map.druhZveri")}</strong></td>
                    <td>{data.druhZveri || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td><strong>{t("Map.znackaVoz")}</strong></td>
                    <td>{data.znackaVozidla || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td><strong>{t("Map.povrchVozovky")}</strong></td>
                    <td>{data.povrchVozovky || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td><strong>{t("Map.alkohol")}</strong></td>
                    <td>{data.alkohol || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td><strong>{t("Map.viditelnost")}</strong></td>
                    <td>{data.viditelnost || 'N/A'}</td>
                  </tr>
                </tbody>
              </table>
            </Popup>
          </Marker>
        ))}
      </Fragment>
    );
  }

  function GrowTransition(props: GrowProps) {
    return <Grow {...props} />;
  }
  
  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway')
      return;

    setShowSnackbar(false);
  };

  function updateProgressBar(processed: any, total: any, elapsed: any) {
    if (elapsed > 1000) {
      // if it takes more than a second to load, display the progress bar:
      // progress.style.display = 'block';
      // progressBar.style.width = Math.round(processed/total*100) + '%';
      setProgress(Math.round(Number(processed/total*100)))
      setLoading(true);
    }

    if (processed === total) {
      // all markers processed - hide the progress bar:
      // progress.style.display = 'none';
      setProgress(0);
      setLoading(false);
    }
  }

  return (
    <>
    
      <div className="box-12 py-2 text-right form">

       

        <SFilterSettings 
            filter={filter} 
            filterValues={filterValues}
            onFilterChange={handleFilterChange} 
            endpointService={SearchServiceTZ}
        />
      </div>
      <ShowBox className="box-12 py-2" name="">
        <SFilterValueDraw 
          filterValues={filterValues} 
          onChange={handleFilterValuesChange} 
        />
      </ShowBox>
        
      {/* <Stack spacing={2} direction="row">
      <CircularProgress variant="determinate" value={progress} /></Stack> */}

      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <h1 style={{ fontSize: '26px' }}>
          <strong>{showHeatmap ? t("Map.heatNadpis") : t("Map.baseNadpis")}</strong>
        </h1>

        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <FaMap name='pinIcon' size={24} style={{ marginRight: '10px', color: 'black', cursor: 'pointer' }} />
          <div className={`toggle-switch ${showHeatmap ? 'checked' : ''}`} onClick={toggleMap}>
            <div className="slider"/>
          </div>
        </div>
      </div>
      {/* <Stack spacing={2} direction="row">
      <CircularProgress variant="determinate" value={progress} /></Stack> */}
      <div id="map">
      <ShowBox className="box-12 py-2" name="">
        <MapContainer center={[49.8, 15.5]} zoom={7.5} style={{ zIndex: 1, width: '100%'}} maxZoom={18}>
          { loading && <div className={css.loading}>
            <div><MdLocalPolice /> {t("Traffic.loading")}</div>
          </div> }
          {showHeatmap && mapDataTZ !== null && mapDataTZ.length > 0 &&
              <HeatmapLayer
                fitBoundsOnLoad
                fitBoundsOnUpdate
                points={heatmapData}
                latitudeExtractor={(point: any) => point[0]}
                longitudeExtractor={(point: any) => point[1]}
                key={Math.random() + Math.random()}
                intensityExtractor={(point: any) => parseFloat(point[0])}
                {...heatmapOptions}
              />
          }

          {!showHeatmap && mapDataTZ !== null && mapDataTZ.length > 0 &&
              <MarkerClusterGroup /*chunkProgress={updateProgressBar}*/ chunkedLoading maxClusterRadius={100} removeOutsideVisibleBounds disableClusteringAtZoom={17} spiderfyDistanceMultiplier={2}>
                <MultipleMarkers />
              </MarkerClusterGroup>
          }
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </MapContainer>
      </ShowBox>
      </div>
      {/* <Stack spacing={2} direction="row">
      <CircularProgress variant="determinate" value={progress} /></Stack> */}

      <Snackbar
        open={showSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={GrowTransition}
        message={t("Map.pocetNehod", { count: countValues })}
        onClose={handleCloseSnackbar}
        autoHideDuration={5000}
        color="secondary"
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: "center",
            color: 'white',
            backgroundColor: '#1F98F5',
          }
        }}
      />
    </>
  );
}