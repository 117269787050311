
import { Link/*, useNavigate*/ } from "react-router-dom";
import { useEffect, useState } from "react";
import image from '../../../assets/css/images/police.jpg';
import { Grid, MenuItem, Select } from "@mui/material";
import analyzisService from "../../../services/StatisticService";
import { MdLocalPolice } from "react-icons/md";
import { SearchFilter, SearchFilterValue } from "../../../interfaces/SearchFilter";
import { Sort } from "../../../interfaces/Sort";
import { GraphValue } from "../../../interfaces/Graphs";
import filterItems from './../../../interfaces/ConstFilter';
import { useTranslation } from 'react-i18next';
import PageForm from "../../../components/PageHeader/PageForm";

export default function CDashboard() {

  const { t } = useTranslation("crimes");

  const [crimesInRegion, setCrimesInRegion]: [any, Function]  = useState([]);
  const [crimesTotal, setCrimesTotal] = useState(0);
  const [from, setFrom] = useState("2020-01-01");
  const [to, setTo] = useState("2023-01-01");
  const [loading, setLoading] = useState(true);
  const [typeDate, setTypeDate]= useState('datum_podania');

  const [ filter ] = useState<SearchFilter[]>(filterItems);
  const [ filterValues ] = useState<SearchFilterValue[]>(
    [
      {
          type: {
              value: "AND",
              label: "A súčastne"
          },
          value: [
              {
                  type: {
                      "value": "AND",
                      "label": "A súčastne"
                  },
                  atribute: {
                      value: "region_title",
                      label: "Kraj"
                  },
                  option: {
                      value: "CONTAINS",
                      label: "obsahuje text"
                  },
                  value: [
                      {
                          label: "Banskobystrický",
                          value: "Banskobystrický"
                      }
                  ]
              }
          ]
      }
  ]
  );
  const [ sort ] = useState<Sort>({ page: 1, perPage: 5, label: "", order: true });

  const [ graphValues ] = useState<GraphValue[]>([]);

  const dataToFile = {
    filter: filter,
    filterValues: filterValues,
    sort: sort,
    graphValues: graphValues
  };

  const saveRegionToLocal = (kraj: String) => {
    let tempData = dataToFile;
    tempData.filterValues[0].value[0].value[0].value = kraj;
    tempData.filterValues[0].value[0].value[0].label = kraj;
    const name = "Mapa regionov"
    window.sessionStorage.setItem('TCSearch' + name, JSON.stringify(dataToFile));
    const arr = JSON.parse(window.sessionStorage.getItem('TCSearch') || "[]");
    const exist = arr.find((item: any) => item.value === name);
    if (exist) {
        arr.splice(arr.indexOf(exist), 1);
    }
    arr.push({ label: name + " (" + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString() + ")", value: name });
    window.sessionStorage.setItem('TCSearch', JSON.stringify(arr));
  }

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to, typeDate]); 

  const refresh = () => {
    setLoading(true);
    analyzisService.getRegionByCrimes(from, to, typeDate)
    .then((response: any) => {
      var count = 0;
      response.data.data.forEach( (item: any) => {
        count += Number(item.count);
      });
      response.data.data.forEach( (item: any) => {
        item.crimePercent = (Number(item.count)/ count * 100).toFixed(2);
        if (item.crimePercent <= 7) { item.color = "#006100";}
        else if (item.crimePercent <= 9) { item.color = "#498a00";}
        else if (item.crimePercent <= 11) { item.color = "#8bb500";}
        else if (item.crimePercent <= 13) { item.color = "#d6e600";}
        else if (item.crimePercent <= 15) { item.color = "#ffe500";}
        else if (item.crimePercent <= 17) { item.color = "#ffa600";}
        else if (item.crimePercent <= 19) { item.color = "#ff6f00";}
        else { item.color = "#ff2200";}
      });
      setCrimesTotal(count);
      setCrimesInRegion(response.data.data);
      setLoading(false);
    })
    .catch((error: any) => {});
  }

  const setTimeFrom = (e: any) => {
    setFrom(e.target.value);
  }

  const setTimeTo = (e: any) => {
    setTo(e.target.value);
  }

  const setDate = (e: any) => {
    setTypeDate(e.target.value);
  }

  /* const redirect = (regionId: number) => {
    var idType = 6;
    switch (typeDate) {
      case 'datum_podania':
        idType = 6;
        break;
      case 'datum_zacatia':
        idType = 9;
        break;
      case 'datum_pokracovania_tc':
        idType = 12;
        break;
      case 'datum_ukoncenia':
        idType = 15;
        break;
      case 'datum_obvinenia':
        idType = 22;
        break;
      case 'datum_rozsirenia':
        idType = 25;
        break;
      case 'datum_pokracovania_p':
        idType = 28;
        break;
      case 'datum_prevzatia':
        idType = 31;
        break;
      case 'datum_vratenia':s
        idType = 36;
        break;
      case 'datum_ukoncenia_po_dos':
        idType = 41;
        break;
    }
    return '/crimes/search?page=1&perPage=10&order=&asc=false&filter=[[3,6,9,41,44,45,' + idType + '],["3=","' + idType + '=[\\"' + from + '\\",\\"' + to + '\\"]","44=' + regionId + '","45="]]&graphs=[]&custom=null';
  } */

  return (
    <PageForm label={t("dashboard.pageTitle")} image={image}>
      {loading && <div className="loading">
        <div><MdLocalPolice /> {t("dashboard.loading")}</div>
      </div>}
      <Grid container spacing={2} paddingBottom={2}>
        <Grid item xs={12} md={4} mb={2}>
          <Select
            labelId="selectTypeDate"
            id="selectTypeDate"
            value={typeDate}
            onChange={(e) => setDate(e)}
            fullWidth
          >
            <MenuItem value={'datum_podania'}>{t("dashboard.datum_podania")}</MenuItem>
            <MenuItem value={'datum_zacatia'}>{t("dashboard.datum_zacatia")}</MenuItem>
            <MenuItem value={'datum_pokracovania_tc'}>{t("dashboard.datum_pokracovania_tc")}</MenuItem>
            <MenuItem value={'datum_ukoncenia'}>{t("dashboard.datum_ukoncenia")}</MenuItem>
            <MenuItem value={'datum_obvinenia'}>{t("dashboard.datum_obvinenia")}</MenuItem>
            <MenuItem value={'datum_rozsirenia'}>{t("dashboard.datum_rozsirenia")}</MenuItem>
            <MenuItem value={'datum_pokracovania_p'}>{t("dashboard.datum_pokracovania_p")}</MenuItem>
            <MenuItem value={'datum_prevzatia'}>{t("dashboard.datum_prevzatia")}</MenuItem>
            <MenuItem value={'datum_vratenia'}>{t("dashboard.datum_vratenia")}</MenuItem>
            <MenuItem value={'datum_ukoncenia_po_dos'}>{t("dashboard.datum_ukoncenia_po_dos")}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} md={4}>
          <input type="date" style={{height: "56px", width: "100%"}} value={from} onChange={e => setTimeFrom(e)}></input>
        </Grid>
        <Grid item xs={12} md={4}>
          <input type="date" style={{height: "56px", width: "100%"}} value={to} onChange={e => setTimeTo(e)}></input>
        </Grid>
        <Grid item xs={12} md={9}>
          <svg className="regions-map" viewBox="0 0 1550 1200">
            <g>
              <Link to={"/crimes/search/-1"} onClick={() => saveRegionToLocal("Žilinský")} className="good">
                <polygon style={{fill: crimesInRegion.length >= 3 && crimesInRegion[4].color, stroke: "white"}}
                  points="570.667,530.968 555.228,535.178 524.329,496.04 532.298,458 496.391,458 477.105,466.166
                  466.722,452.225 481.125,440.385 508,413.476 486.284,401.05 498.028,381.958 462.227,352.379 450.536,312.623 458.456,283.648
                  466.853,287.697 479.691,263.6 498.276,256.808 492.023,244.269 503.371,237.455 517.457,243.085 550.611,244.663
                  566.285,231.838 579.267,240.491 601.868,231.185 600.576,257.021 605.025,270.368 601.499,293.288 658.536,283.223
                  661.895,269.788 680.545,239.66 705.829,232.169 726.512,207 738.212,207 749.271,227.448 760.197,271.934 777.513,273.246
                  796.342,298.222 814.366,287.072 822.431,335.433 804.167,361.981 836.505,370.057 842.391,400.665 860.736,412 874.254,412
                  867.963,440.086 855.182,446.781 878.836,471.75 872.014,483.026 818.986,477.073 802.654,485.95 783.407,481.537 771.12,472
                  714.885,472 683.387,499.177 673.879,499.881 672.331,477.76 627.583,490.512 609.309,485.512 599.074,511.09 600.408,540.433"/>
                <text>
                  <tspan x="42%" y="30%">{t("dashboard.zilina")}</tspan>
                  <tspan x="42%" y="34%">{ crimesInRegion.length >= 1 ? crimesInRegion[4].crimePercent + "%" : ""}</tspan>
                </text>
              </Link>
              <Link to={"/crimes/search/-1"} onClick={() => saveRegionToLocal("Prešovský")} className="worst">
                <polygon style={{fill: crimesInRegion.length >= 3 && crimesInRegion[6].color, stroke: "white"}}
                  points="1271.909,528.503 1266.15,509.788 1244.671,493.504 1224,495.352 1224,509.719 1199,516.65
                  1199,499.133 1168.271,484 1136.985,484 1129.227,465.741 1099.784,459.414 1068.828,438.165 1060.403,456.665 1039.138,463.296
                  1003.542,436.978 991.15,452.463 974.076,445.348 941.145,459.212 952.26,476.678 937.384,491.554 920.248,484.415
                  904.921,489.013 878.391,484.038 886.333,470.882 865.016,448.321 873.207,444.128 881.746,406 862.834,406 847.78,396.924
                  842.427,369.195 858.511,359.241 889.329,380.206 906.479,326.028 921.381,313.641 954.255,312.026 957.28,292.1 986.927,285.348
                  1016.796,307.359 1030.69,287.282 1045.09,293.043 1058.802,282.071 1082.745,320.978 1095.14,311.682 1114.506,334.029
                  1156.524,307.139 1144.443,284.488 1163.26,270.69 1181.558,281.363 1198.206,264.714 1242.368,285.323 1287.95,270.62
                  1301.668,288.909 1316.368,281.559 1328.151,302.179 1348.643,295.874 1378.463,314.334 1395.743,338.815 1400.511,362.652
                  1446.479,371.845 1469.366,397.786 1491.793,394.796 1505.189,411.168 1534.829,409.757 1532.188,448.042 1515.089,455.167
                  1500.482,476.265 1501.909,494.814 1492.192,499.673 1468.046,502.356 1459.394,479.281 1436.094,470.319 1425.76,490.986
                  1421.759,504.325 1383.339,508.441 1368.676,490.846 1341.846,485.816 1327.675,503.528 1335.1,524.315 1321.078,538.337"/>
                <text>
                  <tspan x="80%" y="32%">{t("dashboard.presov")}</tspan>
                  <tspan x="80%" y="36%">{ crimesInRegion.length >= 1 ? crimesInRegion[6].crimePercent + "%" : ""}</tspan>
                </text>
              </Link>
              <Link to={"/crimes/search/-1"} onClick={() => saveRegionToLocal("Košický")} className="bad">
                <polygon style={{fill: crimesInRegion.length >= 3 && crimesInRegion[7].color, stroke: "white"}}
                  points="1322.282,706.36 1317.296,691.399 1307.271,689.395 1292.471,646.758 1274.077,643.505
                  1264.295,624.919 1243.708,613.371 1238.114,625 1214.543,625 1202.988,639.685 1189.796,634.855 1161.23,649.569
                  1149.335,641.306 1132.2,635.935 1116.231,638.745 1104.821,624.478 1084.438,616.966 1053.177,619.049 1024.38,633.943
                  995.855,631.749 996.918,656.187 989.345,659.974 963.334,659.011 949.666,656.447 947.144,638.791 961.253,619.643
                  957.52,604.708 941.199,598.948 943.104,578 928.852,561.556 913.13,554.157 913.955,541.777 925.589,517.539 940.376,497.046
                  959.906,477.516 950.021,461.984 974.088,451.851 993.013,459.736 1004.619,445.228 1038.02,469.915 1064.753,461.561
                  1071.33,447.091 1097.362,464.903 1124.919,470.946 1133.016,490 1167.292,490 1193,502.854 1193,524.995 1230,514.253
                  1230,500.759 1242.882,499.682 1260.861,513.399 1267.169,533.687 1323.019,544.85 1342.012,525.875 1334.443,504.663
                  1344.276,492.376 1365.447,496.345 1380.784,514.749 1426.359,509.866 1431.357,493.206 1439.023,477.875 1454.722,483.913
                  1464.067,508.834 1490.263,505.924 1491.902,531.573 1483.92,537.56 1484.97,557.499 1477.651,575.793 1457.805,585.194
                  1458.785,595.001 1443.886,600.589 1435.091,617.08 1427.912,675.532 1433.387,686.482 1423.202,697.45 1403.968,689.756
                  1377.609,700.908 1356.699,697.771 1346.733,710.727"/>
                <text>
                  <tspan x="70%" y="45%">{t("dashboard.kosice")}</tspan>
                  <tspan x="70%" y="49%">{ crimesInRegion.length >= 1 ? crimesInRegion[7].crimePercent + "%" : ""}</tspan>
                </text>
              </Link>
              <Link to={"/crimes/search/-1"} onClick={() => saveRegionToLocal("Banskobystrický")} className="average">
                <polygon style={{fill: crimesInRegion.length >= 3 && crimesInRegion[5].color, stroke: "white"}}
                  points="636.345,809.929 601.529,814.51 602.817,805.493 613.829,795.482 618.362,761.482 601.56,758.896
                  573.626,771.866 548.334,766.808 554.224,754.186 549.293,739.392 564.536,715.003 539.874,675.407 521.49,670.498
                  506.075,698.265 487.562,701.188 481.125,707.628 476.956,702.938 486.054,692.021 484.924,669.418 474.859,662.098 486,640.83
                  470.512,631.98 466.64,619.59 484.317,611.172 498.67,614.76 499.91,601.132 513.582,583.187 530.586,595.939 537.55,573.888
                  553.79,562.722 556.803,540.967 570.537,537.221 606.792,548.757 605.127,512.106 612.892,492.694 627.618,496.71
                  666.863,485.498 668.313,506.157 685.794,505.024 717.115,478 768.979,478 780.774,487.082 803.522,492.428 820.2,483.292
                  873.145,489.19 905.25,495.195 919.921,490.786 933.436,496.414 920.411,514.463 908.045,540.224 906.87,557.843 925.148,566.444
                  936.896,580 934.801,603.053 952.48,609.292 954.747,618.357 940.856,637.209 944.334,661.553 962.666,664.989 984.528,665.8
                  977.069,676.988 976.113,689.415 967.741,695.694 969.837,708.268 961.026,720.602 949.596,725.797 944.17,741.173
                  928.354,735.591 913.292,748.644 898.857,744.202 895.396,758.046 883.678,764.881 877.137,778.897 859.07,777.947 841,791.5
                  833.711,777.832 812.594,779.752 810.789,766.219 797.767,757.933 785.027,758.912 768.206,744.194 735.375,767.175
                  729.191,790.882 719.762,808.026 711.493,803.893 669.392,810.208 662.011,819.435"/>
                <text>
                  <tspan x="48%" y="52%">{t("dashboard.banskabystrica")}</tspan>
                  <tspan x="48%" y="56%">{ crimesInRegion.length >= 1 ? crimesInRegion[5].crimePercent + "%" : ""}</tspan>
                </text>
              </Link>
              <Link to={"/crimes/search/-1"} onClick={() => saveRegionToLocal("Nitriansky")} className="good">
                <polygon style={{fill: crimesInRegion.length >= 3 && crimesInRegion[2].color, stroke: "white"}}
                  points="393.07,935.947 369.534,939.87 350.285,929.283 333.491,922.565 324.086,930.089 315.43,922.515
                  307.444,934.208 267.093,933.152 257.421,925.354 243.752,921.309 252.829,907.682 271.73,903.474 273.766,886.171
                  289.506,880.266 294.95,859.579 301.179,811.825 289.515,801.327 278.928,798.069 284.513,783.176 272.249,771.729
                  283.192,769.384 282.226,756.815 297.908,755.893 307.106,737.496 293,731.45 297.251,722.097 292.238,709.061 303.616,682.513
                  318.312,662.918 331.296,648.753 300.725,622.259 311.127,601.453 318.887,598.867 341.346,592.45 348.117,572.137
                  343.92,554.513 355.27,555.932 374.528,563.269 378.438,576.953 400.487,590.374 396.81,603.246 403.526,617.8 416.336,629.623
                  421.263,647.485 436.979,636 445.028,636 461.434,623.264 465.493,636.152 477.995,643.24 467.138,663.935 479.075,672.6
                  479.944,689.987 469.041,703.066 480.869,716.374 490.426,706.812 509.901,703.735 524.464,677.502 536.035,680.593
                  557.464,714.997 542.707,738.608 547.776,753.814 539.666,771.192 574.374,778.134 602.44,765.104 611.638,766.518
                  608.171,792.518 597.183,802.507 595.364,815.237 576.207,817.062 564.052,822.672 551.934,816.612 545.58,844.146
                  527.675,845.265 534.681,862.781 525.416,879.252 539.848,898.124 551.356,907.167 544.387,913.503 526.368,906.084
                  518.704,924.698 498.6,932.923 475.646,929.929 460.543,935.593 450.398,927.477 420.623,941.851"/>
                <text>
                  <tspan x="27%" y="66%">{t("dashboard.nitra")}</tspan>
                  <tspan x="27%" y="70%">{ crimesInRegion.length >= 1 ? crimesInRegion[2].crimePercent + "%" : ""}</tspan>
                </text>
              </Link>
              <Link to={"/crimes/search/-1"} onClick={() => saveRegionToLocal("Trenčiansky")} className="average">
                <polygon style={{fill: crimesInRegion.length >= 3 && crimesInRegion[3].color, stroke: "white"}}
                  points="421.663,626.503 408.477,614.268 403.19,602.786 407.512,587.642 383.562,573.056
                  379.472,558.735 356.731,550.07 336.08,547.487 341.883,571.863 337.045,586.379 306.632,578.329 297.087,567.723
                  276.783,571.784 261.714,559.729 235.805,566.207 218.794,576.95 196.788,546.141 206.561,534.413 198.954,519.199
                  179.982,517.091 173.706,513.325 182.977,503.282 197.635,493.204 209.505,495.748 219.315,505.558 248.909,492.875
                  268.491,488.752 290.394,466.849 315.607,463.697 320.635,432.197 334.152,427.137 354.737,432.28 368.353,420.939
                  381.118,397.543 378.594,381.377 389,356.604 389,341.134 404.229,323.859 431.504,320.95 445.35,316.457 456.958,355.832
                  490.167,383.253 477.92,403.168 498.063,414.677 458.596,451.222 475.099,473.531 497.609,464 525.266,464 517.869,497.537
                  550.956,539.571 548.211,559.337 532.451,570.142 527.415,586.075 512.419,574.821 494.09,598.873 493.33,607.242
                  483.667,604.825 461.405,615.69 442.972,630 435.021,630 424.729,637.521"/>
                <text>
                  <tspan x="26%" y="41%">{t("dashboard.trencin")}</tspan>
                  <tspan x="26%" y="45%">{ crimesInRegion.length >= 1 ? crimesInRegion[3].crimePercent + "%" : ""}</tspan>
                </text>
              </Link>
              <Link to={"/crimes/search/-1"} onClick={() => saveRegionToLocal("Trnavský")} className="bad">
                <polygon style={{fill: crimesInRegion.length >= 3 && crimesInRegion[1].color, stroke: "white"}}
                  points="224.337,897.735 210.053,900.116 203.178,879 191.052,879 178,868.559 178,856.886 151.375,826
                  135.481,826 129.29,817.917 154.391,800.418 149.621,780.326 166.172,776.619 170.573,766.044 181.933,771.28 201.224,763.332
                  209.494,745.608 202.198,736.226 205.141,720.53 194.167,699.58 198.322,680.881 184.433,669.129 175.231,649.701 159.38,642.767
                  156.403,634.579 166.041,622.799 151.984,608.741 159.958,598.375 177.529,589.59 172.338,570.125 150.749,568.926
                  138.99,581.753 126.686,590.745 110.61,595 93.826,595 79.706,586.582 62.904,600.66 58.156,587.034 71.836,571.357
                  72.393,555.435 80,548.299 80,536.12 107.476,504.435 110.48,492.9 130.586,480.142 153.744,483.842 176.554,501.378
                  164.287,514.676 178.014,522.909 195.045,524.801 199.438,533.587 189.212,545.859 217.206,585.05 238.195,571.793
                  260.286,566.271 275.218,578.216 294.913,574.277 303.368,583.671 327.958,590.181 306.896,596.499 293.275,623.741
                  322.839,649.363 298.408,679.434 285.762,708.938 290.749,721.903 285,734.55 298.894,740.504 294.092,750.107 275.774,751.185
                  276.808,764.616 259.751,768.271 277.487,784.824 271.072,801.931 286.485,806.673 294.821,814.175 289.05,858.423
                  284.494,875.738 268.234,881.837 266.27,898.542 249.171,902.35 238.894,917.779"/>
                <text>
                  <tspan x="17%" y="50%">{t("dashboard.trnava")}</tspan>
                  <tspan x="16%" y="54%">{ crimesInRegion.length >= 1 ? crimesInRegion[1].crimePercent + "%" : ""}</tspan>
                </text>
              </Link>
              <Link to={"/crimes/search/-1"} onClick={() => saveRegionToLocal("Bratislavský")}className="worst">
                <polygon style={{fill: crimesInRegion.length >= 3 && crimesInRegion[0].color, stroke: "white"}}
                  points="86.69,810.107 94.24,784.627 82.851,764.626 68.442,758.611 58,741.171 58,719.592
                  44.896,708.712 47.034,687.584 29.453,674.843 35.02,657.303 33.15,640.339 50.675,626.714 53.631,613.923 63.24,608.205
                  80.292,593.917 92.174,601 111.39,601 129.305,596.258 143.007,586.376 153.259,575.135 167.664,575.91 170.47,586.425
                  156.043,593.632 144.016,609.263 157.958,623.203 149.597,633.422 154.62,647.233 170.77,654.299 179.567,672.871
                  191.678,683.119 187.833,700.42 198.859,721.47 195.802,737.775 202.506,746.394 196.776,758.671 182.068,764.728
                  167.429,757.971 161.83,771.409 142.376,775.732 147.607,797.7 123.855,814.144 106.275,816.919"/>
                <text>
                  <tspan x="8%" y="58%">{t("dashboard.bratislava")}</tspan>
                  <tspan x="8%" y="62%">{ crimesInRegion.length >= 1 ? crimesInRegion[0].crimePercent + "%" : ""}</tspan>
                </text>
              </Link>
            </g>
          </svg>
        </Grid>
      
        <Grid xs={12} md={3}  style={{ marginTop: '200px' }}>
          <div className="legend">
            <span className="dot" style={{backgroundColor: "#006100"}}></span><h5>{t("dashboard.legend1")}</h5>
          </div>
          <div className="legend">
            <span className="dot" style={{backgroundColor: "#498a00"}}></span><h5>{t("dashboard.legend2")}</h5>
          </div>
          <div className="legend">
            <span className="dot" style={{backgroundColor: "#8bb500"}}></span><h5>{t("dashboard.legend3")}</h5>
          </div>
          <div className="legend">
            <span className="dot" style={{backgroundColor: "#d6e600"}}></span><h5>{t("dashboard.legend4")}</h5>
          </div>
          <div className="legend">
            <span className="dot" style={{backgroundColor: "#ffe500"}}></span><h5>{t("dashboard.legend5")}</h5>
          </div>
          <div className="legend">
            <span className="dot" style={{backgroundColor: "#ffa600"}}></span><h5>{t("dashboard.legend6")}</h5>
          </div>
          <div className="legend">
            <span className="dot" style={{backgroundColor: "#ff6f00"}}></span><h5>{t("dashboard.legend7")}</h5>
          </div>
          <div className="legend">
            <span className="dot" style={{backgroundColor: "#ff2200"}}></span><h5>{t("dashboard.legend8")}</h5>
          </div>
        </Grid>
      </Grid>
      { crimesInRegion.length >= 1 && <div>
        <h3 style={{textAlign: "left"}}>{t("dashboard.legend9")} {crimesTotal}</h3>
      </div>}
    </PageForm>
  );
}