import HeaderCarousel from '../../../../../components/HeaderCarousel/HeaderCarousel';
import image from '../../../../../assets/css/images/nahlasene.jpg';
import './StateCompareInfo.css';
import { PrisonCountForState, PrisonForState, State, StateCompareRequest } from '../../../../../interfaces/europe/interfaces';
import React, { useEffect, useRef } from 'react';
import { COLORS, GET_ALL_STATES, ITEM_HEIGHT, ITEM_PADDING_TOP, LINK_STATE_COMAPRE_CO, LINK_STATE_COMAPRE_CONVICTED, LINK_STATE_COMAPRE_INFO, LINK_STATE_COMAPRE_POLICE, LINK_STATE_COMAPRE_PRISON, MAP_COLORS, POST_FILTER_CO_LINK, POST_POLICE_CIVILIANS_LINK, POST_POLICE_OFFICERS_LINK, POST_STATE_COMPARE_INFO, POST_STATE_COMPARE_POLICE_COUNTS, POST_STATE_COMPARE_POLICE_INDEXES, POST_STATE_COMPARE_PRISON_COUNTS, POST_STATE_COMPARE_PRISON_INFO, STATE_INFO_SITE, TABLE_PAGE_SIZE_TEN } from '../../../../../interfaces/europe/Constants';
import axios from 'axios';
import { Box, Tabs, Tab, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { LineChart, CartesianGrid, XAxis, YAxis, Legend, Line, TooltipProps, Tooltip as TooltipChart, ResponsiveContainer } from 'recharts';
import { useTranslation } from "react-i18next";

export default function StateComparePolice() {
    const targetRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation("europe");

    const { codes } = useParams<string>();

    //RESTS FOR GET
    const [states, setStates] = React.useState<State[]>([]);
    const [prisonCount, setPrisonCount] = React.useState<PrisonCountForState[]>([]);
    const [prisonInfo, setPrisonInfo] = React.useState<PrisonForState[]>([]);

    //RESTS POST 
    let stateCompareRequest: StateCompareRequest = { "states": [] };
    const [stateIds, setStateIds] = React.useState<number[]>([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL_EU + GET_ALL_STATES).then((response) => {
            setStates(response.data);
        });
    }, []);

    useEffect(() => {
        stateCompareRequest.states = stateIds;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_PRISON_INFO, stateCompareRequest).then((response) => {
            setPrisonInfo(response.data);
        });
    }, [stateIds]);

    useEffect(() => {
        stateCompareRequest.states = stateIds;

        axios.post(process.env.REACT_APP_API_URL_EU + POST_STATE_COMPARE_PRISON_COUNTS, stateCompareRequest).then((response) => {
            setPrisonCount(response.data);
        });
    }, [stateIds]);

    useEffect(() => {
        if (codes !== undefined && codes !== null && codes !== ":codes") {
            setStateIds([...(codes.split(',').map(Number)).filter(x => x !== null)]);
        }
    }, []);

    const [value, setValue] = React.useState(4);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleStateChange = (event: SelectChangeEvent<typeof stateIds>) => {
        const {
            target: { value },
        } = event;
        setStateIds(value as number[]);
    };


    function getState(x: number[], l: number = 3) {
        const filteredCourses = states.filter(state => x.slice(0, l).some(number => number === state.id));
        const translated = filteredCourses.map(u => t(`states.${u.code}`));
        if (x.length > l) {
            return translated.join(', ') + ",...";
        }
        return translated.join(', ');
    }

    const tableData = [
        { label: t("prison.preTrialDetainees"), key: "preTrialDetaineesPercentage" },
        { label: t("prison.femalePrisoners"), key: "femalePrisonersPercentage" },
        { label: t("prison.juvenilesMinorsYoungPrisoners"), key: "minorsPercentage" },
        { label: t("prison.foreignPrisoners"), key: "foreignPrisonersPercentage" },
        { label: t("prison.numberOfEstablishments"), key: "numberOfInstitutions" },
        { label: t("prison.officialCapacity"), key: "officialCapacity" }
    ];

    return (
        <>
            <HeaderCarousel name={t("stateCompare.title")} image={image} />

            <div className='underLabel pb-2 '>
            <br />
                <p>{t("stateCompare.subTitle")}</p>
            </div>
            
            <div className='d-inline w-100'>
                <FormControl sx={{ m: 1, width: 500 }}>
                    <InputLabel id="multiple-checkbox-label-states"><p>{t("stateCompare.states")}</p></InputLabel>
                    <Select
                        labelId="multiple-checkbox-label-states"
                        id="multiple-checkbox-states"
                        multiple
                        value={stateIds}
                        onChange={handleStateChange}
                        input={<OutlinedInput label="States" />}
                        renderValue={selected => getState(selected)}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                    width: 250,
                                },
                            }
                        }
                        }
                    >
                        {states.map((state) => (
                            <MenuItem key={state.name.toString()} value={state.id} >
                                <Checkbox checked={stateIds.indexOf(state.id) > -1} />
                                <ListItemText primary={state.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            {stateIds.length !== 0 ?
                <div>
                    <div>
                        <Box sx={{ width: '100%' }}>
                            <Tabs
                                onChange={handleChange}
                                value={value}
                                aria-label="Tabs where selection follows focus"
                                selectionFollowsFocus>
                                <Link to={LINK_STATE_COMAPRE_INFO + stateIds} ><Tab label={t(`stateInfo.information`)} ></Tab></Link>
                                <Link to={LINK_STATE_COMAPRE_CO + stateIds}><Tab label={t(`stateInfo.criminalOffences`)} ></Tab></Link>
                                <Link to={LINK_STATE_COMAPRE_POLICE + stateIds}><Tab label={t(`stateInfo.police`)}></Tab></Link>
                                <Link to={LINK_STATE_COMAPRE_CONVICTED + stateIds}><Tab label={t(`stateInfo.convictedCriminalOffences`)} ></Tab></Link>
                                <Link to={LINK_STATE_COMAPRE_PRISON + stateIds} className="text-primary "><Tab label={t(`stateInfo.prison`)} className="font-weight-bold"></Tab></Link>

                            </Tabs>
                        </Box>
                    </div>

                    <div ref={targetRef}>
                        <div className="my-3 col-10">
                            <h5>{getState(stateIds, 10)}</h5>
                        </div>
                    </div>
                </div>
                :
                <div></div>
            }
            <div ref={targetRef}>
                {(prisonInfo && prisonCount && prisonCount.length > 0 && prisonInfo.length > 0) && (
                <div className="my-5 col-10">
                    <h3>{t("prison.additionalinfo")}</h3>
                    <table className="table table-hover table-auto table-striped mt-2">
                    <thead>
                        <tr>
                            <th className="border border-gray-300 p-2 text-left">{t("prison.category")}</th>
                            {prisonInfo.map((state) => (
                            <th key={state.stateId} className="border border-gray-300 p-2 text-center">
                                {state.stateCode}
                            </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((row) => {
                            const numericValues = prisonInfo.map(state => {
                                const value = state[row.key as keyof PrisonForState];
                                return typeof value === "number" ? value : null;
                            }).filter(value => value !== null) as number[]; 
                    
                            const minValue = Math.min(...numericValues);
                            const maxValue = Math.max(...numericValues);

                            return (
                                <tr key={row.key}>
                                    <th className="border border-gray-300 p-2 font-bold">{row.label}</th>
                                    {prisonInfo.map((state) => {
                                        const value = state[row.key as keyof PrisonForState];
                                        const numericValue = typeof value === 'string' ? parseFloat(value) : value;

                                        let className = "";
                                        if (numericValue == minValue) {
                                            className += "bg-success text-white p-2 rounded"; 
                                        } else if (numericValue == maxValue) {
                                            className += "bg-danger text-white p-2 rounded"; 
                                        }

                                        return (
                                            <td key={state.stateId} className="border border-gray-300 p-2 text-center">
                                                {["officialCapacity", "numberOfInstitutions"].includes(row.key) 
                                                    ? value 
                                                    : <span className={className}>{value}% </span>}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                    </table>
                    <div className='info'>
                        {t('prison.asof')}{" "}
                        {prisonCount.map((data, index) => {
                            const year = data.data[data.data.length - 1]?.year;
                            return year ? (index !== prisonCount.length - 1 ? year + ", " : year) : "";
                        }).join('')}
                    </div>
                    <div className='info my-0 mt-0'>* {t(`prison.percentage`)}</div>
                </div>
            )}

            <div className='mt-5'>
                <h2>{t(`prison.prisonersovertime`)}</h2>
                <ResponsiveContainer width="85%" height={400}>
                    <LineChart data={prisonCount} margin={{ top: 20, bottom: 20 }}>
                    <CartesianGrid strokeDasharray="2 2" />
                    <XAxis allowDuplicatedCategory={false} dataKey="year" type='number' domain={["dataMin", "dataMax"]} />
                    <YAxis domain={["auto", "auto"]} />
                    <Legend />
                    <TooltipChart/>
                    {prisonCount.map((state, index) => (
                        <Line
                        key={state.stateId}
                        type="monotone"
                        data={state.data}
                        dataKey="countOfPrisoners"
                        name={`${t(`states.${state.stateCode}`)}`}
                        stroke={COLORS[index % COLORS.length]}
                        connectNulls={false}
                        />
                    ))}
                    </LineChart>
                </ResponsiveContainer>
                <br />
                <h2>{t(`prison.indexovertime`)}</h2>
                <ResponsiveContainer width="85%" height={400}>
                    <LineChart data={prisonCount} margin={{ top: 20, bottom: 20 }}>
                    <CartesianGrid strokeDasharray="2 2" />
                    <XAxis allowDuplicatedCategory={false} dataKey="year" type='number' domain={["dataMin", "dataMax"]} />
                    <YAxis domain={["auto", "auto"]} />
                    <Legend />
                    <TooltipChart />
                    {prisonCount.map((state, index) => (
                        <Line
                        key={state.stateId}
                        type="monotone"
                        data={state.data}
                        dataKey="indexOfPrisoners"
                        name={`${t(`states.${state.stateCode}`)}`}
                        stroke={COLORS[index % COLORS.length]}
                        />
                    ))}
                    </LineChart>
                </ResponsiveContainer>
            </div>

            <div className='info'>{t(`statistics.crimeIndex`)}</div>

            </div>

            
        </>

    );
}
