import { FaCar, FaHome, FaSearch, FaMap, FaList, FaShareAlt, FaGlobe, FaFile, FaGlobeEurope, FaUserPlus, FaUserAlt, FaUser, FaFilter, FaPencilRuler } from "react-icons/fa";
import { IconType } from "react-icons/lib";
import { MdLocalPolice, MdOutlineLogout, MdPostAdd, MdQueryStats } from "react-icons/md";
import { BsGraphDown, BsGraphUp } from "react-icons/bs";
import Home from "../pages/default/Home/Home";
import CDashboard from "../pages/crimes/CDashboard/CDashboard";
// import CSearch from "../pages/crimes/CSearch/CSearch";
import CSearchNew from "../pages/crimes/CSearch/CSearchNew";
import TSearch from "../pages/traffic/TSearch/TSearch";
import Crimes from "../pages/crimes/Crimes";
import CAdd from "../pages/crimes/CAdd/CAdd";
import Traffic from "../pages/traffic/Traffic";
import TMap from "../pages/traffic/TMap/TMap";
import TAdd from "../pages/traffic/TAdd/TAdd";
import TGraphs from "../pages/traffic/TGraphs/TGraphs";
// import Login from './../pages/default/Login/Login';
// import Registration from './../pages/default/Registration/Registration';
import AccessForbidden from './../pages/default/AccessForbidden/AccessForbidden';
import PageNotFound from './../pages/default/PageNotFound/PageNotFound';
import Users from './../pages/default/Users/Users';
// import Logout from './../pages/default/Logout/Logout';
import Share from "../pages/default/Share/Share";
import Analyzis from "../pages/crimes/DataAnalyzis/Analyzis";
import HomeEurope from "../pages/europe/Home/Home/HomeEurope";

import AdministrationEurope from "../pages/europe/Home/Administration/AdministrationEurope";
import States from "../pages/europe/Home/States/States";
import StateInfo from "../pages/europe/Home/States/StateDetails/StateInfo";
import CriminalOffences from "../pages/europe/Home/CriminalOffences/CriminalOffences";
import CriminalOffenceMap from "../pages/europe/Home/CriminalOffences/CriminalOffenceMap";
import Login from "../pages/default/Login/Login";
import Registration from "../pages/default/Registration/Registration";
import Filters from "../pages/crimes/Filters/Filters";
import AccountSettings from "../pages/default/AccountSettings/AccountSettings";
import CriminalOffenceAnalysesDrugs from "../pages/europe/Home/CriminalOffences/CriminalOffenceAnalysesDrugs";
import StateCompareCriminalOffences from "../pages/europe/Home/States/StateCompare/StateCompareCriminalOffences";
import StateCompareInfo from "../pages/europe/Home/States/StateCompare/StateCompareInfo";
import CriminalOffencePrediction from "../pages/europe/Home/CriminalOffences/CriminalOffencePrediction";
import DistrictMap from "../pages/crimes/CDashboard/DistrictMap";
import TReportCenter from "../pages/traffic/TReportCenter/TReportCenter";
import TReport from "../pages/traffic/TReportCenter/TReport";
import TAnalysis from "../pages/traffic/TAnalysis/TAnalysis";
import ConvictedCriminalOffencePrediction from "../pages/europe/Home/CriminalOffences/CriminalOffencePredictionConvicted";
import TEuropeAccidentsMap from "../pages/traffic/TEurope/TEuropeAccidentsMap";

interface MenuItems {
    label: string,
    icon: IconType,
    path: string,
    unloggedOnly?: boolean;
    loggedOnly: boolean;
    crimesOnly?: boolean;
    trafficOnly?: boolean;
    managerOnly?: boolean;
    adminOnly?: boolean;
    hidden?: boolean;
    module: () => JSX.Element,
    submenu?: MenuItems[]
}

const menuItems: MenuItems[] = [
    {
        label: "menu.home",
        icon: FaHome,
        path: "/",
        loggedOnly: false,
        module: Home,
    },
    /*{
        label: "menu.accSettings",
        icon: FaCogs,
        path: "/settings",
        loggedOnly: true,
        hidden: true,
        module: AccountSettings,
    },*/
    {
        label: "menu.shared",
        icon: FaShareAlt,
        path: "/shared",
        loggedOnly: true,
        hidden: true,
        module: Share,
    },
    {
        label: "menu.crimes",
        icon: MdLocalPolice,
        path: "/crimes",
        loggedOnly: false,
        crimesOnly: false,
        module: Crimes,
        submenu: [
            {
                label: "menu.crimesPreview",
                icon: BsGraphUp,
                path: "/crimes/dashboard",
                loggedOnly: false,
                crimesOnly: false,
                module: CDashboard
            },
            {
                label: "menu.districts",
                icon: FaMap,
                path: "/crimes/map",
                loggedOnly: false,
                crimesOnly: false,
                module: DistrictMap
            },
            {
                label: "menu.crimesSearch",
                icon: FaSearch,
                path: "/crimes/search",
                loggedOnly: false,
                crimesOnly: false,
                module: CSearchNew
            },
            {
                label: "menu.crimesAnalytics",
                icon: BsGraphDown,
                path: "/crimes/analyzis",
                loggedOnly: false,
                crimesOnly: false,
                module: Analyzis
            },
            {
                label: "menu.filters",
                icon: FaFilter,
                path: "/crimes/filters",
                loggedOnly: true,
                crimesOnly: false,
                module: Filters
            }
        ]
    },

    {
        label: "menu.traffic",
        icon: FaCar,
        path: "/traffic",
        loggedOnly: false,
        trafficOnly: false,
        module: Traffic,
        submenu: [
            {
                label: "menu.trafficReportCenter",
                icon: FaPencilRuler,
                path: "/traffic/report-center",
                loggedOnly: true,
                trafficOnly: false,
                module: TReportCenter
            },
            {
                label: "menu.trafficReport",
                icon: FaList,
                path: "/traffic/report-center/report/:id",
                loggedOnly: true,
                hidden: true,
                module: TReport,
            },
            {
                label: "menu.trafficMap",
                icon: FaMap,
                path: "/traffic/dashboard",
                loggedOnly: false,
                trafficOnly: false,
                module: TMap
            },
            /* {
                label: "menu.trafficSearch",
                icon: FaSearch,
                path: "/traffic/search",
                loggedOnly: false,
                trafficOnly: false,
                module: TSearch
            }, */
            {
                label: "menu.trafficSearch",
                icon: FaSearch,
                path: "/traffic/search-n",
                loggedOnly: false,
                trafficOnly: false,
                module: TSearch
            },
            /*{
                label: "menu.trafficImport",
                icon: MdPostAdd,
                path: "/traffic/add",
                loggedOnly: false,
                trafficOnly: false,
                module: TAdd
            },*/
            {
                label: "menu.trafficGraphs",
                icon: BsGraphDown,
                path: "/traffic/graphs",
                loggedOnly: false,
                trafficOnly: false,
                module: TGraphs,
                submenu: [

                ]
            },
            {
                label: "menu.trafficAnalysis",
                icon: MdQueryStats,
                path: "/traffic/analysis",
                loggedOnly: false,
                crimesOnly: false,
                module: TAnalysis
            },
            {
                label: "menu.accidentsEurope",
                icon: FaGlobeEurope,
                path: "/traffic/euaccidents",
                loggedOnly: false,
                crimesOnly: false,
                module: TEuropeAccidentsMap
            },
        ]
    },
    {
        label: "menu.europe",
        icon: FaGlobe,
        path: "/europe",
        loggedOnly: false,
        module: HomeEurope,
        submenu: [

            {
                label: "menu.europeMap",
                icon: FaGlobeEurope,
                path: "/europe/maps",
                loggedOnly: false,
                crimesOnly: false,
                module: CriminalOffenceMap
            },
            {
                label: "menu.europeAnalyses",
                icon: BsGraphUp,
                path: "/europe/analyses/bac",
                loggedOnly: false,
                crimesOnly: false,
                module: HomeEurope
            },
            {
                label: "menu.europePrediction",
                icon: FaSearch,
                path: "/europe/prediction/",
                loggedOnly: false,
                crimesOnly: false,
                hidden: false,
                module: CriminalOffencePrediction,
            },
            {
                label: "menu.europePrediction",
                icon: FaSearch,
                path: "/europe/prediction/convicted",
                loggedOnly: false,
                crimesOnly: false,
                hidden: true,
                module: ConvictedCriminalOffencePrediction,
            },
            {
                label: "menu.europeStates",
                icon: FaList,
                path: "/europe/states",
                loggedOnly: false,
                hidden: false,
                module: States,

            },
            {
                label: "menu.europeOffences",
                icon: FaList,
                path: "/europe/criminalOffences",
                loggedOnly: false,
                hidden: false,
                module: CriminalOffences,

            },
            {
                label: "menu.europeStateInfo",
                icon: FaList,
                path: "/europe/states/info/id=:id/code=:code",
                loggedOnly: false,
                hidden: true,
                module: StateInfo,
            },
            {
                label: "menu.europeStateCompare",
                icon: FaList,
                path: "/europe/states/compare/info/codes=:codes",
                loggedOnly: false,
                hidden: false,
                module: StateCompareInfo,

            },
            {
                label: "menu.europeStateCompare",
                icon: FaList,
                path: "/europe/states/compare/criminalOffences/codes=:codes",
                loggedOnly: false,
                hidden: true,
                module: StateCompareCriminalOffences
            },
            {
                label: "menu.europeAdministration",
                icon: FaFile,
                path: "/europe/administration",
                loggedOnly: false,
                hidden: false,
                module: AdministrationEurope,
            },
            {
                label: "Analyses drugs",
                icon: BsGraphUp,
                path: "/europe/analyses/drugs",
                loggedOnly: false,
                crimesOnly: false,
                hidden: true,
                module: CriminalOffenceAnalysesDrugs
            },

        ]
    },
    {
        label: "menu.crimesImport",
        icon: MdPostAdd,
        path: "/crimesAdd",
        loggedOnly: false,
        crimesOnly: false,
        adminOnly: true,
        module: CAdd
    },
    {
        label: "menu.userManagement",
        icon: FaUser,
        path: "/userManagement",
        loggedOnly: false,
        crimesOnly: false,
        adminOnly: true,
        module: Users
    },
    {
        label: "menu.profile",
        icon: FaUser,
        path: "/profile",
        unloggedOnly: false,
        loggedOnly: true,
        module: AccountSettings,
    },
    {
        label: "menu.login",
        icon: FaUserAlt,
        path: "/login",
        unloggedOnly: true,
        loggedOnly: false,
        module: Login,
    },
    {
        label: "menu.register",
        icon: FaUserPlus,
        path: "/registration",
        unloggedOnly: true,
        loggedOnly: false,
        module: Registration,
    },
    /*{
        label: "menu.logout",
        icon: MdOutlineLogout,
        path: "/logout",
        loggedOnly: true,
        hidden: true,
        module: Logout,
    },*/
    {
        label: "AccessForbiden",
        icon: MdOutlineLogout,
        path: "/accessforbidden",
        loggedOnly: false,
        hidden: true,
        module: AccessForbidden,
    },
    {
        label: "PageNotFound",
        icon: MdOutlineLogout,
        path: "/pagenotfound",
        loggedOnly: false,
        hidden: true,
        module: PageNotFound,
    },
    {
        label: "menu.crimesSearch",
        icon: FaSearch,
        path: "/crimes/search/:id",
        loggedOnly: false,
        crimesOnly: false,
        hidden: true,
        module: CSearchNew
    }
];

export { menuItems };
export type { MenuItems };