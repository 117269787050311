import CustomModal from "../Modal/CustomModal";
import { Button } from "../StyleComponents/StyleComponents";
import { FaSave, FaPlus, FaTimes } from 'react-icons/fa';
import { FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@mui/material";
import Select from 'react-select';
import { useState, useEffect, useMemo } from 'react';
import { filterType, SearchFilter, SearchFilterValue } from "../../interfaces/SearchFilter";
import css from './Graph.module.css';
import SFilterSettings from "../Search/SFilterSettings";
import { GraphValue, GraphData } from '../../interfaces/Graphs';
import NotificationService from "../../services/NotificationService";
import { useTranslation } from 'react-i18next';
import { ExpressionValue } from "../Expressions/Expressions";

interface IProps {
    filter: SearchFilter[];
    filterValues: SearchFilterValue[];
    graphValue?: GraphValue;
    endpointService: any;
    show: boolean;
    onClose: () => void;
    onSave: (graph: GraphValue) => void;
    customExpressions?: ExpressionValue[];
    analzyse?: boolean;
    settingTAP?: boolean;//sluzi pre vyber nastaveni, ktore sa maju zobrazit v dopravnych nehodach - chodci
    reportCenter?: boolean;//pre vyber nastaveni, ktore sa maju/nemaju zobrazit v report centri
    onColorChange?: (index: number, newColor: string) => void;
};

export default function GraphModal(props: IProps) {

    const { t } = useTranslation("crimes");
    const [ title, setTitle ] = useState("");
    const [ osX, setOsX ] = useState("");
    const [ osY, setOsY ] = useState("");
    const [ width, setWidth ] = useState(100);
    const [ height, setHeight ] = useState(300);
    const [ widthMark, setWidthMark ] = useState({ label: '%', value: '%'});
    const [ heightMark, setHeightMark ] = useState({ label: 'px', value: 'px'});
    const [ type, setType ] = useState({ label: t("graph.typeBar"), value: 'bar'});
    const [ group, setGroup ] = useState({ label: t("graph.valueCount"), value: "count"});
    const [ color, setColor ] = useState("#FFFF00");
    const [ showLabels, setShowLabels ] = useState<boolean | 'value'>(false);
    const [ dataType, setDataType ] = useState("single");
    const [ datasets, setDatasets ] = useState<any>([
        { name: "", liveData: true, color: "", atr1: undefined, atr2: undefined, atr1Interval: undefined, atr2Interval: undefined, filterValues: undefined, nullValues: false }
    ]);

    const [ optionsWidth ] = useState([
        { label: "px", value: "px" },
        { label: "%", value: "%" },
        { label: "vw", value: "vw" },
    ]);

    const [ optionsHeight ] = useState([
        { label: "px", value: "px" },
        { label: "vh", value: "vh" },
    ]);

    const optionsType = useMemo(() => [
        { label: t("graph.typeBar"), value: "bar" },
        { label: t("graph.typeLine"), value: "line" },
        // { label: "Koláčový graf", value: "pie" },
    ], [ t ]);

    const optionsGroup = useMemo(() => [
        { label: t("graph.valueCount"), value: "count" },
        { label: t("graph.valueMean"), value: "avg" },
        { label: t("graph.valueMax"), value: "max" },
        { label: t("graph.valueMin"), value: "min" },
        { label: t("graph.valueSum"), value: "sum" },
        { label: t("graph.valueMedian"), value: "median" },
        { label: t("graph.valueModus"), value: "stats_mode" },
    ], [ t ]);

    const optionsIntervalOriginal = useMemo(() => [
        { label: t("graph.noDefined"), value: "" },
        { label: t("graph.intervalYear"), value: "YYYY" },
        { label: t("graph.intervalMonth"), value: "MONTH YYYY" },
        { label: t("graph.intervalWeek"), value: "WW.YYYY" },
        { label: t("graph.intervalDay"), value: "DD.MM.YYYY" },
    ], [ t ]);

    const optionsIntervalTAP = useMemo(() => [
        { label: t("graph.noDefined"), value: "" },
        { label: t("graph.intervalYear"), value: "Year" },
        { label: t("graph.intervalMonth"), value: "Month" },
        { label: t("graph.intervalWeek"), value: "Weekday" },
        { label: t("graph.intervalDay"), value: "Day" },
        { label: t("graph.intervalHour"), value: "Hour" },
    ], [ t ]);

    const optionsIntervalTAPAnalyse = useMemo(() => [
        { label: t("graph.noDefined"), value: "" },
        { label: t("graph.intervalYear"), value: "YYYY" },
        { label: t("graph.individualMonth"), value: "YYYY-MM" },
        { label: t("graph.individualWeek"), value: "YYYY-WW" },
        { label: t("graph.individualDay"), value: "YYYY-MM-DD" },
        // { label: t("graph.intervalHour"), value: "YYYY-MM-DD-HH24" },
    ], [ t ]);

    const optionsInterval = useMemo(() => 
        props.settingTAP ? (props.analzyse ? optionsIntervalTAPAnalyse : optionsIntervalTAP) : optionsIntervalOriginal
    , [props.settingTAP, props.analzyse, optionsIntervalTAPAnalyse, optionsIntervalTAP, optionsIntervalOriginal]);

    const optionsIntervalBy = useMemo(() => [
        { label: "Dátum podania", value: "datum_podania" },
        { label: "Dátum začatia", value: "datum_zacatia" },
        { label: "Dátum pokračovavnia TČ", value: "datum_pokracovania_tc" },
        { label: "Dátum ukončenia", value: "datum_ukoncenia" },
        { label: "Dátum obvinenia", value: "datum_obvinenia" },
        { label: "Dátum rozšírenia", value: "datum_rozsirenia" },
        { label: "Dátum pokračovania P", value: "datum_pokracovania_p" },
        { label: "Dátum prevzatia", value: "datum_prevzatia" },
        { label: "Dátum vrátenia", value: "datum_vratenia" },
        { label: "Dátum ukončenia PO DOS", value: "datum_ukoncenia_po_dos" },
    ], []);

    /*const optionsIntervalBy = useMemo(() => [
        { label: t("graph.datum_podania"), value: "datum_podania" },
        { label: t("graph.datum_zacatia"), value: "datum_zacatia" },
        { label: t("graph.datum_pokracovania_tc"), value: "datum_pokracovania_tc" },
        { label: t("graph.datum_ukoncenia"), value: "datum_ukoncenia" },
        { label: t("graph.datum_obvinenia"), value: "datum_obvinenia" },
        { label: t("graph.datum_rozsirenia"), value: "datum_rozsirenia" },
        { label: t("graph.datum_pokracovania_p"), value: "datum_pokracovania_p" },
        { label: t("graph.datum_prevzatia"), value: "datum_prevzatia" },
        { label: t("graph.datum_vratenia"), value: "datum_vratenia" },
        { label: t("graph.datum_ukoncenia_po_dos"), value: "datum_ukoncenia_po_dos" },
    ], [ t ]);*/

    useEffect(() => {
        if (props.graphValue) {
            const g = props.graphValue;
            setTitle(g.texts[0]);
            setOsX(g.texts[2]);
            setOsY(g.texts[1]);
            if (g.width) {
                setWidth(g.width[0]);
                let find = optionsWidth.find(el => el.value === g.width?.[1]);
                if (find) {
                    setWidthMark(find);
                }
            }
            if (g.height) {
                setHeight(g.height[0]);
                let find = optionsHeight.find(el => el.value === g.height?.[1]);
                if (find) {
                    setHeightMark(find);
                }
            }
            let find = optionsType.find(el => el.value === g.type);
            if (find) {
                setType(find);
            }
            find = optionsGroup.find(el => el.value === g.group);
            if (find) {
                setGroup(find);
            }
            let sl: boolean | 'value' = false;
            if (g.showLabels) sl = true;
            if (g.showLabels === 'value') sl = 'value';
            setShowLabels(sl);
            if (g.data.length > 1) {
                setDataType('datasets');
            } else if (g.data[0].dataSecond) {
                setDataType('multi');
            } else {
                setDataType('single');
            }
            setDatasets(g.data.map((d) => {
                let atr1: any = props.filter.find(flt => flt.atr === d.dataFirst);
                atr1 = { label: atr1?.name, value: atr1?.atr };
                let atr2: any = props.filter.find(flt => flt.atr === d.dataSecond);
                if (atr2) {
                    atr2 = { label: atr2?.name, value: atr2?.atr };
                }
                let atr1Interval: any = optionsInterval.find(el => el.value === d.dataFirstInterval);
                let atr2Interval: any = optionsInterval.find(el => el.value === d.dataSecondInterval);
                let atr1IntervalBy: any = optionsIntervalBy.find(el => el.value === d.dataFirstIntervalBy);
                let atr2IntervalBy: any = optionsIntervalBy.find(el => el.value === d.dataSecondIntervalBy);
                return {
                    name: d.title,
                    filterValues: d.filter,
                    liveData: d.filter === undefined,
                    atr1: atr1,
                    atr2: atr2,
                    atr1Interval: atr1Interval,
                    atr2Interval: atr2Interval,
                    atr1IntervalBy: atr1IntervalBy,
                    atr2IntervalBy: atr2IntervalBy,
                    color: d.color ? d.color : "",
                    nullValues: d.nullValues
                }
            }));
        }
    }, [ props.graphValue, props.filter, optionsWidth, optionsHeight, optionsType, optionsGroup, optionsInterval, optionsIntervalBy]);

    const handleTitleChange = (value: any) => {
        setTitle(value.target.value);
    }

    const handleOsXChange = (value: any) => {
        setOsX(value.target.value);
    }

    const handleOsYChange = (value: any) => {
        setOsY(value.target.value);
    }

    const handleWidthChange = (value: any) => {
        setWidth(value.target.value);
    }

    const handleHeightChange = (value: any) => {
        setHeight(value.target.value);
    }

    const handleWidthMarkChange = (value: any) => {
        setWidthMark(value);
    }

    const handleHeightMarkChange = (value: any) => {
        setHeightMark(value);
    }

    const handleTypeChange = (value: any) => {
        setType(value);
    }

    const handleGroupChange = (value: any) => {
        setGroup(value);
    }

    const handleLabelsChange = (value: any) => {
        setShowLabels(value.target.value);
    }

    const handleDataTypeChange = (value: any) => {
        setDataType(value.target.value);
    }

    const handleDatasetAtributeOneChange = (value: any, config: any) => {
        const tempDatasets = [...datasets];
        tempDatasets[config.name.replace("atr1", "")].atr1 = value;
        setDatasets(tempDatasets);
    }

    const handleDatasetAtributeTwoChange = (value: any, config: any) => {
        const tempDatasets = [...datasets];
        tempDatasets[config.name.replace("atr2", "")].atr2 = value;
        setDatasets(tempDatasets);
    }

    const handleDatasetAtributeOneIntervalChange = (value: any, config: any) => {
        const tempDatasets = [...datasets];
        tempDatasets[config.name.replace("atr1interval", "")].atr1Interval = value;
        setDatasets(tempDatasets);
    }

    const handleDatasetAtributeOneIntervalByChange = (value: any, config: any) => {
        const tempDatasets = [...datasets];
        tempDatasets[config.name.replace("atr1intervalBy", "")].atr1IntervalBy = value;
        setDatasets(tempDatasets);
    }

    const handleDatasetAtributeTwoIntervalChange = (value: any, config: any) => {
        const tempDatasets = [...datasets];
        tempDatasets[config.name.replace("atr2interval", "")].atr2Interval = value;
        setDatasets(tempDatasets);
    }

    const handleDatasetAtributeTwoIntervalByChange = (value: any, config: any) => {
        const tempDatasets = [...datasets];
        tempDatasets[config.name.replace("atr2intervalBy", "")].atr2IntervalBy = value;
        setDatasets(tempDatasets);
    }

    const handleDatasetNameChange = (value: any) => {
        const tempDatasets = [...datasets];
        tempDatasets[value.target.name.replace("name", "")].name = value.target.value;
        setDatasets(tempDatasets);
    }

    const handleDatasetLiveChange = (value: any) => {
        const tempDatasets = [...datasets];
        tempDatasets[value.target.name.replace("live", "")].liveData = value.target.checked;
        setDatasets(tempDatasets);
    }

    const handleDatasetNullValuesChange = (value: any) => {
        const tempDatasets = [...datasets];
        tempDatasets[value.target.name.replace("nullValues", "")].nullValues = value.target.checked;
        setDatasets(tempDatasets);
    }

  //  const handleDatasetColorChange = (value: any) => {
  //      const tempDatasets = [...datasets];
  //      tempDatasets[value.target.name.replace("color", "")].color = value.target.value;
  //      setDatasets(tempDatasets);
  //  }

    const handleDatasetColorChange = (value: any) => {
        const index = Number(value.target.name.replace("color", ""));
        const newColor = value.target.value;

        const tempDatasets = [...datasets];
        tempDatasets[index].color = newColor;
        setDatasets(tempDatasets);

        if (props.onColorChange) {
            props.onColorChange(index, newColor);
        }
    };

    const handleDatasetFilterChange = (idx: number, value: SearchFilterValue[]) => {
        const tempDatasets = [...datasets];
        tempDatasets[idx].filterValues = value;
        setDatasets(tempDatasets);
    }

    const handleAddDataset = () => {
        const tempDatasets = [...datasets];
        tempDatasets.push({ name: "", liveData: true, color: "", atr1: undefined, atr2: undefined, atr1Interval: undefined, atr2Interval: undefined, filterValues: undefined, nullValues: false });
        setDatasets(tempDatasets);
    }

    const handleRemoveDataset = (idx: number) => {
        const tempDatasets = [...datasets];
        tempDatasets.splice(idx, 1);
        setDatasets(tempDatasets);
    }

    const handleSave = () => {
        let check = true;
        let tempDatasets = dataType === 'datasets' ? datasets : [datasets[0]];
        tempDatasets.forEach((dataset: any, idx: number) => {
            { 
            if (dataset.name.length < 1) {
                check = false;
                NotificationService.error("Chyba", `Dataset číslo ${idx + 1} nemá vyplnený názov!`);
            }
            /*    
            if (dataset.color.length !== 0 && (dataset.color.length !== 6 || !dataset.color.match(/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/))) {
                check = false;
                NotificationService.error("Chyba", `Dataset číslo ${idx + 1} nemá správnu farbu! (prázdne alebo hexadecimálne číslo RRGGBB)`);
            }*/}
            if (!dataset.atr1) {
                check = false;
                NotificationService.error("Chyba", `Dataset číslo ${idx + 1} nemá zvolený atribút!`);
            }
            if (!dataset.atr2 && dataType === 'multi') {
                check = false;
                NotificationService.error("Chyba", `Dataset číslo ${idx + 1} nemá zvolený atribút!`);
            }
            const atr1 = props.filter.find(flt => flt.atr === dataset.atr1?.value);
            if (atr1?.type === filterType.DATE) {
                if (!dataset.atr1Interval) {
                    check = false;
                    NotificationService.error("Chyba", `Dataset číslo ${idx + 1} nemá zvolený interval aaa!`);
                }
            } else {
                //dataset.atr1Interval = undefined;
            }
            const atr2 = props.filter.find(flt => flt.atr === dataset.atr2?.value);
            if (atr2?.type === filterType.DATE) {
                if (!dataset.atr2Interval) {
                    check = false;
                    NotificationService.error("Chyba", `Dataset číslo ${idx + 1} nemá zvolený interval vvv!`);
                }
            } else {
                //dataset.atr2Interval = undefined;
            }
        });
        if (check) {

            const data: GraphData[] = tempDatasets.map((dataset: any) => ({
                title: dataset.name,
                filter: !dataset.liveData ? dataset.filterValues : undefined,
                dataFirst: dataset.atr1.value,
                dataFirstInterval: dataset.atr1Interval?.value,
                dataFirstIntervalBy: dataset.atr1IntervalBy?.value,
                dataSecond: dataType === 'multi' ? dataset.atr2.value : undefined,
                dataSecondInterval: dataset.atr2Interval?.value,
                dataSecondIntervalBy: dataset.atr2IntervalBy?.value,
                color: dataset.color?.length > 0 ? dataset.color : undefined,
                nullValues: dataset.nullValues
            }));

            const graph: GraphValue = {
                texts: [title, osY, osX],
                width: [width, widthMark.value],
                height: [height, heightMark.value],
                showLabels: showLabels,
                type: type.value,
                group: group.value,
                data: data
            };
            props.onSave(graph);
        }
    }

    const selectStyle = {
        styles: { menuPortal: (base: any) => ({ ...base, zIndex: 9999 }), control: (base: any) => ({ ...base, padding: '9px'}) },
        menuPortalTarget: document.body
    };

    const filterOptions = props.filter.map((item) => ({ label: item.name, value: item.atr }));
    const customExp = () => {
        let temp = {
            label: "",
            value: ""
        };
        if (props.customExpressions) {
            props.customExpressions.forEach((expresion : any) => {
                expresion.expression.forEach((exp : any) => {
                    temp.value = temp.value + exp.value;
                });
                temp.label = expresion.label;
            });
            
        }
        filterOptions.push(temp);
    }
    customExp();

    return (
        <CustomModal
            show={props.show}
            title={props.graphValue ? t("graph.editTitle") : t("graph.addTitle")}
            width="700px"
            onClose={props.onClose}
        >
            <div className='box-12'>
                <div className='box-12 py-1 px-2'>
                    <TextField label={t("graph.placeholderName")} variant="outlined" fullWidth value={title} onChange={handleTitleChange} />
                </div>
                <div className='box-6 py-2 px-2'>
                    <TextField label={t("graph.placeholderY")} variant="outlined" fullWidth value={osY} onChange={handleOsYChange} />
                </div>
                <div className='box-6 py-2 px-2'>
                    <TextField label={t("graph.placeholderX")} variant="outlined" fullWidth value={osX} onChange={handleOsXChange} />
                </div>
            </div>
            {props.reportCenter && props.analzyse ? <></> //TODO: odstranit po tom ako sa spravi nastavenie grafu predikcie v centre reportov
            : 
            <>
                { !props.reportCenter && <>
                <div className='box-6'>
                    <div className='box-6 py-1 px-2'>
                        <TextField type="number" label={t("graph.placeholderWidth")} variant="outlined" fullWidth value={width} onChange={handleWidthChange} />
                    </div>
                    <div className='box-6 py-1 px-2'>
                        <Select
                            options={optionsWidth}
                            value={widthMark}
                            onChange={handleWidthMarkChange}
                            {...selectStyle}
                        />
                    </div>
                </div>
                <div className='box-6'>
                    <div className='box-6 py-1 px-2'>
                        <TextField type="number" label={t("graph.placeholderHeight")} variant="outlined" fullWidth value={height} onChange={handleHeightChange} />
                    </div>
                    <div className='box-6 py-1 px-2'>
                        <Select
                            options={optionsHeight}
                            value={heightMark}
                            onChange={handleHeightMarkChange}
                            {...selectStyle}
                        />
                    </div>
                </div></>}
                <div className='box-6'>
                    <div className='box-12 py-1 px-2'>
                        <Select
                            options={optionsType}
                            value={type}
                            onChange={handleTypeChange}
                            {...selectStyle}
                        />
                    </div>
                </div>
                <div className='box-6'>
                    <div className='box-12 py-1 px-2'>
                        <Select
                            options={optionsGroup}
                            value={group}
                            onChange={handleGroupChange}
                            {...selectStyle}
                        />
                    </div>
                </div>
                <div className='box-6'>
                    <div className='box-12 py-1 px-2'>
                        <FormLabel>{t("graph.valueText")}</FormLabel>
                        <RadioGroup
                            value={showLabels}
                            onChange={handleLabelsChange}
                        >
                            <FormControlLabel value={false} control={<Radio />} label={t("graph.valueHide")} />
                            <FormControlLabel value={true} control={<Radio />} label={t("graph.valueShow")} />
                            <FormControlLabel value="value" control={<Radio />} label={t("graph.valueOnly")} />
                        </RadioGroup>
                    </div>
                </div>
                <div className='box-6'>
                    <div className='box-12 py-1 px-2'>
                        <FormLabel>{t("graph.dataType")}</FormLabel>
                        <RadioGroup
                            value={dataType}
                            onChange={handleDataTypeChange}
                        >
                            <FormControlLabel value="single" control={<Radio />} label={t("graph.dataSingle")} />
                            { !props.analzyse && <FormControlLabel value="multi" control={<Radio />} label={t("graph.dataMulti")} /> }
                            { !props.analzyse && <FormControlLabel value="datasets" control={<Radio />} label={t("graph.dataSet")} /> }
                        </RadioGroup>
                    </div>
                </div>
                { datasets.map((data: any, idx: number) => 
                    (idx === 0 || dataType === 'datasets') && <div key={idx} className={`box-12 py-2 my-1 ${css.dataset}`} style={{ position: 'relative' }}>
                        { idx > 0 && <div className={css.close} onClick={() => handleRemoveDataset(idx)}>
                            <FaTimes />
                        </div> }
                        <div className='box-6 py-1 px-2'>
                            <TextField name={"name" + idx} label={t("graph.datasetName")} variant="outlined" fullWidth value={data.name} onChange={handleDatasetNameChange} />
                        </div>
                        <div className='box-6 py-1 px-2'>
                            {/* <TextField name={"color" + idx} label={t("graph.datasetColor")} variant="outlined" fullWidth value={data.color} onChange={handleDatasetColorChange} /> */}
                            <TextField
                                type="color"
                                name={"color" + idx}
                                label={t("graph.datasetColor")}
                                variant="outlined"
                                fullWidth
                                onChange={handleDatasetColorChange}
                            />
                        </div>
                        <div className='box-12'>
                            <div className='box-12'>
                                <div className={`${dataType === 'multi' ? 'box-6' : 'box-12'} py-1 px-2`}>
                                    <div>{t("graph.fromtable")}</div>
                                    <Select
                                        name={"atr1" + idx}
                                        options={filterOptions}
                                        value={data.atr1}
                                        isSearchable={true}
                                        onChange={handleDatasetAtributeOneChange}
                                        {...selectStyle}
                                    />
                                </div>
                                { /* props.filter.find((flt) => flt.atr === data.atr1?.value)?.type === filterType.DATE &&  */ } <div className={`${dataType === 'multi' ? 'box-6' : 'box-12'} py-1 px-2`}>
                                    <div>{t("graph.interval")}</div>
                                    <Select
                                        name={"atr1interval" + idx}
                                        options={optionsInterval}
                                        value={data.atr1Interval}
                                        isSearchable={true}
                                        onChange={handleDatasetAtributeOneIntervalChange}
                                        {...selectStyle}
                                    />
                                </div>
                                {!props.settingTAP && data?.atr1Interval?.value.length > 0 && <div className={`${dataType === 'multi' ? 'box-6' : 'box-12'} py-1 px-2`}>
                                    <div>{t("graph.interval1")}</div>
                                    <Select
                                        name={"atr1intervalBy" + idx}
                                        options={optionsIntervalBy}
                                        value={data.atr1IntervalBy}
                                        isSearchable={true}
                                        onChange={handleDatasetAtributeOneIntervalByChange}
                                        {...selectStyle}
                                    />
                                </div> }
                            </div>
                            { dataType === 'multi' && <div className='box-12'>
                                <div className='box-6 py-1 px-2'>
                                    <Select
                                        name={"atr2" + idx}
                                        options={filterOptions}
                                        value={data.atr2}
                                        isSearchable={true}
                                        onChange={handleDatasetAtributeTwoChange}
                                        {...selectStyle}
                                    />
                                </div>
                                {/* dataType === 'multi' && props.filter.find((flt) => flt.atr === data.atr2?.value)?.type === filterType.DATE &&*/ } <div className='box-6 py-1 px-2'>
                                    <Select
                                        name={"atr2interval" + idx}
                                        options={optionsInterval}
                                        value={data.atr2Interval}
                                        isSearchable={true}
                                        onChange={handleDatasetAtributeTwoIntervalChange}
                                        {...selectStyle}
                                    />
                                </div>
                                {!props.settingTAP &&  data?.atr2Interval?.value.length > 0 && <div className='box-6 py-1 px-2'>
                                    <Select
                                        name={"atr2intervalBy" + idx}
                                        options={optionsIntervalBy}
                                        value={data.atr2IntervalBy}
                                        isSearchable={true}
                                        onChange={handleDatasetAtributeTwoIntervalByChange}
                                        {...selectStyle}
                                    />
                                </div> }
                            </div> }
                        </div>
                        <div className='box-6 py-1 px-2'>
                            <div className='py-4'>
                                <input type="checkbox" name={"live" + idx} checked={data.liveData} onChange={handleDatasetLiveChange} /> {!props.reportCenter ? t("graph.useTableData") : t("graph.useGlobalData")}
                            </div>
                        </div>
                        {props.settingTAP && <div className='box-6 py-1 px-2'>
                            <div className='py-4'>
                                <input type="checkbox" name={"nullValues" + idx} checked={data.nullValues} onChange={handleDatasetNullValuesChange} /> {t("graph.showNullValues")}
                            </div>
                        </div>}
                        { !data.liveData && <div className="box-6 pt-4 px-2 text-left">
                            <SFilterSettings 
                                filter={props.filter} 
                                filterValues={data.filterValues || props.filterValues}
                                onFilterChange={(nfilter, nfilterValues) => { handleDatasetFilterChange(idx, nfilterValues); }} 
                                endpointService={props.endpointService}
                            />
                        </div> }
                    </div>
                ) }
                
                { dataType === "datasets" && <div className='box-12 mt-2 text-center'>
                    <Button onClick={handleAddDataset}><FaPlus />{t("graph.addDatasetButton")}</Button>
                </div> }
            </>}
            <div className='box-12 mt-2 text-right'>
                <Button onClick={handleSave}>{props.graphValue ? <FaSave /> : <FaPlus /> } {props.graphValue ? t("graph.saveButton") : t("graph.addButton")}</Button>
            </div>
        </CustomModal>
    )
}