import { useState } from 'react';
// import { useSearchParams } from 'react-router-dom';
import { SearchFilter, SearchFilterValue } from '../../../interfaces/SearchFilter';
import filterItemsTraffic from '../../../interfaces/ConstFilterTraffic';
import filterItemsTrafficPeds from '../../../interfaces/ConstFilterTrafficPeds';
import filterItemsTrafficAnimals from '../../../interfaces/ConstFilterTrafficAnimals';
import STable from '../../../components/Search/STableWT';
import searchServiceWT from '../../../services/SearchServiceDNWT';
import SearchServiceTAP from '../../../services/SearchServiceTAP';
import SearchServiceTZ from '../../../services/SearchServiceTZ';
import ReportServiceTAP from '../../../services/ReportsService';
import SFilterSettings from '../../../components/Search/SFilterSettings';
import { Sort } from '../../../interfaces/SortWT';
import SFilterValueDraw from '../../../components/Search/SFilterValueDraw';
import { GraphValue } from '../../../interfaces/Graphs';
import Expressions, { ExpressionValue } from '../../../components/Expressions/Expressions';
import ShowBox from '../../../components/ShowBox';
import MultiGraphs from '../../../components/MultiGraphs/MultiGraphs';
import Settings from '../../../components/Settings/Settings';
import GraphController from '../../../components/Graphs/GraphController';
import { useTranslation } from 'react-i18next';
import { getRegions, getYears } from '../../../interfaces/traffic_accidents/FormControllerData';
import { Button } from '../../../components/StyleComponents/StyleComponents';
import image from '../../../assets/css/images/police.jpg';
import PageForm from '../../../components/PageHeader/PageForm';
import { ToggleButtonGroup } from '@mui/material';

export default function TSearch() {
    const { t,i18n } = useTranslation("traffic");
    const currentLanguage = i18n.language; 
    //console.log("Current languague:  " + currentLanguage);
    //console.log("Service values: " + searchServiceWT.getTableData); 

    // const [searchParams, setSearchParams] = useSearchParams();

    const [ filter, setFilter ] = useState<SearchFilter[]>(filterItemsTraffic);
    const [ filterValues, setFilterValues ] = useState<SearchFilterValue[]>([]);
    const [ sort, setSort ] = useState<Sort>({ page: 1, perPage: 5, label: "", order: true,language: currentLanguage });
    const [ graphValues, setGraphValues ] = useState<GraphValue[]>([]);
    const [ expressionValues, setExpressionValues ] = useState<ExpressionValue[]>([]);

    const [ filterTAP, setFilterTAP ] = useState<SearchFilter[]>(filterItemsTrafficPeds);
    const [ filterValuesTAP, setFilterValuesTAP ] = useState<SearchFilterValue[]>([]);
    const [ sortTAP, setSortTAP ] = useState<Sort>({ page: 1, perPage: 5, label: "", order: true,language: currentLanguage  });
    const [ graphValuesTAP, setGraphValuesTAP ] = useState<GraphValue[]>([]);
    const [ expressionValuesTAP, setExpressionValuesTAP ] = useState<ExpressionValue[]>([]);

    const [ filterTZ, setFilterTZ ] = useState<SearchFilter[]>(filterItemsTrafficAnimals);
    const [ filterValuesTZ, setFilterValuesTZ ] = useState<SearchFilterValue[]>([]);
    const [ sortTZ, setSortTZ ] = useState<Sort>({ page: 1, perPage: 5, label: "", order: true,language: currentLanguage  });
    const [ graphValuesTZ, setGraphValuesTZ ] = useState<GraphValue[]>([]);
    const [ expressionValuesTZ, setExpressionValuesTZ ] = useState<ExpressionValue[]>([]);

    //TODO: remove when https://github.com/apache/echarts/issues/14996 is fixed
    function checkLongLabels(value: string) {
        const data = ['chovanie_chodca', 'stav_chodca', 'situacia_v_mieste_nehody', 'statna_prislusnost_chodca', 'druh_pozemnej_komunikacie', 'kraj', 'alkohol_u_chodca', 'druh_drogy_u_chodca', 'poskytnutie_prvej_pomoci','zavinenie_nehody'];
        return data.includes(value);
      }

    const handleFilterChange = (newFilter: SearchFilter[], newValues: SearchFilterValue[]) => {
        setFilter(newFilter);
        setFilterValues(newValues);
    }

    const handleFilterChangeTAP = (newFilter: SearchFilter[], newValues: SearchFilterValue[]) => {
        setFilterTAP(newFilter);
        setFilterValuesTAP(newValues);
    }

    const handleFilterChangeTZ = (newFilter: SearchFilter[], newValues: SearchFilterValue[]) => {
        setFilterTZ(newFilter);
        setFilterValuesTZ(newValues);
    }

    const handleFilterValuesAdd = (newValues: SearchFilterValue) => {
        setFilterValues([...filterValues, newValues]);
    }

    const handleFilterValuesAddTAP = (newValues: SearchFilterValue) => {
        setFilterValuesTAP([...filterValuesTAP, newValues]);
    }

    const handleFilterValuesAddTZ = (newValues: SearchFilterValue) => {
        setFilterValuesTZ([...filterValuesTAP, newValues]);
    }

    const handleSortChange = (newSort: Sort) => {
        setSort(newSort);
    }

    const handleFilterValuesChange = (newValues: SearchFilterValue[]) => {
        setFilterValues(newValues);
    }

    const handleFilterValuesChangeTAP = (newValues: SearchFilterValue[]) => {
        setFilterValuesTAP(newValues);
    }

    const handleFilterValuesChangeTZ = (newValues: SearchFilterValue[]) => {
        setFilterValuesTZ(newValues);
    }

    const handleGraphValueChange = (newGraphs: GraphValue[]) => {
        setGraphValues(newGraphs);
    }

    const handleGraphValueChangeTAP = (newGraphs: GraphValue[]) => {
        newGraphs.forEach(function (value) {
            if ((value.data[0].dataFirstInterval === '')) {
                value.longXLabels = checkLongLabels((value.data[0].dataFirst ?? 'null'));
            }
            value.settingTAP = true;
          });
        setGraphValuesTAP(newGraphs);
    }

    const handleGraphValueChangeTZ = (newGraphs: GraphValue[]) => {
        setGraphValuesTZ(newGraphs);
    }

    const handleExpressionChange = (newExp: any[]) => {
        setExpressionValues(newExp);
    }

    const handleImportData = (data: any) => {
        if (toggleDN && toggleZV) {
            setFilter(data?.filter);
            setFilterValues(data?.filterValues);
            setSort(data?.sort);
            setGraphValues(data?.graphValues);
            setExpressionValues(data?.expressionValues);
        } else if (!toggleDN && toggleZV) {
            setFilterTAP(data?.filter);
            setFilterValuesTAP(data?.filterValues);
            setSortTAP(data?.sort);
            /*setGraphValuesTAP(data?.graphValues);*/
            handleGraphValueChangeTAP(data?.graphValues);
            setExpressionValuesTAP(data?.expressionValues);
        }
        else {  // (toggleDN && !toggleZV)
            setFilterTZ(data?.filter);
            setFilterValuesTZ(data?.filterValues);
            setSortTZ(data?.sort);
            setGraphValuesTZ(data?.graphValues);
            //handleGraphValueChangeTZ(data?.graphValues);
            setExpressionValuesTZ(data?.expressionValues);
        }
    }

    //toggleDN == true, ak sa zobrazuju zaznamy o Dopravnych Nehodach
    const [toggleDN, setToggle] = useState(true)
    const [toggleZV, setToggleZV] = useState(true)

    function handleClick() {
        setToggle(!toggleDN)

        if (!toggleZV) {
            setToggleZV(!toggleZV)
        }
    }

    function handleZVClick() {
        setToggleZV(!toggleZV)

        if (!toggleDN) {
            setToggle(!toggleDN)
        }
    }

    const handleToggleDNMain = () => {
        if (!toggleDN) {
            setToggle(!toggleDN)
        }

        if (!toggleZV) {
            setToggleZV(!toggleZV)
        }
    };

    const dataToFile = {
        filter: (toggleDN && toggleZV) ? filter : toggleZV ? filterTAP : filterTZ,
        filterValues: (toggleDN && toggleZV) ? filterValues : toggleZV ? filterValuesTAP : filterValuesTZ,
        sort: (toggleDN && toggleZV) ? sort : toggleZV ? sortTAP : sortTZ,
        graphValues: (toggleDN && toggleZV) ? graphValues : toggleZV ? graphValuesTAP : graphValuesTZ,
        expressionValues: (toggleDN && toggleZV) ? expressionValues : toggleZV ? expressionValuesTAP : expressionValuesTZ
    };
    const RegionName = "Region";
    const YearName = "Year";
    const GraphControllerProps =
        [
            {
                label: t("Graph.regiony"),//"Regióny",
                useIterval: false,
                selectionData: getRegions(),
                name: RegionName
            },
            {
                label: t("Graph.roky"),
                useIterval: true,
                selectionData: getYears(),
                name: YearName
            }
        ];
        const onClick = (state: any) => {
            console.log(state);
            /* let data_url = GenerateUrl(state);
            console.log(data_url);
            FetchGraphData(data_url); */
        };

    return (
        <PageForm label={t("Search.vyhladavanie")} image={image}>

        <div className="box-12">
            <div className='box-12 py-2 text-center'>
                <ToggleButtonGroup>
                    <Button onClick={handleToggleDNMain} style={{margin: '0px'}}>{t("Search.nadpisNehody")}</Button>
                    <Button className={toggleDN ? 'main-button-peds' : ``} onClick={handleClick} style={{margin: '0px'}}>{t("Search.nadpisChodciOnly")}</Button>
                    <Button className={toggleZV ? 'main-button-zver' : ``} onClick={handleZVClick} style={{margin: '0px'}}>{t("Search.nadpisZverOnly")}</Button>
                </ToggleButtonGroup>
            </div>

            <div className="box-12 py-2 text-right">
                <Settings 
                    dataKey={(toggleDN && toggleZV) ? "DNSearch" : toggleZV ? "TAPSearch" : "TZSearch"}
                    dataToFile={dataToFile}
                    onImportData={handleImportData}
                    saveOnRefresh={false}
                    loadOnRefresh={false}
                />
                <SFilterSettings 
                    filter={(toggleDN && toggleZV) ? filter : toggleZV ? filterTAP : filterTZ} 
                    filterValues={(toggleDN && toggleZV) ? filterValues : toggleZV ? filterValuesTAP : filterValuesTZ}
                    withVisibleOption
                    onFilterChange={(toggleDN && toggleZV) ? handleFilterChange : toggleZV ? handleFilterChangeTAP : handleFilterChangeTZ} 
                    endpointService={(toggleDN && toggleZV) ? searchServiceWT : toggleZV ? SearchServiceTAP : SearchServiceTZ}
                />
            </div>
            <ShowBox className="box-12 py-2" name="">
                <SFilterValueDraw 
                    filterValues={(toggleDN && toggleZV) ? filterValues : toggleZV ? filterValuesTAP : filterValuesTZ} 
                    onChange={(toggleDN && toggleZV) ? handleFilterValuesChange : toggleZV ? handleFilterValuesChangeTAP : handleFilterValuesChangeTZ } 
                />
            </ShowBox>

            {(toggleDN && toggleZV)  ? <GraphController GraphControls={GraphControllerProps} OnClick={onClick}></GraphController> : ""}
            
            <ShowBox className="box-12 py-2" name="">
                <STable 
                    filter={(toggleDN && toggleZV) ? filter : toggleZV ? filterTAP : filterTZ}
                    filterValues={(toggleDN && toggleZV) ? filterValues : toggleZV ? filterValuesTAP : filterValuesTZ}
                    sort={sort}
                    onFilterAdd={(toggleDN && toggleZV) ? handleFilterValuesAdd : toggleZV ? handleFilterValuesAddTAP : handleFilterValuesAddTZ}
                    onSortChange={handleSortChange}
                    endpointService={(toggleDN && toggleZV) ? searchServiceWT : toggleZV ? SearchServiceTAP : SearchServiceTZ}
                    iconBar = {toggleDN ? false : true}
                    editMode = {toggleDN ? false : true}
                />
            </ShowBox>

            {toggleDN ? "" : 
                <ShowBox className="box-12 py-2" name={t("Search.grafy") + ""}>
                    <MultiGraphs 
                        filter={filterTAP}
                        customExpressions={expressionValuesTAP}
                        filterValues={filterValuesTAP}
                        graphValues={graphValuesTAP}
                        onChange={handleGraphValueChangeTAP}
                        endpointService={SearchServiceTAP} 
                        endpointServiceReport={ReportServiceTAP} 
                        settingTAP = {true}
                    />
                </ShowBox>
            }

            {toggleZV ? "" : 
                <ShowBox className="box-12 py-2" name={t("Search.grafy") + ""}>
                    <MultiGraphs 
                        filter={filterTZ}
                        customExpressions={expressionValuesTZ}
                        filterValues={filterValuesTZ}
                        graphValues={graphValuesTZ}
                        onChange={handleGraphValueChangeTZ}
                        endpointService={SearchServiceTZ} 
                    />
                </ShowBox>
            }
        </div>
        </PageForm>
    );
}