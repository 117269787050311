import axios, { AxiosResponse } from "axios";
import QueryString from "qs";
import { Cookies } from 'react-cookie';
import { ExpressionValue } from "../components/Expressions/Expressions";

const analyzisService: any = {
    /*getBasicStatistic: (search: string, byTime? : string, byDateOne? : string, byDateTwo? : string, decimalCount? : string): any => {
        return axios.get(process.env.REACT_APP_API_URL + "/tc/statistic/basicStatistic", {
            params: {
                search: JSON.stringify(search),
                byTime,
                byDateOne,
                byDateTwo,
                decimalCount
            },
            headers: { 'SID': new Cookies().get("user")?.token }
        });
    },*/
    getBasicStatistic: (atr1: string, atr2: string, atr1Interval: string, atr2Interval: string, atr1IntervalBy: string, atr2IntervalBy: string, group: string, search: string, custom?: ExpressionValue, decimalCount? : string, signal?: any): Promise<AxiosResponse<any, any>>  => {
        return axios.get(process.env.REACT_APP_API_URL + "/tc/statistic/basicStatistic", {
            params: {
                atr1: atr1,
                atr2: atr2,
                atr1interval: atr1Interval,
                atr2interval: atr2Interval,
                atr1intervalBy: atr1IntervalBy === "" ? undefined : atr1IntervalBy,
                atr2intervalBy: atr2IntervalBy === "" ? undefined : atr2IntervalBy,
                group: group,
                search: JSON.stringify(search),
                custom: JSON.stringify(custom),
                decimalCount
            },
            signal
        });
    },
    getHistogram: (atr1: string, atr2: string, atr1Interval: string, atr2Interval: string, atr1IntervalBy: string, atr2IntervalBy: string, group: string, search: string, custom?: ExpressionValue, intervalLength? : string, signal?: any): Promise<AxiosResponse<any, any>>  => {
        return axios.get(process.env.REACT_APP_API_URL + "/tc/statistic/histogram", {
            params: {
                atr1: atr1,
                atr2: atr2,
                atr1interval: atr1Interval,
                atr2interval: atr2Interval,
                atr1intervalBy: atr1IntervalBy === "" ? undefined : atr1IntervalBy,
                atr2intervalBy: atr2IntervalBy === "" ? undefined : atr2IntervalBy,
                group: group,
                search: JSON.stringify(search),
                custom: JSON.stringify(custom),
                intervalLength
            },
            signal
        });
    },
    /*getHistogram: (search: string, byTime? : string, byDateOne? : string, byDateTwo? : string, intervalLength? : string): any => {
        return axios.get(process.env.REACT_APP_API_URL + "/tc/statistic/histogram", {
            params: {
                search: JSON.stringify(search),
                byTime,
                byDateOne,
                byDateTwo,
                intervalLength
            },
            headers: { 'SID': new Cookies().get("user")?.token }
        });
    },*/
    getRegionByCrimes: (from? :string, to? : string, type? : string): any => {
        return axios.get(process.env.REACT_APP_API_URL + "/tc/statistic/region-crimes", {
            params: {
                from,
                to,
                type,
            },
            headers: { 'SID': new Cookies().get("user")?.token }
        });
    },

    getDateRange: (): Promise<AxiosResponse<any, any>> => {
        return axios.get(process.env.REACT_APP_API_URL + "/tc/statistic/year-range", {
            headers: { 'SID': new Cookies().get("user")?.token }
        });
    },
    
    getDistrictByCrimes: (from? :string, to? : string, type? : string, regionId? : number): any => {
        return axios.get(process.env.REACT_APP_API_URL + "/tc/statistic/district-crimes", {
            params: {
                from,
                to,
                type,
                regionId
            },
            headers: { 'SID': new Cookies().get("user")?.token }
        });
    },
    getDistribution: (distribution: string, atr1: string, atr2: string, atr1Interval: string, atr2Interval: string, atr1IntervalBy: string, atr2IntervalBy: string, group: string, search: string, custom?: ExpressionValue, intervalLength? : string, signal?: any): Promise<AxiosResponse<any, any>>  => {
        return axios.get(process.env.REACT_APP_API_URL + "/tc/statistic/getDistribution", {
            params: {
                distribution: distribution,
                atr1: atr1,
                atr2: atr2,
                atr1interval: atr1Interval,
                atr2interval: atr2Interval,
                atr1intervalBy: atr1IntervalBy === "" ? undefined : atr1IntervalBy,
                atr2intervalBy: atr2IntervalBy === "" ? undefined : atr2IntervalBy,
                group: group,
                search: JSON.stringify(search),
                custom: JSON.stringify(custom),
                intervalLength
            },
            signal
        });
    },
    /*getDistribution: (distribution: string, search: string, byTime? : string, byDateOne? : string, byDateTwo? : string, interval? : string): any => {
        return axios.get(process.env.REACT_APP_API_URL + "/tc/statistic/getDistribution", {
            params: {
                search: JSON.stringify(search),
                byTime,
                byDateOne,
                byDateTwo,
                interval,
                distribution
            },
            headers: { 'SID': new Cookies().get("user")?.token }
        });
    },*/
    getCumulativeFunction: (data: any, distribution: string, a: number, b: number): any => {
        return axios.get(process.env.REACT_APP_API_URL + "/tc/statistic/getCumulativeFunction", {  
            params: {
                list: data,
                distribution,
                a,
                b,
            },
            headers: { 'SID': new Cookies().get("user")?.token },
            paramsSerializer: (par: any) => QueryString.stringify(par)
        });
    },
    getPrediction: (ar: number, countOfPredict: number, atr1: string, atr2: string, atr1Interval: string, atr2Interval: string, atr1IntervalBy: string, atr2IntervalBy: string, group: string, search: string, custom?: ExpressionValue, signal?: any): Promise<AxiosResponse<any, any>>  => {
        return axios.get(process.env.REACT_APP_API_URL + "/tc/prediction", {
            params: {
                ar: ar,
                countOfPredict: countOfPredict,
                atr1: atr1,
                atr2: atr2,
                atr1interval: atr1Interval,
                atr2interval: atr2Interval,
                atr1intervalBy: atr1IntervalBy === "" ? undefined : atr1IntervalBy,
                atr2intervalBy: atr2IntervalBy === "" ? undefined : atr2IntervalBy,
                group: group,
                search: JSON.stringify(search),
                custom: JSON.stringify(custom),
            },
            signal
        });
    },
    getCorrelation: (atr1: string, atr2: string, atr1Interval: string, atr2Interval: string, atr1IntervalBy: string, atr2IntervalBy: string, group: string, 
            search: string, 
            batr1: string, batr2: string, batr1Interval: string, batr2Interval: string, batr1IntervalBy: string, batr2IntervalBy: string, bgroup: string, 
            bsearch: string, 
            custom?: ExpressionValue, bcustom?: ExpressionValue, 
            signal?: any): Promise<AxiosResponse<any, any>>  => {
        return axios.get(process.env.REACT_APP_API_URL + "/tc/correlation", {
            params: {
                atr1: atr1,
                atr2: atr2,
                atr1interval: atr1Interval,
                atr2interval: atr2Interval,
                atr1intervalBy: atr1IntervalBy === "" ? undefined : atr1IntervalBy,
                atr2intervalBy: atr2IntervalBy === "" ? undefined : atr2IntervalBy,
                group: group,
                search: JSON.stringify(search),
                custom: JSON.stringify(custom),
                batr1: batr1,
                batr2: batr2,
                batr1interval: batr1Interval,
                batr2interval: batr2Interval,
                batr1intervalBy: batr1IntervalBy === "" ? undefined : batr1IntervalBy,
                batr2intervalBy: batr2IntervalBy === "" ? undefined : batr2IntervalBy,
                bgroup: bgroup,
                bsearch: JSON.stringify(bsearch),
                bcustom: JSON.stringify(bcustom),
            },
            signal
        });
    },
}

export default analyzisService;