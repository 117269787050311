import { BsGraphUp } from "react-icons/bs";
import { FaAddressBook, FaFile, FaGlobeEurope, FaHome, FaList } from "react-icons/fa";
import Home from "../pages/default/Home/Home";
import { MenuItems } from "./ConstMenu";

import AdministrationEurope from "../pages/europe/Home/Administration/AdministrationEurope";
import CriminalOffenceAnalyses from "../pages/europe/Home/CriminalOffences/CriminalOffenceAnalyses";
import CriminalOffenceMap from "../pages/europe/Home/CriminalOffences/CriminalOffenceMap";
import CriminalOffences from "../pages/europe/Home/CriminalOffences/CriminalOffences";
import HomeEurope from "../pages/europe/Home/Home/HomeEurope";
import StateCrimesOffences from "../pages/europe/Home/States/StateDetails/StateCrimesOffences";
import StateInfo from "../pages/europe/Home/States/StateDetails/StateInfo";
import States from "../pages/europe/Home/States/States";
import StateCompare from "../pages/europe/Home/States/StateCompare/StateCompareInfo";
import CriminalOffenceAnalysesDrugs from "../pages/europe/Home/CriminalOffences/CriminalOffenceAnalysesDrugs";
import StateCompareCriminalOffences from "../pages/europe/Home/States/StateCompare/StateCompareCriminalOffences";
import CriminalOffencePrediction from "../pages/europe/Home/CriminalOffences/CriminalOffencePrediction";
import StatePolice from "../pages/europe/Home/States/StateDetails/StatePolice";
import StateComparePolice from "../pages/europe/Home/States/StateCompare/StateComparePolice";
import CriminalOffencePredictionConvicted from "../pages/europe/Home/CriminalOffences/CriminalOffencePredictionConvicted";
import StateConvictedCrimesOffences from "../pages/europe/Home/States/StateDetails/StateConvictedCrimesOffences";
import StateCompareConvictedCriminalOffences from "../pages/europe/Home/States/StateCompare/StateCompareConvictedCriminalOffences";
import StateComparePrison from "../pages/europe/Home/States/StateCompare/StateComparePrison";
import StatePrison from "../pages/europe/Home/States/StateDetails/StatePrison";
import PrisonPrediction from "../pages/europe/Home/CriminalOffences/PrisonPrediction";


const menuItemsEurope: MenuItems[] = [
    {
        label: "Home",
        icon: FaHome,
        path: "/europe",
        loggedOnly: false,
        module: HomeEurope,
    },
    {
        label: "Crimes report",
        icon: FaAddressBook,
        path: "/europe/report",
        loggedOnly: false,
        adminOnly: false,
        module: CriminalOffenceMap,
        submenu: [
            {
                label: "Map",
                icon: FaGlobeEurope,
                path: "/europe/maps",
                loggedOnly: false,
                crimesOnly: false,
                module: CriminalOffenceMap
            },
            {
                label: "Analyses",
                icon: BsGraphUp,
                path: "/europe/analyses/bac",
                loggedOnly: false,
                crimesOnly: false,
                module: CriminalOffenceAnalyses
            },
            {
                label: "Analyses",
                icon: BsGraphUp,
                path: "/europe/analyses/drugs",
                loggedOnly: false,
                crimesOnly: false,
                module: CriminalOffenceAnalysesDrugs
            },

           
           
       
            // {
            //     label: "Prediction",
            //     icon: FaSearch,
            //     path: "/europe/prediction",
            //     loggedOnly: false,
            //     crimesOnly: false,
            //     module: HomeEurope
            // }
        ]
    },

    {
        label: "States",
        icon: FaList,
        path: "/europe/states",
        loggedOnly: false,
        hidden: false,
        module: States,

    },
    {
        label: "Prediction",
        icon: FaList,
        path: "/europe/prediction/",
        loggedOnly: false,
        hidden: false,
        module: CriminalOffencePrediction,
    },
    {
        label: "Prediction",
        icon: FaList,
        path: "/europe/prediction/convicted",
        loggedOnly: false,
        hidden: false,
        module: CriminalOffencePredictionConvicted,
    },
    {
        label: "Prediction",
        icon: FaList,
        path: "/europe/prediction/prison",
        loggedOnly: false,
        hidden: false,
        module: PrisonPrediction,
    },
    {
        label: "Criminal offences",
        icon: FaList,
        path: "/europe/criminalOffences",
        loggedOnly: false,
        hidden: false,
        module: CriminalOffences,

    },
    {
        label: "StateInfo",
        icon: FaList,
        path: "/europe/states/info/id=:id/code=:code",
        loggedOnly: false,
        hidden: true,
        module: StateInfo,
    },
    {
        label: "StateCrimesOffences",
        icon: FaList,
        path: "/europe/states/info/id=:id/code=:code/crimeoffences",
        loggedOnly: false,
        hidden: true,
        module: StateCrimesOffences,
    },
    {
        label: "StatePolice",
        icon: FaList,
        path: "/europe/states/info/id=:id/code=:code/police",
        loggedOnly: false,
        hidden: true,
        module: StatePolice,
    },
    {
        label: "StatePrison",
        icon: FaList,
        path: "/europe/states/info/id=:id/code=:code/prison",
        loggedOnly: false,
        hidden: true,
        module: StatePrison,
    },
    {
        label: "Administration",
        icon: FaFile,
        path: "/europe/administration",
        loggedOnly: false,
        hidden: false,
        module: AdministrationEurope,
    },
    {
        label: "Krimis",
        icon: FaFile,
        path: "/",
        loggedOnly: false,
        hidden: false,
        module: Home,
    },
    {
        label: "menu.europeStateCompare",
        icon: FaList,
        path: "/europe/states/compare/info/codes=:codes",
        loggedOnly: false,
        hidden: false,
        module: StateCompare,

    },
    {
        label: "menu.europeStateCompare",
        icon: FaList,
        path: "/europe/states/compare/criminalOffences/codes=:codes",
        loggedOnly: false,
        hidden: true,
        module: StateCompareCriminalOffences
    },
    {
        label: "menu.europeStateCompare",
        icon: FaList,
        path: "/europe/states/compare/police/codes=:codes",
        loggedOnly: false,
        hidden: true,
        module: StateComparePolice
    },
    {
        label: "menu.europeStateCompare",
        icon: FaList,
        path: "/europe/states/compare/prison/codes=:codes",
        loggedOnly: false,
        hidden: true,
        module: StateComparePrison
    },
    {
        label: "menu.europeStateCompare",
        icon: FaList,
        path: "/europe/states/compare/convictedCriminalOffences/codes=:codes",
        loggedOnly: false,
        hidden: true,
        module: StateCompareConvictedCriminalOffences
    },
    {
        label: "StateConvicted",
        icon: FaList,
        path: "/europe/states/info/id=:id/code=:code/convicted",
        loggedOnly: false,
        hidden: true,
        module: StateConvictedCrimesOffences
    }

    
];

export { menuItemsEurope };
export type { MenuItems };
