import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Chart } from "react-google-charts";
import { FaFilter } from 'react-icons/fa';
import image from '../../../assets/css/images/nahlasene.jpg';
import { population, State } from '../../../interfaces/europe/interfaces';
import './TEuropeAccidentsMap.css';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useEffect } from 'react';
import { GET_ALL_STATES, ITEM_HEIGHT, ITEM_PADDING_TOP, MAP_COLORS, TABLE_PAGE_SIZE_TEN } from '../../../interfaces/europe/Constants';
import { numberWithSpaces, getImage, CustomToolbar } from '../../../interfaces/europe/Functions';
import HeaderCarousel from '../../../components/HeaderCarousel/HeaderCarousel';
import { useTranslation } from "react-i18next";

export default function EuropeAccidentsMap() {

    const { t: tEurope } = useTranslation("europe"); // Borrowed from Europe, to avoid unnecessary duplication
    const { t } = useTranslation("traffic");

    const HEADER_WITH_INDEX_AND_COUNTRY = ["Code", "Country", "Per Million Capita", "Count"]; // Header for the chart

    interface EuropeRecordType {
        title: string,
        value: number,
    }

    const entries: EuropeRecordType[] = [
        { title: "EuropeMap.subTitle.accidents", value: 0 },
        { title: "EuropeMap.subTitle.casualties", value: 1 },
    ];

    interface EuropeAccident {
        id: number;
        stateId: number;
        year: number;
        entryType: number;
        value: number;
        codeState?: string;
    }

    const [states, setStates] = React.useState<State[]>([]);
    const [population, setPopulation] = React.useState<population[]>([]);
    const [selectedEntry, setSelectedEntry] = React.useState<EuropeRecordType>(entries[0]);
    const [selectedYear, setSelectedYear] = React.useState<number>(0);
    const [counts, setCounts] = React.useState<EuropeAccident[]>([]);
    const [years, setYears] = React.useState<number[]>();

    // Get states
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL_EU + GET_ALL_STATES).then((response) => {
            setStates(response.data);
        });
    }, []);

    // Get population
    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL_EU + "/StatePopulation/all").then((response) => { //TODO: Move to constants

            setPopulation(response.data);
        });
    }, []);

    // Get data about accidents in Europe
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL_EU + "/EuropeAccident/all").then((response) => { //TODO: Move to constants
            setCounts(response.data);
            setYears(Array.from(new Set((response.data as EuropeAccident[]).map(row => row.year))));
        });
    }, []);


    useEffect(() => {
        if (years) setSelectedYear(years[0] || 0);
    }, [years]);

    //Assign State Codes
    counts.map((row) => {
        const state = states.find(state => state.id == row.stateId);
        row.codeState = state?.code || "UNKNOWN";
    });
    
    // Filter data by selected entry and year
    const selected = counts.filter(row => row.entryType == selectedEntry?.value || 0);
    const selectedByYear = selected.filter(row => row.year == selectedYear);

    const perCapitaDictionary = selectedByYear.reduce((acc, row) => {
        // If population for selected year is not found, use any population for the state
        const populationFound = population.find(p => Number(p.stateId) == row.stateId && Number(p.year) == selectedYear) || 
            population.find(p => Number(p.stateId) == row.stateId);

        // Find population for the state
        const populationCount = Number(populationFound?.population || 0);
        // Calculate per mil capita
        const perCapita = populationCount ? Math.ceil(row.value / (populationCount / 1000000)) : 0;
        acc[row.stateId] = perCapita;

        return acc;
    }, {} as Record<number, number>);

    // Map data to chart
    const mapped = selectedByYear.map(d => 
    [
        d.codeState!.toUpperCase(), 
        `${tEurope("states." + d.codeState!.toUpperCase())}`, 
        perCapitaDictionary[d.stateId],
        d.value
    ]);

    const chartData = [HEADER_WITH_INDEX_AND_COUNTRY, ...mapped];

    const handleEntryChange = (event: SelectChangeEvent<number>) => {
        const {
            target: { value },
        } = event;

        setSelectedEntry(entries.find(entry => entry.value == value) || entries[0]);
    };

    const handleYearChange = (event: SelectChangeEvent<number>) => {
        const {
            target: { value },
        } = event;

        setSelectedYear(value as number);
    };

    const translatedRows = selectedByYear.map(row => ({
        ...row,
        nameState: tEurope(`states.${row.codeState}`),
    }));

    const columns: GridColDef[] = [
        {
            field: 'flag', headerName: '', width: 75, sortable: false, disableExport: true, filterable: false, renderCell: (params) => {
                return (
                    <div className="flag">
                        <img src={getImage(params.row.codeState)} alt={"Flag"} />

                    </div>
                )
            }
        },
        { field: 'nameState', headerName: `${tEurope("map.state")}`, flex: 1 },
        {
            field: 'count', headerName: `${tEurope("map.count")}`, flex: 1, renderCell: (params) => {
                return (
                    <>{numberWithSpaces(params.row.value)}</>
                )
            },
        },
        {
            field: 'perMilCapita', headerName: `${t("EuropeMap.perMilCapita")}`, flex: 1, renderCell: (params) => {
                return (
                    <>{numberWithSpaces(perCapitaDictionary[params.row.stateId])}</>
                )
            },
        },
    ];

    const options = {
        region: "150",
        displayMode: 'regions',
        datalessRegionColor: '#fff',
        colorAxis: {
            colors: [MAP_COLORS[0], MAP_COLORS[1], MAP_COLORS[2]]
        },
    }

    return (<>
        <div className=''>
            <HeaderCarousel name={t("EuropeMap.title")} image={image} />

            <div className='underLabel '>
                <FaFilter className='my-3'></FaFilter><span className='pl-1'>Filter</span>
            </div>

             <form >
                <div>
                <div className='d-inline'>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="label-entry">{t("EuropeMap.entry")}</InputLabel>
                            <Select
                                labelId="label-entry"
                                id="select-entry"
                                value={selectedEntry}
                                onChange={(e) => handleEntryChange(e as SelectChangeEvent<number>)}
                                input={<OutlinedInput label={t("EuropeMap.entry")} />}
                                renderValue={(selected) => t(selected.title)}
                                MenuProps={{
                                    PaperProps: {   
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                            width: 250,
                                        },
                                    }
                                }}
                            >
                                {entries?.map((entry) => (
                                    <MenuItem key={t(entry.title)} value={entry.value}>
                                        <Checkbox defaultChecked={true} checked={entry == selectedEntry} />
                                        <ListItemText primary={t(entry.title)} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='d-inline'>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="label-year">{t("EuropeMap.year")}</InputLabel>
                            <Select
                                labelId="label-year"
                                id="select-year"
                                value={selectedYear}
                                onChange={(e) => { handleYearChange(e); }}
                                input={<OutlinedInput label={t("EuropeMap.year")} />}
                                renderValue={(selected) => selected}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                            width: 250,
                                        },
                                    }
                                }}
                            >
                                {years?.map((year) => (
                                    <MenuItem key={year.toString()} value={year}>
                                        <Checkbox defaultChecked={true} checked={year == selectedYear} />
                                        <ListItemText primary={year} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </form>
        </div >

        <div className="App mb-2">
            <Chart chartType="GeoChart" width="100%" height="600px" data={chartData} options={options} />
        </div>


        <div className="mt-5 col-12">
            <h3 className="mb-4">{t(selectedEntry.title)} {t("EuropeMap.perYear")} {selectedYear}</h3>
            <div style={{ height: 650, width: '100%' }}>

                <DataGrid
                    rows={translatedRows}
                    columns={columns}
                    disableColumnSelector
                    disableSelectionOnClick={true}
                    pageSize={TABLE_PAGE_SIZE_TEN}
                    autoHeight={true}
                    getRowId={(row) => row.id}

                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'index', sort: 'desc' }],
                        },
                    }}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{ toolbar: { allColumns: true } }}
                />


            </div>
        </div>
    </>);
}
