import { filterType, SearchFilter } from "./SearchFilter";

const filterItemsTrafficAnimals: SearchFilter[] = [
  {
    atr: "druh_zveri",
    name: "Druh zveri",
    active: true,
    type: filterType.TEXT
  },{
    atr: "hlavne_priciny_nehody",
    name: "Príčina nehody",
    active: true,
    type: filterType.TEXT
  },{
    atr: "celkova_hmotna_skoda",
    name: "Celková hmotná škoda",
    active: false,
    type: filterType.TEXT
  },{
    atr: "nasledky_nehody_usmrtene_osoby",
    name: "Počet usmrtených osôb",
    active: false,
    type: filterType.TEXT
  },{
    atr: "nasledky_nehody_tazko_zranene_osoby",
    name: "Počet ťažko zranených osôb",
    active: false,
    type: filterType.TEXT
  },{
    atr: "nasledky_nehody_lahko_zranene_osoby",
    name: "Počet ľahko zranených osôb",
    active: false,
    type: filterType.TEXT
  },{
    atr: "datum",
    name: "Dátum",
    active: false,
    type: filterType.DATE
  },{
    atr: "hodina",
    name: "Hodina",
    active: false,
    type: filterType.TEXT
  },{
    atr: "minuta",
    name: "Minuta",
    active: false,
    type: filterType.NONE
  },{
    atr: "id_kraj",  
    name: "Kraj",
    active: true,
    type: filterType.TEXT
  },{
    atr: "viditelnost",
    name: "Viditeľnosť",
    active: false,
    type: filterType.TEXT
  },{
    atr: "alkohol_u_vinnika_nehody_pritomny",
    name: "Alkohol za volantom",
    active: false,
    type: filterType.TEXT
  },{
    atr: "stav_povrchu_vozovky_v_dobe_nehody",
    name: "Povrch vozovky",
    active: false,
    type: filterType.TEXT
  },{
    atr: "vyrobna_znacka_motoroveho_vozidla",
    name: "Značka vozidla",
    active: false,
    type: filterType.TEXT
  }
];

export default filterItemsTrafficAnimals;